import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getPinSize, getImageSize, getBorderWidth, calculatePinSize2D } from './util';
import ShapeWithIconModel from '../FileViewers/Tour/PinEditors/Appearance/ShapeWithIcon/model';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';

export const S = {
    Wrapper: styled.div`
        position: relative;
        flex-shrink: 0;

        display: grid;
        border-radius: 50%;

        > * {
            grid-row: 1;
            grid-column: 1;
            margin: auto;
        }
    `,
    Pin: styled.span`
        display: flex;
        align-items: center;
        justify-content: center;
        
        border-radius: 50%;
        // so we can set the border outside of the circle
        box-sizing: initial;
        flex-shrink: 0;
    `,
    Highlight: styled.div`
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid var(--vcs-color--primary);
        transition: opacity 0.25s ease-in;
    `
};

export const PinUI = ({
    appearanceModel,
    // isTextPins are the draft-js injected-within-text pins. Their size is calculated differently.
    isTextPin,
    visible = true,
    highlightOpacity = 0,
    ...rest
}) => {
    // Shape
    const size = getPinSize(appearanceModel.size);
    const borderWidth = getBorderWidth(appearanceModel.borderWidth);
    const imageSize = getImageSize(appearanceModel.imageSize);

    const isShapeNone = appearanceModel.shape === ShapeWithIconModel.SHAPES.NONE.type;
    const isImageNone = appearanceModel.image === ShapeWithIconModel.IMAGES.NONE.type;

    const appearanceStyles = isShapeNone
        ? {}
        : {
            border: `${isTextPin ? '0.1em' : `${borderWidth}px`} solid ${appearanceModel.borderColor.toCSS()}`,
            backgroundColor: appearanceModel.color.toCSS()
        };

    // Highlight
    const highlightSize = isTextPin
        ? `calc(100% + ${2 * (isShapeNone ? 0 : borderWidth)}px)`
        : `${calculatePinSize2D(appearanceModel)}px`;

    return (
        <S.Wrapper {...rest}>
            { !isShapeNone &&
                <S.Pin
                    className='pinUI__pin'
                    style={{
                        width: isTextPin ? '1em' : `${size}px`,
                        height: isTextPin ? '1em' : `${size}px`,
                        ...(!visible ? ({ opacity: 0 }) : {}),
                        ...appearanceStyles
                    }}
                />}
            { !isImageNone &&
                <Icon
                    icon={appearanceModel.image}
                    size={`${imageSize}px`}
                    color={ appearanceModel.imageColor.toCSS() }
                />}
            { !isTextPin && (
                <S.Highlight
                    className='pinUI__highlight'
                    style={{
                        width: highlightSize,
                        height: highlightSize,
                        opacity: highlightOpacity
                    }}
                />
            )}
        </S.Wrapper>
    );
};

PinUI.propTypes = {
    appearanceModel: PropTypes.object,
    isTextPin: PropTypes.bool,
    visible: PropTypes.bool,
    style: PropTypes.object,
    highlightOpacity: PropTypes.number
};
