import {
    observable,
    action,
    autorun,
    computed,
    observe,
    makeObservable,
    runInAction
} from 'mobx';

import { utils } from '../../lib';
import contextMenu from '../../view/context-menu';
import Index from '../utils/object-index';

class Item {
    constructor (data, actions, context) {
        makeObservable(this, {
            isSelected: computed,
            isValid: computed,
            select: action,
            deselect: action,
            toggleSelect: action,
            markValid: action,
            markInvalid: action,
            actions: computed,
            actionNames: computed
        });

        this.data = data;
        this.allActions = actions;
        this.context = context;
    }

    get id () {
        return this.data.id;
    }

    get isSelected () {
        return this.context.collectionStore.selectedItems.get(this.id);
    }

    get isValid () {
        return this.context.collectionStore.validItems.get(this.id);
    }

    select () {
        this.context.collectionStore.selectItem(this.id);
    }

    deselect () {
        this.context.collectionStore.deselectItem(this.id);
    }

    toggleSelect () {
        if (this.isSelected) {
            this.deselect();
        } else {
            this.select();
        }
    }

    markValid () {
        this.context.collectionStore.markValid(this.id);
    }

    markInvalid () {
        this.context.collectionStore.markInvalid(this.id);
    }

    get actions () {
        return Object.keys(this.allActions)
            .reduce((actions, actionName) => {
                const action = this.allActions[actionName];
                if (action.isAllowed(this.data)) {
                    actions[actionName] = action;
                }
                return actions;
            },
            {});
    }

    get actionNames () {
        return Object.keys(this.actions);
    }

    hasAction (action) {
        return !!this.actions[action];
    }

    doAction (action) {
        return this.actions[action].action.call(this.context, [this.data]);
    }

    contextMenuActions () {
        return [];
    }
}

const emptySelection = {
    items: [],
    hasAction (action) {
        return false;
    },
    doAction (action, context = null) {
    },
    contextMenuActions () {
        return [];
    },
    actions: {},
    hasItem (item) {
        return false;
    },
    lastItem () {
        return undefined;
    },
    deselect () {}
};

class ItemSelection {
    static create (items, context) {
        const selectedItems = items.filter(item => item.isSelected);
        return (selectedItems.length === 0)
            ? emptySelection
            : (selectedItems.length === 1)
                ? ItemSelection.fromSingleItem(selectedItems[0], context)
                : new ItemSelection(selectedItems, context);
    }

    static fromSingleItem (item, context) {
        return {
            items: [item],
            hasAction: item.hasAction.bind(item),
            doAction: item.doAction.bind(item),
            contextMenuActions: item.contextMenuActions.bind(item),
            actions: item.actions,
            hasItem (i) {
                return item === i;
            },
            lastItem () {
                return item;
            },
            deselect: item.deselect.bind(item)
        };
    }

    constructor (items, context) {
        this.items = items;
        this.context = context;
        const firstItem = this.items[0];
        const initialActionNames = firstItem.actionNames.filter(action => {
            return firstItem.actions[action].isMultiple(firstItem);
        });
        this.actionNames = Array.from(
            this.items
                .reduce((actionNames, item) => {
                    return utils.setIntersection(
                        actionNames,
                        item.actionNames.filter(action => {
                            return item.actions[action].isMultiple(item);
                        })
                    );
                },
                new Set(initialActionNames))
        );
        this.actions = this.actionNames
            .reduce((actions, action) => {
                actions[action] = firstItem.actions[action];
                return actions;
            },
            {});
    }

    hasAction (action) {
        return !!this.actions[action];
    }

    doAction (action) {
        return this.actions[action].action.call(this.context, this.items.map(item => item.data));
    }

    contextMenuActions () {
        return contextMenu.fromActions(
            this,
            this.actions,
            this.items[0].contextMenuTemplate
        );
    }

    hasItem (item) {
        return this.items.indexOf(item) !== -1;
    }

    lastItem () {
        return this.items[this.items.length - 1];
    }

    deselect () {
        this.items.forEach(item => item.deselect());
    }
}

const SelectionMode = {
    NORMAL: 'normal',
    CTRL: 'ctrl',
    SHIFT: 'shift',
    MOBILE: 'mobile',
    fromEvent (event) {
        if (event.ctrlKey || event.metaKey) {
            return SelectionMode.CTRL;
        } else if (event.shiftKey) {
            return SelectionMode.SHIFT;
        } else {
            return SelectionMode.NORMAL;
        }
    }
};

const selectionModes = {
    [SelectionMode.NORMAL]: {
        onClickItem (item) {
            runInAction(() => {
                this.selection.items
                    .filter(selectedItem => selectedItem !== item)
                    .forEach(selectedItem => selectedItem.deselect());
                item.toggleSelect();
            });
        },
        onRightClick (item) {
            if (!this.selection.hasItem(item)) {
                this.selection.deselect();
                item.select();
            }
        }
    },
    [SelectionMode.CTRL]: {
        onClickItem (item) {
            item.toggleSelect();
        },
        onRightClick (item) {
            item.select();
        }
    },
    [SelectionMode.SHIFT]: {
        onClickItem (item) {
            const currentIndex = this.items.indexOf(item);
            const lastItem = this.selection.lastItem();
            const lastSelectedIndex = lastItem
                ? this.items.indexOf(lastItem)
                : currentIndex;
            const from = Math.min(lastSelectedIndex, currentIndex);
            const to = Math.max(lastSelectedIndex, currentIndex);
            this.selection.deselect();
            window.getSelection().removeAllRanges();
            for (let i = from; i <= to; i++) {
                this.items[i].select();
            }
        },
        onRightClick (item) {
        }
    },
    [SelectionMode.MOBILE]: {
        onClickItem (item, callback) {
            item.context.collectionStore.mobileSelectionMode
                ? item.toggleSelect()
                : callback && callback();
            !this.selection.items.length && item.context.collectionStore.exitMobileSelectionMode();
        }
    }
};
Object.keys(selectionModes).forEach(mode => {
    selectionModes[mode].name = mode;
});

class CollectionStore {
    mobileSelectionMode = false;
    selectedItems = observable.map([], { deep: false });
    validItems = observable.map([], { deep: false });
    filters = observable.map([], { deep: false });
    validators = observable.map([], { deep: false });

    constructor (items, actions, context) {
        makeObservable(this, {
            mobileSelectionMode: observable,
            context: computed,
            selection: computed,
            items: computed,
            selectItem: action,
            deselectItem: action
        });

        this.dataItems = items;
        this.actions = actions;
        this.dataContext = context;
        this.mobileMode = Settings.device.isMobile;
        this.index = new Index(() => this.items, (item) => item.data.id);

        this.selectedItems.replace(
            new Map(
                this.dataItems.map(item => ([item.id, false]))
            ),
            { deep: false }
        );
        this.validItems.replace(
            new Map(
                this.dataItems.map(item => {
                    const isInvalid = Array.from(this.validators.entries())
                        .some(([_, predicate]) => !predicate(item));
                    return ([item.id, !isInvalid]);
                })
            )
        );

        this.disposers = [
            autorun(() => {
                const nextSelectedItems = new Map(
                    this.dataItems
                        .map(item => ([item.id, !!this.selectedItems.get(item.id)]))
                );
                const nextValidItems = new Map(
                    this.dataItems.map(item => {
                        const isInvalid = Array.from(this.validators.entries())
                            .some(([_, predicate]) => !predicate(item));
                        return ([item.id, !isInvalid]);
                    })
                );
                runInAction(() => {
                    this.selectedItems.replace(nextSelectedItems);
                    this.validItems.replace(nextValidItems);
                });
            }),
            // Each time the filters are being updated, we must clear up the selection.
            // In case we have previously selected an item and then filtered it out,
            // The filtered collection will not show it, but it will still be in the selection
            // and therefore the actions, if any are called, will be executed on this element as well
            observe(this.filters, () => this.selection.deselect())
        ];
    }

    get context () {
        return Object.assign(this.dataContext, { collectionStore: this });
    }

    cleanUp () {
        this.disposers && this.disposers.forEach(d => d());
        this.index.cleanUp();
    }

    createCollectionItem = (item) => {
        return new Item(item, this.actions, this.context);
    };

    get selection () {
        const selectedIDs = Array.from(this.selectedItems.entries())
            .filter(([_, isSelected]) => isSelected)
            .map(([id, _]) => id);
        return ItemSelection.create(
            this.items.filter(item => selectedIDs.includes(item.id)),
            this.context
        );
    }

    get items () {
        return this.dataItems
            .map(item => this.createCollectionItem(item))
            .filter(item => {
                return !Array.from(this.filters.entries())
                    .some(([_, predicate]) => !predicate(item));
            });
    }

    onClickItem = (ev, item, callback) => {
        const mode = this.mobileMode
            ? selectionModes[SelectionMode.MOBILE]
            : selectionModes[SelectionMode.fromEvent(ev)];
        mode.onClickItem && mode.onClickItem.call(this, item, callback);
    };

    onRightClick = (ev, item) => {
        const mode = selectionModes[SelectionMode.fromEvent(ev)];
        mode.onRightClick && mode.onRightClick.call(this, item);
    };

    enterMobileSelectionMode = (item) => {
        this.mobileSelectionMode = true;
        this.selection.deselect();
        this.items.find(i => i.data.id === item.id).select();
    };

    exitMobileSelectionMode = () => {
        this.mobileSelectionMode = false;
        this.selection.deselect();
    };

    onClickContainer = () => {
        this.selection.deselect();
        this.mobileSelectionMode && this.exitMobileSelectionMode();
    };

    selectItem = id => this.selectedItems.set(id, true);
    deselectItem = id => this.selectedItems.set(id, false);
    markValid = id => this.validItems.set(id, true);
    markInvalid = id => this.validItems.set(id, false);

    selectAll = () => {
        this.selectedItems.replace(new Map(
            Array.from(this.selectedItems.keys()).map(id => ([id, true]))
        ));
    };
}

export default CollectionStore;
