import React from 'react';
import PropTypes from 'prop-types';
import { SlideType } from '../../models/slide';
import BoardModel from '../../models/board';

import Board2 from './Board2';
import Board from './Board';
import TourViewer from './Tour';

const BoardPreview = (props) => {
    return (
        props.preview.slide.version === 2
            ? <Board2 {...props} />
            : <Board {...props} />
    );
};

BoardPreview.propTypes = {
    preview: PropTypes.shape({
        slide: PropTypes.instanceOf(BoardModel)
    })
};

export default {
    [SlideType.BOARD]: BoardPreview,
    [SlideType.TOUR]: TourViewer
};
