import pubsub from '../base/pubsub';

const cache = new Map();
const observedKeys = new Set();

const withUserPrefix = key => `${Settings.user.login}.${key}`;

function storageGet (key, defaultVal = null) {
    if (!cache.has(key)) {
        cache.set(key, window.store.get(withUserPrefix(key), defaultVal));
    }
    return cache.get(key);
}

function storageSet (key, value) {
    cache.set(key, value);
    window.store.set(withUserPrefix(key), value);
    if (observedKeys.has(key)) {
        pubsub.publish(`storage.${key}`, value);
    }
}

export default {
    set: storageSet,
    get: storageGet
};
