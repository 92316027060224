function hideSplashScreen () {
    const splash = document.querySelector('#splash-screen');

    if (splash) {
        const fadeEffect = setInterval(function () {
            if (!splash.style.opacity) {
                splash.style.opacity = 1;
            }
            if (splash.style.opacity < 0.1) {
                clearInterval(fadeEffect);
                splash.style.display = 'none';
            } else {
                splash.style.opacity -= 0.01;
            }
        }, 5);
    }
}

export default hideSplashScreen;
