import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import KeyHandler from '../../Components/KeyHandler';
import CollectionStore from '../Stores/Collection';

class CollectionKeyHandler extends React.Component {
    constructor () {
        super();

        this.KEY_EVENTS = {
            select: {
                predicate: event => event.which === 65 && event.ctrlKey,
                handler: this.selectAll
            },
            delete: {
                predicate: event => event.which === 46,
                handler: this.deleteSelected
            }
        };
    }

    selectAll = () => {
        this.props.collectionStore.selectAll();
        return false;
    };

    deleteSelected = () => {
        this.props.collectionStore.selection.doAction('delete');
    };

    render () {
        return (
            <React.Fragment>
                <KeyHandler keyEvents={this.KEY_EVENTS} extend={this.props.extend}/>
                { this.props.children }
            </React.Fragment>
        );
    }
};

CollectionKeyHandler.propTypes = {
    collectionStore: PropTypes.instanceOf(CollectionStore),
    extend: PropTypes.bool
};

CollectionStore.defaultProps = {
    extend: false
};

export default observer(CollectionKeyHandler);
