import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { EditorState, convertFromRaw, convertToRaw, CompositeDecorator } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';

import { Menu } from '@vectorworks/vcs-ui/dist/lib/Menu/Menu';
import { RGBColor } from '@vectorworks/vcs-ui/dist/lib/ColorPicker/utils';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';

import {
    bindMenu,
    bindTrigger,
    usePopupState
} from 'material-ui-popup-state/hooks';

import {
    Bold,
    Italic,
    Underline,
    UnorderedList,
    OrderedList,
    AlignLeft,
    AlignCenter,
    AlignRight,
    HeadlineOne,
    HeadlineTwo,
    HeadlineThree,
    StyledToolbar
} from './Buttons';
import { LINK_DECORATOR } from './decorators';
import LinkPopup from './LinkPopup';
import { insertLink, changeColor, blockStyleFn, getAlignment, setAlignment as _sa } from '../../../../Editor/Board/TextEditor/editor-funcs';
import { StyledEditor } from './StyledEditor';

import ColorPicker from '../../../../common/ColorPicker/ColorPicker';

const toolbarPlugin = createToolbarPlugin();
const { Toolbar } = toolbarPlugin;
const plugins = [toolbarPlugin];
export const decorator = new CompositeDecorator([LINK_DECORATOR]);

const S = {
    Wrapper: styled.div`
        display: flex;
        flex-direction: column;
    `,
    Separator: styled.div`
        border-left: 1px solid var(--border-color);
        height: 20px;
    `,
    TextColorTool: styled(IconButton)`
        height: 32px;
        padding: 0 6px;
        display: block;

        > svg {
            width: 32px;
            height: 32px;
            display: block;
            fill: var(--text-primary-color);
        }
    `
};

const EditorTool = ({ id, onClick, children, ...rest }) =>
    <button onClick={onClick} {...rest}>
        <div id={id}>
            {children}
        </div>
    </button>;

EditorTool.propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    analitycs: PropTypes.object.isRequired
};

const TextColorTool = ({
    onChange,
    color,
    popupId,
    ...rest
}) => {
    const popup = usePopupState({ variant: 'popper', popupId });
    const [anchorEl, setAnchorEl] = React.useState(null);

    const createTriggerProps = () => {
        const init = bindTrigger(popup);
        const onClick = (e) => {
            init.onClick();
            setAnchorEl(e.currentTarget);
        };
        return {
            ...init,
            onClick
        };
    };

    const createMenuProps = () => {
        const init = bindMenu(popup);
        const onClose = () => {
            init.onClose();
            setAnchorEl(null);
        };

        return {
            ...init,
            onClose,
            anchorEl
        };
    };

    return (
        <div>
            <S.TextColorTool {...createTriggerProps()}>
                <svg
                    id='text-color'
                    x='0px' y='0px'
                    viewBox='0 0 32 32'
                    xmlSpace='preserve'
                    {...rest}
                >
                    <path d='M17.6,17.1h-3.3L13.8,19h-2.6l3.6-10h2.3l3.7,10h-2.6L17.6,17.1z M14.9,15.3h2.1L16,11.8L14.9,15.3z'/>
                    <path fill={RGBColor.isAutoColor(color) ? 'red' : color.toCSS()} d='M11,20h10v2H11V20z'/>
                </svg>
            </S.TextColorTool>

            <Menu
                {...createMenuProps()}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <ColorPicker
                    selectedColor={color}
                    onChange={onChange}
                />
            </Menu>
        </div>
    );
};

TextColorTool.propTypes = {
    onChange: PropTypes.func,
    color: PropTypes.instanceOf(RGBColor),
    popupId: PropTypes.string
};

function TextPinEditor ({ editorState, customStyleMap, onChange, className, ...editorPropTypes }) {
    const editorRef = React.createRef();
    const block = editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey());
    const inlineStyles = editorState.getCurrentInlineStyle();

    const handleInsertLink = ({ title, linkState }) => {
        const { link, inNewTab } = linkState;
        const newEditorState = insertLink(editorState, link, title, inNewTab);
        onChange(newEditorState);
    };

    const focus = () => {
        editorRef.current.focus();
    };

    const getSelectedColor = () => {
        const color = inlineStyles.toJS().find(item => item.startsWith('color'));
        return color ? RGBColor.fromStyleClass(color) : RGBColor.AUTO_COLOR;
    };

    const onChangeColor = color => {
        focus();

        if (RGBColor.isAutoColor(color)) {
            const newEditorState = changeColor(
                RGBColor.AUTO_COLOR,
                { editorState, customStyleMap }
            );
            onChange(newEditorState);
        } else {
            const rgbaColorString = color.toDraftJsCustomStyle();
            const colorStyleName = color.toStyleClass();
            const newCustomStyleMap = {
                ...customStyleMap,
                [colorStyleName]: rgbaColorString
            };

            const newEditorState = changeColor(
                color,
                { editorState, customStyleMap: newCustomStyleMap }
            );
            onChange(newEditorState, newCustomStyleMap);
        }
    };

    const setAlignment = ({ alignment }) => {
        const aligned = _sa(editorState, alignment);
        return onChange(aligned);
    };

    const isAligned = (alignment) => {
        return getAlignment(editorState) === alignment;
    };

    return (
        <S.Wrapper className={className}>
            <StyledEditor onClick={focus}>
                <Editor
                    editorState={editorState}
                    customStyleMap={customStyleMap}
                    onChange={onChange}
                    plugins={plugins}
                    decorators={[decorator]}
                    placeholder={gettext('Write your text here.')}
                    ref={editorRef}
                    blockStyleFn={blockStyleFn}
                    {...editorPropTypes}
                />
            </StyledEditor>
            {
                !editorPropTypes.readOnly && (
                    <Toolbar>
                        {
                            externalProps => (
                                <StyledToolbar>
                                    <Bold toggled={inlineStyles.has('BOLD')} {...externalProps} />
                                    <Italic toggled={inlineStyles.has('ITALIC')} {...externalProps} />
                                    <Underline toggled={inlineStyles.has('UNDERLINE')} {...externalProps} />
                                    <TextColorTool
                                        onChange={onChangeColor}
                                        color={getSelectedColor()}
                                        popupId='text-editor__color'
                                        disabled={false}
                                        id='color-tool'
                                        data-what='text-style-tool'
                                        data-which='change-text-pin-color'
                                        ga-action='Presentation_Text_Pin_Change_Color'
                                        ga-label='Click_Text_Pin_Change_Color'
                                    />

                                    <S.Separator />
                                    <AlignLeft {...externalProps} toggled={isAligned('left')} setAlignment={setAlignment} />
                                    <AlignCenter {...externalProps} toggled={isAligned('center')} setAlignment={setAlignment} />
                                    <AlignRight {...externalProps} toggled={isAligned('right')} setAlignment={setAlignment} />

                                    <S.Separator />
                                    <UnorderedList toggled={block.includes('unordered-list-item')} {...externalProps} />
                                    <OrderedList toggled={block.includes('ordered-list-item')} {...externalProps} />

                                    <S.Separator />
                                    <HeadlineOne toggled={block.includes('header-one')} {...externalProps} />
                                    <HeadlineTwo toggled={block.includes('header-two')} {...externalProps} />
                                    <HeadlineThree toggled={block.includes('header-three')} {...externalProps} />

                                    <S.Separator />
                                    <LinkPopup editorState={editorState} onSubmit={handleInsertLink} />
                                </StyledToolbar>
                            )
                        }
                    </Toolbar>
                )
            }
        </S.Wrapper>
    );
}

TextPinEditor.propTypes = {
    editorState: PropTypes.instanceOf(EditorState),
    customStyleMap: PropTypes.object,
    className: PropTypes.string,
    onChange: PropTypes.func
};

TextPinEditor.defaultProps = {
    customStyleMap: {},
    onChange: () => {},
    editorProps: {}
};

export {
    TextPinEditor,
    convertFromRaw, convertToRaw
};
