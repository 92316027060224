import React, { Component } from 'react';

import { action, observable, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { commonPropTypes } from './utils';

import HEICCanvas from './HEICCanvas';

const ZOOM_LEVEL = {
    MIN: 0.5,
    MAX: 15
};

const ZOOM_STEP = 0.2;

const OFFSET_DEFAULT = {
    x: 0,
    y: 0
};

class ImageViewer extends Component {
    name = 'ImageViewer';
    containerRef = React.createRef();
    zoom = 1;
    offset = OFFSET_DEFAULT;
    initialOffset = OFFSET_DEFAULT;
    draggable = false;
    offsetRange = OFFSET_DEFAULT;
    clientOffset = {
        x: undefined,
        y: undefined
    };

    realSize = 1;
    percentage = 0;

    onLoad = () => {
        setTimeout(() => {
            this.props.store.endLoading();
            this.recalculatePercentage();
        }, 100);
    };

    recalculatePercentage = () => {
        this.percentage = Math.round((this.containerRef.current.getBoundingClientRect().height * 100) / this.containerRef.current.naturalHeight);
    };

    onWheel = e => {
        const delta = e.nativeEvent.deltaY;
        if (delta > 0) {
            this.zoomOut();
        } else if (delta < 0) {
            this.zoomIn();
        }
    };

    zoomIn = () => {
        this.zoom = Math.min(this.zoom + ZOOM_STEP, ZOOM_LEVEL.MAX);
        setTimeout(this.recalculatePercentage, 1);
    };

    zoomOut = () => {
        this.zoom = Math.max(1, this.zoom - ZOOM_STEP);
        this.zoom <= 1 && this.resetOffset();
        setTimeout(this.recalculatePercentage, 1);
    };

    resetZoom = () => {
        this.zoom = 1;
    };

    onMouseDown = e => {
        if (this.zoom === 1) {
            return;
        }
        this.clientOffset = {
            x: e.clientX,
            y: e.clientY
        };
        this.initialOffset = this.offset;
        this.draggable = true;
    };

    onMove = e => {
        if (!e.clientX && !e.clientY || !this.draggable) {
            return;
        }

        const offset = {
            x: e.clientX - this.clientOffset.x,
            y: e.clientY - this.clientOffset.y
        };

        this.offset = {
            x: (offset.x / this.zoom) + this.initialOffset.x,
            y: (offset.y / this.zoom) + this.initialOffset.y
        };
    };

    onMouseUp = e => {
        this.draggable = false;
    };

    setActualPixelsSize = () => {
        if (this.percentage === 100) {
            this.zoom = 1;
            setTimeout(this.recalculatePercentage, 1);
        } else {
            this.zoom = this.containerRef.current.naturalHeight / this.containerRef.current.height;
            setTimeout(this.recalculatePercentage, 1);
        }
    };

    resetOffset = () => {
        this.offset = OFFSET_DEFAULT;
    };

    isSVG = () => this.props.store.file.file.extension.toLowerCase() === '.svg' ? 'image-svg' : '';

    get isHEIC () {
        return this.props.store?.file?.file?.fileType?.type === 'heic';
    }

    preventDefault = e => e.preventDefault();

    constructor (props) {
        super(props);

        makeObservable(this, {
            zoom: observable,
            offset: observable,
            initialOffset: observable,
            draggable: observable,
            offsetRange: observable,
            clientOffset: observable,
            realSize: observable,
            percentage: observable,
            recalculatePercentage: action,
            zoomIn: action,
            zoomOut: action,
            onMouseDown: action,
            onMove: action,
            onMouseUp: action,
            setActualPixelsSize: action,
            resetOffset: action,
            isHEIC: computed
        });
    }

    render () {
        const transform = `scale3d(${this.zoom}, ${this.zoom}, 1) translate3d(${this.offset.x}px, ${this.offset.y}px, 0px)`;
        const canBeDragged = this.zoom === 1 ? '' : 'draggable';
        const isBeingDragged = this.draggable ? 'dragging' : '';

        return (
            <div
                className='fileview-component-loader image-viewer'
                data-what='file-viewer'
                {...this.props.controllerTogglers}
                onWheel={this.onWheel}
                onMouseUp={this.onMouseUp}
                onMouseOut={this.onMouseUp}
                ref={frame => { this.frame = frame; }}
            >
                { this.props.children }

                {this.isHEIC
                    ? <HEICCanvas
                        store={this.props.store}
                        zoom={this.zoom}
                        resetZoom={this.resetZoom}
                        offset={this.offset}
                        resetOffset={this.resetOffset}
                        draggable={this.draggable}
                        preventDefault={this.preventDefault}
                        onMouseMove={this.onMove}
                        onMouseDown={this.onMouseDown}
                        onLoad={this.onLoad}
                        containerRef={this.containerRef}
                    />
                    : <img
                        className={`regular-image ${this.isSVG()} ${canBeDragged} ${isBeingDragged}`}
                        style={{ transform, ...(this.props.store.loading ? { display: 'none' } : {}) }}
                        src={this.props.sourceUrl || this.props.store.file.sourceUrl}
                        onLoad={this.props.onLoad || this.onLoad}
                        onDragStart={this.preventDefault}
                        onMouseMove={this.onMove}
                        onMouseDown={this.onMouseDown}
                        ref={this.containerRef}
                    />}
            </div>
        );
    }
};

export default observer(ImageViewer);

ImageViewer.propTypes = {
    ...commonPropTypes,
    onLoad: PropTypes.func,
    sourceUrl: PropTypes.string
};
