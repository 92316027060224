import { autorun, observe, extendObservable } from 'mobx';
import { promiseUtils } from '../../js/lib';
import { Rotation } from './math-funcs';
import { parseProps, parseForProps, elementListRenderer, createTemplate, htmlToElement } from './bind';
import registerVrModeUi from './vr-mode-ui';
import registerLookControls from './look-controls';
import registerActivityMonitor from './activity-monitor';
import { waitForNode } from '../../js/lib/utils';
import Model from '../../js/iboards/FileViewers/Tour/PinEditors/Appearance/ShapeWithIcon/model';

const eventListenerMixin = {
    init () {
        this.setEventListeners();
    },
    update (oldData) {
        this.updateEventListeners();
    },
    play () {
        this.updateEventListeners();
    },
    pause () {
        this.removeEventListeners();
    },
    remove () {
        this.removeEventListeners();
    },
    updateEventListeners () {
        if (!this.el.isPlaying) { return; }
        this.removeEventListeners();
        this.setEventListeners();
    },
    setEventListeners () {
        Object.keys(this.eventHandlers).forEach(eventName => {
            this.el.addEventListener(eventName, this.eventHandlers[eventName]);
        });
    },
    removeEventListeners () {
        Object.keys(this.eventHandlers).forEach(eventName => {
            this.el.removeEventListener(eventName, this.eventHandlers[eventName]);
        });
    }
};

const makeComponents = aframe => {
    return {
        pin: {
            ...eventListenerMixin,
            schema: {
                id: { type: 'number' }
            },
            eventHandlers: {
                'raycaster-intersected': function (ev) {
                    if (!this.sceneEl.is('vr-mode')) return;
                    this.confirmingIntersectionTimout = setTimeout(
                        () => this.emit(
                            'pin-click',
                            { event: ev, id: this.components.pin.data.id }
                        ),
                        2000
                    );
                },
                'raycaster-intersected-cleared': function (ev) {
                    this.confirmingIntersectionTimout && clearTimeout(
                        this.confirmingIntersectionTimout
                    );
                },
                click (ev) {
                    const pin = this.components.pin;
                    pin.restoreCursor();
                    this.emit(
                        'pin-click',
                        {
                            event: ev,
                            id: this.components.pin.data.id
                        }
                    );
                },
                mousedown (ev) {
                    const pin = this.components.pin;
                    this.emit('pin-mousedown', { event: ev, id: pin.data.id });
                },
                mouseup (ev) {
                    const pin = this.components.pin;
                    this.emit('pin-mouseup', { event: ev, id: pin.data.id });
                },
                mouseenter (ev) {
                    const pin = this.components.pin;
                    pin.setPointerCursor();

                    const highlight = pin.el.getElementsByClassName('pin__highlight');
                    if (highlight.length > 0) {
                        highlight[0].getChildEntities().forEach(e => e.setAttribute('opacity', 0.5));
                    }

                    this.emit(
                        'pin-mouseenter',
                        { event: ev, id: pin.data.id }
                    );
                },
                mouseleave (ev) {
                    const pin = this.components.pin;
                    pin.restoreCursor();

                    const highlight = pin.el.getElementsByClassName('pin__highlight');
                    if (highlight.length > 0) {
                        highlight[0].getChildEntities().forEach(e => e.setAttribute('opacity', 0));
                    }
                    this.emit(
                        'pin-mouseleave',
                        { event: ev, id: pin.data.id }
                    );
                }
            },
            setPointerCursor () {
                const canvas = this.el.sceneEl.canvas;
                this.savedCursorClass = canvas.classList.contains('a-grab-cursor') && 'a-grab-cursor';
                window.requestAnimationFrame(() => {
                    canvas.classList.remove('a-grab-cursor');
                    canvas.classList.add('cursor-pointer');
                });
            },
            restoreCursor () {
                const canvas = this.el.sceneEl.canvas;
                window.requestAnimationFrame(() => {
                    canvas.classList.remove('cursor-pointer');
                    this.savedCursorClass &&
                        canvas.classList.add(this.savedCursorClass);
                    this.savedCursorClass = undefined;
                });
            }
        },
        svgload: {
            schema: {
                canvasSelector: { type: 'string' },
                image: { type: 'string' },
                imageSize: { type: 'number' },
                fillStyle: { type: 'string' }
            },
            update () {
                const { canvasSelector, image, imageSize, fillStyle } = this.data;

                waitForNode(() => document.querySelector(canvasSelector))
                    .then(canvas => {
                        const canvasSize = 192;
                        canvas.width = canvasSize;
                        canvas.height = canvasSize;
                        const ctx = canvas.getContext('2d');

                        // Clear the canvas
                        ctx.clearRect(0, 0, canvas.width, canvas.height);
                        ctx.beginPath();

                        // On setting the image to None, the canvas should still be cleared.
                        if (['', '0', 'null', 'undefined'].includes(image)) return;
                        if (!Model.supportsImage(image)) return;

                        const i = document.createElement('span');
                        i.setAttribute('class', `icon-${image}`);
                        document.body.appendChild(i);

                        // get the styles for the icon you just made
                        const iStyles = window.getComputedStyle(i);
                        const iBeforeStyles = window.getComputedStyle(i, ':before');

                        const fontFamily = iStyles.getPropertyValue('font-family');
                        const fontWeight = iStyles.getPropertyValue('font-weight');

                        /*
                            Intervals: imageSize[1...12] <=> fontSize[96...192]. Max size is the canvasSize.
                            For bigger imageSize, offset is 0 from the beginning of the canvas.
                            The smaller the icon, the bigger padding we need around it, inside the canvas.
                        */
                        const offset = 12 - imageSize;
                        const padding = offset / 2;

                        const fontSize = 192 - offset;

                        const canvasFont = `${fontWeight} ${fontSize}px ${fontFamily}`; // should be something like: '900 40px "Font Awesome 5 Pro"'
                        const icon = String.fromCodePoint(iBeforeStyles.getPropertyValue('content').codePointAt(1)); // codePointAt(1) because the first character is a double quote

                        ctx.font = canvasFont;
                        ctx.fillStyle = fillStyle;
                        ctx.textAlign = 'center';
                        ctx.fillText(icon, canvasSize / 2, canvasSize - padding);
                        i.remove();
                    });
            }
        },
        bind: {
            schema: { type: 'string' },
            multiple: true,
            init () {
                this.disposers = [];
            },
            update () {
                this.cleanUp();
                const component = this.id;
                const props = parseProps(this.data);
                this.disposers = [];
                props.forEach((prop) => {
                    this.disposers.push(
                        autorun(() => {
                            const val = prop.eval(aframe.store);
                            if (val === undefined || val === null) {
                                if (prop.prop) {
                                    this.el.removeAttribute(component, prop.prop);
                                } else if (!prop.prop && props.length === 1) {
                                    this.el.removeAttribute(component);
                                }
                            } else {
                                this.el.setAttribute(component, val);
                            }
                        }, { name: `bind__${component}__${prop.prop}` })
                    );
                });
            },
            tick () {},
            remove () {
                this.cleanUp();
            },
            pause () {},
            play () {},
            cleanUp () {
                this.disposers.forEach(disposer => disposer());
                this.disposers = [];
            }
        },
        'bind-for': {
            schema: { type: 'string' },
            init () {},
            update () {
                this.cleanUp();
                const props = parseForProps(this.data);
                const template = createTemplate(this.el.getAttribute('template'));
                const renderer = elementListRenderer(
                    this.el,
                    item => template.render(item, { [props.name]: item.id })
                );
                this.propDisposer = observe(aframe.store, props.prop, change => {
                    if (change.type === 'update') {
                        renderer.removeAll();
                        change.newValue && renderer.add(change.newValue);
                    } else if (change.type === 'splice') {
                        if (change.removedCount) {
                            renderer.remove(change.removed, change.index);
                        }
                        if (change.addedCount) {
                            renderer.add(change.added, change.index);
                        }
                    }
                }, true);
            },
            tick () {},
            remove () {
                this.cleanUp();
            },
            pause () {},
            play () {},
            cleanUp () {
                this.arrayDisposer && this.arrayDisposer();
                this.propDisposer && this.propDisposer();
            }
        },
        'inject-template': {
            schema: { type: 'string' },
            init () {},
            update () {
                const template = createTemplate(this.data);
                if (this.el.firstChild) this.el.removeChild(this.el.firstChild);
                const node = template.render(null, {});
                node && this.el.appendChild(node);
            },
            tick () {},
            remove () {},
            pause () {},
            play () {},
            cleanUp () {
                this.propDisposer && this.propDisposer();
            }
        },
        'cursor-switch': {
            schema: {
                template: { type: 'string' },
                templateVR: { type: 'string' },
                selector: { type: 'string', default: '.panorama-cursor' }
            },
            init () {
                this.onEnterVR = this.onEnterVR.bind(this);
                this.onExitVR = this.onExitVR.bind(this);
            },
            update () {
                this.removeCursor();
                if (this.el.sceneEl.is('vr-mode')) {
                    this.addCursor(this.data.templateVR);
                } else {
                    this.addCursor(this.data.template);
                }
            },
            tick () {},
            remove () {
                this.removeEventListeners();
            },
            pause () {
                this.removeEventListeners();
            },
            play () {
                this.addEventListeners();
            },
            addEventListeners () {
                this.el.sceneEl.addEventListener('enter-vr', this.onEnterVR);
                this.el.sceneEl.addEventListener('exit-vr', this.onExitVR);
            },
            removeEventListeners () {
                this.el.sceneEl.removeEventListener('enter-vr', this.onEnterVR);
                this.el.sceneEl.removeEventListener('exit-vr', this.onExitVR);
            },
            onEnterVR (ev) {
                this.removeCursor();
                this.addCursor(this.data.templateVR);
            },
            onExitVR (ev) {
                this.removeCursor();
                this.addCursor(this.data.template);
            },
            removeCursor () {
                const cursorEl = this.el.querySelector(this.data.selector);
                cursorEl && this.el.removeChild(cursorEl);
            },
            addCursor (template) {
                const cursorEl = htmlToElement(this.el.getAttribute(template));
                this.el.appendChild(cursorEl);
            }
        },
        'sky-loader': {
            schema: {
                imageProp: { type: 'string' }
            },
            templates: {
                SKY: '<a-sky></a-sky>',
                ANIMATION: `<a-animation
                    attribute="opacity"
                    begin="change-sky-request"
                    dur="500"
                    fill="forwards"
                    easing="ease-in-out-quad"
                    from="1"
                    to="0"
                    repeat="0">
                </a-animation>`
            },
            init () {
                this.imageSrc = null;
                this.animatedImageSrc = null;
                extendObservable(this, {
                    trackingEnabled: true
                });
                this.setEventListeners();
            },
            update () {
                this.cleanUp();
                this.updateEventListeners();
                this.imageSrc = this.getImage().src;
                this.setInitialSky();
                this.disposer = autorun(() => {
                    if (!this.trackingEnabled) return;
                    const image = this.getImage();
                    if (image.state === 'ready' && image.src !== this.imageSrc) {
                        this.imageSrc = image.src;
                        if (this.el.sceneEl.is('vr-mode')) {
                            const currentSky = this.el.querySelector('a-sky');
                            currentSky && currentSky.setAttribute('src', this.imageSrc);
                        } else if (!this.animatedImageSrc) {
                            this.startLoading();
                        }
                    }
                }, { name: 'sky-loader' });
            },
            tick () {},
            remove () {
                this.removeEventListeners();
                this.cleanUp();
            },
            pause () {
                this.removeEventListeners();
            },
            play () {
                this.updateEventListeners();
            },
            cleanUp () {
                this.disposer && this.disposer();
                this.disposer = null;
                const skyEls = Array.from(this.el.querySelectorAll('a-sky'));
                skyEls.forEach(skyEl => this.el.removeChild(skyEl));
                this.animatedImageSrc = null;
            },
            startLoading () {
                aframe.store.imageShown = false;
                this.animatedImageSrc = this.imageSrc;
                const skyEl = this.addNewSky();
                skyEl.addEventListener(
                    'materialtextureloaded',
                    () => {
                        /*
                        * ensure that the animation will start after the next
                        * image is rendered; it will actually start after around 400ms
                        */

                        !this.el.sceneEl.is('vr-mode') &&
                            setTimeout(() => this.startAnimation(), 50);
                    }
                );
            },
            createSky (props) {
                const skyEl = htmlToElement(this.templates.SKY);
                Object.keys(props).forEach(k => skyEl.setAttribute(k, props[k]));
                return skyEl;
            },
            removeOldSky () {
                const [nextSkyEl, currentSkyEl] = Array.from(this.el.querySelectorAll('a-sky'));
                this.el.removeChild(currentSkyEl);
                const radius = nextSkyEl.getAttribute('radius');
                nextSkyEl.setAttribute('radius', (Number(radius) / 1.1).toString());
                this.addAnimation(nextSkyEl);
                this.el.sceneEl.emit('sky-loaded');
                if (this.animatedImageSrc !== this.imageSrc) {
                    this.startLoading();
                } else {
                    this.animatedImageSrc = null;
                    aframe.store.imageShown = true;
                    this.trackingEnabled = false;
                }
            },
            setInitialSky () {
                const props = {
                    ...this.getSkyProps(),
                    ...(this.imageSrc ? { src: this.imageSrc } : { color: '#fff' })
                };
                const skyEl = this.createSky(props);
                this.el.appendChild(skyEl);
                this.addAnimation(skyEl);
            },
            addNewSky () {
                const props = {
                    ...this.getSkyProps(),
                    src: this.animatedImageSrc
                };
                props.radius = (props.radius * 1.1).toString();
                const skyEl = this.createSky(props);
                this.el.insertBefore(skyEl, this.el.querySelector('a-sky'));
                return skyEl;
            },
            getImage () {
                return aframe.store[this.data.imageProp] || {};
            },
            getSkyProps () {
                return aframe.utils.styleParser.parse(this.el.getAttribute('skyProps'));
            },
            addAnimation (el) {
                const animation = htmlToElement(this.templates.ANIMATION);
                animation.addEventListener('animationend', () => {
                    console.log('end animation', new Date().getTime());
                    this.removeOldSky();
                });
                el.appendChild(animation);
            },
            startAnimation () {
                console.log('start animation', new Date().getTime());
                this.el.querySelector('a-animation').emit('change-sky-request');
            },
            eventHandlers: {
                'start-sky-loading': function (ev) {
                    this.components['sky-loader'].trackingEnabled = true;
                },
                'stop-sky-loading': function (ev) {
                    this.components['sky-loader'].trackingEnabled = false;
                }
            },
            updateEventListeners () {
                if (!this.el.isPlaying) { return; }
                this.removeEventListeners();
                this.setEventListeners();
            },
            setEventListeners () {
                Object.keys(this.eventHandlers).forEach(eventName => {
                    this.el.addEventListener(eventName, this.eventHandlers[eventName]);
                });
            },
            removeEventListeners () {
                Object.keys(this.eventHandlers).forEach(eventName => {
                    this.el.removeEventListener(eventName, this.eventHandlers[eventName]);
                });
            }
        },
        'store-camera-rotation': {
            ...eventListenerMixin,
            eventHandlers: {
                'camera-rotate': function (ev) {
                    aframe.store.currentCameraRotation = {
                        x: ev.detail.x,
                        y: ev.detail.y
                    };
                }
            },
            schema: {}
        },
        'transition-manager': {
            schema: {},
            init () {
                this.place = null;
            },
            update () {
                this.cleanUp();
                this.place = this.getPlace();
                this.doCameraRotation(this.place.cameraRotation);
                this.disposer = autorun(() => {
                    const newPlace = this.getPlace();
                    if (this.place.id !== newPlace.id && this.place.asset.id !== newPlace.asset.id) {
                        const isVRMode = this.el.sceneEl.is('vr-mode');
                        if (isVRMode) {
                            this.doNormalTransition(this.getPlace, newPlace);
                        } else {
                            this.doOpacityTransition(this.place, newPlace);
                        }
                    } else if (this.place.cameraRotation !== newPlace.cameraRotation) {
                        this.doCameraRotation(newPlace.cameraRotation);
                    }
                    this.place = newPlace;
                }, { name: 'transition-manager' });
            },
            tick () {},
            remove () {
                this.cleanUp();
            },
            pause () {},
            play () {},
            cleanUp () {
                this.disposer && this.disposer();
                this.disposer = null;
            },
            getPlace () {
                const place = aframe.store.screen.place || {};
                const placeProps = aframe.store.screen.placeProps || {};
                return {
                    id: place.id || -1,
                    cameraRotation: placeProps.cameraRotation || { x: 0, y: 0, z: 0 },
                    asset: place.asset || {}
                };
            },
            doNormalTransition (oldPlace, newPlace) {
                const scene = this.el.sceneEl;
                const skyLoader = scene.querySelector('[sky-loader]');
                skyLoader.emit('start-sky-loading', {});
            },
            async doOpacityTransition (oldPlace, newPlace) {
                const scene = this.el.sceneEl;
                const cameraRig = scene.querySelector('.camera').object3D;
                const prevCameraQuaternion = cameraRig.quaternion.clone();
                const nextCameraRotation = new Rotation(newPlace.cameraRotation);
                const cameraRotated = promiseUtils.promisifyEvent(scene, 'camera-rotate');
                scene.emit('camera-rotation-request', nextCameraRotation.toObject());
                await cameraRotated;
                const sky = scene.querySelector('a-sky').object3D;
                const cameraQuaternionDiff = cameraRig.quaternion
                    .clone().multiply(prevCameraQuaternion.inverse());
                sky.quaternion.multiply(cameraQuaternionDiff);
                aframe.store.controlScene('lookControls', false);
                const skyLoaded = promiseUtils.promisifyEvent(scene, 'sky-loaded');
                const skyLoader = scene.querySelector('[sky-loader]');
                skyLoader.emit('start-sky-loading', {});
                await skyLoaded;
                aframe.store.controlScene('lookControls', true);
            },
            doCameraRotation (cameraRotation) {
                const scene = this.el.sceneEl;
                scene.emit('camera-rotation-request', new Rotation(cameraRotation).toObject());
            }
        }
    };
};

const registerCompnents = aframe => {
    const components = makeComponents(aframe);
    Object.keys(components).forEach(name => {
        aframe.registerComponent(name, components[name]);
    });
    registerVrModeUi(aframe);
    registerLookControls(aframe);
    registerActivityMonitor(aframe);
};

export {
    makeComponents as components,
    registerCompnents,
    eventListenerMixin
};
