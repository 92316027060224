import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { Avatar } from '@vectorworks/vcs-ui/dist/lib/Avatar/Avatar';
import { TextOverflow, TextSecondary } from '@vectorworks/vcs-ui/dist/lib/styles/common';
import { S as SharedStyles } from './S';

const S = {
    Comment: styled.div`
        display: flex;
        flex-direction: column;
        padding: 8px 15px;
        padding-bottom: 0;

        ${props => props.active && SharedStyles.sideStripe()};
    `,
    Header: styled.div`
        display: flex;
        align-items: center;
    `,
    Info: styled.div`
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 0 4px;
        min-width: 0;
    `,
    Actions: styled.div`
        align-self: flex-start;
        display: flex;
        align-items: center;

        > button {
            margin-left: 16px; 
        }
    `,
    Content: styled.div`
        padding: 8px 0;
        white-space: pre-wrap;
        word-break: normal;
        word-wrap: break-word;
    `
};

function Comment ({
    comment,
    children,
    menuItems = [],
    extraActions = null,
    ...rest
}) {
    const [hover, setHover] = useState(false);

    return (
        <S.Comment
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            {...rest}
        >
            <S.Header>
                <Avatar
                    name={comment.author}
                    email={comment.owner.email}
                    login={comment.owner.login}
                />
                <S.Info>
                    <TextOverflow data-what='comment-author'>
                        <b>{comment.author}</b>
                    </TextOverflow>
                    <TextOverflow>
                        <TextSecondary data-what='comment-date'>
                            {comment.published}
                        </TextSecondary>
                    </TextOverflow>
                </S.Info>

                <S.Actions onClick={e => e.stopPropagation()}>
                    {
                        menuItems.length > 0 && hover &&
                            menuItems.map(m => m)
                    }
                    {extraActions}
                </S.Actions>
            </S.Header>
            <S.Content>
                {children}
            </S.Content>
        </S.Comment>
    );
}

Comment.propTypes = {
    comment: PropTypes.object,
    extraActions: PropTypes.any,
    menuItems: PropTypes.array
};

export default Comment;
