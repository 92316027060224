import React from 'react';
import PropTypes from 'prop-types';

import S from '../styles';
import ViewerContext from '../../ViewerContext';

const Filter = ({ isActive, clear, label, children }) => {
    const { isMobile } = React.useContext(ViewerContext);
    const Component = isMobile ? S.MobileFilter : S.LgFilter;
    return (
        <Component showBorder={isActive}>
            { children }
            {
                isActive &&
                <S.ClearIcon
                    icon='close'
                    onClick={clear}
                    isMobile={isMobile}
                    data-what='ccad-filter'
                    data-which={`${label}-close`}
                />
            }
        </Component>
    );
};

Filter.propTypes = {
    isActive: PropTypes.bool,
    clear: PropTypes.func,
    label: PropTypes.string
};

export default Filter;
