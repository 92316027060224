import React from 'react';

import PropTypes from 'prop-types';

import { createEventTracker } from '~static/file-viewer/panorama/event';
import aframeStore from '~static/file-viewer/panorama/store';
import fullScreen from '../../base/fullscreen';
import isSupported from '~static/file-viewer/panorama/device';
import { delay } from '../../lib/utils';

class PanoramaScene extends React.Component {
    constructor (props) {
        super(props);
        this._sceneEventHandlers = {};
        this.container = React.createRef();
        this.scene = null;
    }

    componentDidMount () {
        this.init();
    }

    componentWillUnmount () {
        this.cleanUp();
    }

    shouldComponentUpdate () {
        return false;
    }

    init () {
        aframeStore.set(this.props.sceneStore);
        this.startEventListenerTracking();
        this.scene = this.createScene(this.container.current);
        this.props.sceneStore.setScene(this.scene);
        this.props.sceneRef(this.scene);
        this.onSceneLoad = () => {
            this.scene.removeEventListener('loaded', this.onSceneLoad);
            this.scene && this.setupScene(this.scene);

            /*
                Handle Safari CSS glitch - on rendering of A-frame, Safari seems to lose the viewport
                for a moment and absolutely position: fixed elements get displaced. Jira issue: VCS-29240
            */
            if (Settings.device.browser === 'Safari') {
                const repaintCSS = async () => {
                    const entries = document.querySelectorAll('.__forceCSSRerender');
                    entries.forEach(e => { e.firstChild.style.opacity = 0.99; });
                    await delay();
                    entries.forEach(e => { e.firstChild.style.opacity = 1; });
                };
                repaintCSS();
            }
        };
        this.scene.addEventListener('loaded', this.onSceneLoad);
    }

    cleanUp () {
        this.scene && this.cleanupScene(this.scene);
        this.stopEventListenerTracking();
        this.removeScene(this.container.current);
        this.scene = null;
        this.props.sceneStore.clearScene();
        this.props.sceneRef(null);
        aframeStore.reset();
        // clear dom elements from AFRAME systems
        for (const component of Object.values(window.AFRAME.components)) {
            const system = component.Component.prototype.system;
            if (system) {
                delete system.el;
                delete system.sceneEl;
                for (const [name, val] of Object.entries(system)) {
                    if (val instanceof HTMLElement) { // eslint-disable-line
                        delete system[name];
                    }
                }
            }
        }
    }

    startEventListenerTracking () {
        this.windowTracker = createEventTracker(
            window,
            [
                'resize', 'orientationchange', 'load', 'message', 'keyup', 'keydown',
                'vrdisplaypresentchange', 'vrdisplayactivate', 'vrdisplaydeactivate',
                'vrdisplaydisconnect', 'vrdisplaypointerrestricted',
                'vrdisplaypointerunrestricted'
            ]
        );
        this.windowTracker.start();
        this.documentTracker = createEventTracker(
            document,
            ['fullscreenchange', 'mozfullscreenchange', 'webkitfullscreenchange']
        );
        this.documentTracker.start();
    }

    stopEventListenerTracking () {
        for (const tracker of [this.windowTracker, this.documentTracker]) {
            tracker.stop();
            tracker.removeLeakedListeners();
        }
        this.windowTracker = null;
        this.documentTracker = null;
    }

    setupScene (scene) {
        const vrPossible = scene && isSupported() &&
            (scene.isMobile ||
                scene.checkHeadsetConnected &&
                    scene.checkHeadsetConnected());
        if (!this.props.sceneStore.controller.vrMode && vrPossible) {
            this.props.sceneStore.controlScene('vrMode', true);
        }
        this.setEventListeners(scene);
    }

    cleanupScene (scene) {
        this.removeEventListeners(scene);
        scene.renderer.dispose();
        delete scene.renderer.context;
        delete scene.renderer.domElement;
        delete scene.renderer;
        delete scene.camera;
        delete scene.sceneEl;
        delete scene.parentEl;
        const canvas = scene.canvas;
        try {
            const gl = canvas.getContext('webgl');
            gl.getExtension('WEBGL_lose_context').loseContext();
        } catch (error) {
            console.log('Error losing WebGL context', error);
        }
        canvas.width = 1;
        canvas.height = 1;
        canvas.clearEventListeners();
        this.scene.clearEventListeners();
    }

    resetScene = () => {
        this.cleanUp();
        this.init();
    };

    forEachCanvasEvent (scene, callback) {
        const canvas = scene.querySelector('canvas');
        Object.keys(this.props.eventHandlers)
            .filter(event => event.startsWith('canvas-'))
            .forEach(event => {
                const match = /canvas-(.+)/.exec(event);
                const domEvent = match[1];
                callback(canvas, event, domEvent);
            });
    }

    createSceneEventHandler (scene, event) {
        this._sceneEventHandlers[event] = this._sceneEventHandlers[event] ||
            ((...args) => this.props.eventHandlers[event](...args));
        return this._sceneEventHandlers[event];
    }

    setEventListeners (scene) {
        this.forEachCanvasEvent(scene, (canvas, event, domEvent) => {
            canvas.addEventListener(domEvent, this.createSceneEventHandler(scene, event));
        });
        Object.keys(this.props.eventHandlers).forEach(event => {
            scene.addEventListener(event, this.createSceneEventHandler(scene, event));
        });
        scene.addEventListener('enter-vr', this.promoteCanvasOnFullScreen);
        scene.addEventListener('exit-vr', this.restoreCanvas);
        scene.addEventListener('exit-vr', this.resetScene);
    }

    removeEventListeners (scene) {
        this.forEachCanvasEvent(scene, (canvas, event, domEvent) => {
            canvas.removeEventListener(domEvent, this.createSceneEventHandler(scene, event));
        });
        Object.keys(this.props.eventHandlers).forEach(event => {
            scene.removeEventListener(event, this.createSceneEventHandler(scene, event));
        });
        scene.removeEventListener('enter-vr', this.promoteCanvasOnFullScreen);
        scene.removeEventListener('exit-vr', this.restoreCanvas);
        scene.removeEventListener('exit-vr', this.resetScene);
    }

    promoteCanvasOnFullScreen = (ev) => {
        if (fullScreen.fake) {
            this.scene.canvas.classList.add('canvas-fullscreen');
        }
    };

    restoreCanvas = (ev) => {
        if (fullScreen.fake) {
            this.scene.canvas.classList.remove('canvas-fullscreen');
        }
    };

    createScene (container) {
        const html = this.props.sceneTemplate(this.props.sceneProps);
        container.innerHTML = html.trim();
        return container.firstChild;
    }

    removeScene (container) {
        while (container.firstChild) {
            container.removeChild(container.firstChild);
        }
    }

    render () {
        return (
            <div
                ref={this.container}
                style={{ width: '100%', height: '100%' }}
            />
        );
    }
}

PanoramaScene.propTypes = {
    sceneRef: PropTypes.func,
    eventHandlers: PropTypes.object,
    sceneStore: PropTypes.object,
    sceneProps: PropTypes.object,
    sceneTemplate: PropTypes.func
};
PanoramaScene.defaultProps = {
    sceneProps: {}
};

export default PanoramaScene;
