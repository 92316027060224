import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

const Frame = ({ slide, classes, children }) =>
    <div
        className={`ib-preview board-layout ${classes} layout-${slide.layout}`}
        style={{
            background: slide.image ? `url('${slide.image}')` : slide.backgroundColor.toCSS()
        }}
    >{children}
    </div>;

Frame.defaultProps = { classes: '' };
Frame.propTypes = {
    slide: PropTypes.object.isRequired,
    classes: PropTypes.string
};

export default observer(Frame);
