import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import { MenuItem } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';
import { Checkbox, CheckboxState } from '@vectorworks/vcs-ui/dist/lib/Checkbox/Checkbox';

import { TABLES } from '../../utils';

import Filter from './Filter';
import Trigger from '../Trigger';
import S from '../styles';
import ViewerContext from '../../ViewerContext';

const GroupByMenu = ({ popperState, clear }) => {
    const { store } = React.useContext(ViewerContext);
    const { columns } = store;
    const fixedCollapsables = TABLES[store.table].collapsable.map(c => c.id);
    return (
        <S.ScrollableMenu {...bindMenu(popperState)}>
            {
                columns
                    .active
                    .map(c => !fixedCollapsables.includes(c.id) &&
                    <MenuItem
                        key={`ci-${c.id}`}
                        onClick={ c.toggleCollapsable }
                        data-what='ccad-filters-groupby-item'
                        data-which={`${c.id}`}
                    >
                        <Checkbox
                            state={ c.isCollapsable ? CheckboxState.ON : CheckboxState.OFF }
                            style={{ margin: '0 5px' }}
                            data-what='ccad-filters-groupby-checkbox'
                            data-which={`${c.id}`}
                        />
                        { c.title }
                    </MenuItem>
                    )
            }
            <MenuItem
                style={{
                    padding: '5px 0 0 42px',
                    borderTop: '1px solid var(--border-color)'
                }}
                key='ci-none'
                onClick={ clear }
                data-what='ccad-filters-groupby-item'
                data-which='none'
            >
                None
            </MenuItem>
        </S.ScrollableMenu>
    );
};

GroupByMenu.propTypes = {
    popperState: PropTypes.object,
    clear: PropTypes.func
};

const GroupByDropdown = observer(() => {
    const { store, isMobile } = React.useContext(ViewerContext);
    const { columns } = store;
    const popperState = usePopupState({ variant: 'popper', popupId: 'groupByMenu' });
    const isActive = columns.userCollapsable.length > 0;

    const clear = () => {
        columns.clearCollapsableColumns();
    };

    return (
        <Filter
            isActive={isActive}
            clear={clear}
            label='groupby'
        >
            {
                isMobile
                    ? <S.FilterIcon
                        icon='groups-16'
                        {...bindTrigger(popperState)}
                        data-what='ccad-filter'
                        data-which='groupby-button'
                    />
                    : <Trigger
                        {...bindTrigger(popperState)}
                        data-what='ccad-filter'
                        data-which='groupby-button'
                    >
                        {gettext('Group By')}
                    </Trigger>
            }
            <GroupByMenu store={store} popperState={popperState} clear={clear}/>
        </Filter>
    );
});

export default GroupByDropdown;
