import { loadOneDrivePages } from '../Sidebar/Integrations/api';

export const ONE_DRIVE_PAGES_NAMES = {
    MY_DRIVE: {
        id: 'myFilesOneDrive',
        name: gettext('My Files')
    },
    SHARED_WITH_ME: {
        id: 'sharedWithMeOneDrive',
        name: gettext('Shared')
    },
    UNKNOWN_DRIVE: {
        id: 'driveId_',
        name: gettext('Drive')
    }
};

function trimSlashes (input) {
    return input.replace(/(^\/+|\/+$)/mg, '');
}

export function parseSharedDriveLookup (folder = '') {
    let driveId = '';
    let path = folder.replace(/^\/+|\/+$/g, '');

    if (folder.startsWith('driveId_')) {
        const driveIdLookup = folder.split('/', 1)[0];
        driveId = driveIdLookup.match(/driveId_([\w-$!]+)/)[1];
        path = trimSlashes(folder.slice(driveIdLookup.length));
    }

    return [driveId, path];
}

export function isSharedWithMeRoot (input) {
    const [driveId, path] = parseSharedDriveLookup(input);
    return driveId.startsWith('sharedWithMeOneDrive') && !path;
}

export function previewPath (initialPath = '') {
    const [driveId, path] = parseSharedDriveLookup(initialPath);
    const previewName = driveId && driveId !== ONE_DRIVE_PAGES_NAMES.MY_DRIVE.id
        ? driveId.startsWith(ONE_DRIVE_PAGES_NAMES.SHARED_WITH_ME.id) ||
          (!path && initialPath.startsWith(ONE_DRIVE_PAGES_NAMES.UNKNOWN_DRIVE))
            ? ONE_DRIVE_PAGES_NAMES.SHARED_WITH_ME.name
            : gettext((
                loadOneDrivePages(true).find(sd => sd.id === driveId) ||
                { name: 'Shared' }
            ).name)
        : ONE_DRIVE_PAGES_NAMES.MY_DRIVE.name;

    return [previewName, path]
        .map(trimSlashes)
        .filter(part => !!part)
        .join('/');
}

export function withDriveId (_path, driveId) {
    const path = trimSlashes(_path);
    return trimSlashes(
        !driveId || driveId === 'myFilesOneDrive'
            ? path
            : driveId === 'sharedWithMeOneDrive'
                ? `driveId_sharedWithMeOneDrive${Settings.user.nvwuid.replace(/-/g, '')}/${path}`
                : `driveId_${driveId}/${path}`
    );
}

export function handleSharedDriveRoot (parts) {
    const shared_paths = loadOneDrivePages(true).find(({ id }) => id === 'sharedWithMeOneDrive').shared_paths;
    parts.forEach(part => {
        shared_paths.forEach(path => {
            if (part[1].split('/').length === 1 && path.startsWith(part[1])) {
                part[1] = 'driveId_sharedWithMeOneDrive' + Settings.user.nvwuid.replace(/-/g, '');
            }
        });
    });
    return parts;
}

export function checkDriveIdShared (driveId) {
    const shared_paths = loadOneDrivePages(true).find(({ id }) => id === 'sharedWithMeOneDrive')?.shared_paths;
    return shared_paths?.some(path => path.startsWith(driveId));
}

export function checkItemInRootOfShared (prefix) {
    const [drive_id, path] = parseSharedDriveLookup(prefix);
    return (checkDriveIdShared('driveId_' + drive_id) && path.split('/').length === 1);
};
