import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { InputLabelField, ValidatedInput } from '@vectorworks/vcs-ui/dist/lib/Input/Text/Input';
import { textOverflow } from '@vectorworks/vcs-ui/dist/lib/styles/common';

const S = {
    Row: styled.div`
        display: flex;

        > label {
            margin-top: 9px;
            width: 70px;
            flex-shrink: 0;
            ${textOverflow}
        }
    `,
    Input: styled(ValidatedInput)`
        flex-grow: 0;
    `
};

function TitleEditor ({ title, onChange, ...rest }) {
    const [errors, setErrors] = useState({});
    const [errorText, setErrorText] = useState();

    useEffect(() => {
        const errorValues = Object.values(errors).filter(Boolean);
        errorValues.length
            ? setErrorText(errorValues[0])
            : setErrorText(undefined);
    }, [errors]);

    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        // TODO: Fix: this will work event without the validations
        onChange(newTitle);
        setErrors({
            ...errors,
            REQUIRED: newTitle.length === 0
                ? gettext('This field is required.')
                : undefined,
            TOO_LONG: newTitle.length > 255
                ? gettext('The maximum length is 255 characters.')
                : undefined
        });
    };

    return (
        <S.Row {...rest}>
            <InputLabelField
                labelText={gettext('Title')}
                title={gettext('Title')}
                htmlFor='pin-editor__title'
            />
            <ValidatedInput
                id='pin-editor__title'
                value={title}
                onChange={handleTitleChange}
                errorText={errorText}
                {...rest}
            />
        </S.Row>
    );
}

TitleEditor.propTypes = {
    title: PropTypes.string,
    onChange: PropTypes.func
};

export default TitleEditor;
