import moment from 'moment';
import { FileTypes } from '../lib/file';

import * as api from './api';

/* eslint-disable */
Number.prototype.asClock = function () {
    return this.toString().padStart(2, '0');
};
/* eslint-enable */

const vrMoreOnMobile = function (fileType) {
    return !Settings.device.isMobile && fileType === FileTypes.vgx;
};

const getVRLocalStorageKey = function () {
    return `${Settings.user.login}.vrCodes`;
};

function VRCodeViewModel (params) {
    const self = this;

    self.ctx = params.ctx;
    self.dialog = params.dialog;
    self.loading = ko.observable(true);
    self.code = ko.observable(null);
    self.expires = ko.observable(null);
    self.expiresIn = ko.observable(null);
    self.refreshInterval = null;

    self.moreOnMobile = self.ctx.fileType && vrMoreOnMobile(self.ctx.fileType);

    self.title = gettext('Do more with this model!');
    self.description = interpolate(
        gettext('Enter this code into the %(link)s for Meta Quest for an immersive, realistic, and engaging experience.'),
        { link: `<a href="https://www.meta.com/experiences/6982964221751719/" target="_blank">${gettext('Vectorworks Odyssey app')}</a>` },
        true
    );

    self.init = function () {
        self.getShortCode()
            .then(() => {
                self.recalcExpiresIn();
                if (!self.refreshInterval) {
                    self.refreshInterval = setInterval(() => {
                        self.expires() && self.recalcExpiresIn();
                    }, 1000);
                }
            });
    };

    self.getShortCode = () => {
        return api.getShortCode(this.ctx.file)
            .then(({ code, expires }) => {
                self.code(code);
                self.expires(expires);
                self.loading(false);
            })
            .catch(err => console.error(err));
    };

    self.recalcExpiresIn = () => {
        const diff = moment.duration(moment.utc(self.expires()).local().diff(moment()));
        self.expiresIn(`${diff.minutes().asClock()}:${diff.seconds().asClock()}`);
        diff.minutes() < 1 && self.getShortCode();
    };

    self.confirm = () => {
        self.moreOnMobile && window.localStorage.setItem(getVRLocalStorageKey(), true);
        clearInterval(self.refreshInterval);
        self.dialog.close();
    };

    self.dismiss = () => {
        clearInterval(self.refreshInterval);
        self.dialog.dismiss();
    };

    self.init();
}

!ko.components.isRegistered('dialog-vr-code') &&
ko.components.register('dialog-vr-code', {
    viewModel: VRCodeViewModel,
    template: { element: 'dialog-vr-code' }
});

export {
    VRCodeViewModel,
    vrMoreOnMobile,
    getVRLocalStorageKey
};
