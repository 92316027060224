import React from 'react';

import { observer } from 'mobx-react';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';
import PropTypes from 'prop-types';

const VideoVolume = ({ viewer }) =>
    <span className={`ctrl-tool strl-wrapper volume-popup ${viewer.canPlay ? '' : 'disabled'}`}>
        <Popup
            className='volume-slider-wrapper'
            trigger={
                <span
                    className={`icon ${viewer.volume ? 'icon-volume-on' : 'icon-volume-off'}`}
                    onClick={viewer.toggleVolume}
                    ga-action='File_View_Controller'
                    ga-label='Click_Toggle_Volume'
                    data-what='controller-tool'
                    data-which='toggle-volume'
                    id='toggle-volume'
                />
            }
            content={
                <div className='volume-slider'>
                    <input
                        className='slider'
                        type='range'
                        onInput={viewer.updateVolume}
                        value={(viewer.volume * 100) || 0}
                        onChange={viewer.updateVolume}
                        max='100'
                        step='0.1'
                    />
                    <div
                        className='slider-fill'
                        style={{ width: `${viewer.volume * 100}%` }}
                    />
                </div>
            }
            on='hover'
            position='top center'
            hoverable
            basic
        />
    </span>;

VideoVolume.propTypes = {
    viewer: PropTypes.object.isRequired
};

export default observer(VideoVolume);
