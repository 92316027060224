// ! use only single quotes in templates passed as attributes
// The .raycaster__tour-pin is needed for the hover over a pin to work.
const pin = props => `
    <a-entity
        id='bind-for-pins-$pin'
        class='raycaster__tour-pin'
        bind__position='functions.findPin($pin) && functions.getPinPosition($pin)'
        bind__rotation='functions.findPin($pin) && functions.getPinRotation($pin)'
        bind__look-at='functions.findPin($pin) && functions.getPinLookAtCamera($pin)'
        bind__pin='id: $pin'
    >
        <a-entity
            position='0 0 0'
            bind__inject-template='functions.findPin($pin) && functions.getPinHoverTemplate($pin)'
        ></a-entity>

        <a-entity
            position='0 0 0.002'
            bind__inject-template='functions.findPin($pin) && functions.getPinSelectionTemplate($pin)'
        ></a-entity>

        <a-circle
            class='invisible-pin__click'
            position='0 0 0.006'
            bind__visible='!(functions.findPin($pin) && functions.isVisiblePin($pin))'
            material='transparent: true; opacity: 0'
            bind__radius='functions.findPin($pin) && (functions.calcPinSize($pin) + functions.calcPinBorder($pin))'
        ></a-circle>

        <a-ring
            position='0 0 0.004'
            bind__visible='functions.findPin($pin) && functions.isVisiblePin($pin)'
            bind__material='functions.findPin($pin) && functions.getPinBorderColor($pin)'
            bind__radius-inner='functions.findPin($pin) && functions.calcPinSize($pin)'
            bind__radius-outer='functions.findPin($pin) && (functions.calcPinSize($pin) + functions.calcPinBorder($pin))'
        ></a-ring>

        <a-circle
            position='0 0 0.006'
            bind__visible='functions.findPin($pin) && functions.isVisiblePin($pin)'
            primitive='a-circle'
            bind__material='functions.findPin($pin) && functions.getPinColor($pin)'
            bind__radius='functions.findPin($pin) && functions.calcPinSize($pin)'
        ></a-circle>

        <a-plane
            position='0 0 0.008'
            bind__visible='functions.findPin($pin) && functions.isVisiblePin($pin)'
            bind__material='functions.findPin($pin) && functions.getPinMaterialSource($pin)'
            bind__width='functions.findPin($pin) && functions.calcImageSize($pin)'
            bind__height='functions.findPin($pin) && functions.calcImageSize($pin)'
            bind__svgload='functions.findPin($pin) && functions.getPinSvgLoadParams($pin)'
        ></a-plane>

        <a-plane
            rotation='0 180 0'
            position='0 0 -0.008'
            bind__visible='functions.findPin($pin) && functions.isVisiblePin($pin)'
            bind__material='functions.findPin($pin) && functions.getPinMaterialSource($pin)'
            bind__width='functions.findPin($pin) && functions.calcImageSize($pin)'
            bind__height='functions.findPin($pin) && functions.calcImageSize($pin)'
            bind__svgload='functions.findPin($pin) && functions.getPinSvgLoadParams($pin)'
        ></a-plane>
    </a-entity>
`;

export const pins = props => `
    <a-entity
        bind-for="pins as pin"
        template="${pin(props)}"
        bind__visible="image.state === 'ready' && imageShown"
    ></a-entity>
`;

const cursorTemplate = props => `
    <a-entity
        class='panorama-cursor'
        cursor='rayOrigin: mouse'
        raycaster='objects: .raycaster__tour-pin; near: 1; far: 11'
    ></a-entity>`;

const cursorTemplateVR = props => `
    <a-entity
        class='panorama-cursor'
        position='0 0 -3'
        geometry='primitive: ring; radiusInner: 0.095; radiusOuter: 0.105;'
        material='color: #fff; shader: flat; opacity: 0.5; visible: true'
        raycaster='objects: .raycaster__tour-pin; near: 1; far: 11'
    >
        <a-animation
            attribute='scale'
            begin='raycaster-intersection'
            end='raycaster-intersection-cleared'
            dur='1000'
            fill='alternate'
            from='1 1 1'
            to='2 2 2'
            repeat='indefinite'
        ></a-animation>
        <a-animation
            attribute='scale'
            begin='raycaster-intersection-cleared'
            dur='500'
            to='1 1 1'
            repeat='0'
        ></a-animation>
        <a-ring color='#00bcb4' radius-inner='0.075' radius-outer='0.095'></a-ring>
        <a-ring color='#fff' radius-inner='0.065' radius-outer='0.075' opacity='0.5'></a-ring>
   </a-entity>`;

// cameraRotation - is that the property?
export const scene = props => {
    const sky = props.skyAnimation
        ? `<a-entity
            sky-loader="imageProp: image"
            skyProps="rotation: 0 0 0; radius: 10;"
        ></a-entity>`
        : `<a-sky
            bind__src="image.src"
            rotation="0 0 0"
            bind__enabled="image.state === 'ready'"
            radius="10"
        ></a-sky>`;

    return `<a-scene
        bind__vcs-vr-mode-ui="enabled: ${!!props.vrModeEnabled} && !!controller.vrMode"
        vr-mode-ui="enabled: false"
        embedded
        bind__activity-monitor="enabled: ${!!props.autoRotateEnabled}; timeout: 30000; key: place.id"
        store-camera-rotation
        ${props.skyAnimation ? 'transition-manager' : ''}
    >
        ${sky}
        <a-entity
            class="camera"
            camera="near: 0.005; far: 20"
            position rotation
            bind__vcs-look-controls="enabled: !!controller.lookControls;
                reverseMouseDrag: true; reverseTouchDrag: true;
                initialRotation: ${props.skyAnimation ? '' : 'cameraRotation'};
                autoRotateEnabled: ${props.autoRotateEnabled};
                autoRotateSpeed: 0.0005;"
            cursor-switch="template: cursor-template; templateVR: cursor-template-vr"
            cursor-template="${cursorTemplate(props)}"
            cursor-template-vr="${cursorTemplateVR(props)}"
        ></a-entity>
        <a-entity id="light" light="type:ambient;"></a-entity>
        ${props.sceneChildren ? props.sceneChildren(props) : ''}
    </a-scene>`;
};
