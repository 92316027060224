import React from 'react';
import PropTypes from 'prop-types';

class MentionsTextArea extends React.Component {
    onFocus = e => {
        e.stopPropagation();
        e.preventDefault();
        this.props.onFocus && this.props.onFocus();
    };

    onBlur = () => {
        this.props.onBlur && this.props.onBlur();
    };

    onInput = e => {
        this.props.onInput(e);
        this.props.onChange(e);
    };

    getEditableDiv = () => this.div;

    disableDragAndDrop = e => {
        e.preventDefault();
        return false;
    };

    stopPropagation = e => e.stopPropagation();

    render () {
        return (
            <div
                className='mf-input'
                ref={div => { this.div = div; }}
                contentEditable='true'
                draggable='false'
                onFocus={this.onFocus}
                onClick={this.stopPropagation}
                onBlur={this.onBlur}
                placeholder={this.props.placeholder}
                onInput={this.onInput}
                onKeyDown={this.props.onKeyDown}
                onKeyPress={this.props.onKeyPress}
                onDrop={this.disableDragAndDrop}
                onDragOver={this.disableDragAndDrop}
                onPaste={this.props.onPaste}
                data-what='comment-field'
            />
        );
    }
}

export default MentionsTextArea;

MentionsTextArea.propTypes = {
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyPress: PropTypes.any,
    onPaste: PropTypes.any,
    placeholder: PropTypes.string
};
