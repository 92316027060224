import React, { Component } from 'react';

import { autorun } from 'mobx';
import { observer } from 'mobx-react';

import { commonPropTypes } from './utils';
import { isVideoPanoramic } from '~static/file-viewer/panorama/mp4';
import PanoramicVideoViewer from './PanoramicVideoViewer';
import VideoViewer from './VideoViewer';
import VideoController from './Controllers/VideoController';

import { queryString } from '~static/js/lib';

class VideoLoader extends Component {
    name = 'VideoLoader';
    sourceUrl = '';

    constructor (props) {
        super(props);
        this.state = {
            videoUrl: '',
            isPanorama: 'unknown'
        };

        this.loadFile();
    };

    componentDidMount () {
        this.autorunDisposer = autorun(() => this.props.store.file.sourceUrl && this.loadFile());
    }

    componentWillUnmount () {
        this.autorunDisposer();
    }

    loadFile = () => {
        if (this.sourceUrl === this.props.store.file.sourceUrl) {
            return;
        }
        this.sourceUrl = this.props.store.file.sourceUrl;
        this.props.store.startLoading();
        (
            Settings.offlinePresentation
                ? Promise.resolve(this.props.store.file.offlineSourceUrlResp)
                : $.getJSON(queryString.buildUrl(
                    this.props.store.file.sourceUrl,
                    {
                        response_type: 'data',
                        viewable: 'off'
                    }
                ))
        ).catch(err => {
            if (err?.status === 404) {
                this.props.store.file.setNotFoundFile();
            }
        }).then(data => {
            if (this.props.store.file.file.fileType.type === 'video_360') {
                this.setState({ isPanorama: true });
            } else {
                isVideoPanoramic(data.url)
                    .then(isPanorama => this.setState({ isPanorama }))
                    .catch(error => {
                        console.error(error);
                        this.setState({ isPanorama: false });
                    });
            }
        });
    };

    renderViewer = () => {
        const { children } = this.props; // eslint-disable-line
        return this.state.isPanorama
            ? <PanoramicVideoViewer {...this.props}>
                {children}
            </PanoramicVideoViewer>
            : <React.Fragment>
                <VideoViewer
                    {...this.props}
                    ref={instance => {
                        this.viewer = instance;
                    }}
                >
                    { children }
                </VideoViewer>
                {
                    this.viewer &&
                    <VideoController store={this.props.store} viewer={this.viewer}/>
                }
            </React.Fragment>;
    };

    render () {
        return this.state.isPanorama === 'unknown'
            ? (<div className='fileview-component-loader' data-what='file-viewer'>
                { this.props.children }
            </div>)
            : this.renderViewer();
    }
};

export default observer(VideoLoader);

VideoLoader.propTypes = {
    ...commonPropTypes
};
