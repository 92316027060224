import { observable, action, makeObservable } from 'mobx';

import { SlideType } from '../models/slide';
import { ImageResource } from '../FileViewers/loader';

class Controller {
    isVisible = true;
    vrPossible = false;

    constructor () {
        makeObservable(this, {
            isVisible: observable,
            vrPossible: observable,
            show: action,
            hide: action,
            setProperty: action
        });
    }

    show () {
        this.isVisible = true;
    }

    hide () {
        this.isVisible = false;
    }

    setProperty (name, value) {
        this[name] = value;
    }
}

class BoardPreview {
    activeItem = null;
    controller = new Controller();

    constructor (root, slide, optional = {}) {
        makeObservable(this, {
            activeItem: observable,
            controller: observable,
            setActiveItem: action
        });

        this.root = root;
        this.slide = slide;

        if (this.slide.layout === 'empty') {
            this.slide.layout = 'intro';
            this.slide.text.text = gettext('Oops! This presentation is empty!');
        }
    }

    setActiveItem (itemId) {
        this.activeItem = itemId;
    };
}

class TourPreview {
    place = null;
    placeProps = { cameraRotation: null };
    image = new ImageResource();
    hoveredPin = null;
    controller = new Controller();

    constructor (root, slide, optional = {}) {
        makeObservable(this, {
            place: observable,
            placeProps: observable,
            image: observable,
            hoveredPin: observable,
            controller: observable,
            goto: action,
            hoverPin: action,
            unhoverPin: action
        });

        this.root = root;
        this.slide = slide;
        if (optional.place) {
            const place = slide.places.find(p => p.id === parseInt(optional.place));
            this.goto(place, place.props);
        } else {
            this.goto(slide.home, slide.home ? slide.home.props : {});
        }
    }

    goto (place, props = { cameraRotation: null }) {
        this.place = place;
        Object.assign(this.placeProps, props);
        this.image = new ImageResource();
        if (place && place.asset.state === 'ready') {
            this.root.imagePreloader.replace([
                place.medialUrl,
                ...place.portals.map(p => p.toPlace?.medialUrl)
            ]);
            this.image.trackLoading(this.root.imagePreloader.waitFor(place.medialUrl));
        }
    }

    hoverPin (pin, position) {
        this.hoveredPin = {
            pin,
            position
        };
    }

    unhoverPin () {
        this.hoveredPin = null;
    }
}

const stores = {
    [SlideType.BOARD]: BoardPreview,
    [SlideType.TOUR]: TourPreview
};

const createStore = (root, slide, optional) => {
    return new stores[slide.type](root, slide, optional);
};

export default createStore;
export { TourPreview };
