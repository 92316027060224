import loadScript from '~static/js/lib/load-script';
import { patchEventListenerMethods } from './event';
import { registerCompnents } from './components';
import aframeStore from './store';

function patchEventHandling (prototype) {
    prototype.originalAddEventListener = prototype.addEventListener;
    prototype.addEventListener = function (...args) {
        this._eventListeners = this._eventListeners || [];
        this._eventListeners.push(args);
        return prototype.originalAddEventListener.apply(this, args);
    };
    prototype.clearEventListeners = function () {
        (this._eventListeners || []).forEach(
            args => this.removeEventListener(...args));
        this._eventListeners = undefined;
    };
}

export default () => {
    if (window.AFRAME) {
        return Promise.resolve(window.AFRAME);
    } else {
        patchEventListenerMethods(window);
        patchEventListenerMethods(document);
        return loadScript(Settings.AFRAME, 'aframe-script')
            .then(async () => {
                patchEventHandling(window.AFRAME.AScene.prototype);
                patchEventHandling(HTMLCanvasElement.prototype); // eslint-disable-line
                aframeStore.reset();
                window.webvrpolyfill.config.CARDBOARD_UI_DISABLED = false;
                registerCompnents(window.AFRAME);
                await import('aframe-look-at-component');
                return window.AFRAME;
            });
    }
};
