import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';

import { COLUMNS, FILTERS } from '../../utils';
import S from '../styles';

const FilterTypeMenu = ({ columnId, filter, changeFilter, close }) => {
    const onKeyDown = e => {
        e.stopPropagation();
        if (e.key === 'Escape') {
            close();
        }
    };

    const filterColumnLike = COLUMNS[columnId].filterLikeType || COLUMNS[columnId].type;

    return (
        <S.Select
            value={filter}
            onChange={changeFilter}
            data-what='ccad-filter-by'
            data-which='condition'
            style={{ margin: 'unset' }}
            tabIndex={0}
            onKeyDown={onKeyDown}
        >
            {
                Object.values(FILTERS)
                    .map(f =>
                        f.allowedTypes.includes(filterColumnLike) &&
                        <MenuItem
                            key={`fc-${f.name}`}
                            value={f.name}
                            data-what='ccad-filter-by-condition'
                            data-which={`${f.name.toLowerCase()}`}
                            onKeyDown={onKeyDown}
                        >
                            { f.title }
                        </MenuItem>
                    )
            }
        </S.Select>
    );
};

FilterTypeMenu.propTypes = {
    columnId: PropTypes.string,
    filter: PropTypes.string,
    changeFilter: PropTypes.func,
    close: PropTypes.func
};

export default FilterTypeMenu;
