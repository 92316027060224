import React from 'react';

import { observer } from 'mobx-react';
import { reaction } from 'mobx';
import PropTypes from 'prop-types';

function calcAspectRatioFit (target, container) {
    const ratio = Math.min(
        1,
        container.width / target.width,
        container.height / target.height
    );

    return {
        width: target.width * ratio,
        height: target.height * ratio
    };
}

class ScaleDown extends React.Component {
    componentDidMount () {
        this.container = document.querySelector(`${this.props.container}`);
        this.element = document.querySelector(this.props.selector);

        window.addEventListener('resize', this.resize);

        this.reactionDisposer = reaction(
            () => this.props.file,
            src => this.resize(),
            { fireImmediately: true }
        );

        /*
            Only needed because the container in Intro
            has width and height 0 in the beginning
        */
        setTimeout(this.resize);
    }

    componentWillUnmount () {
        this.reactionDisposer && this.reactionDisposer();
        window.removeEventListener('resize', this.resize);
        this.container = undefined;
        this.element = undefined;
    }

    resize = () => {
        if (!this.container || this.props.file.state !== 'ready') return;

        const size = this.props.file.params.size;

        const {
            clientWidth: containerWidth,
            clientHeight: containerHeight
        } = this.container;

        const newSize = calcAspectRatioFit(size, { width: containerWidth, height: containerHeight });
        const elementWrapper = this.element.parentNode;
        elementWrapper.style.height = `${newSize.height}px`;
        elementWrapper.style.width = `${newSize.width}px`;
    };

    render () {
        return (
            <React.Fragment>
                { this.props.children }
            </React.Fragment>
        );
    }
}

ScaleDown.propTypes = {
    selector: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    file: PropTypes.object.isRequired
};

ScaleDown.defaultProps = {
    container: '#presentation-screen .ib-file-container'
};

export default observer(ScaleDown);
