import { observable, action, makeObservable } from 'mobx';

import storage from '../utils/storage';

class SessionStore {
    static storedProps = ['lastSelectFilesDialogUse'];

    lastSelectFilesDialogUse = {
        storageType: null,
        folder: null
    };

    constructor (root) {
        makeObservable(this, {
            lastSelectFilesDialogUse: observable,
            trackSelectFilesDialogUse: action
        });

        this.root = root;
        this.storageDisposer = storage.syncWith(this, 'session', SessionStore.storedProps);
    }

    cleanUp () {
        this.storageDisposer();
    }

    trackSelectFilesDialogUse (files) {
        if (files.length) {
            const first = files[0];
            this.lastSelectFilesDialogUse = {
                storageType: first.storageType,
                folder: first.folder,
                ownerInfo: first.ownerInfo
            };
        }
    }
}

export default SessionStore;
