import React from 'react';

const NotPermittedPanel = () =>
    (<div className='message-box flex-col not-authenticated'>
        <span className='icon icon-user'/>

        <div className='message'>
            <span className='msg-row-prim'>{ gettext('Not permitted.') }</span>
            <span className='msg-row-sec'>{ gettext('You are not allowed to comment on this file.') }</span>
        </div>
    </div>);

export default NotPermittedPanel;
