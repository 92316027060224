class LinkState {
    constructor (link, inNewTab) {
        this.link = link;
        this.inNewTab = inNewTab;
    }

    static createDefault () {
        return new LinkState('https://example.com', true);
    }

    static fromApi ({ link, in_new_tab }) {
        return new LinkState(link, in_new_tab);
    }

    static forApi ({ link, inNewTab }) {
        return {
            link,
            in_new_tab: inNewTab
        };
    }

    copy () {
        return new LinkState(this.link, this.inNewTab);
    }

    updated (linkStateUpdateData) {
        const copy = {
            ...this.copy(),
            ...linkStateUpdateData
        };
        return new LinkState(copy.link, copy.inNewTab);
    }

    equals ({ link, inNewTab }) {
        return this.link === link && this.inNewTab === inNewTab;
    }
}

export { LinkState };
