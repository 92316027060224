export default (src, id) => {
    if (document.getElementById(id)) {
        return Promise.resolve(null);
    }
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.id = id;
        script.onload = () => {
            resolve(null);
        };
        script.src = src;
        const firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(script, firstScript);
    });
};
