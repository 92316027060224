import React from 'react';

import PropTypes from 'prop-types';

import DialogFrame from '../../Components/Dialog/DialogFrame';

const DeleteCarouselItem = props => {
    const onSubmit = () => {
        props.dialog.close();
    };

    const onCancel = () => {
        props.dialog.dismiss();
    };

    return (
        <DialogFrame
            dialog={props.dialog}
            title={gettext('Delete confirmation')}
            submitTitle={gettext('Delete')}
            cancelTitle={gettext('Cancel')}
            onSubmit={onSubmit}
            onCancel={onCancel}
            submitAnalytics={{
                'ga-action': 'Delete_Carousel_Item_Dialog',
                'ga-label': 'Click_Submit'
            }}
            cancelAnalytics={{
                'ga-action': 'Delete_Carousel_Item_Dialog',
                'ga-label': 'Click_Cancel'
            }}
        >
            <p className='dialog-paragraph'>
                { gettext('Are you sure you want to remove this file from the slide? All pins that use it will be deleted. The file will remain in the Presentation resources panel.') }
            </p>
        </DialogFrame>
    );
};

DeleteCarouselItem.propTypes = {
    dialog: PropTypes.any
};

export default DeleteCarouselItem;
