import { observable, computed, action, runInAction, makeObservable } from 'mobx';

import api from '../api';
import Index from '../utils/object-index';
import { saveMixin } from './save';
import itemContainerMixin from './item-container';
import assetMixin from './asset';
import BasePin from './pin';
import { getPinSize } from '../Pins/util';
import { pinPoint } from '../FileViewers/Tour/point';
import { LinkState } from './link';

const partitionPinProps = (data) => {
    const {
        id,
        title,
        props,
        rotation,
        position,
        position_locked: positionLocked,
        visible,
        ...specificPinProps
    } = data;
    return {
        basePinProps: {
            id,
            title,
            props,
            rotation,
            position,
            positionLocked,
            visible
        },
        specificPinProps
    };
};

const tourBuilder = {
    build (base, data) {
        const tour = new Tour({
            ...base,
            homeId: data.home
        });
        tour.places.replace(data.places.map(place => this.createPlace(tour, place)));
        if (data.map) {
            tour.map = this.createMap(tour, data.map);
        }
        return tour;
    },
    createPortal (tour, place, data) {
        const { basePinProps, specificPinProps: sp } = partitionPinProps(data);
        const portalProps = {
            toPlaceId: sp.to_place,
            toPlaceProps: sp.to_place_props,
            assetId: sp.asset
        };

        return new Portal({
            root: tour.root,
            tour,
            place,
            basePinProps,
            ...portalProps
        });
    },
    createAssetPin (tour, place, data) {
        const { basePinProps, specificPinProps: sp } = partitionPinProps(data);
        const assetPinProps = {
            assetId: sp.asset
        };

        return new AssetPin({
            root: tour.root,
            tour,
            place,
            basePinProps,
            ...assetPinProps
        });
    },
    createTextPin (tour, place, data) {
        const { basePinProps, specificPinProps: sp } = partitionPinProps(data);
        const textPinProps = {
            textProps: sp.text_props
        };

        return new TextPin({
            root: tour.root,
            tour,
            place,
            basePinProps,
            ...textPinProps
        });
    },
    createLinkPin (tour, place, data) {
        const { basePinProps, specificPinProps: sp } = partitionPinProps(data);
        const linkPinProps = {
            linkState: LinkState.fromApi(sp),
            linkProps: sp.link_props
        };

        return new LinkPin({
            root: tour.root,
            tour,
            place,
            basePinProps,
            ...linkPinProps
        });
    },
    createPlace (tour, data) {
        const place = new Place({
            root: tour.root,
            tour,
            id: data.id,
            assetId: data.asset,
            order: data.order,
            props: data.props
        });

        const portals = data.portals.map(portal => this.createPortal(tour, place, portal));
        place.portals.replace(portals);

        const assetPins = data.asset_pins.map(assetPin => this.createAssetPin(tour, place, assetPin));
        place.assetPins.replace(assetPins);

        const textPins = data.text_pins.map(textPin => this.createTextPin(tour, place, textPin));
        place.textPins.replace(textPins);

        const linkPins = data.link_pins.map(linkPin => this.createLinkPin(tour, place, linkPin));
        place.linkPins.replace(linkPins);

        return place;
    },
    createAssetItem (tour, assetInstance) {
        return new AssetItem({
            root: tour.root,
            tour,
            id: assetInstance.uuid,
            assetId: assetInstance.uuid
        });
    },
    createMap (tour, data) {
        const map = new TourMap({
            root: tour.root,
            tour,
            id: data.id,
            imageId: data.image
        });
        map.pins.replace(data.pins.map(pin => this.createMapPin(map, pin)));
        return map;
    },
    createMapPin (map, data) {
        return new MapPin({
            root: map.root,
            tour: map.tour,
            map,
            id: data.id,
            title: data.title,
            toPlaceId: data.to_place,
            props: data.props,
            position: data.position
        });
    }
};

export class AssetItem {
    what = 'asset-item';

    constructor (data) {
        makeObservable(this, {
            asset: computed,
            removePinsForAsset: action,
            remove: action
        });

        Object.assign(this, data);
        this.root.asset.index.onDelete(
            this.assetId,
            action('syncBoardItemWithAsset', () => {
                this.tour.assets.remove(this);
                // Remove all pins with this asset
                this.tour.places.forEach(place =>
                    place.assetPins.replace(place.assetPins.filter(ap => ap.assetId !== this.assetId))
                );
            })
        );

        this.which = this.asset.filename;
    }

    get asset () {
        return this.root.asset.index.map.get(this.assetId);
    }

    removePinsForAsset () {
        this.tour.places.forEach(place => {
            /* Somehow, I once got `place = undefined, this.tour.places = [undefined]`, after I deleted all panoramas.
            Couldn't reproduce now, so adding `place?.pins`, just in case */
            place?.pins
                .filter(pin => pin.asset?.id === this.assetId)
                .map(pin => pin.remove());
        });
    }

    remove () {
        // Delete pins for that File
        this.removePinsForAsset();
        this.tour.removeAssets([this.asset]);
    }
}

class MapPin extends BasePin {
    constructor (data) {
        super(data);

        makeObservable(this, {
            toPlace: computed,
            updateCameraRotation: action,
            target: computed
        });

        this.tour.placeIndex.onDelete(
            this.toPlaceId,
            action('syncMapPinWithPlace', () => {
                this.map.pins.remove(this);
            })
        );
    }

    get toPlace () {
        return this.tour.placeIndex.map.get(this.toPlaceId);
    }

    updateCameraRotation (cameraRotation) {
        this.toPlace.updateCameraRotation(cameraRotation);
    }

    get target () {
        return this.map;
    }

    saveCommands = {
        update: {
            command (data) {
                return api.tour.updateMapPin(this, data);
            },
            params: {
                mergeObjectArgs: true
            }
        }
    };
}

Object.assign(MapPin.prototype, saveMixin);

class TourMap {
    imageId;
    pins = [];

    constructor (data) {
        makeObservable(this, {
            imageId: observable,
            pins: observable,
            image: computed,
            removePin: action
        });

        this.root = data.root;
        this.tour = data.tour;
        this.id = data.id;
        this.imageId = data.imageId;

        this.trackImage();
    }

    get image () {
        return this.root.asset.index.map.get(this.imageId);
    }

    set image (value) {
        this.imageId = value;
        this.trackImage();
    }

    trackImage () {
        const oldImageId = this.imageId;
        this.imageId && this.root.asset.index.onDelete(
            this.imageId,
            action('syncTourMapWithAsset', () => {
                if (oldImageId === this.imageId) {
                    this.tour.map = null;
                }
            })
        );
    }

    addPin (data) {
        return this.scheduleSave(
            this.saveCommands.addPin,
            {
                map: this.id,
                to_place: data.to_place,
                title: data.title,
                position: data.position,
                props: data.props
            }
        );
    }

    removePin (pin) {
        this.pins.remove(pin);
        this.scheduleSave(this.saveCommands.removePin, pin);
    }

    saveCommands = {
        addPin: {
            command (data) {
                return api.tour.createMapPin(this, data)
                    .then(data => runInAction(() => {
                        const mapPin = tourBuilder.createMapPin(this, data);
                        this.pins.push(mapPin);
                        return mapPin;
                    }));
            },
            params: {
                flush: true
            }
        },
        removePin: {
            command (pin) {
                return api.tour.removeMapPin(pin);
            }
        }
    };
}

Object.assign(TourMap.prototype, saveMixin);

class Rotation {
    constructor ({
        pitch = 0,
        yaw = 0,
        roll = 0,
        lookAtCamera = true
    } = {}) {
        this.pitch = pitch;
        this.yaw = yaw;
        this.roll = roll;
        this.lookAtCamera = lookAtCamera;
    }
}

export class TourPin extends BasePin {
    static TYPES = {
        portal: 1,
        assetPin: 2,
        textPin: 3,
        linkPin: 4,
        pendingTypePin: 5
    };

    rotation;

    constructor (basePinProps, pinType) {
        const { rotation, ...restBasePinProps } = basePinProps;
        super(restBasePinProps);
        makeObservable(this, {
            rotation: observable,
            updateRotation: action
        });
        this.isTourPin = true;
        this.pinType = pinType;

        this.databaseId = this.id;
        this.id = this.createUUID();
        this.position2D = { x: 0.5, y: 0.5 };
        this.rotation = new Rotation(rotation);
    }

    /*
        You can differetiate different types of pins that have the same id-s in the DB.
        Useful when you need to iterate over all pins - for example, in the Aframe Scene.
        Save the dBpin type in the last 8 bits. Unshift before API calls.
    */
    createUUID () {
        return (this.id << 8) | this.pinType;
    }

    get slide () {
        return this.tour;
    }

    move = ({ position2D, scene }) => {
        if (this.positionLocked) return;

        const { x, y } = position2D;
        const { x: oldX, y: oldY } = this.position2D;
        if (x !== oldX || y !== oldY) {
            this.position = pinPoint.normalized(
                x, y,
                scene,
                {
                    x: getPinSize(Number(this.props.size)) / 2,
                    y: getPinSize(Number(this.props.size)) / 2
                }
            );
            this.scheduleSave(this.saveCommands.update, { position: this.position });

            const { left: offsetX, top: offsetY } = scene.canvas.getBoundingClientRect();
            this.position2D = {
                ...this.position2D,
                x: x - offsetX,
                y: y - offsetY
            };
        }
    };

    updateRotation (updates) {
        const rotation = {
            ...this.rotation,
            ...updates
        };
        this.rotation = new Rotation(rotation);
        this.scheduleSave(this.saveCommands.update, { rotation });
    }
}

export class PendingTypePin {
    constructor (place, { position, props }) {
        this.tour = place.tour;
        this.place = place;

        this.position = position;
        this.props = props;
        this.rotation = new Rotation();

        this.isTourPin = true;
        this.pinType = TourPin.TYPES.pendingTypePin;
        this.visible = true;

        this.databaseId = -1;
        this.id = -1;
        this.result = new Promise((resolve, reject) => {
            this.deferred = { resolve, reject };
        });
    }

    onChoose = (pinType) => {
        return this.deferred.resolve(pinType);
    };

    onCancel = () => {
        return this.deferred.reject();
    };
}

export class Portal extends TourPin {
    absolutePosition;
    toPlaceId;
    toPlaceProps;

    constructor ({ basePinProps, ...rest }) {
        super(basePinProps, TourPin.TYPES.portal);

        makeObservable(this, {
            absolutePosition: observable,
            toPlaceId: observable,
            toPlaceProps: observable,
            asset: computed,
            toPlace: computed,
            updateCameraRotation: action
        });

        this.root = rest.root;
        this.tour = rest.tour;
        this.place = rest.place;
        this.toPlaceId = rest.toPlaceId;
        this.toPlaceProps = rest.toPlaceProps;
        this.assetId = rest.assetId;

        this.tour.placeIndex.onDelete(
            this.toPlaceId,
            action('syncPortalWithToPlace', () => {
                this.place.portals.remove(this);
            })
        );
    }

    get asset () {
        return this.tour.root.asset.index.map.get(this.toPlace.asset.uuid);
    }

    get toPlace () {
        return this.tour.placeIndex.map.get(this.toPlaceId);
    }

    updateCameraRotation (cameraRotation) {
        this.toPlaceProps.cameraRotation = cameraRotation;
        this.scheduleSave(this.saveCommands.update, { to_place_props: this.toPlaceProps });
    }

    remove () {
        this.place.removePortal(this);
    }

    saveCommands = {
        update: {
            command (data) {
                return api.tour.portal.update(this, data);
            },
            params: {
                mergeObjectArgs: true
            }
        }
    };
}

export class AssetPin extends TourPin {
    absolutePosition;
    assetId;

    constructor ({ basePinProps, ...rest }) {
        super(basePinProps, TourPin.TYPES.assetPin);

        makeObservable(this, {
            absolutePosition: observable,
            assetId: observable,
            asset: computed
        });

        this.root = rest.root;
        this.tour = rest.tour;
        this.place = rest.place;
        this.assetId = rest.assetId;
    }

    get asset () {
        return this.tour.root.asset.index.map.get(this.assetId);
    }

    remove () {
        this.place.removeAssetPin(this);
    }

    saveCommands = {
        update: {
            command (data) {
                return api.tour.assetPin.update(this, data);
            },
            params: {
                mergeObjectArgs: true
            }
        }
    };
}

export class TextPin extends TourPin {
    absolutePosition;
    textProps; // Raw draft-js EditorState, convertToRaw(editorState.getCurrentContent())

    constructor ({ basePinProps, ...rest }) {
        super(basePinProps, TourPin.TYPES.textPin);

        makeObservable(this, {
            absolutePosition: observable,
            textProps: observable,
            updateContent: action
        });

        this.root = rest.root;
        this.tour = rest.tour;
        this.place = rest.place;
        this.textProps = rest.textProps;
    }

    updateContent (data) {
        this.textProps = data.text_props;
        this.scheduleSave(this.saveCommands.update, data);
    }

    remove () {
        this.place.removeTextPin(this);
    }

    saveCommands = {
        update: {
            command (data) {
                return api.tour.textPin.update(this, data);
            },
            params: {
                mergeObjectArgs: true
            }
        }
    };
}

export class LinkPin extends TourPin {
    absolutePosition;
    linkState;
    linkProps;

    constructor ({ basePinProps, ...rest }) {
        super(basePinProps, TourPin.TYPES.linkPin);

        makeObservable(this, {
            absolutePosition: observable,
            linkState: observable,
            linkProps: observable,
            update: action
        });

        this.root = rest.root;
        this.tour = rest.tour;
        this.place = rest.place;
        this.linkState = rest.linkState;
        this.linkProps = rest.linkProps;
    }

    update (linkStateUpdateData) {
        const oldState = this.linkState.copy();
        const newState = this.linkState.updated(linkStateUpdateData);

        if (!newState.equals(oldState)) {
            this.linkState = newState;
            this.scheduleSave(
                this.saveCommands.update,
                LinkState.forApi(newState)
            );
        }
    }

    remove () {
        this.place.removeLinkPin(this);
    }

    saveCommands = {
        update: {
            command (data) {
                return api.tour.linkPin.update(this, data);
            },
            params: {
                mergeObjectArgs: true
            }
        }
    };
}

export class Place {
    what = 'place';

    portals = [];
    assetPins = [];
    textPins = [];
    linkPins = [];
    pendingTypePins = [];
    props;

    constructor (data) {
        makeObservable(this, {
            portals: observable,
            assetPins: observable,
            textPins: observable,
            linkPins: observable,
            pendingTypePins: observable,
            props: observable,
            asset: computed,
            medialUrl: computed,
            remove: action,
            updateCameraRotation: action,
            removePortal: action,
            removeAssetPin: action,
            removeTextPin: action,
            removeLinkPin: action,
            removePendingTypePin: action,
            addPendingTypePin: action
        });

        this.root = data.root;
        this.tour = data.tour;
        this.id = data.id;
        this.assetId = data.assetId;
        this.order = data.order;
        this.props = data.props;

        this.root.asset.index.onDelete(
            this.assetId,
            action('syncPlaceWithAsset', () => {
                this.tour.items.remove(this);
            })
        );

        this.which = this.asset.filename;
    }

    get asset () {
        return this.root.asset.index.map.get(this.assetId);
    }

    get medialUrl () {
        return this.asset && this.asset.fileVersion && this.asset.fileVersion.download_url;
    }

    get pins () {
        return [...this.assetPins, ...this.linkPins, ...this.textPins, ...this.portals, ...this.pendingTypePins];
    }

    remove () {
        this.tour.removeItem(this);
        this.tour.assetIds.remove(this.asset.uuid);
    }

    updateCameraRotation (cameraRotation) {
        this.props = {
            ...this.props,
            cameraRotation
        };
        this.scheduleSave(this.saveCommands.update, { props: this.props });
    }

    // Generic pins
    addPendingTypePin ({ position, props }) {
        const pin = new PendingTypePin(this, { position, props });
        this.pendingTypePins.push(pin);
        return Promise.resolve(pin);
    }

    removePendingTypePin (pin) {
        this.pendingTypePins.remove(pin);
    }

    // Portals
    addPortal (data) {
        return this.scheduleSave(this.saveCommands.portal.add, data);
    }

    removePortal (portal) {
        this.portals.remove(portal);
        return this.scheduleSave(this.saveCommands.portal.remove, portal);
    }

    // Asset pins
    addAssetPin (data) {
        this.tour.addAsset(data.asset);
        return this.scheduleSave(this.saveCommands.assetPin.add, data);
    }

    removeAssetPin (assetPin) {
        this.assetPins.remove(assetPin);
        return this.scheduleSave(this.saveCommands.assetPin.remove, assetPin);
    }

    // Text Pins
    addTextPin (data) {
        return this.scheduleSave(this.saveCommands.textPin.add, data);
    }

    removeTextPin (textPin) {
        this.textPins.remove(textPin);
        return this.scheduleSave(this.saveCommands.textPin.remove, textPin);
    }

    // Link Pins
    addLinkPin (data) {
        return this.scheduleSave(this.saveCommands.linkPin.add, data);
    }

    removeLinkPin (linkPin) {
        this.linkPins.remove(linkPin);
        return this.scheduleSave(this.saveCommands.linkPin.remove, linkPin);
    }

    saveCommands = {
        update: {
            command (data) {
                return api.tour.updatePlace(this, data)
                    .then(data => {});
            },
            params: {
                flush: true
            }
        },
        portal: {
            add: {
                command (data) {
                    return api.tour.portal.create(this, data)
                        .then(data => runInAction(() => {
                            const portal = tourBuilder.createPortal(this.tour, this, data);
                            this.portals.push(portal);
                            return portal;
                        }));
                },
                params: {
                    flush: true
                }
            },
            remove: {
                command (portal) {
                    return api.tour.portal.remove(portal);
                },
                params: {
                    noReduce: true
                }
            }
        },
        assetPin: {
            add: {
                command (data) {
                    return api.tour.assetPin.create(this, data)
                        .then(data => runInAction(() => {
                            const assetPin = tourBuilder.createAssetPin(this.tour, this, data);
                            this.assetPins.push(assetPin);
                            return assetPin;
                        }));
                },
                params: {
                    flush: true
                }
            },
            remove: {
                command (assetPin) {
                    return api.tour.assetPin.remove(assetPin);
                },
                params: {
                    noReduce: true
                }
            }
        },
        textPin: {
            add: {
                command (data) {
                    return api.tour.textPin.create(this, data)
                        .then(data => runInAction(() => {
                            const textPin = tourBuilder.createTextPin(this.tour, this, data);
                            this.textPins.push(textPin);
                            return textPin;
                        }));
                },
                params: {
                    flush: true
                }
            },
            remove: {
                command (textPin) {
                    return api.tour.textPin.remove(textPin);
                },
                params: {
                    noReduce: true
                }
            }
        },
        linkPin: {
            add: {
                command (data) {
                    return api.tour.linkPin.create(this, data)
                        .then(data => runInAction(() => {
                            const linkPin = tourBuilder.createLinkPin(this.tour, this, data);
                            this.linkPins.push(linkPin);
                            return linkPin;
                        }));
                },
                params: {
                    flush: true
                }
            },
            remove: {
                command (linkPin) {
                    return api.tour.linkPin.remove(linkPin);
                },
                params: {
                    noReduce: true
                }
            }
        }
    };
}

Object.assign(Place.prototype, saveMixin);

class Tour {
    title;
    assetIds = [];
    places = [];
    assets = [];
    homeId;
    map;

    static create (base, data) {
        return tourBuilder.build(base, data);
    }

    constructor (data) {
        makeObservable(this, {
            title: observable,
            assetIds: observable,
            places: observable,
            assets: observable,
            homeId: observable,
            map: observable,
            home: computed,
            assetItems: computed,
            nonPanoramas: computed,
            removeMap: action
        });

        this.root = data.root;
        this.id = data.id;
        this.title = data.title;
        this.date_modified = data.date_modified;
        this.displayDateModified = data.displayDateModified;
        this.type = data.type;
        this.assetIds = data.assetIds;
        this.order = data.order;
        this.homeId = data.homeId;

        this.items = this.places;
        this.text = {
            color: '#000000'
        };
        this.placeIndex = new Index(this.places);
    }

    get home () {
        return this.places.length ? this.places[0] : null;
    }

    get assetItems () {
        return this.assetIds
            .map(id => this.root.asset.index.map.get(id))
            .filter(asset => !!asset)
            .map(asset => tourBuilder.createAssetItem(this, asset));
    }

    get nonPanoramas () {
        return this.assetItems.filter(a => !a.asset.isPanorama);
    }

    createMap (image) {
        this.scheduleSave(this.saveCommands.createMap, { image });
    }

    removeMap () {
        this.scheduleSave(this.saveCommands.removeMap, this.map);
        this.map = null;
    }

    cleanUp () {
        this.placeIndex.cleanUp();
    }

    addAssets (assets) {
        return Promise.all(assets.map(asset => {
            return this.addAsset(asset.uuid);
        }));
    }

    removeAssets (assets) {
        return Promise.all(assets.map(asset => {
            return this.removeAsset(asset.uuid);
        }));
    }

    getOrCreatePlaceByAsset (asset) {
        const place = this.places.find(p => p.asset.uuid === asset.uuid);
        return place
            ? Promise.resolve(place)
            : this.addItems([asset]).then(() => {
                return this.places.find(p => p.asset.uuid === asset.uuid);
            });
    }

    saveCommands = {
        addItems: {
            command (assets) {
                return Promise.all(assets.map(asset =>
                    api.tour.createPlace(this, {
                        asset: asset.id
                    }).then(data => {
                        this.pushItem(tourBuilder.createPlace(this, data));
                        this.assetIds.push(asset.id);
                    })
                ));
            },
            params: {
                flush: true
            }
        },
        moveItem: {
            command (item, order) {
                return api.tour.updatePlace(item, { order })
                    .then(data => this.updateOrder(item, data.order));
            },
            params: {
                noReduce: true
            }
        },
        removeItem: {
            command (item) {
                return api.tour.removePlace(item);
            }
        },
        createMap: {
            command (data) {
                return api.tour.createMap(this, data)
                    .then(data => runInAction(() => {
                        this.map = tourBuilder.createMap(this, data);
                    }));
            },
            params: {
                flush: true
            }
        },
        removeMap: {
            command (map) {
                return api.tour.removeMap(map);
            }
        }
    };
}

Object.assign(
    Tour.prototype,
    saveMixin,
    itemContainerMixin,
    assetMixin
);

export default Tour;
export {
    tourBuilder
};
