import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { textSecondary } from '@vectorworks/vcs-ui/dist/lib/styles/common';

const S = {
    Wrapper: styled.div`
        position: relative;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;

        text-align: center;
        padding: 15px;
        border-radius: 10px;
        overflow: hidden;
    `,
    Layover: styled.div`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    `,
    Icon: styled(Icon)`
        color: var(--grey4);
        margin-bottom: 12px;
    `,
    Message: styled.div`
    `,
    Title: styled.div`
        font-weight: bold;
    `,
    Detail: styled.div`
        ${textSecondary}
        line-height: 20px;
    `
};

const EmptyMessage = ({ icon, primText = '', secondaryText = '', ...rest }) => {
    return (
        <S.Wrapper {...rest}>
            <S.Icon icon={icon} size='64px' />
            <S.Message>
                <S.Title>{primText}</S.Title>
                <S.Detail>{secondaryText}</S.Detail>
            </S.Message>
        </S.Wrapper>
    );
};

EmptyMessage.propTypes = {
    icon: PropTypes.string,
    primText: PropTypes.any,
    secondaryText: PropTypes.any,
    classes: PropTypes.string
};

export default EmptyMessage;

export const SidePanelEmptyMessage = styled(EmptyMessage)`
    margin: 15px;
`;
