import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { EditorImageViewer, PreviewImageViewer, SimpleImageViewer } from './Image';
import { EditorPDFViewer, PreviewPDFViewer, SimplePDFViewer } from './PDF';

import Spinner from '../../Components/Spinner';

const SimpleViewers = {
    IMG: SimpleImageViewer,
    PDF: SimplePDFViewer
};

const EditorViewers = {
    IMG: EditorImageViewer,
    PDF: EditorPDFViewer
};

const PreviewViewers = {
    IMG: PreviewImageViewer,
    PDF: PreviewPDFViewer
};

const unsupportedType = () =>
    <span
        style={{ color: 'red', fontSize: '13px' }}
    >{ gettext('Unsupported file type.') }
    </span>;

const nullViewer = () => <div/>;

const EditorViewer = observer(props => {
    let Viewer;
    const Viewers = props.editor.slide.layout === 'intro' ? SimpleViewers : EditorViewers;
    const file = props.file;

    if (!(file.fileVersion && file.fileVersion.file_type)) {
        Viewer = () => <Spinner/>;
    } else {
        Viewer = file
            ? file.fileVersion && Viewers[file.fileVersion.file_type]
                ? Viewers[file.fileVersion.file_type]
                : (file.fileVersion.file_type === 'PANORAMA' ? Viewers.IMG : unsupportedType)
            : nullViewer;
    }

    return (
        <Viewer
            {...props}
            dragDropMonitor={props.editor.dragDropMonitor}
            screen={props.editor}
        />
    );
});

EditorViewer.propTypes = {
    file: PropTypes.object,
    pins: PropTypes.array,
    editor: PropTypes.object,
    fileOverlay: PropTypes.any
};

EditorViewer.defaultProps = {
    useSimple: false
};

const PreviewViewer = props => {
    const file = props.file;
    const Viewers = props.screen.slide.layout === 'intro' ? SimpleViewers : PreviewViewers;
    const Viewer = file
        ? file.fileVersion && Viewers[file.fileVersion.file_type]
            ? Viewers[file.fileVersion.file_type]
            : (file.fileVersion.file_type === 'PANORAMA' ? Viewers.IMG : nullViewer)
        : nullViewer;

    return <Viewer {...props} />;
};

PreviewViewer.propTypes = {
    file: PropTypes.object,
    pins: PropTypes.array,
    screen: PropTypes.object,
    previewContainer: PropTypes.object
};

export {
    EditorViewer,
    PreviewViewer
};
