import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import styled from 'styled-components';
import HoverPopover from 'material-ui-popup-state/HoverPopover';

import { getPinSize } from '../../Pins/Pin';
import { pinPoint2D } from './point';
import TooltipContent from '../../Pins/Preview';

const S = {
    HoverPopover: styled(HoverPopover)`
        > .MuiPaper-elevation {
            box-shadow: var(--evelation-shadow-1);
        }
    `
};

/*
Renders a point at approximately the bottom right corner of the 3d pin.
This point is used as a starting popup location for the pin's tooltip
*/
const Point = (props, ref) => {
    const { hoveredPin, container, ...rest } = props;
    const { pin, position: AframePosition } = hoveredPin;
    const pinSize = getPinSize(pin.props.size);
    const { x, y } = AframePosition;
    const { position, top, left, units } = pinPoint2D.viewPoint(x, y, container);
    return (
        <Observer>{() => (
            <div
                ref={ref}
                style={{
                    position,
                    width: 0,
                    height: 0,
                    top: `${top + pinSize}${units}`,
                    left: `${left + pinSize}${units}`,
                    opacity: 0
                }}
                {...rest}
            />
        )}
        </Observer>
    );
};

Point.propTypes = {
    hoveredPin: PropTypes.object,
    container: PropTypes.object
};

const InvisiblePin = React.forwardRef(Point);

const PinInfo = ({ container, hoveredPin, screen }) => {
    const [anchorEl, setAnchorEl] = React.useState();
    const pinRef = React.createRef();

    React.useEffect(() => {
        setAnchorEl(pinRef.current);
    }, [pinRef]);

    return (
        <Observer>{() => (
            <React.Fragment>
                <InvisiblePin
                    container={container}
                    hoveredPin={hoveredPin}
                    ref={pinRef}
                />
                {
                    anchorEl && (
                        <S.HoverPopover
                            onMouseLeave={() => screen.unhoverPin() }
                            open={true}
                            anchorEl={anchorEl}
                            /*
                                In full screen, anything outside '#presentation-fs-preview' will be invisible.
                                So, if the popup is rendered in the root element, it will not be visible.
                                So, pass the `container` prop.
                            */
                            container={container}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                        >
                            <TooltipContent pin={hoveredPin.pin} />
                        </S.HoverPopover>
                    )
                }
            </React.Fragment>
        )}
        </Observer>
    );
};

PinInfo.propTypes = {
    hoveredPin: PropTypes.object,
    container: PropTypes.object,
    screen: PropTypes.object
};

export { PinInfo };
