import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';
import { MenuItem } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';

import { Appearance as A } from './util';
import api from '../../../../api';

import ShapeWithIconModel from './ShapeWithIcon/model';
import ShapeWithIconPresetPreview from './ShapeWithIcon/Preset';
import { Chess } from './Chess';

const S = {
    Presets: styled.div`
        overflow-y: scroll;
    `,
    IconMenuItem: styled(MenuItem)`
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        overflow: hidden;

        &:hover {
            background-color: transparent;
            #preset__remove {
                opacity: 1;
                z-index: 2;
            }
        }
    `,
    PresetWrapper: styled.div`
        position: absolute;
        margin: auto;
    `,
    PresetRemove: styled(IconButton)`
        position: absolute;
        top: 0;
        right: 0;
        border: 2px solid var(--bg-color);
        background-color: var(--text-primary-color);
        color: var(--bg-color);

        opacity: 0;
        transition: opacity 0.25s ease-in;
    `,
    AutoIconBadge: styled.div`
        position: absolute;
        bottom: 2px;
        left: 2px;
        
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        font-weight: bold;

        background-color: var(--text-primary-color);
        color: white;
        z-index: 2;
    `
};

/*
Default preset gets updated automatically on every pin appearance modification
and gets applied by default to all new pins.
*/
export class DefaultPresets {
    constructor (initialPreset = new ShapeWithIconModel()) {
        this.default = initialPreset;
    }

    setDefault (model) {
        this.default = model;
    }
};

const Presets = ({ model, onChange, exposeSave, ...rest }) => {
    const [presets, setPresets] = useState(Presets.PREDEFINED);

    const presetFromApi = (apiPreset) => {
        const Model = apiPreset.props.type === A.TYPES.SHAPE_WITH_ICON ? ShapeWithIconModel : null;
        return {
            ...apiPreset,
            props: Model.fromApi(apiPreset.props)
        };
    };

    useEffect(() => {
        api.presets.list()
            .then(({ results }) => setPresets(p => [...p, ...(results.map(presetFromApi))]));
    }, []);

    const save = (model) => {
        if (!presets.find(preset => model.compare(preset.props))) {
            api.presets.create(model)
                .then(apiPreset => {
                    setPresets(p => [...p, presetFromApi(apiPreset)]);
                });
        }
    };
    exposeSave(save);

    const apply = (model) => {
        onChange(model);
    };

    const remove = (e, id) => {
        e.stopPropagation(); // So that we don't click the preset behind
        api.presets.delete(id)
            .then(setPresets(p => p.filter(pr => pr.id !== id)));
    };

    return (
        <S.Presets {...rest}>
            { presets.map(({ id, isDefault, props: modelPreset }) => (
                <S.IconMenuItem key={id} onClick={ () => apply(modelPreset) }>
                    <Chess />
                    { modelPreset.type === A.TYPES.SHAPE_WITH_ICON &&
                        <S.PresetWrapper data-what='pin-edit-panel' data-which='apply-style'>
                            <ShapeWithIconPresetPreview model={modelPreset} />
                        </S.PresetWrapper>}

                    { !isDefault &&
                        <S.PresetRemove onClick={ e => remove(e, id) } id='preset__remove' data-what='pin-edit-panel' data-which='remove-style'>
                            <Icon icon='close' size='sm' />
                        </S.PresetRemove>}

                    { modelPreset.image === ShapeWithIconModel.IMAGES.AUTO.type &&
                        <S.AutoIconBadge title={gettext('Automatic image based on pin type')}>A</S.AutoIconBadge>}
                </S.IconMenuItem>
            )) }
        </S.Presets>
    );
};

Presets.PREDEFINED = [
    { id: 'DEFAULT_PIN', props: new ShapeWithIconModel() }
].map(p => {
    p.isDefault = true;
    return p;
});

Presets.propTypes = {
    model: PropTypes.object,
    onChange: PropTypes.func,
    exposeSave: PropTypes.func
};

export default Presets;
