import React from 'react';
import PropTypes from 'prop-types';

import ViewerContext from '../../ViewerContext';
import { pubsub } from '../../../../../../base';
import { COLUMNS, FILTERS, FILTER_TYPES } from '../../utils';
import S from '../styles';

const ValuesMenu = ({
    columnId,
    filter,
    type,
    close,
    onRadioClick,
    inputContents,
    onInputChange,
    updateError
}) => {
    const { store } = React.useContext(ViewerContext);
    const [from, setFrom] = React.useState(store.filters[columnId]?.from || '');
    const [to, setTo] = React.useState(store.filters[columnId]?.to || '');
    const refInputTo = React.useRef(null);
    const refRadioExact = React.useRef(null);
    const refRadioRange = React.useRef(null);
    const filterLikeNumber = COLUMNS[columnId].type !== 'string' &&
        COLUMNS[columnId].filterLikeType !== 'string';
    const showRange = filter === FILTERS.EQUALS_NUMBER.name;

    React.useEffect(() => {
        updateError();
    }, [store.records]);

    const onChangeFrom = e => {
        setFrom(e.target.value);
        store.setFilterFrom(columnId, e.target.value);
        pubsub.publish('ccad.filters.icon.update', columnId);
    };

    const onChangeTo = e => {
        setTo(e.target.value);
        store.setFilterTo(columnId, e.target.value);
        pubsub.publish('ccad.filters.icon.update', columnId);
    };

    const onKeyDown = e => {
        e.stopPropagation();
        if (e.key === 'Enter' || e.key === 'Escape') {
            close();
        }
    };

    const onKeyDownFrom = e => {
        // if you press enter in the "From" input, you should move on to "To"
        if (e.key === 'Enter') {
            refInputTo?.current?.focus();
        } else {
            onKeyDown(e);
        }
    };

    const onKeyDownRadio = e => {
        // if you press space or enter in a radio button, it should get toggled
        if (e.key === 'Enter' || e.key === ' ') {
            onRadioClick(e.target.dataset.value);
        } else {
            onKeyDown(e);
        }
    };

    return (
        <S.RangeMenu>
            <S.RangeMenuRow>
                {
                    showRange &&
                    filterLikeNumber &&
                    <S.RadioButtonContainer>
                        <S.RadioButton
                            onClick={onRadioClick}
                            state={type}
                            value={FILTER_TYPES.EXACT.name}
                            key='exactRadioBtn'
                            tabIndex={0}
                            onKeyDown={onKeyDownRadio}
                            ref={refRadioExact}
                            data-what='ccad-filters-radio-button'
                            data-which='exact'
                        />
                    </S.RadioButtonContainer>
                }
                <S.InputContainer>
                    <S.SingleValueInput
                        value={inputContents}
                        onChange={onInputChange}
                        disabled={type !== FILTER_TYPES.EXACT.name}
                        tabIndex={0}
                        autoFocus={true}
                        onKeyDown={onKeyDown}
                        wide={showRange && filterLikeNumber}
                        data-what='ccad-filters-input'
                        data-which='single-value'
                    />
                </S.InputContainer>
            </S.RangeMenuRow>
            {
                showRange &&
                filterLikeNumber &&
                <S.RangeMenuRow>
                    <S.RangeMenuColumn>
                        <S.RadioButtonContainer>
                            <S.RadioButton
                                onClick={onRadioClick}
                                state={type}
                                value={FILTER_TYPES.RANGE.name}
                                key='rangeRadioBtn'
                                tabIndex={0}
                                onKeyDown={onKeyDownRadio}
                                ref={refRadioRange}
                                data-what='ccad-filters-radio-button'
                                data-which='range'
                            />
                        </S.RadioButtonContainer>
                        <S.RadioButtonContainer />
                    </S.RangeMenuColumn>
                    <S.RangeMenuColumn style={{ margin: '0 16px 0 0' }}>
                        <S.RangeMenuText>
                            { pgettext('minimum value of a range', 'From') }
                        </S.RangeMenuText>
                        <S.RangeMenuText>
                            { pgettext('maxumum value of a range', 'To') }
                        </S.RangeMenuText>
                    </S.RangeMenuColumn>
                    <S.RangeMenuColumn>
                        <S.RangeInput
                            value={from}
                            onChange={onChangeFrom}
                            disabled={type !== FILTER_TYPES.RANGE.name}
                            tabIndex={0}
                            onKeyDown={onKeyDownFrom}
                            data-what='ccad-filters-input'
                            data-which='range-from'
                        />
                        <S.RangeInput
                            value={to}
                            onChange={onChangeTo}
                            disabled={type !== FILTER_TYPES.RANGE.name}
                            tabIndex={0}
                            onKeyDown={onKeyDown}
                            ref={refInputTo}
                            data-what='ccad-filters-input'
                            data-which='range-to'
                        />
                    </S.RangeMenuColumn>
                </S.RangeMenuRow>
            }
        </S.RangeMenu>
    );
};

ValuesMenu.propTypes = {
    columnId: PropTypes.string,
    filter: PropTypes.string,
    type: PropTypes.string,
    close: PropTypes.func,
    onRadioClick: PropTypes.func,
    inputContents: PropTypes.string,
    onInputChange: PropTypes.func,
    updateError: PropTypes.func
};

export default ValuesMenu;
