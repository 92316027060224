import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import Viewer from '../../FileViewers/ThumbnailViewer';

const Tour = props =>
    <div
        className='thumbnail-tour' // TODO: Add CSS for empty layout
        style={{
            background: 'lightgray'
        }}
    >
        {
            props.slide.home && props.slide.home.asset
                ? <Viewer
                    file={props.slide.home.asset}
                />
                : <div className='ib-slide-empty'>{ gettext('Tour is empty') }
                </div>
        }
    </div>;

Tour.propTypes = {
    slide: PropTypes.object.isRequired
};

export default observer(Tour);
