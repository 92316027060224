import { SVGs as ProviderIcons } from './storage-providers-icons';

class StorageProvider {
    static fromStorageType (storageType) {
        return StorageProvider.all[storageType || Settings.DEFAULT_STORAGE];
    }

    static guessFromPreviewPath (previewPath = StorageProvider.S3.displayName) {
        return Object
            .values(StorageProvider.all)
            .find(provider => previewPath.startsWith(provider.displayName));
    }

    constructor (storageType, icon, displayName) {
        this.storageType = storageType;
        this.icon = icon;
        this.icon16 = `${icon}-16`;
        this.displayName = gettext(displayName);
        this.svgIcon = ProviderIcons[this.storageType];
    }
}

StorageProvider.S3 = new StorageProvider('s3', 'icon-home', 'Home');
StorageProvider.INTERNAL = new StorageProvider('internal', 'icon-home', 'Internal');
StorageProvider.GOOGLE_DRIVE = new StorageProvider('google_drive', 'icon-gdrive', 'Google Drive');
StorageProvider.DROPBOX = new StorageProvider('dropbox', 'icon-dropbox', 'Dropbox');
StorageProvider.ONE_DRIVE = new StorageProvider('one_drive', 'icon-onedrive', 'OneDrive');

StorageProvider.all = {
    s3: StorageProvider.S3,
    internal: StorageProvider.INTERNAL,
    google_drive: StorageProvider.GOOGLE_DRIVE,
    dropbox: StorageProvider.DROPBOX,
    one_drive: StorageProvider.ONE_DRIVE

};

export {
    StorageProvider
};
