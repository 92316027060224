/*
This is the hidden pin that is standing in the background of a draggable item in Carousel.
Usually hidden behind a picture, visible only while dragging over the canvas.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { PinUI } from './PinUI';
import { inject } from '../Store';
import ShapeWithIconModel from '../FileViewers/Tour/PinEditors/Appearance/ShapeWithIcon/model';

const DEFAULT_PIN_PRESET = new ShapeWithIconModel();

class DraggedPin extends React.Component {
    element = React.createRef();

    componentDidMount () {
        /*
        TODO:
        Investigate this. Without it, dragging a pin from Tour/Others files panel positions the pin
        at x0,y0. I suspect that the EditorPanoramaViewer.setMode() re-renders the side panel
        */
        this.props.dragDropMonitor.setDraggedPin(
            this.props.isDragging ? this.element.current : null
        );
    }

    componentDidUpdate (prevProps) {
        if (prevProps.isDragging !== this.props.isDragging) {
            this.props.dragDropMonitor.setDraggedPin(
                this.props.isDragging ? this.element.current : null
            );
        }
    }

    render () {
        return (
            <div
                className='pin draggingPin'
                ref={this.element}
                style={{
                    opacity: this.props.isDragging ? 1 : 0
                }}
            >
                <PinUI
                    style={{
                        transform: `scale(${this.props.dragDropMonitor.editor.slide.scaleFactor})`,
                        transformOrigin: 'top left'
                    }}
                    appearanceModel={
                        this.props.ui.inMapEditor
                            ? DEFAULT_PIN_PRESET
                            : this.props.ui.defaultPresets.default
                    }
                />
            </div>
        );
    }
}
DraggedPin.propTypes = {
    dragDropMonitor: PropTypes.object,
    ui: PropTypes.object,
    isDragging: PropTypes.bool
};
DraggedPin.defaultProps = {
    isDragging: false
};

export default inject('ui')(observer(DraggedPin));
