import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';

import CommentField from './CommentField';
import utils from './utils';

const CommentEdit = ({
    comment,
    commentsStore,
    onUpdate,
    onCancel
}) => {
    const editField = useRef();

    useEffect(() => {
        editField.current.setContent(comment.content);
        editField.current.setFocus();
    }, []);

    const cancel = () => {
        editField.current.clear();
        onCancel();
    };

    const update = () => {
        if (editField.current.isEmpty ||
            editField.current.isProcessing() ||
            editField.current.hasError()
        ) {
            cancel();
            return Promise.reject();
        }

        editField.current.setProcessing(true);

        const updatedComment = {
            id: comment.id,
            content: editField.current.getContent()
        };

        const mentions = utils.getMentions(updatedComment.content);
        return commentsStore
            .checkMentionPermissionsAndAlert(mentions)
            .then(() => onUpdate(updatedComment, mentions));
    };

    return (
        <CommentField
            ref={editField}
            placeholder={comment.content}
            onEnter={update}
            onEscape={cancel}
            commentsStore={commentsStore}
        >
            <a
                className='comment-action-btn'
                onClick={cancel}
                ga-action={commentsStore.GASettings.action}
                ga-label='Cancel_Edit_Comment'
            >{ gettext('Cancel') }</a>
            <Button
                onClick={update}
                ga-action={commentsStore.GASettings.action}
                ga-label='Finish_Edit_Comment'
                style={{ lineHeight: '24px', marginLeft: 8 }}
                disabled={editField.current?.hasError()}
            >{ gettext('Finish') }</Button>
        </CommentField>
    );
};

export default CommentEdit;

CommentEdit.propTypes = {
    comment: PropTypes.object,
    commentsStore: PropTypes.object.isRequired,
    onUpdate: PropTypes.func,
    onCancel: PropTypes.func
};
