import { baseApi, COMMENTS_API_ROOT } from '../../comments/api';
import { request } from '../../base/axios';

const presentationApi = {
    registerNotificationListener (slide) {
        if (!Settings.user.isAuthenticated) return;

        return request({
            method: 'POST',
            url: `${COMMENTS_API_ROOT}comment-notification-listener/`,
            data: {
                resource: {
                    resource_type: 'slide',
                    object: {
                        id: slide.id,
                        presentation: slide.presentation
                    }
                }
            }
        });
    },
    loadCommentsForPresentation (presentation) {
        return request({
            method: 'GET',
            url: `${COMMENTS_API_ROOT}presentation/${presentation}/`
        });
    },
    submitComment (slide, comment, mentions) {
        return request({
            method: 'POST',
            url: `${COMMENTS_API_ROOT}comment/`,
            data: {
                content: comment,
                mentions,
                resource: {
                    resource_type: 'slide',
                    object: {
                        id: slide.id,
                        presentation: slide.presentation
                    }
                }
            }
        });
    },
    submitReply (resource, comment, mentions) {
        return request({
            method: 'POST',
            url: `${COMMENTS_API_ROOT}reply/`,
            data: {
                ...comment,
                mentions
            }
        });
    },
    updateComment (resource, comment, mentions = []) {
        const { id } = comment;
        return request({
            method: 'PATCH',
            url: `${COMMENTS_API_ROOT}comment/${id}/`,
            data: {
                ...comment,
                mentions
            }
        });
    },
    updateReply (resource, comment, mentions) {
        const { content, id } = comment;
        return request({
            method: 'PATCH',
            url: `${COMMENTS_API_ROOT}reply/${id}/`,
            data: {
                content,
                mentions
            }
        });
    },
    deleteComment (resource, commentId) {
        return request({
            method: 'DELETE',
            url: `${COMMENTS_API_ROOT}comment/${commentId}/`
        });
    },
    deleteReply (resource, replyId) {
        return request({
            method: 'DELETE',
            url: `${COMMENTS_API_ROOT}reply/${replyId}/`
        });
    },
    getComment (resource, commentId) {
        return request({
            method: 'GET',
            url: `${COMMENTS_API_ROOT}comment/${commentId}/`
        });
    },
    getReply (resource, replyId) {
        return request({
            method: 'GET',
            url: `${COMMENTS_API_ROOT}reply/${replyId}/`
        });
    }
};

export default {
    ...baseApi,
    ...presentationApi
};
