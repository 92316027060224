import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import * as D from '@vectorworks/vcs-ui/dist/lib/Dialog';

import { makeRef } from './utils';
import { pinPoint } from './point';
import { PinInfo } from './Popover';
import { promiseUtils } from '../../../lib';
import { scene as sceneTemplate, pins as pinsTemplate } from './templates';
import { withLoadDependency } from '../../common/loaderHOCs';
import isSupported from '~static/file-viewer/panorama/device';
import loadAframe from '~static/file-viewer/panorama/aframe-loader';
import { pos2Rot } from '~static/file-viewer/panorama/math-funcs';
import LoadingSpinner from '../../common/LoadingSpinner';
import PanoramaScene from '../../../Components/Panorama/PanoramaScene';
import SceneStore from './SceneStore';
import { Dialog, ConfirmDialogViewModel } from '../../../base/dialog';
import { inject } from '../../Store';
import { FileTypeCanvas } from './FileTypeCanvas';
import { LinkPin, AssetPin, TextPin, Portal } from '../../models/tour';
import { TextPreview } from './TextPreview';

const PORTAL_ANIMATION_ENABLED = false;
const PORTAL_ZOOM_ANIMATION_ENABLED = false;

class PreviewPanoramaViewer extends React.Component {
    name = 'PreviewPanoramaViewer';

    constructor (props) {
        super(props);
        this.sceneRef = makeRef();
        this.sceneStore = new SceneStore(this.props.preview);

        this.textPinsDialogStore = new D.DialogStore();
    }

    doRotationAnimation (portal) {
        if (!PORTAL_ANIMATION_ENABLED) {
            return Promise.resolve(null);
        }
        const scene = this.sceneRef.current;
        const rot = pos2Rot(portal.position);
        const targetPitch = rot.x;
        const targetYaw = rot.y;
        scene.emit('camera-animation-request', {
            targetPitch,
            targetYaw,
            data: { portal }
        });
        return promiseUtils.promisifyEvent(scene, 'camera-animation-end');
    }

    doZoomAnimation (portal) {
        if (!PORTAL_ZOOM_ANIMATION_ENABLED) {
            return Promise.resolve(null);
        }
        const scene = this.sceneRef.current;
        scene.emit('camera-zoom-animation-request', {});
        return promiseUtils.promisifyEvent(scene, 'camera-zoom-animation-end');
    }

    handlePortalPreview = (portal) => {
        const scene = this.sceneRef.current;
        const isVRMode = scene.is('vr-mode');
        const animation = (PORTAL_ANIMATION_ENABLED && !isVRMode)
            ? this.doRotationAnimation(portal)
            : (PORTAL_ZOOM_ANIMATION_ENABLED && !isVRMode)
                ? this.doZoomAnimation(portal)
                : Promise.resolve(null);
        animation
            .then(() => this.props.preview.goto(
                portal.toPlace,
                { cameraRotation: portal.toPlaceProps.cameraRotation || null }
            ));
    };

    handleAssetPinPreview = (pin) => {
        const previewItem = { asset: pin.asset };
        this.props.fileViewStore.file.open(previewItem, [previewItem]);
    };

    handleTextPinPreview = (textPin) => {
        this.textPinsDialogStore.open({
            component: TextPreview,
            params: { textPin }
        });
    };

    handleLinkPinPreview = (linkPin, ev) => {
        const linkDOMEl = document.createElement('a');
        const href = linkPin.linkState.link.startsWith('http')
            ? linkPin.linkState.link
            : `https://${linkPin.linkState.link}`;
        linkDOMEl.href = href;
        if (linkPin.linkState.inNewTab) {
            linkDOMEl.target = '_blank';
        }
        linkDOMEl.click(ev);
        linkDOMEl.remove();
    };

    sceneEventHandlers = {
        'pin-click': ev => {
            this.props.preview.unhoverPin();
            const pin = this.props.preview.place.pins
                .find(p => p.id === ev.detail.id);
            if (!pin) return;

            return [
                [Portal, this.handlePortalPreview],
                [AssetPin, this.handleAssetPinPreview],
                [TextPin, this.handleTextPinPreview],
                [LinkPin, this.handleLinkPinPreview]
            ].find(([pinType, _]) => pin instanceof pinType)[1](pin, ev);
        },
        'pin-mouseenter': ev => {
            if (!ev.detail.event.detail.intersection) return;
            const pin = this.props.preview.place.pins.find(p => p.id === ev.detail.id);
            const scene = this.sceneRef.current;
            const position = pinPoint.sceneToCanvas(
                ev.detail.event.detail.intersection.object,
                scene
            );
            this.props.preview.hoverPin(pin, position);
        },
        'pin-mouseleave': ev => {
            this.props.preview.unhoverPin();
        },
        'enter-vr': ev => {
            this.props.preview.controller.hide();
        },
        'exit-vr': ev => {
            this.props.preview.controller.show();
        }
    };

    componentDidMount () {
        this.sceneStore.precalcPinPositions();

        if (Settings.device.isMobile && Settings.device.isIOS) {
            const id = setTimeout(() => {
                // iOS 13+ devices
                if (window.DeviceOrientationEvent && typeof window.DeviceOrientationEvent.requestPermission === 'function') {
                    Dialog.open({
                        component: 'dialog-motion-sensors-request-permission'
                    }).result.then(() => {
                        window.DeviceOrientationEvent.requestPermission();
                    });
                } else {
                    // devices under iOS 13
                    Dialog.open({
                        component: 'alert',
                        ctx: {
                            template: { name: 'dialog-ios-sensors-disabled' }
                        }
                    });
                }
                this.notifySensorsDisabled &&
                window.removeEventListener('deviceorientation', this.notifySensorsDisabled);
            }, 500);
            this.notifySensorsDisabled = () => clearTimeout(id);
            window.addEventListener('deviceorientation', this.notifySensorsDisabled);
        }

        const scene = this.sceneRef.current;
        this.onSceneLoad = () => {
            scene.removeEventListener('loaded', this.onSceneLoad);
            const vrPossible = scene && isSupported() &&
                (scene.isMobile ||
                    scene.checkHeadsetConnected &&
                        scene.checkHeadsetConnected());
            this.props.preview.controller.setProperty('vrPossible', vrPossible);
        };
        scene.addEventListener('loaded', this.onSceneLoad);
    }

    componentDidUpdate (prevProps) {
        if (this.props.preview !== prevProps.preview) {
            this.sceneStore.reset(this.props.preview);
            this.sceneStore.precalcPinPositions();
        }
    }

    render () {
        const hoveredPin = this.props.preview.hoveredPin;
        return (
            <div className='fileview-component-loader panorama-viewer' data-what='file-viewer'>
                { this.props.children }
                <LoadingSpinner resource={this.sceneStore.image}/>
                <PanoramaScene
                    sceneRef={this.sceneRef}
                    eventHandlers={this.sceneEventHandlers}
                    sceneProps={{
                        autoRotateEnabled: true,
                        vrModeEnabled: true,
                        sceneChildren: pinsTemplate,
                        skyAnimation: true
                    }}
                    sceneStore={this.sceneStore}
                    sceneTemplate={sceneTemplate}
                />
                <FileTypeCanvas pins={this.props.preview.place.pins} />
                <D.DialogContainer dialogContainer={this.textPinsDialogStore} />
                { hoveredPin &&
                <PinInfo
                    container={this.props.previewContainer.current}
                    hoveredPin={hoveredPin}
                    screen={this.props.preview}
                />}
            </div>
        );
    };
};

PreviewPanoramaViewer.propTypes = {
    preview: PropTypes.object,
    fileViewStore: PropTypes.object,
    previewContainer: PropTypes.object
};

export default withLoadDependency(loadAframe)(inject('fileViewStore')(observer(PreviewPanoramaViewer)));

if (process.env.GFX_SHORTCUTS) {
    window.gfx = {
        sky: {
            reset () {
                this.object3D.setRotationFromEuler(new THREE.Euler(0, 0, 0, 'XYZ'));
            },
            rotateEuler (x, y, z) {
                this.object3D.setRotationFromEuler(new THREE.Euler(x, y, z, 'XYZ'));
            },
            applyQ (x, y, z) {
                const q = new THREE.Quaternion()
                    .setFromEuler(new THREE.Euler(x, y, z, 'XYZ'));
                this.object3D.quaternion.multiply(q);
            },
            mulQ (q) {
                this.object3D.quaternion.multiply(q);
            }
        },
        camera: {
            reset () {
                window.gfx.scene.emit('camera-rotation-request', { x: 0, y: 0 });
            },
            rotateTo (pitch, yaw) {
                const prevQ = this.object3D.quaternion.clone();
                window.gfx.scene.emit('camera-rotation-request', { x: pitch, y: yaw });
                return prevQ.inverse().multiply(this.object3D.quaternion);
            }
        }
    };
    Object.defineProperty(window.gfx, 'scene', {
        get () {
            return document.querySelector('a-scene');
        }
    });
    Object.defineProperty(window.gfx.sky, 'object3D', {
        get () {
            return window.gfx.scene.querySelector('[sky-loader]').object3D;
        }
    });
    Object.defineProperty(window.gfx.camera, 'object3D', {
        get () {
            return window.gfx.scene.querySelector('.camera').object3D;
        }
    });
    Object.defineProperty(window.gfx, 'lookControls', {
        get () {
            return window.gfx.scene.querySelector('a-entity.camera').components['vcs-look-controls'];
        }
    });
}

!ko.components.isRegistered('dialog-motion-sensors-request-permission') &&
ko.components.register('dialog-motion-sensors-request-permission', {
    viewModel: ConfirmDialogViewModel,
    template: { element: 'dialog-motion-sensors-request-permission' }
});
