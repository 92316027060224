import React from 'react';
import PropTypes from 'prop-types';

import { observable, computed, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { addClass } from '../../lib/dom-utils';
import { commonPropTypes } from './utils';
import { makeDisplayable } from './VideoViewer';
import { makeRef } from './PanoramaViewer';
import { withLoadDependency } from '../../iboards/common/loaderHOCs';
import loadAframe from '~static/file-viewer/panorama/aframe-loader';
import PanoramaScene from '../../Components/Panorama/PanoramaScene';
import { queryString, utils } from '../../lib';
import VideoController from './Controllers/VideoController';
import Spinner from '../../Components/Spinner';

function createVideo (url, eventHandlers) {
    const video = document.createElement('video');
    video.id = `panoramic-video-${utils.generateGuid()}`;
    video.crossOrigin = '';
    video.src = url;
    addClass(video, 'cached-video');
    document.body.append(video);

    Object.entries(eventHandlers).forEach((entry) => {
        video.addEventListener(entry[0], entry[1]);
    });
    return `#${video.id}`;
}

function removeVideo (videoId) {
    // Remove all event listeners
    videoId && $(videoId).off();
    videoId && $(videoId).remove();
}

const sceneTemplate = props => `
    <a-scene
        bind__vcs-vr-mode-ui="enabled: ${!!props.vrModeEnabled} && !!controller.vrMode"
        vr-mode-ui="enabled: false"
        embedded
    >
        <a-videosphere
            rotation="0 180 0"
            bind__src="video"
            radius="10"
        ></a-videosphere>
        <a-entity
            class="camera"
            camera="near: 0.005; far: 20"
            position rotation
            bind__vcs-look-controls="enabled: !!controller.lookControls;
                reverseMouseDrag: true; reverseTouchDrag: true"
        >
        </a-entity>
    </a-scene>
`;

class SceneStore {
    video;
    controller = {
        lookControls: true,
        vrMode: false
    };

    constructor (screen, options = {}) {
        makeObservable(this, {
            video: observable,
            controller: observable,
            reset: action,
            setState: action,
            controlScene: action
        });

        this.reset(options);
    }

    reset (options = {}) {
        this.controller = {
            lookControls: true,
            vrMode: false
        };
        this.video = undefined;
        Object.assign(this, options);
    }

    setState (prop, value) {
        this[prop] = value;
    }

    setScene (scene) {
        this.scene = scene;
    }

    clearScene () {
        this.scene = undefined;
    }

    controlScene (prop, value) {
        this.controller[prop] = value;
    }
}

class PanoramicVideoViewer extends React.Component {
    time = 0;
    duration = 0;
    paused = true;
    volume = 0;
    buffered = 0;
    timeDrag = false;
    muted = false;

    name = 'PanoramicVideoViewer';
    canPlay = true;
    videoUrl = '';

    constructor (props) {
        super(props);

        makeObservable(this, {
            time: observable,
            duration: observable,
            paused: observable,
            volume: observable,
            buffered: observable,
            timeDrag: observable,
            muted: observable,
            displayTime: computed,
            displayDuration: computed,
            progress: computed,
            setVideo: action,
            togglePause: action,
            timeUpdate: action,
            durationChange: action,
            updateTime: action,
            toggleVolume: action,
            updateVolume: action,
            toggleController: action
        });

        this.sceneRef = makeRef();
        this.sceneStore = new SceneStore();
    }

    componentDidMount () {
        this.setVideo(this.props.store.file.sourceUrl);
    }

    componentDidUpdate (prevProps) {
        if (this.props.store.file.sourceUrl !== prevProps.sourceUrl) {
            this.props.store.file.sourceUrl && this.setVideo(this.props.store.file.sourceUrl);
        }
    }

    componentWillUnmount () {
        removeVideo(this.sceneStore.video);
        this.sceneStore.reset();
    }

    get displayTime () {
        return makeDisplayable(this.time);
    }

    get displayDuration () {
        return makeDisplayable(this.duration);
    }

    get progress () {
        return `${(this.time / this.duration) * 100}%`;
    }

    setVideo = videoUrl => {
        if (this.videoUrl === videoUrl) return;
        this.time = 0;
        this.duration = 0;
        this.paused = true;
        this.volume = 0;
        this.buffered = 0;
        this.timeDrag = false;
        this.muted = false;
        this.videoUrl = videoUrl;
        this.props.store.startLoading();

        const videoPromise =
            Settings.offlinePresentation
                ? Promise.resolve(this.props.store.file.offlineSourceUrlResp)
                : $.getJSON(queryString.buildUrl(
                    videoUrl,
                    {
                        response_type: 'data',
                        viewable: 'off'
                    }
                ));

        videoPromise.catch(err => {
            if (err?.status === 404) {
                this.props.store.file.setNotFoundFile();
            }
        }).then(data => {
            // Remove previous video, if there's one
            this.sceneStore.video && removeVideo(this.sceneStore.video);

            const videoId = createVideo(data.url, {
                timeupdate: this.timeUpdate,
                durationchange: this.durationChange
            });
            this.sceneStore.setState('video', videoId);

            this.props.store.endLoading();
        }, () => {
            this.props.store.endLoading();
        });
    };

    togglePause = () => {
        this.ensureVideo();
        this.paused = !this.paused;
        this.paused ? this.video.pause() : this.video.play();
    };

    timeUpdate = e => {
        this.time = Math.floor(e.target.currentTime);
        this.paused = e.target.paused;
        if (e.target.readyState === 0) return;

        const buf = e.target.buffered;
        const bufLen = buf.length - 1;
        this.buffered = Math.floor(((buf.end(bufLen) - buf.start(bufLen)) / this.duration) * 100);
    };

    durationChange = e => {
        this.duration = Math.floor(e.target.duration);
        this.volume = e.target.volume;
        this.paused = e.target.paused;
    };

    updateTime = event => {
        this.ensureVideo();
        this.video.currentTime = event.target.value;
        this.time = Math.floor(this.video.currentTime);
    };

    toggleVolume = () => {
        this.ensureVideo();
        this.muted = !this.muted;
        this.video.muted = this.muted;
        if (this.muted) {
            this.volume = 0;
        } else {
            this.volume = this.video.volume;
        }
    };

    updateVolume = event => {
        this.ensureVideo();
        this.video.volume = event.target.value / 100;
        this.volume = this.video.volume;
    };

    ensureVideo = () => {
        if (!this.video) {
            this.video = document.querySelector(this.sceneStore.video);
        }
    };

    toggleController = () => {
        this.props.store.ui.controller.toggle();
    };

    enterVR = () => {
        const vrBtn = document.querySelector('#a-enter-vr-button');
        vrBtn.click();
    };

    render () {
        return (
            <div
                className='fileview-component-loader panorama-viewer'
                data-what='file-viewer'
                { ...this.props.controllerTogglers }
            >
                { this.props.viwerControllerComponent
                    ? this.props.viwerControllerComponent(this, this.props.store)
                    : <VideoController viewer={this} store={this.props.store}/>}

                {/*
                Do not create panorama scene untill we're sure we have some video data.
                It's safe to say that when we have video duration, the video can be loaded/played.
            */}

                {
                    this.duration > 0
                        ? <PanoramaScene
                            sceneRef={this.sceneRef}
                            eventHandlers={{}}
                            sceneProps={{
                                ...this.props.sceneProps,
                                vrModeEnabled: true
                            }}
                            sceneStore={this.sceneStore}
                            sceneTemplate={sceneTemplate}
                        />
                        : <Spinner/>
                }
            </div>
        );
    }
}

PanoramicVideoViewer.propTypes = {
    ...commonPropTypes,
    viwerControllerComponent: PropTypes.func
};

export default withLoadDependency(loadAframe)(observer(PanoramicVideoViewer));
