import React from 'react';
import PropTypes from 'prop-types';

const Icon = props =>
    (<span
        className={`icon ${props.icon}`}
        onClick={props.onClick && props.onClick}
    />);

export default Icon;

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func
};
