function LoadingManager (onLoad, onProgress, onError) {
    const scope = this;
    let isLoading = false;
    let itemsLoaded = 0;
    let itemsTotal = 0;

    this.onStart = undefined;
    this.onLoad = onLoad;
    this.onProgress = onProgress;
    this.onError = onError;

    this.itemStart = function (url) {
        itemsTotal++;

        if (isLoading === false) {
            if (scope.onStart !== undefined) {
                scope.onStart(url, itemsLoaded, itemsTotal);
            }
        }
        isLoading = true;
    };

    this.itemEnd = function (url) {
        itemsLoaded++;

        if (scope.onProgress !== undefined) {
            scope.onProgress(url, itemsLoaded, itemsTotal);
        }

        if (itemsLoaded === itemsTotal) {
            isLoading = false;
            if (scope.onLoad !== undefined) {
                scope.onLoad();
            }
        }
    };

    this.itemError = function (url) {
        if (scope.onError !== undefined) {
            scope.onError(url);
        }
    };
}

const DefaultLoadingManager = new LoadingManager();

function Loader () {
    this.manager = DefaultLoadingManager;
}

Object.assign(Loader.prototype, {
	load: function (url, onLoad, onProgress, onError) { // eslint-disable-line
        if (this.path !== undefined) url = this.path + url;

        const scope = this;

        // Check for data: URI
        const dataUriRegex = /^data:(.*?)(;base64)?,(.*)$/;
        const dataUriRegexResult = url.match(dataUriRegex);

        // Safari can not handle Data URIs through XMLHttpRequest so process manually
        if (dataUriRegexResult) {
            const mimeType = dataUriRegexResult[1];
            const isBase64 = !!dataUriRegexResult[2];
            let data = dataUriRegexResult[3];

            data = window.decodeURIComponent(data);
            if (isBase64) data = window.atob(data);

            try {
                let response;
                const responseType = (this.responseType || '').toLowerCase();

                response = new ArrayBuffer(data.length);
                const view = new Uint8Array(response);

                for (let i = 0; i < data.length; i++) {
                    view[i] = data.charCodeAt(i);
                }
                if (responseType === 'blob') {
					response = new Blob([response], { type: mimeType }); // eslint-disable-line
                }
                // Wait for next browser tick
                window.setTimeout(function () {
                    if (onLoad) onLoad(response);
                    scope.manager.itemEnd(url);
                }, 0);
            } catch (error) {
                // Wait for next browser tick
                window.setTimeout(function () {
                    if (onError) onError(error);
                    scope.manager.itemEnd(url);
                    scope.manager.itemError(url);
                }, 0);
            }
        } else {
			var request = new XMLHttpRequest(); // eslint-disable-line
            request.open('GET', url, true);
            request.addEventListener('load', function (event) {
                const response = event.target.response;

                if (this.status >= 200 && this.status < 300) {
                    if (onLoad) onLoad(response);
                    scope.manager.itemEnd(url);
                } else if (this.status === 0) {
                    // Some browsers return HTTP Status 0 when using non-http protocol
                    // e.g. 'file://' or 'data://'. Handle as success.
                    console.warn('Loader: HTTP Status 0 received.');

                    if (onLoad) onLoad(response);
                    scope.manager.itemEnd(url);
                } else {
                    if (onError) onError(event);
                    scope.manager.itemEnd(url);
                    scope.manager.itemError(url);
                }
            }, false);

            if (onProgress !== undefined) {
                request.addEventListener('progress', function (event) {
                    onProgress(event);
                }, false);
            }

            request.addEventListener('error', function (event) {
                if (onError) onError(event);
                scope.manager.itemEnd(url);
                scope.manager.itemError(url);
            }, false);

            if (this.responseType !== undefined) request.responseType = this.responseType;
            if (this.withCredentials !== undefined) request.withCredentials = this.withCredentials;

            for (const header in this.requestHeader) {
                request.setRequestHeader(header, this.requestHeader[header]);
            }
            request.send(null);
        }

        scope.manager.itemStart(url);
        return request;
    },
    setResponseType: function (value) {
        this.responseType = value;
        return this;
    },
    setWithCredentials: function (value) {
        this.withCredentials = value;
        return this;
    },
    setHeader: function (value) {
        this.requestHeader = this.requestHeader || {};
        Object.assign(this.requestHeader, value);
        return this;
    }
});

export {
    Loader
};
