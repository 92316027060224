import React from 'react';

import { observer } from 'mobx-react';
import { reaction, observable, makeObservable, runInAction } from 'mobx';
import PropTypes from 'prop-types';

import ScaleDown from './ScaleDown';
import Spinner from '../../Components/Spinner';

class FakeLazyImageInner extends React.Component {
    state = {
        mounted: false,
        size: {
            height: 0
        }
    };

    componentDidMount () {
        this.mount();

        this.reactionDisposer = reaction(
            () => this.props.file,
            src => this.setSize(),
            {
                fireImmediately: true
            }
        );
    }

    componentWillUnmount () {
        this.setState({ mounted: false });
        this.reactionDisposer();
    }

    mount = () => {
        this.screen = document.querySelector('#presentation-screen') ||
            document.querySelector('.image-ctx-wrapper');

        if (!this.screen) {
            setTimeout(this.mount, 10);
        } else {
            this.setState({ mounted: true }, this.setSize);
        }
    };

    setSize = () => {
        if (!this.state.mounted) return;
        const file = this.props.file;
        if (file.state !== 'ready') return;

        const size = this.calculateCorrectSize(file.params.size);
        this.setState({ size });
    };

    calculateCorrectSize = imgSize => {
        const { clientWidth, clientHeight } = this.screen;
        if (imgSize.height < clientHeight || imgSize.width < clientWidth) {
            return imgSize;
        } else {
            const scale = clientHeight / imgSize.height;
            return {
                height: clientHeight,
                width: imgSize.width * scale
            };
        }
    };

    hideSpinner = () => {
        this.spinner.ref.style.display = 'none';
    };

    render () {
        const file = this.props.file;

        return (
            <div className='img-fake-wrapper'>
                <Spinner ref={spinner => { this.spinner = spinner; } }/>
                {
                    this.state.mounted &&
                        <img
                            className='img-fake'
                            style={{
                                height: `${this.state.size.height}px`,
                                width: `${this.state.size.width}px`
                            }}
                            onLoad={this.hideSpinner}
                            src={file.fileVersion.thumbnail}
                        />
                }
            </div>
        );
    }
};

FakeLazyImageInner.propTypes = {
    file: PropTypes.object
};

const FakeLazyImage = observer(FakeLazyImageInner);

class LazyImage extends React.Component {
    loaded = false;

    constructor (props) {
        super(props);

        makeObservable(this, {
            loaded: observable
        });
    }

    componentDidMount () {
        this.srcReactionDisposer = reaction(
            () => this.props.src,
            src => this.hideImage(),
            {
                fireImmediately: true
            }
        );
    }

    componentWillUnmount () {
        this.srcReactionDisposer();
    }

    onLoad = () => {
        this.fadeIn(this.img);
    };

    fadeIn = el => {
        let start = null;
        const propOnLoad = this.props.eventHandlers.onLoad;
        const self = this;

        function increase (timestamp) {
            if (!start) {
                start = timestamp;
            }
            const progress = timestamp - start;
            const opacity = Math.min(progress / 320, 1);
            el.style.opacity = opacity;
            if (Math.abs(opacity - 1) < Number.EPSILON) {
                propOnLoad();
                runInAction(() => {
                    self.loaded = true;
                });
            } else {
                window.requestAnimationFrame(increase);
            }
        }
        window.requestAnimationFrame(increase);
    };

    hideImage = () => {
        this.img.style.opacity = 0;
        this.loaded = false;
    };

    setRefs = node => {
        this.props.reference(node);
        this.img = node;
    };

    onDragStart = ev => ev.preventDefault();

    render () {
        return (
            <React.Fragment>
                <ScaleDown
                    container={this.props.containerSelector}
                    selector='.img-real'
                    file={this.props.placeholder}
                >
                    <img
                        className='img-real'
                        src={this.props.src}
                        ref={this.setRefs}
                        {...this.props.eventHandlers}
                        onLoad={this.onLoad}
                        onDragStart={this.onDragStart}
                    />
                </ScaleDown>
                {
                    !this.loaded && !this.props.hideFakeLazyImage &&
                        <FakeLazyImage
                            file={this.props.placeholder}
                            ref={this.fakeImage}
                        />
                }
            </React.Fragment>
        );
    }
};

LazyImage.propTypes = {
    src: PropTypes.string,
    reference: PropTypes.func,
    eventHandlers: PropTypes.object,
    placeholder: PropTypes.object,
    containerSelector: PropTypes.string,
    hideFakeLazyImage: PropTypes.bool
};

export default observer(LazyImage);
