import { observable, computed, action, runInAction, makeObservable } from 'mobx';

import { slideFromApi } from '../models/slide';
import itemContainerMixin from '../models/item-container';
import { saveMixin } from '../models/save';
import api from '../api';

class SlideStore {
    slides = [];

    constructor (root) {
        makeObservable(this, {
            slides: observable,
            setSlides: action,
            index: computed,
            arrayIndex: computed,
            renameSlide: action,
            createOrUpdateSlideFromData: action
        });

        this.root = root;
        this.items = this.slides;
    }

    setSlides (slides) {
        this.slides.replace(slides.map(data => slideFromApi(this.root, data)));
    }

    get index () {
        return new Map(this.slides.map(s => [s.id, s]));
    }

    get arrayIndex () {
        return new Map(this.slides.map((s, index) => [s.id, index]));
    }

    createBoard = data => {
        return api.slide.createSlide(this.root.id, Object.assign(data, { slide_type: 'board' }))
            .then(data => {
                this.root.ui.toggle('carousel', true);
                return slideFromApi(this.root, data);
            });
    };

    createTour = data => {
        return api.slide.createSlide(this.root.id, Object.assign(data, { slide_type: 'virtual_tour' }))
            .then(data => {
                this.root.ui.toggle('carousel', true);
                return slideFromApi(this.root, data);
            });
    };

    remove (slides) {
        return Promise.all(slides.map(slide =>
            api.slide.removeSlide(slide)
                .then(() => runInAction(() => {
                    slide.cleanUp();
                    this.slides.remove(slide);
                }))
        ));
    }

    duplicate (slide) {
        return api.slide.duplicateSlide(this.root.id, slide)
            .then(data => this.createOrUpdateSlideFromData(data));
    }

    moveSlide (fromIndex, toIndex) {
        return this.moveItem(fromIndex, toIndex);
    }

    renameSlide (slide, title) {
        slide.title = title;
        this.scheduleSave(this.saveCommands.updateSlide, slide, { title });
    }

    createOrUpdateSlideFromData (data) {
        const slide = slideFromApi(this.root, data);
        const slideIndex = this.slides.findIndex(s => s.id === data.id);
        slideIndex === -1
            ? this.slides.push(slide)
            : this.assets[slideIndex] = slide;
        this.sortItems();
        return slide;
    }

    saveCommands = {
        moveItem: {
            command (slide, order) {
                return api.slide.updateSlide(slide, { order })
                    .then(data => this.updateOrder(slide, data.order));
            },
            params: {
                noReduce: true
            }
        },
        updateSlide: {
            command (slide, data) {
                return api.slide.updateSlide(slide, data);
            }
        }
    };
}

Object.assign(SlideStore.prototype, itemContainerMixin, saveMixin);

export default SlideStore;
