import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

const IterableContainer = observer(props => {
    const {
        entryType: Entry,
        entryName,
        entries,
        keyBuilder,
        getEntryProps,
        ...rest
    } = props;

    return entries.map(entry =>
        <Entry
            key={keyBuilder(entry)}
            {...{ [entryName]: entry }}
            {...getEntryProps(entry)}
            {...rest}
        />
    );
});

IterableContainer.defaultProps = {
    getEntryProps: () => ({})
};

IterableContainer.propTypes = {
    entries: PropTypes.array,
    entryType: PropTypes.object,
    entryName: PropTypes.string,
    keyBuilder: PropTypes.func,
    entryProps: PropTypes.func
};

export default IterableContainer;
