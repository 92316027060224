import React from 'react';
import PropTypes from 'prop-types';
import { Observer, observer } from 'mobx-react';

import { Editor as VCSBoardEditor } from '@vectorworks/vcs-boards';

import AspectRatio from '../../common/AspectRatio';
import BrandingLogo from '../../../branding/BrandingLogo';
import ErrorBoundary from '../../common/ErrorBoundary';

import PreviewText from '../../Editor/Board2/TextEditor/PreviewText';
import { PreviewImageViewer } from '../../FileViewers/Image';
import BackgroundPins from '../../Editor/Board2/Preview/Pins/BackgroundPins';

class BoardViewer extends React.Component {
    componentDidMount () {
        this.props.store.setPreviewMode(true);
    }

    get branding () {
        return this.props.store.brandingImage;
    }

    previewPDF (asset) {
        const pdfAsset = this.props.store.asset.assets.find(a => a.id === asset.pdfResource);
        if (pdfAsset) {
            const previewItem = { asset: pdfAsset };
            this.props.store.fileViewStore.file.open(previewItem, [previewItem]);
        }
    }

    render () {
        const { previewContainer, preview } = this.props;
        const slide = preview.slide;

        return (
            <Observer>
                {() => (<React.Fragment>
                    <ErrorBoundary title={gettext('Something went wrong with your board!')}>
                        <div className='slide-type-indicator'>
                            <span className='icon icon-board'/>
                        </div>
                        <AspectRatio selector='#presentation-screen'>
                            <div id='presentation-screen'>
                                <BrandingLogo branding={ this.branding } />
                                <VCSBoardEditor
                                    key={slide.id}
                                    store={slide.vcsBEStore}
                                    renderBoxContent={(editorBox) => {
                                        if (editorBox.cloudBox.type === 'image') {
                                            return (
                                                <div
                                                    data-what='presentation-preview'
                                                    data-which={`${editorBox.cloudBox.type}-box`}
                                                    onClick={() => { this.previewPDF(editorBox.cloudBox.asset); }}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        cursor: preview.root.asset.index.map.get(editorBox.cloudBox.asset.pdfResource)
                                                            ? 'pointer'
                                                            : 'inherit'
                                                    }}
                                                >
                                                    <PreviewImageViewer
                                                        file={editorBox.cloudBox.asset}
                                                        pins={editorBox.cloudBox.pins}
                                                        screen={this.props.preview}
                                                        previewContainer={previewContainer}
                                                    />
                                                </div>
                                            );
                                        } else if (editorBox.cloudBox.type === 'text') {
                                            return (
                                                <PreviewText
                                                    editorBox={editorBox}
                                                    color={slide.text.color.toCSS()}
                                                    editor={this.props.preview}
                                                    slide={slide}
                                                    data-what='presentation-preview'
                                                    data-which={`${editorBox.cloudBox.type}-box`}
                                                />
                                            );
                                        }
                                    }}
                                    style={{
                                        height: '100%'
                                    }}
                                >
                                    <BackgroundPins
                                        preview={preview}
                                        previewContainer={previewContainer}
                                    />
                                </VCSBoardEditor>
                            </div>
                        </AspectRatio>
                    </ErrorBoundary>
                </React.Fragment>)}
            </Observer>
        );
    }
};

BoardViewer.propTypes = {
    store: PropTypes.object.isRequired,
    preview: PropTypes.object.isRequired,
    previewContainer: PropTypes.object
};

export default observer(BoardViewer);
