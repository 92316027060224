export const SVGs = {
    dropbox: `
    <svg viewBox='0 0 32 32'>
        <g>
            <polygon
                class='st0'
                style="fill: #0061FF"
                points='9.4,4.8 2.8,9 9.4,13.2 16,9'
            />
            <polygon
                class='st0'
                style="fill: #0061FF"
                points='22.6,4.8 16,9 22.6,13.2 29.2,9'
            />
            <polygon
                class='st0'
                style="fill: #0061FF"
                points='2.8,17.4 9.4,21.6 16,17.4 9.4,13.2'
            />
            <polygon
                class='st0'
                style="fill: #0061FF"
                points='22.6,13.2 16,17.4 22.6,21.6 29.2,17.4'
            />
            <polygon
                class='st0'
                style="fill: #0061FF"
                points='9.4,23 16,27.2 22.6,23 16,18.8'
            />
        </g>
    </svg>`,
    google_drive: `
    <svg viewBox='0 0 32 32'>
        <polygon
            class='st0'
            style="fill: #FFCF4A"
            points='21,19 28,19 20,5 13,5
        '/>
        <polygon
            class='st1'
            style="fill: #1CA261"
            points='15.4,12.3 11.8,6.2 4,19.7 7.6,25.8
        '/>
        <polygon
            class='st2'
            style="fill: #4687F4"
            points='13,20 9,26 25,26 28,20
        '/>
    </svg>`,
    one_drive:
    `<svg x="0px" y="0px" viewBox="0 0 32 32">
        <path
            d="M13.111,11.804L13.111,11.804l5.108,3.059l3.044-1.281c0.619-0.267,1.286-0.405,1.959-0.404 c0.113,0,0.224,0.005,0.334,0.012c-1.043-4.068-5.186-6.52-9.253-5.477c-1.833,0.47-3.424,1.606-4.464,3.186l0.078-0.002 C11.045,10.896,12.152,11.21,13.111,11.804z"
            style="fill: #0364B8;"
            >
        </path>
        <path
            d="M13.112,11.804c-0.96-0.594-2.067-0.908-3.196-0.906L9.839,10.9c-3.358,0.042-6.046,2.798-6.004,6.156 c0.015,1.218,0.396,2.403,1.092,3.401l4.503-1.896l2.003-0.842l4.458-1.877l2.328-0.979L13.112,11.804z"
            style="fill: #0078D4;"
            >
        </path>
        <path
            d="M23.556,13.19c-0.111-0.008-0.222-0.012-0.334-0.012c-0.674-0.001-1.34,0.137-1.959,0.404l-3.044,1.28 l0.883,0.528l2.893,1.733l1.262,0.756l4.316,2.585c1.295-2.403,0.396-5.4-2.007-6.695C24.945,13.436,24.26,13.238,23.556,13.19 L23.556,13.19z"
            style="fill: #1490DF;"
            >
        </path>
        <path
            d="M23.257,17.88l-1.262-0.756l-2.893-1.734l-0.882-0.528l-2.329,0.979l-4.458,1.877L9.43,18.561l-4.504,1.896 c1.136,1.634,3.001,2.607,4.991,2.606h13.306c1.818,0.001,3.489-0.997,4.351-2.597L23.257,17.88z"
            style="fill: #28A8EA;"
            >
        </path>
    </svg>`
};
