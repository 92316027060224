import { request } from '../../base/axios';

export const loadIntegrations = () =>
    request.get('/restapi/public/v1/integrations/all/');

export const loadStorage = () =>
    request.get('/restapi/public/v2/current-account/');

// Google
let googlePages = [];

export const loadGooglePages = (instantReturn = false, useCache = true, config = null) => {
    if (instantReturn) {
        return googlePages;
    } else {
        return useCache && googlePages.length > 0
            ? Promise.resolve(googlePages)
            : (
                config
                    ? request.get('/restapi/public/v1/integrations/google-pages/', config)
                    : request.get('/restapi/public/v1/integrations/google-pages/')
            )
                .then(resp => {
                    googlePages = resp instanceof Array
                        ? resp
                        : googlePages;
                    return googlePages;
                })
                .catch(() => googlePages);
    }
};

// One Drive
let oneDrivePages = [];

export const loadOneDrivePages = (instantReturn = false, useCache = true, config = null) => {
    if (instantReturn) {
        return oneDrivePages;
    } else {
        return useCache && oneDrivePages.length > 0
            ? Promise.resolve(oneDrivePages)
            : (
                config
                    ? request.get('/restapi/public/v1/integrations/onedrive-pages/?fields=(shared_paths)', config)
                    : request.get('/restapi/public/v1/integrations/onedrive-pages/?fields=(shared_paths)')
            )
                .then(resp => {
                    oneDrivePages = resp instanceof Array
                        ? resp
                        : oneDrivePages;
                    return oneDrivePages;
                })
                .catch(() => oneDrivePages);
    }
};

let oneDriveOwnId = null;

export const loadOneDriveOwnId = (instantReturn = false, useCache = true) => {
    if (instantReturn) {
        return oneDriveOwnId;
    } else {
        return useCache && oneDriveOwnId
            ? Promise.resolve(oneDriveOwnId)
            : request.get('/restapi/public/v1/integrations/onedrive-root-id/')
                .then(resp => {
                    oneDriveOwnId = resp.drive_id;
                    return oneDriveOwnId;
                });
    }
};
