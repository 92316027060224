import axios from 'axios';
import WebFont from 'webfontloader';

const GF_API_KEY = window.Settings?.google?.fontsAPIKey;

const googleFontsList = [];
export const loadGoogleFonts = () => {
    return googleFontsList.length
        ? Promise.resolve(googleFontsList)
        : axios.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${GF_API_KEY}`);
};

class Font {
    constructor (data) {
        this.family = data.family;
        this.category = data?.category;
    }
}

export class SimplePaginator {
    constructor (items, pageSize = 25) {
        this.items = items;
        this.pageSize = pageSize;
        this.index = 0;
    }

    next () {
        const result = this.items.slice(
            this.index,
            this.index + this.pageSize
        );
        this.index += this.pageSize;
        return result;
    }
}

export class FontsAPI extends SimplePaginator {
    constructor () {
        super([]);
    }

    load = () =>
        loadGoogleFonts()
            .then(data => {
                this.items = data.items.map(i => new Font(i));
                return this;
            });

    next () {
        const res = super.next();
        return res.map(f => f.family);
    };

    search = query => {
        const q = query?.toLowerCase();
        return new SimplePaginator(
            this.items
                .filter(f =>
                    f.family.toLowerCase().includes(q) ||
                    f.category.includes(q)
                )
                .map(f => f.family)
        );
    };
}

export const loadFonts = (families, ops = { text: null }) => {
    return WebFont.load({
        google: {
            families,
            ...ops
        }
    });
};
