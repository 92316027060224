import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { commonPropTypes, reactionWithOldValue } from './utils';
import { DefaultController } from './Controllers/Default';

class VGXViewer extends React.Component {
    name = 'VGXViewer';

    componentDidMount () {
        this.props.store.endLoading();
        this.reactionDisposer = reactionWithOldValue(
            () => this.props.store.file.file,
            (newValue, oldValue) => {
                if (!oldValue || oldValue.versionId !== newValue.versionId) {
                    this.props.store.endLoading();
                }
            },
            { name: 'endLoadingOnChangedFile' }
        );
    }

    componentWillUnmount () {
        this.reactionDisposer && this.reactionDisposer();
    }

    onIframeLoad = () => {
        const iframeSrc = $(this.iframe).attr('src');
        if (iframeSrc.startsWith(window.location.origin)) {
            $(this.iframe).contents().find('body').on('mousemove', this.props.store.ui.controller.show);
        }
    };

    getIframeSrc = () => {
        if (Settings.offlinePresentation) {
            return `app/file-viewer/vgx/vgx-template.html?embedded=1&download_url=${this.props.sourceUrl}&branding=${false}`;
        } else {
            const brandingUrl = this.props.store.file.file.ownerInfo &&
                this.props.store.file.file.ownerInfo.branding.image;
            return `${window.location.origin}/portal/file-viewer/vgx/?embedded=1&download_url=${this.props.sourceUrl}&branding=${!!brandingUrl}`;
        }
    };

    render () {
        return (
            <React.Fragment>
                <div
                    className='fileview-component-loader'
                    data-what='file-viewer'
                    {...this.props.controllerTogglers}
                >
                    { this.props.children }

                    {
                        !this.props.store.loading &&
                        <iframe
                            className='file-view-iframe'
                            ref={iframe => { this.iframe = iframe; }}
                            title={this.props.store.file.name}
                            src={ this.getIframeSrc() }
                            onLoad={this.onIframeLoad}
                            allowFullScreen
                        />
                    }
                </div>
                <DefaultController {...this.props} viewer={this}/>
            </React.Fragment>
        );
    };
};

export default observer(VGXViewer);

VGXViewer.propTypes = {
    ...commonPropTypes,
    sourceUrl: PropTypes.string
};
