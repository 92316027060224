import React from 'react'; // eslint-disable-line
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

const Portal = props => ReactDOM.createPortal(
    props.children,
    document.querySelector(props.container)
);

Portal.propTypes = {
    container: PropTypes.any
};

export default Portal;
