import React from 'react';
import PropTypes from 'prop-types';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Box, Popover } from '@mui/material';

import FilterTypeMenu from './FilterTypeMenu';
import DistinctValuesMenu from './DistinctValuesMenu';
import ValuesMenu from './ValuesMenu';
import RemoveButton from './RemoveButton';

import ViewerContext from '../../ViewerContext';
import { pubsub } from '../../../../../../base';
import { FILTERS, FILTER_TYPES } from '../../utils';
import S from '../styles';

const ColumnFilterMenu = ({ columnId }) => {
    const { store } = React.useContext(ViewerContext);
    const [isOpen, setIsOpen] = React.useState(false);
    const [anchor, setAnchor] = React.useState({ x: 0, y: 0 });
    const [filter, setFilter] = React.useState(null);
    const [type, setType] = React.useState(null);
    const [inputContents, setInputContents] = React.useState(null);
    const [error, setError] = React.useState(null);

    const handleMenuOpened = ({ id, x, y }) => {
        if (columnId === id) {
            setIsOpen(true);
            setAnchor({ x, y });
        }
    };

    React.useEffect(() => {
        initState();
        pubsub.subscribe(ColumnFilterMenu, 'ccad.filtermenu.open', handleMenuOpened);
        return () => pubsub.unsubscribeAll(ColumnFilterMenu);
    }, []);

    React.useEffect(() => {
        updateError();
    }, [store.isQueryEmpty]);

    React.useEffect(() => {
        remove();
    }, [store.table]);

    const updateError = () => {
        const filt = store.filters[columnId];
        if (store.isQueryEmpty) {
            setError('No results found.');
            filt.isActive && store.toggleFilter(columnId);
            pubsub.publish('ccad.filters.icon.update', columnId);
        } else if (filt?.isActive) {
            setError(null);
        }
    };

    const initState = () => {
        if (store.filters[columnId]?.filter?.name) {
            const filter = store.filters[columnId].filter;
            setFilter(filter.name);
            store.setFilter(columnId, filter);
        } else {
            setFilter(FILTERS.CONTAINS.name);
            store.setFilter(columnId, FILTERS.CONTAINS);
        }

        const filterType = store.filters[columnId]?.filter?.type
            ? filter.type
            : FILTER_TYPES.EXACT;
        setType(filterType.name);
        store.setFilterType(columnId, filterType.name);

        setInputContents(store.filters[columnId]?.filter?.value || '');

        pubsub.publish('ccad.filters.icon.update', columnId);
    };

    const changeFilter = e => {
        const filt = Object.values(FILTERS).find(f => f.name === e.target.value);
        store.unsetFilter(columnId);
        setError(null);
        setInputContents('');
        setFilter(filt.name);
        store.setFilter(columnId, filt);
        setType(FILTER_TYPES.EXACT.name);
        store.setFilterType(columnId, FILTER_TYPES.EXACT.name);
        pubsub.publish('ccad.filters.icon.update', columnId);
    };

    const onRadioClick = type => {
        setType(type);
        store.setFilterType(columnId, type);
    };

    const onInputChange = e => {
        setInputContents(e.target.value);
        store.setFilterValue(columnId, e.target.value);
        pubsub.publish('ccad.filters.icon.update', columnId);
    };

    const close = () => {
        setIsOpen(false);
        pubsub.publish('ccad.filtermenu.close');
    };

    const remove = () => {
        store.unsetFilter(columnId);
        initState();
    };

    const onKeyDown = e => {
        e.stopPropagation();
        if (e.key === 'Escape') {
            close();
        }
    };

    return (
        <Popover
            open={isOpen}
            anchorReference='anchorPosition'
            anchorPosition={{
                top: anchor.y,
                left: anchor.x
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            onKeyDown={onKeyDown}
        >
            <ClickAwayListener
                mouseEvent='onMouseDown'
                touchEvent='onTouchStart'
                onClickAway={close}
            >
                <Box>
                    <S.FilterMenu
                        data-what='ccad-filters-menu'
                        data-which={columnId}
                    >
                        <FilterTypeMenu
                            columnId={columnId}
                            filter={filter}
                            changeFilter={changeFilter}
                            close={close}
                        />
                        {
                            filter === FILTERS.DISTINCT.name
                                ? <DistinctValuesMenu
                                    columnId={columnId}
                                    close={close}
                                />
                                : <ValuesMenu
                                    columnId={columnId}
                                    filter={filter}
                                    type={type}
                                    close={close}
                                    onRadioClick={onRadioClick}
                                    inputContents={inputContents}
                                    onInputChange={onInputChange}
                                    updateError={updateError}
                                />
                        }
                        <S.ErrorContainer>
                            <div
                                className='secondary error italic'
                                data-what='ccad-column-filter'
                                data-which='error-message'
                            >
                                {error}
                            </div>
                        </S.ErrorContainer>
                        <RemoveButton
                            close={close}
                            remove={remove}
                        />
                    </S.FilterMenu>
                </Box>
            </ClickAwayListener>
        </Popover>
    );
};

ColumnFilterMenu.propTypes = {
    columnId: PropTypes.string
};

export default ColumnFilterMenu;
