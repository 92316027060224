import { sentAnalyticsEvent } from '../base/analytics';
import pubsub from './pubsub';

const Action = {
    text (item) {
        const t = this.properties.text;
        const which = this.name.replace(/([A-Z])/g, '-$1').toLowerCase();
        return t
            ? ($.isFunction(t) ? t.call(this, item) : t)
            : `<span class='icon ${this.properties.icon}' data-what="ctx-action" data-which="${which}"></span>${this.properties.title}`;
    },
    url (item) {
        const t = this.properties.url;
        return $.isFunction(t) ? t.call(this, item) : t;
    },
    isAllowed (item) {
        return this.properties.isAllowed.call(this, item);
    },
    isMultiple (item) {
        const t = this.properties.multiple;
        return $.isFunction(t) ? t.call(this, item) : t;
    }
};

function publishActionEvents (action, name, itemType) {
    return function (items, calledFrom) {
        calledFrom === 'portalTools' &&
            sentAnalyticsEvent({ action: 'Tools', label: `${action.properties.GALabel}` }, {});
        return action.action.call(this, [].concat(items), calledFrom)
            .then(result => {
                pubsub.publish(`${itemType}.action.${name}`, result);
                return result;
            });
    };
}

// Sets the priority in the toolbar.
// If an action is not presented here, it will not appear in the toolbar.
const ACTION_PRIORITIES = {
    asset: [
        'showInNomad',
        'view',
        'mountSharedNode',
        'unmountSharedNode',
        'share',
        'manageLinks',
        'stopSharing',
        'vrCode',
        'qrCode',
        'addToPresentation',
        'trainImageStyle',
        'photogrammetry',
        'addToBluebeamSession',
        'delete',
        'exportPdf',
        'styleTransfer',
        'upsampling',
        'generate3DModel',
        'revitExport',
        'download',
        'restore',
        'deleteForever',
        'rename',
        'newTask',
        'viewOriginal',
        'copyToMyStorage'
    ],
    task: [
        'delete',
        'edit',
        'toggle'
    ],
    presentation: [
        'startPreview',
        'restore',
        'deleteForever',
        'delete',
        'rename',
        'duplicate',
        'migrate',
        'download'
    ],
    'bluebeam-file': [
        'view',
        'requestSnapshotsForFiles'
    ],
    'bluebeam-session': [
        'view',
        'requestSnapshotsForSession',
        'finalizeSession',
        'openUrlDialog',
        'addFilesToSession'
    ],
    'presentations-library-file': [
        'delete'
    ],
    'presentations-slide': [
        'edit',
        'delete',
        'rename',
        'duplicate'
    ]
};

function handleAutoExecute (action) {
    if (action.properties.autoExecute && action.properties.isAllowed) {
        action.properties.isAllowed() &&
        action.properties.autoExecute();
    };
};

function buildAction (action, name, itemType) {
    handleAutoExecute(action);

    return Object.assign(
        Object.create(Action),
        action,
        { name },
        { priority: ACTION_PRIORITIES[itemType].indexOf(name) },
        { action: publishActionEvents(action, name, itemType) }
    );
}

function filterAllowedActions (allActions, item) {
    return Object
        .keys(allActions)
        .reduce((actions, actionName) => {
            const action = allActions[actionName];
            if (action.isAllowed(item)) {
                actions[actionName] = action;
            }
            return actions;
        },
        {});
}

export {
    buildAction,
    filterAllowedActions
};
