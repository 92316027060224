import urlParser from 'url-parse';

function extract (str) {
    return str.split('?')[1] || '';
}

function parse (str) {
    // Create an object with no prototype
    const ret = Object.create(null);

    if (typeof str !== 'string') {
        return ret;
    }

    str = str.trim().replace(/^(\?|#|&)/, '');

    if (!str) {
        return ret;
    }

    str.split('&').forEach(function (param) {
        const parts = param.replace(/\+/g, ' ').split('=');
        // Firefox (pre 40) decodes `%3D` to `=`
        // https://github.com/sindresorhus/query-string/pull/37
        let key = parts.shift();
        let val = parts.length > 0 ? parts.join('=') : undefined;

        key = decodeURIComponent(key);

        // missing `=` should be `null`:
        // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
        val = val === undefined ? null : decodeURIComponent(val);

        if (ret[key] === undefined) {
            ret[key] = val;
        } else if (Array.isArray(ret[key])) {
            ret[key].push(val);
        } else {
            ret[key] = [ret[key], val];
        }
    });

    return ret;
}

function stringify (obj) {
    return (Object.keys(obj).length) ? '?' + $.param(obj) : '';
}

function parseUrl (url) {
    const parser = urlParser(url);
    return {
        protocol: parser.protocol,
        host: parser.host,
        hostname: parser.hostname,
        port: parser.port,
        pathname: parser.pathname.startsWith('/') ? parser.pathname : `/${parser.pathname}`,
        search: parser.query,
        hash: parser.hash
    };
}

function buildUrl (url, qs, hash) {
    const u = parseUrl(url);
    const baseQs = parse(u.search);
    const search = stringify(Object.assign(baseQs, qs));
    u.hash = hash || u.hash;
    return `${u.protocol}//${u.host}${u.pathname}${search}${u.hash}`;
}

export {
    extract,
    parse,
    stringify,
    parseUrl,
    buildUrl
};
