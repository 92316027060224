import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import keyboard from '../../base/keyboard';
import ProjectorStore from '../Stores/Projector';
import createPreviewStore from '../Preview/Store';
import { useParams } from 'react-router-dom';

const Projector = ({ store, ...props }) => {
    const params = useParams();

    const projector = useMemo(
        () => ProjectorStore.create(store, params.slideId),
        [params.slideId]
    );
    const preview = useMemo(
        () => createPreviewStore(store, projector.slide, projector.optional),
        [projector]
    );

    const keyboardActions = useMemo(
        () => ({
            left: {
                predicate: event => event.which === 37,
                handler: () => {
                    document.activeElement.blur();
                    projector.prev();
                }
            },
            right: {
                predicate: event => event.which === 39,
                handler: () => {
                    document.activeElement.blur();
                    projector.next();
                }
            }
        }),
        [projector]
    );

    useEffect(() => {
        keyboard.pushKeyEvents(keyboardActions);
        return () => keyboard.popKeyEvents();
    }, []);
    useEffect(() => keyboard.peekKeyEvents().extend(keyboardActions), [keyboardActions]);

    return (
        <React.Fragment>
            {
                props.children({
                    store,
                    projector,
                    preview,
                    params
                })
            }
        </React.Fragment>
    );
};

Projector.propTypes = {
    store: PropTypes.object.isRequired,
    slideId: PropTypes.string
};

export default observer(Projector);
