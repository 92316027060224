import { observable, action, makeObservable } from 'mobx';

function makeRef () {
    const ref = current => {
        ref.current = current;
    };
    ref.current = null;
    return ref;
}

class Ref {
    current = null;
    isMounted = false;

    constructor () {
        makeObservable(this, {
            isMounted: observable,
            setNode: action
        });
    }

    setNode (node) {
        if (!node) return;
        this.current = node;
        this.isMounted = true;
    }
}

export {
    makeRef,
    Ref
};
