import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';

import Board from '../models/board';
import SlideThumbnails from '../Layouts/Thumbnail';

const S = {
    BoardThumbnail: styled.div`
        flex: 1;

        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--grey1);

        img {
            height: 100%;
            width: 100%;
        }
    `
};

const ErrorTumbnail = () =>
    (<div
        className='layout-intro thumbnail-intro' // TODO: Add CSS for empty layout
        style={{
            background: 'lightgray'
        }}
    >
        <div>{ gettext('Error occurred processing media') }</div>
    </div>);

const BoardV2Thumbnail = observer(({ slide: board }) => {
    const { thumbnail } = board;

    return (
        <S.BoardThumbnail>
            {
                thumbnail
                    ? <img src={thumbnail} />
                    : <Icon icon='board' size='md' />
            }
        </S.BoardThumbnail>
    );
});

BoardV2Thumbnail.propTypes = {
    slide: PropTypes.object.isRequired
};

const ThumbnailDecider = ({ slide }) => {
    const Thumbnail = slide instanceof Board
        ? (
            slide.version === 2
                ? BoardV2Thumbnail
                : SlideThumbnails[slide.layout] || null
        )
        : SlideThumbnails.tour;

    return Thumbnail
        ? <Thumbnail slide={slide} />
        : <ErrorTumbnail/>;
};

ThumbnailDecider.propTypes = {
    slide: PropTypes.object.isRequired
};

export default observer(ThumbnailDecider);
