import React from 'react';

function createMarkup (html) {
    return { __html: html };
}

const NotAuthenticatedPanel = () => {
    const { pathname, search, hash } = window.location;
    const next = window.encodeURIComponent(pathname + search + hash);

    return (
        <div className='message-box flex-col not-authenticated'>
            <span className='icon icon-user' />

            <div className='message'>
                <span className='msg-row-prim'>{gettext('Not permitted.')}</span>
                <span
                    className='msg-row-sec'
                    dangerouslySetInnerHTML={createMarkup(
                        interpolate(
                        gettext("Please %(page)s to post comments."), // eslint-disable-line
                            {
                                page: `<a href="/accounts/login/?next=${next}">${gettext('sign in')}</a>`
                            },
                            true)
                    )}
                />
            </div>
        </div>
    );
};

export default NotAuthenticatedPanel;
