import { reaction, makeAutoObservable } from 'mobx';

import storage from '../../../storage';

import { TABLES, FILTERS, COLUMNS } from './utils';
import { UnitsStore } from './units';
import RecordFactory from './record-factory';

const hiddenColumns = ['id', 'deviceID', 'makeModel'];
const hideEmptyColumns = ['room', 'rackLocation']; // do not display these columns in mobile if their contents are empty

class Column {
    active = true;
    sort = false;

    constructor (id, type, store) {
        this.id = id;
        this.type = type;
        this.store = store;
        this.title = COLUMNS[id]?.title;

        makeAutoObservable(this);

        reaction(() => this.active, this.store.viewerStore.query);
        reaction(() => this.sort, this.store.viewerStore.query);
    }

    get unit () {
        return this.store.viewerStore.unitsStore.index[this.id]?.unit;
    }

    setActive (v) {
        this.active = v;
    };

    setFilterBy = () => {
        this.store.viewerStore.setFilterBy(this.id);
    };

    toggleActive = () => {
        this.store.filterBy === this.id &&
            this.store.clearFilter();
        this.setActive(!this.active);
    };

    setSort (c) {
        this.sort = c;
    };

    toggleSort = () => {
        this.setSort(
            this.sort
                ? (this.sort === 'ASC' ? 'DESC' : false)
                : 'ASC'
        );
    };

    shouldRender = contents =>
        !(hideEmptyColumns.includes(this.id) && !contents.replaceAll(' ', ''));
}

class ColumnsStore {
    constructor (columns, viewerStore) {
        this.columns = columns;
        this.viewerStore = viewerStore;
        makeAutoObservable(this);
    }

    setColumns = clmns => {
        this.columns = clmns;
    };

    get active () {
        return this.columns.filter(c => !hiddenColumns.includes(c.id) && c.active);
    }

    get activeCount () {
        return this.active.length;
    }

    get filterable () {
        return this.columns.filter(c => !hiddenColumns.includes(c.id));
    }

    get isEmpty () {
        return this.columns.length === 0;
    }

    get sorting () {
        return this.active.filter(c => c.sort);
    }

    get map () {
        return new Map(this.columns.map(i => [i.id, i]));
    }

    orderCriteria = table => {
        const clauses = (
            this.sorting.length > 0
                ? this.sorting
                : TABLES[table].orderBy
        )
            .map(c => `${c.id} ${c.sort}`);
        return clauses.length ? ` ORDER BY ${clauses.join(', ')} ` : '';
    };

    setSortCriterias = table => {
        /*
            Make sure that data is always sorted
            by certain columns,
            e.g. by Device in the Devices view
        */
        this.columns.map(c =>
            TABLES[table].orderBy
                .map(r =>
                    (r.id === c.id && !c.sort) && c.setSort(r.sort)
                )
        );
    };
}

class CCADViewerStore {
    constructor () {
        this.db = null;
        this.table = storage.get('ccad.table', TABLES.circuits.name);

        this.columns = new ColumnsStore([], this);
        this.unitsStore = new UnitsStore();
        this.records = [];
        this.filterBy = null;
        this.filter = FILTERS.contains.name;
        this.filterQuery = null;

        makeAutoObservable(this);

        reaction(() => this.table, this.loadTable);
        reaction(() => this.filterBy, this.query);
        reaction(() => this.filter, this.query);
        reaction(() => this.filterQuery, this.query);
    }

    setTable = tbl => {
        this.table = tbl;
        storage.set('ccad.table', tbl);
    };

    loadTable = () => {
        this.columns.setColumns([]);
        this.records = [];
        this.filterBy = null;
        this.filter = FILTERS.contains.name;
        this.filterQuery = null;

        this.query();
    };

    setFilterBy = v => {
        this.filterBy = v;
    };

    setFilter = v => {
        this.filter = v;
    };

    setFilterQuery = v => {
        this.filterQuery = v;
    };

    clearFilter = () => {
        this.setFilterBy(null);
        this.setFilter(FILTERS.contains.name);
        this.setFilterQuery(null);
        this.query();
    };

    query = () => {
        const filter = Object.keys(FILTERS).find(f => FILTERS[f].name === this.filter);
        const condition = (this.filterBy && this.filter && this.filterQuery)
            ? FILTERS[filter].condition(this.filterBy, this.filterQuery)
            : '';

        const query = (
            TABLES[this.table].base.replaceAll('\n', '') +
            condition +
            this.columns.orderCriteria(this.table)
        );

        const [results] = this.db.exec(query);
        if (results) {
            const { columns, values } = results;
            if (this.columns.isEmpty) {
                this.columns.setColumns(
                    columns.map(id => new Column(id, COLUMNS[id]?.type, this.columns))
                );
            }
            const records = values.map(v => v.reduce((acc, c, i) => ({ ...acc, [columns[i]]: c }), {}));
            const uniqueRecords = records.reduce((acc, c) => acc.find(item => item.id === c.id)
                ? acc
                : [...acc, c], []);
            this.records = uniqueRecords.map(r => RecordFactory.create(this.table, r));
            this.columns.setSortCriterias(this.table);
        } else {
            this.records = [];
        }
    };
}

export default CCADViewerStore;
