import React from 'react';

import Icon from '../../Components/Icon';

const SharedFileNotFound = props => (
    <div className='no-preview'>
        <div className='message-box flex-col'>
            <Icon icon='icon-view'/>
            <div className='message'>
                <span className='msg-row-prim'>{ gettext('File not found') }</span>
                <span>{ gettext('The file you are trying to view cannot be found. It was either deleted, unshared, or you are signed in to an account that has no access to it.') }</span>
            </div>
        </div>
    </div>);

export default SharedFileNotFound;
