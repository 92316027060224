import React from 'react';
import PropTypes from 'prop-types';

const calculateAspectRatio = screen => {
    const { clientWidth, clientHeight } = screen.parentNode;

    return clientWidth > clientHeight
        ? calculateLandscape16x9(screen)
        : calculatePortrait16x9(screen);
};

const calculateLandscape16x9 = screen => {
    const { clientWidth, clientHeight } = screen.parentNode;
    let width = Math.round((clientHeight / 9) * 16);
    let height = clientHeight;

    screen.style.width = `${width}px`;
    screen.style.height = `${height}px`;

    /* Recalculate size if calculated width is wider than element */
    if (width > clientWidth) {
        height = Math.round((clientWidth / 16) * 9);
        width = Math.round((height / 9) * 16);
        screen.style.height = `${height}px`;
        screen.style.width = `${width}px`;
    }

    return Promise.resolve({
        width,
        height,
        screen
    });
};

const calculatePortrait16x9 = screen => {
    const { clientWidth, clientHeight } = screen.parentNode;
    let height = Math.round((clientWidth / 16) * 9);
    let width = clientWidth;

    screen.style.width = `${width}px`;
    screen.style.height = `${height}px`;

    if (height > clientHeight) {
        width = Math.round((clientHeight / 9) * 16);
        height = Math.round((width / 16) * 9);
        screen.style.width = `${width}px`;
        screen.style.height = `${height}px`;
    }

    return Promise.resolve({
        width,
        height,
        screen
    });
};

const validateSizes = ({ width, height, screen }) => {
    const { clientWidth, clientHeight } = screen.parentNode;

    return (width !== clientWidth && height !== clientHeight)
        ? Promise.reject()
        : Promise.resolve();
};

class AspectRatio extends React.Component {
    componentDidMount () {
        window.addEventListener('resize', this.keepScreenAspectRatio);
        this.keepScreenAspectRatio();
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this.keepScreenAspectRatio);
    }

    keepScreenAspectRatio = () => {
        const screen = document.querySelector(this.props.selector);

        const calculation = calculateAspectRatio(screen);
        /*
         * Because of zoom animation in editor,
         * calculate the size of slide screen, then after animation if finished
         * check if size is correct, if not, calculate and set again.
         */
        calculation.then(res => {
            setTimeout(() => {
                validateSizes(res)
                    .then(() => {})
                    .catch(() => calculateAspectRatio(screen));
            }, Settings.device.browser === 'IE11' || Settings.device.browser === 'Edge'
                ? 1000
                : 350
            );
        });
    };

    render () {
        return (
            <React.Fragment>
                { this.props.children }
            </React.Fragment>
        );
    }
}

export default AspectRatio;

AspectRatio.propTypes = {
    selector: PropTypes.string
};
