import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Observer } from 'mobx-react';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';
import { TextOverflow } from '@vectorworks/vcs-ui/dist/lib/styles/common';
import { ConfirmDeletePinDialog } from '../../FileViewers/Tour/PinEditors/PinEditPanel';
import { inject } from '../../Store';

const S = {
    PinRow: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 26px;
        padding-left: 20px;
        border-bottom: 1px solid var(--border-color);
        cursor: pointer;
    `,
    Actions: styled.div`
        display: flex;
        align-items: center;

        > * {
            margin: 0 7px;
        }
    `,
    PinTitle: styled(TextOverflow)`
        flex: 1;

        font-size: 13px;
        min-width: 130px;
        max-width: 220px;
    `
};

export const PinRow = inject('dialog')(
    ({ pin, rotateCameraToPin, dialog, ...rest }) => {
        const rotateToPin = (e) => {
            e.stopPropagation();
            rotateCameraToPin(pin);
        };

        // const lock = (e) => {
        //     e.stopPropagation();
        //     pin.setPositionLocked(!pin.positionLocked);
        // };

        // const visible = (e) => {
        //     e.stopPropagation();
        //     pin.setVisible(!pin.visible);
        // };

        const deletePin = (e) => {
            e.stopPropagation();
            dialog.open({
                component: ConfirmDeletePinDialog,
                context: {
                    deletePin: () => pin.remove()
                }
            });
        };

        return (
            <Observer>{() => (
                <S.PinRow
                    onClick={rotateToPin}
                    {...rest}
                >
                    <Icon icon='pin-16' size='sm' style={{ marginRight: 4 }} />
                    <S.PinTitle>{pin.title}</S.PinTitle>
                    <S.Actions>
                        <IconButton onClick={deletePin} title={gettext('Delete')}>
                            <Icon icon='trash' size='sm' />
                        </IconButton>
                    </S.Actions>
                </S.PinRow>
            )}
            </Observer>
        );
    }
);
PinRow.propTypes = {
    pin: PropTypes.object,
    dialog: PropTypes.object,
    rotateCameraToPin: PropTypes.func
};
