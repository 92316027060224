import React from 'react';
import PropTypes from 'prop-types';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import renderViewer from '../../preview/Viewers';
import fullScreen from '../../base/fullscreen';
import { removeClass } from '../../lib/dom-utils';
import BrandingLogo from '../../branding/BrandingLogo';

const Toolbar = observer((props) => {
    const close = () => props.store.ui.hide();

    return (
        <div className='toolbar fv-toolbar presentation-fv-toolbar'>
            <div className='toolbar-prim-text file-name visible-lg-block pull-left' what='file-name'>
                { props.store.file.file.name }
            </div>

            <div className='gl-tools'>
                <a
                    className='tool icon-close'
                    title={ gettext('Close') }
                    onClick={close}
                    which='close'
                    ga-action='File_View'
                    ga-label='Close_FileView'
                />
            </div>
        </div>
    );
});

Toolbar.propTypes = {
    store: PropTypes.object
};

class Fileview extends React.Component {
    state = {
        animationTimeout: false
    };

    constructor (props) {
        super(props);

        makeObservable(this, {
            branding: computed,
            viewerType: computed
        });
    }

    componentDidMount () {
        fullScreen.on('change', this.handleFullscreenChange);
        !this.state.animationTimeout && setTimeout(() => this.setState({ animationTimeout: true }), 300);
    }

    componentWillUnmount () {
        this.props.store.file.cleanUp();
        fullScreen.off('change', this.handleFullscreenChange);
    }

    handleFullscreenChange = e => {
        this.props.store.ui.fullScreen = fullScreen.isFullscreen;

        if (!fullScreen.isFullscreen) {
            const fileView = document.querySelector('.file-view');
            removeClass(fileView, 'fake-fs');
        }
    };

    get branding () {
        const file = this.props.store.file.file;
        return !file.isEmpty && file.ownerInfo.branding;
    }

    get viewerType () {
        const file = this.props.store.file.file;
        return (file.isEmpty && !file.name)
            ? 'empty'
            : (file.fileType.type || 'default');
    }

    render () {
        return (
            <div className='fv-overlay presentation-fv'>
                <div className='file-view'>
                    <Toolbar store={this.props.store}/>
                    <div className='viewer-wrapper'>
                        {this.branding && <BrandingLogo branding={this.branding} />}
                        {this.state.animationTimeout && renderViewer({ store: this.props.store, type: this.viewerType })}
                    </div>
                </div>
            </div>
        );
    }
};

Fileview.propTypes = {
    store: PropTypes.object
};

export default observer(Fileview);
