import React from 'react';

import { extractUrls } from '../Editor/Board/TextEditor/editor-funcs';

class Linkify extends React.Component {
    parseString (string) {
        if (string === '') {
            return string;
        }

        const matches = extractUrls(string);
        if (!matches.length) {
            return string;
        }

        const elements = [];
        let lastIndex = 0;
        matches.forEach((match, i) => {
            // Push preceding text if there is any
            if (match.index > lastIndex) {
                elements.push(string.substring(lastIndex, match.index));
            }

            elements.push(<a href={match.url} target='_blank' key={i} rel='noreferrer'>{match.text}</a>);

            lastIndex = match.lastIndex;
        });

        // Push remaining text if there is any
        if (string.length > lastIndex) {
            elements.push(string.substring(lastIndex));
        }

        return (elements.length === 1) ? elements[0] : elements;
    }

    parse (children, key = 0) {
        if (typeof children === 'string') {
            return this.parseString(children);
        } else if (React.isValidElement(children) && (children.type !== 'a') && (children.type !== 'button')) {
            return React.cloneElement(children, { key }, this.parse(children.props.children));
        } else if (Array.isArray(children)) {
            return children.map((child, i) => this.parse(child, i));
        }

        return children;
    }

    render () {
        return (
            <React.Fragment>
                {this.parse(this.props.children)}
            </React.Fragment>
        );
    }
}

export default Linkify;
