const UPDATE_TIME_DIFFERENCE = 30;
const checkUpdatePermission = time =>
    moment().diff(moment.utc(time).local(), 'minutes') < UPDATE_TIME_DIFFERENCE;

const getMentions = content => {
    const comment = $(`<div>${content}</div>`);
    const htmlMentions = $(comment).find('.mention');
    return $(htmlMentions).map(function () {
        return {
            email: $(this).attr('email'),
            name: $(this).html(),
            login: $(this).attr('login')
        };
    }).toArray();
};

const validateCommentWithoutHTML = comment =>
    $(`<div>${comment}</div>`).text().replace(/(\r\n|\n|\r|\s)/gm, '').length > 0;

const checkAndAdd = (list, mention) => {
    const found = list.some(m => (m.login === mention.login && m.login !== null) ||
    (mention.login === null && mention.email === m.email));
    !found && list.push(mention);
};

const createMarkup = markup => {
    return { __html: markup };
};

const clearEmpty = object => {
    const newObj = { ...object };
    Object.keys(newObj).forEach((key) => (newObj[key] == null) && delete newObj[key]);
    return newObj;
};

export default {
    checkUpdatePermission,
    getMentions,
    validateCommentWithoutHTML,
    checkAndAdd,
    createMarkup,
    clearEmpty
};
