// This shouldn't be in the Preview section
import React from 'react';
import PropTypes from 'prop-types';
import { PendingPinOptions } from '../PendingTypePin';
import { PendingTypePin as PendingPinTypeModel } from '../../models/tour';

function PendingTypePin ({ pin }) {
    return (
        <PendingPinOptions onSelect={(_, pinType) => pin.onChoose(pinType) } />
    );
}

PendingTypePin.propTypes = {
    pin: PropTypes.instanceOf(PendingPinTypeModel)
};

export default PendingTypePin;
