import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import Cell from './Cell';
import ColumnFilterToggler from './Filters/ColumnFilters/ColumnFilterToggler';
import { NON_FILTERABLE_COLUMNS } from './utils';
import S from './styles';
import ViewerContext from './ViewerContext';

const ColumnTitle = ({ column }) => {
    const { id, title, unit } = column;
    const disableFiltering = NON_FILTERABLE_COLUMNS
        .map(c => c.id)
        .includes(id);
    return (
        <S.MobileTitle>
            <ColumnFilterToggler
                columnId={id}
                isMobile={true}
                isHidden={disableFiltering}
            />
            <b>
                {`${title || id} ${unit ? `(${unit.title})` : ''}`}
            </b>
        </S.MobileTitle>
    );
};

ColumnTitle.propTypes = {
    column: PropTypes.object
};

const MobileContents = observer(
    ({ item, skipColumns = [] }) => {
        const { store } = React.useContext(ViewerContext);
        return (
            <React.Fragment>
                {
                    store.columns.active
                        .filter(c => !skipColumns.find(s => s.id === c.id) && c.shouldRender(item[c.id]))
                        .map(c =>
                            <S.SmContent key={`sm-${c.id}`}>
                                <ColumnTitle column={c} />
                                <Cell
                                    key={`${item.key}#${c.id}`}
                                    item={item}
                                    cell={c}
                                />
                            </S.SmContent>
                        )
                }
            </React.Fragment>
        );
    }
);

MobileContents.propTypes = {
    item: PropTypes.object,
    skipColumns: PropTypes.array
};

export default MobileContents;
