import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import CommentsHeader from './CommentsHeader';
import { VCDOCHeader } from './VCDOCHeader';
import VCDOCCommentsStore from './vcdoc-store';
import PostCommentField from '../../../comments/PostCommentField';
import CommentsList from '../../../comments/CommentsList';
import NotAuthenticatedPanel from '../../../comments/NotAuthenticatedPanel';
import NotPermittedPanel from '../../../comments/NotPermittedPanel';
import NotAllowedCommentsPanel from '../../../comments/NotAllowedCommentsPanel';

const S = {
    Content: styled.div`
        display: flex;
        flex-direction: column;
        overflow: auto;
    `
};

export const CommentsContent = observer(({ store }) => {
    /* commentsStore is being chosen after the file request
    and alse commentsStore is not observable. So this worked out.
    If changing, test if the comments are loaded for both .vcdoc and other files
    on refresh and both with comments panel initially opened/closed */
    if (store.file.file.isEmpty || !store.file.file.exists) return null;
    const commentsStore = store.file.commentsStore;

    const { canComment, allowComments } = commentsStore;
    const isVCDOC = commentsStore instanceof VCDOCCommentsStore;

    return (
        <S.Content>
            {
                isVCDOC
                    ? <VCDOCHeader commentsStore={commentsStore} />
                    : <CommentsHeader
                        commentsStore={commentsStore}
                    />
            }
            <S.Content>
                {
                    canComment
                        ? store.file.preview.error === 'found'
                            ? <NotPermittedPanel />
                            : allowComments
                                ? (
                                    !isVCDOC
                                        ? <PostCommentField commentsStore={commentsStore} />
                                        : null
                                )
                                : <NotAllowedCommentsPanel />
                        : <NotAuthenticatedPanel />
                }
                <CommentsList commentsStore={commentsStore}>
                    {
                        isVCDOC
                            ? <PostCommentField commentsStore={commentsStore} />
                            : null
                    }
                </CommentsList>
            </S.Content>
        </S.Content>
    );
});

CommentsContent.propTypes = {
    store: PropTypes.object.isRequired
};

class CommentsSection extends React.Component {
    constructor (props) {
        super(props);
        this.panelRef = React.createRef();
    }

    getZIndex = () => {
        return this.props.store.ui.overlay.length > 1 &&
            this.props.store.ui.overlay[1] === 'comments' &&
            this.panelRef.current &&
            Number(window.getComputedStyle(this.panelRef.current).zIndex) + 1;
    };

    render () {
        return (
            <div
                className={`comments-section ${this.props.store.ui.comments ? 'expanded' : ''}`}
                ref={this.panelRef}
                style={{ zIndex: this.getZIndex() }}
            >
                <CommentsContent store={this.props.store} />
            </div>
        );
    }
};

export default observer(CommentsSection);

CommentsSection.propTypes = {
    store: PropTypes.object.isRequired
};
