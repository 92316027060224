import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import Cell from './Cell';
import S from './styles';
import ViewerContext from './ViewerContext';

// this component displays the contents of a record in the desktop layout
// (1 row in the table)
const LgContents = React.forwardRef(
    ({ item, selected, skipColumns = [], isNested = false }, ref) => {
        const { store } = React.useContext(ViewerContext);
        const count = isNested
            ? store.columns.active.length - skipColumns.length
            : store.columns.active.length;
        return (
            <S.LgContents
                ref={ref}
                className={`${selected ? 'item-selected-light' : null}`}
                count={count}
                showBorder={!isNested}
            >
                {
                    store.columns.active.map(cell =>
                        skipColumns.map(c => c.id).includes(cell.id)
                            ? !isNested && <Cell
                                key={`${item.key}#${cell.id}`}
                                item={item}
                                cell={null}
                            />
                            : <Cell
                                key={`${item.key}#${cell.id}`}
                                item={item}
                                cell={cell}
                            />
                    )
                }
            </S.LgContents>
        );
    }
);

LgContents.propTypes = {
    item: PropTypes.object,
    selected: PropTypes.bool,
    skipColumns: PropTypes.array,
    isNested: PropTypes.bool
};

export default observer(LgContents);
