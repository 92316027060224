import React from 'react';

import { observer } from 'mobx-react';

import Icon from '../../Components/Icon';

const NoPermission = () => (
    <div className='no-preview'>
        <div className='message-box flex-col'>
            <Icon icon='icon-view'/>
            <div className='message'>
                <span className='msg-row-prim'>{ gettext('Not allowed') }</span>
                <span>{ gettext("Sorry, you don't have the required permissions to view this file.") }</span>
            </div>
        </div>
    </div>);

export default observer(NoPermission);
