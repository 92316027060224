import React from 'react';
import PropTypes from 'prop-types';

const RadioButton = ({ checked, name, value, onChange, label, ...rest }) => (
    <label className={`vw-radio ${checked ? 'checked' : 'inactive'}`} {...rest}>
        <input
            type='radio'
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
        />
        <div className='check'><div className='inside'/></div>
        <span className='text'>{ label }</span>
    </label>
);

RadioButton.propTypes = {
    value: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func
};

export default RadioButton;
