import React from 'react';

const NotAllowedCommentsPanel = () =>
    <div className='message-box flex-col not-authenticated'>
        <span className='icon icon-user'/>
        <div className='message'>
            <span className='msg-row-prim'>{gettext('Not permitted.')}</span>
            <span className='msg-row-sec'>{gettext('Comments are not allowed by the owner.')}</span>
        </div>
    </div>;

export default NotAllowedCommentsPanel;
