function addClass (element, className) {
    element.classList.add(className);
}

function removeClass (element, className) {
    element.classList.remove(className);
}

function toggleClass (element, className) {
    element.classList.toggle(className);
}

function hasClass (element, className) {
    return element.classList.contains(className);
}

// Consider rotation
function isCursorOverElement (el, cursorEvent, cache = true) {
    if (!el) return false;
    const { pageY, pageX } = cursorEvent;
    const { left, right, bottom, top } = el.getBoundingClientRect();
    return pageX > left && pageX < right && pageY > top && pageY < bottom;
}

function isPointInRect (point, rect) {
    return point.top > rect.top &&
        point.top < rect.top + rect.height &&
        point.left > rect.left &&
        point.left < rect.left + rect.width;
}

export {
    addClass,
    removeClass,
    toggleClass,
    hasClass,
    isCursorOverElement,
    isPointInRect
};
