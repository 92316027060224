import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { loadFonts } from './api';
import { extractBoxFonts } from './utils';

/*
This should be re-rendered on:
1. In the editorBox. Reasons:
    1.1. When resizing the box, the font sizes are re-applied
2. editorState changes. Reasons:
    2.1 On toggle unordered/ordered/not list, the DOM element toggles between <ul>/<ol>/<div> and so, the DOM el disappears
    2.2. On typing new blocks
    * Currently implemented by toggling a key={trigger} from the parent

Don't access editorBox.<property> in the parent, because that would lead to re-renders of the DraftJS editor and bad performance.
*/

export const FontSizesCSS = observer(({ parentNode, editorBox }) => {
    React.useEffect(() => loadFonts(extractBoxFonts(editorBox.fontFamilies)), []);

    if (parentNode) {
        const blocks = parentNode.querySelectorAll('[data-block=true]');

        blocks.forEach(node => {
            const blockKey = node.getAttribute('data-offset-key').split('-')[0];
            const fontSize = editorBox.fontSizes[blockKey];
            const fontFamily = editorBox.fontFamilies[blockKey];

            node.style.fontSize = fontSize + 'px';
            node.style.fontFamily = fontFamily;

            if (node.parentNode.nodeName === 'OL') {
                node.style.setProperty('--ol-li-paddingLeft', `${fontSize * 2}px`);
                node.style.setProperty('--ol-li-before-width', `${fontSize * 2}px`);
            }
            if (node.parentNode.nodeName === 'UL') {
                node.style.setProperty('--ul-li-marginLeft', `${fontSize * 2}px`);
            }
        });
    }

    return null;
});

FontSizesCSS.propTypes = {
    parentNode: PropTypes.object,
    editorBox: PropTypes.object
};
