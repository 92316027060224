import React from 'react';
import { observer } from 'mobx-react';

import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import { MenuItem } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';

import Filter from './Filter';
import S from '../styles';
import ViewerContext from '../../ViewerContext';

const SortBy = observer(({ col: c }) => {
    const onClick = () => c.toggleSort();

    return (
        <MenuItem
            key={`ci-s-${c.id}`}
            onClick={onClick}
            data-what='ccad-sort-column-item'
            data-which={`${c.id}`}
        >
            <S.Sorting
                data-what='ccad-sort-column-checkbox'
                data-which={`${c.id}`}
            >
                {
                    c.sort && <Icon
                        icon={c.sort === 'ASC' ? 'sort-down' : 'sort-up'}
                        size='xs'
                    />
                }
            </S.Sorting>
            { c.title }
        </MenuItem>
    );
});

const SortWidget = observer(() => {
    const { store } = React.useContext(ViewerContext);
    const { columns } = store;
    const popperState = usePopupState({ variant: 'popper', popupId: 'sortMenu' });
    const isActive = columns.active.filter(c => c.sort).length > 0;

    const clear = () => {
        columns.active.map(c => c.setSort(false));
    };

    return (
        <Filter
            isActive={isActive}
            clear={clear}
            label='sort'
        >
            <S.FilterIcon
                icon='sort-16'
                {...bindTrigger(popperState)}
                data-what='ccad-filter'
                data-which='sort-button'
            />
            <S.ScrollableMenu {...bindMenu(popperState)}>
                { columns.filterable.map(c => <SortBy key={`sb-${c.id}`} col={c}/>) }
            </S.ScrollableMenu>
        </Filter>
    );
});

export default SortWidget;
