/*
    Used to preload larger files
    Downloads resources consecutively, 1 by 1, so that less bandwidth is used
    When replace() is called, all the rest processes are deleted, and because
    of the consecutive downloading, they are never called, so canceling is not
    needed.
*/

const createPreloader = options => {
    return {
        processes: 0,
        urls: [],
        loaded: new Map(),
        pending: new Map(),
        loading: new Map(),
        load (urls) {
            this.urls = [...urls, ...this.urls];
            this.runLoadProcess();
        },
        replace (urls) {
            this.urls = [];
            this.load(urls);
        },
        runLoadProcess () {
            if (this.urls.length === 0) return;
            this.processes += 1;
            const [url, ...rest] = this.urls;
            this.urls = rest;
            const process = this.loadResource(url);
            this.loading.set(url, process);
            process.result
                .then(image => {
                    this.loading.has(url) && this.loading.delete(url);
                    if (this.pending.has(url)) {
                        this.pending.get(url).forEach(([resolve, _]) => {
                            resolve(image);
                        });
                        this.pending.delete(url);
                    }
                    this.loaded.set(url, image);
                    this.processes -= 1;
                    this.processes === 0 && this.runLoadProcess();
                    return image;
                });
        },
        waitFor (url) {
            return this.loaded.has(url)
                ? Promise.resolve(this.loaded.get(url))
                : new Promise((resolve, reject) => {
                    if (!this.pending.has(url)) {
                        this.pending.set(url, []);
                    }
                    this.pending.get(url).push([resolve, reject]);
                });
        },
        cleanUp () {
            for (const process of this.loading.values()) {
                process.cancel();
            }
        },
        ...options
    };
};

export default createPreloader;
