function makeRef () {
    const ref = current => {
        ref.current = current;
    };
    ref.current = null;
    return ref;
}

export {
    makeRef
};
