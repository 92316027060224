import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import debounce from 'lodash.debounce';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';

import { Menu } from '@vectorworks/vcs-ui/dist/lib/Menu/Menu';
import { MenuItem } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';
import { Checkbox, CheckboxState } from '@vectorworks/vcs-ui/dist/lib/Checkbox/Checkbox';
import { Select } from '@vectorworks/vcs-ui/dist/lib/Select/Select';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { Input } from '@vectorworks/vcs-ui/dist/lib/Input/Text/Input';

import withResponsiveListeners from '../../../../utils/Responsive';

import { FILTERS } from './utils';

const S = {
    Trigger: styled.div`
        height: 40px;
        min-width: 100px;
        padding: 0 10px;
        margin: 0 5px;

        color: var(--text-secondary-color);
        border: 1px solid var(--input-border-color);
        border-radius: 2px;

        display: inline-flex;
        align-items: center;
        justify-content: space-between;

        cursor: pointer;
    `,
    Menu: styled(Menu)`
        ul {
            display: flex;
            flex-direction: column;
        }
    `,
    Select: styled(Select)`
        margin: 5px 10px;
        height: 40px;
        min-width: 100px; 
        
        border: 1px solid var(--input-border-color);
    `,
    Input: styled(Input)`
        margin: 5px 10px;
        padding-left: 16px;
        min-width: 100px;

        background: transparent;
        border: 1px solid var(--input-border-color);
    `,
    Sorting: styled.div`
        height: 17px;
        width: 17px;
        margin: 0 5px;

        color: var(--text-secondary-color);
        border: 1px solid var(--text-primary-color);
        border-radius: 3px;

        display: flex;
        align-items: center;
        justify-content: center;
    `,
    FiltersMobile: styled.div`
        padding: 10px 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        border-bottom: 1px solid var(--border-color);
    `,
    FiltersDesktop: styled.div`
        padding: 10px 0;

        display: flex;
        position: sticky;
        left: 0;
    `
};

const Trigger = ({ children, ...rest }) =>
    <S.Trigger {...rest}>
        { children }
        <Icon icon='arrow-down' style={{ fontSize: 7, marginLeft: 5 }}/>
    </S.Trigger>;

const ColumnsDropdown = props => {
    const { columns } = props.store;
    const popperState = usePopupState({ variant: 'popper', popupId: 'columnsMenu' });

    return (
        <React.Fragment>
            <Trigger
                {...bindTrigger(popperState)}
                data-what='ccad-filters-menu'
                data-which='columns'
            >
                {gettext('Columns')}
            </Trigger>
            <Menu {...bindMenu(popperState)}>
                {
                    columns
                        .filterable
                        .map(c => (c.id !== 'device') &&
                            <MenuItem
                                key={`ci-${c.id}`}
                                onClick={ c.toggleActive }
                                data-what='ccad-filters-column-item'
                                data-which={`${c.id}`}
                            >
                                <Checkbox
                                    state={ c.active ? CheckboxState.ON : CheckboxState.OFF }
                                    style={{ margin: '0 5px' }}
                                    data-what='ccad-filters-column-checkbox'
                                    data-which={`${c.id}`}
                                />
                                { c.title }
                            </MenuItem>
                        )
                }
            </Menu>
        </React.Fragment>
    );
};

ColumnsDropdown.propTypes = {
    store: PropTypes.object,
    viewer: PropTypes.object
};

const FilterField = observer(({ store }) => {
    const { columns } = store;
    const onChange = e => store.setFilterBy(e.target.value);

    return (
        <S.Select
            value={store.filterBy || false}
            onChange={onChange}
            data-what='ccad-filter-by'
            data-which='column'
        >
            <MenuItem
                key='fbf-none'
                value={false}
                data-what='ccad-filter-by-column'
                data-which='none'
            >
                { gettext('None') }
            </MenuItem>
            {
                columns
                    .active
                    .map(c =>
                        <MenuItem
                            key={`fbf-${c.id}`}
                            value={c.id}
                            data-what='ccad-filter-by-column'
                            data-which={`${c.id}`}
                        >
                            { c.title }
                        </MenuItem>
                    )
            }
        </S.Select>
    );
});

FilterField.propTypes = {
    store: PropTypes.object
};

const FilterCondition = observer(({ store }) => {
    const onChange = e => store.setFilter(e.target.value);

    return (
        <S.Select
            value={store.filter || false}
            onChange={onChange}
            data-what='ccad-filter-by'
            data-which='condition'
        >
            {
                Object.values(FILTERS)
                    .map(f =>
                        <MenuItem
                            key={`fc-${f.name}`}
                            value={f.name}
                            data-what='ccad-filter-by-condition'
                            data-which={`${f.name.toLowerCase()}`}
                        >
                            { f.title }
                        </MenuItem>
                    )
            }
        </S.Select>
    );
});

FilterCondition.propTypes = {
    store: PropTypes.object
};

const FiltersDropdown = observer(({ store }) => {
    const popperState = usePopupState({ variant: 'popper', popupId: 'filtersMenu' });

    const updateSearchQuery = e => store.setFilterQuery(e.target.value);
    const onInputChange = React.useCallback(debounce(updateSearchQuery, 200), []);

    return (
        <React.Fragment>
            <Trigger
                {...bindTrigger(popperState)}
                data-what='ccad-filters-menu'
                data-which='filter'
            >
                { gettext('Filter') }
            </Trigger>
            <S.Menu {...bindMenu(popperState)}>
                <FilterField store={store} />
                { store.filterBy && <FilterCondition store={store} /> }
                {
                    store.filterBy && <S.Input
                        defaultValue={store.filterQuery}
                        onChange={onInputChange}
                        data-what='ccad-filter-by'
                        data-which='value'
                    />
                }
            </S.Menu>
        </React.Fragment>
    );
});

FiltersDropdown.propTypes = {
    store: PropTypes.object
};

const SortBy = observer(({ col: c }) => {
    const onClick = () => c.toggleSort();

    return (
        <MenuItem
            key={`ci-s-${c.id}`}
            onClick={onClick}
            data-what='ccad-sort-column-item'
            data-which={`${c.id}`}
        >
            <S.Sorting
                data-what='ccad-sort-column-checkbox'
                data-which={`${c.id}`}
            >
                {
                    c.sort && <Icon
                        icon={c.sort === 'ASC' ? 'sort-down' : 'sort-up'}
                        size='xs'
                    />
                }
            </S.Sorting>
            { c.title }
        </MenuItem>
    );
});

const SortWidget = observer(({ store }) => {
    const { columns } = store;
    const state = usePopupState({ variant: 'popper', popupId: 'sortMenu' });

    return (
        <React.Fragment>
            <Trigger
                {...bindTrigger(state)}
                data-what='ccad-filters-menu'
                data-which='sort'
            >
                { gettext('Sort') }
            </Trigger>
            <Menu {...bindMenu(state)}>
                { columns.active.map(c => <SortBy key={`sb-${c.id}`} col={c}/>) }
            </Menu>
        </React.Fragment>
    );
});

SortWidget.propTypes = {
    store: PropTypes.object
};

const ConnectCADFilters = props => {
    const isMobile = React.useMemo(() => !['md', 'lg'].includes(props.screen.size), [props.screen]);
    return isMobile
        ? <S.FiltersMobile>
            <ColumnsDropdown {...props} />
            <FiltersDropdown {...props} />
            <SortWidget {...props} />
        </S.FiltersMobile>
        : <S.FiltersDesktop>
            <ColumnsDropdown {...props} />
            <FiltersDropdown {...props} />
        </S.FiltersDesktop>;
};

ConnectCADFilters.propTypes = {
    store: PropTypes.object,
    viewer: PropTypes.object,
    screen: PropTypes.object
};

export default withResponsiveListeners(ConnectCADFilters);
