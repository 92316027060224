import React from 'react';
import { observer } from 'mobx-react';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';

import { COLUMNS, NON_FILTERABLE_COLUMNS } from './utils';
import ColumnFilterToggler from './Filters/ColumnFilters/ColumnFilterToggler';
import ViewerContext from './ViewerContext';
import S from './styles';

const ColumnTitle = observer(({ column, nestedCount, canBeFiltered }) => {
    const onClick = () => canBeFiltered && column.toggleSort();
    const title = column.unit
        ? `${column.title} (${column.unit.title})`
        : column.title;
    return (
        <S.ColumnTitle
            key={column.id}
            cellType={COLUMNS[column.id].type}
            nestedCount={nestedCount}
            canBeFiltered={canBeFiltered}
        >
            <span
                style={{ fontSize: 15 }}
                onClick={onClick}
            >
                { title || column.id }
            </span>
            <ColumnFilterToggler
                columnId={column.id}
                isHidden={!canBeFiltered}
            />
            {
                column.sort &&
                <Icon
                    icon={column.sort === 'ASC' ? 'sort-down-16' : 'sort-up-16'}
                    size='xs'
                    onClick={onClick}
                />
            }
        </S.ColumnTitle>
    );
});

const Head = observer(() => {
    const { store } = React.useContext(ViewerContext);
    const nestedColumnsCount = store.columns.hasNestedColumns
        ? store.columns.nestedColumn.children.length
        : 0;
    const { nestedColumn } = store.columns;
    const nonFilterableIds = NON_FILTERABLE_COLUMNS.map(c => c.id);
    return (
        <S.Head>
            {
                store.columns.hasNestedColumns &&
                <S.NestedSubHead nestedColumnsCount={nestedColumnsCount}>
                    <ColumnTitle
                        column={nestedColumn.parent}
                        nestedCount={nestedColumn.children.length}
                        canBeFiltered={false}
                    />
                </S.NestedSubHead>
            }
            <S.NonNestedSubHead count={store.columns.active.length - nestedColumnsCount}>
                {
                    !store.columns.hasNestedColumns &&
                    store.columns.collapsable.map(c => <ColumnTitle
                        key={c.title}
                        column={c}
                        canBeFiltered={!nonFilterableIds.includes(c.id)}
                    />)
                }
                {
                    store.columns.nonCollapsable.map(c => <ColumnTitle
                        key={c.title}
                        column={c}
                        canBeFiltered={!nonFilterableIds.includes(c.id)}
                    />)
                }
            </S.NonNestedSubHead>
        </S.Head>
    );
});

export default Head;
