import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';

import { pos2Rot } from '~static/file-viewer/panorama/math-funcs';
import { pubsub } from '../../base';
import { promiseUtils } from '../../lib';
import Tour from '../models/tour';
import Board from '../models/board';

const S = {
    PinsWrapper: styled.div`
        display: flex;
        flex-direction: column;
        border-left: 1px solid rgba(255, 255, 255, 0.3);
        margin-left: 4px;
    `,
    PinRow: styled.div`
        display: flex;
        align-items: center;

        > i {
            margin-right: 4px;
        }
    `
};

const triggerPinMouseLeave = pinId => {
    try {
        const target = document.querySelector(`#bind-for-pins-${pinId}`);
        const event = new window.CustomEvent(
            'mouseleave',
            {
                detail: {
                    id: pinId,
                    intersection: { object: target.object3D }
                }
            }
        );
        target.dispatchEvent(event);
    } catch (e) {}
};

const triggerHoverOnPin = pinId => {
    try {
        const target = document.querySelector(`#bind-for-pins-${pinId}`);
        const event = new window.CustomEvent(
            'mouseenter',
            {
                detail: {
                    id: pinId,
                    intersection: { object: target.object3D }
                }
            }
        );
        target.dispatchEvent(event);
    } catch (e) {}
};

const SlidePin = ({ pin, preview, projector, slideIsActive, ...props }) => {
    const rotateCameraToPinAnimation = () => {
        const rot = pos2Rot(pin.position);
        const targetPitch = rot.x;
        const targetYaw = rot.y;
        window.AFRAME.store.scene.sceneEl.emit('camera-animation-request', {
            targetPitch,
            targetYaw,
            data: { pin }
        });
    };

    const navigateToBoardAndHighlight = async () => {
        projector.navigate(pin.slide);
        await new Promise(resolve => setTimeout(resolve, 2500));
        pubsub.publish('pin.highlight', pin.id);
    };

    const rotateToPinAndHover = async () => {
        rotateCameraToPinAnimation();
        await promiseUtils.promisifyEvent(window.AFRAME.store.scene.sceneEl, 'camera-animation-end');
        triggerHoverOnPin(pin.id);
    };

    const onClick = async () => {
        if (projector.slide.id === pin.slide.id) {
            if (projector.slide instanceof Tour) {
                if (preview.hoveredPin) {
                    triggerPinMouseLeave(preview.hoveredPin.pin.id);
                    preview.unhoverPin();
                }
                if (preview.place.id !== pin.place.id) {
                    const navPromise = promiseUtils.promisifyEvent(window.AFRAME.store.scene.sceneEl, 'sky-loaded');
                    preview.goto(pin.place, pin.place.props);
                    await navPromise;
                }
                await new Promise(resolve => setTimeout(resolve, 100));
                await rotateToPinAndHover();
            } else if (projector.slide instanceof Board) {
                pubsub.publish('pin.highlight', pin.id);
            }
        } else {
            if (pin.slide instanceof Tour) {
                if (!pin.place) {
                    await navigateToBoardAndHighlight();
                } else {
                    projector.navigate(pin.place.tour, { place: pin.place.id });
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    await rotateToPinAndHover();
                }
            } else if (pin.slide instanceof Board) {
                await navigateToBoardAndHighlight();
            }
        }
    };

    return (
        pin.visible
            ? (
                <span
                    className='c-item c-item-pin text-collapse'
                    title={pin.title}
                    onClick={onClick}
                    data-what='slide-item'
                    data-which={`${pin.id}:${pin.title}`}
                    ga-action='Presentation_Contents'
                    ga-label='Click_Pin'
                >
                    <S.PinRow>
                        <Icon icon='pin-16' size='sm' />
                        <span>{pin.title}</span>
                    </S.PinRow>
                </span>
            )
            : null
    );
};

SlidePin.propTypes = {
    pin: PropTypes.object,
    preview: PropTypes.object,
    projector: PropTypes.object,
    slideIsActive: PropTypes.bool
};

const TourPlace = observer(({ place, preview, projector, slideIsActive, ...props }) => {
    const isActive = preview.place &&
        preview.place.assetId === place.assetId &&
        slideIsActive;

    const name = place.asset.filename
        .substr(0, place.asset.filename.lastIndexOf('.'));

    const onClick = () => {
        if (preview.hoveredPin) {
            triggerPinMouseLeave(preview.hoveredPin.pin.id);
            preview.unhoverPin();
        }

        slideIsActive
            ? preview.goto(place, place.props)
            : projector.navigate(place.tour, { place: place.id });
    };

    return (
        <React.Fragment>
            <span
                className={`c-item text-collapse ${isActive ? 'active-item' : ''}`}
                title={name}
                key={place.id}
                onClick={onClick}
                data-what='slide-item'
                data-which={`${place.id}:${name}`}
                ga-action='Presentation_Contents'
                ga-label='Click_File'
            >{name}
            </span>
            <S.PinsWrapper>
                {
                    place.pins &&
                    place.pins.map(pin =>
                        <SlidePin
                            key={pin.id}
                            pin={pin}
                            preview={preview}
                            projector={projector}
                            slideIsActive={slideIsActive}
                        />
                    )
                }
            </S.PinsWrapper>
        </React.Fragment>
    );
});

const Slide = observer((props) => {
    const [expanded, setExpanded] = useState(false);
    const toggleContent = () => {
        setExpanded(!expanded);
    };

    const navigate = () => {
        const { projector, slide } = props;
        projector.navigate(slide);
    };

    const renderContents = () => {
        const { slide, isCurrent, preview, projector } = props;
        return (
            <React.Fragment>
                {
                    slide.places &&
                    slide.places.map(place =>
                        <TourPlace
                            key={place.id}
                            place={place}
                            preview={preview}
                            projector={projector}
                            slideIsActive={isCurrent}
                        />
                    )
                }
                {
                    slide.pins && !slide.places &&
                    slide.pins.map(pin =>
                        <SlidePin
                            key={pin.id}
                            pin={pin}
                            slideIsActive={isCurrent}
                            projector={projector}
                        />
                    )
                }
            </React.Fragment>
        );
    };

    const { slide, isCurrent } = props;

    return (
        <div
            className={`slide ${isCurrent ? 'current' : ''} ${expanded ? 'expanded' : ''}`}
            key={slide.id}
            data-what='contents-slide'
            data-which={`${slide.type}:${slide.title}`}
            ga-action='Presentation_Contents'
            ga-label='Click_Slide'
        >
            {
                (slide.pins || slide.places) &&
                <div
                    className={`expand-btn ${expanded ? '' : 'closed'}`}
                    onClick={toggleContent}
                    ga-action='Presentation_Contents'
                    ga-label='Click_Toggle_Slide'
                >
                    <span className='icon icon-arrow-down-16'/>
                </div>
            }

            <div
                className='dot'
                onClick={navigate}
                ga-action='Presentation_Contents'
                ga-label='Click_Navigate_Slide'
            />

            <span className='title' onClick={navigate}>{ slide.title }</span>

            <div className={`content ${expanded ? 'opened' : 'closed'}`}>
                { expanded && renderContents() }
            </div>
        </div>
    );
});

Slide.propTypes = {
    slide: PropTypes.object.isRequired,
    projector: PropTypes.shape({
        slide: PropTypes.object,
        navigate: PropTypes.func
    }).isRequired,
    isCurrent: PropTypes.bool,
    preview: PropTypes.object
};

const Contents = props => {
    const close = () => {
        props.store.ui.toggle('contents');
    };

    const { projector, preview, store } = props;
    const slides = store.slide.slides;
    const slide = projector.slide;
    const isOpen = store.ui.contents;

    return (
        <div className={`contents-sidebar ${isOpen ? 'opened' : ''}`}>
            <div className='presentation-title sidebar-header'>
                <span className='icon icon-close' onClick={close}/>
                { props.store.title }
            </div>

            <div className='contents'>
                {
                    slides.map(s =>
                        <Slide
                            slide={s}
                            key={s.id}
                            isCurrent={slide.id === s.id}
                            projector={projector}
                            preview={preview}
                        />
                    )
                }
            </div>
        </div>
    );
};

Contents.propTypes = {
    projector: PropTypes.shape({
        slide: PropTypes.object,
        navigate: PropTypes.func
    }).isRequired,
    preview: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
};

export default observer(Contents);
