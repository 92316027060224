import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { toDataURL } from 'qrcode';

import DialogFrame from '../Components/Dialog/DialogFrame';

export default function QRCodeDialog (props) {
    const [url, setUrl] = useState('');

    useEffect(() => {
        toDataURL(window.location.href, (err, url) => {
            setUrl(url);
        });
    }, []);

    const close = () => {
        props.dialog.dismiss();
    };

    return (
        <DialogFrame
            id='dialog-qr-code'
            dialogSizeClass='xs'
            title=''
            submitTitle={gettext('Got it')}
            hideButtons
            dialog={props.dialog}
            useSimpleCallback={true}
            submitAnalytics={{
                'ga-action': 'QRCode',
                'ga-label': 'QRCode_Dialog_Submit'
            }}
            cancelAnalytics={{
                'ga-action': 'QRCode_Dialog_Cancel',
                'ga-label': 'QRCode'
            }}
        >
            <div className='flex-center flex-col content'>
                <div className='title'>{gettext('Do more with this presentation!')}</div>
                <img data-testid='img__qr-code' src={url} />
                <div className='info'>{gettext('Scan the QR code with your mobile device for an immersive VR experience.')}</div>

                <div
                    className='vw-btn vw-btn-prim vw-btn-default mtb15'
                    onClick={close}
                >{ gettext('Got it')}
                </div>
            </div>
        </DialogFrame>
    );
};

QRCodeDialog.propTypes = {
    dialog: PropTypes.any
};
