import TWEEN from '@tweenjs/tween.js/src/Tween';

import VelocityTracker from './velocity-tracker';
import createCameraAnimation from './camera-animation';

export default function (AFRAME) {
    const THREE = window.THREE;
    const utils = AFRAME.utils;
    const bind = utils.bind;
    const PolyfillControls = utils.device.PolyfillControls;

    // To avoid recalculation at every mouse movement tick
    const GRABBING_CLASS = 'a-grabbing';
    const PI_2 = Math.PI / 2;

    const checkHasPositionalTracking = utils.device.checkHasPositionalTracking;

    /**
     * look-controls. Update entity pose, factoring mouse, touch, and WebVR API data.
     */
    AFRAME.registerComponent('vcs-look-controls', {
        dependencies: ['position', 'rotation'],

        schema: {
            enabled: { default: true },
            hmdEnabled: { default: true },
            pointerLockEnabled: { default: false },
            reverseMouseDrag: { default: false },
            reverseTouchDrag: { default: false },
            touchEnabled: { default: true },
            initialRotation: { default: '0 0 0' },
            autoRotateEnabled: { default: false },
            autoRotateSpeed: { default: 0.001 },
            zoomEnabled: { default: true },
            minZoom: { default: 1 },
            maxZoom: { default: 10 },
            mouseZoomSpeed: { default: 0.1 },
            touchZoomSpeed: { default: 0.05 }
        },

        init () {
            this.previousHMDPosition = new THREE.Vector3();
            this.hmdQuaternion = new THREE.Quaternion();
            this.hmdEuler = new THREE.Euler();
            this.position = new THREE.Vector3();
            // To save / restore camera pose
            this.savedRotation = new THREE.Vector3();
            this.savedPosition = new THREE.Vector3();
            this.polyfillObject = new THREE.Object3D();
            this.polyfillControls = new PolyfillControls(this.polyfillObject);
            this.rotation = {};
            this.deltaRotation = {};
            this.savedPose = null;
            this.pointerLocked = false;
            this.mode = 'normal';
            this.setupMouseControls();
            this.bindMethods();

            this.savedPose = {
                position: new THREE.Vector3(),
                rotation: new THREE.Euler()
            };

            // Call enter VR handler if the scene has entered VR before the event listeners attached.
            if (this.el.sceneEl.is('vr-mode')) { this.onEnterVR(); }

            this.prevZoomDistance = 0;
        },

        update (oldData) {
            const data = this.data;

            // Disable grab cursor classes if no longer enabled.
            if (data.enabled !== oldData.enabled) {
                this.updateGrabCursor(data.enabled);
            }

            // Reset pitch and yaw if disabling HMD.
            if (oldData && !data.hmdEnabled && !oldData.hmdEnabled) {
                this.pitchObject.rotation.set(0, 0, 0);
                this.yawObject.rotation.set(0, 0, 0);
            }

            if (oldData && !data.pointerLockEnabled !== oldData.pointerLockEnabled) {
                this.removeEventListeners();
                this.addEventListeners();
                if (this.pointerLocked) { document.exitPointerLock(); }
            }

            this.mode = this.data.autoRotateEnabled
                ? 'auto_rotate'
                : 'normal';
            oldData.initialRotation !== data.initialRotation && this.setupInitialRotation();
            this.velocityTracker.reset();
        },

        tick (t) {
            const data = this.data;
            if (!data.enabled) return;
            this.updateOrientation();
        },

        play () {
            this.addEventListeners();
        },

        pause () {
            this.removeEventListeners();
        },

        remove () {
            this.removeEventListeners();
        },

        ifEnabled (func) {
            return function (...args) {
                if (!this.data.enabled) return;
                return func.apply(this, args);
            };
        },

        bindMethods () {
            this.onMouseDown = bind(this.ifEnabled(this.onMouseDown), this);
            this.onMouseMove = bind(this.ifEnabled(this.onMouseMove), this);
            this.onMouseUp = bind(this.ifEnabled(this.onMouseUp), this);
            this.onMouseWheel = bind(this.ifEnabled(this.onMouseWheel), this);
            this.onTouchStart = bind(this.ifEnabled(this.onTouchStart), this);
            this.onTouchMove = bind(this.ifEnabled(this.onTouchMove), this);
            this.onTouchEnd = bind(this.ifEnabled(this.onTouchEnd), this);
            this.onEnterVR = bind(this.ifEnabled(this.onEnterVR), this);
            this.onExitVR = bind(this.ifEnabled(this.onExitVR), this);
            this.onPointerLockChange = bind(this.ifEnabled(this.onPointerLockChange), this);
            this.onPointerLockError = bind(this.ifEnabled(this.onPointerLockError), this);
            this.onActivity = bind(this.ifEnabled(this.onActivity), this);
            this.onIdle = bind(this.ifEnabled(this.onIdle), this);
            this.onAnimationRequest = bind(this.ifEnabled(this.onAnimationRequest), this);
            this.onZoomAnimationRequest = bind(this.ifEnabled(this.onZoomAnimationRequest), this);
            this.onCameraRotationRequest = bind(this.ifEnabled(this.onCameraRotationRequest), this);
        },

        /**
         * Set up states and Object3Ds needed to store rotation data.
         */
        setupMouseControls () {
            this.mouseDown = false;
            this.touchStarted = false;
            this.touchZoomStarted = false;
            this.pitchObject = new THREE.Object3D();
            this.yawObject = new THREE.Object3D();
            this.yawObject.position.y = 10;
            this.yawObject.add(this.pitchObject);
            this.velocityTracker = new VelocityTracker(this.pitchObject, this.yawObject);
        },

        setupInitialRotation () {
            const initialRotation = this.data.initialRotation.split(';')[0];
            this.rotateCamera(utils.coordinates.parse(initialRotation));
        },

        rotateCamera (rotation) {
            const object3D = this.el.object3D;

            // Calculate polyfilled HMD quaternion.
            this.polyfillControls.update();
            this.hmdEuler.setFromQuaternion(this.polyfillObject.quaternion, 'YXZ');

            object3D.rotation.x = rotation.x;
            object3D.rotation.y = rotation.y;

            this.el.emit('camera-rotate', rotation);

            this.pitchObject.rotation.x = rotation.x - this.hmdEuler.x;
            this.yawObject.rotation.y = rotation.y - this.hmdEuler.y;

            this.velocityTracker = new VelocityTracker(this.pitchObject, this.yawObject);
        },

        setupCameraAnimation ({ targetPitch, targetYaw, data }) {
            // Calculate polyfilled HMD quaternion.
            const hmdEuler = new THREE.Euler();
            this.polyfillControls.update();
            hmdEuler.setFromQuaternion(this.polyfillObject.quaternion, 'YXZ');

            this.pitchAnimation = createCameraAnimation(
                this.pitchObject.rotation, 'x', targetPitch - hmdEuler.x
            );
            this.pitchAnimation.start();
            this.yawAnimation = createCameraAnimation(
                this.yawObject.rotation, 'y', targetYaw - hmdEuler.y
            );
            this.yawAnimation.onComplete(() => {
                this.pitchAnimation.stop();
                this.yawAnimation.stop();
                this.el.sceneEl.emit('camera-animation-end', { data });
                this.mode = 'normal';
            });
            this.yawAnimation.start();
        },

        setupCameraZoomAnimation () {
            const camera = this.el.sceneEl.camera;
            this.zoomAnimation = createCameraAnimation(
                camera, 'zoom', camera.zoom * 2, 2000
            );
            this.zoomAnimation.onUpdate(() => {
                camera.updateProjectionMatrix();
            });
            this.zoomAnimation.onComplete(() => {
                this.zoomAnimation.stop();
                this.el.sceneEl.emit('camera-zoom-animation-end', {});
                this.mode = 'normal';
            });
            this.zoomAnimation.start();
        },

        /**
         * Add mouse and touch event listeners to canvas.
         */
        addEventListeners () {
            const sceneEl = this.el.sceneEl;
            const canvasEl = sceneEl.canvas;

            // Wait for canvas to load.
            if (!canvasEl) {
                sceneEl.addEventListener('render-target-loaded', bind(this.addEventListeners, this));
                return;
            }

            // Mouse events.
            canvasEl.addEventListener('mousedown', this.onMouseDown, false);
            window.addEventListener('mousemove', this.onMouseMove, false);
            window.addEventListener('mouseup', this.onMouseUp, false);
            canvasEl.addEventListener('wheel', this.onMouseWheel, false);

            // Touch events.
            canvasEl.addEventListener('touchstart', this.onTouchStart);
            window.addEventListener('touchmove', this.onTouchMove);
            window.addEventListener('touchend', this.onTouchEnd);

            // sceneEl events.
            sceneEl.addEventListener('enter-vr', this.onEnterVR);
            sceneEl.addEventListener('exit-vr', this.onExitVR);

            // Pointer Lock events.
            if (this.data.pointerLockEnabled) {
                document.addEventListener('pointerlockchange', this.onPointerLockChange, false);
                document.addEventListener('mozpointerlockchange', this.onPointerLockChange, false);
                document.addEventListener('pointerlockerror', this.onPointerLockError, false);
            }

            sceneEl.addEventListener('idle', this.onIdle);
            sceneEl.addEventListener('activity', this.onActivity);
            sceneEl.addEventListener('camera-animation-request', this.onAnimationRequest);
            sceneEl.addEventListener('camera-zoom-animation-request', this.onZoomAnimationRequest);
            sceneEl.addEventListener('camera-rotation-request', this.onCameraRotationRequest);
        },

        /**
         * Remove mouse and touch event listeners from canvas.
         */
        removeEventListeners () {
            const sceneEl = this.el.sceneEl;
            const canvasEl = sceneEl && sceneEl.canvas;

            if (!canvasEl) { return; }

            // Mouse events.
            canvasEl.removeEventListener('mousedown', this.onMouseDown);
            window.removeEventListener('mousemove', this.onMouseMove);
            window.removeEventListener('mouseup', this.onMouseUp);
            canvasEl.removeEventListener('wheel', this.onMouseWheel, false);

            // Touch events.
            canvasEl.removeEventListener('touchstart', this.onTouchStart);
            window.removeEventListener('touchmove', this.onTouchMove);
            window.removeEventListener('touchend', this.onTouchEnd);

            // sceneEl events.
            sceneEl.removeEventListener('enter-vr', this.onEnterVR);
            sceneEl.removeEventListener('exit-vr', this.onExitVR);

            // Pointer Lock events.
            document.removeEventListener('pointerlockchange', this.onPointerLockChange, false);
            document.removeEventListener('mozpointerlockchange', this.onPointerLockChange, false);
            document.removeEventListener('pointerlockerror', this.onPointerLockError, false);

            sceneEl.removeEventListener('idle', this.onIdle);
            sceneEl.removeEventListener('activity', this.onActivity);
            sceneEl.removeEventListener('camera-animation-request', this.onAnimationRequest);
            sceneEl.removeEventListener('camera-zoom-animation-request', this.onZoomAnimationRequest);
            sceneEl.removeEventListener('camera-rotation-request', this.onCameraRotationRequest);
        },

        /**
         * Update orientation for mobile, mouse drag, and headset.
         * Mouse-drag only enabled if HMD is not active.
         */
        updateOrientation () {
            const el = this.el;
            const hmdEuler = this.hmdEuler;
            const pitchObject = this.pitchObject;
            const yawObject = this.yawObject;
            const sceneEl = this.el.sceneEl;
            const { x: oldX, y: oldY } = el.object3D.rotation;

            // In VR mode, THREE is in charge of updating the camera rotation.
            if (sceneEl.is('vr-mode') && sceneEl.checkHeadsetConnected()) { return; }

            if (this.mode === 'auto_rotate') {
                const direction = this.data.reverseMouseDrag ? 1 : -1;
                this.yawObject.rotation.y += this.data.autoRotateSpeed * direction;
            } else if (this.mode.startsWith('animate_')) {
                TWEEN.update();
            } else if ((this.mouseDown || this.touchStarted)) {
                this.velocityTracker.track();
            } else {
                this.velocityTracker.applyVelocity(this.data.reverseMouseDrag ? 1 : -1);
            }
            this.velocityTracker.sync();

            // Calculate polyfilled HMD quaternion.
            this.polyfillControls.update();
            hmdEuler.setFromQuaternion(this.polyfillObject.quaternion, 'YXZ');

            // On mobile, do camera rotation with touch events and sensors.
            el.object3D.rotation.x = hmdEuler.x + pitchObject.rotation.x;
            el.object3D.rotation.x = Math.max(-PI_2, Math.min(PI_2, el.object3D.rotation.x));
            el.object3D.rotation.y = hmdEuler.y + yawObject.rotation.y;

            const { x, y } = el.object3D.rotation;
            if (x !== oldX || y !== oldY) {
                el.emit('camera-rotate', { x, y, z: 0 });
            }
        },

        /**
         * Translate mouse drag into rotation.
         *
         * Dragging up and down rotates the camera around the X-axis (yaw).
         * Dragging left and right rotates the camera around the Y-axis (pitch).
         */
        onMouseMove (event) {
            let movementX;
            let movementY;
            const pitchObject = this.pitchObject;
            const previousMouseEvent = this.previousMouseEvent;
            const yawObject = this.yawObject;

            // Not dragging or not enabled.
            if (!this.mouseDown && !this.pointerLocked) return;

            // Calculate delta.
            if (this.pointerLocked) {
                movementX = event.movementX || event.mozMovementX || 0;
                movementY = event.movementY || event.mozMovementY || 0;
            } else {
                movementX = event.screenX - previousMouseEvent.screenX;
                movementY = event.screenY - previousMouseEvent.screenY;
            }
            this.previousMouseEvent = event;

            // Calculate rotation.
            const direction = this.data.reverseMouseDrag ? 1 : -1;
            yawObject.rotation.y += movementX * 0.002 * direction;
            pitchObject.rotation.x += movementY * 0.002 * direction;
            pitchObject.rotation.x = Math.max(-PI_2, Math.min(PI_2, pitchObject.rotation.x));
        },

        /**
         * Register mouse down to detect mouse drag.
         */
        onMouseDown (evt) {
            // Handle only primary button.
            if (evt.button !== 0) return;

            const sceneEl = this.el.sceneEl;
            const canvasEl = sceneEl && sceneEl.canvas;

            this.mouseDown = true;
            this.mode = 'normal';
            this.previousMouseEvent = evt;
            document.body.classList.add(GRABBING_CLASS);

            if (this.data.pointerLockEnabled && !this.pointerLocked) {
                if (canvasEl.requestPointerLock) {
                    canvasEl.requestPointerLock();
                } else if (canvasEl.mozRequestPointerLock) {
                    canvasEl.mozRequestPointerLock();
                }
            }
            this.velocityTracker.reset();
        },

        /**
         * Register mouse up to detect release of mouse drag.
         */
        onMouseUp () {
            if (!this.mouseDown) return;
            this.mouseDown = false;
            document.body.classList.remove(GRABBING_CLASS);
            this.velocityTracker.startInertiaMovement();
        },

        onMouseWheel (evt) {
            this.mode = 'normal';
            if (!this.data.zoomEnabled) return;
            const camera = this.el.sceneEl.camera;
            const sign = evt.deltaY < 0
                ? 1
                : evt.deltaY > 0
                    ? -1
                    : 0;
            camera.zoom = Math.max(
                this.data.minZoom,
                Math.min(
                    this.data.maxZoom,
                    camera.zoom + sign * camera.zoom * this.data.mouseZoomSpeed
                )
            );
            camera.updateProjectionMatrix();
        },

        /**
         * Register touch down to detect touch drag.
         */
        onTouchStart (evt) {
            console.log('touches', evt.touches.length);
            this.mode = 'normal';
            if (this.touchStarted) {
                this.touchStarted = false;
            }
            if (this.touchZoomStarted) {
                this.touchZoomStarted = false;
            }
            if (this.data.touchEnabled && evt.touches.length === 1) {
                this.touchStart = {
                    x: evt.touches[0].pageX,
                    y: evt.touches[0].pageY
                };
                this.touchStarted = true;
                this.velocityTracker.reset();
            } else if (this.data.zoomEnabled && evt.touches.length === 2) {
                this.onTouchZoomStart(evt);
            }
        },

        /**
         * Translate touch move to Y-axis rotation.
         */
        onTouchMove (evt) {
            if (this.touchStarted) {
                const canvas = this.el.sceneEl.canvas;
                const yawObject = this.yawObject;
                const pitchObject = this.pitchObject;
                const deltaY = 2 * Math.PI * (evt.touches[0].pageX - this.touchStart.x) / canvas.clientWidth;
                const deltaX = 2 * Math.PI * (evt.touches[0].pageY - this.touchStart.y) / canvas.clientHeight;
                const direction = this.data.reverseTouchDrag ? 1 : -1;
                yawObject.rotation.y += deltaY * 0.5 * direction;
                pitchObject.rotation.x += deltaX * 0.5 * direction;
                pitchObject.rotation.x = Math.max(-PI_2, Math.min(PI_2, pitchObject.rotation.x));
                this.touchStart = {
                    x: evt.touches[0].pageX,
                    y: evt.touches[0].pageY
                };
            } else if (this.touchZoomStarted) {
                this.onTouchZoomMove(evt);
            }
        },

        /**
         * Register touch end to detect release of touch drag.
         */
        onTouchEnd () {
            if (this.touchStarted) {
                this.touchStarted = false;
                this.velocityTracker.startInertiaMovement();
            } else if (this.touchZoomStarted) {
                this.touchZoomStarted = false;
            }
        },

        onTouchZoomStart (evt) {
            this.touchZoomStarted = true;
            const dx = evt.touches[0].pageX - evt.touches[1].pageX;
            const dy = evt.touches[0].pageY - evt.touches[1].pageY;
            this.prevZoomDistance = Math.sqrt(dx * dx + dy * dy);
        },

        onTouchZoomMove (evt) {
            if (!this.touchZoomStarted) return;
            if (evt.touches.length !== 2) {
                this.touchZoomStarted = false;
                return;
            }
            const dx = evt.touches[0].pageX - evt.touches[1].pageX;
            const dy = evt.touches[0].pageY - evt.touches[1].pageY;
            const distance = Math.sqrt(dx * dx + dy * dy);
            const sign = distance > this.prevZoomDistance ? 1 : -1;

            const camera = this.el.sceneEl.camera;
            camera.zoom = Math.max(
                this.data.minZoom,
                Math.min(
                    this.data.maxZoom,
                    camera.zoom + sign * this.data.touchZoomSpeed * camera.zoom
                )
            );
            camera.updateProjectionMatrix();
            this.prevZoomDistance = distance;
        },

        /**
         * Save pose.
         */
        onEnterVR () {
            this.saveCameraPose();
        },

        /**
         * Restore the pose.
         */
        onExitVR () {
            this.restoreCameraPose();
            this.previousHMDPosition.set(0, 0, 0);
        },

        /**
         * Update Pointer Lock state.
         */
        onPointerLockChange () {
            this.pointerLocked = !!(document.pointerLockElement || document.mozPointerLockElement);
        },

        /**
         * Recover from Pointer Lock error.
         */
        onPointerLockError () {
            this.pointerLocked = false;
        },

        onIdle () {
            if (this.data.autoRotateEnabled) {
                this.mode = 'auto_rotate';
            }
        },

        onActivity () {
            if (this.data.autoRotateEnabled) {
                if (!this.mode.startsWith('animate_')) {
                    this.mode = 'normal';
                }
            }
        },

        onAnimationRequest (ev) {
            this.mode = 'animate_camera';
            this.setupCameraAnimation(ev.detail);
        },

        onZoomAnimationRequest (ev) {
            this.mode = 'animate_camera_zoom';
            this.setupCameraZoomAnimation(ev.detail);
        },

        onCameraRotationRequest (ev) {
            this.rotateCamera(ev.detail);
        },

        /**
         * Toggle the feature of showing/hiding the grab cursor.
         */
        updateGrabCursor (enabled) {
            const sceneEl = this.el.sceneEl;

            function enableGrabCursor () { sceneEl.canvas.classList.add('a-grab-cursor'); }
            function disableGrabCursor () { sceneEl.canvas.classList.remove('a-grab-cursor'); }

            if (!sceneEl.canvas) {
                if (enabled) {
                    sceneEl.addEventListener('render-target-loaded', enableGrabCursor);
                } else {
                    sceneEl.addEventListener('render-target-loaded', disableGrabCursor);
                }
                return;
            }

            if (enabled) {
                enableGrabCursor();
                return;
            }
            disableGrabCursor();
        },

        /**
         * Save camera pose before entering VR to restore later if exiting.
         */
        saveCameraPose () {
            const el = this.el;
            const hasPositionalTracking = this.hasPositionalTracking !== undefined
                ? this.hasPositionalTracking
                : checkHasPositionalTracking();

            if (this.hasSavedPose || !hasPositionalTracking) { return; }

            this.savedPose.position.copy(el.object3D.position);
            this.savedPose.rotation.copy(el.object3D.rotation);
            this.hasSavedPose = true;
        },

        /**
         * Reset camera pose to before entering VR.
         */
        restoreCameraPose () {
            const el = this.el;
            const savedPose = this.savedPose;
            const hasPositionalTracking = this.hasPositionalTracking !== undefined
                ? this.hasPositionalTracking
                : checkHasPositionalTracking();

            if (!this.hasSavedPose || !hasPositionalTracking) { return; }

            // Reset camera orientation.
            el.object3D.position.copy(savedPose.position);
            el.object3D.rotation.copy(savedPose.rotation);
            this.hasSavedPose = false;
        }
    });
};
