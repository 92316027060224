import React from 'react';
import { observer } from 'mobx-react';

import Head from './Head';
import Body from './Body';
import ViewerContext from './ViewerContext';

const Table = () => {
    const { isMobile } = React.useContext(ViewerContext);
    return (
        <React.Fragment>
            { !isMobile && <Head/> }
            <Body/>
        </React.Fragment>
    );
};

export default observer(Table);
