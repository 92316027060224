import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Cell from './Cell';
import S from './styles';

// this component displays the contents of a record in the desktop layout
// (1 row in the table)
const LgContents = React.forwardRef(
    ({ item, store, onClick, selected, skipColumns = [] }, ref) =>
        <S.LgContent
            count={store.columns.activeCount}
            className={`${selected ? 'item-selected-light' : null}`}
            ref={ref}
        >
            {
                store.columns.active.map(cell =>
                    <Cell
                        key={`${item.key}#${cell.id}`}
                        item={item}
                        onClick={onClick}
                        cell={!skipColumns.includes(cell.id) ? cell : null}
                        store={store}
                        isMobile={false}
                    />
                )
            }
        </S.LgContent>
);

LgContents.propTypes = {
    item: PropTypes.object,
    store: PropTypes.object,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    skipColumns: PropTypes.array
};

export default observer(LgContents);
