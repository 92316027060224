import ReactDOM from 'react-dom';
import React from 'react';

import { Toasts } from '@vectorworks/vcs-ui/dist/lib/Toast/Toast';

try {
    ReactDOM.render(
        <Toasts />,
        document.getElementById('react-toasts')
    );
} catch (e) {
    console.log('Unable to render toasts.');
}
