import { detectPanoramaImage } from '../../file-viewer/panorama/jpeg';
import { FileTypes, FilePath } from '../lib/file';
import { Dialog } from '../base/dialog';
import DialogInvalidUpload from './Dialog/DialogInvalidUpload';

const validations = {
    required: value => value.trim().length,
    maxLength: (value, maxLength) => value.length <= maxLength,
    notInArray: (value, array) => !array.includes(value),
    inArray: (value, array) => array.includes(value)
};

function createSlideValidation (value, existingSlideTitles) {
    const msg = !validations.required(value)
        ? gettext('This field is required.')
        : !validations.maxLength(value, 256)
            ? gettext('The maximum length is 255 characters.')
            : validations.inArray(value, existingSlideTitles)
                ? gettext('Slide with the same name already exists.')
                : null;

    return { error: msg };
}

function renameSlideValidation (value, existingSlideTitles) {
    const msg = !validations.required(value)
        ? gettext('This field is required')
        : !validations.maxLength(value, 256)
            ? gettext('Max length should be less than 256 symbols')
            : validations.inArray(value, existingSlideTitles)
                ? gettext('Slide with the same name already exists.')
                : null;

    return { error: msg };
}

function insertLinkTitleValidation (value) {
    const msg = !validations.required(value)
        ? gettext('This field is required.')
        : !validations.maxLength(value, 256)
            ? gettext('The maximum length is 255 characters.')
            : null;

    return { error: msg };
}
function insertLinkURLValidation (value) {
    const msg = !validations.required(value)
        ? gettext('This field is required.')
        : !validations.maxLength(value, 1000)
            ? gettext('The maximum length is 1000 characters.')
            : null;

    return { error: msg };
}

const fileContentValidators = {
    panorama (file) {
        return new Promise((resolve, reject) =>
            detectPanoramaImage(isPanorama => resolve(isPanorama))(file)
        );
    }
};

function validateExistingFiles (assets, files) {
    const [existing, nonExisting] = files.reduce((acc, file) => {
        const group = assets.find(
            asset => asset.state !== 'error' && asset.filename === file.name
        )
            ? 0
            : 1;
        acc[group].push(file);
        return acc;
    }, [[], []]);
    return existing.length
        ? (Dialog.open({
            component: 'dialog-confirm-replace-file-in-presentation',
            ctx: {
                ctx: { items: existing }
            }
        }).result
            .then(() => files, () => nonExisting))
        : Promise.resolve(files);
}

const baseValidator = {
    validateUpload (files) {
        // TODO: maybe we should validate for assets in preparing state
        return Promise.all(files.map(file => this.isFileAllowedForUpload(file)))
            .then(results => results.reduce((acc, result, i) => {
                result
                    ? acc[0].push(files[i])
                    : acc[1].push(files[i]);
                return acc;
            }, [[], []]));
    },
    forUpload (assetStore, dialogStore, message) {
        const validator = files => this.validateUpload(files)
            .then(([validFiles, invalidFiles]) => {
                return validateExistingFiles(assetStore.assets, validFiles)
                    .then(confirmedFiles => [confirmedFiles, invalidFiles]);
            })
            .then(([validFiles, invalidFiles]) => {
                if (invalidFiles.length) {
                    dialogStore.open({
                        component: DialogInvalidUpload,
                        context: {
                            items: invalidFiles,
                            message: interpolate(
                                gettext('%(message)s Supported file types are: %(types)s.'),
                                {
                                    message: `${message}`,
                                    types: this.previewTypes
                                }, true)
                        }
                    });
                }
                return [validFiles, invalidFiles];
            });
        validator.settings = this;
        return validator;
    },
    forUploadOfOneFile (assetStore, dialogStore, message) {
        const validator = files => this.validateUpload(files)
            .then(([validFiles, invalidFiles]) => {
                return validateExistingFiles(assetStore.assets, validFiles)
                    .then(confirmedFiles => [confirmedFiles, invalidFiles]);
            })
            .then(([validFiles, invalidFiles]) => {
                if (!validFiles.length) {
                    dialogStore.open({
                        component: DialogInvalidUpload,
                        context: {
                            items: invalidFiles,
                            message: interpolate(
                                gettext('%(message)s Supported file types are: %(types)s.'),
                                {
                                    message: `${message}`,
                                    types: this.previewTypes
                                }, true)
                        }
                    });
                }
                return [validFiles, invalidFiles];
            });
        validator.settings = this;
        return validator;
    },
    isFileTypeAllowed (name) {
        return this.types.includes(FileTypes.get(name).type);
    },

    isFileExtensionAllowed (name) {
        return this.extensions.some(ext => !!FilePath(name).extension().match(ext));
    },

    isFileMimeTypeAllowed (mimeType) {
        return this.mimeTypes.some(t => mimeType.match(t));
    },
    async isFileContentAllowed (file) {
        for (const contentType of this.fileContent) {
            const result = await fileContentValidators[contentType](file);
            if (result) {
                return true;
            }
        }
        return false;
    },
    isFileAllowedForUpload (file) {
        if (this.fileContent) {
            return this.isFileContentAllowed(file);
        }

        let allowed = false;
        if (this.mimeTypes) {
            allowed = allowed || this.isFileMimeTypeAllowed(file.type);
        }
        if (this.extensions) {
            allowed = allowed || this.isFileExtensionAllowed(file.name);
        }
        return Promise.resolve(allowed);
    }
};

const assetValidators = {
    PRESENTATION: {
        types: ['image', 'panorama', 'pdf', 'video', 'vgx'],
        extensions: [/\.(vgx$)/i],
        mimeTypes: [/image\/.*/i, /video\/.*/i, /application\/pdf/i],
        htmlInputTypes: 'image/*, video/*, .pdf, .vgx',
        previewTypes: gettext('PDFs, videos, 3D models (.vgx files), and images (including 360° panoramic)')
    },
    BOARD: {
        types: ['image', 'panorama', 'pdf', 'video', 'vgx'],
        extensions: [/\.(vgx$)/i],
        mimeTypes: [/image\/.*/i, /video\/.*/i, /application\/pdf/i],
        htmlInputTypes: 'image/*, video/*, .pdf, .vgx',
        previewTypes: gettext('PDFs, videos, 3D models (.vgx files), and images (including 360° panoramic)')
    },
    LAYOUT: {
        types: ['image', 'pdf'],
        mimeTypes: [/image\/.*/i, /application\/pdf/i],
        htmlInputTypes: 'image/*, .pdf',
        previewTypes: gettext('images and PDFs')
    },
    PDF: {
        types: ['pdf'],
        mimeTypes: [/application\/pdf/i],
        htmlInputTypes: '.pdf',
        previewTypes: gettext('PDFs')
    },
    IMAGE: {
        types: ['image'],
        mimeTypes: [/image\/.*/i],
        htmlInputTypes: 'image/*',
        previewTypes: gettext('images')
    },
    PANORAMA: {
        types: ['panorama'],
        extensions: [/\.(jpe?g$)/i],
        fileContent: ['panorama'],
        htmlInputTypes: '.jpg, .jpeg, .JPG, .JPEG',
        previewTypes: gettext('360° panoramic images')
    }
};
Object.keys(assetValidators).forEach(k => {
    Object.assign(assetValidators[k], baseValidator);
});

export default validations;
export {
    assetValidators,
    createSlideValidation,
    renameSlideValidation,
    insertLinkTitleValidation,
    insertLinkURLValidation,
    validateExistingFiles
};
