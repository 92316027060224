import React from 'react';

import Version1 from './v1/index';
import Version2 from './v2/index';

const ConnectCADViewer = props => Settings.NEW_FEATURES.isActive('use-connect-cad-version-2')
    ? <Version2 {...props} />
    : <Version1 {...props} />;

export default ConnectCADViewer;
