import React from 'react';

import { observer } from 'mobx-react';

import { commonPropTypes } from './utils';

const EmptyViewer = props => (
    <div
        className='fileview-component-loader'
        data-what='file-viewer'
        {...props.controllerTogglers}
    >
        { props.children }
    </div>);

export default observer(EmptyViewer);

EmptyViewer.propTypes = {
    ...commonPropTypes
};
