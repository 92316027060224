import React from 'react';

import { observer } from 'mobx-react';
import convert from 'htmr';
import PropTypes from 'prop-types';

import { PreviewTextPin, ThumbnailTextPin } from '../Pins/TextPin';
import Linkify from './Linkify';

function makeThumbnailPin (props, context) {
    return <ThumbnailTextPin {...props} />;
}

function makePreviewPin (props, context) {
    return (
        <PreviewTextPin
            {...props}
            screen={context.screen}
            previewContainer={context.containerRef}
        />
    );
}

function makePin (props, context) {
    const { board, elementFactories } = context;
    const factory = elementFactories.pin;
    const pinId = Number(props['data-pin-id']);
    const pin = board.getPinById(pinId);
    return !!pin && factory({
        key: `pin-${pinId}-${props.key}`,
        pin
    }, context);
}

/* eslint-disable */
const htmlMapper = context => ({
    _: (node, props, children) => {
        return (typeof props === 'undefined')
            ? context.renderText(node)
            : props['data-component'] === 'pin'
                ? makePin(props, context)
                : React.createElement(node, props, children);
    }
});
/* eslint-enable */

const SimpleText = observer(({ text, htmlMapper }) => {
    const html = text.text
        ? ((text.text.html == null)
            ? (text.text || '')
            : text.text.html)
        : '';
    return (
        <div className='ib-text-container'>
            <div className='ib-text-field no-text-editor'>
                <div
                    className='ib-text'
                    draggable={false}
                    size={2}
                    style={{
                        color: text.color.toCSS()
                    }}
                >
                    {convert(html, { transform: htmlMapper })}
                </div>
            </div>
        </div>
    );
});
SimpleText.propTypes = {
    text: PropTypes.object,
    htmlMapper: PropTypes.object
};

const ThumbnailSimpleText = observer(({ text, board }) =>
    <SimpleText
        text={text}
        htmlMapper={htmlMapper({
            board,
            elementFactories: {
                pin: makeThumbnailPin
            },
            renderText: node => node
        })}
    />
);
ThumbnailSimpleText.propTypes = {
    text: PropTypes.object,
    board: PropTypes.object
};

const PreviewSimpleText = observer(({ board, text, screen, previewContainer }) =>
    <SimpleText
        text={text}
        htmlMapper={htmlMapper({
            board,
            screen,
            containerRef: previewContainer,
            elementFactories: {
                pin: makePreviewPin
            },
            renderText: node => <Linkify>{node}</Linkify>
        })}
    />
);
PreviewSimpleText.propTypes = {
    board: PropTypes.object,
    text: PropTypes.object,
    screen: PropTypes.object,
    previewContainer: PropTypes.object
};

export {
    ThumbnailSimpleText,
    PreviewSimpleText
};
