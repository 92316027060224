import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { inject } from '../Store';
import Comments from '../Comments';
import Contents from './Contents';
import ControllBar from './ControllBar';
import { addClass, removeClass } from '../../lib/dom-utils';
import Fileview from '../FileViewers/Fileview';
import fullScreen from '../../base/fullscreen';
import keyboard from '../../base/keyboard';
import { queryString } from '../../lib';
import SlideAnimation from '../common/SlideAnimation';
import { Ref } from '../utils/react-utils';
import Viewers from './Viewers';
import { DialogContainer } from '@vectorworks/vcs-ui/dist/lib/Dialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { SlideType } from '../models/slide';

window.moment = moment;

const PreviewSlide = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const next = queryString.parse(location.search).next;
    const previewContainer = new Ref();
    let preview = null;
    const setPreviewContainer = node => previewContainer.setNode(node);

    const closePreview = () => {
        navigate(next || `/${props.store.id}/`);
        keyboard.peekKeyEvents().reduce();
        removeClass(preview, 'is-fullscreen');
        removeClass(preview, 'full-screen');
        fullScreen.isFullscreen && fullScreen.exit();
    };

    useEffect(() => {
        props.projector.slide.type === SlideType.BOARD &&
            props.store.setPreviewMode(true);

        return () => {
            props.store.setPreviewMode(false);
        };
    }, []);

    /* Preload preveious and next slides */
    useEffect(() => {
        const processes = [];

        [
            props.projector.nextSlide(),
            props.projector.prevSlide()
        ]
            .filter(slide => !!slide && slide.type === SlideType.BOARD)
            .forEach(slide => {
                slide.preloadImages()
                    .forEach(p => processes.push(p));
            });

        return () => {
            processes.forEach(process => process.cancelDownload());
        };
    }, [props.projector.slideId]);

    useEffect(() => {
        setTimeout(() => {
            const keyEvents = keyboard.peekKeyEvents();
            keyEvents.extend({
                escape: {
                    predicate: event => event.which === 27,
                    handler: () => closePreview()
                }
            });

            props.store.setFullscreen(fullScreen.isFullscreen);
        }, 100);

        preview = document.getElementById('presentation-fs-preview');
        addClass(preview, 'is-fullscreen');

        return () => {
            if (!fullScreen.isFullscreen) {
                removeClass(preview, 'is-fullscreen');
                removeClass(preview, 'full-screen');
            }
        };
    });

    const renderViewer = () => {
        const Viewer = props.preview ? Viewers[props.preview.slide.type] : null;

        return (
            <Viewer
                preview={props.preview}
                projector={props.projector}
                store={props.store}
                previewContainer={previewContainer}
            />
        );
    };

    return (
        <React.Fragment>
            <div id='fs-splash'/>
            <div className='ib-fs-wrapper' ref={setPreviewContainer}>
                {
                    props.fileViewStore.ui.visible &&
                        <Fileview store={props.fileViewStore} />
                }

                <Contents {...props} preview={props.preview} />
                {
                    !Settings.offlinePresentation &&
                    <Comments {...props} isInPreview={true} />
                }
                { props.store.transitions && <SlideAnimation/> }

                { renderViewer() }
                <ControllBar screen={props.preview} projector={props.projector} store={props.store}/>
                <DialogContainer dialogContainer={props.store.dialog} />
            </div>
        </React.Fragment>
    );
};

PreviewSlide.propTypes = {
    store: PropTypes.object.isRequired,
    fileViewStore: PropTypes.object,
    projector: PropTypes.shape({
        slide: PropTypes.object,
        next: PropTypes.func,
        prev: PropTypes.func,
        nextSlide: PropTypes.func,
        prevSlide: PropTypes.func,
        hasNext: PropTypes.func,
        hasPrev: PropTypes.func,
        slideId: PropTypes.string
    }).isRequired,
    hideToolbar: PropTypes.bool,
    preview: PropTypes.object.isRequired
};

export default inject('fileViewStore')(observer(PreviewSlide));
