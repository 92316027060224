import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Cell from './Cell';
import S from './styles';

const MobileContents = observer(
    ({ item, store, skipColumns = [] }) =>
        store.columns.active
            .filter(c => !skipColumns.includes(c.id) && c.shouldRender(item[c.id]))
            .map(c =>
                <S.SmContent key={`sm-${c.id}`}>
                    <b>
                        {`${c.title || c.id} ${c.unit ? `(${c.unit.title})` : ''}`}
                    </b>
                    <Cell
                        key={`${item.key}#${c.id}`}
                        item={item}
                        cell={c}
                        store={store}
                        isMobile={true}
                    />
                </S.SmContent>
            )
);

MobileContents.propTypes = {
    item: PropTypes.object,
    store: PropTypes.object,
    skipColumns: PropTypes.array
};

export default MobileContents;
