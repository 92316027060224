import { makeAutoObservable, runInAction, observable } from 'mobx';

import storage from '../../../storage';
import { COLUMNS } from './utils';

const PRECISION = 2;

const UNITS = {
    length: {
        m: {
            id: 'm',
            // translators: abbreviation of the unit of measurement "meter"
            title: gettext('m'),
            toBase: x => x,
            fromBase: x => x
        },
        mm: {
            id: 'mm',
            // translators: abbreviation of the unit of measurement "millimeter"
            title: gettext('mm'),
            toBase: x => `ROUND(${x} / 1000, ${PRECISION})`,
            fromBase: x => `ROUND(1000 * ${x}, ${PRECISION})`
        },
        in: {
            id: 'in',
            // translators: abbreviation of the unit of measurement "inch"
            title: gettext('in'),
            toBase: x => `ROUND(0.0254 * ${x}, ${PRECISION})`,
            fromBase: x => `ROUND(${x} / 0.0254, ${PRECISION})`
        },
        ft: {
            id: 'ft',
            // translators: abbreviation of the unit of measurement "foot"
            title: gettext('ft'),
            toBase: x => `ROUND(0.3048 * ${x}, ${PRECISION})`,
            fromBase: x => `ROUND(${x} / 0.3048, ${PRECISION})`
        },
        ftin: {
            id: 'ftin',
            // translators: abbreviation of the unit of measurement "foot / inch"
            title: gettext('ft/in'),
            toBase: x => {
                // not used: there's no column with such a default unit
                return x;
            },
            fromBase: x => {
                const feet = `(CAST ((${x} / 0.3048) AS INT))`;
                const inches = `(ROUND (((${x} - ${feet} * 0.3048) / 0.0254), ${PRECISION}))`;
                return `(${feet} || "'" || ${inches} || """")`;
            }
        }
    },
    weight: {
        kg: {
            id: 'kg',
            // translators: abbreviation of the unit of measurement "kilogram"
            title: gettext('kg'),
            toBase: x => x,
            fromBase: x => x
        },
        lbs: {
            id: 'lbs',
            // translators: abbreviation of the unit of measurement "pound"
            title: gettext('lbs'),
            toBase: x => `ROUND(0.453592 * ${x}, ${PRECISION})`,
            fromBase: x => `ROUND(${x} / 0.453592, ${PRECISION})`
        }
    },
    power: {
        w: {
            id: 'w',
            // translators: abbreviation of the unit of measurement "watt"
            title: gettext('W'),
            toBase: x => x,
            fromBase: x => x
        },
        kw: {
            id: 'kw',
            // translators: abbreviation of the unit of measurement "kilowatt"
            title: gettext('kW'),
            toBase: x => `ROUND(1000 * ${x}, ${PRECISION})`,
            fromBase: x => `ROUND(${x} / 1000, ${PRECISION})`
        }
    }
};

const COL_TO_UNIT = {
    cableLength: {
        unitType: 'length',
        defaultUnit: UNITS.length.m
    },
    deviceDimensions: {
        unitType: 'length',
        defaultUnit: UNITS.length.mm
    },
    weight: {
        unitType: 'weight',
        defaultUnit: UNITS.weight.kg
    },
    power: {
        unitType: 'power',
        defaultUnit: UNITS.power.w
    }
};

class Unit {
    unit;

    constructor (column, unitStore) {
        this.column = column;
        const savedValue = storage.get(`ccad.units.${column}`);
        this.unit = UNITS[COL_TO_UNIT[column].unitType][savedValue] || COL_TO_UNIT[column].defaultUnit;
        this.default = COL_TO_UNIT[column].defaultUnit;
        this.unitType = COL_TO_UNIT[column].unitType;
        this.store = unitStore;

        makeAutoObservable(this);
    }
}

class UnitsStore {
    static CONVERTIBLE_COLUMNS = {
        cableLength: {
            unitType: 'length',
            defaultUnit: UNITS.length.m
        },
        deviceDimensions: {
            unitType: 'length',
            defaultUnit: UNITS.length.mm
        },
        weight: {
            unitType: 'weight',
            defaultUnit: UNITS.weight.kg
        },
        power: {
            unitType: 'power',
            defaultUnit: UNITS.power.w
        }
    };

    columnUnits = observable.map([], { deep: false });

    constructor () {
        this.columnUnits = new Map();
        Object.keys(COL_TO_UNIT).forEach(col => {
            this.columnUnits.set(col, new Unit(col, this));
        });
        makeAutoObservable(this);
    }

    setUnits = data => {
        Object.entries(data).forEach(([col, value]) => {
            storage.set(`ccad.units.${col}`, value.id);
            runInAction(() => {
                this.columnUnits.set(col, new Unit(col, this));
            });
        });
    };

    get index () {
        const data = {};
        this.columnUnits
            .forEach((value, key) => {
                data[key] = value;
            });
        return data;
    }

    convertColumn = column => {
        const convertableColumn = this.index[column.id];
        const srcUnit = convertableColumn.default;
        const dstUnit = convertableColumn.unit;
        if (column.id === COLUMNS.deviceDimensions.id) {
            const columns = ['Height', 'Width', 'Depth'].map(c =>
                `(${dstUnit.fromBase(srcUnit.toBase(c))})`
            );
            return columns.join(' || " / " || ');
        }
        return dstUnit.fromBase(srcUnit.toBase(column.query));
    };
}

export { UnitsStore, UNITS };
