import React from 'react';

import { DialogContainer } from '@vectorworks/vcs-ui/dist/lib/Dialog';

import Portal from '../Portal';

const MainDialog = (props) =>
    <Portal container='#react-dialog-container'>
        <DialogContainer {...props} />
    </Portal>;

export default MainDialog;
export { DialogContainer };
