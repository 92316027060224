import React from 'react';
import PropTypes from 'prop-types';

const withResponsiveListeners = (WrappedComponent) => {
    return class extends React.Component {
        state = {
            screen: Settings.device.getScreenInfo()
        };

        resizeHandler = () => this.setState({ screen: Settings.device.getScreenInfo() });
        componentDidMount () { window.addEventListener('resize', this.resizeHandler); }
        componentWillUnmount () { window.removeEventListener('resize', this.resizeHandler); }

        render () {
            return (
                <WrappedComponent screen={this.state.screen} {...this.props}/>
            );
        }
    };
};

withResponsiveListeners.propTypes = {
    WrappedComponent: PropTypes.Component
};

export default withResponsiveListeners;
