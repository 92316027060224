import { COLUMNS } from './utils';

class Record {
    constructor (data) {
        Object.assign(this, data);
    }

    get key () {
        return `${this.type}#${this.id}`;
    }

    isMe (sourceItem, cells, extraContidion) {
        const contidionMet = extraContidion
            ? extraContidion(sourceItem, this)
            : true;
        return (sourceItem[cells.src] === this[cells.dst]) && contidionMet;
    }

    getAlign (cell) {
        return this[cell.id] === '---'
            ? 'center'
            : COLUMNS[cell.id].type === 'number'
                ? 'right'
                : 'left';
    }
}

class Device extends Record {
    type = 'devices';
}

class Equipment extends Record {
    type = 'equipment';
}

class Circuit extends Record {
    type = 'circuits';
}

class RecordFactory {
    static map = {
        devices: Device,
        equipment: Equipment,
        circuits: Circuit
    };

    static create (table, data) {
        return new RecordFactory.map[table](data);
    }
}

export default RecordFactory;
