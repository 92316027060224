import { COLUMNS } from './utils';
import isEqual from 'lodash/isEqual';

class Record {
    constructor (data) {
        Object.assign(this, data);
    }

    get key () {
        return `${this.type}#${this.id}`;
    }

    isNavigatedTo (sourceItem, cells, extraCondition) {
        const conditionMet = extraCondition
            ? extraCondition(sourceItem, this)
            : true;
        return (sourceItem[cells.src] === this[cells.dst]) && conditionMet;
    }

    isMe (item) {
        return isEqual(this, item);
    }

    getAlign (cell) {
        return this[cell.id] === '---'
            ? 'center'
            : COLUMNS[cell.id].type === 'number'
                ? 'right'
                : 'left';
    }
}

export default Record;
