import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Accepts default query and expanded values as props
// You can add onInput callback that will trigger on each input change
// You can add onSubmit callback that will trigger on Enter key

class SearchWidget extends Component {
    constructor (props) {
        super(props);
        this.inputRef = React.createRef();
    }

    keyPressListener = e => {
        switch (e.key) {
        case 'Enter':
            this.onSubmit(e);
            break;
        case 'Escape':
            this.onClose(e);
            break;
        }
    };

    componentDidMount () {
        this.props.expanded && this.inputRef.current.focus();
    }

    onInput = e => {
        const query = e.target.value;
        this.props.onInput && this.props.onInput(query);
    };

    onClose = e => {
        this.props.onClose && this.props.onClose();
        this.inputRef.current.value = '';
    };

    onSubmit = e => {
        const query = e.target.value;
        query && this.props.onSubmit && this.props.onSubmit(query);
    };

    render () {
        return (
            <React.Fragment>
                {
                    this.props.expanded &&
                        <div className={ 'search-input__wrap ' + (this.props.cssClass ? this.props.cssClass : '') }>
                            <input
                                className='di-input'
                                type='search'
                                ref={ this.inputRef }
                                placeholder={ this.props.placeholder || gettext('Search...') }
                                defaultValue={ this.props.initialQuery }
                                onChange={ this.onInput }
                                onKeyUp={ this.keyPressListener }
                            />
                            <span className='icon icon16 icon-x-16' onClick={ this.onClose } />
                        </div>
                }
            </React.Fragment>
        );
    }
}

SearchWidget.propTypes = {
    initialQuery: PropTypes.string,
    placeholder: PropTypes.string,
    cssClass: PropTypes.string,
    expanded: PropTypes.bool,
    onInput: PropTypes.func,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func
};

export default SearchWidget;
