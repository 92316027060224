import React from 'react';

import PropTypes from 'prop-types';

import KeyHandler from '../KeyHandler';
import { Dialog } from '@vectorworks/vcs-ui/dist/lib/Dialog';
import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';

// TODO: Rename to deprecated
// TODO: Use container from vcs-ui. Params vs context
// TODO: DIalog sizes
// TODO: cssClass passing

class DialogFrame extends React.Component {
    constructor (props) {
        super(props);
        this.KEY_EVENTS = {
            escape: {
                predicate: event => event.which === 27,
                handler: () => this.props.onCancel()
            },
            ...(props.dontCloseOnEnter
                ? {}
                : {
                    enter: {
                        predicate: event => event.which === 13,
                        handler: () => this.onSubmit()
                    }
                })
        };
    }

    isValid = () => this.props.validationError ? !this.props.validationError : true;
    onSubmit = () =>
        this.isValid()
            ? this.props.useSimpleCallback
                ? this.props.dialog.close()
                : this.props.onSubmit()
            : null;

    onCancel = () =>
        this.props.useSimpleCallback
            ? this.props.dialog.dismiss()
            : this.props.onCancel();

    render () {
        // xs | sm | md | lg, or any valid CSS like 700px
        const { dialogSizeClass, cssClass, submitAnalytics, cancelAnalytics, tests } = this.props;

        return (
            <React.Fragment>
                <KeyHandler keyEvents={this.KEY_EVENTS} extend={this.props.extend}/>
                <Dialog size={dialogSizeClass} className={cssClass} id={this.props.id} {...tests}>
                    {!this.props.hideHeader && (
                        <Dialog.Header
                            title={ this.props.title }
                            onClose={this.onCancel}
                            {...cancelAnalytics}
                        />
                    )}

                    <Dialog.Inner style={{ display: 'flex', flexDirection: 'column' }}>
                        { this.props.children }
                    </Dialog.Inner>
                    {
                        !this.props.hideButtons && (
                            <Dialog.Buttons>
                                <Button
                                    variant='primary'
                                    disabled={!this.isValid()}
                                    onClick={ this.onSubmit }
                                    {...submitAnalytics}
                                    data-what='dialog-btn'
                                    data-which='primary'
                                >{ this.props.submitTitle }
                                </Button>

                                { !this.props.hideCancel && (
                                    <Button
                                        variant='secondary'
                                        onClick={this.onCancel}
                                        data-what='dialog-btn'
                                        data-which='secondary'
                                        {...cancelAnalytics}
                                    >{ this.props.cancelTitle }
                                    </Button>
                                )}
                            </Dialog.Buttons>
                        )
                    }
                </Dialog>
            </React.Fragment>
        );
    }
};

DialogFrame.propTypes = {
    id: PropTypes.string,
    dialogSizeClass: PropTypes.string,
    cssClass: PropTypes.string,
    noInnerPadding: PropTypes.bool,
    title: PropTypes.string,
    submitTitle: PropTypes.string,
    onSubmit: PropTypes.func,
    hideCancel: PropTypes.bool,
    hideButtons: PropTypes.bool,
    cancelTitle: PropTypes.string,
    onCancel: PropTypes.func,
    validationError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    submitAnalytics: PropTypes.any,
    cancelAnalytics: PropTypes.any,
    dontCloseOnEnter: PropTypes.bool,
    dialog: PropTypes.object.isRequired,
    useSimpleCallback: PropTypes.bool,
    hideHeader: PropTypes.bool,
    tests: PropTypes.object,
    extend: PropTypes.bool
};

DialogFrame.defaultProps = {
    submitAnalytics: {},
    cancelAnalytics: {},
    tests: {},
    useSimpleCallback: false
};

export default DialogFrame;
