import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Observer } from 'mobx-react';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';
import { MenuItem, MenuItemIcon } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';
import { Dialog } from '@vectorworks/vcs-ui/dist/lib/Dialog/Dialog';
import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';
import { Collapse, Toggler } from '@vectorworks/vcs-ui/dist/lib/Collapse/Collapse';
// TODO: Make sure you deploy vcs-ui

import { inject } from '../../../Store';

import TitleEditor from './Appearance/TitleEditor';
import Appearance from './Appearance/Appearance';
import Rotate from './Appearance/ShapeWithIcon/Rotate';
import Presets from './Appearance/Presets';
import { TargetType } from '../../../models/board';
import KeyHandler from '../../../../Components/KeyHandler';

export const S = {
    Content: styled.div`
        overflow-y: auto;
        padding-bottom: 15px;
    `,
    Separator: styled.div`
        border-bottom: 1px solid var(--border-color);
    `,
    Section: styled.div`
        padding: 15px;
        padding-top: 0;
    `,
    SectionTitle: styled.div`
        padding: 0 15px;
        color: var(--text-secondary-color);
        text-transform: uppercase;
        font-weight: 500;
        line-height: 50px;
        flex-shrink: 0;
    `,
    Toggler: styled(Toggler)`
        padding: 0 15px;
        flex-shrink: 0;
    `,
    Collapse: styled(Collapse)`
        flex-shrink: 0;
    `,
    SaveStyle: styled(Button)`
        text-transform: none;
        font-size: 13px;
    `,
    Title: styled(TitleEditor)`
        width: 100%;

        > label {
            color: var(--text-primary-color);
        }

        > input {
            /* Inconsistent between Firefox and Chrome */
            width: 100%;
        }
    `
};

export const ConfirmDeletePinDialog = ({ dialog }) => {
    const dismiss = () => dialog.dismiss();
    const confirm = () => {
        return dialog.close(dialog.params.context.deletePin());
    };

    const KEY_EVENTS = {
        enter: {
            predicate: e => e.key === 'Enter',
            handler: () => confirm()
        },
        escape: {
            predicate: e => e.key === 'Escape',
            handler: () => dismiss()
        }
    };

    return (
        <React.Fragment>
            <KeyHandler keyEvents={KEY_EVENTS} />
            <Dialog>
                <Dialog.Header title={gettext('Delete confirmation')} onClose={dismiss} />
                <Dialog.Inner>
                    <Dialog.Block>
                        <div>{gettext('Are you sure you want to delete this pin?')}</div>
                    </Dialog.Block>
                </Dialog.Inner>
                <Dialog.Buttons>
                    <Button variant='primary' onClick={confirm} autoFocus>{gettext('Delete')}</Button>
                </Dialog.Buttons>
            </Dialog>
        </React.Fragment>
    );
};

ConfirmDeletePinDialog.propTypes = {
    dialog: PropTypes.object
};

export const PinEditPanel = inject('dialog', 'ui')(
    ({ ui, dialog, pin, onClose, children }) => {
        const [expandAppearance, setExpandAppearance] = React.useState(true);
        const [expandSavedStyles, setExpandSavedStyles] = React.useState(true);
        const [expandRotate, setExpandRotate] = React.useState(true);

        const deletePin = () => {
            return dialog.open({
                component: ConfirmDeletePinDialog,
                context: {
                    deletePin: () => {
                        onClose();
                        return pin.remove();
                    }
                }
            });
        };

        const handleTitleChange = (title) => {
            // TODO: Fix: this will work event without the validations
            pin.updateTitle(title);
        };

        const handleUpdateProps = (...args) => {
            const request = pin.updateProps(...args);
            ui.defaultPresets.setDefault(pin.props);
            return request;
        };

        const registerUndoUpdate = (...args) => {
            return pin.registerUndoUpdate(...args);
        };

        const handleUpdateRotation = (updates) => {
            return pin.updateRotation(updates);
        };

        let _exposedSaveStyle = () => {};
        const handleSaveStyle = () => {
            return _exposedSaveStyle(pin.props);
        };

        return (
            <Observer>{() => (
                <React.Fragment>
                    <Dialog.Header borderBottom>
                        <IconButton onClick={onClose} data-what='pin-edit-panel' data-which='close'>
                            <Icon icon='hide' />
                        </IconButton>
                        <Dialog.Title>{gettext('Pin properties')}</Dialog.Title>
                    </Dialog.Header>

                    <S.Content>

                        {/* Specific pin type content */}
                        { children }

                        {/* Appearance controls */}
                        <S.Toggler
                            expand={expandAppearance}
                            onClick={() => setExpandAppearance(!expandAppearance)}
                            variant='secondary'
                        >
                            {gettext('Appearance')}
                        </S.Toggler>
                        <S.Collapse in={expandAppearance}>
                            <S.Section>
                                <S.Title title={pin.title} onChange={handleTitleChange} data-what='pin-edit-panel' data-which='title'/>
                                <Appearance
                                    model={pin.props}
                                    onChange={handleUpdateProps}
                                    registerUndoUpdate={registerUndoUpdate}
                                    isTextPin={pin.targetType === TargetType.TEXT}
                                />
                                <S.SaveStyle variant='secondary' onClick={ () => handleSaveStyle() } data-what='pin-edit-panel' data-which='save-style'>
                                    {gettext('Save style')}
                                </S.SaveStyle>
                            </S.Section>
                        </S.Collapse>
                        <S.Separator />

                        {/* Saved styles */}
                        <S.Toggler
                            expand={expandSavedStyles}
                            onClick={() => setExpandSavedStyles(!expandSavedStyles)}
                            variant='secondary'
                        >
                            {gettext('Saved styles')}
                        </S.Toggler>
                        <S.Collapse in={expandSavedStyles}>
                            <S.Section>
                                <Presets
                                    model={pin.props}
                                    onChange={handleUpdateProps}
                                    exposeSave={(save) => { _exposedSaveStyle = save; }}
                                />
                            </S.Section>
                        </S.Collapse>

                        {/* Rotate Pins */}
                        {pin.isTourPin && (
                            <React.Fragment>
                                <S.Separator />
                                <S.Toggler
                                    expand={expandRotate}
                                    onClick={() => setExpandRotate(!expandRotate)}
                                    variant='secondary'
                                >
                                    {gettext('Rotate')}
                                </S.Toggler>
                                <S.Collapse in={expandRotate}>
                                    <S.Section>
                                        <Rotate model={pin.rotation} onChange={handleUpdateRotation} pinId={pin.id.toString()} />
                                    </S.Section>
                                </S.Collapse>
                            </React.Fragment>
                        )}

                        <S.Separator style={{ marginBottom: 8 }} />

                        <MenuItem onClick={deletePin}>
                            <MenuItemIcon>
                                <Icon icon='trash' />
                            </MenuItemIcon>
                            {gettext('Delete pin')}
                        </MenuItem>
                    </S.Content>

                </React.Fragment>
            )}
            </Observer>
        );
    }
);

// Like DraggablePin2D's props
PinEditPanel.propTypes = {
    ui: PropTypes.object,
    dialog: PropTypes.object,
    onClose: PropTypes.func,
    pin: PropTypes.object
};
