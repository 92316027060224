import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { PreviewSimpleText } from '../../common/SimpleText';
import { PreviewViewer as Viewer } from '../../FileViewers';
import Frame from './Frame';

const Intro = props =>
    <React.Fragment>
        <Frame slide={props.slide}>
            <PreviewSimpleText
                board={props.slide}
                text={props.slide.text}
                screen={props.preview}
                previewContainer={props.previewContainer}
            />
            <div className='ib-file-container'>
                <div style={{ maxWidth: '100%', width: '100%', height: '100%', position: 'relative' }}>
                    <Viewer
                        file={props.slide.media}
                        pins={props.slide.pins}
                        screen={props.preview}
                        previewContainer={props.previewContainer}
                        useSimple={true}
                    />
                </div>
            </div>
        </Frame>
    </React.Fragment>;

Intro.propTypes = {
    slide: PropTypes.object.isRequired,
    preview: PropTypes.object.isRequired,
    previewContainer: PropTypes.object
};

export default observer(Intro);
