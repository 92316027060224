import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';

import S from '../styles';
import ViewerContext from '../../ViewerContext';

const RemoveButton = ({ close, remove }) => {
    const { isMobile } = React.useContext(ViewerContext);
    const onKeyDown = e => {
        e.stopPropagation();
        if (e.key === 'Escape') {
            close();
        }
        if (e.key === 'Enter') {
            remove();
        }
    };

    return (
        <S.RemoveContainer>
            {
                isMobile
                    ? <Icon
                        data-what='ccad-column-filter'
                        data-which='remove-button-mobile'
                        icon='trash'
                        onClick={remove}
                    />
                    : <Button
                        data-what='ccad-column-filter'
                        data-which='remove-button-desktop'
                        onClick={remove}
                        variant='secondary'
                        tabIndex={0}
                        onKeyDown={onKeyDown}
                    >
                        { gettext('Remove') }
                    </Button>
            }
        </S.RemoveContainer>
    );
};

RemoveButton.propTypes = {
    close: PropTypes.func,
    remove: PropTypes.func
};

export default RemoveButton;
