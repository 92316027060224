import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import loadAframe from '~static/file-viewer/panorama/aframe-loader';
import { withLoadDependency } from '../../common/loaderHOCs';
import LoadingSpinner from '../../common/LoadingSpinner';
import { ImageResource } from '../loader';
import PanoramaScene from '../../../Components/Panorama/PanoramaScene';
import SceneStore from './SceneStore';
import { makeRef } from './utils';
import { scene as sceneTemplate } from './templates';

class CameraRotationReader extends React.Component {
    name = 'CameraRotationReader';

    constructor (props) {
        super(props);
        this.rotation = null;
        this.sceneRef = makeRef();
        const place = this.props.place;
        const initialCameraRotation =
            props.initialCameraRotation || { x: 0, y: 0, z: 0 };

        this.sceneStore = new SceneStore({
            place,
            placeProps: {
                cameraRotation: initialCameraRotation
            },
            image: new ImageResource(place.asset.fileVersion.download_url)
        });
    }

    sceneEventHandlers = {
        'camera-rotate': this.props.onCameraRotate
    };

    componentDidMount () {
        this.sceneStore.screen.image.load();
    }

    render () {
        return (
            <div
                style={{ minHeight: '500px' }}
            >
                { this.props.children }
                <LoadingSpinner resource={this.sceneStore.image}/>
                <PanoramaScene
                    sceneRef={this.sceneRef}
                    eventHandlers={this.sceneEventHandlers}
                    sceneStore={this.sceneStore}
                    sceneTemplate={sceneTemplate}
                />
            </div>
        );
    }
}

CameraRotationReader.propTypes = {
    place: PropTypes.object,
    onCameraRotate: PropTypes.func,
    initialCameraRotation: PropTypes.object
};

export default withLoadDependency(loadAframe)(observer(CameraRotationReader));
