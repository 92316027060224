import React from 'react';

import { action } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { toggleClass, hasClass } from '../../../../lib/dom-utils';
import fullScreen from '../../../../base/fullscreen';

const FullScreen = props => {
    const togglePortalFVFullscreen = action(() => {
        fullScreen.toggle($('.viewer-wrapper').get(0));
        props.store.ui.fullScreen = !props.store.ui.fullScreen;
    });

    const toggleBoardsFVFullscreen = action(() => {
        const isFullscreen = props.store.root.isFullscreen;
        if (isFullscreen) {
            const fileView = document.querySelector('.file-view');
            toggleClass(fileView, 'fake-fs');
            props.store.ui.fullScreen = hasClass(fileView, 'fake-fs');
        } else {
            fullScreen.toggle($('.viewer-wrapper').get(0));
            props.store.ui.fullScreen = !props.store.ui.fullScreen;
        }
    });

    const toggleFullscreen = () =>
        props.store.isPortalFV
            ? togglePortalFVFullscreen()
            : toggleBoardsFVFullscreen();

    const tooltipText = props.store.ui.fullScreen ? 'Exit full screen' : 'Enter full screen';

    return (
        <span
            className={`ctrl-tool icon ${props.store.ui.fullScreen ? 'icon-exit-full-screen' : 'icon-full-screen'}`}
            onClick={toggleFullscreen}
            title={gettext(tooltipText)}
            ga-action='File_View_Controller'
            ga-label='Click_Toggle_Fullscreen'
            data-what='controller-tool'
            data-which='toggle-fullscreen'
        />
    );
};

FullScreen.propTypes = {
    store: PropTypes.object
};

export default observer(FullScreen);
