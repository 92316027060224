import { computed, observable, action, makeObservable, override } from 'mobx';
import CommentsStore from './store';
import { fileResourceId } from '../../file';

export class SharedSets {
    static ERRORS = {
        FILE_NOT_FOUND: 'FILE_NOT_FOUND'
    };

    selected;
    error;
    sets = [];

    constructor (root) {
        this.root = root;
        makeObservable(this, {
            selected: observable,
            error: observable,
            setError: action,
            setSelected: action,
            loadSets: action,
            sets: observable,
            setSets: action
        });
    }

    setError = value => {
        this.error = value;
    };

    setSelected = (s) => {
        if (this.selected?.uuid !== s?.uuid) {
            this.selected = s;
            if (s) {
                const resourceId = fileResourceId.fromLink(s.uuid, '');
                this.root.file.open(resourceId)
                    .then(() => {
                        if (!this.root.file.file.exists) {
                            this.setError(SharedSets.ERRORS.FILE_NOT_FOUND);
                            return;
                        }

                        this.root.file.commentsStore.load();
                        this.setError(null);
                    });
            }
        }
    };

    setSets = (s) => {
        this.sets = s;
        if (s?.length > 0) {
            this.setSelected(s[0]);
        }
    };

    loadSets = async () => {
        return Promise.resolve(window.vwAPI.GetDocumentData()).then(({ sets }) =>
            this.setSets(sets)
        );
    };
}
class VCDOCCommentsStore extends CommentsStore {
    tempCommentGeometry;
    visiblePages = [];
    pages = [];

    // Togglers
    showAnnotations = true;
    showAllComments = true;

    constructor (root) {
        super(root);
        this.pages = [];
        this.isWebview =
            window.location.pathname.startsWith('/comments/webview');
        this.showAnnotations = !this.isWebview;

        makeObservable(this, {
            setFocusedComment: override,
            pageCommentsMap: computed,

            visiblePages: observable,
            setVisiblePages: action,
            visiblePageNumber: computed,

            pages: observable,
            setPages: action,
            setPagesFromComments: action,

            tempCommentGeometry: observable,
            setTempCommentGeometry: action,
            commentGeometries: computed,

            showAnnotations: observable,
            setShowAnnotations: action,

            showAllComments: observable,
            setShowAllComments: action,
            toPage: action,

            onCommentsLoad: action,

            currentVersionComments: computed
        });
    }

    get currentVersionComments () {
        /* This method intentionally uses this.comments and not this.filteredComments.
        It's used to hide the comments header in VCDOC comments panel if there are no comments
        for this version. If it uses filteredComments, if all the comments are resolved and you
        uncheck the resolved filter, filteredComments will become [] and the header will disappear and you
        won't be able to show the resolved comments anymore.
        */
        return this.comments.filter(c => this.filters.latestVersionOnly
            ? c.resource.object.version_id === this.root.currentVersion.versionId
            : true);
    }

    get visiblePageNumber () {
        if (this.visiblePages.length > 0) {
            return this.visiblePages[0].pageNumber;
        }
    }

    get commentGeometries () {
        return this.showAnnotations
            ? this.filteredComments
                .filter(
                    (c) =>
                        c.metadata.vcdoc.pageNumber ===
                              this.visiblePageNumber &&
                          c.metadata?.vcdoc?.geometry
                )
                .map((c) => {
                    c.metadata.vcdoc.geometry.id = c.id;
                    return c.metadata.vcdoc.geometry;
                })
                .concat(this.tempCommentGeometry)
                .filter((g) => !!g)
            : [];
    }

    get pageCommentsMap () {
        return new Map(
            Object.values(this.pages).map(({ pageNumber }) => [
                pageNumber,
                this.filteredComments.filter(
                    (c) => c.metadata.vcdoc.pageNumber === pageNumber
                )
            ])
        );
    }

    toPage = (pageNumber) => {
        if (this.visiblePageNumber !== pageNumber) {
            if (this.isWebview) {
                // TODO: Webview page navigation hander?
                this.setVisiblePages([this.pages.find(p => p.pageNumber === pageNumber)]);
            } else {
                this.vcsPDFViewer.store.toPage(pageNumber);
            }
        }
    };

    setVisiblePages = (v) => {
        this.visiblePages = v;
        this.tempCommentGeometry = null;
    };

    setPages = (pages) => {
        this.pages = pages;
    };

    setTempCommentGeometry = (g) => {
        this.tempCommentGeometry = g;
    };

    setShowAnnotations = (bool) => {
        this.showAnnotations = bool;
    };

    setShowAllComments = (bool) => {
        this.showAllComments = bool;
    };

    setFocusedComment (c) {
        this.focusedComment = c;
        if (this.isWebview && c) {
            window.vwAPI.HighlightArea?.(c.metadata.vcdoc.geometry.geometry);
        }
    }

    setPagesFromComments () {
        const result = {};

        this.comments.forEach(c => {
            const page = c.metadata.vcdoc.geometry.geometry.page;
            if (!(page.pageNumber in result)) {
                result[page.pageNumber] = page;
            }
        });

        const pages = Object.values(result);
        if (pages.length > 0) {
            this.setPages(pages);
            this.setVisiblePages([pages[0]]);
        }
    }

    onCommentsLoad () {
        if (this.isWebview) {
            this.setPagesFromComments();
        }
    }
}

export default VCDOCCommentsStore;
