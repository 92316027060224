import CBuffer from 'CBuffer';

const PI_2 = Math.PI / 2;

export default class VelocityTracker {
    constructor (pitchObject, yawObject) {
        this.pitchObject = pitchObject;
        this.yawObject = yawObject;
        this.lastPitch = pitchObject.rotation.x;
        this.lastYaw = yawObject.rotation.y;
        this.pitchRotations = new CBuffer(5);
        this.yawRotations = new CBuffer(5);
        this.reset();
    }

    reset () {
        this.velocity = null;
        this.pitchRotations.fill(0);
        this.yawRotations.fill(0);
    }

    track () {
        this.pitchRotations.unshift(this.pitchObject.rotation.x - this.lastPitch);
        this.yawRotations.unshift(this.yawObject.rotation.y - this.lastYaw);
    }

    sync () {
        this.lastPitch = this.pitchObject.rotation.x;
        this.lastYaw = this.yawObject.rotation.y;
    }

    startInertiaMovement () {
        const pitchObject = this.pitchObject;
        const yawObject = this.yawObject;
        this.velocity = {
            pitch: this.pitchRotations.avg() * 0.7,
            yaw: this.yawRotations.avg() * 0.7,
            counter: 0,
            apply (direction) {
                if (this.counter % 1 === 0) {
                    this.slowDown();
                    if (this.pitch !== 0 || this.yaw !== 0) {
                        yawObject.rotation.y += this.yaw * direction;
                        pitchObject.rotation.x += this.pitch * direction;
                        pitchObject.rotation.x = Math.max(-PI_2, Math.min(PI_2, pitchObject.rotation.x));
                    }
                }
                this.counter += 1;
            },
            slowDown () {
                this.pitch *= 0.9;
                if (Math.abs(this.pitch) < 0.0005) {
                    this.pitch = 0;
                }
                this.yaw *= 0.9;
                if (Math.abs(this.yaw) < 0.0005) {
                    this.yaw = 0;
                }
            }
        };
    }

    applyVelocity (direction) {
        this.velocity && this.velocity.apply(direction);
    }
};
