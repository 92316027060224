import React from 'react';
import PropTypes from 'prop-types';

import urlRegexSafe from 'url-regex-safe';

import { InputLabelField, ValidatedInput } from '@vectorworks/vcs-ui/dist/lib/Input/Text/Input';
import { Checkbox, CheckboxState } from '@vectorworks/vcs-ui/dist/lib/Checkbox/Checkbox';

import { LinkState } from '../../../../models/link';
import { Wrapper, TextLinkContents, NewTabCheckbox } from './styles';

const LinkCreator = ({
    linkState,
    onChange,
    ...rest
}) => {
    const getError = () => {
        return linkState.link.match(urlRegexSafe()) ? '' : gettext('Enter a valid web address');
    };

    const handleInputChange = (link) => {
        onChange({
            error: getError(),
            linkState: linkState.updated({ link })
        });
    };

    const toggleInNewTab = () => {
        onChange({
            error: getError(),
            linkState: linkState.updated({ inNewTab: !linkState.inNewTab })
        });
    };

    return (
        <Wrapper {...rest}>
            <TextLinkContents>
                <ValidatedInput
                    id='link-creator__url'
                    errorText={getError()}
                    value={linkState.link}
                    onChange={e => handleInputChange(e.target.value)}
                    data-what='pin-edit-panel' data-which='link'
                />
            </TextLinkContents>
            <NewTabCheckbox>
                <Checkbox
                    onClick={toggleInNewTab}
                    state={linkState.inNewTab ? CheckboxState.ON : CheckboxState.OFF}
                    aria-labelledby='link-creator__new-tab'
                    data-what='pin-edit-panel' data-which='link-new-tab'
                />
                <InputLabelField
                    id='link-creator__new-tab'
                    labelText={gettext('Open in new tab')}
                    onClick={toggleInNewTab}
                />
            </NewTabCheckbox>
        </Wrapper>
    );
};

LinkCreator.propTypes = {
    linkState: PropTypes.instanceOf(LinkState),
    onChange: PropTypes.func
};

LinkCreator.defaultProps = {
    linkState: LinkState.createDefault()
};

export { LinkCreator };
