class SaveCommand {
    constructor (command, context, args) {
        this.command = command;
        this.context = context;
        this.args = args;
        this.result = new Promise((resolve, reject) => {
            this._deffered = { resolve, reject };
        });
    }

    execute () {
        return this.command.command
            .apply(this.context, this.args)
            .then(
                result => {
                    this._deffered.resolve(result);
                    return result;
                },
                error => {
                    this._deffered.reject(error);
                    return error;
                }
            );
    }

    skip () {
        this._deffered.reject(null);
    }
}

const saveMixin = {
    scheduleSave (command, ...args) {
        const saveCommand = new SaveCommand(command, this, args);
        this.root.saveQueue.push(saveCommand);
        return saveCommand.result;
    }
};

const saveMixinNoRoot = {
    scheduleSave (command, ...args) {
        this.saveQueue.push(
            new SaveCommand(command, this, args)
        );
    }
};

export {
    saveMixin,
    saveMixinNoRoot,
    SaveCommand
};
