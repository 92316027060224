import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    usePopupState,
    bindTrigger,
    bindMenu
} from 'material-ui-popup-state/hooks';
import { EditorState } from 'draft-js';

import styled from 'styled-components';

import { ValidatedInput } from '@vectorworks/vcs-ui/dist/lib/Input/Text/Input';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { Menu } from '@vectorworks/vcs-ui/dist/lib/Menu/Menu';

import { LinkCreator } from '../LinkCreator/LinkCreator';
import { ChooseSlide } from '../LinkCreator/ChooseSlide';
import { getTextFromSelection } from '../../../../Editor/Board/TextEditor/editor-funcs';
import { LinkState } from '../../../../models/link';
import { S as PinStyles } from '../../../../Pins/PinUI';

const S = {
    ...PinStyles,
    Content: styled.div`
        width: 400px;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
    `
};

function LinkPopup ({ editorState, onSubmit }) {
    const popupState = usePopupState({ variant: 'popper', popupId: 'text-editor__link' });
    const [title, setTitle] = useState('');
    const [linkState, setLinkState] = useState(LinkState.createDefault());

    const setTextFromSelection = () => {
        const selectedText = getTextFromSelection(editorState);
        setTitle(selectedText || 'click here');
    };

    const handleLinkStateUpdate = ({ linkState }) => {
        setLinkState(linkState.updated(linkState));
    };

    const submit = () => onSubmit({ title, linkState });

    const createTriggerProps = () => {
        const initial = bindTrigger(popupState);
        const onClick = (e) => {
            setTextFromSelection();
            initial.onClick(e);
        };
        return {
            ...initial,
            onClick
        };
    };

    const createMenuProps = () => {
        const initial = bindMenu(popupState);
        const onClose = (e) => {
            submit();
            initial.onClose(e);
        };
        return {
            ...initial,
            onClose
        };
    };

    const handleSlideChange = ({ slidePreviewUrl }) => {
        handleLinkStateUpdate({
            linkState: linkState.updated({
                link: slidePreviewUrl,
                inNewTab: false
            })
        });
    };

    return (
        <React.Fragment>
            <IconButton
                aria-label='text-pin__editor__link__button'
                title={gettext('Link')}
                {...createTriggerProps()}
            >
                <Icon icon='insert-link' />
            </IconButton>
            <Menu {...createMenuProps()}>
                <S.Content>
                    <ValidatedInput
                        id='text-pin__editor__link__display'
                        labelText={gettext('Text to display')}
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                    <LinkCreator linkState={linkState} onChange={handleLinkStateUpdate} />
                    <ChooseSlide onChange={handleSlideChange} style={{ marginTop: 20 }} />

                    {/* For testing purposes only */}
                    <button
                        data-testid='text-pin__editor__link__done'
                        onClick={submit}
                        style={{ display: 'none' }}
                    />
                </S.Content>
            </Menu>
        </React.Fragment>
    );
}

LinkPopup.propTypes = {
    editorState: PropTypes.instanceOf(EditorState),
    onSubmit: PropTypes.func
};

export default LinkPopup;
