import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';

import { FilePath, FileTypes } from '../../../lib/file';
import Toolbar from './Toolbar';
import CollectionStore from '../../Stores/Collection';
import Collection from '../../common/Collection';
import ThumbsLoader from '../../common/ThumbsLoader';
import { ListItemSpinner } from '../../../Components/Spinner';
import UploadOverlay from '../../common/UploadOverlay';
import actions from './actions';
import { addAssetsToPresentation } from './upload';

const Asset = observer((props) => {
    const { id, filename, fileVersion } = props.data;

    const getIcon = () => {
        return FileTypes.get(props.data.filename, false, null).defaultFileTypeIcon();
    };

    const getBonusStyles = () => {
        const styles = [];
        if (props.isSelected) styles.push('item-selected-light list-selected-light');
        return styles.join(' ');
    };

    const readyAndInvalid = () => {
        return props.data.state === 'ready' && !props.isValid;
    };

    return (
        <div
            className={'asset library-ls-item library-asset-ls-item ' + getBonusStyles() }
            data-what='library-asset'
            data-which={`${id}:${filename}`}
            {...props.clickEvents(props.data)}
        >
            <div className='attr attr-primary name'>
                <div className='name-wrapper' title={ filename }>
                    <div className='base-name'><span>
                        {
                            (props.data.state === 'error' || readyAndInvalid()) &&
                                <span className='name-invalid icon icon-status-warning-16' />
                        }
                        <span>{ FilePath(filename).baseName() }</span>
                    </span>
                    </div>
                    <div className='extension'>{ FilePath(filename).extension() }</div>
                </div>
                {
                    readyAndInvalid() &&
                    <div className='extra-row'>{gettext("Not a 360° panoramic image; it can't be added to a tour.") }</div>
                }
                { props.data.isUploading && <ListItemSpinner message={gettext('Uploading...')} /> }
            </div>

            <ThumbsLoader thumbnail={fileVersion && fileVersion.thumbnail} iconHTML={getIcon()} />
        </div>
    );
});

Asset.propTypes = {
    isSelected: PropTypes.bool,
    isValid: PropTypes.bool,
    data: PropTypes.object,
    collectionStore: PropTypes.object,
    clickEvents: PropTypes.func
};

class AssetLibrary extends React.Component {
    constructor (props) {
        super(props);
        this.collectionStore = props.collectionStore || new CollectionStore(
            props.store.asset.assets,
            actions,
            {
                assetStore: props.store.asset,
                store: props.store
            }
        );
    }

    componentDidMount () {
        runInAction(() => {
            if (this.props.filterFunction) {
                this.collectionStore.filters.set('fileType', this.props.filterFunction);
            }
            if (this.props.validationFunction) {
                this.collectionStore.validators.set('fileTypeAllowed', this.props.validationFunction);
            }
        });
    }

    componentWillUnmount () {
        this.collectionStore.cleanUp();
    }

    getEmptyMessage = () => {
        const filenameFilter = this.collectionStore.filters.get('filename');
        const noSearchResults = (
            !!filenameFilter &&
            !this.collectionStore.items.filter(i => filenameFilter(i)).length
        );
        return (
            noSearchResults
                ? {
                    icon: 'search',
                    primText: gettext('No results found'),
                    secondaryText: gettext('Close search and add files from the library toolbar.')
                }
                : {
                    icon: 'file',
                    secondaryText: gettext('Drop files from your computer or add files from your cloud storage to make them available for adding to your slides later. You can also add files directly to the Slide resources panel as you are building your slides.')
                }
        );
    };

    onDrop = files => {
        addAssetsToPresentation(this.props.store, files, true);
    };

    render () {
        return (
            <React.Fragment>
                <div className={`file-library ${this.props.cssClass ? this.props.cssClass : ''}`}>
                    <Toolbar
                        id={this.props.id}
                        store={this.props.store}
                        collectionStore={ this.collectionStore }
                        uploadValidation={ this.props.uploadValidation }
                        filterFunction={ this.props.filterFunction }
                        selectDialogProps={this.props.selectDialogProps}
                        target={this.collectionStore.selection}
                        closePanel={this.props.closePanel}
                    />

                    <Dropzone onDrop={this.onDrop} noClick>
                        { ({ getRootProps, getInputProps, isDragActive }) =>
                            <div
                                id='assets-wrapper'
                                onClick={this.collectionStore.onClickContainer}
                                onTouchEnd={this.collectionStore.onClickContainer}
                                {...getRootProps()}
                            >
                                <div className='container-fluid items-container'>
                                    <Collection
                                        collectionStore={this.collectionStore}
                                        renderItem={props => <Asset {...props} />}
                                        emptyMessage={ this.getEmptyMessage() }
                                    />
                                </div>

                                {
                                    isDragActive &&
                                    <UploadOverlay
                                        title={gettext('Drop files here to add them to this library')}
                                    />
                                }
                            </div>}
                    </Dropzone>
                </div>
            </React.Fragment>
        );
    }
}

AssetLibrary.propTypes = {
    id: PropTypes.any,
    store: PropTypes.object,
    collectionStore: PropTypes.object,
    cssClass: PropTypes.string,
    uploadValidation: PropTypes.func,
    validationFunction: PropTypes.func,
    filterFunction: PropTypes.func,
    selectDialogProps: PropTypes.object,
    closePanel: PropTypes.bool
};

export default observer(AssetLibrary);
