import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { requestDeepLink } from './api';
import { toast } from '../toast';
import { utils } from '../lib';
import { buildAction } from '../base/action';
import CollapseToTarget from '../Components/CollapseToTarget';
import { isLinkView } from '../preview/link';
import { FileTypes } from '../lib/file';

const { waitForNode, navigateToURL } = utils;

const ShowInNomad = function ({ node, link }) {
    const [closed, setClosed] = useState(false);

    const close = () => {
        setClosed(true);
    };

    return !closed
        ? <CollapseToTarget
            targetNode={node}
            delay={5000}
            content={({
                cssClass,
                callbackRef,
                style
            }) => <div className='notifyjs-corner notifyjs-corner--lower'>
                <div
                    className={`simple-notification ${cssClass}`}
                    ref={callbackRef}
                    style={style}
                >
                    <span className='icon icon32 icon-home' />
                    <a href={link} className='u'>{gettext('SHOW IN APP')}</a>
                    <span onClick={close} className='icon icon32 icon-close' />
                </div>
            </div>}
        />
        : null;
};

ShowInNomad.propTypes = {
    node: PropTypes.object.isRequired,
    link: PropTypes.string.isRequired
};

const getDeepLink = function () {
    const promise = new Promise(function (resolve, reject) {
        const deepLink = window.sessionStorage.getItem(`deepLink:${window.location.href}`);
        if (deepLink) {
            resolve(deepLink.split(':')[1]);
        } else {
            requestDeepLink()
                .then(({ shortLink }) => {
                    if (shortLink) {
                        window.sessionStorage.setItem(
                            `deepLink:${window.location.href}`,
                            shortLink
                        );

                        resolve(shortLink);
                    } else {
                        reject();
                    }
                })
                .catch(error => reject(error));
        }
    });

    return promise;
};

const renderAnimation = async function (
    shortLink,
    animationTargetNode
) {
    const targetNode = await waitForNode(animationTargetNode);
    const rootNode = await waitForNode(() => document.getElementById('show-in-nomad'));

    ReactDOM.render(
        <ShowInNomad node={ targetNode } link={shortLink} />,
        rootNode
    );
};

// Should be visible in fileview only and not on selection.
// Added as an extra action to the fileview.
const getAnimationTargetNode = function (isContextAction) {
    if (isContextAction) {
        return () => document.querySelector('[data-test-id="ctx_toolbar__show-in-nomad"]');
    } else {
        const screenInfo = Settings.device.getScreenInfo();

        const getGlobalDropdownTrigger = () => document.querySelector('global-action-toolbar > .dropdown-toggle');
        const getActionInGlobalToolbar = () => document.querySelector('[data-test-id="gl_toolbar__show-in-nomad"]');

        return screenInfo.size === 'xs'
            ? getGlobalDropdownTrigger
            : getActionInGlobalToolbar;
    }
};

const isAllowed = function () {
    return Settings.device.isMobile &&
        isLinkView();
};

function handleRequestLinkError () {
    toast('ERROR_OCCURED');
}

const createAction = (isContextAction, fileType) => {
    const animationTargetNode = getAnimationTargetNode(isContextAction);

    return buildAction({
        action: async function () {
            return getDeepLink()
                .then(shortLink => navigateToURL(shortLink))
                .catch(handleRequestLinkError);
        },
        properties: {
            multiple: false,
            title: gettext('Show in Nomad'),
            icon: 'icon-home action__show-in-nomad',
            GALabel: 'Click_ShowInNomad',
            isAllowed: () => isAllowed() && fileType !== FileTypes.vgx,
            autoExecute: async function () {
                return getDeepLink()
                    .then(shortLink => renderAnimation(shortLink, animationTargetNode))
                    .catch(e => console.log('Nomad deeplink generation failed'));
            }
        }
    }, 'showInNomad', 'asset');
};

const showInNomadAction = (fileType = null) => createAction(true, fileType);
const showNomadGlobalAction = (fileType = null) => createAction(false, fileType);

export default ShowInNomad;
export {
    requestDeepLink,
    showInNomadAction,
    showNomadGlobalAction
};
