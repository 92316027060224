import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Input, InputLabelField } from '@vectorworks/vcs-ui/dist/lib/Input/Text/Input';
import { Slider } from '@vectorworks/vcs-ui/dist/lib/Slider/Slider';
import { Checkbox, CheckboxState } from '@vectorworks/vcs-ui/dist/lib/Checkbox/Checkbox';
import { textOverflow } from '@vectorworks/vcs-ui/dist/lib/styles/common';

const S = {
    Wrapper: styled.div`
        display: flex;
        flex-direction: column;
    `,
    Row: styled.div`
        .MuiSlider-root {
            margin: 0;
        }

        position: relative;
        display: flex;
        align-items: center;
        min-height: 30px;
        margin-bottom: 10px;

        > label {
            margin-top: 5px;
            width: 70px;
            flex-shrink: 0;
            ${textOverflow}
        }

        > div {
            flex: 1;
        }
    `,
    CheckboxForm: styled.div`
        display: flex;
        align-items: center;

        > label {
            margin-bottom: 0;
            margin-left: 7px;
            cursor: pointer;
        }
    `,
    Slider: styled(Slider)`
        flex: 1;
    `,
    Input: styled(Input)`
        width: 65px;
        margin-left: 16px;
    `,
    InputLabelField: styled(InputLabelField)`
        color: var(--text-primary-color);
    `
};

const Editor = ({ model, onChange, pinId, ...rest }) => {
    const handleLookAtCamera = e => {
        const lookAtCamera = document.getElementById(`bind-for-pins-${pinId}`).getAttribute('rotation');
        const { x, y, z } = lookAtCamera;

        onChange({
            lookAtCamera: !model.lookAtCamera,
            pitch: Math.round(x),
            yaw: Math.round(y),
            roll: Math.round(z)
        });
    };

    return (
        <S.Wrapper {...rest}>
            <S.Row>
                <S.CheckboxForm>
                    <Checkbox
                        aria-labelledby='pin-editor__look-at'
                        state={model.lookAtCamera ? CheckboxState.ON : CheckboxState.OFF}
                        onClick={handleLookAtCamera}
                    />
                    <S.InputLabelField
                        id='pin-editor__look-at'
                        labelText={gettext('Look at camera')}
                        htmlFor='pin-editor__look-at'
                        onClick={handleLookAtCamera}
                    />
                </S.CheckboxForm>
            </S.Row>

            <S.Row>
                <S.InputLabelField
                    labelText={gettext('Pitch')}
                    title={gettext('Pitch')}
                    htmlFor='pin-editor__pitch'
                />
                <S.Slider
                    disabled={ model.lookAtCamera }
                    id='pin-editor__pitch'
                    value={model.pitch}
                    onChange={e => onChange({ pitch: e.target.value })}
                    min={-180}
                    max={180}
                />
                <S.Input
                    type='number'
                    disabled={ model.lookAtCamera }
                    value={model.pitch}
                    onChange={e => onChange({ pitch: e.target.value })}
                    min={-180}
                    max={180}
                />
            </S.Row>

            <S.Row>
                <S.InputLabelField
                    labelText={gettext('Yaw')}
                    title={gettext('Yaw')}
                    htmlFor='pin-editor__yaw'
                />
                <S.Slider
                    disabled={ model.lookAtCamera }
                    id='pin-editor__yaw'
                    value={model.yaw}
                    onChange={e => onChange({ yaw: e.target.value })}
                    min={-180}
                    max={180}
                />
                <S.Input
                    type='number'
                    disabled={ model.lookAtCamera }
                    value={model.yaw}
                    onChange={e => onChange({ yaw: e.target.value })}
                    min={-180}
                    max={180}
                />
            </S.Row>

            {/* TODO: This option could be available in board pins */}
            <S.Row>
                <S.InputLabelField
                    labelText={gettext('Roll')}
                    title={gettext('Roll')}
                    htmlFor='pin-editor__roll'
                />
                <S.Slider
                    disabled={ model.lookAtCamera }
                    id='pin-editor__tiltX'
                    value={model.roll}
                    onChange={e => onChange({ roll: e.target.value })}
                    min={-180}
                    max={180}
                />
                <S.Input
                    type='number'
                    disabled={ model.lookAtCamera }
                    value={model.roll}
                    onChange={e => onChange({ roll: e.target.value })}
                    min={-180}
                    max={180}
                />
            </S.Row>
        </S.Wrapper>
    );
};

Editor.propTypes = {
    model: PropTypes.object,
    onChange: PropTypes.func,
    pinId: PropTypes.string
};

export default Editor;
