import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
    bindMenu,
    bindTrigger,
    usePopupState
} from 'material-ui-popup-state/hooks';

import { SingleColorBox } from '@vectorworks/vcs-ui/dist/lib/ColorPicker/ColorBox';
import { RGBColor } from '@vectorworks/vcs-ui/dist/lib/ColorPicker/utils';
import { Menu } from '@vectorworks/vcs-ui/dist/lib/Menu/Menu';
import { disabled as disabledCSS } from '@vectorworks/vcs-ui/dist/lib/styles/common';

import ColorPicker from '../../../../common/ColorPicker/ColorPicker';
import { Chess } from './Chess';

const S = {
    ColorEditBox: styled.div`
        ${props => props.disabled ? disabledCSS : ''}
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    `,
    SingleColorBox: styled(SingleColorBox).attrs(props => {
        const opacity = props.color.a;
        const solid = RGBColor.fromObject({ ...props.color });
        solid.a = 1;

        return {
            style: {
                backgroundColor: solid.toCSS(),
                opacity
            }
        };
    })`
        position: absolute;
        margin: auto;

        width: 20px;
        height: 20px;
        border-radius: 2px;
        outline: none;
        box-shadow: inset 0px 0px 0px 1px rgb(0 0 0 / 5%);
    `
};

export const ColorEditBox = ({ onChange, color, popupId, disabled = false, ...rest }) => {
    const colorPopup = usePopupState({ variant: 'popper', popupId });

    return (
        <React.Fragment>
            <S.ColorEditBox {...bindTrigger(colorPopup)} disabled={disabled} {...rest}>
                <Chess size={7} />
                <S.SingleColorBox color={color} onClick={() => { }} />
            </S.ColorEditBox>

            <Menu {...bindMenu(colorPopup)}>
                <ColorPicker
                    selectedColor={color}
                    onChange={onChange}
                />
            </Menu>
        </React.Fragment>
    );
};
ColorEditBox.propTypes = {
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    color: PropTypes.instanceOf(RGBColor),
    popupId: PropTypes.string
};
