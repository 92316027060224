import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';

import { MenuItem } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';
import { Select } from '@vectorworks/vcs-ui/dist/lib/Select/Select';
import { TextOverflow } from '@vectorworks/vcs-ui/dist/lib/styles/common';

import { inject } from '../../../../Store';
import SlideStore from '../../../../Stores/Slide';
import { SlideSelectItem } from './styles';

export const ChooseSlide = inject('slide')(
    ({
        slide: slideStore,
        onChange,
        ...rest
    }) => {
        const handleOptionClick = (slide) => {
            onChange({
                slide,
                slidePreviewUrl: `${window.location.origin}/portal/presentation/preview/${slideStore.root.id}/${slide.id}/`
            });
        };

        return (
            <Observer>{() => (
                <Select value='lock' {...rest}>
                    <MenuItem value='lock' disabled style={{ display: 'none' }}>
                        {gettext('Generate from slide (optional)')}
                    </MenuItem>
                    {slideStore.slides.map(
                        (s, index) => (
                            <MenuItem key={s.id} value={s.id} onClick={() => handleOptionClick(s)}>
                                <SlideSelectItem>
                                    <TextOverflow style={{ flex: 1 }}>{s.title}</TextOverflow>
                                    <div style={{ margin: '0 10px' }}>{`(${index + 1}/${slideStore.slides.length})`}</div>
                                </SlideSelectItem>
                            </MenuItem>
                        )
                    )}
                </Select>
            )}
            </Observer>
        );
    }
);
ChooseSlide.propTypes = {
    initialSlide: PropTypes.shape({
        title: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        slide: PropTypes.instanceOf(SlideStore)
    })
};
