import React from 'react';
import PropTypes from 'prop-types';

import { Observer } from 'mobx-react';

export function FileTypeCanvas ({ pins }) {
    return (
        <Observer>
            {() => (
                <div style={{ display: 'none' }}>
                    { pins.map(({ id }) => <canvas key={id} id={`pinIconCanvas--${id}`} />) }
                </div>
            )}
        </Observer>
    );
}

FileTypeCanvas.propTypes = {
    pins: PropTypes.arrayOf(PropTypes.object)
};
