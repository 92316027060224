import React from 'react';

import Record from './record';
import S from './styles';

const FILTERS = {
    CONTAINS: {
        name: 'CONTAINS',
        title: gettext('Contains'),
        conditionExact: (c, v) => `${c} LIKE '%${v}%'`,
        conditionRange: (c, from, to) => `${c} BETWEEN ${from} AND ${to}`,
        allowedTypes: ['number', 'string']
    },
    NOTCONTAINS: {
        name: 'NOTCONTAINS',
        title: gettext('Does not contain'),
        conditionExact: (c, v) => `${c} NOT LIKE '%${v}%'`,
        conditionRange: (c, from, to) => `${c} NOT BETWEEN ${from} AND ${to}`,
        allowedTypes: ['number', 'string']
    },
    EQUALS_STRING: {
        name: 'EQUALS_STRING',
        title: gettext('Equals'),
        conditionExact: (c, v) => `LOWER(${c}) = LOWER('${v}')`,
        conditionRange: () => null, // not used
        allowedTypes: ['string']
    },
    EQUALS_NUMBER: {
        name: 'EQUALS_NUMBER',
        title: gettext('Equals'),
        conditionExact: (c, v) => `${c} = ${v}`,
        conditionRange: (c, from, to) => `${c} BETWEEN ${from} AND ${to}`,
        allowedTypes: ['number']
    },
    DISTINCT: {
        name: 'DISTINCT',
        title: gettext('Distinct values'),
        conditionExact: (c, values) => {
            const condition = values.map(v => `${c} = '${v}'`).join(' OR ');
            return condition && `(${condition})`;
        },
        conditionRange: () => null, // not used
        allowedTypes: ['string']
    }

};

const FILTER_TYPES = {
    EXACT: {
        name: 'exact',
        condition: 'conditionExact',
        requiredFields: ['value']
    },
    RANGE: {
        name: 'range',
        condition: 'conditionRange',
        requiredFields: ['from', 'to']
    }
};

const FILTER_COMBINATIONS = {
    AND: {
        query: 'AND',
        title: gettext('AND')
    },
    OR: {
        query: 'OR',
        title: gettext('OR')
    }
};

const COLUMNS = {
    id: {
        id: 'id',
        query: 'ID',
        title: '',
        type: 'number'
    },
    deviceID: {
        id: 'deviceID',
        query: 'DeviceID',
        title: '',
        type: 'number'
    },
    number: {
        id: 'number',
        query: 'Number',
        title: gettext('Number'),
        type: 'string'
    },
    cable: {
        id: 'cable',
        query: 'Cable',
        title: gettext('Cable'),
        type: 'string'
    },
    cableType: {
        id: 'cableType',
        query: 'Type',
        title: gettext('Cable Type'),
        type: 'string'
    },
    cableLength: {
        id: 'cableLength',
        query: 'Len',
        title: gettext('Length'),
        type: 'number'
    },
    srcDeviceName: {
        id: 'srcDeviceName',
        query: '"Src.Device"',
        title: gettext('Source Device'),
        type: 'string'
    },
    srcConnector: {
        id: 'srcConnector',
        query: '"Src.Conn"',
        title: gettext('Source Connector'),
        type: 'string'
    },
    srcSocketName: {
        id: 'srcSocketName',
        query: '"Src.Socket"',
        title: gettext('Source Socket'),
        type: 'string'
    },
    dstSocketName: {
        id: 'dstSocketName',
        query: '"Dst.Socket"',
        title: gettext('Destination Socket'),
        type: 'string'
    },
    dstConnector: {
        id: 'dstConnector',
        query: '"Dst.Socket"',
        title: gettext('Destination Connector'),
        type: 'string'
    },
    dstDeviceName: {
        id: 'dstDeviceName',
        query: '"Dst.Device"',
        title: gettext('Destination Device'),
        type: 'string'
    },
    deviceName: {
        id: 'deviceName',
        query: 'Name',
        title: gettext('Name'),
        type: 'string'
    },
    tag: {
        id: 'tag',
        query: 'Tag',
        title: gettext('Tag'),
        type: 'string'
    },
    desc: {
        id: 'desc',
        query: 'Description',
        title: gettext('Description'),
        type: 'string'
    },
    make: {
        id: 'make',
        query: 'Make',
        title: gettext('Make'),
        type: 'string'
    },
    model: {
        id: 'model',
        query: 'Model',
        title: gettext('Model'),
        type: 'string'
    },
    makeModel: {
        id: 'makeModel',
        query: 'Make || " " || Model',
        title: gettext('Make and model'),
        type: 'string'
    },
    mounting: {
        id: 'mounting',
        query: 'Mounting',
        title: gettext('Mounting'),
        type: 'string'
    },
    deviceDimensions: {
        id: 'deviceDimensions',
        query: 'Height || " / " || Width || " / " || Depth',
        // Translators: abbreviation for Height / Width / Depth
        title: gettext('H / W / D'),
        type: 'number',
        filterLikeType: 'string'
    },
    power: {
        id: 'power',
        query: 'Power',
        title: gettext('Power'),
        type: 'number'
    },
    weight: {
        id: 'weight',
        query: 'Weight',
        title: gettext('Weight'),
        type: 'number'
    },
    modular: {
        id: 'modular',
        query: 'Modular',
        title: gettext('Modular'),
        type: 'number'
    },
    numSlots: {
        id: 'numSlots',
        query: 'NumSlots',
        title: gettext('Slots'),
        type: 'number'
    },
    location: {
        id: 'location',
        query: '',
        title: gettext('Location'),
        type: 'string'
    },
    room: {
        id: 'room',
        query: 'Room',
        title: gettext('Room'),
        type: 'string'
    },
    dropPoint: {
        id: 'dropPoint',
        query: 'DropPoint',
        title: gettext('Drop Point'),
        type: 'string'
    },
    rack: {
        id: 'rack',
        query: 'Rack',
        title: gettext('Rack'),
        type: 'string'
    },
    rackU: {
        id: 'rackU',
        query: 'RackU',
        title: gettext('Rack Unit'),
        type: 'string'
    },
    slot: {
        id: 'slot',
        query: 'Slot',
        title: gettext('Slot'),
        type: 'string'
    },
    rackLocation: {
        id: 'rackLocation',
        query: '(IIF(Rack IS NULL, "", Rack || " ") || IIF(RackU AND Slot != "", RackU || "." || Slot, COALESCE(RackU, Slot)))',
        title: gettext('Rack Location'),
        type: 'string'
    },
    device: {
        id: 'device',
        query: 'Device',
        title: gettext('Device'),
        type: 'string'
    },
    socketName: {
        id: 'socketName',
        query: 'Socket',
        title: gettext('Socket'),
        type: 'string'
    },
    signal: {
        id: 'signal',
        query: 'Signal',
        title: gettext('Signal'),
        type: 'string'
    },
    adaptor: {
        id: 'adaptor',
        query: 'Adaptor',
        title: gettext('Adaptor'),
        type: 'string'
    },
    connector: {
        id: 'connector',
        query: 'Conn',
        title: gettext('Connector'),
        type: 'string'
    },
    socketType: {
        id: 'socketType',
        query: '',
        title: gettext('Type'),
        type: 'string'
    },
    circuitsCount: {
        id: 'circuitsCount',
        query: '',
        title: gettext('Number of Circuits'),
        type: 'number'
    },
    connectedTo: {
        id: 'connectedTo',
        query: 'CircuitNumber',
        title: gettext('Connected to'),
        type: 'string'
    }
};

const NESTED_COLUMNS = {
    location: {
        id: 'location',
        title: gettext('Location'),
        columns: [
            COLUMNS.room,
            COLUMNS.dropPoint,
            COLUMNS.rack,
            COLUMNS.rackU,
            COLUMNS.slot
        ],
        type: 'string'
    }
};

const NON_FILTERABLE_COLUMNS = [
    COLUMNS.deviceDimensions
];

const TABLES = {
    circuits: {
        name: 'circuits',
        title: gettext('Circuits'),
        icon: 'cad-connections',
        columns: [
            COLUMNS.id,
            COLUMNS.number,
            COLUMNS.cable,
            COLUMNS.cableLength,
            COLUMNS.cableType,
            COLUMNS.signal,
            COLUMNS.srcDeviceName,
            COLUMNS.srcSocketName,
            COLUMNS.srcConnector,
            COLUMNS.dstDeviceName,
            COLUMNS.dstSocketName,
            COLUMNS.dstConnector
        ],
        view: 'CircuitList',
        collapsable: [],
        nested: false
    },
    equipment: {
        name: 'equipment',
        title: gettext('Equipment'),
        icon: 'cad-equipment',
        columns: [
            COLUMNS.id,
            COLUMNS.deviceName,
            COLUMNS.tag,
            COLUMNS.desc,
            COLUMNS.make,
            COLUMNS.model,
            COLUMNS.makeModel,
            COLUMNS.mounting,
            COLUMNS.deviceDimensions,
            COLUMNS.power,
            COLUMNS.weight,
            COLUMNS.modular,
            COLUMNS.numSlots,
            COLUMNS.room,
            COLUMNS.rackLocation
        ],
        view: 'DeviceList',
        collapsable: [],
        nested: false
    },
    devices: {
        name: 'devices',
        title: gettext('Devices'),
        icon: 'cad-devices',
        columns: [
            COLUMNS.id,
            COLUMNS.deviceID,
            COLUMNS.device,
            COLUMNS.socketName,
            COLUMNS.signal,
            COLUMNS.adaptor,
            COLUMNS.connector,
            COLUMNS.connectedTo
        ],
        view: 'DevicesSocketsAdapters',
        collapsable: [COLUMNS.device],
        nested: false
    },
    locations: {
        name: 'locations',
        title: gettext('Locations'),
        icon: 'cad-locations',
        columns: [
            COLUMNS.room,
            COLUMNS.dropPoint,
            COLUMNS.rack,
            COLUMNS.rackU,
            COLUMNS.slot,
            COLUMNS.id,
            COLUMNS.deviceName,
            COLUMNS.tag,
            COLUMNS.desc,
            COLUMNS.make,
            COLUMNS.model,
            COLUMNS.makeModel,
            COLUMNS.mounting,
            COLUMNS.deviceDimensions,
            COLUMNS.power,
            COLUMNS.weight,
            COLUMNS.modular,
            COLUMNS.numSlots
        ],
        view: 'DeviceList',
        collapsable: [],
        nested: {
            parent: COLUMNS.location,
            children: [
                COLUMNS.room,
                COLUMNS.dropPoint,
                COLUMNS.rack,
                COLUMNS.rackU,
                COLUMNS.slot
            ]
        }
    }
};

const createCollapsableTable = (records, columns) => {
    if (columns.length === 0) {
        return records;
    }

    const remainingColumns = [...columns];
    const column = remainingColumns.shift();
    const contents = records.reduce((acc, r) => {
        const record = new Record(r);
        const element = acc.find(e => e.value === r[column]);
        if (element) {
            element.contents.push(record);
            return acc;
        } else {
            acc.push({
                value: r[column],
                column,
                contents: [record]
            });
            return acc;
        }
    }, []);

    contents.forEach(el => {
        el.contents = createCollapsableTable(el.contents, remainingColumns);
    });

    return contents;
};

const highlightSearch = (text, searchQuery, item, cell) => {
    if (searchQuery) {
        const strText = text.toString();
        const parts = strText.split(new RegExp(`(${searchQuery})`, 'gi'));

        return (
            <span>{
                parts.map((part, ind) => part.toLowerCase() === searchQuery.toLowerCase()
                    ? <S.Highlight key={`hl-${item.id}-${cell.id}-${ind}`}>
                        {part}
                    </S.Highlight>
                    : part
                )
            }
            </span>
        );
    } else {
        return text;
    }
};

const buildQuery = (baseQuery, store, skipFilters) => {
    const filterQuery = store.buildFilterQuery(skipFilters);
    const searchQuery = store.columns.searchCriteria();
    const sortQuery = store.columns.orderCriteria();

    let whereQuery = '';
    if (filterQuery || searchQuery) {
        whereQuery = 'WHERE ';
        whereQuery += filterQuery;
        if (filterQuery?.length > 0 && searchQuery?.length > 0) {
            whereQuery += ' AND ';
        }
        whereQuery += searchQuery;
    }

    return [baseQuery, whereQuery, sortQuery].join(' ');
};

export {
    TABLES,
    FILTERS,
    FILTER_TYPES,
    FILTER_COMBINATIONS,
    COLUMNS,
    NESTED_COLUMNS,
    NON_FILTERABLE_COLUMNS,
    createCollapsableTable,
    highlightSearch,
    buildQuery
};
