import { utils as libUtils } from '../../js/lib';

export default function (AFRAME) {
    const utils = AFRAME.utils;
    const bind = utils.bind;

    AFRAME.registerComponent('activity-monitor', {
        schema: {
            enabled: { default: true },
            timeout: { default: 5000 },
            key: { default: '' }
        },

        states: {
            disabled: {
                name: 'disabled',
                onEnter () {
                    this.timer = null;
                    this.removeEventListeners();
                },
                onEvent (type, ev) {},
                onLeave () {}
            },
            idle: {
                name: 'idle',
                onEnter () {
                    this.el.emit('idle');
                },
                onEvent (type, ev) {
                    if (type === 'pointerDown') {
                        this.el.emit('activity');
                        this.enterState(this.states.pointerDown);
                    } else if (type === 'enterVr') {
                        this.el.emit('activity');
                        this.enterState(this.states.vrMode);
                    } else if (type === 'pinMouseEnter') {
                        this.el.emit('activity');
                        this.enterState(this.states.hoverPin);
                    }
                },
                onLeave () {}
            },
            pointerDown: {
                name: 'pointerDown',
                onEnter () {},
                onEvent (type, ev) {
                    if (type === 'pointerUp') {
                        this.enterState(this.states.waiting);
                    }
                },
                onLeave () {}
            },
            vrMode: {
                name: 'vrMode',
                onEnter () {},
                onEvent (type, ev) {
                    if (type === 'exitVr') {
                        this.enterState(this.states.waiting);
                    }
                },
                onLeave () {}
            },
            hoverPin: {
                name: 'hoverPin',
                onEnter () {},
                onEvent (type, ev) {
                    if (type === 'pinMouseLeave') {
                        this.enterState(this.states.waiting);
                    } else if (type === 'pinClick') {
                        this.enterState(this.states.idle);
                    }
                },
                onLeave () {}
            },
            waiting: {
                name: 'waiting',
                onEnter () {
                    this.timer.start();
                },
                onEvent (type, ev) {
                    if (type === 'timeout') {
                        this.enterState(this.states.idle);
                    } else if (type === 'pointerDown') {
                        this.enterState(this.states.pointerDown);
                    } else if (type === 'enterVr') {
                        this.enterState(this.states.vrMode);
                    } else if (type === 'pinMouseEnter') {
                        this.enterState(this.states.hoverPin);
                    }
                },
                onLeave () {
                    this.timer.stop();
                }
            }
        },

        enterState (state) {
            this.state && this.state.onLeave.call(this);
            this.state = state;
            this.state.onEnter.call(this);
        },

        init () {
            this.bindMethods();
        },

        update (oldData) {
            this.timer && this.timer.stop();
            if (this.data.enabled) {
                this.timer = libUtils.createTimer(this.data.timeout, this.onEvent('timeout'));
                this.enterState(this.states.idle);
            } else {
                this.enterState(this.states.disabled);
            }
        },

        tick (t) {},

        play () {
            if (!this.data.enabled) return;
            this.addEventListeners();
        },

        pause () {
            if (!this.data.enabled) return;
            this.removeEventListeners();
            this.timer.stop();
        },

        remove () {
            if (!this.data.enabled) return;
            this.removeEventListeners();
            this.timer.stop();
        },

        addEventListeners () {
            const sceneEl = this.el.sceneEl;
            const canvasEl = sceneEl.canvas;

            // Wait for canvas to load.
            if (!canvasEl) {
                this.addEventListenersBound = bind(this.addEventListeners, this);
                sceneEl.addEventListener('render-target-loaded', this.addEventListenersBound);
                return;
            } else if (this.addEventListenersBound) {
                sceneEl.removeEventListener('render-target-loaded', this.addEventListenersBound);
                this.addEventListenersBound = null;
            }

            // Mouse events.
            canvasEl.addEventListener('mousedown', this.onMouseDown, false);
            window.addEventListener('mouseup', this.onMouseUp, false);

            // Touch events.
            canvasEl.addEventListener('touchstart', this.onTouchStart);
            window.addEventListener('touchend', this.onTouchEnd);

            // sceneEl events.
            sceneEl.addEventListener('enter-vr', this.onEnterVr);
            sceneEl.addEventListener('exit-vr', this.onExitVr);

            // pin events.
            sceneEl.addEventListener('pin-mouseenter', this.onPinMouseEnter);
            sceneEl.addEventListener('pin-mouseleave', this.onPinMouseLeave);
            sceneEl.addEventListener('pin-click', this.onPinClick);
        },

        removeEventListeners () {
            const sceneEl = this.el.sceneEl;
            const canvasEl = sceneEl.canvas;

            // Mouse events.
            canvasEl.removeEventListener('mousedown', this.onMouseDown);
            window.removeEventListener('mouseup', this.onMouseUp);

            // Touch events.
            canvasEl.removeEventListener('touchstart', this.onTouchStart);
            window.removeEventListener('touchend', this.onTouchEnd);

            // sceneEl events.
            sceneEl.removeEventListener('enter-vr', this.onEnterVr);
            sceneEl.removeEventListener('exit-vr', this.onExitVr);

            // pin events.
            sceneEl.removeEventListener('pin-mouseenter', this.onPinMouseEnter);
            sceneEl.removeEventListener('pin-mouseleave', this.onPinMouseLeave);
            sceneEl.removeEventListener('pin-click', this.onPinClick);
        },

        bindMethods () {
            this.onMouseDown = this.onEvent('pointerDown');
            this.onMouseUp = this.onEvent('pointerUp');
            this.onTouchStart = this.onEvent('pointerDown');
            this.onTouchEnd = this.onEvent('pointerUp');
            this.onEnterVr = this.onEvent('enterVr');
            this.onExitVr = this.onEvent('exitVr');
            this.onPinMouseEnter = this.onEvent('pinMouseEnter');
            this.onPinMouseLeave = this.onEvent('pinMouseLeave');
            this.onPinClick = this.onEvent('pinClick');
        },

        onEvent (type) {
            return ev => this.state.onEvent.call(this, type, ev);
        }
    });
};
