const splitContextActions = (actions, visibleActionsCount, hiddenActions = []) => {
    if (!actions) return { primary: [], secondary: [] };
    const actionObjects = [];

    Object.keys(actions).forEach(action => {
        if (actions[action].priority >= 0 && hiddenActions.indexOf(action) < 0) {
            actionObjects.push({
                name: action,
                data: actions[action]
            });
        }
    });
    actionObjects.sort((a, b) => a.data.priority - b.data.priority);

    let spliceCount = visibleActionsCount;
    // If there is only one tool in the dropdown
    if (actionObjects.length === spliceCount + 1) {
        spliceCount += 1;
    };

    return {
        primary: actionObjects.splice(0, spliceCount),
        secondary: actionObjects
    };
};

export default splitContextActions;
