import React from 'react';
import PropTypes from 'prop-types';

const DEFAULT_TITLE = gettext('Something went wrong!');
const DEFAULT_TEXT = gettext('Try refreshing the page or going to the previous one.');

class ErrorBoundary extends React.Component {
    state = { hasError: false };

    componentDidCatch (error, errorInfo) {
        this.setState({ hasError: true });
        window.Sentry && window.Sentry.captureException(error, { extra: errorInfo });
    }

    render () {
        if (this.state.hasError) {
            const { title, text } = this.props;

            return (
                <div className='message-box flex-col'>
                    <span className='icon icon-status-error-16'/>
                    <div className='message'>
                        <span className='msg-row-prim'>{ title || DEFAULT_TITLE }</span>
                        <span>{ text || DEFAULT_TEXT }</span>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string
};

export default ErrorBoundary;
