import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as Tour from '../../models/tour';
import * as Board from '../../models/board';

import Text from './Text';
import Asset from './Asset';
import Link from './Link';
import PendingTypePin from './PendingTypePin';

const ANY_PIN = PropTypes.oneOfType([
    Tour.Portal, Tour.LinkPin, Tour.AssetPin, Tour.TextPin,
    Board.LinkPin, Board.AssetPin, Board.TextPin
]);

const Default = ({ pin }) => {
    return <div style={{ padding: 7 }}>{pin.title}</div>;
};

Default.propTypes = {
    pin: ANY_PIN
};

const S = {
    Text: styled(Text)`
        > div {
            max-height: 170px;
            max-width: 300px;
        }
    `
};

const TooltipContent = ({ pin, ...rest }) => {
    const Component = [
        [Tour.PendingTypePin, PendingTypePin],
        [Tour.Portal, Default],
        [Tour.AssetPin, Asset],
        [Board.AssetPin, Asset],
        [Tour.TextPin, S.Text],
        [Board.TextPin, S.Text],
        [Tour.LinkPin, Link],
        [Board.LinkPin, Link]
    ].find(([pinType, _]) => pin instanceof pinType)[1] || Default;

    return <Component pin={pin} {...rest} />;
};

TooltipContent.propTypes = {
    pin: ANY_PIN
};

export default TooltipContent;
