import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from '@vectorworks/vcs-ui/dist/lib/Dialog';
import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';

const EmptyTrashDialog = ({ dialog }) => {
    const onClose = () => dialog.dismiss();
    const {
        size, onAccept,
        infoMessage = gettext('Are you sure you want to permanently delete all the files in your trash? ')
    } = dialog.params.params;
    const onConfirm = () => {
        onAccept();
        dialog.close();
    };

    return (
        <Dialog
            size={ size }
            data-what='dialog'
            data-which='empty-trash'
        >
            <Dialog.Header
                onClose={onClose}
            >
                <Dialog.Title>{gettext('Empty trash')}</Dialog.Title>
            </Dialog.Header>
            <Dialog.Inner>
                <div className='di-block'>
                    {infoMessage}
                    <b>{gettext('You won\'t be able to restore later.')}</b>
                </div>
            </Dialog.Inner>
            <Dialog.Buttons>
                <Button
                    onClick={onConfirm}
                    variant='primary'
                    data-what='dialog-btn'
                    data-which='primary'
                >{gettext('Delete')}
                </Button>
                <Button
                    onClick={onClose}
                    variant='secondary'
                    data-what='dialog-btn'
                    data-which='secondary'
                >{gettext('Cancel')}
                </Button>
            </Dialog.Buttons>
        </Dialog>
    );
};

EmptyTrashDialog.propTypes = {
    size: PropTypes.string,
    onAccept: PropTypes.func,
    dialog: PropTypes.object
};

export default EmptyTrashDialog;
