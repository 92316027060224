import { observable, makeObservable } from 'mobx'; // eslint-disable-line
import { account } from '../base';

class CollaboratorsStore {
    collaboratorsList = [];
    loaded = false;

    constructor () {
        makeObservable(this, {
            collaboratorsList: observable,
            loaded: observable
        });

        this.loadCollaborators = account.createCollaboratorsLoader();
    }

    getCollaborators = () =>
        this.loadCollaborators()
            .then(collaborators => {
                this.collaboratorsList = collaborators;
                this.loaded = true;
            });

    isEmpty = () => this.suggestions.length === 0;
}

const store = new CollaboratorsStore();
export default store;
