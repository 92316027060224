import React, { Component } from 'react';

import { observer } from 'mobx-react';

import { commonPropTypes } from './utils';
import ErrorViewer from './ErrorViewer';

class DefaultViewer extends Component {
    name = 'DefaultViewer';

    onIframeLoad = () => {
        this.props.store.endLoading();
    };

    render () {
        const srcUrl = this.props.store.file.sourceUrl;

        return (
            <div
                className='fileview-component-loader'
                data-what='file-viewer'
                {...this.props.controllerTogglers}
            >
                { this.props.children }

                {
                    this.props.store.file.preview.error
                        ? <ErrorViewer store={this.props.store}/>
                        : srcUrl && <iframe
                            className='file-view-iframe'
                            title={this.props.store.file.file.name}
                            src={srcUrl}
                            onLoad={this.onIframeLoad}
                        />
                }
            </div>
        );
    }
}

export default observer(DefaultViewer);

DefaultViewer.propTypes = {
    ...commonPropTypes
};
