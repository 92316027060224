import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { ThumbnailSimpleText } from '../../common/SimpleText';

const Intro = props =>
    <div
        className='layout-intro thumbnail-intro' // TODO: Add CSS for empty layout
        style={{ background: 'lightgray' }}
    >
        <ThumbnailSimpleText board={props.slide} text={props.slide.text} />
    </div>;

Intro.propTypes = {
    slide: PropTypes.object
};

export default observer(Intro);
