function patchEventListenerMethods (element) {
    if (element.eventListeners) return;
    element.eventListeners = new Map();

    element._addEventListener = element.addEventListener;
    element.addEventListener = function (event, listener, ...args) {
        this._addEventListener(event, listener, ...args);
        if (!this.eventListeners.has(event)) this.eventListeners.set(event, []);
        this.eventListeners.get(event).push(listener);
    };

    element._removeEventListener = element.removeEventListener;
    element.removeEventListener = function (event, listener, ...args) {
        this._removeEventListener(event, listener, ...args);
        if (this.eventListeners.has(event)) {
            const listenerIndex = this.eventListeners.get(event).indexOf(listener);
            if (listenerIndex !== -1) {
                this.eventListeners.get(event).splice(listenerIndex, 1);
            }
        }
    };
}

function createEventTracker (element, trackedEvents) {
    function reduceListeners () {
        return trackedEvents.reduce((acc, event) => {
            const eventListeners = element.eventListeners.get(event) || [];
            return acc.concat(eventListeners.map(l => [event, l]));
        }, []);
    }

    return {
        start () {
            this.listenersBefore = reduceListeners();
        },
        stop () {
            this.listenersAfter = reduceListeners();
            this.leakedListeners = this.listenersAfter.reduce((acc, a) => {
                if (!this.listenersBefore.find(b => a[0] === b[0] && a[1] === b[1])) {
                    acc.push(a);
                }
                return acc;
            }, []);
        },
        removeLeakedListeners () {
            for (const [event, listener] of this.leakedListeners) {
                element.removeEventListener(event, listener);
            }
        }
    };
}

export {
    patchEventListenerMethods,
    createEventTracker
};
