import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { TABLES } from './utils';
import ViewerContext from './ViewerContext';
import Location from './Location';
import { MobileCollapsableRow } from './CollapsableRow';
import S from './styles';

const DesktopNestedRow = ({
    record,
    identation,
    nestedColumnsCount,
    open,
    toggleOpen
}) =>
    <S.DesktopNestedRow>
        <S.NestedColumn
            nestedColumnsCount={nestedColumnsCount}
            identation={identation}
            isEmpty={false}
        >
            <S.Toggler
                className='cllps-toggle'
                key={`toggler-${record.column}-${record.value}`}
                expand={open}
                onClick={toggleOpen}
                data-what='nested-toggler'
                data-which={`${record.column}-${record.value}`}
            >
                { record.value }
            </S.Toggler>
        </S.NestedColumn>
        <S.NestedContentsFiller />
    </S.DesktopNestedRow>;

DesktopNestedRow.propTypes = {
    record: PropTypes.object,
    identation: PropTypes.number,
    nestedColumnsCount: PropTypes.number,
    open: PropTypes.bool,
    toggleOpen: PropTypes.func
};

const NestedRecord = ({ record, identation }) => {
    const { store, isMobile } = React.useContext(ViewerContext);
    const [open, setOpen] = React.useState(true);
    const [selected, setSelected] = React.useState(false);
    const nestedColumns = TABLES[store.table].nested.children;
    const nestedColumnsCount = nestedColumns.length;
    const isValueEmpty = record.value?.trim().length === 0;
    const nextIdentation = isValueEmpty ? identation : identation + 1;

    const toggleOpen = () => setOpen(!open);
    const toggleSelected = () => setSelected(!selected);

    const isCollapsable = r => r.hasOwnProperty('value') && r.hasOwnProperty('contents');

    return isCollapsable(record)
        ? <React.Fragment>
            {
                !isValueEmpty && (
                    isMobile
                        ? <MobileCollapsableRow
                            row={record}
                            identation={nextIdentation}
                            onClick={toggleOpen}
                            expand={open}
                        />
                        : <DesktopNestedRow
                            record={record}
                            identation={nextIdentation}
                            nestedColumnsCount={nestedColumnsCount}
                            open={open}
                            toggleOpen={toggleOpen}
                        />
                )
            }
            {
                open &&
                    record.contents.map(c => <NestedRecord
                        key={isCollapsable(c) ? `${c.column}-${c.value}` : c.id}
                        record={c}
                        identation={isValueEmpty ? identation : identation + 1}
                    />)
            }
        </React.Fragment>
        : <S.EmptyNestedColumn
            className={`${!isMobile && selected ? 'item-selected-light' : null}`}
            onClick={toggleSelected}
        >
            {
                !isMobile &&
                    <S.NestedColumn
                        nestedColumnsCount={nestedColumnsCount}
                        isEmpty={true}
                        identation={nextIdentation}
                    />
            }
            <Location
                item={record}
                skipColumns={nestedColumns}
                identation={nextIdentation}
            />
        </S.EmptyNestedColumn>;
};

NestedRecord.propTypes = {
    record: PropTypes.object,
    identation: PropTypes.number
};

export default observer(NestedRecord);
