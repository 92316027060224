function checkOverflow (el) {
    const curOverflow = el.style.overflow;

    if (!curOverflow || curOverflow === 'visible') {
        el.style.overflow = 'hidden';
    }

    const isOverflowing = el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;

    return isOverflowing;
};

function hideElement (el) {
    const display = el.style.display;
    el.style.display = 'none';
    return () => {
        el.style.display = display;
    };
}

const textUtils = {
    hasContent: text =>
        $(text).text().replace(/\s\s+/g, '').replace(/\r?\n|\r/, '').length,
    clearUnnecessaryCharacters: text =>
        text.replace(/&nbsp;/gi, '')
};

export {
    checkOverflow,
    hideElement,
    textUtils
};
