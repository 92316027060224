export function transalteVCSBEActions (actions) {
    const actionTranslations = {
        // Fitting
        Fill: gettext('Fill'),
        Fit: gettext('Fit'),
        Center: gettext('Center'),

        // Crop
        Crop: gettext('Crop'),
        Done: gettext('Done'),
        Reset: gettext('Reset'),
        Delete: gettext('Delete'),

        // Arrange
        Forward: gettext('Forward'),
        Backward: gettext('Backward'),
        'To front': gettext('To front'),
        'To back': gettext('To back')
    };

    return actions.map(vcsBEAction => {
        vcsBEAction.title = actionTranslations[vcsBEAction.title] || vcsBEAction.title;
        return vcsBEAction;
    });
}

const patchers = {
    Delete: ({ action, root, board }) => {
        const copy = { ...action };
        const original = () => copy.handler();
        action.handler = (...args) => {
            root.ui.toggle('boxSelectionEditor', false);
            const boxes = [...board.vcsBEStore.selection.boxes.map(b => b.cloudBox)];

            const result = original(...args);

            board.undoStore.add({
                undo: () => boxes.forEach(box => box.restore()),
                redo: () => boxes.forEach(box => board.vcsBEStore.removeBox(box.editorBox))
            });
            return result;
        };
    }
};

export function patchVCSEBEActions ({ actions, root, board } = {}) {
    actions.forEach(action => {
        patchers[action.id] && patchers[action.id]({ action, root, board });
    });

    return actions;
}
