import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import ExitPreview from '../common/ExitPreview';
import fullScreen from '../../base/fullscreen.js';

const LeftSection = observer(props => {
    const toggleFullscreen = () => {
        const preview = document.getElementById('presentation-fs-preview');
        props.store.setFullscreen(!props.store.isFullscreen);
        fullScreen.toggle(preview);
    };

    const toggleMap = () => {
        props.store.ui.toggle('map');
    };

    const isFullscrenEnabled = fullScreen.enabled && !(Settings.device.isIOS && Settings.device.isMobile);
    const isFullscreen = props.store.isFullscreen;
    const isMapOpen = props.store.ui.map;
    const isTour = props.projector.slide.type === 'virtual_tour';
    const hasMap = props.projector.slide.map;

    return (
        <div className='control-section left'>
            <ExitPreview/>
            {
                isFullscrenEnabled &&
                <span
                    className='ctrl-tool'
                    onClick={toggleFullscreen}
                    ga-action='Presentation_Preview'
                    ga-label='Click_Toggle_Fullscreen'
                    what='toggle-fullscreen'
                >
                    <span
                        className={`icon ${isFullscreen ? 'icon-exit-full-screen' : 'icon-full-screen'}`}
                        title={isFullscreen ? gettext('Exit full screen') : gettext('Enter full screen')}
                    />
                </span>
            }

            {
                isTour && hasMap &&
                <span
                    className={`ctrl-tool ${isMapOpen ? 'active' : ''}`}
                    onClick={toggleMap}
                >
                    <span
                        className='icon icon-pin-map'
                        data-what='toggle-map'
                        title={isMapOpen ? gettext('Hide Tour map') : gettext('Show Tour map') }
                    />
                </span>
            }
        </div>
    );
});

LeftSection.propTypes = {
    projector: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
};

const CenterSection = observer(props => {
    const next = () => props.projector.next();
    const prev = () => props.projector.prev();

    const { projector } = props;
    const currentSlide = projector.slidePointer + 1;
    const slides = projector.root.slide.slides.length;

    return (
        <div className='control-section center slide-navigation'>
            <div
                className='nav-item'
                onClick={prev}
                ga-action='Presentation_Preview'
                ga-label='Click_Previous_Slide'
                data-what='navigation'
                data-which='previous'
                title={gettext('Previous slide')}
            >
                <span
                    className={`icon icon-left-arrow ${!props.projector.hasPrev() ? 'disabled' : ''}`}
                />
            </div>

            <span>{`${currentSlide} / ${slides}`}</span>

            <div
                className='nav-item'
                onClick={next}
                ga-action='Presentation_Preview'
                ga-label='Click_Previous_Slide'
                data-what='navigation'
                data-which='next'
                title={gettext('Next slide')}
            >
                <span
                    className={`icon icon-right-arrow ${!props.projector.hasNext() ? 'disabled' : ''}`}
                />
            </div>
        </div>
    );
});

CenterSection.propTypes = {
    projector: PropTypes.object.isRequired
};

const RightSection = observer(props => {
    const enterVR = () => {
        const vrBtn = document.querySelector('#a-enter-vr-button');
        vrBtn.click();
    };

    const toggleContents = () => {
        props.store.ui.comments &&
            props.store.ui.toggle('comments');
        props.store.ui.toggle('contents');
    };

    const toggleComments = () => {
        props.store.ui.contents &&
            props.store.ui.toggle('contents');
        props.store.ui.toggle('comments');
    };

    const areContentsOpen = props.store.ui.contents;
    const areCommentsOpen = props.store.ui.comments;

    return (
        <div className='control-section right'>
            {
                !Settings.offlinePresentation &&
                <span
                    className={`ctrl-tool ${areCommentsOpen ? 'active' : ''}`}
                    ga-action='Presentation_Preview'
                    ga-label='Click_Toggle_Comments'
                    onClick={toggleComments}
                >
                    <span
                        className='icon icon-comments'
                        title={areCommentsOpen ? gettext('Hide comments') : gettext('Show comments')}
                    />
                </span>
            }

            <span
                className={`ctrl-tool ${areContentsOpen ? 'active' : ''}`}
                what='presentation-toggle-contents'
                ga-action='Presentation_Preview'
                ga-label='Click_Toggle_Contents'
                title={areContentsOpen ? gettext('Hide navigation') : gettext('Show navigation')}
                onClick={toggleContents}
            >
                <span
                    className='icon icon-presentation-content'
                />
            </span>

            {
                props.screen.controller.vrPossible &&
                <span
                    className='ctrl-tool custom-enter-vr-btn'
                    onClick={enterVR}
                    ga-action='Presentation_Preview'
                    ga-label='Click_Enter_VR'
                    what='enter-vr'
                >
                    <span className='icon icon-3d-stereo' />
                </span>
            }
        </div>
    );
});

RightSection.propTypes = {
    screen: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
};

const ControllBar = props => (
    props.screen.controller.isVisible && <div className='controller-zone'>
        <div className='controller pres-controller'>
            <div className='controller-inner'>
                <LeftSection {...props}/>
                <CenterSection {...props}/>
                <RightSection {...props}/>
            </div>
        </div>
    </div>
);
ControllBar.propTypes = {
    screen: PropTypes.object.isRequired,
    projector: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
};

export default observer(ControllBar);
