import { ContentState, convertToRaw } from 'draft-js';
import { observable, computed, action, reaction, runInAction, makeObservable } from 'mobx';
import { delay } from '../../lib/utils';
import { isEqual } from 'lodash';

import { Store as FreeBoardStore } from '@vectorworks/vcs-boards';
import { RGBColor } from '@vectorworks/vcs-ui/dist/lib/ColorPicker/utils';

import api from '../api';
import Index from '../utils/object-index';
import { saveMixin } from './save';
import itemContainerMixin from './item-container';
import assetMixin from './asset';
import BasePin from './pin';
import { LinkState } from './link';

import { patchVCSEBEActions, transalteVCSBEActions } from './v2/actions';
import {
    ImageBox,
    TextBox,
    BackgroundBox
} from './v2/box';
import { CommandTypes } from './v2/box/mixins';
import UndoStore from '../Stores/Undo';

const TargetType = {
    MEDIA: 'media',
    TEXT: 'text'
};

const partitionPinProps = (data) => {
    const {
        id,
        title,
        box: boxId,
        props,
        position,
        position_locked: positionLocked,
        visible,
        date_trashed: dateTrashed,
        target_id: targetId,
        target_type: targetType,
        ...specificPinProps
    } = data;
    return {
        basePinProps: {
            id,
            title,
            boxId,
            props,
            position,
            positionLocked,
            visible,
            dateTrashed,
            targetId,
            targetType
        },
        specificPinProps
    };
};

const boardBuilder = {
    build: action(function (base, data) {
        const board = new Board({
            ...base,
            layout: data.content.layout || 'intro',
            text: {
                ...data.content.text,
                color: RGBColor.guess(data.content.text?.color) || RGBColor.black
            },
            thumbnail: data.thumbnail || null,
            media: data.content.media || null
        });
        board.items.replace(data.items.map(item => this.createItem(board, item)));
        board.setBackgroundColor(RGBColor.guess(data.content.backgroundColor) || RGBColor.white);
        const assetPins = data.asset_pins?.map(p => this.createAssetPin(board, p)) || [];
        const textPins = data.text_pins?.map(p => this.createTextPin(board, p)) || [];
        const linkPins = data.link_pins?.map(p => this.createLinkPin(board, p)) || [];
        board.pins.replace([].concat(assetPins, textPins, linkPins));

        // Boxes
        data.boxes
            .map(box => this.createBox(board, box))
            .forEach(b => board.vcsBEStore.addBox(b.editorBox, { updateZOrder: false }));

        if (board.version === 2) {
            const databaseZOrder = data.z_order;
            const editorZOrder = {};

            board.vcsBEStore.boxes.forEach(eb => {
                editorZOrder[eb.id] = databaseZOrder[eb.cloudBox.databaseId];
            });

            board.vcsBEStore.applyZOrder(editorZOrder);
            board.optimisticZOrder = editorZOrder;
        }

        return board;
    }),
    createBox (board, data, options) {
        if (data.type === 'text') {
            return new TextBox({
                root: board.root,
                board,
                data
            });
        } else if (data.type === 'image') {
            return new ImageBox({
                root: board.root,
                board,
                data,
                options
            });
        }
    },
    createAssetPin (board, data) {
        const item = board.items.find(bi => bi.id === data.to_item);
        const { basePinProps } = partitionPinProps(data);
        const assetPinProps = {
            assetId: item?.assetId
        };
        return new AssetPin({
            root: board.root,
            board,
            toItemId: data.to_item,
            basePinProps,
            ...assetPinProps
        });
    },
    createTextPin (board, data) {
        const { basePinProps, specificPinProps: sp } = partitionPinProps(data);
        const textPinProps = {
            textProps: sp.text_props
        };

        return new TextPin({
            root: board.root,
            board,
            basePinProps,
            ...textPinProps
        });
    },
    createLinkPin (board, data) {
        const { basePinProps, specificPinProps: sp } = partitionPinProps(data);
        const linkPinProps = {
            linkState: LinkState.fromApi(sp),
            linkProps: sp.link_props
        };

        return new LinkPin({
            root: board.root,
            board,
            basePinProps,
            ...linkPinProps
        });
    },
    createItem (board, data) {
        return new BoardItem({
            root: board.root,
            board,
            id: data.id,
            assetId: data.asset,
            order: data.order
        });
    }
};

export class BoardBasePin extends BasePin {
    static TYPES = {
        assetPin: 1,
        textPin: 2,
        linkPin: 3,
        pendingTypePin: 4
    };

    constructor (data = {}) {
        super(data);

        this.targetType = this.targetType || undefined;
        this.targetId = this.targetId || undefined;
        this.postCreationQueue = [];
        this.restoreScheduled = [];
        this.restoreFromBoxQueue = [];

        makeObservable(this, {
            targetType: observable,
            targetId: observable
        });
    }

    // Can I reuse the mixin?
    // TODO: !!! Execute in order !!! Await previous.
    postCreate () {
        this.postCreationQueue.forEach(callback => callback());
        this.postCreationQueue = [];
        this.root.saveQueue.flush();
    }

    createUUID () {
        return (this.id << 8) | this.pinType;
    }

    get isOptimistic () {
        return !this.databaseId;
    }

    get slide () {
        return this.board;
    }

    get box () {
        // DIRTY
        if (!this.boxId) return this.board._BACKGROUND_BOX;

        const editorBox = this.board.vcsBEStore.boxes.find(box => [box.id, box.cloudBox.databaseId].includes(this.boxId));
        return editorBox
            ? editorBox.cloudBox
            : this.board._BACKGROUND_BOX;
    }

    move ({ targetType, targetId, position }) {
        if (this.positionLocked) return;

        const oldPosition = { ...this.position };
        this.board?.undoStore.add({
            undo: () => this.move({ targetType, targetId, position: oldPosition }),
            redo: () => this.move({ targetType, targetId, position })
        });

        targetType = targetType || this.targetType;
        targetId = targetId || this.targetId;
        if (this.targetType !== targetType) {
            const update = {
                [TargetType.TEXT]: this.setPositionInText,
                [TargetType.MEDIA]: this.setPositionOnMedia
            }[targetType];
            update.call(this, targetId, position);
            this.scheduleUpdatePosition();
        } else if (targetType === TargetType.MEDIA) {
            if (this.targetId !== targetId) {
                this.setPositionOnMedia(targetId, position);
                this.scheduleUpdatePosition();
            } else {
                const { x: oldX, y: oldY } = this.position;
                const { x, y } = position;
                if (x !== oldX || y !== oldY) {
                    this.position = position;
                    this.scheduleUpdatePosition();
                }
            }
        }
    }

    setPositionInText () {
        this.targetType = TargetType.TEXT;
        this.targetId = null;
        this.position = null;
    }

    setPositionOnMedia (targetId, position) {
        this.targetType = TargetType.MEDIA;
        this.targetId = targetId;
        this.position = position;
    }

    scheduleUpdatePosition () {
        this.scheduleSave(
            this.saveCommands.update,
            {
                target_type: this.targetType,
                target_id: this.targetId,
                position: this.position
            }
        );
    }

    scheduleCreate (data) {
        const handle = () => this.scheduleSave(
            this.saveCommands.add,
            { ...data, box: this.box?.databaseId }
        )
            .then(({ id }) => runInAction(() => {
                this.databaseId = id;
                this.board.pins.includes(this)
                    ? this.postCreate()
                    : this.scheduleDelete();
            }));

        handle.context = this;
        handle.type = CommandTypes.CREATE;

        this.box?.isOptimistic
            ? this.box.postCreationQueue.push(handle)
            : handle();
    }

    get trashPinsCommand () {
        return this.root.saveQueue.commands
            .find(sc => sc.command.type === CommandTypes.TRASH_PINS);
    }

    get trashPinsKey () {
        return {
            [BoardBasePin.TYPES.assetPin]: 'asset_pins',
            [BoardBasePin.TYPES.textPin]: 'text_pins',
            [BoardBasePin.TYPES.linkPin]: 'link_pins'
        }[this.pinType];
    }

    static createTrashPinsData (pins, method = 'trash') {
        const data = {
            asset_pins: { trash: [], restore: [] },
            text_pins: { trash: [], restore: [] },
            link_pins: { trash: [], restore: [] }
        };

        pins.forEach(pin => {
            if (pin.databaseId) {
                data[pin.trashPinsKey][method].push(pin.databaseId);
            }
        });

        return data;
    }

    scheduleTrash (options = {}) {
        if (!this.databaseId) return;

        const { method = 'trash' } = options;
        const command = this.trashPinsCommand;
        if (command) {
            // Remove it from the other method
            command.args[0][this.trashPinsKey][method === 'trash' ? 'restore' : 'trash'] =
            command.args[0][this.trashPinsKey][method === 'trash' ? 'restore' : 'trash']
                .filter(id => id !== this.databaseId);
            // Add it to the proper place
            command.args[0][this.trashPinsKey][method].push(this.databaseId);
        } else {
            this.scheduleSave(
                this.saveCommands.trashPins,
                BoardBasePin.createTrashPinsData([this], method)
            );
        }
    }

    scheduleDelete () {
        return this.scheduleSave(this.saveCommands.remove);
    }

    remove () {
        this.board.pins.remove(this);
        this.dateTrashed = new Date();
        if (this.box?.isOptimistic) {
            [
                this.restoreFromBoxQueue,
                this.box.postCreationQueue
            ] = this.box.postCreationQueue.reduce((result, command) => {
                result[command.context === this ? 0 : 1].push(command);
                return result;
            }, [[], []]);
        }

        [
            this.restoreScheduled,
            this.root.saveQueue.commands
        ] = this.root.saveQueue.commands.reduce((result, command) => {
            result[command.context === this ? 0 : 1].push(command);
            return result;
        }, [[], []]);

        if (!this.isOptimistic) {
            this.slide.version === 2
                ? this.scheduleTrash({ method: 'trash' })
                : this.scheduleDelete();
        }

        this.board.undoStore.add({
            undo: () => { this.restore(); },
            redo: () => { this.remove(); }
        });
    }

    restore () {
        this.board.pins.push(this);
        this.dateTrashed = false;

        this.root.saveQueue.commands = this.root.saveQueue.commands.concat(this.restoreScheduled);
        if (this.box !== this.board._BACKGROUND_BOX) {
            this.box.postCreationQueue = this.box.postCreationQueue.concat(this.restoreFromBoxQueue);
            !this.box.isOptimistic && this.box.postCreate({ flush: false });
        }

        this.restoreScheduled = [];
        this.restoreFromBoxQueue = [];

        if (!this.isOptimistic) {
            this.scheduleTrash({ method: 'restore' });
        }

        this.board.undoStore.add({
            undo: () => { this.remove(); },
            redo: () => { this.restore(); }
        });
    }

    saveCommands = {
        add: {
            type: CommandTypes.CREATE,
            command (data) {
                return api.board[this.pinTypeName].create(this.board, data);
            },
            params: {
                noReduce: true
            }
        },
        remove: {
            type: CommandTypes.DELETE,
            command (data) {
                return api.board[this.pinTypeName].remove(this, data);
            },
            params: {
                noReduce: true
            }
        },
        trashPins: {
            type: CommandTypes.TRASH_PINS,
            command (pins) {
                return api.board.trashPins(this.board, pins);
            }
        },
        update: {
            type: CommandTypes.UPDATE,
            command (data) {
                const handle = () => api.board[this.pinTypeName].update(this, data);

                return this.isOptimistic
                    ? Promise.resolve(this.postCreationQueue.push(handle))
                    : handle();
            },
            params: {
                mergeObjectArgs: true
            }
        }
    };

    get pinTypeName () {
        return Object.keys(BoardBasePin.TYPES).find(k => BoardBasePin.TYPES[k] === this.pinType);
    }
}

export class PendingTypePin {
    constructor ({ slide, position, target_id, target_type, box, props }) {
        this.pinType = BoardBasePin.TYPES.pendingTypePin;

        this.isPendingTypePin = true;
        this.board = slide;
        this.slide = slide;
        this.position = position;
        this.targetId = target_id;
        this.targetType = target_type;
        this.boxId = box;
        this.props = props;

        this.result = new Promise((resolve, reject) => {
            this.deferred = { resolve, reject };
        }).finally(() => {
            this.slide.removePendingTypePin(this);
        });
    }

    onChoose = (pinType) => {
        return this.deferred.resolve(pinType);
    };

    onCancel = () => {
        return this.deferred.reject();
    };
}

class AssetPin extends BoardBasePin {
    assetId;
    assetProps;

    get toItem () {
        return this.board.itemIndex.map.get(this.toItemId);
    }

    get asset () {
        return this.toItem.asset;
    }

    get target () {
        return this.toItem;
    }

    pinType = BoardBasePin.TYPES.assetPin;

    constructor ({ basePinProps, ...rest }) {
        super(basePinProps);

        makeObservable(this, {
            assetId: observable,
            assetProps: observable,
            toItem: computed,
            asset: computed,
            target: computed
        });

        this.root = rest.root;
        this.board = rest.board;
        this.toItemId = rest.toItemId;
        this.assetId = rest.assetId;

        this.databaseId = this.id;
        this.id = this.createUUID();
    }
}

Object.assign(AssetPin.prototype, saveMixin);

class TextPin extends BoardBasePin {
    pinType = BoardBasePin.TYPES.textPin;

    constructor ({ basePinProps, ...rest }) {
        super(basePinProps);

        this.textProps = this.textProps || undefined;

        makeObservable(this, {
            textProps: observable,
            updateContent: action
        });

        this.root = rest.root;
        this.board = rest.board;
        this.textProps = rest.textProps;

        this.databaseId = this.id;
        this.id = this.createUUID();
    }

    updateContent (data) {
        this.textProps = data.text_props;
        this.scheduleSave(this.saveCommands.update, data);
    }
}

Object.assign(TextPin.prototype, saveMixin);

class LinkPin extends BoardBasePin {
    pinType = BoardBasePin.TYPES.linkPin;

    constructor ({ basePinProps, ...rest }) {
        super(basePinProps);

        this.linkState = this.linkState || undefined;
        this.linkProps = this.linkProps || undefined;

        makeObservable(this, {
            linkState: observable,
            linkProps: observable,
            update: action
        });

        this.root = rest.root;
        this.board = rest.board;
        this.linkState = rest.linkState;
        this.linkProps = rest.linkProps;

        this.databaseId = this.id;
        this.id = this.createUUID();
    }

    update (linkStateUpdateData) {
        const oldState = this.linkState.copy();
        const newState = this.linkState.updated(linkStateUpdateData);

        if (!newState.equals(oldState)) {
            this.linkState = newState;
            this.scheduleSave(
                this.saveCommands.update,
                LinkState.forApi(newState)
            );
        }
    }
}

Object.assign(LinkPin.prototype, saveMixin);

class BoardItem {
    what = 'board-item';

    constructor (data) {
        makeObservable(this, {
            asset: computed,
            remove: action,
            removePin: action,
            removePins: action
        });

        Object.assign(this, data);
        this.root.asset.index.onDelete(
            this.assetId,
            action('syncBoardItemWithAsset', () => {
                this.board.items.remove(this);
                this.board.vcsBEStore.boxes
                    .filter(box => box.cloudBox.assetId === this.assetId)
                    .forEach(box => this.board.vcsBEStore.removeBox(box));
                this.board.pins.replace(
                    this.board.pins.filter(p => p.assetId !== this.assetId)
                );
            })
        );

        this.which = this.asset.filename;
    }

    get asset () {
        return this.root.asset.index.map.get(this.assetId);
    }

    addPin (data) {
        return this.board.addAssetPin({
            ...data,
            to_item: this.id
        });
    }

    remove () {
        this.board.pins
            .filter(pin => pin.asset?.id === this.assetId)
            .map(pin => pin.remove());
        this.board.vcsBEStore.boxes
            .filter(box => box.cloudBox.assetId === this.assetId)
            .forEach(box => this.board.vcsBEStore.removeBox(box));
        this.board.items.remove(this);
        this.scheduleSave(this.board.saveCommands.removeItem, this);
    }

    removePin (pin) {
        this.board.pins.remove(pin);
        this.scheduleSave(this.saveCommands.removePin, pin);
    }

    removePins (targetType) {
        this.board.pins.replace(this.board.pins.filter(p => p.targetType !== targetType));
    }

    saveCommands = {
        removePin: {
            command (pin) {
                return api.board.assetPin.remove(pin);
            },
            params: {
                noReduce: true
            }
        }
    };
}

Object.assign(BoardItem.prototype, saveMixin);

class Board {
    title;
    assetIds = [];
    layout;
    backgroundColor;
    text;
    items = [];
    pins = [];
    mediaId;
    thumbnail;

    static create (base, data) {
        return boardBuilder.build(base, data);
    }

    constructor (data) {
        makeObservable(this, {
            title: observable,
            assetIds: observable,
            layout: observable,
            thumbnail: observable,
            backgroundColor: observable,
            setBackgroundColor: action,
            text: observable,
            items: observable,
            pins: observable,
            mediaId: observable,
            media: computed,
            assets: computed,
            mediaPins: computed,
            textPins: computed,
            backgroundPins: computed,
            clearPins: action,
            setThumbnail: action,
            updateContent: action,
            addPendingTypePin: action,
            removePendingTypePin: action
        });

        Object.assign(this, data);
        this.itemIndex = new Index(this.items);

        this.version = this.root.version;
        this.undoStore = new UndoStore(this.root);

        if (this.version === 2) {
            this.vcsBEStore = new FreeBoardStore({
                onBoxUpdate: (editorBox) => {
                    this.registerUndoUpdate(editorBox);
                    editorBox.cloudBox.update();
                },
                onBoxDelete: (editorBox) => {
                    const box = editorBox.cloudBox;
                    this.root.saveQueue.commands = this.root.saveQueue.commands
                        .filter(saveCommand => saveCommand.context !== box);

                    // Remove all pending pins to that box
                    box.pins && box.pins.map(pin => pin.remove());

                    if (box.databaseId) {
                        box.scheduleDelete();
                    }
                },
                onZReorder: (updates, options = {}) => {
                    if (options.undo) {
                        const previous = { ...this.optimisticZOrder };

                        this.undoStore.add({
                            undo: () => {
                                this.vcsBEStore.applyZOrder(previous);
                                this.optimisticZOrder = previous;
                                this.onZReorder();
                            },
                            redo: () => {
                                this.vcsBEStore.applyZOrder({ ...updates });
                                this.optimisticZOrder = updates;
                                this.onZReorder();
                            }
                        });
                    }

                    this.optimisticZOrder = updates;
                    this.onZReorder();
                }
            });

            /* Mocks the background as a box. It's not actually added to vcsBEStore.boxes */
            this._BACKGROUND_BOX = new BackgroundBox({ board: this });

            reaction(
                () => this.vcsBEStore.selection.actions,
                (actions) => {
                    patchVCSEBEActions({ actions, root: this.root, board: this });
                    transalteVCSBEActions(actions);
                    this.root.ui.toggle('boxSelectionEditor', !!actions.length);
                }
            );
        }

        this.trackMedia();
    }

    get scaleFactor () {
        return this.version === 2
            ? this.vcsBEStore.scaleFactor
            : 1;
    }

    get media () {
        return this.root.asset.index.map.get(this.mediaId);
    }

    set media (value) {
        this.mediaId = value;
        this.trackMedia();
    }

    registerUndoUpdate (editorBox) {
        const box = editorBox.cloudBox;
        const updates = { ...box.apiProps };
        if (
            !isEqual(
                box.getComparableProps(updates),
                box.getComparableProps(box.updateStack[box.updateStack.length - 1])
            )
        ) {
            const index = box.updateStack.push(updates) - 1;
            this.undoStore.add({
                undo: () => {
                    box.applyProps(box.updateStack[index - 1]);
                },
                redo: () => {
                    box.applyProps(box.updateStack[index]);
                }
            });
        }
    }

    /* Returns a list of cancelable image download processes */
    preloadImages () {
        if (this.version === 2) {
            return this.vcsBEStore.boxes
                .filter(box => box.cloudBox instanceof ImageBox)
                .map(box => {
                    const img = new window.Image();
                    img.src = box.cloudBox.asset.fileVersion.download_url;
                    img.cancelDownload = () => { img.src = ''; };
                    return img;
                });
        }
        return [];
    }

    trackMedia () {
        const oldMediaId = this.mediaId;
        this.mediaId && this.root.asset.index.onDelete(
            this.mediaId,
            action('syncBoardMedia', () => {
                if (oldMediaId === this.mediaId) {
                    this.media = null;
                }
            })
        );
    }

    get assets () {
        return this.assetIds
            .map(id => this.root.asset.index.map.get(id))
            .filter(asset => !!asset);
    }

    get mediaPins () {
        return this.pins.filter(p => p.targetType === TargetType.MEDIA && p.targetId);
    }

    get textPins () {
        return this.pins.filter(p => p.targetType === TargetType.TEXT || !p.targetId);
    }

    get backgroundPins () {
        return this.version === 2
            ? this.pins.filter(p => p.targetType === TargetType.MEDIA && !p.boxId)
            : [];
    }

    getPinById (id) {
        return this.pins.find(p => p.id === id);
    }

    getTextPinById (id) {
        return this.textPins.find(p => p.id === id);
    }

    setBackgroundColor (color) {
        this.backgroundColor = color;
        if (this.version === 2) {
            this.vcsBEStore.setBackgroundColorCSS(color.toCSS());
        }
    }

    clearPins (targetType) {
        this.items.forEach(i => i.removePins(targetType));
    }

    setThumbnail (thumbnail) {
        this.thumbnail = thumbnail;
    }

    updateContent (data) {
        return this.version === 2
            ? this.scheduleSave(this.saveCommands.updateContent, {
                backgroundColor: this.backgroundColor
            })
            : this.scheduleSave(this.saveCommands.updateContent, {
                layout: this.layout,
                background: this.background,
                backgroundColor: this.backgroundColor,
                text: this.text,
                media: this.mediaId
            });
    }

    cleanUp () {
        this.itemIndex.cleanUp();
    }

    // Boxes
    pdfToImages (asset) {
        return this.scheduleSave(this.saveCommands.pdfToImages, asset);
    }

    // Boxes
    addImageBox (asset, editorBoxProps) {
        const box = boardBuilder.createBox(this, {
            type: 'image',
            asset: asset.id,
            props: {
                width: asset.params.size.width,
                height: asset.params.size.height,
                ...editorBoxProps
            }
        });
        this.vcsBEStore.addBox(box.editorBox);
        box.scheduleCreate();

        /*
            The reason for the delay() is that Image.fitSlide() requires the box's
            getBoundingClientRect() in order to position rotated images properly,
            but the actual DOM element only renders at the next tick.

            As s possible solution, if the rotation === 0, then use the width/height of the box
            instead of waiting for the bounding box. Or calclulate the boundingBox dimensions
            using the W/H/Rotation of the box.
        */
        return delay()
            .then(() => {
                box.editorBox.selectOnly();
                const isPositioned =
                    typeof editorBoxProps.x === 'number' ||
                    typeof editorBoxProps.y === 'number';
                if (!isPositioned) {
                    (box.editorBox.width > 1920 || box.editorBox.height > 1080)
                        ? box.editorBox.fitSlide()
                        : box.editorBox.moveToCenter();

                    box.editorBox.preventOverlap();
                }
            })
            .then(() => {
                this.undoStore.add({
                    undo: () => this.vcsBEStore.removeBox(box.editorBox),
                    redo: () => box.restore()
                });

                return box;
            });
    }

    addTextBox () {
        const contentState = ContentState.createFromText(gettext('Double click to edit'));
        const blocks = contentState.getBlocksAsArray();
        const fontSizes = {};
        const fontFamilies = {};
        blocks.forEach(b => {
            fontSizes[b.key] = 42;
            fontFamilies[b.key] = 'Roboto';
        });

        const data = {
            type: 'text',
            asset: null,
            props: {
                x: 1920 / 2 - 180,
                y: 1080 / 2 - 30,
                fontSizes,
                fontFamilies,
                text: {
                    raw: convertToRaw(contentState)
                }
            }
        };

        const box = boardBuilder.createBox(this, data);
        this.vcsBEStore.addBox(box.editorBox);
        box.scheduleCreate();

        return delay()
            .then(() => {
                box.editorBox.selectOnly();
                box.editorBox.preventOverlap();
                box.update();
                setTimeout(() => {
                    box.editorBox.enterTextEditMode();
                }, 100);
            })
            .then(() => {
                this.undoStore.add({
                    undo: () => this.vcsBEStore.removeBox(box.editorBox),
                    redo: () => {
                        this.vcsBEStore.addBox(box.editorBox);
                        box.scheduleCreate();
                    }
                });

                return box;
            });
    }

    // Generic Pins
    addPendingTypePin (data) {
        const pin = new PendingTypePin({ ...data, slide: this });
        this.pins.push(pin);
        return pin;
    }

    removePendingTypePin (pin) {
        this.pins.remove(pin);
    }

    onAddPin (factory, data) {
        const pin = factory(this, data);
        this.pins.push(pin);
        pin.scheduleCreate(data);
        this.undoStore.add({
            undo: () => { pin.remove(); },
            redo: () => { pin.restore(); }
        });
        return Promise.resolve(pin);
    }

    addAssetPin (data) {
        return this.onAddPin(boardBuilder.createAssetPin, data);
    }

    addTextPin (data) {
        return this.onAddPin(boardBuilder.createTextPin, data);
    }

    addLinkPin (data) {
        return this.onAddPin(boardBuilder.createLinkPin, data);
    }

    onZReorder () {
        const optEditorBox = this.vcsBEStore.boxes.find(({ cloudBox }) => cloudBox.isOptimistic);
        if (optEditorBox) {
            optEditorBox.cloudBox.postCreationQueue.push(() => this.onZReorder());
        } else {
            const zOrder = {};
            this.vcsBEStore.boxes.forEach(editorBox => {
                const box = editorBox.cloudBox;
                zOrder[box.databaseId] = this.vcsBEStore.zOrder[editorBox.id];
                return this.scheduleSave(this.saveCommands.zOrder, zOrder);
            });
        }
    }

    saveCommands = {
        addItems: {
            command (assets) {
                return Promise.all(assets.map(asset =>
                    api.board.createItem(this, {
                        asset: asset.id
                    }).then(data => {
                        const item = boardBuilder.createItem(this, data);
                        this.pushItem(item);
                        return item;
                    })
                ));
            },
            params: {
                flush: true
            }
        },
        moveItem: {
            command (item, order) {
                return api.board.updateItem(item, { order })
                    .then(data => this.updateOrder(item, data.order));
            },
            params: {
                noReduce: true
            }
        },
        removeItem: {
            command (item) {
                return api.board.removeItem(item);
            },
            params: {
                noReduce: true
            }
        },
        updateContent: {
            command (content) {
                return api.board.updateBoard(this, { content });
            }
        },
        zOrder: {
            command (zOrder) {
                return api.board.zOrder(this, zOrder);
            }
        },
        pdfToImages: {
            command (asset) {
                return api.asset.pdfToImages({
                    presentationId: this.root.id,
                    boardId: this.id,
                    assetUUID: asset.id
                });
            },
            params: {
                flush: true
            }
        }
    };
};

Object.assign(
    Board.prototype,
    saveMixin,
    itemContainerMixin,
    assetMixin
);

export default Board;
export {
    AssetPin,
    TextPin,
    LinkPin,
    BoardItem,
    TargetType,
    boardBuilder
};
