import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { PreviewSimpleText } from '../../common/SimpleText';
import { PreviewViewer as Viewer } from '../../FileViewers';
import Frame from './Frame';

const Show = props =>
    <Frame classes='layout-show' slide={props.slide}>
        <div className='ib-file-container ib-file-container-layout'>
            <Viewer
                file={props.slide.media}
                pins={props.slide.mediaPins}
                screen={props.preview}
                previewContainer={props.previewContainer}
            />
        </div>
        <PreviewSimpleText
            board={props.preview.slide}
            text={props.preview.slide.text}
            screen={props.preview}
            previewContainer={props.previewContainer}
        />
    </Frame>;

Show.propTypes = {
    preview: PropTypes.object.isRequired,
    slide: PropTypes.object.isRequired,
    previewContainer: PropTypes.object
};

export default observer(Show);
