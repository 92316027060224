import React from 'react';

import PropTypes from 'prop-types';

const ThumbsLoader = props => {
    return (
        <React.Fragment>
            { props.thumbnail && <div className='asset-img' style={{ backgroundImage: `url('${props.thumbnail}')` }} /> }
            { !props.thumbnail && <div className='icon svg-file-icon' dangerouslySetInnerHTML={{ __html: props.iconHTML }} /> }
        </React.Fragment>
    );
};

ThumbsLoader.propTypes = {
    thumbnail: PropTypes.string,
    iconHTML: PropTypes.string
};

const DialogThumbsLoader = props => {
    return (
        <div className='di-thumb'>
            { props.thumbnail && <div className='di-thumb-image di-thumb-image--background' style={{ backgroundImage: `url("${props.thumbnail}")` }} /> }
            { !props.thumbnail && <div className='icon svg-file-icon' dangerouslySetInnerHTML={{ __html: props.iconHTML }} /> }
        </div>
    );
};

DialogThumbsLoader.propTypes = {
    thumbnail: PropTypes.string,
    iconHTML: PropTypes.string
};

export default ThumbsLoader;
export { DialogThumbsLoader };
