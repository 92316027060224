import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

const VideoProgress = ({ viewer }) =>
    <div className='time-controlls'>
        <div
            className={`ctrl-tool ctrl-wrapper ${viewer.canPlay ? '' : 'disabled'}`}
            style={{ flex: '1' }}
        >
            <div className='progress-slider'>
                {!!viewer.updateTime && <input
                    className='slider'
                    type='range'
                    min='0'
                    max={viewer.duration}
                    onChange={viewer.updateTime}
                    onInput={viewer.updateTime}
                    value={viewer.time || 0}
                />}
                <div
                    className='slider-fill'
                    style={{ width: viewer.progress }}
                />
                <div
                    className='slider-buffered'
                    style={{ width: `${viewer.buffered}%` }}
                />
            </div>
        </div>

        <div className='ctrl-tool time-display'>
            {`${viewer.displayTime || '00:00'} / ${viewer.displayDuration || '00:00'}`}
        </div>
    </div>;

VideoProgress.propTypes = {
    viewer: PropTypes.object
};

export default observer(VideoProgress);
