import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from '@vectorworks/vcs-ui/dist/lib/Dialog';
import Preview from '../../Pins/Preview/Text';

export const TextPreview = ({ dialog }) => {
    const textPin = dialog.params.params.textPin;

    return (
        <Dialog>
            <Dialog.Header
                title={textPin.title}
                onClose={() => dialog.dismiss()}
            />
            <Dialog.Inner>
                <Dialog.Block>
                    <Preview pin={textPin} />
                </Dialog.Block>
            </Dialog.Inner>
        </Dialog>
    );
};

TextPreview.propTypes = {
    dialog: PropTypes.object
};
