/*
This component serves as transparency indication background
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

const Styled = styled.div`
    border-collapse: collapse;
    padding: 5px;
    flex-shrink: 0;
    display: inherit;

    div div {
        width: 4px;
        height: 4px;
        background: var(--grey5);
        flex-shrink: 0;
    }

    div:nth-child(odd) div:nth-child(even) {
        background: white;
    }
    div:nth-child(even) div:nth-child(odd) {
        background: white;
    }
`;

export const Chess = React.memo(({ size = 15, ...props }) => {
    const arr = _.range(size);

    return (
        <Styled {...props}>
            { arr.map((_, i) => <div key={'tr' + i}>{arr.map((_, j) => <div key={'td' + i + ':' + j} />)}</div>)}
        </Styled>
    );
});

Chess.propTypes = {
    size: PropTypes.number
};
