import React from 'react';

import { observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import reactStringReplace from 'react-string-replace';

import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';

import { commonPropTypes, reactionWithOldValue } from './utils';
import keyboard from '~static/js/base/keyboard';
import UnityVGXViewerController from './Controllers/UnityVGXViewerController';
import { DefaultController } from './Controllers/Default';
import { requestDeepLink } from '../../nomad/ShowInNomad';

const NotEnoughMemory = () => (
    <div className='message-box flex-col'>
        <span className='icon icon-missing-3d-capabilities' />
        <div className='message'>
            <span className='msg-row-prim'>{ gettext('Large 3D model') }</span>
            <span>
                {
                    reactStringReplace(
                        gettext('Due to browsers’ memory usage limitations, this model cannot be loaded. If you have a late model device with good memory, try viewing the model in the Vectorworks Nomad mobile app. If the model is still too large, reduce the size by hiding objects or using the clip cube (see this %(helpArticleTitle)s for details).'),
                        '%(helpArticleTitle)s',
                        () => <a
                            key='help-link-model-optimization'
                            href='/portal/help/faq/troubleshooting/#how-can-i-optimize-my-model-for-viewing-in-ar-or-3d'
                        >{ gettext('FAQ') }
                        </a>
                    )
                }
            </span>
        </div>
    </div>);

const UnknownError = () => (
    <div className='message-box flex-col'>
        <span className='icon icon-missing-3d-capabilities' />
        <div className='message'>
            <span className='msg-row-prim'>{ gettext('Unknown error') }</span>
            <span>
                { gettext('An unknown error has occurred. Please contact the system administrator for assistance.') }
            </span>
        </div>
    </div>);

class UnityVGXViewer extends React.Component {
    name = 'UnityVGXViewer';
    keyEvents = null;

    state = { error: null };

    controller = {
        state: observable.map(new Map()),
        togglePanel: name => {
            this.sendCommand('viewerController.togglePanel', name);
        },
        toggleNavigationMode: () => {
            this.sendCommand('viewerController.toggleNavigationMode');
        },
        goHome: () => {
            this.sendCommand('viewerController.goHome');
        },
        initState () {
            this.state.set('currentPanel', null);
            this.state.set('navigationMode', 'orbit');
        }
    };

    componentDidMount () {
        this.controller.initState();
        this.props.store.endLoading();
        this.reactionDisposer = reactionWithOldValue(
            () => this.props.store.file.file,
            (newValue, oldValue) => {
                if (!oldValue || oldValue.versionId !== newValue.versionId) {
                    this.props.store.endLoading();
                }
            },
            { name: 'endLoadingOnChangedFile' }
        );
        this.keyEvents = keyboard.peekKeyEvents();
        this.keyEvents.extend({
            left: {
                predicate: event => event.which === 37,
                handler: () => {}
            },
            right: {
                predicate: event => event.which === 39,
                handler: () => {}
            }
        });
    }

    componentWillUnmount () {
        this.keyEvents && this.keyEvents.reduce();
        this.keyEvents = null;
        this.reactionDisposer && this.reactionDisposer();
        window.document.removeEventListener('UnityVGXViewerEvent', this.onViewerEvent);
        this.controller.initState();
    }

    onViewerEvent = e => {
        const { name, data } = e.detail;
        if (name === 'viewerController.state') {
            this.controller.state.set('currentPanel', data.currentPanel);
            this.controller.state.set('navigationMode', data.navigationMode);
            if (data.navigationMode === 'walkthrough') {
                setTimeout(() => this.iframe.contentWindow.focus(), 100);
            }
        }

        if (name === 'error') {
            this.props.store.endLoading();
            this.setState({ error: data });
        }
    };

    sendCommand = (command, data) => {
        const event = new window.CustomEvent(
            'UnityVGXViewerCommand',
            { detail: { command, data } }
        );
        this.iframe.contentDocument.dispatchEvent(event);
    };

    onIframeLoad = () => {
        const iframeSrc = $(this.iframe).attr('src');
        if (iframeSrc.startsWith(window.location.origin)) {
            $(this.iframe).contents().find('body').on('mousemove', this.props.store.ui.controller.show);
        }
        window.document.addEventListener('UnityVGXViewerEvent', this.onViewerEvent);
    };

    getIframeSrc = () => {
        const src = Settings.offlinePresentation
            ? `app/file-viewer/unity-vgx/vgx-template.html?embedded=1&download_url=${this.props.sourceUrl}`
            : `${window.location.origin}/portal/file-viewer/unity-vgx/?embedded=1&download_url=${this.props.sourceUrl}`;
        return src;
    };

    render () {
        const { error } = this.state;
        const panel = this.controller?.state.get('currentPanel');
        return !this.state.error
            ? <React.Fragment>
                <div
                    className='fileview-component-loader'
                    data-what='file-viewer'
                    {...this.props.controllerTogglers}
                >
                    { this.props.children }

                    {
                        !this.props.store.loading &&
                            <iframe
                                className='file-view-iframe'
                                ref={iframe => { this.iframe = iframe; }}
                                title={this.props.store.file.name}
                                src={ this.getIframeSrc() }
                                onLoad={this.onIframeLoad}
                                allowFullScreen
                            />
                    }
                </div>
                {
                    window.Settings.device.isMobile || (document.querySelector('.viewer-wrapper')?.clientWidth < 1020)
                        ? (!panel || ['clipCube'].includes(panel)) && <UnityVGXViewerController {...this.props} viewer={this}/>
                        : <UnityVGXViewerController {...this.props} viewer={this}/>
                }
            </React.Fragment>
            : <React.Fragment>
                <div
                    className='fileview-component-loader'
                    data-what='file-viewer'
                    {...this.props.controllerTogglers}
                >
                    {
                        error && (
                            error.message.includes('memory')
                                ? <NotEnoughMemory />
                                : <UnknownError />
                        )
                    }
                </div>
                <DefaultController {...this.props} />
            </React.Fragment>;
    };
};

class OpenInNomadOnMobile extends React.Component {
    componentDidMount () {
        this.props.store.endLoading();
    }

    handleOpenInNomad = () => {
        const resourceId = this.props.store.file.file.data.resource_id;
        const params = new URLSearchParams(window.location.search);
        params.append('file.resourceId', resourceId);

        const l = window.location;
        const url = l.origin + l.pathname + '?' + params.toString();

        requestDeepLink(url)
            .then(({ shortLink }) => {
                if (shortLink) {
                    window.location.href = shortLink;
                }
            })
            .catch(() => {});
    };

    render () {
        return (
            <React.Fragment>
                <div
                    className='fileview-component-loader'
                    data-what='file-viewer'
                    {...this.props.controllerTogglers}
                >
                    <div className='message-box flex-col'>
                        <span className='icon icon-missing-3d-capabilities' />
                        <div className='message'>
                            <span className='msg-row-prim'>{ gettext('Unsupported browser') }</span>
                            <span className='msg-row-sec'>
                                {gettext('Vectorworks 3D models can\'t be viewed in mobile browsers due to browsers\' technical limitations. Get the free Vectorworks Nomad mobile app.')}
                            </span>
                            <br/>
                            <Button variant='primary' onClick={this.handleOpenInNomad}>
                                {gettext('Open in Nomad')}
                            </Button>
                        </div>
                    </div>

                    { this.props.children }
                </div>
                <DefaultController {...this.props} />
            </React.Fragment>
        );
    }
}

OpenInNomadOnMobile.propTypes = {
    ...commonPropTypes
};

const OpenInNomad = observer(OpenInNomadOnMobile);

UnityVGXViewer.propTypes = {
    ...commonPropTypes,
    sourceUrl: PropTypes.string
};

export default observer(UnityVGXViewer);
export { OpenInNomad };
