import React from 'react';
import AspectRatio from './AspectRatio';

const SlideAnimation = () => (
    <AspectRatio selector='#ib-transition-overlay'>
        <div id='ib-transition-overlay'/>
    </AspectRatio>
);

export default SlideAnimation;
