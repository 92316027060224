import React from 'react';

import Icon from '../../Components/Icon';

const OwnerStoppedIntegration = props => (
    <div className='no-preview'>
        <div className='message-box flex-col'>
            <Icon icon='icon-view'/>
            <div className='message'>
                <span className='msg-row-prim'>{ gettext('Not found') }</span>
                <span>{ gettext('The third-party cloud storage provider where this file was located is no longer integrated with Vectorworks Cloud Services. Contact the owner to regain access.') }</span>
            </div>
        </div>
    </div>);

export default OwnerStoppedIntegration;
