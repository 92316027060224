import React from 'react';
import PropTypes from 'prop-types';

import RadioButton from './RadioButton';

const RadioGroup = props => {
    return (
        <div className='vw-radio-group'>
            {
                props.options.map(o =>
                    <RadioButton
                        key={o.value}
                        name={o.value}
                        label={o.label}
                        value={o.value}
                        checked={props.value === o.value}
                        defaultChecked={props.value === o.value}
                        onChange={props.onChange}
                        data-what={props.what}
                        data-which={o.value}
                    />
                )
            }
        </div>
    );
};

RadioGroup.propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func,
    value: PropTypes.string,
    what: PropTypes.string
};

export default RadioGroup;
