import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { convertToRaw } from 'draft-js';

import { Dialog } from '@vectorworks/vcs-ui/dist/lib/Dialog';
import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';
import { MenuItem, MenuItemIcon } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';

import { PinEditPanel, S as PinEditPanelS } from './PinEditPanel';
import { TextPinEditor } from './TextPinEditor/TextPinEditor';
import { contentStateToHTML, buildEditorState } from '../../../Editor/Board/TextEditor/editor-funcs';
import { getColorsFromEditorState } from '../../../Editor/utils';

import TextPreview from '../../../Pins/Preview/Text';
import { inject } from '../../../Store';
import { Observer } from 'mobx-react';

const S = {
    ...PinEditPanelS,
    TextPinEditor: styled(TextPinEditor)`
        width: 100%;
    `,
    TextPreview: styled(TextPreview)`
        max-height: 170px;
    `
};

const EditTextDialog = ({ dialog }) => {
    const { pin } = dialog.params.params;

    const [editorState, setEditorState] = useState(buildEditorState(pin.textProps));
    const [customStyleMap, setCustomStyleMap] = useState(getColorsFromEditorState(editorState));

    const onClose = () => dialog.close();

    const handleTextChange = (editorState, newCustomStyleMap) => {
        newCustomStyleMap && setCustomStyleMap(newCustomStyleMap);
        if (editorState) {
            const contentState = editorState.getCurrentContent();
            const rawContent = convertToRaw(contentState);
            const result = {
                raw: rawContent,
                html: contentStateToHTML(contentState, newCustomStyleMap || customStyleMap)
            };
            pin.updateContent({ text_props: result });
            setEditorState(editorState);
        }
    };

    return (
        <Observer>{() => (
            <Dialog>
                <Dialog.Header title={gettext('Edit text')} />
                <Dialog.Inner style={{ display: 'flex', overflow: 'auto' }}>
                    <Dialog.Block style={{ display: 'flex', overflow: 'auto', width: '100%' }}>
                        <S.TextPinEditor
                            editorState={editorState}
                            customStyleMap={customStyleMap}
                            onChange={handleTextChange}
                            ariaLabel='text-pin__editor'
                        />
                    </Dialog.Block>
                </Dialog.Inner>
                <Dialog.Buttons>
                    <Button variant='primary' onClick={onClose}>{gettext('Done')}</Button>
                </Dialog.Buttons>
            </Dialog>
        )}
        </Observer>
    );
};

EditTextDialog.propTypes = {
    dialog: PropTypes.object
};

export const TextPinEditorPopup = inject('dialog')(
    ({ pin, dialog, onClose }) => {
        const editText = () => {
            return dialog.open({
                component: EditTextDialog,
                params: { pin }
            });
        };

        return (
            <Observer>{() => (
                <PinEditPanel pin={pin} onClose={onClose}>
                    <S.SectionTitle>{gettext('Text')}</S.SectionTitle>
                    <S.Section style={{ paddingBottom: pin.textProps.html ? 8 : 0 }}>
                        {/* Why cant i pass pin={pin} - Preview doesn't react to changes! */}
                        <S.TextPreview pin={{ textProps: pin.textProps }} />
                    </S.Section>
                    <MenuItem
                        onClick={editText}
                        title={gettext('Edit text')}
                        data-what='pin-edit-panel' data-which='edit-text'
                    >
                        <MenuItemIcon>
                            <Icon icon='edit' />
                        </MenuItemIcon>
                        {gettext('Edit text')}
                    </MenuItem>
                    <S.Separator style={{ marginTop: 8 }} />
                </PinEditPanel>
            )}
            </Observer>
        );
    }
);

// Like DraggablePin2D's props
TextPinEditorPopup.propTypes = {
    onClose: PropTypes.func,
    pin: PropTypes.object,
    dialog: PropTypes.object
};
