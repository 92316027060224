import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import { DraggableCore } from 'react-draggable';

import { inject } from '../Store';
import { TargetType } from '../models/board';
import { getPinSize, getBoundPosition } from './util';
import { PinUI } from './PinUI';
import IconPicker from '../FileViewers/Tour/PinEditors/Appearance/ShapeWithIcon/IconPicker';
import ShapeWithIconModel from '../FileViewers/Tour/PinEditors/Appearance/ShapeWithIcon/model';
import { rotationProps } from '../Editor/DragDropMonitor/util';
import { PendingTypePin } from './PendingTypePin';

const DRAG_TRESHOLD = 5;

const Pin = observer((
    {
        pin, pinRef,
        dragging, position, top, left, units, x, y, // positioning & dragging
        className, extraClassName, style, // styling
        ...rest
    }
) => {
    const [hovered, setHovered] = useState(false);

    const onMouseEnter = e => {
        rest.onMouseEnter && rest.onMouseEnter(e);
        setHovered(true);
    };

    const onMouseLeave = e => {
        rest.onMouseLeave && rest.onMouseLeave(e);
        setHovered(false);
    };

    const onClick = e => {
        e.stopPropagation();
        rest.onClick && rest.onClick(e);
        rest.onMouseLeave && rest.onMouseLeave();
        rest.onBlur && rest.onBlur(e);
    };

    const onMouseUp = e => {
        e.__vcsPinEvent = true;
        rest.onMouseUp && rest.onMouseUp(e);
    };

    const getHighlightOpacity = () => {
        const { highlightOpacity, highlightTransparentPins, isHovered, isSelected } = rest;

        if (highlightOpacity !== undefined) return highlightOpacity;
        if (isSelected) return 1;
        if (isHovered || hovered) return 0.5;
        if (highlightTransparentPins && (pin.isTransparent || !pin.visible)) return 0.5;

        return 0;
    };

    const getAppearanceModel = () => {
        const image = ShapeWithIconModel.IMAGES.AUTO.type === pin.props.image
            ? IconPicker.getAutoImage(pin)
            : pin.props.image;

        return {
            ...pin.props,
            image
        };
    };

    const eventHandlers = Object.entries(rest)
        .reduce((data, [key, value]) => {
            if (key.startsWith('on')) {
                data[key] = value;
            }
            return data;
        }, {});

    return (
        <div
            className={[
                'pin',
                className, extraClassName,
                dragging ? 'dragging' : ''
            ].join(' ')}
            style={{
                ...(style || {}),
                ...(
                    position === 'absolute'
                        ? {
                            position: 'absolute',
                            top: `${top}${units}`,
                            left: `${left}${units}`
                        }
                        : {}
                ),
                transform: `translate(${x}px, ${y}px)`
            }}
            ref={pinRef}
            title={pin.title}
            ga-action='Presentation_Pin'
            ga-label='Click_Pin'
            {...eventHandlers}
            onClick={onClick}
            onMouseUp={onMouseUp}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <PinUI
                appearanceModel={getAppearanceModel()}
                isTextPin={pin.targetType === TargetType.TEXT}
                visible={pin.visible}
                highlightOpacity={getHighlightOpacity()}
            />
        </div>
    );
});

Pin.propTypes = {
    pin: PropTypes.object.isRequired,
    position: PropTypes.string,
    left: PropTypes.number,
    top: PropTypes.number,
    size: PropTypes.number,
    units: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    onClick: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
    pinRef: PropTypes.object,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onTouchStart: PropTypes.func,
    onTouchEnd: PropTypes.func,
    extraClassName: PropTypes.string,
    dragging: PropTypes.bool,
    highlightTransparentPins: PropTypes.bool,
    isHovered: PropTypes.bool,
    isSelected: PropTypes.bool,
    highlightOpacity: PropTypes.number
};
Pin.defaultProps = {
    className: '',
    highlightTransparentPins: false
};

export function get2DPosition (pin) {
    return (pin.isTourPin
        ? pin.position2D
        : pin.position) || { x: 0, y: 0 };
}

function startDistanceMeasure () {
    return {
        x: 0,
        y: 0,
        add (dx, dy) {
            this.x += dx;
            this.y += dy;
        },
        isDrag () {
            return Math.abs(this.x) > DRAG_TRESHOLD || Math.abs(this.y) > DRAG_TRESHOLD;
        }
    };
}

/* eslint-disable react/prop-types */
const Draggable = inject('dragMovePinManager')(observer(class Draggable extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            x: 0,
            y: 0,
            dragging: false,
            slackX: 0,
            slackY: 0
        };
        this.pinRef = React.createRef();
        this.store = this.props.dragMovePinManager.root;
        this.version = this.props.screen.slide.version;
    }

    componentDidMount () {
        this.isCanceled = false;
    }

    componentWillUnmount () {
        this.isCanceled = true;
    }

    onStart = (ev, data) => {
        ev.preventDefault();
        if (this.isCanceled) return;
        this.props.dragMovePinManager.start(null, this);
        this.dragDistance = startDistanceMeasure();
        this.setState({
            dragging: true
        });

        if (this.version === 2) {
            this.rotationProps = rotationProps(this.props.pin.box.editorBox.rotation);
        }
    };

    onDrag = (ev, data) => {
        ev.preventDefault();
        ev.stopPropagation();

        if (this.isCanceled || !this.state.dragging) return;
        if (this.props.pin.positionLocked) {
            this.props.screen.draggingALockedPin = true;
            return;
        };

        if (this.props.bounds && this.version !== 2) {
            // Get bound position. This will ceil/floor the x and y within the boundaries.
            const [newDeltaX, newDeltaY] = getBoundPosition(
                this.props.bounds, this.pinRef.current, data.deltaX, data.deltaY
            );
            // Update the event we fire to reflect what really happened after bounds took effect.
            data.x = data.x - data.deltaX + newDeltaX;
            data.y = data.y - data.deltaY + newDeltaY;
            data.deltaX = newDeltaX;
            data.deltaY = newDeltaY;
        }

        if (this.props.pin.isTourPin) {
            this.props.dragMovePinManager.drag({
                rect: this.pinRef.current.getBoundingClientRect(),
                pin: this.props.pin,
                data: this.props.data
            }, this);
        } else {
            this.props.dragMovePinManager.drag(null, this);
        }

        if (this.version === 2) {
            const scale = this.props.pin.box.editorBox.store.scaleFactor;
            const { sin, cos } = this.rotationProps;
            const dX = ((cos * data.deltaX) + (data.deltaY > 0 ? +1 : -1) * Math.abs(data.deltaY) * sin) / scale;
            const dY = ((cos * data.deltaY) + (data.deltaX > 0 ? -1 : +1) * Math.abs(data.deltaX) * sin) / scale;

            this.dragDistance.add(dX, dY);

            const newState = {
                x: this.state.x + dX,
                y: this.state.y + dY
            };
            this.setState(newState);
        } else {
            this.dragDistance.add(data.deltaX, data.deltaY);
            const newState = {
                x: this.state.x + data.deltaX,
                y: this.state.y + data.deltaY
            };
            this.setState(newState);
        }
    };

    onStop = (ev, data) => {
        ev.preventDefault();
        if (!this.state.dragging || this.isCanceled) return;
        this.props.dragMovePinManager.stop({
            rect: this.pinRef.current.getBoundingClientRect(),
            pin: this.props.pin,
            data: this.props.data,
            delta: { x: this.state.x, y: this.state.y }
        }, this);
        this.setState({ x: 0, y: 0, dragging: false });

        if (!this.dragDistance.isDrag()) {
            this.props.screen.setMode('editPin', { pin: this.props.pin });
        } else {
            // A dirty fix for open/close PinEdit/BoxSelection edit panels.
            // ev.stopPropagation() starts dragging the box
            ev.__vcsPinEvent = true;
        }
    };

    onDragCancel = () => {
        if (!this.state.dragging || this.isCanceled) return;
        this.setState({ x: 0, y: 0, dragging: false });
    };

    hide () {
        const display = this.pinRef.current && this.pinRef.current.style.display;
        if (this.pinRef.current) this.pinRef.current.style.display = 'none';
        return () => {
            if (this.pinRef.current) this.pinRef.current.style.display = display;
        };
    }

    render () {
        const { x, y, dragging } = this.state;
        const child = React.cloneElement(React.Children.only(this.props.children), {
            x,
            y,
            dragging,
            extraClassName: 'draggable',
            pinRef: this.pinRef
        });
        return (
            <DraggableCore
                onStart={this.onStart}
                onDrag={this.onDrag}
                onStop={this.onStop}
            >
                {child}
            </DraggableCore>
        );
    }
}));

Draggable.propTypes = {
    dragMovePinManager: PropTypes.object,
    pin: PropTypes.object,
    data: PropTypes.object,
    screen: PropTypes.object.isRequired,
    bounds: PropTypes.string
};
Draggable.defaultProps = {
    bounds: 'parent'
};

function withDraggable (PinComponent) {
    const Wrapper = observer(class Wrapper extends React.Component {
        render () {
            const { x, y } = get2DPosition(this.props.pin);
            const initPosition = this.props.pinPoint.viewPoint(x, y, this.props.container);
            return (
                <Draggable {...this.props}>
                    <PinComponent
                        {...this.props}
                        {...initPosition}
                        ref={this.ref}
                    />
                </Draggable>
            );
        }
    });

    Wrapper.displayName = `withDraggable(${PinComponent.displayName || PinComponent.name})`;
    Wrapper.propTypes = {
        ...PinComponent.propTypes,
        position: PropTypes.string,
        left: PropTypes.number,
        top: PropTypes.number,
        units: PropTypes.string
    };

    return React.forwardRef((props, ref) => {
        return <Wrapper {...props} ref={ref}/>;
    });
}

const DraggablePin = withDraggable(Pin);

DraggablePin.propTypes = {
    container: PropTypes.object.isRequired,
    pin: PropTypes.object.isRequired,
    pinPoint: PropTypes.object.isRequired,
    popoverActions: PropTypes.object,
    screen: PropTypes.object.isRequired,
    data: PropTypes.object
};
DraggablePin.defaultProps = {
    data: {
        source: TargetType.MEDIA
    }
};

const EditorPin = observer(props =>
    props.pin.isPendingTypePin
        ? (
            <PendingTypePin {...props} />
        )
        : (
            <DraggablePin
                isSelected={props.screen.mode.state?.pin?.id === props.pin.id}
                highlightTransparentPins={true}
                onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.screen.setMode('editPin', { pin: props.pin });
                }}
                {...props}
            />
        )
);

EditorPin.propTypes = {
    container: PropTypes.object.isRequired,
    pin: PropTypes.object.isRequired,
    pinPoint: PropTypes.object.isRequired,
    screen: PropTypes.object.isRequired
};

function withPinPoint (Component, pinPoint) {
    function Wrapper (props, ref) {
        return <Component {...props} pinPoint={pinPoint} ref={ref} />;
    }
    Wrapper.displayName = `withPinPoint(${Component.displayName || Component.name})`;
    return React.forwardRef(Wrapper);
}

const MapViewerPin = observer(({ pin, screen, pinPoint, container, currentPlace }) => {
    const openPanorama = () => {
        const item = screen.slide.places.find(p => pin.toPlaceId === p.id);
        screen.goto(item, item.props);
    };
    const { x, y } = pin.position;
    const position = pinPoint.viewPoint(x, y, container);
    const current = currentPlace;

    const isCurrent = current && pin.toPlaceId === current.id;

    return (
        <Pin
            pin={pin}
            className={isCurrent ? 'current-pin' : ''}
            onClick={isCurrent ? null : openPanorama}
            {...position}
        />
    );
});

MapViewerPin.propTypes = {
    screen: PropTypes.object.isRequired,
    pin: PropTypes.object.isRequired,
    pinPoint: PropTypes.object.isRequired,
    container: PropTypes.object.isRequired,
    currentPlace: PropTypes.object
};

export {
    Pin,
    EditorPin,
    withPinPoint,
    DraggablePin,
    withDraggable,
    MapViewerPin,
    getPinSize
};

/* eslint-enable */
