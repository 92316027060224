import { observable, action, runInAction, autorun, makeObservable } from 'mobx';
import { FileTypes } from '../../lib';

import api from '../api';
import Index from '../utils/object-index';

const mobileFilter = Settings.device.isMobile
    ? l => /mobile/.test(l)
    : l => !/mobile/.test(l);

const defaultVersionLabel = Settings.device.isMobile ? 'recommended' : 'original';

const assetBuilder = {
    build (versionLabel, data) {
        const original = data.file_version;
        const thumbnail = original && original.thumbnail ? original.thumbnail : null;

        const asset = new Asset({
            id: data.uuid,
            uuid: data.uuid,
            presentation: data.presentation,
            filename: data.filename,
            state: data.state,
            dateCreated: data.date_created,
            dateModified: data.date_modified,
            params: data.params,
            alternativeVersions: this.buildAlternativeVersions([
                ...data.alternative_versions,
                {
                    labels: ['original'],
                    file_version: original,
                    params: {}
                }
            ], thumbnail),
            version: `${data.date_modified}/${data.sequence_number || -1}`
        });

        asset.fileVersion = (
            asset.alternativeVersions[versionLabel]
                ? asset.alternativeVersions[versionLabel].fileVersion
                : asset.alternativeVersions[defaultVersionLabel]
                    ? asset.alternativeVersions[defaultVersionLabel].fileVersion
                    : original
        );
        return asset;
    },
    buildAlternativeVersions (versions, thumbnail) {
        const getLabel = label => Settings.device.isMobile
            ? label.match(/.+?(?=.mobile)/)[0]
            : label;

        return versions.reduce((acc, v) => {
            for (const label of v.labels.filter(mobileFilter)) {
                acc[getLabel(label)] = {
                    fileVersion: {
                        ...v.file_version,
                        thumbnail
                    },
                    params: v.params
                };
            }
            return acc;
        }, {});
    }
};

class Asset {
    state;
    fileVersion;
    pdfToImageJobRunning;

    static create (versionLabel, data) {
        return assetBuilder.build(versionLabel, data);
    }

    constructor (data) {
        makeObservable(this, {
            state: observable,
            fileVersion: observable,
            pdfToImageJobRunning: observable,
            setPdfToImageJobRunning: action
        });
        Object.assign(this, data);
    }

    get title () {
        return this.filename.substr(0, this.filename.lastIndexOf('.'));
    }

    get fileType () {
        return FileTypes.fromResourceFileType(this.fileVersion?.file_type) ||
            FileTypes.get(this.filename, false);
    }

    get isUploading () {
        return this.state.match(/init|prepare/);
    }

    get isReady () {
        return this.state === 'ready';
    }

    get isPanorama () {
        return this.fileType.type === FileTypes.panorama;
    }

    /*
    You can break PDFs into images.
    The newly created images carry the source PDF document info in asset.params.pdf
    */
    get pdfResource () {
        return this.params.pdf?.document_uuid;
    }

    setPdfToImageJobRunning = (value) => {
        this.pdfToImageJobRunning = value;
    };

    updateFileVersion (versionLabel) {
        const newFileVersion = this.alternativeVersions[versionLabel]
            ? this.alternativeVersions[versionLabel].fileVersion
            : this.alternativeVersions[defaultVersionLabel]
                ? this.alternativeVersions[defaultVersionLabel].fileVersion
                : null;
        if (newFileVersion) {
            this.fileVersion = newFileVersion;
        }
    }
}

class AssetStore {
    assets = [];

    constructor (root) {
        makeObservable(this, {
            assets: observable,
            buildVersions: action,
            unshiftAsset: action,
            unshiftAssets: action,
            remove: action,
            createOrUpdateAssetFromData: action
        });

        this.root = root;
        this.index = new Index(this.assets);

        this.labelAutorun = autorun(() => this.buildVersions(this.root.versionLabel));
    }

    createAssetFromData (data) {
        return Asset.create(this.root.versionLabel, data);
    }

    buildVersions (versionLabel) {
        this.assets.map(a => a.updateFileVersion(versionLabel));
    }

    unshiftAsset (data) {
        const asset = this.createAssetFromData(data);
        this.assets.unshift(asset);
        return asset;
    }

    unshiftAssets (assets) {
        assets.forEach(asset => this.unshiftAsset(asset));
    }

    remove (assets) {
        return Promise.all(assets.map(asset =>
            api.asset.removeAsset(this.root.id, asset.uuid)
                .then(data => {
                    runInAction(() => {
                        this.assets.remove(asset);
                    });
                })
        ));
    }

    load () {
        const request = Settings.offlinePresentation
            ? Promise.resolve(Settings.offlinePresentation.assets)
            : api.asset.loadAssets(this.root.id);
        return request
            .then(assets => {
                runInAction(() => {
                    this.assets.replace(assets.map(a => this.createAssetFromData(a)));
                });
            });
    }

    getAsset (uuid) {
        return this.assets.find(asset => asset.uuid === uuid);
    }

    createOrUpdateAssetFromData (data) {
        const asset = this.createAssetFromData(data);
        const assetIndex = this.assets.findIndex(a => a.id === data.uuid);
        if (assetIndex === -1) {
            this.assets.unshift(asset);
        } else if (this.assets[assetIndex].version < asset.version) {
            this.assets[assetIndex] = asset;
        }
    }

    cleanUp () {
        this.index.cleanUp();
        this.labelAutorun();
    }
}

export {
    assetBuilder,
    Asset,
    AssetStore
};
