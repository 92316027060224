import React from 'react';
import PropTypes from 'prop-types';

import ShapeWithIconEditor from './ShapeWithIcon/Editor';
import { Appearance as A } from './util';

function Appearance ({ model, onChange, ...rest }) {
    return (
        model.type === A.TYPES.SHAPE_WITH_ICON
            ? <ShapeWithIconEditor model={model} onChange={onChange} {...rest} />
            : null
    );
}

Appearance.propTypes = {
    // Instance of a class, that inherits Appearance
    model: PropTypes.object,
    onChange: PropTypes.func
};

export default Appearance;
