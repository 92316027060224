import styled from 'styled-components';

export const Wrapper = styled.div`
    
`;

export const RadioButtons = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 7px;
`;

export const TextLinkContents = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NewTabCheckbox = styled.div`
    display: flex;
    align-items: center;

    > label {
        margin-bottom: 0;
        margin-left: 7px;
        cursor: pointer;
        color: var(--text-primary-color);
    }
`;

export const SlideSelectItem = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
`;
