import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import Cell from './Cell';
import Entity from './Entity';
import S from './styles';
import withResponsiveListeners from '../../../../utils/Responsive';
import ViewerContext from './ViewerContext';

const DeviceSocket = observer(
    ({ item, skipColumns, identation }) => {
        const { store } = React.useContext(ViewerContext);
        const { columns } = store;

        return (
            <Entity
                item={item}
                identation={identation}
                skipColumns={skipColumns}
                mobilePreview={
                    <S.TogglerDetails columns={2}>
                        <Cell
                            key={`${item.key}#socketName`}
                            cell={columns.map.get('socketName')}
                            item={item}
                            isPreview={true}
                        />
                        <Cell
                            key={`${item.key}#connectedTo`}
                            cell={columns.map.get('connectedTo')}
                            item={item}
                            isPreview={true}
                        />
                    </S.TogglerDetails>
                }
            />
        );
    }
);

DeviceSocket.propTypes = {
    item: PropTypes.object,
    skipColumns: PropTypes.array,
    identation: PropTypes.number
};

export default withResponsiveListeners(DeviceSocket);
