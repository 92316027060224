import { RGBColor } from '@vectorworks/vcs-ui/dist/lib/ColorPicker/utils';

const mixColors = (color1, color2) => {
    let color = [RGBColor.guess(color1), RGBColor.guess(color2)];

    let C = 0;
    let M = 0;
    let Y = 0;
    let K = 0;

    for (let i = 0; i < color.length; i++) {
        color[i] = color[i].toCMYK();
        C += color[i].c;
        M += color[i].m;
        Y += color[i].y;
        K += color[i].k;
    }
    C = C / color.length;
    M = M / color.length;
    Y = Y / color.length;
    K = K / color.length;

    color = {
        c: C,
        m: M,
        y: Y,
        k: K
    };
    return RGBColor.fromCMYK(color);
};

export {
    mixColors
};
