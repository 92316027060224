import styled, { css } from 'styled-components';

import { Select } from '@vectorworks/vcs-ui/dist/lib/Select/Select';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { Input } from '@vectorworks/vcs-ui/dist/lib/Input/Text/Input';
import { Menu } from '@vectorworks/vcs-ui/dist/lib/Menu/Menu';
import { RadioButton } from '@vectorworks/vcs-ui/dist/lib/Radio/RadioButton';

const Filter = styled.div`
    display: flex;
`;

const FilterIcon = styled(Icon)`
    margin: 10px 0;
    width: 32px;
    heigh: 32px;
    font-size: 16px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const S = {
    Trigger: styled.div`
        height: 40px;
        min-width: 100px;
        padding: 0 10px;
        margin: 0 5px;

        color: var(--text-secondary-color);
        border: 1px solid var(--input-border-color);
        border-radius: 2px;

        display: inline-flex;
        align-items: center;
        justify-content: space-between;

        cursor: pointer;
    `,
    Menu: styled(Menu)`
        ul {
            display: flex;
            flex-direction: column;
        }
    `,
    ScrollableMenu: styled(Menu)`
        .MuiPaper-root {
            overflow: hidden auto !important;
        }
    `,
    Select: styled(Select)`
        height: 40px;
        width: 100%;
        
        border: 1px solid var(--input-border-color);
    `,
    Input: styled(Input)`
        margin: 5px 10px;
        padding-left: 16px;
        width: 170px;

        background: transparent;
        border: 1px solid var(--input-border-color);
    `,
    Sorting: styled.div`
        height: 17px;
        width: 17px;
        margin: 0 5px;

        color: var(--text-secondary-color);
        border: 1px solid var(--text-primary-color);
        border-radius: 3px;

        display: flex;
        align-items: center;
        justify-content: center;
    `,
    FiltersMobile: styled.div`
        width: 100%;
        padding: 10px 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-items: center;

        border-bottom: 1px solid var(--border-color);
    `,
    FiltersDesktop: styled.div`
        padding: 10px 0;

        display: flex;
        position: sticky;
        left: 0;
    `,
    LgFilter: styled(Filter)`
        margin: 0 15px 0 0;
        width: 132px;
    `,
    MobileFilter: styled(Filter)`
        width: 64px;

        ${({ showBorder }) => showBorder && css`
            margin: -1px;
            border: 1px solid var(--grey3);
            border-radius: 5px;
        `}
    `,
    SearchIcon: styled(FilterIcon)`
        ${({ isMobile }) => !isMobile && css`
            font-size: x-large;
        `}

        ${({ open }) => open && ` 
            margin: -1px;
            border: 1px solid var(--border-color);
            background-color: var(--vcs-color--cyan);
        `}
    `,
    SearchBox: styled(Input)`
        border-radius: 5px;

        ${({ isMobile }) => !isMobile && css`
            padding: 0 27px 0 35px;
            margin: 0 0 0 -35px;
        `}
    `,
    FilterIcon,
    ClearIcon: styled(Icon)`
        width: 32px;
        heigh: 32px;
        border-radius: 5px;

        ${({ isMobile }) => isMobile
        ? css`
            margin: 0 0 0 -1px;
            border-left: 1px solid var(--border-color);
        `
        : css`
            margin: 4px 0 0 0;
            cursor: pointer;
        `}
    `,
    ClearSearchIcon: styled(FilterIcon)`
        ${({ isMobile }) => isMobile
        ? css`
            margin: 0 0 0 -1px;
            border-left: 1px solid var(--border-color);
        `
        : css`
            margin: 4px 0 0 -32px;
            cursor: pointer;
            font-size: x-large;
        `}
    `,
    SearchMenu: styled(Menu)`
        display: flex;

        ul {
            padding: 0 !important;
            display: flex;
        }
    `,
    SubmitSearchIcon: styled(Icon)`
        margin: 0 8px 0 -32px;
        font-size: 16px;
    
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    FilterMenu: styled.div`
        padding: 24px 13px;
        width: 200px;

        display: flex;
        flex-direction: column;
        row-gap: 20px;
        background-color: white;
    `,
    RadioButtonContainer: styled.div`
        margin: 0 16px 0 0;
        width: 16px;
        height: 100%;
        display: flex;
        align-items: center;
    `,
    RadioButton: styled(RadioButton)`
        margin: 8px 0;
        width: 16px;
        height: 16px;
    `,
    RangeMenu: styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    `,
    RangeMenuRow: styled.div`
        display: flex;
    `,
    RangeMenuColumn: styled.div`
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    `,
    RangeMenuText: styled.div`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
    `,
    Option: styled.div`
        padding: 7px 0;
        display: grid;
        grid-template-columns: 0.1fr 1fr;
        align-items: center;
    `,
    SingleValueInput: styled(Input)`
        width: 100%;
    `,
    InputContainer: styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: end;
    `,
    RangeInput: styled(Input)`
        width: 100%;
    `,
    DistinctValuesMenu: styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
    `,
    DistinctValuesContainer: styled.div`
        margin: 10px 0;
        max-height: 240px;
        overflow-y: scroll;
    `,
    DistinctValue: styled.div`
        padding: 7px 0;
        display: flex;
        cursor: pointer;
        width: 85%;
        word-wrap: anywhere;
    `,
    DistinctValuesSearchBar: styled.div`
        position: relative;
        padding: 0;
        margin: 0;
        align-items: center;
    `,
    DistinctValuesSearchIcon: styled(Icon)`
        position: absolute;
        bottom: 5px;
        left: 8px;
    `,
    DistinctValuesInput: styled(Input)`
        padding: 0 0 0 25px;
        width: 100%;
        height: 25px;
        border-radius: 5px;
    `,
    ErrorContainer: styled.div`
        height: 1.25em;
        text-align: center;
    `,
    RemoveContainer: styled.div`
        display: flex;
        justify-content: end;
    `
};

export default S;
