import pubsub from './pubsub';

const keyEventsStack = [];

function handleKeyDown (event) {
    const keyEvents = (keyEventsStack[keyEventsStack.length - 1] || { events: {} }).events;

    for (const key in keyEvents) {
        if (keyEvents[key].predicate &&
                keyEvents[key].predicate(event) &&
                (event.target.getAttribute('type') || event.target.type) !== 'text' &&
                event.target.getAttribute('contenteditable') !== 'true') {
            event.preventDefault();
            keyEvents[key].handler(event);
            return;
        }
    }
}

class KeyEvents {
    constructor (events) {
        this.events = extendObject(null, events);
    }

    extend (newEvents) {
        this.events = extendObject(this.events, newEvents);
    }

    reduce () {
        this.events = Object.getPrototypeOf(this.events);
        if (!this.events) {
            throw new Error('KeyEvents#reduce called without extend');
        }
    }
}

function pushKeyEvents (events) {
    keyEventsStack.push(new KeyEvents(events));
    pubsub.publish('keyboard.shallow', peekKeyEvents());
}

function peekKeyEvents () {
    const keyEvents = keyEventsStack[keyEventsStack.length - 1];
    if (!keyEvents) {
        throw new Error('Key events stack is empty');
    }
    return keyEvents;
}

function popKeyEvents () {
    keyEventsStack.pop();
    if (!keyEventsStack.length) {
        $(document).unbind('keydown');
    } else {
        pubsub.publish('keyboard.shallow', peekKeyEvents());
    }
}

function init () {
    $(document).keydown(handleKeyDown);
}

function extendObject (x, y) {
    const z = Object.create(x);
    Object.assign(z, y);
    return z;
}

export default {
    init,
    pushKeyEvents,
    popKeyEvents,
    peekKeyEvents,
    handleKeyDown
};
