import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import Entity from './Entity';
import Cell from './Cell';
import S from './styles';

import withResponsiveListeners from '../../../../utils/Responsive';
import ViewerContext from './ViewerContext';

const Arrow = () => <S.Arrow>&#10140;</S.Arrow>;

const Circuit = observer(
    ({ item, skipColumns, identation }) => {
        const { store } = React.useContext(ViewerContext);
        const { columns } = store;

        return (
            <Entity
                item={item}
                identation={identation}
                skipColumns={skipColumns}
                mobilePreview={
                    <S.TogglerDetails columns={4}>
                        <Cell
                            key={`${item.key}#number`}
                            cell={columns.map.get('number')}
                            item={item}
                            isPreview={true}
                        />
                        <S.CellPair>
                            <Cell
                                key={`${item.key}#srcDeviceName`}
                                cell={columns.map.get('srcDeviceName')}
                                item={item}
                                isPreview={true}
                            />
                            <Cell
                                key={`${item.key}#srcSocketName`}
                                cell={columns.map.get('srcSocketName')}
                                item={item}
                                isPreview={true}
                            />
                        </S.CellPair>
                        <S.CellPair>
                            <Arrow />
                        </S.CellPair>
                        <S.CellPair>
                            <Cell
                                key={`${item.key}#dstDeviceName`}
                                cell={columns.map.get('dstDeviceName')}
                                item={item}
                                isPreview={true}
                            />
                            <Cell
                                key={`${item.key}#dstSocketName`}
                                cell={columns.map.get('dstSocketName')}
                                item={item}
                                isPreview={true}
                            />
                        </S.CellPair>
                    </S.TogglerDetails>
                }
            />
        );
    }
);

Circuit.propTypes = {
    item: PropTypes.object,
    skipColumns: PropTypes.array,
    identation: PropTypes.number
};

export default withResponsiveListeners(Circuit);
