import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import DialogFrame from '../../Components/Dialog/DialogFrame';
import PanoramaQualitySetting from '../common/PanoramaQualitySetting';

const PanoramaQualitySettingDialog = props => (
    <DialogFrame
        id='dialog-presentation-settings'
        title={gettext('Settings')}
        submitTitle={gettext('Done')}
        cancelTitle={gettext('Cancel')}
        dialog={props.dialog}
        useSimpleCallback={true}
        submitAnalytics={{
            'ga-action': 'Panorama_Quality_Setting_Dialog',
            'ga-label': 'Click_Submit'
        }}
        cancelAnalytics={{
            'ga-action': 'Panorama_Quality_Setting_Dialog',
            'ga-label': 'Click_Cancel'
        }}
        hideCancel={true}
    >
        <div style={{ padding: '0 15px' }}>
            <PanoramaQualitySetting store={props.store}/>
        </div>
    </DialogFrame>
);

PanoramaQualitySettingDialog.propTypes = {
    store: PropTypes.object,
    dialog: PropTypes.object
};

export default observer(PanoramaQualitySettingDialog);
