function copyToClipboard (text) {
    const trigger = document.createElement('div');
    /* eslint-disable */
    const clipboard = new Clipboard(trigger, {
        text: () => text
    });
    /* eslint-enable */
    const promise = new Promise((resolve, reject) => {
        clipboard.on('success', () => {
            clipboard.destroy();
            resolve(null);
        });
        clipboard.on('error', () => {
            clipboard.destroy();
            reject(null);
        });
    });
    $(trigger).click();
    return promise;
}

export default {
    copyToClipboard
};
