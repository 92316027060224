import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import RadioGroup from '../../Components/RadioGroup';
import storage from '../utils/storage';

const PanoramaQualityOptions = [
    { label: gettext('Best quality'), value: 'original' },
    { label: gettext('Balanced'), value: 'recommended' },
    { label: gettext('Best performance'), value: 'fast' }
];

class PresentationQualitySettings extends React.Component {
    updateVersionLabel = e => {
        this.props.store.updateVersionLabel(e.target.value);
        storage.set('preferences.presentation.tour.quality', e.target.value);
    };

    render () {
        return (
            <React.Fragment>
                <div className='dialog-row quality-settings'>
                    <div>
                        <div className='title'>
                            {gettext('Tour quality/performance')}
                        </div>
                        <span className='di-input-text'>
                            {gettext('Allows you to modify the image quality in order to achieve the desired performance.')}
                        </span>
                        <RadioGroup
                            options={PanoramaQualityOptions}
                            value={this.props.store.versionLabel}
                            onChange={this.updateVersionLabel}
                            what='panorama-quality'
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

PresentationQualitySettings.propTypes = {
    store: PropTypes.object
};

export default observer(PresentationQualitySettings);
