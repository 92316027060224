import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

import fullScreen from '../../base/fullscreen';
import ThumbnailDecider from '../common/ThumbnailDecider';

const Slide = ({ slide, store }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const startFromSlide = () => {
        const url = location.pathname;
        navigate(`/${store.id}/${slide.id}/?next=${url}`);

        const preview = document.getElementById('presentation-fs-preview');
        fullScreen.toggle(preview);
    };

    const commentsCount = store.comments.comments
        .filter(c => c.resource.object.id === parseInt(slide.id)).length;

    return (
        <div className='slide--thumbs-wrapper'>
            <div
                className='asset land-slide--thumb'
                data-what='slide'
                data-which={`${slide.type}:${slide.title}`}
            >
                <div className='file-slide--thumb' onClick={startFromSlide}>
                    <div className='presentation-asset-img'>
                        <div className='presentation-layout-slide--thumb'>
                            <ThumbnailDecider
                                store={store}
                                slide={slide}
                            />
                        </div>
                    </div>
                </div>

                <div className='text-prim slide-info--preview'>
                    <div className='attr attr-primary name' title={slide.title}>
                        <span onClick={startFromSlide}>{slide.title}</span>
                    </div>
                    {
                        commentsCount > 0 &&
                        <span className='comments-count'>
                            <span className='icon icon-comment-indicator-16' />
                            {commentsCount}
                        </span>
                    }
                </div>
            </div>
        </div>
    );
};

Slide.propTypes = {
    store: PropTypes.object.isRequired,
    slide: PropTypes.object
};

export default observer(Slide);
