import loadScript from './load-script';

export function loadPdfJs () {
    return window._pdfjsLib
        ? Promise.resolve(window._pdfjsLib)
        : loadScript(Settings.PDFJS.libUrl, 'pdfjs-script')
            .then(() => {
                window.pdfjsLib.workerSrc = Settings.PDFJS.workerUrl;
                window.pdfjsLib.cMapUrl = Settings.PDFJS.cmapsUrl;
                window.pdfjsLib.cMapPacked = true;
                window.pdfjsLib.imageResourcesPath = Settings.PDFJS.imageResourcesPath;

                /* Importing pdfjs sets `window.pdfjsLib`, which might conflict in version with @vectorworks/vcs-pdf-viewer (.vcdoc file viewer),
                which also directy imports pdfjs, so it also sets `window.pdfjsLib`.
                Rename this version and resolve to the renamed value if already loaded to avoid conflicts. */
                window._pdfjsLib = window.pdfjsLib;
                window.pdfjsLib = undefined;
                return loadScript(Settings.PDFJS.viewerUrl, 'pdfjs-viewer-script');
            })
            .then(() => window._pdfjsLib);
}
