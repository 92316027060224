import $ from 'jquery';

const debug = false;

const READ_CHUNK_SIZE = 4096;
const APP1_SEGMENT_MARKER = 225;
const APP_SEGMANT_MARKER_MIN = 224;
const APP_SEGMANT_MARKER_MAX = 239;

const detectPanoramaImage = callback => imageBlob => inspectImageBlob(
    imageBlob, (...args) => checkForPanoramaImage(callback, ...args)
);

function inspectImageBlob (blob, onApp1Segment, offset = 0, length = READ_CHUNK_SIZE) {
    const reader = new window.FileReader();
    reader.addEventListener('loadend', function (e) {
        readImageData(e.target.result, blob, offset, onApp1Segment, offset === 0);
    });

    reader.readAsArrayBuffer(blob.slice(offset, offset + length));
}

function readImageData (slice, imageBlob, blobOffset, onApp1Segment, startOfImage = false) {
    const dataView = new DataView(slice);
    const length = slice.byteLength;

    if (debug) console.log(`Got blob slice of length ${slice.byteLength}`);

    if (startOfImage && (dataView.getUint8(0) !== 0xFF || dataView.getUint8(1) !== 0xD8)) {
        if (debug) console.log('Not a valid JPEG');
        return onApp1Segment(false); // not a valid jpeg
    }

    let offset = startOfImage ? 2 : 0;
    while (offset < length) {
        if (dataView.getUint8(offset) !== 0xFF) {
            if (debug) console.log(`Not a valid marker at offset ${offset}, found: ${dataView.getUint8(offset)}`);
            return onApp1Segment(false); // not a valid marker, something is wrong
        }

        const marker = dataView.getUint8(offset + 1);
        if (debug) console.log(`Found marker: ${marker}`);
        if (APP_SEGMANT_MARKER_MIN <= marker && marker <= APP_SEGMANT_MARKER_MAX) {
            const segmentStart = offset + 4;
            const segmentSize = dataView.getUint16(offset + 2) - 2;
            const segmentEnd = segmentStart + segmentSize;
            if (length < segmentEnd) {
                // Part of the segment is not read; read the whole segment
                return inspectImageBlob(imageBlob, onApp1Segment, blobOffset + offset, segmentSize + 4);
            } else {
                if (marker === APP1_SEGMENT_MARKER) {
                    if (debug) console.log('Found APP1 segment marker');
                    if (onApp1Segment(dataView, segmentStart, segmentSize)) return true;
                }
                offset += 2 + dataView.getUint16(offset + 2);
            }
        } else {
            if (debug) console.log('Found not APPn marker; assume that threre are no more APPn markers');
            return onApp1Segment(false);
        }
    }
    return inspectImageBlob(imageBlob, onApp1Segment, blobOffset + offset, length);
}

function checkForPanoramaImage (callback, dataView, segmentStart, segmentSize) {
    if (!dataView) {
        callback(false);
        return true;
    }

    let offset = segmentStart;
    const segmentEnd = segmentStart + segmentSize;
    while (offset < (segmentEnd - 4)) {
        if (getStringFromDB(dataView, offset, 4) === 'http') {
            const xmpString = getStringFromDB(dataView, offset, segmentEnd - offset);
            const { isPanorama } = inspectXMP(xmpString);
            if (isPanorama) {
                callback(true);
                return true;
            } else {
                return false;
            }
        } else {
            offset++;
        }
    }
    return false;
}

function inspectXMP (data) {
    const startXML = data.search('<x:xmpmeta');
    if (startXML > 0) {
        const endXML = data.search('</x:xmpmeta>') + 12;
        const xmpData = data.substring(startXML, endXML);
        const parsedXmpData = $.parseXML(xmpData);
        const xmp = $(parsedXmpData);
        let projectionType = xmp.find('GPano\\:ProjectionType');
        if (!projectionType.length) {
            projectionType = xmp.find('ProjectionType');
        }
        const projectionTypeAttr = xmp.find('rdf\\:Description').attr('GPano:ProjectionType') ||
            xmp.find('Description').attr('GPano:ProjectionType');
        const isPanorama = projectionType.text() === 'equirectangular' ||
            projectionTypeAttr === 'equirectangular';
        return { isPanorama };
    } else {
        return {};
    }
}

function getStringFromDB (buffer, start, length) {
    let outstr = '';
    for (let n = start; n < start + length; n++) {
        outstr += String.fromCharCode(buffer.getUint8(n));
    }
    return outstr;
}

export {
    detectPanoramaImage
};
