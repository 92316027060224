import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Dialog } from '@vectorworks/vcs-ui/dist/lib/Dialog/Dialog';
import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';
import { Select } from '@vectorworks/vcs-ui/dist/lib/Select/Select';
import { MenuItem } from '@mui/material';

import { UNITS } from './units';
import { COLUMNS, FILTER_COMBINATIONS } from './utils';
import withResponsiveListeners from '../../../../utils/Responsive';

const S = {
    DialogBody: styled.div`
        padding: 7px 0;
        display: flex;
        flex-direction: column;
    `,
    SettingBlock: styled.div`
        padding: 10px 0;
        display: block;

        ${({ isMobile }) => !isMobile && css`
            column-count: 2;
        `}
    `,
    SettingSelector: styled.div`
        margin: 0 auto;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        break-inside: avoid;
    `,
    Label: styled.div`
        width: 120px;
        color: var(--text-secondary-color);
    `
};

const FilterSetting = ({ filterCombination, setCombinationState }) => {
    const handleChange = e => {
        const newCombination = Object.values(FILTER_COMBINATIONS).find(c => c.query === e.target.value);
        setCombinationState(newCombination);
    };

    return (
        <S.SettingSelector>
            <S.Label>
                { gettext('Filter Combination') }
            </S.Label>
            <Select
                key='select-filter-combination'
                className='ccad-settings-select'
                onChange={handleChange}
                value={filterCombination.query}
                data-what='ccad-settings-select'
                data-which='filter-combination'
            >
                {
                    Object.values(FILTER_COMBINATIONS)
                        .map(combination =>
                            <MenuItem
                                key={`combination-${combination.query}`}
                                value={combination.query}
                                data-what={`ccad-combination-item-${combination.query}`}
                                data-which={`${combination.query}`}
                            >
                                { combination.title }
                            </MenuItem>
                        )
                }
            </Select>
        </S.SettingSelector>
    );
};

FilterSetting.propTypes = {
    filterCombination: PropTypes.object,
    setCombinationState: PropTypes.func
};

const UnitSetting = ({ column, unit, unitsStore, onChange }) => {
    const type = unitsStore.index[column].unitType;
    const handleChange = e => onChange(unitsStore.index[column], e.target.value);

    return (
        <S.SettingSelector>
            <S.Label>{ COLUMNS[column].title }</S.Label>

            <Select
                key={`select-${column}`}
                className='ccad-settings-select'
                onChange={handleChange}
                value={unit.id}
                data-what='ccad-settings-select'
                data-which={`${column}`}
            >
                {
                    Object.keys(UNITS[type])
                        .map(id =>
                            <MenuItem
                                key={`units-${column}-${id}`}
                                value={id}
                                data-what={`ccad-units-item-${column}`}
                                data-which={`${id}`}
                            >
                                { UNITS[type][id].title }
                            </MenuItem>
                        )
                }
            </Select>
        </S.SettingSelector>
    );
};

UnitSetting.propTypes = {
    column: PropTypes.string,
    unit: PropTypes.object,
    unitsStore: PropTypes.object,
    onChange: PropTypes.func
};

class SettingsDialog extends React.Component {
    constructor (props) {
        super(props);

        const { index } = props.dialog.params.store.unitsStore;
        const { filterCombination } = props.dialog.params.store;

        this.state = {
            units: Object.keys(index)
                .reduce((acc, col) =>
                    ({
                        ...acc,
                        [col]: index[col].unit
                    }),
                {}),
            filterCombination
        };
    }

    onSubmit = () => {
        this.props.dialog.close(this.state);
        this.props.dialog.params.store.unitsStore.setUnits(this.state.units);
        this.props.dialog.params.store.setFilterCombination(this.state.filterCombination);
        this.props.dialog.params.store.query();
    };

    onClose = () => {
        this.props.dialog.dismiss();
    };

    setUnitState = (unit, value) => {
        const newState = { ...this.state };
        newState.units[unit.column] = UNITS[unit.unitType][value];
        this.setState(newState);
    };

    setCombinationState = combination => {
        const newState = { ...this.state };
        newState.filterCombination = combination;
        this.setState(newState);
    };

    render () {
        const { unitsStore } = this.props.dialog.params.store;
        const screen = Settings.device.getScreenInfo();
        const isMobile = !['md', 'lg'].includes(screen.size);

        return (
            <Dialog
                data-what='ccad-dialog'
                data-which='settings'
            >
                <Dialog.Header onClose={this.onClose}>
                    <Dialog.Title>{ gettext('Settings') }</Dialog.Title>
                </Dialog.Header>

                <Dialog.Inner>
                    <S.DialogBody>
                        <S.SettingBlock isMobile={isMobile}>
                            <FilterSetting
                                filterCombination={this.state.filterCombination}
                                setCombinationState={this.setCombinationState}
                            />
                        </S.SettingBlock>
                        <S.SettingBlock isMobile={isMobile}>
                            {
                                Object.entries(this.state.units)
                                    .map(([col, unit]) =>
                                        <UnitSetting
                                            key={`unit-setting-${col}`}
                                            column={col}
                                            unit={unit}
                                            unitsStore={unitsStore}
                                            onChange={this.setUnitState}
                                        />
                                    )
                            }
                        </S.SettingBlock>
                    </S.DialogBody>
                </Dialog.Inner>

                <Dialog.Buttons>
                    <Button
                        onClick={this.onSubmit}
                        variant='primary'
                        data-what='ccad-units-btn'
                        data-which='ok'
                    >
                        { gettext('OK') }
                    </Button>
                    <Button
                        onClick={this.onClose}
                        variant='secondary'
                        data-what='ccad-units-btn'
                        data-which='cancel'
                    >
                        { gettext('Cancel') }
                    </Button>
                </Dialog.Buttons>
            </Dialog>
        );
    }
};

SettingsDialog.propTypes = {
    dialog: PropTypes.object
};

export default withResponsiveListeners(SettingsDialog);
