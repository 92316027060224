import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Drawer } from '@vectorworks/vcs-ui/dist/lib/Drawer/Drawer';

import CommentsList from '../../comments/CommentsList';
import NotAuthenticatedPanel from '../../comments/NotAuthenticatedPanel';
import PostCommentField from '../../comments/PostCommentField';
import NotAllowedCommentsPanel from '../../comments/NotAllowedCommentsPanel';
import CommentsHeader from '../../preview/Sections/Comments/CommentsHeader';
import styled from 'styled-components';

const S = {
    Drawer: styled(Drawer)`
        &.vcs-theme--dark > .MuiDrawer-paper {
            background-color: rgba(var(--bg-color-rgb), 0.5);
        }
        
        .MuiDrawer-paper {
            width: 320px;
            background-color: var(--bg-color);
        }
    `
};

class CommentsPanel extends React.Component {
    render () {
        const commentsStore = this.props.store.comments;

        return (
            <S.Drawer
                anchor='right'
                variant='persistent'
                open={this.props.store.ui.comments}
                className={[
                    (this.props.isInPreview ? 'vcs-theme--dark' : ''),
                    'ib-carousel'
                ].join(' ')}
            >
                <CommentsHeader
                    commentsStore={commentsStore}
                />
                {
                    commentsStore.canComment
                        ? this.props.store.allowComments
                            ? <PostCommentField commentsStore={commentsStore} />
                            : <NotAllowedCommentsPanel />
                        : <NotAuthenticatedPanel />
                }
                <CommentsList commentsStore={commentsStore} />
            </S.Drawer>
        );
    }
}
CommentsPanel.propTypes = {
    store: PropTypes.object.isRequired,
    isInPreview: PropTypes.bool
};

export default observer(CommentsPanel);
