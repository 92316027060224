import { observable, action, computed, makeObservable } from 'mobx';

import { ImageResource } from '../../FileViewers/loader';
import { pinPoint } from './point';

import ShapeWithIconModel from './PinEditors/Appearance/ShapeWithIcon/model';
import IconPicker from './PinEditors/Appearance/ShapeWithIcon/IconPicker';
import { RGBColor } from '@vectorworks/vcs-ui/dist/lib/ColorPicker/utils';
import { calculateHightlightSize } from '../../Pins/util';

function makeVersion () {
    return new Date().getTime().toString();
}

class SceneStore {
    screen = {
        image: new ImageResource(),
        place: {
            pins: []
        },
        placeProps: {
            cameraRotation: { x: 0, y: 0, z: 0 },
            version: makeVersion()
        }
    };

    selectedPin = undefined;
    imageShown = true;
    controller = {
        lookControls: true,
        vrMode: false
    };

    constructor (screen, options = {}) {
        makeObservable(this, {
            screen: observable,
            selectedPin: observable,
            imageShown: observable,
            controller: observable,
            reset: action,
            place: computed,
            image: computed,
            pins: computed,
            hoveredPinId: computed,
            cameraRotation: computed,
            setState: action,
            controlScene: action,
            precalcPinPositions: action,
            calcPinPosition: action
        });

        this.reset(screen, options);
        this.functions = {
            findPin: pinId => this.pins.find(p => p.id === pinId),
            getPinProp: (pinId, prop, defaultVal) => {
                const pin = this.functions.findPin(pinId);
                if (!pin) return defaultVal;
                const val = prop.split('.').reduce((obj, p) => obj[p], pin);
                return val !== undefined ? val : defaultVal;
            },
            isTransparentPin: pinId => {
                const pin = this.functions.findPin(pinId);
                return pin.isTransparent;
            },
            isHoveredPin: pinId => {
                return this.hoveredPinId === pinId;
            },
            isVisiblePin: pinId => {
                const pin = this.functions.findPin(pinId);
                return pin.visible;
            },
            getPinMaterialSource: pinId => {
                return `src: ${this.functions.getPinIconCanvasSelector(pinId)}; transparent: true`;
            },
            getPinIconCanvasSelector: pinId => {
                return `#pinIconCanvas--${pinId}`;
            },
            getPinSvgLoadParams: pinId => {
                const pin = this.functions.findPin(pinId);
                const model = pin.props;
                const img = ShapeWithIconModel.IMAGES.AUTO.type === model.image
                    ? IconPicker.getAutoImage(pin)
                    : model.image;

                return `
                    canvasSelector: ${this.functions.getPinIconCanvasSelector(pinId)};
                    image: ${img};
                    imageSize: ${pin.props.imageSize};
                    fillStyle: ${pin.props.imageColor.toCSS()}
                `;
            },
            getPinColor: pinId => {
                const pin = this.functions.findPin(pinId);
                const rgba = pin.props.color;
                const isShapeNone = pin.props.shape === ShapeWithIconModel.SHAPES.NONE.type;
                return `color: ${rgba.toCSS()}; transparent: true; opacity: ${isShapeNone ? 0 : rgba.a}; side: double;`;
            },
            getPinBorderColor: pinId => {
                const pin = this.functions.findPin(pinId);
                const rgba = pin.props.borderColor;
                const isShapeNone = pin.props.shape === ShapeWithIconModel.SHAPES.NONE.type;
                return `color: ${rgba.toCSS()}; transparent: true; opacity: ${isShapeNone ? 0 : rgba.a}; side: double;`;
            },
            getPinSize: pinId => this.functions.getPinProp(pinId, 'props.size', 3),
            getPinRotation: pinId => {
                const pin = this.functions.findPin(pinId);
                return `${pin.rotation.pitch} ${pin.rotation.yaw} ${pin.rotation.roll}`;
            },
            getPinLookAtCamera: pinId => {
                const pin = this.functions.findPin(pinId);
                return pin.rotation.lookAtCamera ? '[camera]' : undefined;
            },
            getPinImageSize: pinId => this.functions.getPinProp(pinId, 'props.imageSize', 0),
            getPinPosition: pinId => {
                const pin = this.functions.findPin(pinId);
                if (!pin) return { x: 0, y: 0, z: 0 };
                const relativePosition = pin.position; // this makes positon tracked
                (!pin.absolutePosition ||
                    pin.absolutePosition.relativePosition !== relativePosition
                ) &&
                    this.calcPinPosition(pin);
                return pin.absolutePosition;
            },
            calcPinSize: pinId => {
                const screen = Settings.device.getScreenInfo().size;

                const sizeFactor = { xs: 3.5, sm: 7, md: 6.5, lg: 8 };
                const minSize = { xs: 10, sm: 15, md: 20, lg: 20 };

                return (minSize[screen] + (sizeFactor[screen] * this.functions.getPinSize(pinId))) / 220;
            },
            calcImageSize: pinId => {
                const screen = Settings.device.getScreenInfo().size;

                const sizeFactor = { xs: 3.5, sm: 7, md: 6.5, lg: 8 };
                const minSize = { xs: 10, sm: 15, md: 20, lg: 20 };

                const half = (minSize[screen] + (sizeFactor[screen] * this.functions.getPinImageSize(pinId))) / 220;
                return half * 2;
            },
            calcPinBorder: pinId => {
                const screen = Settings.device.getScreenInfo().size;

                const sizeFactor = { xs: 3.5, sm: 7, md: 6.5, lg: 8 };

                return (sizeFactor[screen] * this.functions.getPinProp(pinId, 'props.borderWidth', 4)) / 1000;
            },
            getBorderAroundPin: (pinId, opacity = 0) => {
                const thickness = 0.02;
                const pin = this.functions.findPin(pinId);

                const height = calculateHightlightSize({
                    size: this.functions.calcPinSize(pinId) * 2,
                    borderWidth: this.functions.calcPinBorder(pinId),
                    imageSize: this.functions.calcImageSize(pinId),
                    isShapeNone: pin.props.shape === ShapeWithIconModel.SHAPES.NONE.type,
                    isImageNone: pin.props.image === ShapeWithIconModel.IMAGES.NONE.type
                });

                const material = `color: ${RGBColor.cyan.toCSS()}; transparent: true; opacity: ${opacity}; side: double;`;

                return `
                    <a-entity class=${this.inEditor ? 'pin__highlight' : ''}>
                        <a-plane
                            position='-${height / 2 + thickness / 2} 0 0'
                            width='${thickness}'
                            height='${height}'
                            material='${material}'
                        ></a-plane>
                        <a-plane
                            position='${height / 2 + thickness / 2} 0 0'
                            width='${thickness}'
                            height='${height}'
                            material='${material}'
                        ></a-plane>
                        <a-plane
                            position='0 ${height / 2 + thickness / 2} 0'
                            width='${height + 2 * thickness}'
                            height='${thickness}'
                            material='${material}'
                        ></a-plane>
                        <a-plane
                            position='0 -${height / 2 + thickness / 2} 0'
                            width='${height + 2 * thickness}'
                            height='${thickness}'
                            material='${material}'
                        ></a-plane>
                    </a-entity>
                `;
            },
            getInvisiblePinTemplate: pinId => {
                return this.inEditor && (
                    this.functions.isTransparentPin(pinId) ||
                    this.functions.isHoveredPin(pinId) ||
                    !this.functions.isVisiblePin(pinId)
                )
                    ? this.functions.getBorderAroundPin(pinId, 0.5)
                    : '';
            },
            getPinHoverTemplate: pinId => {
                return pinId === this.selectedPin
                    ? this.functions.getInvisiblePinTemplate(pinId)
                    : this.functions.getBorderAroundPin(pinId);
            },
            getPinSelectionTemplate: pinId => {
                return pinId === this.selectedPin
                    ? this.functions.getBorderAroundPin(pinId, 1)
                    : this.functions.getInvisiblePinTemplate(pinId);
            }
        };
    }

    reset (screen, options = {}) {
        this.controller = {
            lookControls: true,
            vrMode: false
        };
        this.screen = screen;
        Object.assign(this, options);
    }

    get place () {
        return this.screen.place;
    }

    get image () {
        return this.screen.image;
    }

    get pins () {
        return this.inEditor
            ? this.screen.place.pins
            : observable(this.screen.place.pins.filter(p => !!p.visible));
    }

    get hoveredPinId () {
        return this.screen.hoveredPin?.pin.id;
    }

    get cameraRotation () {
        const rotation = stringifyCoords(this.screen.placeProps.cameraRotation);
        return `${rotation}; ${makeVersion()}`;
    }

    setState (prop, value) {
        this[prop] = value;
    }

    controlScene (prop, value) {
        this.controller[prop] = value;
    }

    setScene (scene) {
        this.scene = scene;
    }

    clearScene () {
        this.scene = undefined;
    }

    precalcPinPositions = () => {
        if (!this.scene) return;
        if (this.scene.hasLoaded) {
            this.screen.slide.places.forEach(place => {
                place.pins.forEach(pin => this.calcPinPosition(pin));
            });
        } else {
            this.scene.addEventListener('loaded', this.precalcPinPositions);
            return;
        }
        this.scene.removeEventListener('loaded', this.precalcPinPositions);
    };

    calcPinPosition (pin) {
        pin.absolutePosition = this.scene
            ? {
                ...pinPoint.viewPoint(pin.position, this.scene),
                relativePosition: pin.position
            }
            : { x: 0, y: 0, z: 0 };
    }
}

function stringifyCoords (coords) {
    return coords
        ? window.AFRAME.utils.coordinates.stringify(coords)
        : '0 0 0';
}

export default SceneStore;
