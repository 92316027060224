import React from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer, Observer } from 'mobx-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { DragDropMonitor, DroppableTypes, PinDroppables } from './DragDropMonitor';
import DraggedPin from '../Pins/DraggedPin';
import AssetItem from '../Carousel/Items/AssetItem';
import TourTimelineItem from '../Carousel/Items/TourTimelineItem';

/**
Use fixed in pixel widths instead of % for Draggable carousel items
because when they are dragged, they become fixed position and the snapshot breaks.
 */
export const getItemStyle = (vendorStyles, carouselItemType, snapshot) => {
    const choices = {
        [AssetItem]: {
            flex: '50%'
        },
        [TourTimelineItem]: {
            flex: '100%'
        }
    };

    return {
        ...vendorStyles,
        ...(choices[carouselItemType] || {})
    };
};

const DraggableItem = observer((props) => {
    const isDragging = computed(() => {
        const { dragDropMonitor } = props;
        const draggedItem = dragDropMonitor.draggedItem;
        return draggedItem &&
            props.item.id === draggedItem.id &&
            PinDroppables.isDraggingOver(dragDropMonitor);
    }).get();

    const CarouselItem = props.carouselItemType;

    return (
        <CarouselItem
            isDragging={isDragging}
            {...props}
        >
            <DraggedPin
                dragDropMonitor={props.dragDropMonitor}
                isDragging={isDragging}
            />
        </CarouselItem>
    );
});

DraggableItem.propTypes = {
    dragDropMonitor: PropTypes.instanceOf(DragDropMonitor),
    carouselItemType: PropTypes.oneOfType(TourTimelineItem, AssetItem),
    item: PropTypes.object
};

// TODO: handle preparing asset
class DraggableItems extends React.Component {
    constructor (props) {
        super(props);
        this.registerDroppable();
    }

    registerDroppable () {
        this.droppable = DroppableTypes.ITEMS.register(this.props.dragDropMonitor);
    }

    componentDidUpdate (prevProps) {
        if (this.props.dragDropMonitor !== prevProps.dragDropMonitor) {
            this.registerDroppable();
        }
    }

    onDragStart = ev => {
        const item = this.props.items[ev.source.index];
        this.props.dragDropMonitor.onDragStart(item);
    };

    onDragUpdate = ev => {
        this.droppable.onDragOver(
            !!ev.destination && ev.destination.droppableId === 'item'
        );
    };

    onDragEnd = result => {
        if (!result.destination) {
            this.props.dragDropMonitor.onDragCancel();
            return;
        }
        this.props.dragDropMonitor.onDragEnd();
        this.props.dragDropMonitor.editor.slide.moveItem(
            result.source.index,
            result.destination.index
        );
    };

    render () {
        return (
            <DragDropContext
                onDragEnd={this.onDragEnd}
                onDragUpdate={this.onDragUpdate}
                onDragStart={this.onDragStart}
            >
                <Droppable
                    droppableId='item'
                    isDropDisabled={ this.props.isDropDisabled }
                >
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                flexShrink: 0
                            }}
                        >
                            <Observer>
                                {() => this.props.items.map((item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={`draggable#${item.id}`}
                                        index={index}
                                        isDragDisabled={this.props.disabledItemPredicate(item)}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                data-what={item.what}
                                                data-which={item.which}
                                                style={getItemStyle(
                                                    provided.draggableProps.style,
                                                    this.props.carouselItemType
                                                )}
                                            >
                                                <DraggableItem
                                                    key={item.id}
                                                    item={item}
                                                    items={this.props.items}
                                                    editor={this.props.editor}
                                                    dragDropMonitor={this.props.dragDropMonitor}
                                                    carouselItemType={this.props.carouselItemType}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </Observer>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

DraggableItems.propTypes = {
    items: PropTypes.array,
    editor: PropTypes.shape({
        slide: PropTypes.object
    }).isRequired,
    dragDropMonitor: PropTypes.instanceOf(DragDropMonitor).isRequired,
    carouselItemType: PropTypes.oneOfType([
        PropTypes.object, PropTypes.func
    ]).isRequired,
    isDropDisabled: PropTypes.bool,
    disabledItemPredicate: PropTypes.func
};

DraggableItems.defaultProps = {
    disabledItemPredicate: () => false
};

export { DraggableItem };
export default observer(DraggableItems);
