import { observable, action, makeObservable } from 'mobx';

import storage from '../utils/storage';
import { queryString } from '../../lib';
import { DefaultPresets } from '../FileViewers/Tour/PinEditors/Appearance/Presets';

class UIStore {
    static storedProps = ['assetLibraryVisible', 'carousel'];
    static mutuallyExcluded = ['comments', 'carousel', 'pinEditor', 'boxSelectionEditor'];

    assetLibraryVisible = true;
    pinEditor = false;
    boxSelectionEditor = false;
    carousel = false;
    comments = false;
    contents = false;
    map = false; // The map widget
    inMapEditor = false;

    constructor (root) {
        makeObservable(this, {
            assetLibraryVisible: observable,
            pinEditor: observable,
            boxSelectionEditor: observable,
            carousel: observable,
            comments: observable,
            contents: observable,
            map: observable,
            inMapEditor: observable,
            toggle: action
        });

        this.root = root;
        this.storageDisposer = storage.syncWith(this, 'ui', UIStore.storedProps);
        this.defaultPresets = new DefaultPresets();
        this.lastCarouselState = this.carousel;

        const search = queryString.parse(window.location.search);
        if (search.comments) {
            this.comments = true;
        }
    }

    get editModeSidePanel () {
        return this.pinEditor || this.boxSelectionEditor || this.carousel;
    }

    toggle (prop, value) {
        if (value === this[prop]) return;

        if (UIStore.mutuallyExcluded.includes(prop)) {
            UIStore.mutuallyExcluded
                .filter(p => p !== prop)
                .forEach(p => { this[p] = false; });
        }
        this[prop] = !this[prop];

        if (prop === 'carousel') {
            this.lastCarouselState = this.carousel;
        } else {
            // All panels are hidden
            if (!UIStore.mutuallyExcluded.find(i => !!this[i])) {
                /*
                    this.carousel = this.lastCarouselState;

                    This causes the following annoying behavior:
                    1. Close the Carousel.
                    2. Edit a box or a pin - edit panel opens
                    3. Any click away moves the slide. Any click for edit on the slide moves the slide also.
                    This way you start moving the slide on every click until you open back the Carousel.
                */
                this.carousel = true;
            }
        }
    }

    cleanUp () {
        this.storageDisposer();
    }
}

export default UIStore;
