import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import FullScreen from './Widgets/FullScreen';
import Navigation from './Widgets/Navigation';

const Controller = observer(props => {
    const onControllerClick = e => {
        e.stopPropagation();
        props.store.ui.controller.show();
    };

    const ftype = props.type || props.store.file?.file?.fileType.type;
    return (
        <div
            className={`controller ${props.extraClasses || ''} expanded ${ftype ? 'ft-' + ftype : ''}`}
            data-what='fileview-controller'
            ref={controller => { props.store.ui.controller.init(controller); }}
            onClick={onControllerClick}
            onFocus={props.store.ui.controller.show}
            onMouseMove={props.store.ui.controller.show}
        >
            <div className='controller-inner'>
                {props.children}
            </div>
        </div>
    );
});

Controller.propTypes = {
    store: PropTypes.object.isRequired,
    type: PropTypes.string,
    extraClasses: PropTypes.string
};

const DefaultController = observer(({ store }) => {
    return (
        <Controller store={store} type='default'>
            <div className='control-section left'/>

            <div className='control-section center'>
                <Navigation store={store} />
            </div>

            <div className='control-section right'>
                <FullScreen store={store} />
            </div>
        </Controller>
    );
});

DefaultController.propTypes = {
    store: PropTypes.object
};

export {
    Controller,
    DefaultController
};
