import React from 'react';
import PropTypes from 'prop-types';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';

import { Dialog } from '@vectorworks/vcs-ui/dist/lib/Dialog/Dialog';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';
import styled from 'styled-components';

const S = {
    Title: styled.div`
        display: flex;
        align-items: center;
    `
};

const CommentsHeader = ({ commentsStore }) => {
    const toggleResolved = () => {
        runInAction(() => {
            commentsStore.filters.resolved = !commentsStore.filters.resolved;
        });
    };

    const onClose = () => {
        commentsStore.root.root.ui.comments = false;
    };

    return (
        <Dialog.Header
            title={
                <S.Title>
                    <IconButton onClick={onClose}>
                        <Icon icon='close' />
                    </IconButton>
                    {`${gettext('Comments')} (${commentsStore.resourceComments.length})`}
                </S.Title>
            }
            isLoading={commentsStore.loading}
        >
            {commentsStore.allowComments && (
                <IconButton
                    title={
                        commentsStore.filters.resolved
                            ? gettext('Show resolved')
                            : gettext('Hide resolved')
                    }
                    toggled={!commentsStore.filters.resolved}
                    onClick={toggleResolved}
                    ga-action={commentsStore.GASettings.action}
                    ga-label='Toggle_Resolved'
                    data-what='comments-btn'
                    data-which='toggle-resolved'
                    style={{ marginLeft: 'auto' }}
                >
                    <Icon icon='status-ok-16' size='16px' />
                </IconButton>
            )}
        </Dialog.Header>
    );
};

export default observer(CommentsHeader);

CommentsHeader.propTypes = {
    commentsStore: PropTypes.object.isRequired
};
