import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Controller } from './Default';
import FullScreen from './Widgets/FullScreen';
import Navigation from './Widgets/Navigation';

const PanoramaController = ({ store, viewer }) =>
    <Controller store={store}>
        <div className='control-section left'>
            {
                viewer && viewer.sceneStore.controller.vrMode &&
                <span
                    className='icon icon-3d-stereo'
                    style={{ marginRight: '2px' }}
                    onClick={viewer.enterVR}
                />
            }
        </div>

        <div className='control-section center'>
            <Navigation store={store} />
        </div>

        <div className='control-section right'>
            <FullScreen store={store} />
        </div>
    </Controller>;

PanoramaController.propTypes = {
    store: PropTypes.object.isRequired,
    viewer: PropTypes.object
};

export default observer(PanoramaController);
