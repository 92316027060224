import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import DialogFrame from '../../../Components/Dialog/DialogFrame';
import CameraRotationReader from './CameraRotationReader';

class SelectCameraPosition extends React.Component {
    constructor (props) {
        super(props);
        this.rotation = props.initialCameraRotation;
    }

    onSubmit = () => {
        this.props.dialog.close(this.rotation);
    };

    onCancel = () => {
        this.props.dialog.dismiss();
    };

    setRotation = ev => {
        this.rotation = ev.detail;
    };

    render () {
        const { place, store, initialCameraRotation } = this.props;

        return (
            <DialogFrame
                title={gettext('Drag to set camera view')}
                submitTitle={gettext('Save')}
                cancelTitle={gettext('Cancel')}
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                dialog={this.props.dialog}
                className='camera-position-dialog'
            >
                <CameraRotationReader
                    editor={store.editor}
                    place={place}
                    onCameraRotate={this.setRotation}
                    initialCameraRotation={initialCameraRotation}
                />
            </DialogFrame>
        );
    }
}

SelectCameraPosition.propTypes = {
    dialog: PropTypes.any,
    place: PropTypes.object,
    store: PropTypes.object,
    initialCameraRotation: PropTypes.object
};

export default observer(SelectCameraPosition);
