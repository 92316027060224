import React, { Component } from 'react';

import wasm_heif from '@saschazar/wasm-heif';
import axios from 'axios';

import { observer } from 'mobx-react';

import { commonPropTypes } from './utils';

class HEICCanvas extends Component {
    name = 'HEICCanvas';
    task = {};

    loadImage = (imageUrl) => {
        this.props.store.startLoading();

        return axios.get(
            imageUrl,
            { responseType: 'arraybuffer' }
        ).catch(err => {
            if (err?.response.status === 404) {
                this.props.store.file.setNotFoundFile();
            }
        });
    };

    decodeImage = (data) => {
        const alpha = true;
        this.heifModule.free();
        return this.heifModule.decode(data, data.length, alpha);
    };

    displayImage = async (task) => {
        const rawData = await this.loadImage(task.imageUrl);
        const data = new Uint8Array(rawData);

        if (task.isCancelled) {
            return;
        }

        let decodedHeif;
        try {
            decodedHeif = this.decodeImage(data);
        } catch (error) {
            this.heifModule = await wasm_heif({ noInitialRun: true });
            decodedHeif = this.decodeImage(data);
        }

        if (task.isCancelled) {
            return;
        }

        const { height, width } = this.heifModule.dimensions();
        const stride = decodedHeif.length / (height * 4);
        const ctx = this.props.containerRef.current.getContext('2d');
        ctx.canvas.width = width;
        ctx.canvas.height = height;
        this.imageData = new window.ImageData(new Uint8ClampedArray(decodedHeif), stride, height);
        ctx.putImageData(this.imageData, 0, 0);
        this.props.containerRef.current.naturalHeight = height;
        this.props.onLoad();
    };

    clearImage = () => {
        this.props.resetZoom();
        this.props.resetOffset();
    };

    async componentDidMount () {
        this.task = {
            imageUrl: this.props.store.file.sourceUrl,
            isCancelled: false
        };
        this.heifModule = await wasm_heif({ noInitialRun: true });
        this.displayImage(this.task);
    }

    componentDidUpdate () {
        if (this.task.imageUrl !== this.props.store.file.sourceUrl) {
            this.task.isCancelled = true;
            this.task = {
                imageUrl: this.props.store.file.sourceUrl,
                isCancelled: false
            };
            this.clearImage();
            this.displayImage(this.task);
        }
    }

    async componentWillUnmount () {
        try {
            this.heifModule.free();
        } catch (error) {
            this.heifModule = await wasm_heif({ noInitialRun: true });
        }
        this.task.isCancelled = true;
    }

    render () {
        const transform = `scale3d(${this.props.zoom}, ${this.props.zoom}, 1) translate3d(${this.props.offset.x}px, ${this.props.offset.y}px, 0px)`;
        const canBeDragged = this.props.zoom === 1 ? '' : 'draggable';
        const isBeingDragged = this.props.draggable ? 'dragging' : '';

        return (
            <canvas
                className={`regular-image ${canBeDragged} ${isBeingDragged}`}
                style={{ transform, ...(this.props.store.loading ? { display: 'none' } : {}) }}
                onDragStart={this.props.preventDefault}
                onMouseMove={this.props.onMouseMove}
                onMouseDown={this.props.onMouseDown}
                ref={this.props.containerRef}
            />
        );
    }
}

export default observer(HEICCanvas);

HEICCanvas.propTypes = {
    ...commonPropTypes
};
