import React from 'react';

import { observer } from 'mobx-react';

const simulateEscapePress = () => {
    $('body').trigger({
        type: 'keydown',
        which: 27
    });
};

const ExitPreview = () => {
    const clickHandler = () => {
        simulateEscapePress();
    };

    return (
        <span
            className='ctrl-tool'
            ga-action='Presentation_Preview'
            ga-label='Click_Close'
            what='close-preview'
            onClick={clickHandler}
            title={gettext('Exit slideshow')}
        >
            <span className='icon icon-stop-presentation'/>
        </span>
    );
};

export default observer(ExitPreview);
