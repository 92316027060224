import { request } from '../base/axios';

const requestDeepLink = function (url = null) {
    return request({
        url: `${Settings.API_V1_ROOT}show_in_nomad/`,
        method: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: {
            link: url || window.location.href
        }
    });
};

export {
    requestDeepLink
};
