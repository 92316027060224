import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { PreviewSimpleText } from '../../common/SimpleText';
import { PreviewViewer as Viewer } from '../../FileViewers';
import Frame from './Frame';

const TitleAndMedia = props =>
    <React.Fragment>
        <Frame slide={props.slide}>
            <PreviewSimpleText
                board={props.slide}
                text={props.slide.text}
                screen={props.preview}
                previewContainer={props.previewContainer}
            />
            <div className='ib-file-container'>
                <Viewer
                    file={props.slide.media}
                    pins={props.slide.mediaPins}
                    screen={props.preview}
                    previewContainer={props.previewContainer}
                />
            </div>
        </Frame>
    </React.Fragment>;

TitleAndMedia.propTypes = {
    slide: PropTypes.object.isRequired,
    preview: PropTypes.object.isRequired,
    previewContainer: PropTypes.object
};

export default observer(TitleAndMedia);
