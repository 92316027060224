import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import { pubsub } from '../../../../base';
import withResponsiveListeners from '../../../../utils/Responsive';
import MobileContents from './MobileContents';
import LgContents from './LgContents';
import ViewerContext from './ViewerContext';
import S from './styles';

const Entity = observer(
    ({
        screen,
        item,
        identation,
        skipColumns,
        mobilePreview,
        isNested,
        selectable = true
    }) => {
        const { store, isMobile } = React.useContext(ViewerContext);
        const { columns } = store;
        const isMobileContentHidden = columns.active.length - columns.collapsable.length === 0;
        const ref = React.useRef(null);

        const [open, setOpen] = React.useState(false);
        const [selected, setSelected] = selectable
            ? React.useState(false)
            : [false, () => null];

        const toggleOpen = () => setOpen(!open);
        const toggleSelected = () => setSelected(!selected);
        const isHighlighted = () =>
            (isMobile && open && !isMobileContentHidden) ||
                (!isMobile && selected);
        const [willScrollTo, setWillScrollTo] = React.useState(false);

        React.useEffect(() => {
            pubsub.subscribe(Entity, 'fv.ccad.item.nav', ({ sourceItem, nav }) => {
                if (item.isNavigatedTo(sourceItem, nav.cells, nav.extraCondition)) {
                    setOpen(true);
                    setWillScrollTo(true);
                    setSelected(true);
                }
            });
            pubsub.subscribe(Entity, 'fv.ccad.go.to', (targetItem) => {
                if (item.isMe(targetItem)) {
                    setOpen(true);
                    setWillScrollTo(true);
                    setSelected(true);
                }
            });
            return () => pubsub.unsubscribeAll(Entity);
        }, []);

        React.useEffect(() => {
            if (willScrollTo && ref.current) {
                ref.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
                setWillScrollTo(false);
            }
        }, [ref?.current, willScrollTo]);

        return (
            <React.Fragment>
                <S.Row
                    ref={ref}
                    className={`${screen.size} ${isHighlighted() ? 'item-selected-light' : null}`}
                    onClick={toggleSelected}
                    expand={open}
                    identation={identation}
                    isMobile={isMobile}
                >
                    {
                        isMobile
                            ? <React.Fragment>
                                <S.Toggler
                                    className='cllps-toggle'
                                    expand={open}
                                    onClick={toggleOpen}
                                    isHidden={isMobileContentHidden}
                                />
                                {
                                    (!open || isMobileContentHidden) &&
                                    mobilePreview
                                }
                                {
                                    !isMobileContentHidden &&
                                    open &&
                                    <S.Collapse in={open}>
                                        <MobileContents
                                            item={item}
                                            skipColumns={skipColumns}
                                        />
                                    </S.Collapse>
                                }
                            </React.Fragment>
                            : <LgContents
                                item={item}
                                selected={selected}
                                skipColumns={skipColumns}
                                isNested={isNested}
                            />
                    }
                </S.Row>
            </React.Fragment>
        );
    }
);

Entity.propTypes = {
    item: PropTypes.object,
    identation: PropTypes.number,
    skipColumns: PropTypes.array,
    selectable: PropTypes.bool,
    mobilePreview: PropTypes.element,
    screen: PropTypes.object
};

export default withResponsiveListeners(Entity);
