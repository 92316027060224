import { observable, action, computed, makeObservable } from 'mobx';

import { saveMixin } from './save';
import { Appearance } from '../FileViewers/Tour/PinEditors/Appearance/util';
import ShapeWithIconModel from '../FileViewers/Tour/PinEditors/Appearance/ShapeWithIcon/model';

class BasePin {
    title;
    props;
    position;
    positionLocked;
    visible;
    dateTrashed;

    constructor (data = {}) {
        makeObservable(this, {
            title: observable,
            props: observable,
            position: observable,
            positionLocked: observable,
            visible: observable,
            dateTrashed: observable,
            updateTitle: action,
            updateProps: action,
            setPositionLocked: action,
            setVisible: action,
            move: action,
            isTransparent: computed
        });

        this.id = data.id;
        this.map = data.map;
        this.position = data.position;
        this.positionLocked = data.positionLocked;
        this.props = data.props;
        this.root = data.root;
        this.targetId = data.targetId;
        this.boxId = data.boxId;
        this.targetType = data.targetType;
        this.title = data.title;
        this.toPlaceId = data.toPlaceId;
        this.tour = data.tour;
        this.visible = data.visible !== false;
        this.dateTrashed = data.dateTrashed;

        this.props = Appearance.fromApi({
            type: Appearance.TYPES.SHAPE_WITH_ICON,
            ...data.props
        });
    }

    updateTitle (title) {
        this.title = title;
        this.scheduleSave(this.saveCommands.update, { title });
    }

    updateProps (updates = {}, options = {}) {
        const oldProps = { ...this.props };
        const props = {
            ...oldProps,
            ...updates
        };

        this.props = new ShapeWithIconModel(props);
        !options.noUndo && this.registerUndoUpdate(oldProps);
        this.scheduleSave(this.saveCommands.update, { props });
    }

    registerUndoUpdate (previous) {
        const current = { ...this.props };
        this.slide.undoStore?.add({
            undo: () => this.updateProps(previous),
            redo: () => this.updateProps(current)
        });
    }

    setPositionLocked (value) {
        this.positionLocked = value;
        this.scheduleSave(this.saveCommands.update, { position_locked: value });
    }

    setVisible (value) {
        this.visible = value;
        this.scheduleSave(this.saveCommands.update, { visible: value });
    }

    move ({ position }) {
        if (this.positionLocked) return;

        const { x: oldX, y: oldY } = this.position;
        const { x, y } = position;
        if (x !== oldX || y !== oldY) {
            this.position = position;
            this.scheduleSave(this.saveCommands.update, { position });
        }
    }

    get isTransparent () {
        const noContent =
            this.props.shape === ShapeWithIconModel.SHAPES.NONE.type ||
            this.props.color.a < 0.1;

        const noBorder =
            this.props.shape === ShapeWithIconModel.SHAPES.NONE.type ||
            this.props.borderColor.a < 0.1 ||
            this.props.borderWidth === 0;

        const noIcon =
            this.props.image === ShapeWithIconModel.IMAGES.NONE.type ||
            this.props.imageColor.a < 0.1;

        return noContent && noBorder && noIcon;
    }

    remove () {
        this.target.removePin(this);
    }
}

Object.assign(BasePin.prototype, saveMixin);

export default BasePin;
