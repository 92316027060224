import styled, { css } from 'styled-components';

const sideStripe = (activeColor = 'red') => {
    return css`
        
    &::before {
        z-index: 1;

        width: 4px;
        height: 100%;

        left: 0;
        top: 0;
        position: absolute;

        content: '';
        background-color: 'transparent';

        ${props => props.active && css`
            background-color: ${activeColor};
            border-top: 4px solid white;
            border-bottom: 4px solid white;
        `}
    }
    `;
};

export const S = {
    Wrapper: styled.div`
        position: relative;
        ${sideStripe()}
    `,
    sideStripe
};
