import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PinUI } from '../../../../../Pins/PinUI';
import { calculatePinSize2D } from '../../../../../Pins/util';
import ShapeWithIconModel from './model';

const S = {
    Pin: styled(PinUI)`
        flex-shrink: 0;
        ${({ scaleRatio }) => `transform: scale(${scaleRatio})`}
    `
};

function Preset ({ model: _model }) {
    const model = { ..._model };

    if (model.image === ShapeWithIconModel.IMAGES.AUTO.type) {
        model.image = ShapeWithIconModel.IMAGES.QUESTION.type;
    }

    return (
        <S.Pin
            className='pin'
            appearanceModel={model}
            scaleRatio={48 / calculatePinSize2D(model)}
        />
    );
}

Preset.propTypes = {
    model: PropTypes.object
};

export default Preset;
