import { buildAction } from '../base/action';
import { Dialog } from '../base/dialog';
import { getQRLocalStorageKey, qrMoreOnMobile } from './qr-code.vm';
import { isLinkView } from '../preview/link';
import { FileTypes } from '../lib/file';

const openDialog = (fileType, fileStore) => () => {
    return Dialog.open({
        component: 'dialog-qr-code',
        ctx: {
            ctx: {
                fileType,
                file: fileStore,
                url: window.location.href
            }
        }
    }).result;
};

const qrCodeAction = (fileType, fileStore) => {
    const action = openDialog(fileType, fileStore);

    return buildAction({
        action,
        properties: {
            multiple: false,
            isAllowed () {
                return isLinkView();
            },
            title: gettext('QR code'),
            icon: 'icon-qr-code',
            autoExecute: () => {
                !(window.localStorage.getItem(getQRLocalStorageKey()) === 'true') &&
                qrMoreOnMobile(fileType) &&
                action();
            },
            pingAnimator: () => qrMoreOnMobile(fileType) && (
                fileType === FileTypes.vgx && !Settings.NEW_FEATURES.isActive('vr-codes')
            ),
            GALabel: 'Click_QRCode'
        }
    }, 'qrCode', 'asset');
};

export { qrCodeAction };
