export function validatedRotation (degrees) {
    /*
        Handle negative and over 360 values.
        Returns a value between [0, 360).
    */
    return ((degrees % 360) + 360) % 360;
}

export function rotationProps (_degrees = 0) {
    const degrees = validatedRotation(_degrees);

    const rad = degrees * (Math.PI / 180);
    const sin = +Math.sin(rad).toFixed(15);
    const cos = +Math.cos(rad).toFixed(15);
    const tg = sin / cos;

    return {
        degrees, rad, sin, cos, tg
    };
}

export function rotatePointCounterClockwise (point, degrees) {
    const { sin, cos } = rotationProps(degrees);
    const { x, y } = point;

    return {
        x: x * cos - y * sin,
        y: x * sin + y * cos
    };
}

export function rotatedCoordinates (box, dropAt, boundingBox, pinWidth) {
    const { left, top } = dropAt;
    const { degrees } = rotationProps(box.rotation);

    let dropPoint = { x: 0, y: 0 };
    const pivotDegrees = degrees % 90;
    const { sin, cos } = rotationProps(pivotDegrees);
    let pivotPoint = { x: 0, y: 0 }; // The A1 point coordinates, relative to the 0

    if (degrees >= 0 && degrees < 90) {
        pivotPoint = {
            x: sin * box.height - left,
            y: top
        };
    } else if (degrees >= 90 && degrees < 180) {
        pivotPoint = {
            x: boundingBox.width - left,
            y: top - sin * box.height
        }; ;
    } else if (degrees >= 180 && degrees < 270) {
        pivotPoint = {
            x: box.width * cos - left,
            y: top - boundingBox.height
        };
    } else if (degrees >= 270 && degrees < 360) {
        pivotPoint = {
            x: -left,
            y: top - cos * box.width
        };
    }

    const pivotRotated = rotatePointCounterClockwise(pivotPoint, degrees);
    dropPoint = {
        x: -pivotRotated.x,
        y: pivotRotated.y
    };

    // Now, dropPoint is the center of the pin rotation plain
    const pinCenter = { x: pinWidth / 2, y: pinWidth / 2 };
    const pinCorrectedCenter = rotatePointCounterClockwise(pinCenter, 360 - degrees);
    const pinCorrection = {
        x: pinCenter.x - pinCorrectedCenter.x,
        y: pinCenter.y - pinCorrectedCenter.y
    };

    return {
        x: (dropPoint.x - pinCorrection.x) / box.width,
        y: (dropPoint.y - pinCorrection.y) / box.height
    };
}
