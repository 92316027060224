import { css, browser, queryString } from '../lib';
import fullScreen from './fullscreen';
import 'array-flat-polyfill';
import matchAll from 'string.prototype.matchall';

if (typeof String.prototype.matchAll === 'undefined') {
    matchAll.shim();
}
/* height: 60%; Add this to IE11 to make the dialog fixed height and avoid the buttons not sticking to the bottom.
    This solves the issue with the child not stretching to a min-height parent.
*/

/* display: block; - Help pages are taking width larger than they should */
if (browser.is.IE11()) {
    css.createCSSSelector('.topic-wrapper', 'display: block;');
    css.createCSSSelector('.slider', 'height: auto; background-color: transparent; border: none;');
}

/* width: 100% on fileview to show the viewers properly on fullscreen mode */
if (fullScreen.enabled) {
    fullScreen.on('change', () => {
        if (!fullScreen.fake) {
            if (fullScreen.isFullscreen) {
                css.createCSSSelector('.viewer-wrapper', 'width: 100%;');
                $('.viewer-wrapperr').css('background-color', '#000');
                $('.viewer-wrapper video').css('width', '100%').css('height', '100%');
            } else {
                $('.viewer-wrapper').css('width', '');
                $('.viewer-wrapper').css('background-color', '');
                $('.viewer-wrapper video').css('width', '').css('height', '');
            }
        }
        Settings.device.isFullscreen = fullScreen.isFullscreen;
    });
}

function handleSafariLoadBug () {
    /*
    This handles a bug in Safari.
    Sometimes Safari requests constants.js without cookies.
    As a result Settings.user is not authenticated.
    A refresh should fix the issue.
    To avoid infinite number of refreshes, q refresh query parameter is added.
    */
    const queryParams = queryString.parse(window.location.search);
    const cookieNames = window.document.cookie
        .split(';')
        .map(v => decodeURIComponent(v.split('=')[0].trim()));
    const reloadTimes = Number(queryParams.reload) || 0;

    const shouldReload = (
        (reloadTimes < 10) &&
        !window.Settings.user.isAuthenticated &&
        browser.is.Safari() &&
        cookieNames.includes(window.Settings.SSO_SESSIONID_MARKER)
    );
    if (shouldReload) {
        setTimeout(() => {
            const newUrl = queryString.buildUrl(window.location.href, { reload: reloadTimes + 1 });
            window.location.replace(newUrl);
        }, 1000);
    }
    return shouldReload;
}

export { handleSafariLoadBug };
