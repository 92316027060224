import React from 'react';
import { observer } from 'mobx-react';

class AfterParent extends React.Component {
    state = {
        mounted: false
    };

    componentDidMount () {
        this.setState({ mounted: true });
    }

    render () {
        return (
            <React.Fragment>
                {
                    this.state.mounted &&
                    this.props.children
                }
            </React.Fragment>
        );
    }
}

export default observer(AfterParent);
