import { Image } from '@vectorworks/vcs-boards';
import { saveMixin } from '../../save';
import { makeRef } from '../../../utils/react-utils';
import { boxMixin } from './mixins';

export class ImageBox {
    constructor ({ root, board, data, options = {} }) {
        this.editorBox = new Image({
            store: board.vcsBEStore,
            ...data.props,
            fitToSlide: options.fitToSlide
        });

        this.databaseId = data.id;
        this.root = root;
        this.board = board;
        this.assetId = data.asset;
        this.type = data.type;
        this.pinContainerRef = makeRef();
        this.editorBox.cloudBox = this;
        this.postCreationQueue = [];
        this.updateStack = [this.apiProps];
    }

    get isOptimistic () {
        return !this.databaseId;
    }

    get id () {
        return this.editorBox.id;
    }

    get asset () {
        return this.root.asset.index.map.get(this.assetId);
    }

    get pins () {
        return this.board.pins.filter(pin => pin.box === this);
    }

    get apiProps () {
        return this.editorBox.apiProps;
    }

    getComparableProps (props) {
        return props || this.apiProps;
    }

    get createData () {
        return {
            type: 'image',
            asset: this.asset.id,
            props: this.apiProps
        };
    }
}

Object.assign(ImageBox.prototype, saveMixin);
Object.assign(ImageBox.prototype, boxMixin);
