import styled from 'styled-components';
import { textOverflow } from '@vectorworks/vcs-ui/dist/lib/styles/common';

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 9999;
`;

/**
 * Carousel items are draggable over the canvas and they contain a child of `DraggedItem` instance.
 * To position that child in the background, this could be a useful wrapper.
 */
export const DraggedPinContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    // Otherwise it makes actions unclickable 
    z-index: -1;

    opacity: 1 !important;
`;

export const PinCreator = styled.div`
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
`;

export const LoadingOverlay = styled.div`
    position: absolute;
    inset: 0px;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const DragOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    background-color: rgba(var(--black-rgb), 0);
    opacity: 0;

    transition: background-color 0.4s ease;
    transition: opacity 0.4s ease;
`;

export const Thumbnail = styled.div.attrs(({ thumbnail, aspectRatio = 9 / 16 }) => ({
    style: {
        backgroundImage: `url(${thumbnail})`,
        paddingBottom: `${aspectRatio * 100}%`
    }
}))`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: auto;
    position: relative;
`;

export const DefaultIcon = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    > div {
        margin-top: -20px;
    }

    svg {
        width: 32px;
        height: 32px;
        fill: var(--text-primary-color);
    }
`;

export const InfoSection = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 5px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: rgba(var(--black-rgb), 0.3);
    color: white;

    div {
        ${textOverflow}
    }
`;
