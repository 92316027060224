/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { runInAction } from 'mobx';
import { Observer } from 'mobx-react';

import styled, { css } from 'styled-components';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { Menu } from '@vectorworks/vcs-ui/dist/lib/Menu/Menu';
import { MenuItem, MenuItemIcon } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';
import { Spinner } from '@vectorworks/vcs-ui/dist/lib/Loading/Spinner';

import {
    usePopupState,
    bindTrigger,
    bindMenu
} from 'material-ui-popup-state/hooks';
import DeleteCarouselItem from '../../Dialog/DeleteCarouselItem';
import SelectCameraPosition from '../../FileViewers/Tour/SelectCameraPosition';
import {
    Overlay,
    DraggedPinContainer,
    PinCreator,
    DragOverlay,
    Thumbnail,
    InfoSection,
    DefaultIcon
} from './common';

import { delay } from '../../../lib/utils';
import { PinRow } from './PinRow';

/**
 * Caveats of isDragged CSS:
 * 1. It relies that DraggedPinContainer has opacity: 1 !important, so it's not overriden by `> *` selector.
 * 2. It relies on DraggedPinContainer is a direct child of <S.Wrapper />.
 * 3. `background-color: transparent`:
 *     2.1 Items are not transparent while reordering vertically in carousel.
 *     2.2 The background gets transparent if you drag over the canvas - only DraggedPin should be visible
 */

export const S = {
    Wrapper: styled.div`
        margin: 7px;
        position: relative;
        background-color: var(--bg-color);

        box-shadow: var(--evelation-shadow-2);
        border-radius: 3px;
        overflow: hidden;
        cursor: pointer;
    
        ${({ isActive }) => isActive && css`
            border-radius: 0;
            outline: 4px solid var(--vcs-color--primary);
            transition: outline .4s ease;
        `}
    
        ${({ isDragging }) => isDragging && css`
            outline: 0;
            background-color: transparent;
            box-shadow: none;

            > * {
                opacity: ${isDragging ? 0 : 1};
            }
        `}

        &:hover {
            ${DragOverlay} {
                cursor: grab;
                opacity: 1;
                background-color: rgba(var(--black-rgb), 0.2);
            }
        }    
    `,
    MenuTrigger: styled.div`
        display: flex;
        align-items: center;

        padding: 2px;
        border-radius: 20px;
        background-color: rgba(var(--black-rgb), 0.5);
            
        color: white;
        font-size: 13px;

        > i {
            display: block;
        }
    `,
    PinsMenu: styled.div`
        > div {
            padding: 2px 8px;
        }
    `,
    ContextMenuActions: styled.div`
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
    `,
    HoverOverlay: styled(Overlay)`
        transition: opacity 0.4s ease;
        opacity: ${(props) => props.forceOpacity ? 1 : 0};

        :hover {
            opacity: 1;
        }
    `
};

// TODO: Loading asset UI.
function TourTimelineItem ({ item, editor, isDragging, children }) {
    const store = editor.root;
    const order = editor.slide.places.indexOf(item);

    const actionsMenuState = usePopupState({ variant: 'popper', popupId: `${item.asset.uuid}__actions` });
    const linksMenuState = usePopupState({ variant: 'popper', popupId: `${item.asset.uuid}__links` });

    const showPanorama = () => {
        item.asset.state === 'ready' &&
            editor.goto(item, item.props);
    };

    const removeItem = e => {
        e.stopPropagation();
        actionsMenuState.close(e);
        const dialog = editor.root.dialog;
        dialog.open({
            component: DeleteCarouselItem
        }).result.then(() => {
            editor.place.assetId === item.assetId && editor.gotoHome();
            item.remove();
        });
    };

    const setCameraPosition = e => {
        e.stopPropagation();
        actionsMenuState.close();
        const dialog = editor.root.dialog;
        editor.setMode('chooseCameraRotation');

        return delay().then(() => {
            dialog.open({
                component: SelectCameraPosition,
                context: {
                    store,
                    place: item,
                    initialCameraRotation: item.props.cameraRotation
                }
            }).result.then(cameraRotation => {
                runInAction(() => {
                    item.updateCameraRotation(cameraRotation);
                    editor.setMode('normal');
                });
            }).catch(() => {
                runInAction(() => {
                    editor.setMode('normal');
                });
            });
        });
    };

    const rotateCameraToPin = (pin) => {
        showPanorama();
        editor.rotateCameraToPin(pin);
        editor.hoverPin(pin);
    };

    const createTriggerProps = (menuState) => {
        const initital = bindTrigger(menuState);
        return {
            ...initital,
            onClick: e => {
                e.stopPropagation();
                initital.onClick(e);
            }
        };
    };

    const createMenuProps = (menuState) => {
        const initital = bindMenu(menuState);
        return {
            ...initital,
            onClose: e => {
                e.stopPropagation();
                editor.unhoverPin();
                initital.onClose(e);
            }
        };
    };

    const shouldForceOverlay = (
        editor.dragDropMonitor.draggedItem?.asset?.id === item.assetId ||
        linksMenuState.isOpen
    ) && !editor.dragDropMonitor.isDragging;

    return (
        <Observer>
            { () => (
                <S.Wrapper
                    onClick={showPanorama}
                    isActive={editor.place?.assetId === item.assetId}
                    isDragging={isDragging}
                >
                    {/* TODO:
                        This needs further investigation --
                        the pins menu in InfoSection is not clickable
                        because S.HoverOverlay is above it
                        and consuming pointer events.
                    */}
                    <S.HoverOverlay
                        forceOpacity={shouldForceOverlay}
                        style={{ zIndex: 1 }}
                    >
                        {/* Pin widget */}
                        <PinCreator>
                            <S.MenuTrigger>
                                <Icon
                                    key={`pin__asset--${item.asset.id}`}
                                    icon='pin-16'
                                    size='sm'
                                    style={{ padding: 2 }}
                                />
                            </S.MenuTrigger>
                        </PinCreator>

                        <DragOverlay>
                            {
                                item.asset.state === 'ready'
                                    ? <div>{gettext('Drag')}</div>
                                    : <Spinner color='primary' style={{ margin: 'auto' }} />
                            }
                        </DragOverlay>

                        {/* Context actions */}
                        <S.ContextMenuActions
                            title={gettext('Menu')}
                            {...createTriggerProps(actionsMenuState)}
                        >
                            <S.MenuTrigger>
                                <Icon icon='more-16' size='sm' />
                            </S.MenuTrigger>
                        </S.ContextMenuActions>
                    </S.HoverOverlay>

                    <Thumbnail thumbnail={item.asset.fileVersion.thumbnail}>
                        { !item.asset.fileVersion.thumbnail && (
                            <DefaultIcon>
                                <div dangerouslySetInnerHTML={{ __html: item.asset.fileType.defaultFileTypeIcon(item.asset.fileType) }} />
                            </DefaultIcon>
                        )}
                    </Thumbnail>

                    {
                        item.pins.length
                            ? (
                                <React.Fragment>
                                    <Menu
                                        {...createMenuProps(linksMenuState)}
                                        style={{ zIndex: 2 }}
                                    >
                                        { item.pins.map((pin, index) => (
                                            <PinRow
                                                key={index}
                                                pin={pin}
                                                rotateCameraToPin={rotateCameraToPin}
                                            />
                                        ))}
                                    </Menu>
                                </React.Fragment>
                            )
                            : null
                    }

                    <Menu {...createMenuProps(actionsMenuState)}>
                        <MenuItem onClick={setCameraPosition}>
                            <MenuItemIcon>
                                <Icon icon='camera-position' />
                            </MenuItemIcon>
                            {gettext('Set camera view...')}
                        </MenuItem>
                        <MenuItem onClick={removeItem}>
                            <MenuItemIcon>
                                <Icon icon='trash' />
                            </MenuItemIcon>
                            {gettext('Delete')}
                        </MenuItem>
                    </Menu>

                    <InfoSection style={{ zIndex: 2 }}>
                        {
                            item.pins.length > 0 &&
                            <S.PinsMenu
                                title={gettext('Pins')}
                                {...createTriggerProps(linksMenuState)}
                            >
                                <S.MenuTrigger>
                                    <Icon icon='pin-16' size='sm' />
                                    { item.pins.length }
                                </S.MenuTrigger>
                            </S.PinsMenu>
                        }
                        <div style={{ flex: 1, padding: '0 5px' }}>{item.asset.filename}</div>
                        {
                            order === 0
                                ? <Icon icon='house' size='sm' />
                                : <div style={{ fontSize: 10 }}>
                                    {`${order + 1} / ${editor.slide.places.length}`}
                                </div>
                        }
                    </InfoSection>
                    <DraggedPinContainer>
                        { children }
                    </DraggedPinContainer>
                </S.Wrapper>
            ) }
        </Observer>
    );
}

TourTimelineItem.propTypes = {
    editor: PropTypes.any,
    isDragging: PropTypes.bool,
    item: PropTypes.shape({
        props: PropTypes.shape({
            cameraRotation: PropTypes.any
        }),
        pins: PropTypes.arrayOf(PropTypes.any),
        asset: PropTypes.shape({

        })
    })
};

export default TourTimelineItem;
