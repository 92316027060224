import { action } from 'mobx';

import pushNotifier from '../../base/push-notifier';
import { ASSET_JOB_ACTIONS, GROUP_ASSET_JOB_ACTIONS } from '../constants';
import { boardBuilder } from '../models/board';

const assetActionResultEventHandlers = {
    [ASSET_JOB_ACTIONS.EXPLODE_PDF]: function (asset, result) {
        result.forEach(asset =>
            this.root.asset.createOrUpdateAssetFromData(asset)
        );
    },
    [ASSET_JOB_ACTIONS.ADD_RESULTS_TO_BOARD]: function (asset, result) {
        const pdfAsset = this.root.asset.getAsset(asset.uuid);
        pdfAsset && pdfAsset.setPdfToImageJobRunning(false);

        const board = this.root.slide.index.get(result.board_id.toString());
        result.items
            .forEach(data => {
                const item = board.items.find(i => i.assetId === data.asset);
                !item && board.pushItem(boardBuilder.createItem(board, data));
            });
    }
};

function handleActionResults (asset, job) {
    const priority = [
        ASSET_JOB_ACTIONS.EXPLODE_PDF,
        ASSET_JOB_ACTIONS.ADD_RESULTS_TO_BOARD
    ];

    Object
        .keys(job.action_results)
        .sort((action1, action2) => priority.indexOf(action1) - priority.indexOf(action2))
        .forEach(resultType => {
            const handler = assetActionResultEventHandlers[resultType];
            handler && handler.call(this, asset, job.action_results[resultType]);
        });
}

const eventHandlers = {
    asset: action(function (event, data) {
        const asset = {
            ...data.asset,
            sequence_number: data.sequence_number
        };
        this.root.asset.createOrUpdateAssetFromData(asset);
        if (data.job.state === 'done' && data.job.action_results) {
            handleActionResults.call(this, asset, data.job);
        }
    }),
    'asset-group': action(function (event, data) {
        if (data.job.state !== 'done') return;
        const actionResults = data.job.action_results;
        actionResults[GROUP_ASSET_JOB_ACTIONS.MAKE_SLIDE] &&
            actionResults[GROUP_ASSET_JOB_ACTIONS.MAKE_SLIDE].forEach(slide =>
                this.root.slide.createOrUpdateSlideFromData(slide)
            );
    }),
    board: action(function (event, data) {
        if (event !== 'board.thumbnail') return;
        const board = this.root.slide.index.get(data.board_id.toString());
        board && board.setThumbnail(data.thumbnail);
    })
};

class PushNotificationListener {
    constructor (root) {
        this.root = root;
    }

    init () {
        this.listener = pushNotifier.listen('iboards', 'v1');
        this.listener.subscribe(({ event, data }) => {
            this.handleEvent(event, data);
        });
    }

    cleanUp () {
        this.listener && this.listener.unsubscribe();
    }

    handleEvent (event, data) {
        const presentationId = data.presentation && data.presentation.uuid;
        const namespace = event.split('.')[0];
        this.root.id === presentationId &&
            eventHandlers[namespace] &&
            eventHandlers[namespace].call(this, event, data);
    }
}

export default PushNotificationListener;
