function buildContextMenuFromActions (context, actions, template) {
    return template
        .map(group => group.filter(action => !!actions[action]))
        .filter(group => group.length > 0)
        .map(group => group.reduce((acc, action) => {
            acc[action] = {
                text: actions[action].text(context),
                url: actions[action].url(context),
                action: context.doAction(action, context.items || null)
            };
            return acc;
        }, {}))
        .reduce((acc, group, i, groups) => {
            if (i > 0) {
                acc['Separator' + i] = {
                    separator: true
                };
            }
            Object.assign(acc, group);
            return acc;
        }, {});
}

export default {
    fromActions: buildContextMenuFromActions
};
