import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { PreviewPanoramaViewer as PanoramaViewer } from '../../FileViewers/Tour';
import MapWidget from '../../common/MapWidget';
import ErrorBoundary from '../../common/ErrorBoundary';
import BrandingLogo from '../../../branding/BrandingLogo';
import AspectRatio from '../../common/AspectRatio';

class TourViewer extends React.Component {
    get branding () {
        return this.props.store.brandingImage;
    }

    render () {
        const place = this.props.preview.place;
        const placeProps = this.props.preview.placeProps;
        const map = this.props.projector.slide.map;

        return (
            <React.Fragment>
                {
                    map &&
                        <MapWidget
                            map={map}
                            currentPlace={place}
                            screen={this.props.preview}
                            store={this.props.store}
                        />
                }

                <AspectRatio selector='#presentation-screen'>
                    <div id='presentation-screen'>
                        <BrandingLogo branding={ this.branding } />
                        <div className='vt-tour ib-file-frame' >
                            <div className='slide-type-indicator'>
                                <span className='icon icon-tour'/>
                            </div>

                            <ErrorBoundary title={gettext('Something went wrong with your tour!')}>
                                {
                                    place && place.asset
                                        ? <PanoramaViewer
                                            place={place}
                                            placeProps={placeProps}
                                            preview={this.props.preview}
                                            previewContainer={this.props.previewContainer}
                                        />
                                        : <React.Fragment>{gettext('There is nothing to be seen!')}</React.Fragment>
                                }
                            </ErrorBoundary>
                        </div>
                    </div>
                </AspectRatio>
            </React.Fragment>
        );
    }
};

TourViewer.propTypes = {
    store: PropTypes.object,
    projector: PropTypes.shape({
        slide: PropTypes.object
    }).isRequired,
    preview: PropTypes.object.isRequired,
    previewContainer: PropTypes.object
};

export default observer(TourViewer);
