import {
    EditorState, ContentState,
    convertFromRaw, convertFromHTML,
    Modifier, SelectionState, RichUtils
} from 'draft-js';

import { RGBColor } from '@vectorworks/vcs-ui/dist/lib/ColorPicker/utils';

import DraftOffsetKey from 'draft-js/lib/DraftOffsetKey';
import { stateToHTML } from 'draft-js-export-html';

import { getSelectionForEvent } from './draft-internal';
import { textUtils } from './utils';

const PIN_TEXT = {
    text: '$',
    length: 1
};

const urlRe = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i;

function extractUrls (text) {
    const urls = [];
    let offset = 0;
    let match = {};
    while (match) {
        const start = text.search(urlRe);
        if (start !== -1) {
            text = text.substring(start);
            match = urlRe.exec(text);
            if (match) {
                const url = match[0];
                urls.push({
                    url,
                    text: url,
                    index: offset + start,
                    lastIndex: offset + start + url.length
                });
                text = text.substring(url.length);
                offset = offset + start + url.length;
            }
        } else {
            match = false;
        }
    }
    return urls;
}

function findPinEntities (contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey &&
                contentState.getEntity(entityKey).getType() === 'PIN'
            );
        },
        callback
    );
}

function pinEntityFilter (contentState, pinEntityKey, pinId) {
    return character => {
        const entityKey = character.getEntity();
        const entity = entityKey && (!pinEntityKey || entityKey === pinEntityKey) &&
            contentState.getEntity(entityKey);
        return entity &&
            entity.getType() === 'PIN' &&
            (entity.getData() || {}).id === pinId;
    };
}

function getPinIdByEntityKey (contentState, entityKey) {
    const entity = contentState.getEntity(entityKey);
    return entity &&
        entity.getType() === 'PIN' &&
        (entity.getData() || {}).id;
}

function getTextFromSelection (editorState) {
    // Get block for current selection
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);

    // Then based on the docs for SelectionState -
    const start = selection.getStartOffset();
    const end = selection.getEndOffset();
    const selectedText = currentBlock.getText().slice(start, end);
    return selectedText;
}

function getSelection (contentState, offsetKey, entityFilter) {
    const { blockKey } = DraftOffsetKey.decode(offsetKey);
    const block = contentState.getBlockForKey(blockKey);
    let entitySelection = SelectionState.createEmpty(blockKey);
    block.findEntityRanges(
        entityFilter,
        (start, end) => {
            entitySelection = entitySelection.merge({
                anchorOffset: start,
                focusOffset: end
            });
        }
    );
    return entitySelection;
}

function getDropSelection (editorState, rect) {
    const x = rect.left;
    const y = rect.top + rect.height / 2;
    const dropSelection = getSelectionForEvent(x, y, editorState);
    return dropSelection.isCollapsed() && dropSelection;
}

function applyPinEntity (contentState, selection, pin) {
    const contentStateWithEntity = contentState.createEntity(
        'PIN', 'IMMUTABLE', { id: pin.id }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    return Modifier.applyEntity(
        contentStateWithEntity,
        selection.merge({ focusOffset: selection.getFocusOffset() + PIN_TEXT.length }),
        entityKey
    );
}

function movePin (editorState, pin, rect, data) {
    let dropSelection = getDropSelection(editorState, rect);
    if (!dropSelection) return;

    const contentState = editorState.getCurrentContent();
    const { blockKey: pinBlockKey } = DraftOffsetKey.decode(data.offsetKey);
    const pinEntitySelection = getSelection(
        contentState, data.offsetKey,
        pinEntityFilter(contentState, data.entityKey, pin.id)
    );

    // correct drop selection
    const dropSelectionCorrection = (pinBlockKey === dropSelection.getAnchorKey() &&
        pinEntitySelection.getEndOffset() <= dropSelection.getStartOffset())
        ? PIN_TEXT.length
        : 0;
    dropSelection = dropSelection.merge({
        anchorOffset: dropSelection.getAnchorOffset() - dropSelectionCorrection,
        focusOffset: dropSelection.getFocusOffset() - dropSelectionCorrection
    });

    // remove old pin
    let newContentState = Modifier.removeRange(
        contentState, pinEntitySelection, 'forward'
    );
    let newEditorState = EditorState.push(editorState, newContentState, 'remove-range');

    // insert new pin as text
    newContentState = Modifier.insertText(
        newContentState, dropSelection,
        PIN_TEXT.text, editorState.getCurrentInlineStyle()
    );
    newEditorState = EditorState.push(newEditorState, newContentState, 'insert-fragment');

    // apply entity over the pin text
    const contentStateWithPin = applyPinEntity(newContentState, dropSelection, pin);
    newEditorState = EditorState.set(
        newEditorState, { currentContent: contentStateWithPin }
    );

    return newEditorState;
}

function addPin (editorState, pin, rect, data) {
    const dropSelection = getDropSelection(editorState, rect);
    if (!dropSelection) return;

    const contentState = editorState.getCurrentContent();

    // insert new pin as text
    const newContentState = Modifier.insertText(
        contentState, dropSelection,
        PIN_TEXT.text, editorState.getCurrentInlineStyle()
    );
    let newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');

    // apply entity over the pin text
    const contentStateWithPin = applyPinEntity(newContentState, dropSelection, pin);
    newEditorState = EditorState.set(
        newEditorState, { currentContent: contentStateWithPin }
    );

    return newEditorState;
}

function removePin (editorState, pin, data) {
    const contentState = editorState.getCurrentContent();
    const pinEntitySelection = getSelection(
        contentState, data.offsetKey,
        pinEntityFilter(contentState, data.entityKey, pin.id)
    );

    // remove old pin
    const newContentState = Modifier.removeRange(
        contentState, pinEntitySelection, 'forward'
    );
    return EditorState.push(editorState, newContentState, 'remove-range');
}

function removePinById (editorState, pinId) {
    function removeOnePinById (editorState) {
        const contentState = editorState.getCurrentContent();
        const blockMap = contentState.getBlockMap();
        let newEditorState = null;
        blockMap.forEach(block => {
            if (newEditorState) return;
            let entitySelection = SelectionState.createEmpty(block.getKey());
            block.findEntityRanges(
                pinEntityFilter(contentState, null, pinId),
                (start, end) => {
                    entitySelection = entitySelection.merge({
                        anchorOffset: start,
                        focusOffset: end
                    });
                }
            );
            if (!entitySelection.isCollapsed()) {
                const newContentState = Modifier.removeRange(
                    contentState, entitySelection, 'forward'
                );
                newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
            }
        });
        return newEditorState;
    }
    let newEditorState = removeOnePinById(editorState);
    while (newEditorState) {
        editorState = newEditorState;
        newEditorState = removeOnePinById(editorState);
    }
    return editorState;
}

function getTextState (contentState) {
    const isEditorEmpty = !contentState.hasText();
    const currentPlainText = contentState.getPlainText();
    const lengthOfEditorContent = currentPlainText.length;
    const lengthOfTrimmedContent = currentPlainText.trim().length;
    const containsOnlySpaces = !isEditorEmpty && !lengthOfTrimmedContent;
    const containsNonSpaces = !isEditorEmpty && lengthOfTrimmedContent;
    return {
        isEditorEmpty,
        currentPlainText,
        lengthOfEditorContent,
        lengthOfTrimmedContent,
        containsOnlySpaces,
        containsNonSpaces
    };
};

function htmlEntityStyleFn (entity) {
    const entityType = entity.get('type');
    if (entityType === 'PIN') {
        const data = entity.getData();
        return {
            element: 'span',
            attributes: {
                'data-component': 'pin',
                'data-pin-id': data.id
            },
            style: {}
        };
    }
}

function htmlBlockStyleFn (block) {
    const data = block.getData();
    const alignment = data.get('alignment', 'left');
    return {
        style: {
            'text-align': alignment
        }
    };
}

function blockStyleFn (block) {
    const data = block.getData();
    const alignment = data.get('alignment', 'left');
    return `ib-text-align-${alignment}`;
}

function htmlInlineStyleFn (customStyleMap) {
    return function (styles) {
        const color = styles.filter((value) => value.startsWith('color-')).first();

        if (color) {
            return {
                element: 'span',
                style: {
                    color: customStyleMap[color]?.color || customStyleMap[color]
                }
            };
        }
    };
}

function contentStateToHTML (contentState, customStyleMap = {}) {
    return getTextState(contentState).containsNonSpaces
        ? textUtils.clearUnnecessaryCharacters(stateToHTML(
            contentState, {
                entityStyleFn: htmlEntityStyleFn,
                blockStyleFn: htmlBlockStyleFn,
                inlineStyleFn: htmlInlineStyleFn(customStyleMap)
            }
        ))
        : '';
}

function getPinIdsDeletedFromText (rawContent, allPinIds) {
    const currentPinIds = Object
        .values(rawContent.entityMap)
        .filter(v => v.type === 'PIN')
        .map(v => v.data.id);
    return allPinIds.reduce((acc, pinId) => {
        if (!currentPinIds.includes(pinId)) {
            acc.push(pinId);
        }
        return acc;
    }, []);
}

function setAlignment (editorState, alignment) {
    const content = editorState.getCurrentContent();
    const entityKey = editorState.getSelection().getStartKey();
    const block = content.getBlockForKey(entityKey);
    const blockData = getAlignment(editorState) === alignment
        ? block.getData().delete('alignment')
        : block.getData().set('alignment', alignment);

    if (entityKey) {
        const newContentState = Modifier.setBlockData(
            content,
            editorState.getSelection(),
            blockData
        );
        return EditorState.set(
            editorState, { currentContent: newContentState }
        );
    } else {
        return editorState;
    }
}

function getAlignment (editorState) {
    const content = editorState.getCurrentContent();
    const entityKey = editorState.getSelection().getStartKey();
    const block = content.getBlockForKey(entityKey);
    const data = block.getData();
    return data.get('alignment');
}

function insertLink (editorState, url, title, inNewTab) {
    return title
        ? insertLinkAtCursor(editorState, url, title, inNewTab)
        : insertLinkOverSelection(editorState, url, inNewTab);
}

function createLinkEntity (editorState, url, inNewTab = true) {
    return editorState.getCurrentContent().createEntity(
        'LINK', 'MUTABLE', { url, title: url, inNewTab }
    );
}

function insertLinkAtCursor (editorState, url, title, inNewTab) {
    const selection = editorState.getSelection();
    const currentContentWithLink = createLinkEntity(editorState, url, inNewTab);
    const entityKey = currentContentWithLink.getLastCreatedEntityKey();

    const textWithEntity = Modifier.replaceText(
        currentContentWithLink,
        selection,
        title,
        editorState.getCurrentInlineStyle(),
        entityKey
    );

    let newEditorState = EditorState.push(editorState, textWithEntity, 'insert-characters');
    const updatedSelection = selection.merge({ focusOffset: selection.getAnchorOffset() + title.length });
    newEditorState = EditorState.forceSelection(newEditorState, updatedSelection);

    return newEditorState;
}

function insertLinkOverSelection (editorState, url, inNewTab) {
    const currentContentWithLink = createLinkEntity(editorState, url, inNewTab);
    const entityKey = currentContentWithLink.getLastCreatedEntityKey();

    let newEditorState = EditorState.set(editorState, { currentContent: currentContentWithLink });
    newEditorState = RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
    );
    newEditorState = EditorState.forceSelection(newEditorState, editorState.getSelection());
    return newEditorState;
}

function changeColor (toggledColor, state) {
    const { editorState, customStyleMap } = state;
    const selection = editorState.getSelection();

    // Let's just allow one color at a time. Turn off all active colors.
    const nextContentState = Object.keys(customStyleMap)
        .reduce((contentState, color) => {
            return Modifier.removeInlineStyle(contentState, selection, color);
        }, editorState.getCurrentContent());

    let nextEditorState = EditorState.push(
        editorState,
        nextContentState,
        'change-inline-style'
    );

    if (RGBColor.isAutoColor(toggledColor)) {
        nextEditorState = EditorState.forceSelection(nextEditorState, nextEditorState.getSelection());
        return nextEditorState;
    }

    const newContentState = Modifier.applyInlineStyle(
        nextEditorState.getCurrentContent(),
        nextEditorState.getSelection(),
        toggledColor.toStyleClass()
    );
    nextEditorState = EditorState.set(nextEditorState, { currentContent: newContentState });

    return nextEditorState;
}

function buildEditorState (text = {}, decorator) {
    if (text.raw) {
        const blocks = convertFromRaw(text.raw);
        return EditorState.createWithContent(blocks);
    } else if (text.html) {
        const blocksFromHTML = convertFromHTML(text);
        return EditorState.createWithContent(
            ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            )
        );
    } else {
        return EditorState.createEmpty(null);
    }
}

export {
    findPinEntities,
    movePin,
    addPin,
    removePin,
    removePinById,
    contentStateToHTML,
    getTextState,
    getPinIdByEntityKey,
    getPinIdsDeletedFromText,
    extractUrls,
    setAlignment,
    getAlignment,
    blockStyleFn,
    insertLink,
    changeColor,
    getTextFromSelection,
    buildEditorState
};
