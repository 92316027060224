import { toCanvas } from 'qrcode';
import { FileTypes } from '../lib/file';

const qrMoreOnMobile = function (fileType) {
    return !Settings.device.isMobile &&
        [FileTypes.vgx, FileTypes.panorama].includes(fileType);
};

const getQRLocalStorageKey = function () {
    return `${Settings.user.login}.qrCodes`;
};

function QRCodeViewModel (params) {
    const self = this;

    self.ctx = params.ctx;
    self.dialog = params.dialog;
    self.moreOnMobile = self.ctx.fileType && qrMoreOnMobile(self.ctx.fileType);

    if (self.moreOnMobile) {
        self.title = self.ctx.fileType === FileTypes.vgx
            ? gettext('Do more with this model!')
            : gettext('Do more with this image!');
        self.description = self.ctx.fileType === FileTypes.vgx
            ? gettext('Scan the QR code with the Vectorworks Nomad mobile app for an option to view in immersive AR mode.')
            : gettext('Scan the QR code with your mobile device for an immersive VR experience.');
    } else {
        self.title = gettext('Take this shared link anywhere you go!');
        self.description = gettext('Scan the QR code with your mobile device.');
    }

    self.init = function () {
        toCanvas(document.getElementById('qr-canvas'), this.ctx.url, function () {});
    };

    self.confirm = () => {
        self.moreOnMobile && window.localStorage.setItem(getQRLocalStorageKey(), true);
        self.dialog.close();
    };

    self.dismiss = () => {
        self.dialog.dismiss();
    };

    self.init();
}

!ko.components.isRegistered('dialog-qr-code') &&
ko.components.register('dialog-qr-code', {
    viewModel: QRCodeViewModel,
    template: { element: 'dialog-qr-code' }
});

export {
    QRCodeViewModel,
    getQRLocalStorageKey,
    qrMoreOnMobile
};
