import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { Collapse } from '@vectorworks/vcs-ui/dist/lib/Collapse/Collapse';

import Reply from './Reply';

const S = {
    Wrapper: styled.div`
        display: flex;
        flex-direction: column;
        padding-left: 16px;
    `,
    Collapse: styled.div`
        display: flex;
        align-items: center;

        margin-bottom: 8px;
        font-weight: bold;
        color: var(--vcs-color--primary);
        cursor: pointer;
    `,
    Expand: styled(Icon)`
        transform: ${props => props.expand ? 'rotate(90deg)' : 'rotate(0)'};
        transform-origin: 50% 40%;
        transition: transform 0.25s ease-in;
    `
};

const RepliesList = ({ comment, commentsStore, children }) => {
    const [collapse, setCollapse] = useState(true);

    const replies = [...comment.replies];
    const lastReply = replies.pop();
    const moreRepliesCount = comment.replies.length - 1;

    const toggleCollapse = () => {
        setCollapse(!collapse);
    };

    return (
        <S.Wrapper>

            {
                moreRepliesCount > 0 && (
                    <S.Collapse onClick={toggleCollapse}>
                        <S.Expand expand={!collapse} icon='right-arrow' size='sm' />
                        {
                            collapse
                                ? `${moreRepliesCount} ${gettext('more replies')}`
                                : gettext('Hide replies')
                        }
                    </S.Collapse>
                )
            }

            <Collapse in={!collapse}>
                {
                    replies.map((reply) => (
                        <Reply
                            key={reply.id.toString()}
                            comment={reply}
                            commentsStore={commentsStore}
                            hideActions
                        />
                    ))
                }
            </Collapse>

            {lastReply &&
                <Reply
                    comment={lastReply}
                    commentsStore={commentsStore}
                />}
            {children}
        </S.Wrapper>
    );
};

export default observer(RepliesList);

RepliesList.propTypes = {
    comment: PropTypes.object,
    commentsStore: PropTypes.object.isRequired
};
