import Board from './board';
import Tour from './tour';
import { utils } from '../../lib/';

const SlideType = {
    BOARD: 'board',
    TOUR: 'virtual_tour'
};

const slideFromApi = (root, data) => {
    const Type = {
        [SlideType.BOARD]: Board,
        [SlideType.TOUR]: Tour
    }[data.slide_type];
    return Type.create(
        {
            root,
            id: String(data.id),
            title: data.title,
            date_modified: data.date_modified,
            displayDateModified: utils.formatDateTime(data.date_modified),
            type: data.slide_type,
            assetIds: data.assets,
            order: data.order
        },
        data.data
    );
};

// TODO: This shouldn't exist!
const defaultSlide = root => slideFromApi(
    root,
    {
        id: '0',
        title: '',
        slide_type: SlideType.BOARD,
        assetIds: [],
        order: 0,
        data: {
            content: {
                layout: 'intro',
                background: '#f0f0f5',
                text: {
                    text: '',
                    color: '#000000'
                }
            },
            items: [],
            pins: [],
            boxes: [],
            z_order: {}
        }
    }
);

export {
    SlideType,
    slideFromApi,
    defaultSlide
};
