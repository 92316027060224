import React from 'react';
import axios from 'axios';
import { observer } from 'mobx-react';
import initSqlJs from 'sql.js';

import sqlWasm from '!!file-loader?name=sql-wasm-[contenthash].wasm!sql.js/dist/sql-wasm.wasm';  // eslint-disable-line

import { commonPropTypes } from '../../utils';
import { queryString } from '../../../../lib';
import { pubsub } from '../../../../base';

import ConnectCADFilters from './Filters';
import Table from './Table';
import Store from './Store';
import UnitsDialog from './UnitsDialog';

class ConnectCADViewer extends React.Component {
    name = 'ConnectCADViewer';

    store = new Store();

    loadDb = async () => {
        const resolvedUrl = await Promise.resolve(
            $.getJSON(
                queryString.buildUrl(
                    this.props.store.file.file.downloadUrl,
                    {
                        web: 'on',
                        download: 'on',
                        response_type: 'data',
                        viewable: 'off'
                    }
                )
            )
        );

        return axios.get(resolvedUrl.url, { responseType: 'arraybuffer' });
    };

    openUnitsDialog = () => {
        const { unitsStore } = this.store;
        window.ReactDialogs.open({
            component: UnitsDialog,
            store: this.store
        })
            .result
            .then(columns => unitsStore.setUnits(columns))
            .catch(() => null);
    };

    async componentDidMount () {
        const loadedDb = await this.loadDb();
        this.sqlPromise = initSqlJs({
            locateFile: () => sqlWasm
        }).then(sql => {
            const uintData = new Uint8Array(loadedDb);
            this.store.db = new sql.Database(uintData);
            this.store.query();
        }).then(() => {
            this.props.store.endLoading();
        });

        pubsub.subscribe(this, 'fv.ccad.units.dialog.open', this.openUnitsDialog);
        pubsub.subscribe(this, 'fv.ccad.table.changed', this.store.setTable);
    }

    componentWillUnmount () {
        pubsub.unsubscribeAll(this);
    }

    render () {
        const { store: fvStore, children } = this.props;

        return (
            <div
                className='fileview-component-loader ccad-component-loader'
                data-what='file-viewer'
                style={{ display: 'block' }}
            >
                { children }
                {
                    (!fvStore.loading && this.store.columns.active) &&
                        <React.Fragment>
                            <ConnectCADFilters store={this.store}/>
                            <Table store={this.store}/>
                        </React.Fragment>
                }
            </div>
        );
    }
};

ConnectCADViewer.propTypes = {
    ...commonPropTypes
};

export default observer(ConnectCADViewer);
