import { observable, action, makeObservable } from 'mobx';

import { utils } from '../../lib';
import api from '../api';

const imageLoader = {
    load (url, onLoad, onProgress, onError) {
        const image = document.createElement('img');

        function onImageLoad () {
            image.removeEventListener('load', onImageLoad, false);
            image.removeEventListener('error', onImageError, false);
            onLoad && onLoad(this);
        }

        function onImageError (event) {
            image.removeEventListener('load', onImageLoad, false);
            image.removeEventListener('error', onImageError, false);
            onError && onError(event);
        }

        image.addEventListener('load', onImageLoad, false);
        image.addEventListener('error', onImageError, false);

        image.crossOrigin = 'anonymous';
        image.src = url;
        return {
            cancel () {
                image.removeEventListener('load', onImageLoad, false);
                image.removeEventListener('error', onImageError, false);
            }
        };
    }
};

const urlResolver = {
    cache: new Map(),
    resolveUrl (url) {
        if (!this.cache.has(url)) {
            return api.misc.resolveDownloadUrl(url)
                .then(resolvedUrl => {
                    this.cache.set(url, resolvedUrl);
                    return resolvedUrl;
                });
        } else {
            return Promise.resolve(this.cache.get(url));
        }
    }
};

const imageCache = {
    get (url) {
        const elementId = this.createElementId(url);
        const element = document.getElementById(elementId);
        return element && `#${elementId}`;
    },
    set (url, image) {
        const elementId = this.createElementId(url);
        image.id = elementId;
        const imageCache = this.getOrCreateImageCache();
        imageCache.appendChild(image);
        return `#${elementId}`;
    },
    createElementId (url) {
        return `img-${utils.hashCode(url)}`;
    },
    getOrCreateImageCache () {
        let imageCache = document.getElementById('image-cache');
        if (!imageCache) {
            imageCache = document.createElement('div');
            imageCache.setAttribute('id', 'image-cache');
            imageCache.setAttribute('style', 'display: none;');
            document.body.appendChild(imageCache);
        }
        return imageCache;
    }
};

const loadImage = url => {
    const result = {
        cancel () {
            this.process && this.process.cancel();
        }
    };
    result.result = urlResolver.resolveUrl(url)
        .then(resolvedUrl => {
            const elementId = imageCache.get(resolvedUrl);
            return elementId || new Promise((resolve, reject) => {
                result.process = imageLoader.load(
                    resolvedUrl,
                    // onLoad callback
                    image => {
                        resolve(imageCache.set(resolvedUrl, image));
                    },
                    // onProgress callback currently not supported
                    undefined,
                    // onError callback
                    error => reject(error)
                );
            });
        });
    return result;
};

class ImageResource {
    state = 'init';
    src = null;
    url = null;

    constructor (url) {
        makeObservable(this, {
            state: observable,
            src: observable,
            setState: action
        });

        this.url = url;
    }

    load () {
        if (!this.url) return;
        this.trackLoading(loadImage(this.url).result);
    }

    trackLoading (promise) {
        this.setState('loading');
        promise
            .then(image => {
                this.setState('ready', { src: image });
            })
            .catch(() => {
                this.setState('error');
            });
    }

    setState (state, props = null) {
        this.state = state;
        props && Object.assign(this, props);
    }
}

export {
    loadImage,
    ImageResource
};
