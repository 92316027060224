import { Dialog } from './dialog';

class IntegrationExpiredRevokedError {
    static showErrorDialog () {
        Dialog.open({
            component: 'dialog-integration-expired-revoked',
            ctx: {
                template: { name: 'dialog-integration-expired-revoked' }
            }
        });
    }

    constructor (httpError) {
        this.httpError = httpError;
    }
}

export {
    IntegrationExpiredRevokedError
};
