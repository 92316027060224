const TABLES = {
    circuits: {
        name: 'circuits',
        title: gettext('Circuits'),
        icon: 'cad-connections',
        base: `SELECT ID as id,
                Number as number,
                Cable as cable,
                Len as cableLength,
                Type as cableType,
                Signal as signal,
                "Src.Device" as srcDeviceName,
                "Src.Socket" as srcSocketName,
                "Src.Conn" as srcConnector,
                "Dst.Device" as dstDeviceName,
                "Dst.Socket" as dstSocketName,
                "Dst.Conn" as dstConnector
            FROM CircuitList`,
        orderBy: []
    },
    equipment: {
        name: 'equipment',
        title: gettext('Equipment'),
        icon: 'cad-equipment',
        base: `SELECT ID as id,
                Name as deviceName,
                Tag as tag,
                Description as desc,
                Make as make,
                Model as model,
                Make || " " || Model as makeModel,
                Mounting as mounting,
                "H/W/D" as deviceDimensions,
                Power as power,
                Weight as weight,
                Modular as modular,
                NumSlots as numSlots,
                Room as room,
                (
                    IIF(Rack IS NULL, "", Rack || " ") ||
                    IIF(
                        RackU AND Slot != "",
                        RackU || "." || Slot,
                        COALESCE(RackU, Slot)
                    )
                ) as rackLocation
            FROM DeviceList`,
        orderBy: []
    },
    devices: {
        name: 'devices',
        title: gettext('Devices'),
        icon: 'cad-devices',
        base: `SELECT ID as id,
                DeviceID as deviceID,
                Device as device,
                Socket as socketName,
                Signal as signal,
                Adaptor as adaptor,
                Conn as connector,
                CircuitNumber as connectedTo
            FROM DevicesSocketsAdapters`,
        orderBy: [
            { id: 'device', sort: 'ASC' }
        ]
    }
};

const FILTERS = {
    contains: {
        name: 'CONTAINS',
        title: gettext('Contains'),
        condition: (c, v) => ` WHERE ${c} LIKE '%${v}%'`
    },
    notContains: {
        name: 'NOT_CONTAINS',
        title: gettext('Does not contain'),
        condition: (c, v) => ` WHERE ${c} NOT LIKE '%${v}%'`
    },
    equals: {
        name: 'EQUALS',
        title: gettext('Equals'),
        condition: (c, v) => ` WHERE LOWER(${c}) = LOWER('${v}')`
    }
};

const COLUMNS = {
    number: {
        title: gettext('Number'),
        type: 'string'
    },
    cable: {
        title: gettext('Cable'),
        type: 'string'
    },
    cableType: {
        title: gettext('Cable Type'),
        type: 'string'
    },
    cableLength: {
        title: gettext('Length'),
        type: 'number'
    },
    srcDeviceName: {
        title: gettext('Source Device'),
        type: 'string'
    },
    srcConnector: {
        title: gettext('Source Connector'),
        type: 'string'
    },
    srcSocketName: {
        title: gettext('Source Socket'),
        type: 'string'
    },
    dstSocketName: {
        title: gettext('Destination Socket'),
        type: 'string'
    },
    dstConnector: {
        title: gettext('Destination Connector'),
        type: 'string'
    },
    dstDeviceName: {
        title: gettext('Destination Device'),
        type: 'string'
    },
    deviceName: {
        title: gettext('Name'),
        type: 'string'
    },
    tag: {
        title: gettext('Tag'),
        type: 'string'
    },
    desc: {
        title: gettext('Description'),
        type: 'string'
    },
    make: {
        title: gettext('Make'),
        type: 'string'
    },
    model: {
        title: gettext('Model'),
        type: 'string'
    },
    makeModel: {
        title: gettext('Make and model'),
        type: 'string'
    },
    mounting: {
        title: gettext('Mounting'),
        type: 'string'
    },
    deviceDimensions: {
        // Translators: abbreviation for Height / Width / Depth
        title: gettext('H / W / D'),
        type: 'number'
    },
    power: {
        title: gettext('Power'),
        type: 'number'
    },
    weight: {
        title: gettext('Weight'),
        type: 'number'
    },
    modular: {
        title: gettext('Modular'),
        type: 'number'
    },
    numSlots: {
        title: gettext('Slots'),
        type: 'number'
    },
    location: {
        title: gettext('Location'),
        type: 'string'
    },
    room: {
        title: gettext('Room'),
        type: 'string'
    },
    rackLocation: {
        title: gettext('Rack Location'),
        type: 'string'
    },
    device: {
        title: gettext('Device'),
        type: 'string'
    },
    socketName: {
        title: gettext('Socket'),
        type: 'string'
    },
    signal: {
        title: gettext('Signal'),
        type: 'string'
    },
    adaptor: {
        title: gettext('Adaptor'),
        type: 'string'
    },
    connector: {
        title: gettext('Connector'),
        type: 'string'
    },
    socketType: {
        title: gettext('Type'),
        type: 'string'
    },
    circuitsCount: {
        title: gettext('Number of Circuits'),
        type: 'number'
    },
    connectedTo: {
        title: gettext('Connected to'),
        type: 'string'
    }
};

export { TABLES, FILTERS, COLUMNS };
