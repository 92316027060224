import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { computed } from 'mobx';
import { Observer } from 'mobx-react';
import HoverPopover from 'material-ui-popup-state/HoverPopover';

import { pubsub } from '../../base';
import TooltipContent from './Preview';
import { inject } from '../Store';
import { AssetPin, LinkPin, TextPin } from '../models/board';
import { TextPreview } from '../FileViewers/Tour/TextPreview';
import { get2DPosition, Pin } from './Pin';

const S = {
    HoverPopover: styled(HoverPopover)`
        > .MuiPaper-elevation {
            box-shadow: var(--evelation-shadow-1);
        }
    `
};

// Remove pinType pinPoint props
const PreviewPin = inject('fileViewStore', 'dialog')(
    ({
        pin, pinPoint, pinType, container, previewContainer, dialog, fileViewStore
    }) => {
        const PinComponent = pinType;
        const { x, y } = get2DPosition(pin);
        const position = pinPoint.viewPoint(x, y, container);

        const [anchorEl, setAnchorEl] = React.useState();
        const [open, setOpen] = React.useState(false);
        const pinRef = React.createRef();

        const handleOpen = React.useCallback(() => {
            setAnchorEl(pinRef.current);
            setOpen(true);
        }, [pinRef]);

        const handleClose = () => {
            setAnchorEl(null);
            setOpen(false);
        };

        React.useEffect(() => {
            const ctx = {};
            pubsub.subscribe(ctx, 'pin.highlight', (pinId) => {
                pinId === pin.id ? handleOpen() : handleClose();
            });

            return () => pubsub.unsubscribe(ctx, 'pin.highlight');
        }, [handleOpen]);

        const mountNode = computed(
            () => previewContainer.isMounted
                ? previewContainer.current
                : null
        );

        const handlePinClick = e => {
            return [
                [AssetPin, handleAssetPinPreview],
                [TextPin, handleTextPinPreview],
                [LinkPin, handleLinkPinPreview]
            ].find(([pinType, _]) => pin instanceof pinType)[1](e);
        };

        const handleAssetPinPreview = e => {
            const item = pin.toItem;
            const items = item.board.items;

            if (item.asset && item.asset.state === 'ready') {
                fileViewStore.file.open(item, items);
            }
        };

        const handleTextPinPreview = e => {
            dialog.open({
                component: TextPreview,
                params: { textPin: pin }
            });
        };

        const handleLinkPinPreview = e => {
            const linkDOMEl = document.createElement('a');
            const href = pin.linkState.link.startsWith('http')
                ? pin.linkState.link
                : `https://${pin.linkState.link}`;
            linkDOMEl.href = href;
            if (pin.linkState.inNewTab) {
                linkDOMEl.target = '_blank';
            }
            linkDOMEl.click(e);
            linkDOMEl.remove();
        };

        return (
            (mountNode && pin.visible)
                ? (
                    <Observer>{() => (
                        <React.Fragment>
                            <PinComponent
                                pin={pin}
                                onClick={handlePinClick}
                                onMouseEnter={handleOpen}
                                onMouseLeave={handleClose}
                                isHovered={open}
                                pinRef={pinRef}
                                title={undefined}
                                highlightOpacity={0}
                                {...position}
                            />
                            <S.HoverPopover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                onMouseLeave={handleClose}
                                /*
                                    In full screen, anything outside '#presentation-fs-preview' will be invisible.
                                    So, if the popup is rendered in the root element, it will not be visible.
                                    So, pass the `container` prop.
                                */
                                container={previewContainer.current}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                <TooltipContent pin={pin} />
                            </S.HoverPopover>
                        </React.Fragment>
                    )}
                    </Observer>
                )
                : null
        );
    }
);
PreviewPin.propTypes = {
    container: PropTypes.object.isRequired,
    pin: PropTypes.object.isRequired,
    pinType: PropTypes.oneOf([PropTypes.func, PropTypes.object]),
    pinPoint: PropTypes.object.isRequired,
    screen: PropTypes.object.isRequired,
    previewContainer: PropTypes.object,
    fileViewStore: PropTypes.object,
    dialog: PropTypes.object.isRequired
};
PreviewPin.defaultProps = {
    pinType: Pin
};

export default PreviewPin;
