import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

const Navigation = ({ store }) => {
    const isMultipleSelection = store.file.group.hasNext() && store.file.group.hasPrev();

    return (
        <React.Fragment>
            <span
                className={`icon icon-left-arrow ${store.file.group.hasPrev() ? '' : 'disabled'}`}
                onClick={store.file.prev}
                title={ gettext('Previous file') }
                ga-action='File_View_Controller'
                ga-label='Click_Previous_File'
                data-what='controller-tool'
                data-which='prev-file'
            />

            <span className='ctrl-tool file-navigation'>
                {
                    isMultipleSelection
                        ? `${store.file.group.cursor + 1} / ${store.file.numberOfFiles}`
                        : '1 / 1'
                }
            </span>

            <span
                className={`icon icon-right-arrow ${store.file.group.hasNext() ? '' : 'disabled'}`}
                onClick={store.file.next}
                title={ gettext('Next file')}
                ga-action='File_View_Controller'
                ga-label='Click_Next_File'
                data-what='controller-tool'
                data-which='next-file'
            />
        </React.Fragment>
    );
};

Navigation.propTypes = {
    store: PropTypes.object
};

export default observer(Navigation);
