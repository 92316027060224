import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { ThumbnailSimpleText } from '../../common/SimpleText';
import Viewer from '../../FileViewers/ThumbnailViewer';

const Intro = props =>
    <div
        className='board-layout layout-intro layout-intro thumbnail-intro'
        style={{ background: props.slide.image ? `url('${props.slide.image}')` : props.slide.backgroundColor.toCSS() }}
    >
        <ThumbnailSimpleText board={props.slide} text={props.slide.text} />
        <div className='ib-file-container'>
            { props.slide.media && <Viewer file={props.slide.media}/> }
        </div>
        {
            (!props.slide.media && !props.slide.text.text) &&
                <div className='ib-slide-empty'>{ gettext('Board is empty') }</div>
        }
    </div>;

Intro.propTypes = {
    slide: PropTypes.object.isRequired
};

export default observer(Intro);
