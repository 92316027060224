import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, CheckboxState } from '@vectorworks/vcs-ui/dist/lib/Checkbox/Checkbox';

import { pubsub } from '../../../../../../base';
import { COLUMNS, TABLES, buildQuery } from '../../utils';
import S from '../styles';
import ViewerContext from '../../ViewerContext';

const SELECTED = {
    NONE: 'none',
    SOME: 'some',
    ALL: 'all'
};

const SearchBar = ({ onSearch, onKeyDown }) =>
    <S.DistinctValuesSearchBar>
        <S.DistinctValuesSearchIcon
            data-what='ccad-column-filter'
            data-which='search-bar-icon'
            icon='search-16'
            size='xs'
        />
        <S.DistinctValuesInput
            data-what='ccad-column-filter'
            data-which='search-bar-input'
            onChange={onSearch}
            onKeyDown={onKeyDown}
        />
    </S.DistinctValuesSearchBar>;

SearchBar.propTypes = {
    onSearch: PropTypes.func,
    onKeyDown: PropTypes.func
};

const DistinctValuesMenu = ({ columnId, close }) => {
    const { store } = React.useContext(ViewerContext);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [distinctValues, setDistinctValues] = React.useState([]);
    const [allSelected, setAllSelected] = React.useState();

    React.useEffect(() => {
        queryDb();
    }, [searchQuery]);

    React.useEffect(() => {
        const selectedValuesCount = distinctValues.filter(v => v.selected).length;
        if (selectedValuesCount === 0) {
            setAllSelected(SELECTED.NONE);
        } else if (selectedValuesCount === distinctValues.length) {
            setAllSelected(SELECTED.ALL);
        } else {
            setAllSelected(SELECTED.SOME);
        }
        pubsub.publish('ccad.filters.icon.update', columnId);
    }, [distinctValues]);

    const queryDb = () => {
        const baseQuery = `SELECT ${COLUMNS[columnId].query} FROM ${TABLES[store.table].view}`;
        const query = buildQuery(baseQuery, store, [columnId]);
        const [results] = store.db.exec(query);
        const { values } = results;
        const valuesSet = values
            .reduce((acc, value) => {
                const includeCondition = searchQuery
                    ? !acc.includes(value[0]) && value.toString().toLowerCase().includes(searchQuery)
                    : !acc.includes(value[0]);
                return includeCondition
                    ? [...acc, value[0]]
                    : acc;
            }, [])
            .sort();
        setDistinctValues(valuesSet.map(value => ({
            title: value,
            selected: store.filters[columnId]?.value?.includes(value)
        })));
    };

    const toggleValueCheckbox = e => {
        const title = e.target.dataset.which;
        const newValues = [...distinctValues];
        const selectedValue = newValues.find(v => v.title === title);
        selectedValue.selected = !selectedValue.selected;
        setDistinctValues(newValues);
        store.setFilterValue(columnId, newValues.filter(v => v.selected).map(v => v.title));
    };

    const onSearch = e => {
        setSearchQuery(e.target.value.toString().toLowerCase());
    };

    const toggleSelectAll = () => {
        const newValues = [...distinctValues];
        newValues.forEach(v => {
            v.selected = allSelected !== SELECTED.ALL;
        });
        setDistinctValues(newValues);
        store.setFilterValue(columnId, newValues.filter(v => v.selected).map(v => v.title));
    };

    const stopKeyPropagation = e => {
        e.stopPropagation();
    };

    const onKeyDownCheckbox = e => {
        e.stopPropagation();
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            toggleValueCheckbox(e);
        }
        if (e.key === 'Escape') {
            close();
        }
    };

    const onKeyDownCheckboxAll = e => {
        e.stopPropagation();
        if (e.key === 'Enter' || e.key === ' ') {
            toggleSelectAll();
        }
        if (e.key === 'Escape') {
            close();
        }
    };

    return (
        <S.DistinctValuesMenu>
            <S.DistinctValue onClick={toggleSelectAll}>
                <Checkbox
                    state={allSelected === SELECTED.ALL
                        ? CheckboxState.ON
                        : allSelected === SELECTED.SOME
                            ? CheckboxState.MIXED
                            : CheckboxState.OFF}
                    style={{ margin: '0 5px' }}
                    tabIndex={0}
                    onKeyDown={onKeyDownCheckboxAll}
                    data-what='ccad-filters-distinct-values-checkbox'
                    data-which='select-all'
                />
                { gettext('Select All') }
            </S.DistinctValue>
            <SearchBar
                onKeyDown={stopKeyPropagation}
                onSearch={onSearch}
                tabIndex={0}
                autoFocus={true}
                data-what='ccad-filters-searchbar'
                data-which='ccad-filters-searchbar'
            />
            <S.DistinctValuesContainer tabIndex={-1}>
                {
                    distinctValues.map(value =>
                        <S.DistinctValue
                            key={`distinct-value-option-${value.title}`}
                            onClick={toggleValueCheckbox}
                            data-what='ccad-filters-distinct-value'
                            data-which={value.title}
                        >
                            <Checkbox
                                state={value.selected ? CheckboxState.ON : CheckboxState.OFF}
                                style={{ margin: '0 5px' }}
                                data-what='ccad-filters-distinct-values-checkbox'
                                data-which={value.title}
                                key={`distinct-value-checkbox-${value.title}`}
                                tabIndex={0}
                                onKeyDown={onKeyDownCheckbox}
                            />
                            { value.title }
                        </S.DistinctValue>
                    )
                }
            </S.DistinctValuesContainer>
        </S.DistinctValuesMenu>
    );
};

DistinctValuesMenu.propTypes = {
    columnId: PropTypes.string,
    close: PropTypes.func
};

export default DistinctValuesMenu;
