/* Convert all API colors to RGBColor,
because previously background was set with HEX colors,
that don't support opacity. */

import { RGBColor } from '@vectorworks/vcs-ui/dist/lib/ColorPicker/utils';
import { Appearance } from '../util';

class Model extends Appearance {
    constructor ({
        // Content
        size = 3,
        shape = Model.SHAPES.CIRCLE.type,
        color = RGBColor.DEFAULT_COLOR,
        // Border
        borderWidth = 4,
        borderColor = RGBColor.white,
        // Image
        image = Model.IMAGES.NONE.type,
        imageSize = 0,
        imageColor = RGBColor.white
    } = {}) {
        super(Appearance.TYPES.SHAPE_WITH_ICON);

        // Content
        this.size = size;
        this.shape = shape;
        this.color = RGBColor.guess(color);

        // Border
        this.borderWidth = borderWidth;
        this.borderColor = RGBColor.guess(borderColor);

        // Image
        // 1. If image is null, the default value doesn't work.
        // 2. If icon is no longer supported, it shouldn't be rendered
        this.image = Model.supportsImage(image)
            ? image
            : Model.IMAGES.NONE.type;
        this.imageSize = imageSize;
        this.imageColor = RGBColor.guess(imageColor);
    }

    compare (other) {
        return Object.keys(this).every((key) => {
            return this[key] instanceof RGBColor
                ? this[key].compare(other[key])
                : this[key] === other[key];
        });
    }
}

class Shape {
    constructor (type, title) {
        this.type = type;
        this.title = title;
    }
}

Model.SHAPES = {
    NONE: new Shape('none', gettext('None')),
    CIRCLE: new Shape('circle', gettext('Circle'))
};

export class Image {
    constructor (type, title = '') {
        this.type = type;
        this.title = title;
    }
}

Model.IMAGES = {
    NONE: new Image(0, gettext('None')),
    AUTO: new Image(1, gettext('Auto')),

    // Arrows
    ARROW_DOWN_FRAME: new Image('pin-icon-arrow-down-frame'),
    ARROW_DOWN: new Image('pin-icon-arrow-down'),
    ARROW_LEFT_FRAME: new Image('pin-icon-arrow-left-frame'),
    ARROW_LEFT: new Image('pin-icon-arrow-left'),
    ARROW_RIGHT_FRAME: new Image('pin-icon-arrow-right-frame'),
    ARROW_RIGHT: new Image('pin-icon-arrow-right'),
    ARROW_UP_FRAME: new Image('pin-icon-arrow-up-frame'),
    ARROW_UP: new Image('pin-icon-arrow-up'),

    // File types
    LINK_1: new Image('pin-icon-link-1'),
    LINK_2: new Image('pin-icon-link-2'),
    LINK_3: new Image('pin-icon-link-3'),

    PANORAMA: new Image('pin-icon-panorama'),
    IMAGE: new Image('pin-icon-image'),
    PDF: new Image('pin-icon-pdf'),
    VGX: new Image('pin-icon-vgx'),
    V: new Image('pin-icon-v'),
    VIDEO: new Image('pin-icon-video'),

    PANORAMA_FRAME: new Image('pin-icon-panorama-frame'),
    IMAGE_FRAME: new Image('pin-icon-image-frame'),
    PDF_FRAME: new Image('pin-icon-pdf-frame'),
    VGX_FRAME: new Image('pin-icon-vgx-frame'),
    V_FRAME: new Image('pin-icon-v-frame'),
    VIDEO_FRAME: new Image('pin-icon-video-frame'),

    // Symbols
    INFO: new Image('pin-icon-info'),
    EXCLAM: new Image('pin-icon-exclam'),
    QUESTION: new Image('pin-icon-question'),
    INFO_FRAME: new Image('pin-icon-info-frame'),
    EXCLAM_FRAME: new Image('pin-icon-exclam-frame'),
    QUESTION_FRAME: new Image('pin-icon-question-frame'),

    // Social
    FB_N: new Image('pin-icon-fb-n'),
    INSTA_N: new Image('pin-icon-insta-n'),
    LINKEDIN_N: new Image('pin-icon-linkedin-n'),
    RSS_N: new Image('pin-icon-rss-n'),
    TWITTER_N: new Image('pin-icon-twitter-n'),
    YT_N: new Image('pin-icon-yt-n')
};
Model.supportsImage = (imageType) => {
    return Object.values(Model.IMAGES).find(i => i.type === imageType);
};

Appearance.MODELS[Appearance.TYPES.SHAPE_WITH_ICON] = Model;

export default Model;
