/* eslint-disable react/prop-types */
import React from 'react';
import { Observer } from 'mobx-react';
import styled from 'styled-components';

import { InputLabelField } from '@vectorworks/vcs-ui/dist/lib/Input/Text/Input';
import { textOverflow } from '@vectorworks/vcs-ui/dist/lib/styles/common';
import { Slider } from '@vectorworks/vcs-ui/dist/lib/Slider/Slider';
import { Select } from '@vectorworks/vcs-ui/dist/lib/Select/Select';
import { MenuItem } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';

import { ColorEditBox } from '../ColorEditBox';
import Model from './model';
import IconPicker from './IconPicker';

const S = {
    Wrapper: styled.div`
        display: flex;
        flex-direction: column;
    `,
    Row: styled.div`
        .MuiSlider-root {
            margin: 0;
        }

        position: relative;
        display: flex;
        align-items: center;
        min-height: 30px;
        margin-bottom: 10px;

        > label {
            margin-top: 5px;
            width: 70px;
            flex-shrink: 0;
            ${textOverflow}
        }
    `,
    InputLabelField: styled(InputLabelField)`
        color: var(--text-primary-color);
    `,
    Slider: styled(Slider)`
        flex: 1;
    `,
    ColorBox: styled(ColorEditBox)`
        flex-shrink: 0;
        margin-left: 16px;
    `
};

function Editor ({ model, onChange, registerUndoUpdate, isTextPin, ...rest }) {
    const isShapeNone = model.shape === Model.SHAPES.NONE.type;
    const isImageNone = model.image === Model.IMAGES.NONE.type;

    const [size, setSize] = React.useState();
    const [borderWidth, setBorderWidth] = React.useState();
    const [imageSize, setImageSize] = React.useState();

    return (
        <Observer>{() => (
            <S.Wrapper {...rest}>
                <S.Row>
                    <S.InputLabelField
                        labelText={gettext('Pin size')}
                        title={gettext('Pin size')}
                        htmlFor='pin-editor__size'
                    />
                    <S.Slider
                        disabled={isTextPin}
                        id='pin-editor__size'
                        value={model.size}
                        onMouseDown={() => setSize(model.size)}
                        onChange={e => onChange({ size: e.target.value }, { noUndo: true })}
                        onChangeCommitted={() => registerUndoUpdate({ size })}
                        min={0}
                        max={10}
                        data-what='pin-edit-panel' data-which='size'
                    />
                </S.Row>

                <S.Row>
                    <S.InputLabelField
                        labelText={gettext('Shape')}
                        title={gettext('Shape')}
                        htmlFor='pin-editor__shape'
                    />
                    <Select
                        value={ model.shape }
                        onChange={ e => onChange({ shape: e.target.value }) }
                        style={{ flex: 1 }}
                        data-what='pin-edit-panel' data-which='shape'
                    >
                        <MenuItem value={Model.SHAPES.NONE.type}>{Model.SHAPES.NONE.title}</MenuItem>
                        <MenuItem value={Model.SHAPES.CIRCLE.type}>{Model.SHAPES.CIRCLE.title}</MenuItem>
                    </Select>

                    <S.ColorBox
                        disabled={ isShapeNone }
                        color={model.color}
                        onChange={color => onChange({ color })}
                        popupId='pin-edit-appearance__color'
                        data-what='pin-edit-panel' data-which='color'
                    />
                </S.Row>

                <S.Row>
                    <S.InputLabelField
                        labelText={gettext('Border')}
                        title={gettext('Border')}
                        htmlFor='pin-editor__border'
                    />
                    <S.Slider
                        disabled={ isShapeNone || isTextPin }
                        id='pin-editor__border'
                        value={model.borderWidth}
                        onMouseDown={() => setBorderWidth(model.borderWidth)}
                        onChange={e => onChange({ borderWidth: e.target.value }, { noUndo: true })}
                        onChangeCommitted={() => registerUndoUpdate({ borderWidth })}
                        min={0}
                        max={15}
                        data-what='pin-edit-panel' data-which='border'
                    />
                    <S.ColorBox
                        disabled={ isShapeNone }
                        color={model.borderColor}
                        onChange={borderColor => onChange({ borderColor })}
                        popupId='pin-edit-appearance__border-color'
                        data-what='pin-edit-panel' data-which='border-color'
                    />
                </S.Row>

                <S.Row>
                    <S.InputLabelField
                        labelText={gettext('Image')}
                        title={gettext('Image')}
                    />
                    <IconPicker
                        image={ model.image }
                        onChange={image => onChange({ image })}
                        style={{ flex: 1 }}
                        data-what='pin-edit-panel' data-which='image'
                    />
                    <S.ColorBox
                        disabled={isImageNone}
                        color={model.imageColor}
                        onChange={imageColor => onChange({ imageColor })}
                        popupId='pin-edit-appearance__image-color'
                        data-what='pin-edit-panel' data-which='image-color'
                    />
                </S.Row>

                <S.Row>
                    <S.InputLabelField
                        labelText={gettext('Image size')}
                        title={gettext('Image size')}
                        htmlFor='pin-editor__image-size'
                    />
                    <S.Slider
                        disabled={isImageNone || isTextPin}
                        id='pin-editor__image-size'
                        value={model.imageSize}
                        onMouseDown={() => setImageSize(model.imageSize)}
                        onChange={e => onChange({ imageSize: e.target.value }, { noUndo: true })}
                        onChangeCommitted={() => registerUndoUpdate({ imageSize })}
                        min={0}
                        max={10}
                        data-what='pin-edit-panel' data-which='image-size'
                    />
                </S.Row>
            </S.Wrapper>
        )}
        </Observer>
    );
}

export default Editor;
