import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Controller } from './Default';
import FullScreen from './Widgets/FullScreen';
import Navigation from './Widgets/Navigation';
import VideoPlayButton from './Widgets/VideoPlayButton';
import VideoProgress from './Widgets/VideoProgress';
import VideoVolume from './Widgets/VideoVolume';

const PlayerController = ({ viewer }) =>
    <React.Fragment>
        <VideoPlayButton viewer={viewer}/>
        <VideoProgress viewer={viewer} />
        <VideoVolume viewer={viewer}/>
    </React.Fragment>;

const FullScreenComponent = ({ store, viewer }) =>
    <React.Fragment>
        <FullScreen store={store} />
        {
            viewer &&
            viewer.sceneStore &&
            viewer.sceneStore.controller.vrMode &&
            <span
                className='icon icon-3d-stereo'
                onClick={viewer.enterVR}
            />
        }
    </React.Fragment>;

const NavigationComponent = ({ store }) =>
    <Navigation store={store} />;

const VideoController = ({ store, children }) =>
    <Controller store={store} extraClasses='video-controller'>
        {children}
    </Controller>;

const DefaultVideoController = ({ store, viewer }) =>
    <VideoController store={store}>
        <div className='control-section left'>
            <PlayerController viewer={viewer}/>
        </div>

        <div className='control-section center'>
            <NavigationComponent store={store} />
        </div>

        <div className='control-section right'>
            <FullScreenComponent store={store} viewer={viewer} />
        </div>
    </VideoController>;

PlayerController.propTypes = {
    viewer: PropTypes.object.isRequired
};
FullScreenComponent.propTypes = {
    viewer: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
};
NavigationComponent.propTypes = {
    store: PropTypes.object.isRequired
};
VideoController.propTypes = {
    store: PropTypes.object.isRequired
};
DefaultVideoController.propTypes = {
    store: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired
};

export default observer(DefaultVideoController);
export {
    VideoController,
    PlayerController,
    NavigationComponent,
    FullScreenComponent
};
