import { queryString } from '../lib';
import { ASSET_JOB_ACTIONS, GROUP_ASSET_JOB_ACTIONS } from './constants';
import { createRequest } from '../base/axios';

// const BASE_URL = Settings.IBOARDS_API_URL;
const BASE_URL = '/restapi/public/v1/iboards';

const api = {
    misc: {
        resolveDownloadUrl (url) {
            if (!url) {
                return Promise.resolve(null);
            }
            return (Settings.offlinePresentation
                ? Promise.resolve({ url })
                : api.request(
                    queryString.buildUrl(
                        url, { response_type: 'data', viewable: 'off' }
                    )
                ))
                .then(data => {
                    return data.url;
                });
        }
    },
    presets: {
        list () {
            return api.request({
                url: `${BASE_URL}/presets/`
            });
        },
        create (props) {
            return api.request({
                url: `${BASE_URL}/presets/`,
                method: 'POST',
                data: { props },
                dataType: 'json'
            });
        },
        delete (id) {
            return api.request({
                url: `${BASE_URL}/presets/${id}/`,
                method: 'DELETE'
            });
        }
    },
    presentation: {
        getPresentation (presentationId) {
            return Settings.offlinePresentation
                ? Settings.offlinePresentation.slides
                : api.request({
                    url: `${BASE_URL}/${presentationId}/?fields=(slides,owner.branding)`
                });
        },
        updatePresentation (presentationId, data) {
            return api.request({
                url: `${BASE_URL}/${presentationId}/`,
                method: 'PATCH',
                data
            });
        },
        restore (presentation) {
            return api.request({
                url: `${BASE_URL}/${presentation.id}/?trash=true`,
                method: 'PATCH',
                data: { is_deleted: false }
            });
        },
        deleteForever (presentation) {
            return api.request({
                url: `${BASE_URL}/${presentation.id}/?trash=true`,
                method: 'DELETE'
            });
        },
        emptyTrash () {
            return api.request({
                url: `${BASE_URL}/empty-trash/`,
                method: 'POST'
            });
        },
        downloadPresentation (presentationId) {
            const os = (window.navigator.userAgent.indexOf('Windows') !== -1) ? 'win' : 'mac';
            return api.request({
                url: `${BASE_URL}/${presentationId}/download/?os=${os}`,
                method: 'GET'
            });
        },
        duplicatePresentation (presentationId) {
            return api.request({
                url: `${BASE_URL}/${presentationId}/duplicate/`,
                method: 'POST'
            });
        },
        migratePresentation (presentationId) {
            return api.request({
                url: `${BASE_URL}/${presentationId}/migrate/`,
                method: 'POST'
            });
        },
        shareLinkEmail (presentationId, data) {
            return api.request({
                url: `${BASE_URL}/${presentationId}/shareable-link-email/`,
                method: 'POST',
                data
            });
        }
    },
    slide: {
        createSlide (presentationId, data) {
            return api.request({
                url: `${BASE_URL}/${presentationId}/slides/`,
                method: 'POST',
                data
            });
        },
        updateSlide (slide, data) {
            return api.request({
                url: `${BASE_URL}/${slide.root.id}/slides/${slide.id}/`,
                method: 'PATCH',
                data
            });
        },
        removeSlide (slide) {
            return api.request({
                url: `${BASE_URL}/${slide.root.id}/slides/${slide.id}/`,
                method: 'DELETE'
            });
        },
        duplicateSlide (presentationId, slide) {
            return api.request({
                url: `${BASE_URL}/${presentationId}/slides/${slide.id}/duplicate/`,
                method: 'POST'
            });
        },
        updateContent (slide, newContent) {
            return api.request({
                url: `${BASE_URL}/${slide.root.id}/slides/${slide.id}/board/`,
                method: 'PUT',
                data: {
                    content: newContent
                }
            });
        },
        addAsset (slide, assetId) {
            return api.request({
                url: `${BASE_URL}/${slide.root.id}/slides/${slide.id}/assets/`,
                method: 'POST',
                data: {
                    action: 'add',
                    asset: assetId
                }
            });
        },
        removeAsset (slide, assetId) {
            return api.request({
                url: `${BASE_URL}/${slide.root.id}/slides/${slide.id}/assets/`,
                method: 'POST',
                data: {
                    action: 'remove',
                    asset: assetId
                }
            });
        }
    },
    board: {
        zOrder (board, zOrder) {
            return api.request({
                url: `${BASE_URL}/${board.root.id}/board/${board.id}/`,
                method: 'PATCH',
                data: {
                    z_order: zOrder
                }
            });
        },
        updateBoard (board, data) {
            return api.request({
                url: `${BASE_URL}/${board.root.id}/slides/${board.id}/board/`,
                method: 'PUT',
                data
            });
        },
        createItem (board, data) {
            return api.request({
                url: `${BASE_URL}/${board.root.id}/slides/${board.id}/board/items/`,
                method: 'POST',
                data
            });
        },
        updateItem (item, data) {
            return api.request({
                url: `${BASE_URL}/${item.root.id}/board/items/${item.id}/`,
                method: 'PATCH',
                data
            });
        },
        removeItem (item) {
            return api.request({
                url: `${BASE_URL}/${item.root.id}/board/items/${item.id}/`,
                method: 'DELETE'
            });
        },
        box: {
            create (board, data) {
                return api.request({
                    url: `${BASE_URL}/${board.root.id}/board/${board.id}/boxes/`,
                    method: 'POST',
                    data
                });
            },
            update (box, data) {
                return api.request({
                    url: `${BASE_URL}/${box.root.id}/board/boxes/${box.databaseId}/`,
                    method: 'PATCH',
                    data
                });
            },
            remove (box) {
                return api.request({
                    url: `${BASE_URL}/${box.root.id}/board/boxes/${box.databaseId}/`,
                    method: 'DELETE'
                });
            }
        },
        assetPin: {
            create (board, data) {
                return api.request({
                    url: `${BASE_URL}/${board.root.id}/board/${board.id}/asset-pins/`,
                    method: 'POST',
                    data
                });
            },
            update (assetPin, data) {
                return api.request({
                    url: `${BASE_URL}/${assetPin.root.id}/board/asset-pins/${assetPin.databaseId}/`,
                    method: 'PATCH',
                    data
                });
            },
            remove (assetPin) {
                return api.request({
                    url: `${BASE_URL}/${assetPin.root.id}/board/asset-pins/${assetPin.databaseId}/`,
                    method: 'DELETE'
                });
            }
        },
        textPin: {
            create (board, data) {
                return api.request({
                    url: `${BASE_URL}/${board.root.id}/board/${board.id}/text-pins/`,
                    method: 'POST',
                    data
                });
            },
            update (textPin, data) {
                return api.request({
                    url: `${BASE_URL}/${textPin.root.id}/board/text-pins/${textPin.databaseId}/`,
                    method: 'PATCH',
                    data
                });
            },
            remove (textPin) {
                return api.request({
                    url: `${BASE_URL}/${textPin.root.id}/board/text-pins/${textPin.databaseId}/`,
                    method: 'DELETE'
                });
            }
        },
        linkPin: {
            create (board, data) {
                return api.request({
                    url: `${BASE_URL}/${board.root.id}/board/${board.id}/link-pins/`,
                    method: 'POST',
                    data
                });
            },
            update (linkPin, data) {
                return api.request({
                    url: `${BASE_URL}/${linkPin.root.id}/board/link-pins/${linkPin.databaseId}/`,
                    method: 'PATCH',
                    data
                });
            },
            remove (linkPin) {
                return api.request({
                    url: `${BASE_URL}/${linkPin.root.id}/board/link-pins/${linkPin.databaseId}/`,
                    method: 'DELETE'
                });
            }
        },
        trashPins (board, data) {
            return api.request({
                url: `${BASE_URL}/${board.root.id}/board/${board.id}/trash-pins/`,
                method: 'PUT',
                data
            });
        }
    },
    tour: {
        createPlace (tour, data) {
            return api.request({
                url: `${BASE_URL}/${tour.root.id}/slides/${tour.id}/vtour/places/`,
                method: 'POST',
                data
            });
        },
        updatePlace (place, data) {
            return api.request({
                url: `${BASE_URL}/${place.root.id}/vtour/places/${place.id}/`,
                method: 'PATCH',
                data
            });
        },
        removePlace (place) {
            return api.request({
                url: `${BASE_URL}/${place.root.id}/vtour/places/${place.id}/`,
                method: 'DELETE'
            });
        },
        portal: {
            create (place, data) {
                return api.request({
                    url: `${BASE_URL}/${place.root.id}/vtour/places/${place.id}/portals/`,
                    method: 'POST',
                    data
                });
            },
            update (portal, data) {
                return api.request({
                    url: `${BASE_URL}/${portal.root.id}/vtour/portals/${portal.databaseId}/`,
                    method: 'PATCH',
                    data
                });
            },
            remove (portal) {
                return api.request({
                    url: `${BASE_URL}/${portal.root.id}/vtour/portals/${portal.databaseId}/`,
                    method: 'DELETE'
                });
            }
        },
        assetPin: {
            create (place, data) {
                return api.request({
                    url: `${BASE_URL}/${place.root.id}/vtour/places/${place.id}/asset-pins/`,
                    method: 'POST',
                    data
                });
            },
            update (assetPin, data) {
                return api.request({
                    url: `${BASE_URL}/${assetPin.root.id}/vtour/asset-pins/${assetPin.databaseId}/`,
                    method: 'PATCH',
                    data
                });
            },
            remove (assetPin) {
                return api.request({
                    url: `${BASE_URL}/${assetPin.root.id}/vtour/asset-pins/${assetPin.databaseId}/`,
                    method: 'DELETE'
                });
            }
        },
        textPin: {
            create (place, data) {
                return api.request({
                    url: `${BASE_URL}/${place.root.id}/vtour/places/${place.id}/text-pins/`,
                    method: 'POST',
                    data
                });
            },
            update (textPin, data) {
                return api.request({
                    url: `${BASE_URL}/${textPin.root.id}/vtour/text-pins/${textPin.databaseId}/`,
                    method: 'PATCH',
                    data
                });
            },
            remove (textPin) {
                return api.request({
                    url: `${BASE_URL}/${textPin.root.id}/vtour/text-pins/${textPin.databaseId}/`,
                    method: 'DELETE'
                });
            }
        },
        linkPin: {
            create (place, data) {
                return api.request({
                    url: `${BASE_URL}/${place.root.id}/vtour/places/${place.id}/link-pins/`,
                    method: 'POST',
                    data
                });
            },
            update (linkPin, data) {
                return api.request({
                    url: `${BASE_URL}/${linkPin.root.id}/vtour/link-pins/${linkPin.databaseId}/`,
                    method: 'PATCH',
                    data
                });
            },
            remove (linkPin) {
                return api.request({
                    url: `${BASE_URL}/${linkPin.root.id}/vtour/link-pins/${linkPin.databaseId}/`,
                    method: 'DELETE'
                });
            }
        },
        createMap (tour, data) {
            return api.request({
                url: `${BASE_URL}/${tour.root.id}/slides/${tour.id}/vtour/map/`,
                method: 'PUT',
                data
            });
        },
        updateMap (map, data) {
            return api.request({
                url: `${BASE_URL}/${map.tour.root.id}/slides/${map.tour.id}/vtour/map/`,
                method: 'PUT',
                data
            });
        },
        removeMap (map) {
            return api.request({
                url: `${BASE_URL}/${map.tour.root.id}/slides/${map.tour.id}/vtour/map/`,
                method: 'DELETE'
            });
        },
        createMapPin (map, data) {
            return api.request({
                url: `${BASE_URL}/${map.tour.root.id}/slides/${map.tour.id}/vtour/map/pins/`,
                method: 'POST',
                data
            });
        },
        updateMapPin (pin, data) {
            return api.request({
                url: `${BASE_URL}/${pin.root.id}/vtour/map-pins/${pin.id}/`,
                method: 'PATCH',
                data
            });
        },
        removeMapPin (pin) {
            return api.request({
                url: `${BASE_URL}/${pin.root.id}/vtour/map-pins/${pin.id}/`,
                method: 'DELETE'
            });
        }
    },
    asset: {
        pdfToImages ({ presentationId, boardId, assetUUID }) {
            return api.request({
                url: `${BASE_URL}/${presentationId}/assets/${assetUUID}/`,
                method: 'PUT',
                data: {
                    job_type: 'reprocess',
                    actions: ['explode_pdf', 'add_results_to_board'],
                    action_args: { add_results_to_board: { board_id: boardId } }
                }
            });
        },
        copyFromCloud (presentationId, data, actions = null) {
            return api.request({
                url: `${BASE_URL}/${presentationId}/assets/`,
                method: 'POST',
                data: {
                    job_type: 'copy',
                    file_version: {
                        owner: data.owner,
                        path: data.prefix,
                        provider: data.storageType,
                        version_id: data.versionId,
                        src_id: data.srcId
                    },
                    ...extractAssetActions(actions)
                }
            });
        },
        copyFromCloudBulk (presentationId, assets, actions = {}) {
            return api.request({
                url: `${BASE_URL}/${presentationId}/assets/bulk/`,
                method: 'POST',
                data: {
                    assets: assets.map(asset => ({
                        job_type: 'copy',
                        file_version: {
                            owner: asset.owner,
                            path: asset.prefix,
                            provider: asset.storageType,
                            version_id: asset.versionId,
                            src_id: asset.srcId
                        },
                        ...extractAssetActions(actions.asset)
                    })),
                    actions: actions.global || [GROUP_ASSET_JOB_ACTIONS.NONE]
                }
            });
        },
        getPathForUpload (presentationId, filename, actions = {}) {
            return api.request({
                url: `${BASE_URL}/${presentationId}/assets/`,
                method: 'POST',
                data: {
                    job_type: 'upload',
                    filename,
                    ...extractAssetActions(actions)
                }
            });
        },
        getPathForUploadBulk (presentationId, filenames, actions = {}) {
            return api.request({
                url: `${BASE_URL}/${presentationId}/assets/bulk/`,
                method: 'POST',
                data: {
                    assets: filenames.map(filename => ({
                        job_type: 'upload',
                        filename,
                        ...extractAssetActions(actions.asset)
                    })),
                    ...(actions.global ? { actions: actions.global } : {})
                }
            });
        },
        removeAsset (presentationId, assetId) {
            return api.request({
                url: `${BASE_URL}/${presentationId}/assets/${assetId}/`,
                method: 'DELETE'
            });
        },
        loadAssets (presentationId) {
            return api.request({
                url: `${BASE_URL}/${presentationId}/assets/`,
                method: 'GET'
            });
        }
    }
};

Object.defineProperty(api, 'request', {
    get () {
        if (!api._request) {
            api._request = createRequest();
        }
        return api._request;
    }
});

function extractAssetActions (actions) {
    return actions
        ? {
            actions: actions.actions || [ASSET_JOB_ACTIONS],
            action_args: actions.actionArgs || null
        }
        : {};
}

export default api;
