import { runInAction } from 'mobx';
import { assetLibraryProgressTracker } from '../../../progress/progress';
import { FilePath } from '../../../lib/file';
import { UploadFolder, get as getUploader } from '../../../upload/upload';
import api from '../../api';
import { boardBuilder, TargetType } from '../../models/board';
import { tourBuilder } from '../../models/tour';
import { ASSET_JOB_ACTIONS, GROUP_ASSET_JOB_ACTIONS } from '../../constants';
import { assetValidators } from '../../validations';
import ConfirmDialog from './../../Dialog/ConfirmDialog';
import { OwnerInfo } from '../../../share/api';

let uploader = null;

function allowAny (files) {
    return Promise.resolve([files, []]);
}

function createUploadFolder () {
    return new UploadFolder('iboards', OwnerInfo.own(), []);
}

function createUploader () {
    return uploader
        ? Promise.resolve(uploader)
        : getUploader({
            bucket: 'internal',
            progressTracker: assetLibraryProgressTracker,
            browseButtonSelector: 'upload-to-library',
            uploadValidation: allowAny
        });
}

function browse ({ rootStore, uploadValidation, collectionStore }) {
    createUploader().then(uploaderInstance => {
        uploader = uploaderInstance;
        uploader.setInputTypes(uploadValidation.settings.htmlInputTypes);
        uploader.uploadInputChange = async files => {
            const [validFiles] = await uploadValidation(files);
            if (!validFiles.length) return;
            const jobs = await api.asset.getPathForUploadBulk(
                rootStore.id,
                validFiles.map(f => f.name)
            );
            jobs.map(job => job.asset).forEach(asset => {
                rootStore.asset.createOrUpdateAssetFromData(asset);
                const collectionItem = collectionStore.index.map.get(asset.uuid);
                collectionItem && collectionItem.select();
            });
            const paths = jobs.map(job => job.file_placeholder_path);
            paths.forEach((path, i) => {
                uploader.startNamedUpload(validFiles[i], FilePath(path).name());
            });
        };
        uploader.browse(createUploadFolder());
    });
}

async function addAssetsToPresentation (rootStore, files, forceDisableSlideCreation = false) {
    const uploader = await createUploader();
    const folder = createUploadFolder();
    const [validFiles] = await assetValidators.PRESENTATION.forUpload(
        rootStore.asset,
        rootStore.dialog,
        gettext('The following files are not supported in a presentation and will not be uploaded.')
    )(files);
    if (!validFiles.length) return;
    const hasPdfs = validFiles.some(file => file.type === 'application/pdf');
    const explodePdf = await (hasPdfs
        ? rootStore.dialog.open({
            component: ConfirmDialog,
            context: {
                title: gettext('Convert PDF pages to images?'),
                yesTitle: gettext('Yes'),
                noTitle: gettext('No'),
                text: `${gettext('This creates an image file for each PDF page, which you can add directly to board slides. PDF files can only be added as pins.')}`,
                submitAnalytics: {
                    'ga-action': 'Confirm_PDF_Exploding_Dialog',
                    'ga-label': 'Click_Submit'
                },
                cancelAnalytics: {
                    'ga-action': 'Confirm_PDF_Exploding_Dialog',
                    'ga-label': 'Click_Cancel'
                }
            }
        }).result.then(() => true, () => false)
        : Promise.resolve(false));

    const jobs = await api.asset.getPathForUploadBulk(
        rootStore.id,
        files.map(f => f.name),
        {
            asset: {
                actions: explodePdf
                    ? [ASSET_JOB_ACTIONS.EXPLODE_PDF]
                    : [ASSET_JOB_ACTIONS.NONE]
            },
            global: forceDisableSlideCreation
                ? [GROUP_ASSET_JOB_ACTIONS.NONE]
                : (rootStore.makeSlidesFromAssets
                    ? [GROUP_ASSET_JOB_ACTIONS.MAKE_SLIDE]
                    : [GROUP_ASSET_JOB_ACTIONS.NONE])
        }
    );
    jobs.map(job => job.asset).forEach(asset =>
        rootStore.asset.createOrUpdateAssetFromData(asset)
    );
    const paths = jobs.map(job => job.file_placeholder_path);
    paths.forEach((path, i) => {
        uploader.startNamedUpload(files[i], FilePath(path).name(), folder);
    });
}

async function replaceBoardMedia (rootStore, board, files) {
    const uploader = await createUploader();
    const folder = createUploadFolder();
    const [validFiles] = await assetValidators.LAYOUT.forUploadOfOneFile(
        rootStore.asset,
        rootStore.dialog,
        gettext('The following files are not supported as a Board base and will not be uploaded.')
    )(files);
    const file = validFiles.length && validFiles[0];
    if (!file) return;
    if (board.mediaPins.length) {
        const shouldContinue = await rootStore.dialog.open({
            component: ConfirmDialog,
            context: {
                title: gettext('Delete confirmation'),
                yesTitle: gettext('Delete'),
                noTitle: gettext('Cancel'),
                text: gettext('Are you sure you want to remove the media from the board? You will lose all your pins as well.'),
                submitAnalytics: {
                    'ga-action': 'Delete_Board_Media_Dialog',
                    'ga-label': 'Click_Submit'
                },
                cancelAnalytics: {
                    'ga-action': 'Delete_Board_Media_Dialog',
                    'ga-label': 'Click_Cancel'
                }
            }
        }).result.then(() => true, () => false);
        if (!shouldContinue) return;
    }
    const job = await api.asset.getPathForUpload(
        rootStore.id, file.name,
        {
            actions: [ASSET_JOB_ACTIONS.REPLACE_BOARD_MEDIA],
            actionArgs: {
                [ASSET_JOB_ACTIONS.REPLACE_BOARD_MEDIA]: {
                    board_id: board.id
                }
            }
        }
    );
    runInAction(() => {
        rootStore.asset.createOrUpdateAssetFromData(job.asset);
        board.media = job.action_results[ASSET_JOB_ACTIONS.REPLACE_BOARD_MEDIA].media;
        board.items.forEach(item => {
            item.pins.replace(item.pins.filter(p => p.targetType === TargetType.TEXT));
        });
    });
    const path = job.file_placeholder_path;
    uploader.startNamedUpload(
        file, FilePath(path).name(), folder
    );
}

async function replaceMapImage (rootStore, tour, files) {
    const uploader = await createUploader();
    const folder = createUploadFolder();
    const [validFiles] = await assetValidators.IMAGE.forUploadOfOneFile(
        rootStore.asset,
        rootStore.dialog,
        gettext('The following files are not supported in a Tour map and will not be uploaded.')
    )(files);
    const file = validFiles.length && validFiles[0];
    if (!file) return;
    if (tour.map && tour.map.pins.length) {
        const shouldContinue = await rootStore.dialog.open({
            component: ConfirmDialog,
            context: {
                title: gettext('Delete confirmation'),
                yesTitle: gettext('Delete'),
                noTitle: gettext('Cancel'),
                text: gettext('Are you sure you want to remove the media from the map? You will lose all your pins as well.'),
                submitAnalytics: {
                    'ga-action': 'Delete_Map_Dialog',
                    'ga-label': 'Click_Submit'
                },
                cancelAnalytics: {
                    'ga-action': 'Delete_Map_Dialog',
                    'ga-label': 'Click_Cancel'
                }
            }
        }).result.then(() => true, () => false);
        if (!shouldContinue) return;
    }
    const actions = tour.map
        ? {
            actions: [ASSET_JOB_ACTIONS.REPLACE_MAP_IMAGE],
            actionArgs: {
                [ASSET_JOB_ACTIONS.REPLACE_MAP_IMAGE]: {
                    map_id: tour.map.id
                }
            }
        }
        : {
            actions: [ASSET_JOB_ACTIONS.CREATE_MAP],
            actionArgs: {
                [ASSET_JOB_ACTIONS.CREATE_MAP]: {
                    tour_id: tour.id
                }
            }
        };
    const job = await api.asset.getPathForUpload(rootStore.id, file.name, actions);
    if (tour.map) {
        runInAction(() => {
            rootStore.asset.createOrUpdateAssetFromData(job.asset);
            tour.map.pins.replace([]);
            tour.map.image = job.action_results[ASSET_JOB_ACTIONS.REPLACE_MAP_IMAGE].image;
        });
    } else {
        runInAction(() => {
            rootStore.asset.createOrUpdateAssetFromData(job.asset);
            tour.map = tourBuilder.createMap(
                tour, job.action_results[ASSET_JOB_ACTIONS.CREATE_MAP].map
            );
        });
    }
    const path = job.file_placeholder_path;
    uploader.startNamedUpload(file, FilePath(path).name(), folder);
}

async function addItemsToBoard (rootStore, board, files) {
    const uploader = await createUploader();
    const folder = createUploadFolder();
    const [validFiles] = await assetValidators.BOARD.forUpload(
        rootStore.asset,
        rootStore.dialog,
        gettext('The following files are not supported in a Board and will not be uploaded.')
    )(files);
    if (!validFiles.length) return;
    const jobs = await api.asset.getPathForUploadBulk(
        rootStore.id,
        validFiles.map(f => f.name),
        {
            asset: {
                actions: [ASSET_JOB_ACTIONS.ADD_TO_BOARD],
                actionArgs: {
                    [ASSET_JOB_ACTIONS.ADD_TO_BOARD]: {
                        board_id: board.id
                    }
                }
            }
        }
    );
    jobs.map(job => job.asset).forEach(asset =>
        rootStore.asset.createOrUpdateAssetFromData(asset)
    );
    jobs.forEach(job => {
        const newItem = boardBuilder.createItem(
            board, job.action_results[ASSET_JOB_ACTIONS.ADD_TO_BOARD].item
        );
        const oldItemIndex = board.items.findIndex(item => item.id === newItem.id);
        if (oldItemIndex !== -1) {
            board.items[oldItemIndex] = newItem;
        } else {
            board.items.push(newItem);
        }
    });
    const paths = jobs.map(job => job.file_placeholder_path);
    paths.forEach((path, i) => {
        uploader.startNamedUpload(files[i], FilePath(path).name(), folder);
    });
}

async function addPlacesToTour (rootStore, tour, files) {
    const uploader = await createUploader();
    const folder = createUploadFolder();
    const [validFiles] = await assetValidators.PANORAMA.forUpload(
        rootStore.asset,
        rootStore.dialog,
        gettext('The following files are not supported in a Tour and will not be uploaded.')
    )(files);
    if (!validFiles.length) return;
    const jobs = await api.asset.getPathForUploadBulk(
        rootStore.id,
        validFiles.map(f => f.name),
        {
            asset: {
                actions: [ASSET_JOB_ACTIONS.ADD_TO_TOUR],
                actionArgs: {
                    [ASSET_JOB_ACTIONS.ADD_TO_TOUR]: {
                        tour_id: tour.id
                    }
                }
            }
        }
    );
    jobs.map(job => job.asset).forEach(asset =>
        rootStore.asset.createOrUpdateAssetFromData(asset)
    );
    jobs.forEach(job => {
        const newPlace = tourBuilder.createPlace(
            tour, job.action_results[ASSET_JOB_ACTIONS.ADD_TO_TOUR].place
        );
        const oldPlaceIndex = tour.places.findIndex(place => place.id === newPlace.id);
        if (oldPlaceIndex !== -1) {
            tour.places[oldPlaceIndex] = newPlace;
        } else {
            tour.places.push(newPlace);
        }
    });
    const paths = jobs.map(job => job.file_placeholder_path);
    paths.forEach((path, i) => {
        uploader.startNamedUpload(files[i], FilePath(path).name(), folder);
    });
}

async function addAssetsToTour (rootStore, tour, files) {
    const uploader = await createUploader();
    const folder = createUploadFolder();
    const [validFiles] = await assetValidators.BOARD.forUpload(
        rootStore.asset,
        rootStore.dialog,
        gettext('The following files are not supported in a Tour and will not be uploaded.')
    )(files);
    if (!validFiles.length) return;
    const jobs = await api.asset.getPathForUploadBulk(
        rootStore.id,
        validFiles.map(f => f.name)
    );
    jobs.map(job => job.asset).forEach(asset =>
        rootStore.asset.createOrUpdateAssetFromData(asset)
    );
    tour.addAssets(jobs.map(j => j.asset));
    const paths = jobs.map(job => job.file_placeholder_path);
    paths.forEach((path, i) => {
        uploader.startNamedUpload(files[i], FilePath(path).name(), folder);
    });
}

export {
    browse,
    addAssetsToPresentation,
    replaceBoardMedia,
    replaceMapImage,
    addItemsToBoard,
    addPlacesToTour,
    addAssetsToTour
};
