import { request } from '../../../base/axios';
import { queryString } from '../../../lib';

import { baseApi, COMMENTS_API_ROOT } from '../../../comments/api';

const fileViewApi = {
    registerNotificationListener (asset) {
        if (!Settings.user.isAuthenticated) return;

        const { storageType, versionId, owner, prefix, ownerInfo } = asset;

        return request({
            method: 'POST',
            url: `${COMMENTS_API_ROOT}comment-notification-listener/`,
            data: {
                resource: {
                    resource_type: 'file',
                    object: {
                        version_id: versionId,
                        owner,
                        provider: storageType,
                        path: prefix
                    }
                },
                link_uuid: ownerInfo.link && ownerInfo.link.uuid
            }
        });
    },
    submitComment (asset, content, mentions, metadata) {
        const { storageType, versionId, owner, prefix, ownerInfo } = asset;
        return request({
            method: 'POST',
            url: `${COMMENTS_API_ROOT}comment/`,
            data: {
                content,
                mentions,
                resource: {
                    resource_type: 'file',
                    object: {
                        version_id: versionId,
                        owner,
                        provider: storageType,
                        path: prefix
                    }
                },
                link_uuid: ownerInfo.link && ownerInfo.link.uuid,
                metadata
            }
        });
    },
    submitReply (asset, comment, mentions) {
        const { ownerInfo } = asset;
        return request({
            method: 'POST',
            url: `${COMMENTS_API_ROOT}reply/`,
            data: {
                ...comment,
                link_uuid: ownerInfo.link && ownerInfo.link.uuid,
                mentions
            }
        });
    },
    getCommentsFromFile (asset) {
        const { storageType, owner, prefix } = asset;
        return request({
            method: 'GET',
            url: `${Settings.API_ROOT}${storageType}/file/:comments/o:${owner}/p:${prefix}/`
        });
    },
    getCommentsFromShareableLink (asset, linkUuid) {
        const { prefix } = asset;
        return request({
            method: 'GET',
            url: `${Settings.API_ROOT}shareable_link/:comments/${linkUuid}${prefix ? '/file/p:' + prefix : ''}/`
        });
    },
    deleteComment (asset, commentId) {
        const { ownerInfo } = asset;
        const qs = queryString.stringify(ownerInfo.link ? { link_uuid: ownerInfo.link.uuid } : {});
        return request({
            method: 'DELETE',
            url: `${COMMENTS_API_ROOT}comment/${commentId}/${qs}`
        });
    },
    deleteReply (asset, replyId) {
        const { ownerInfo } = asset;
        const qs = queryString.stringify(ownerInfo.link ? { link_uuid: ownerInfo.link.uuid } : {});
        return request({
            method: 'DELETE',
            url: `${COMMENTS_API_ROOT}reply/${replyId}/${qs}`
        });
    },
    updateComment (asset, comment, mentions = []) {
        const { ownerInfo } = asset;
        const { id } = comment;
        return request({
            method: 'PATCH',
            url: `${COMMENTS_API_ROOT}comment/${id}/`,
            data: {
                ...comment,
                link_uuid: ownerInfo.link && ownerInfo.link.uuid,
                mentions
            }
        });
    },
    updateReply (asset, comment, mentions) {
        const { ownerInfo } = asset;
        const { content, id } = comment;
        return request({
            method: 'PATCH',
            url: `${COMMENTS_API_ROOT}reply/${id}/`,
            data: {
                content,
                link_uuid: ownerInfo.link && ownerInfo.link.uuid,
                mentions
            }
        });
    },
    getComment (asset, commentId) {
        const { ownerInfo } = asset;
        return request({
            method: 'GET',
            url: `${COMMENTS_API_ROOT}comment/${commentId}/`,
            data: {
                link_uuid: ownerInfo.link && ownerInfo.link.uuid
            }
        });
    },
    getReply (asset, replyId) {
        const { ownerInfo } = asset;

        const qs = queryString.stringify(ownerInfo.link ? { link_uuid: ownerInfo.link.uuid } : {});

        return request({
            method: 'GET',
            url: `${COMMENTS_API_ROOT}reply/${replyId}/${qs}`
        });
    },
    checkAccess (asset, user) {
        const { storageType, owner, prefix, ownerInfo } = asset;
        return request(`${Settings.API_ROOT}${storageType}/file/:shared-with/o:${owner}/p:${prefix}/`,
            {
                params: {
                    user: JSON.stringify(user),
                    link_uuid: ownerInfo.link && ownerInfo.link.uuid
                }
            });
    }
};

export default {
    ...baseApi,
    ...fileViewApi
};
