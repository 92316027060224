import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';

import DraggableItems from './DraggableItems';
import { toast } from '../../toast';

export class DraggablePinCreator {
    what = 'pin-creator';

    constructor (type, { asset, id } = {}) {
        this.id = id || asset?.id || type;
        this.type = type;
        this.isDraggablePinCreator = true;
        this.asset = asset;

        this.which = this.type;
    }

    getTypeIcon () {
        return {
            ASSET: 'drag-drop-file',
            TEXT: 'drag-drop-text',
            LINK: 'drag-drop-web-link'
        }[this.type];
    }
}

DraggablePinCreator.PENDING_TYPE = new DraggablePinCreator('PENDING_TYPE');
DraggablePinCreator.ASSET = new DraggablePinCreator('ASSET');
DraggablePinCreator.TEXT = new DraggablePinCreator('TEXT');
DraggablePinCreator.LINK = new DraggablePinCreator('LINK');

const ALL_PIN_CREATORS = [
    DraggablePinCreator.PENDING_TYPE
    // DraggablePinCreator.ASSET,
    // DraggablePinCreator.TEXT,
    // DraggablePinCreator.LINK
];

const PinCreator = styled.div`
    position: relative;
    border-radius: 100%;
    color: var(--text-primary-color);
    cursor: grab;
    margin: 0 8px;

    i {
        display: block;
    }

    .draggingPin {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        cursor: default;
    }

    ${({ isDragging }) => isDragging && css`
        outline: 0;
        background-color: transparent;
        box-shadow: none;

        > * {
            opacity: ${isDragging ? 0 : 1};
        }
    `}
`;

// Children renders DraggedPin.
function PinWidget ({
    isDragging,
    renderContent = () => <Icon icon='add-pin' />,
    children
}) {
    return (
        <PinCreator
            isDragging={isDragging}
            onClick={ () => toast('PIN.DRAG_TO_CREATE') }
            title={gettext('Add pin')}
        >
            {!isDragging && renderContent()}
            {children}
        </PinCreator>
    );
}

PinWidget.propTypes = {
    isDragging: PropTypes.bool,
    renderContent: PropTypes.func
};

function DraggablePinCreators ({
    editor,
    pinCreators = ALL_PIN_CREATORS,
    renderContent
}) {
    return (
        <div style={{ alignSelf: 'center' }}>
            <DraggableItems
                editor={editor}
                dragDropMonitor={editor.dragDropMonitor}
                items={pinCreators}
                carouselItemType={(props) => (
                    <PinWidget
                        {...props}
                        renderContent={renderContent}
                    />)}
                isDropDisabled
            />
        </div>
    );
}

DraggablePinCreators.propTypes = {
    editor: PropTypes.shape({
        slide: PropTypes.object,
        dragDropMonitor: PropTypes.object,
        place: PropTypes.object,
        placeProps: PropTypes.object,
        image: PropTypes.object
    }).isRequired,
    pinCreators: PropTypes.arrayOf(
        PropTypes.instanceOf(DraggablePinCreator)
    ),
    renderContent: PropTypes.func
};

export default DraggablePinCreators;
