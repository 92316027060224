import { UploadStatus } from '../upload/utils';

function ProgressTracker () {
    const self = this;

    self.init = function () {
        self.items = ko.observableArray([]);
        self.loading = ko.observable(false);
        self.isEmpty = ko.pureComputed(function () {
            return self.items().length === 0;
        }, self);

        self.beforeUnloadListener();
    };

    self.get = function (itemId) {
        return self.items().find(item => item.id === itemId);
    };

    self.add = function (item) {
        (!self.get(item.id)) && self.items.push(item);
        return item;
    };

    self.remove = function (item) {
        self.items.remove(item);
    };

    self.clearFinished = function () {
        self.items.remove(function (item) {
            return item.isCompleted();
        });
    };

    self.hasUploadingFiles = function () {
        const find = ko.utils.arrayFirst(self.items(), function (item) {
            const status = item.status().text;
            return status === UploadStatus.uploading.text || status === UploadStatus.pending.text;
        });
        return find;
    };

    self.beforeUnloadListener = function () {
        !Settings.IN_WEB_VIEW && window.addEventListener('beforeunload', function (e) {
            e = e || window.event;

            if (self.hasUploadingFiles()) {
                const message = gettext('Files are currently uploading. If you leave the page, the upload will be canceled.');
                e.returnValue = message; // Gecko, Trident, Chrome 34+
                return message; // Gecko, WebKit, Chrome <34
            }
            delete e.returnValue;
        });
    };

    self.init();
}

const progressTracker = new ProgressTracker();
const assetLibraryProgressTracker = new ProgressTracker();
const brandingProgressTracker = new ProgressTracker();

export {
    progressTracker,
    assetLibraryProgressTracker,
    brandingProgressTracker
};
