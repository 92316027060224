import pubsub from '../base/pubsub';
import MessengerClient from './messenger-client';

let client = null;
let ready = false;
let pendingTopics = [];
const topics = {};
const channelEnvPrefix = Settings.VCS_ENV === 'prod'
    ? ''
    : `${Settings.VCS_ENV}-`;

function createTopic (name, version, isGlobal) {
    const topic = {
        topic: `push-notification.${version}.${name}`,
        channel: window.Settings.USE_MESSENGER_CLIENT
            ? `${isGlobal ? 'public' : window.Settings.user.login}/${version}/${channelEnvPrefix}${name}`
            : `/vcs/${version}/${channelEnvPrefix}${name}/${(isGlobal ? '' : window.Settings.user.login) + '/'}`
    };
    topics[topic.channel] = topic;
    return topic;
}

function init () {
    if (window.Settings.user.isRemoteUser || !window.Settings.user.isAuthenticated) return;
    if (window.Settings.USE_MESSENGER_CLIENT) {
        client = new MessengerClient();
        client.on('connect', function () {
            console.log('messenger client connected');
            client.subscribe(pendingTopics.map(topic => topic.channel));
            pendingTopics = [];
            ready = true;
        });
        client.on('reconnect', function () {
            console.log('messenger client reconnected');
            client.subscribe(Object.keys(topics));
        });

        client.on('message', function (topic_id, data) {
            const topic = topics[topic_id];
            if (topic) {
                pubsub.publish(topic.topic, data);
            }
        });

        client.on('error', function (error) {
            console.log('messenger client error', error);
            throw new Error(`Push notification error: ${JSON.stringify(error)}`);
        });

        client.connect();
    } else {
        client = window.io(window.Settings.watcher.NOTIFIER_URL);
        client.on('connect', function () {
            client.emit(
                'subscribe',
                { channels: pendingTopics.map(topic => topic.channel) }
            );
            pendingTopics = [];
            ready = true;
        });
        client.on('reconnect', function () {
            client.emit(
                'subscribe',
                { channels: Object.keys(topics) }
            );
        });

        client.on('message', function (msg) {
            if (msg.error) {
                throw new Error(`Push notification error: ${msg.error}`);
            }
            const event = msg.payload;
            const topic = topics[msg.channel];
            if (topic) {
                pubsub.publish(topic.topic, event);
            }
        });
    }
}

function registerListener (topicName, topicVersion, isGlobal = false) {
    const topic = createTopic(topicName, topicVersion, isGlobal);

    if (!ready) {
        pendingTopics.push(topic);
        if (!client) {
            init();
        }
    } else {
        if (window.Settings.USE_MESSENGER_CLIENT) {
            client.subscribe([topic.channel]);
        } else {
            client.emit('subscribe', { channels: [topic.channel] });
        }
    }

    return {
        topic: topic.topic,
        subscribe (handler) {
            pubsub.subscribe(this, topic.topic, handler);
        },
        unsubscribe () {
            pubsub.unsubscribe(this, topic.topic);
        }
    };
}

if (window.Settings.NEW_FEATURES.isActive('manual_push_notifications')) {
    window.copyJobMessage = function (data) {
        pubsub.publish('push-notification.v1.job.copy', { event: null, data });
    };
    // Example: copyJobMessage({uuid: 'test', state: 'progress', progress: 0, date_modified: '2021-09-29T15:36:32.144455', date_created: '2021-09-29T15:36:32.144455'})
}

export default { listen: registerListener };
