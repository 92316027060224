import { queryString } from '../lib';

const NO_VALUE = 'NO_VALUE';
const cache = new Map();
const withUserPrefix = key => `${Settings.user.login}.requestCache.${key}`;
const predicates = [
    url => url.pathname === '/restapi/public/v1/jobs/' &&
        (typeof (queryString.parse(url.search).current) !== 'undefined')
];

function cacheableUrl (url) {
    const parsedUrl = queryString.parseUrl(url);
    for (const predicate of predicates) {
        if (predicate(parsedUrl)) {
            return true;
        }
    }
    return false;
}

function storageGet (url) {
    if (!cacheableUrl(url)) {
        return;
    }
    let value = cache.get(withUserPrefix(url));
    if (!value) {
        value = window.store.get(withUserPrefix(url));
        cache.set(url, value || NO_VALUE);
    }
    return value !== NO_VALUE
        ? value
        : null;
}

function storageSet (url, value) {
    cache.set(url, value);
    window.store.set(withUserPrefix(url), value);
}

export default {
    get: storageGet,
    set: storageSet
};
