import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import withResponsiveListeners from '../../../../utils/Responsive';

import Entity from './Entity';
import Cell from './Cell';
import S from './styles';
import MobileContents from './MobileContents';
import LgContents from './LgContents';

const Arrow = () => <S.Arrow>&#10140;</S.Arrow>;

const Circuit = observer(
    ({ item, onClick, store, screen }) => {
        const isMobile = React.useMemo(() => !['md', 'lg'].includes(screen.size), [screen]);

        return (
            <Entity
                item={item}
                store={store}
                onClick={onClick}
                header={(open, toggle) => isMobile
                    ? <React.Fragment>
                        <S.Toggler className='cllps-toggle' expand={open} onClick={toggle} />
                        {
                            !open &&
                            <S.TogglerDetails columns={4}>
                                <Cell
                                    key={`${item.key}#number`}
                                    cell={store.columns.map.get('number')}
                                    item={item}
                                    store={store}
                                />
                                <S.CellPair>
                                    <Cell
                                        key={`${item.key}#srcDeviceName`}
                                        cell={store.columns.map.get('srcDeviceName')}
                                        item={item}
                                        store={store}
                                    />
                                    <Cell
                                        key={`${item.key}#srcSocketName`}
                                        cell={store.columns.map.get('srcSocketName')}
                                        item={item}
                                        store={store}
                                    />
                                </S.CellPair>
                                <S.CellPair>
                                    <Arrow />
                                </S.CellPair>
                                <S.CellPair>
                                    <Cell
                                        key={`${item.key}#dstDeviceName`}
                                        cell={store.columns.map.get('dstDeviceName')}
                                        item={item}
                                        store={store}
                                    />
                                    <Cell
                                        key={`${item.key}#dstSocketName`}
                                        cell={store.columns.map.get('dstSocketName')}
                                        item={item}
                                        store={store}
                                    />
                                </S.CellPair>
                            </S.TogglerDetails>
                        }
                    </React.Fragment>
                    : <LgContents item={item} store={store} onClick={onClick} />}
                collapse={isMobile &&
                <MobileContents item={item} store={store}/>}
            />
        );
    }
);

Circuit.propTypes = {
    item: PropTypes.object,
    store: PropTypes.object,
    onClick: PropTypes.func
};

export default withResponsiveListeners(Circuit);
