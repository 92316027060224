import React, { Component } from 'react';

import { autorun } from 'mobx';
import { observer } from 'mobx-react';

import { commonPropTypes } from './utils';
import { detectPanoramaImage } from '~static/file-viewer/panorama/jpeg';
import { Loader } from '~static/file-viewer/panorama/loader';
import ImageController from './Controllers/ImageController';
import ImageViewer from './ImageViewer';
import PanoramaController from './Controllers/PanoramaController';
import PanoramaViewer from './PanoramaViewer';
import { queryString } from '~static/js/lib';
import { qrCodeAction } from '../../share/qr-code-action';
import { FileTypes } from '~static/js/lib/file';

class ImageLoader extends Component {
    name = 'ImageLoader';
    sourceUrl = '';

    constructor (props) {
        super(props);
        this.state = {
            imageUrl: '',
            isPanorama: 'unknown'
        };
    };

    componentDidMount () {
        this.autorunDisposer = autorun(() => this.props.store.file.sourceUrl && this.loadFile());
    }

    componentWillUnmount () {
        this.autorunDisposer();
    }

    loadFile = () => {
        if (this.sourceUrl !== this.props.store.file.sourceUrl) {
            this.sourceUrl = this.props.store.file.sourceUrl;
            this.props.store.startLoading();

            const imagePromise = Settings.offlinePresentation
                ? Promise.resolve(this.props.store.file.offlineSourceUrlResp)
                : $.getJSON(queryString.buildUrl(
                    this.props.store.file.sourceUrl,
                    {
                        response_type: 'data',
                        viewable: 'off'
                    }
                ));

            imagePromise.catch(err => {
                if (err?.status === 404) {
                    this.props.store.file.setNotFoundFile();
                }
            })
                .then(data => {
                    this.loadImage(this.props.store.file.file.fileType.type === FileTypes.panorama, data.url);
                });
        }
    };

    loadImage = (isPanorama, imageUrl) => {
        const loader = new Loader();
        loader.setResponseType('blob');
        loader.crossOrigin = '';

        loader.load(
            imageUrl,
            imageBlob => {
                const urlCreator = window.URL || window.webkitURL;
                const url = urlCreator.createObjectURL(imageBlob);
                if (isPanorama) {
                    this.showImage(isPanorama, url);
                } else {
                    detectPanoramaImage(isPanorama => {
                        this.showImage(isPanorama, url);
                    })(imageBlob);
                }
            },
            ({ loaded, total }) => {}
        );
    };

    showImage = (isPanorama, imageUrl) => {
        if (isPanorama && this.props.store.file.file.fileType.type === FileTypes.image) {
            qrCodeAction(FileTypes.panorama, this.props.store.file);
        }
        this.setState({ imageUrl, isPanorama });
    };

    renderViewer = () => {
        const { children, ...props } = this.props;
        return this.state.isPanorama
            ? <React.Fragment>
                <PanoramaViewer
                    {...this.props}
                    sourceUrl={this.state.imageUrl}
                    ref={instance => {
                        this.viewer = instance;
                    }}
                >
                    {children}
                </PanoramaViewer>
                <PanoramaController {...props} viewer={this.viewer}/>
            </React.Fragment>
            : <React.Fragment>
                <ImageViewer
                    {...this.props}
                    sourceUrl={this.state.imageUrl}
                    ref={instance => {
                        this.viewer = instance;
                    }}
                >
                    {children}
                </ImageViewer>
                <ImageController {...props} viewer={this.viewer}/>
            </React.Fragment>;
    };

    render () {
        return this.state.isPanorama === 'unknown'
            ? (<div className='fileview-component-loader' data-what='file-viewer'>
                { this.props.children }
            </div>)
            : this.renderViewer();
    }
};

export default observer(ImageLoader);

ImageLoader.propTypes = {
    ...commonPropTypes
};
