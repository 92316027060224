import Intro from './Intro';
import Tell from './Tell';
import Show from './Show';
import Tour from './Tour';
import Empty from './Empty';
import TitleAndMedia from './TitleAndMedia';

export default {
    intro: Intro,
    'title-and-media': TitleAndMedia,
    'tell-right': Tell,
    'tell-left': Tell,
    'show-right': Show,
    'show-left': Show,
    tour: Tour,
    empty: Empty
};
