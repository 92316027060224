import { makeAutoObservable, runInAction, observable } from 'mobx';

import storage from '../../../storage';

const fmt = x => `${parseFloat(x.toFixed(2))}`;

const UNITS = {
    length: {
        m: {
            id: 'm',
            // translators: abbreviation of the unit of measurement "meter"
            title: gettext('m'),
            toBase: x => x,
            fromBase: x => x
        },
        mm: {
            id: 'mm',
            // translators: abbreviation of the unit of measurement "millimeter"
            title: gettext('mm'),
            toBase: x => fmt(x / 1000),
            fromBase: x => fmt(1000 * x)
        },
        in: {
            id: 'in',
            // translators: abbreviation of the unit of measurement "inch"
            title: gettext('in'),
            toBase: x => fmt(0.0254 * x),
            fromBase: x => fmt(x / 0.0254)
        },
        ft: {
            id: 'ft',
            // translators: abbreviation of the unit of measurement "foot"
            title: gettext('ft'),
            toBase: x => fmt(0.3048 * x),
            fromBase: x => fmt(x / 0.3048)
        },
        ftin: {
            id: 'ftin',
            // translators: abbreviation of the unit of measurement "foot / inch"
            title: gettext('ft/in'),
            toBase: x => {
                const feet = Number(x.substring(0, x.indexOf('\'')));
                const inches = Number(x.substring(x.indexOf('\''), x.indexOf('"')));
                return fmt(0.3048 * feet + 0.0254 * inches);
            },
            fromBase: x => {
                const feet = Math.floor(x / 0.3048);
                const inches = fmt((x - feet * 0.3048) / 0.0254);
                return feet
                    ? `${feet}'${inches}"`
                    : `${inches}"`;
            }
        }
    },
    weight: {
        kg: {
            id: 'kg',
            // translators: abbreviation of the unit of measurement "kilogram"
            title: gettext('kg'),
            toBase: x => x,
            fromBase: x => x
        },
        lbs: {
            id: 'lbs',
            // translators: abbreviation of the unit of measurement "pound"
            title: gettext('lbs'),
            toBase: x => fmt(0.453592 * x),
            fromBase: x => fmt(x / 0.453592)
        }
    },
    power: {
        w: {
            id: 'w',
            // translators: abbreviation of the unit of measurement "watt"
            title: gettext('W'),
            toBase: x => x,
            fromBase: x => x
        },
        kw: {
            id: 'kw',
            // translators: abbreviation of the unit of measurement "kilowatt"
            title: gettext('kW'),
            toBase: x => fmt(1000 * x),
            fromBase: x => fmt(x / 1000)
        }
    }
};

const COL_TO_UNIT = {
    cableLength: {
        unitType: 'length',
        defaultUnit: UNITS.length.m
    },
    deviceDimensions: {
        unitType: 'length',
        defaultUnit: UNITS.length.mm
    },
    weight: {
        unitType: 'weight',
        defaultUnit: UNITS.weight.kg
    },
    power: {
        unitType: 'power',
        defaultUnit: UNITS.power.w
    }
};

class Unit {
    unit;

    constructor (column, unitStore) {
        this.column = column;
        const savedValue = storage.get(`ccad.units.${column}`);
        this.unit = UNITS[COL_TO_UNIT[column].unitType][savedValue] || COL_TO_UNIT[column].defaultUnit;
        this.default = COL_TO_UNIT[column].defaultUnit;
        this.unitType = COL_TO_UNIT[column].unitType;
        this.store = unitStore;

        makeAutoObservable(this);
    }
}

class UnitsStore {
    static CONVERTIBLE_COLUMNS = {
        cableLength: {
            unitType: 'length',
            defaultUnit: UNITS.length.m
        },
        deviceDimensions: {
            unitType: 'length',
            defaultUnit: UNITS.length.mm
        },
        weight: {
            unitType: 'weight',
            defaultUnit: UNITS.weight.kg
        },
        power: {
            unitType: 'power',
            defaultUnit: UNITS.power.w
        }
    };

    columnUnits = observable.map([], { deep: false });

    constructor () {
        this.columnUnits = new Map();
        Object.keys(COL_TO_UNIT).forEach(col => {
            this.columnUnits.set(col, new Unit(col, this));
        });
        makeAutoObservable(this);
    }

    setUnits = data => {
        Object.entries(data).forEach(([col, value]) => {
            storage.set(`ccad.units.${col}`, value.id);
            runInAction(() => {
                this.columnUnits.set(col, new Unit(col, this));
            });
        });
    };

    get index () {
        const data = {};
        this.columnUnits
            .forEach((value, key) => {
                data[key] = value;
            });
        return data;
    }
}

// TODO: This should be a Unit method
const convertUnit = (cell, srcValue, unitsStore) => {
    const dstUnit = cell.unit;

    if (dstUnit) {
        const srcUnit = unitsStore.index[cell.id].default;
        if (cell.id === 'deviceDimensions') {
            const srcDimensions = srcValue.split('/');
            const srcMetric = srcDimensions.map(d => srcUnit.toBase(Number(d)));
            const dstDimensions = srcMetric.map(d => dstUnit.fromBase(d));
            return dstDimensions.join(' / ');
        } else {
            const srcMetric = srcUnit.toBase(srcValue);
            return dstUnit.fromBase(srcMetric);
        }
    } else {
        return srcValue;
    }
};

export { UnitsStore, UNITS, convertUnit };
