import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import IterableContainer from '../../../../common/IterableContainer';
import { BasePreviewPinType } from '../../../../FileViewers/Image';

function BackgroundPins ({ preview, previewContainer }) {
    return (
        <IterableContainer
            entryType={BasePreviewPinType}
            entryName='pin'
            entries={preview.slide.backgroundPins.filter(p => !p.dateTrashed)}
            keyBuilder={p => `${p.pinType}-${p.id}`}
            screen={preview}
            previewContainer={previewContainer}
        />
    );
}

BackgroundPins.propTypes = {
    preview: PropTypes.object,
    previewContainer: PropTypes.object
};

export default observer(BackgroundPins);
