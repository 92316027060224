import React from 'react';

export const LINK_DECORATOR = {
    strategy: function (contentBlock, callback, contentState) {
        contentBlock.findEntityRanges(
            (character) => {
                const entityKey = character.getEntity();
                return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
            },
            callback
        );
    },
    component: function ({ contentState, entityKey, children }) {
        const { title, url, inNewTab } = contentState.getEntity(entityKey).getData();
        return (
            <a
                href={url}
                title={title}
                target={inNewTab ? '_blank' : undefined}
                rel='noreferrer'
            >{children}
            </a>
        );
    }
};
