import { action } from 'mobx';

const itemContainerMixin = {
    updateOrder: action('updateOrder', function (item, order) {
        item.order = order;
    }),
    relaceItem: action('replaceItem', function (item) {
        this.items[this.items.findIndex(i => i.id === item.id)] = item;
        this.sortItems();
    }),
    moveItem: action('moveItem', function (fromIndex, toIndex) {
        const item = this.items[fromIndex];
        const [removed] = this.items.splice(fromIndex, 1);
        this.items.splice(toIndex, 0, removed);

        const newIndex = this.items.findIndex(i => i.id === item.id);
        const order = newIndex === 0
            ? { name: 'begin' }
            : (newIndex === (this.items.length - 1))
                ? { name: 'end' }
                : {
                    name: 'between',
                    args: [this.items[newIndex - 1].id, this.items[newIndex + 1].id]
                };
        this.scheduleSave(this.saveCommands.moveItem, item, order);
    }),
    addItems (assets) {
        return this.scheduleSave(this.saveCommands.addItems, assets);
    },
    removeItem: action('removeItem', function (item) {
        this.items.remove(item);
        this.scheduleSave(this.saveCommands.removeItem, item);
    }),
    sortItems: action('sortItems', function () {
        this.items.replace(
            this.items.slice().sort((a, b) => a.order - b.order)
        );
    }),
    pushItem: action('pushItems', function (item) {
        this.items.push(item);
        this.sortItems();
    })
};

export default itemContainerMixin;
