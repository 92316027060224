import Record from './record';

class Device extends Record {
    type = 'devices';
}

class Equipment extends Record {
    type = 'equipment';
}

class Circuit extends Record {
    type = 'circuits';
}

class Location extends Record {
    type = 'locations';
}

class RecordFactory {
    static map = {
        devices: Device,
        equipment: Equipment,
        circuits: Circuit,
        locations: Location
    };

    static create (table, data) {
        return new RecordFactory.map[table](data);
    }
}

export default RecordFactory;
