class DragMovePinManager {
    LAYERS = {
        TEXT: 0,
        BOARD: 1
    };

    constructor (root) {
        this.root = root;
        this.dragging = false;
        this.layers = [null, null];
    }

    get activeLayers () {
        return this.layers.filter(l => !!l);
    }

    reduceLayers (handler) {
        return (ev, target) => this.activeLayers.reduce(
            (acc, l) => acc || (l[handler] && l[handler](ev, target)),
            false
        );
    }

    start (ev, target) {
        this.reduceLayers('onDragPinStart')(ev, target);
    }

    drag (ev, target) {
        this.reduceLayers('onDragPin')(ev, target);
    }

    stop (ev, target) {
        const handled = this.reduceLayers('onDragPinEnd')(ev, target);
        handled
            ? this.activeLayers.forEach(l => l.onDropPin && l.onDropPin(ev, target))
            : target.onDragCancel(ev);
    }

    subscribe (layer, s) {
        this.layers[layer] = s;
    }

    unsubscribe (layer) {
        this.layers[layer] = null;
    }
}

export default DragMovePinManager;
