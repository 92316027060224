import { computed, reaction, observable, makeObservable } from 'mobx';

class Index {
    deleteHandlers = observable.map([], { deep: false });

    constructor (items, idSelector = (item) => item.id) {
        makeObservable(this, {
            map: computed
        });

        if (typeof items === 'function') {
            this._itemGetter = items;
        } else {
            this._items = items;
        }
        this.idSelector = idSelector;
        this.deleteHandlers = new Map();

        this.reactor = reaction(
            () => this.map,
            (value) => {
                const deleted = [];
                for (const id of this.deleteHandlers.keys()) {
                    if (!value.has(id)) {
                        const deleteHandlers = this.deleteHandlers.get(id) || [];
                        deleteHandlers.forEach(handler => {
                            handler.handler.call(handler.context);
                        });
                        deleted.push(id);
                    }
                }
                deleted.forEach(id => {
                    this.deleteHandlers.delete(id);
                });
            });
    }

    get items () {
        return (this._itemGetter)
            ? this._itemGetter()
            : this._items;
    }

    get map () {
        return new Map(this.items.map(i => [this.idSelector(i), i]));
    }

    onDelete (id, handler, context) {
        setDefault(this.deleteHandlers, id, []).push({
            handler,
            context
        });
    }

    cleanUp () {
        this.reactor();
    }
}

function setDefault (map, key, value) {
    if (map.has(key)) {
        return map.get(key);
    } else {
        map.set(key, value);
        return value;
    }
}

export default Index;
