import Intro from './Intro';
import Show from './Show';
import Tell from './Tell';
import TitleAndMedia from './TitleAndMedia';

export default {
    intro: Intro,
    'title-and-media': TitleAndMedia,
    'show-left': Show,
    'show-right': Show,
    'tell-left': Tell,
    'tell-right': Tell
};
