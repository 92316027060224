import pathToRegexp from 'path-to-regexp';
import { observable, makeObservable } from 'mobx';

import Projector from './Projector';

const routes = [
    {
        basename: '/portal/presentation',
        paths: [
            '/:uuid',
            '/edit/:uuid/:slideId/map/',
            '/edit/:uuid/:slideId/',
            '/preview/:uuid/:slideId?/'
        ]
    },
    {
        basename: '/presentations',
        paths: [
            '/:uuid/:slideId?/'
        ]
    }
];

function matchRoute (path) {
    const fullRoutes = routes.reduce((allRoutes, route) => {
        return [...allRoutes, ...route.paths.map(p => route.basename + p)];
    }, []);

    for (const route of fullRoutes) {
        const keys = [];
        const re = pathToRegexp(route, keys);
        const match = re.exec(path);
        if (match) {
            return match.slice(1).map((chunk, i) => {
                return {
                    name: keys[i].name,
                    value: chunk
                };
            });
        }
    }
}

function pathFromWindow (location) {
    return (Settings.offlinePresentation && !Settings.inAppViewer)
        ? location.hash.replace(/^#/, '')
        : location.pathname;
}

class StaticProjector {
    projector;

    constructor (root, history) {
        makeObservable(this, {
            projector: observable
        });

        this.root = root;
        this.history = history;
    }

    init () {
        this.createProjector();
        this.unsubscribe = this.history.listen(
            () => this.createProjector()
        );
    }

    createProjector () {
        const params = matchRoute(pathFromWindow(window.location));
        const slideId = params.find(p => p.name === 'slideId');
        this.projector = Projector.create(
            this.root, slideId && slideId.value,
            this.history, this.history.location
        );
    }

    cleanUp () {
        this.unsubscribe && this.unsubscribe();
    }
}

export default StaticProjector;
export {
    matchRoute,
    pathFromWindow
};
