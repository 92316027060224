import styled, { css } from 'styled-components';
import { Collapse, Toggler } from '@vectorworks/vcs-ui/dist/lib/Collapse/Collapse';

const CELL_WIDTH_PX = 150;
const NESTED_WIDTH_RATIO = 0.15;
const CELL_PADDING_PX = 10;

const nestedColumnWidth = nestedColumnsCount =>
    `${CELL_WIDTH_PX + (nestedColumnsCount - 1) * CELL_WIDTH_PX * NESTED_WIDTH_RATIO}px`;
const nestedContentsWidth = count => `${(count + 1) * CELL_WIDTH_PX}px`;

const LgContents = styled.div`
    min-height: 50px;
    min-width: 100%;
    align-items: center;

    display: grid;
    grid-gap: 15px;

    ${({ showBorder }) => showBorder && css`
        border-bottom: 1px solid var(--border-color)};
    `}

    ${({ count }) => css`
    width: ${nestedContentsWidth(count)};
    grid-template-columns: repeat(${count}, ${CELL_WIDTH_PX}px);
    `}
`;

const SubHead = styled.div`
    height: 45px;
    display: grid;
    grid-gap: 15px;
    align-items: center;
`;

const Cell = styled.div`
    align-contents: center;
    display: flex;
`;

const S = {
    Head: styled.div`
        margin: 10px 0 0;
        min-width: 100%;
        width: fit-content;
        display: flex;

        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
    `,
    NestedSubHead: styled(SubHead)`
        ${({ nestedColumnsCount }) => css`
            width: ${nestedColumnWidth(nestedColumnsCount)};
        `}
    `,
    NonNestedSubHead: styled(SubHead)`
        ${({ count }) => css`
            width: ${nestedContentsWidth(count)};
            grid-template-columns: repeat(${count}, ${CELL_WIDTH_PX}px);
        `}
    `,
    /* eslint-disable */
    Body: styled.div`
        ${({ isMobile }) => isMobile
            ? css`
                width: 100%;
            `
            : css`
                min-width: 100%;
                width: fit-content;
                display: flex;
                flex-direction: column;
            `
        }
        overflow-x: hidden;
        overflow-y: auto;
    `,
    /* eslint-enable */
    MobileTitle: styled.div`
        width: 45%;
        display: flex;
        align-items: center;
    `,
    ColumnTitle: styled.div`
        padding: 0 10px;
        align-items: center;
        white-space: nowrap;
        color: var(--text-secondary-color);
        
        ${({ canBeFiltered }) => canBeFiltered && css`
            cursor: pointer;
        `}

        ${({ cellType }) => cellType && css`
            display: flex;
            justify-content: ${cellType === 'number' ? 'right' : 'left'};
        `}

        ${({ nestedColumnsCount }) => nestedColumnsCount > 0 && css`
            width: ${nestedColumnWidth(nestedColumnsCount)};
        `}
    `,
    Arrow: styled.div`
        justify-self: center;
        align-self: center;
    `,
    LgCell: styled(Cell)`
        padding: 0 10px;

        ${({ align }) => align && css`
            justify-content: ${align};
        `}
    `,
    /* eslint-disable */
    MobileCell: styled(Cell)`
        ${({ isPreview }) => isPreview
            ? css`
                padding: 0 ${CELL_PADDING_PX}px;
            `
            : css`
                width: 45%;
            `
        }
    `,
    /* eslint-enable */
    CellPair: styled.div`
        display: grid;
    `,
    TogglerDetails: styled.div`
        margin-left: 10px;
        display: grid;
        flex: 1;
        align-items: center;
                
        ${({ columns }) => css`
            grid-template-columns: repeat(${columns}, 1fr);
        `}}
    `,
    Toggler: styled(Toggler)`
        ${({ setHeight }) => setHeight && css`
            height: 100%; // fix a bug in Safari (VCS-31084)
        `}

        ${({ isMobile }) => !isMobile && css`
            padding: 0 0 0 10px;
        `}

        ${({ isHidden }) => isHidden && css`
            visibility: hidden;
        `}
    `,
    Row: styled.div`
        min-height: 50px;
        display: flex;

        ${({ isMobile, identation }) => isMobile && css`
            padding: 5px 0 5px ${(identation > 1 ? identation - 1 : identation) * 20}px; 
            width: 100%;
            font-weight: bold;
            border-bottom: 1px solid var(--border-color);
        `}

        ${({ expand }) => !expand && css`
            align-items: center;
        `}
    `,
    MobileCollapsableRow: styled.div`
        margin: -1px 0;

        border-bottom: 1px solid var(--border-color);
        background-color: var(--grey1);
        font-weight: bold;

        ${({ identation }) => css`
            padding: 0 0 0 ${identation * 20}px;
        `}
    `,
    LgContents,
    SmContent: styled.div`
        display: flex;

        padding: 5px 0;
        align-items: center;
    `,
    Collapse: styled(Collapse)`
        ${({ in: open }) => open && css`flex: 1 0 auto;`}}
    `,
    SecondaryText: styled.div`
        color: var(--text-secondary-color) !important;
    `,
    DesktopNestedRow: styled.div`
        width: 100%;
        display: flex;
        background-color: var(--grey1);
        border-bottom: 1px solid var(--border-color);
    `,
    NestedColumn: styled.div`
        font-weight: bold;
        flex: 0 0 auto;

        ${({ isEmpty }) => !isEmpty && css`
            background-color: var(--grey1);
        `}

        ${({ nestedColumnsCount }) => css`
            width: ${nestedColumnWidth(nestedColumnsCount)};
        `}

        ${({ identation }) => css`
            padding: 0 0 0 ${identation * CELL_WIDTH_PX * NESTED_WIDTH_RATIO}px;
        `}
    `,
    EmptyNestedColumn: styled.div`
        display: flex;
    `,
    NestedContentsFiller: styled.div`
        width: 100%;
        background-color: var(--grey1);
    `,
    LgCollapsableRow: styled(LgContents)`
        margin: -1px 0;

        border: 1px solid var(--border-color);
        background-color: var(--grey1);
        font-weight: bold;
    `,
    Highlight: styled.span`
        padding: 1px;
        background-color: yellow;
        font-weight: bold;
    `
};

export default S;
