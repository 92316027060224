import TWEEN from '@tweenjs/tween.js/src/Tween';

import { shortestAngle } from './math-funcs';

function createAnimation (object, prop, target, time = 1500) {
    const { distance, direction } = shortestAngle(object[prop], target);
    return {
        object,
        prop,
        target,
        distance,
        direction,
        start () {
            this.tween = new TWEEN.Tween(object) // Create a new tween that modifies 'object'.
                .to(
                    { [prop]: object[prop] + distance * direction },
                    time
                ) // Move to target in time milisecond.
                .easing(TWEEN.Easing.Cubic.Out); // Use an easing function to make the animation smooth.
            if (this.updateCallback) {
                this.tween.onUpdate(this.updateCallback);
            }
            if (this.completeCallback) {
                this.tween.onComplete(this.completeCallback);
            }
            this.tween.start(); // Start the tween immediately.
        },
        stop () {
            this.tween.stop();
        },
        onUpdate (callback) {
            this.updateCallback = callback;
        },
        onComplete (callback) {
            this.completeCallback = callback;
        }
    };
}

export default createAnimation;
