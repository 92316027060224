import React from 'react';
import PropTypes from 'prop-types';

import withResponsiveListeners from '../../../../utils/Responsive';

import ColumnsDropdown from './Filters/ToolbarFilters/ColumnsDropdown';
import SearchBox from './Filters/ToolbarFilters/SearchBox';
import GroupByDropdown from './Filters/ToolbarFilters/GroupByDropdown';
import SortWidget from './Filters/ToolbarFilters/SortWidget';
import S from './Filters/styles';
import { TABLES } from './utils';
import ViewerContext from './ViewerContext';

const ConnectCADFilters = props => {
    const { isMobile } = React.useContext(ViewerContext);
    const isLocations = props.table === TABLES.locations.name;
    return isMobile
        ? <S.FiltersMobile>
            <ColumnsDropdown {...props} />
            {
                !isLocations &&
                    <GroupByDropdown {...props} />
            }
            <SortWidget {...props} />
            <SearchBox {...props} />
        </S.FiltersMobile>
        : <S.FiltersDesktop>
            <ColumnsDropdown {...props} />
            {
                !isLocations &&
                    <GroupByDropdown {...props} />
            }
            <SearchBox {...props} />
        </S.FiltersDesktop>;
};

ConnectCADFilters.propTypes = {
    table: PropTypes.string,
    viewer: PropTypes.object,
    screen: PropTypes.object
};

export default withResponsiveListeners(ConnectCADFilters);
