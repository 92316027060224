import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import {
    usePopupState,
    bindTrigger,
    bindMenu
} from 'material-ui-popup-state/hooks';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { Menu } from '@vectorworks/vcs-ui/dist/lib/Menu/Menu';
import { MenuItem, MenuItemIcon } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';
import { Spinner } from '@vectorworks/vcs-ui/dist/lib/Loading/Spinner';

import { Asset } from '../../Stores/Asset';
import { inject } from '../../Store';
import DeleteCarouselItem from '../../Dialog/DeleteCarouselItem';
import { Overlay, Thumbnail, InfoSection, DefaultIcon, PinCreator } from './common';
import { FilePath } from '../../../lib';
import { S as TourStyles } from './TourTimelineItem';
import { assetValidators } from '../../validations';
import DraggablePinCreators, { DraggablePinCreator } from '../../Editor/DraggablePinCreators';
import DraggableImage from '../../Images/DraggedImage';

const S = {
    LoadingOverlay: styled(Overlay)`
        opacity: 1;
        background-color: rgba(var(--black-rgb),0.2);
    `,
    HoverOverlay: styled(Overlay)`
        transition: opacity 0.4s ease;
        opacity: ${(props) => props.forceOpacity ? 1 : 0};

        :hover {
            opacity: 1;
        }
    `,
    ...TourStyles
};

function AssetItem ({ item, editor, fileViewStore }) {
    const actionsMenuState = usePopupState({ variant: 'popper', popupId: `${item.asset.uuid}__actions` });
    const filePath = FilePath(item.asset.filename);

    const isLoading = (
        item.asset.state !== 'ready' ||
        item.asset.pdfToImageJobRunning
    );

    const previewFile = () => {
        !isLoading &&
            fileViewStore.file.open(item, [item]);
    };

    const removeItem = e => {
        e.stopPropagation();
        actionsMenuState.close(e);
        const dialog = editor.root.dialog;
        dialog.open({
            component: DeleteCarouselItem
        }).result.then(() => {
            item.remove();
        });
    };

    const pdfToImages = (e) => {
        editor.pdfToImages(item.asset);
        item.asset.setPdfToImageJobRunning(true);
        actionsMenuState.close(e);
    };

    const createTriggerProps = (menuState) => {
        const initital = bindTrigger(menuState);
        return {
            ...initital,
            onClick: e => {
                e.stopPropagation();
                initital.onClick(e);
            }
        };
    };

    const createMenuProps = (menuState) => {
        const initital = bindMenu(menuState);
        return {
            ...initital,
            onClose: e => {
                e.stopPropagation();
                initital.onClose(e);
            }
        };
    };

    return (
        /*
        TODO:
            1. Loading = always on top. asset !== 'ready' / pdfSpliting, etc
            2. Everything else - on hover with opacity transition
        */
        <S.Wrapper
            onDoubleClick={previewFile}
            style={{ cursor: 'default' }}
        >
            {
                isLoading
                    ? (
                        <S.LoadingOverlay>
                            <Spinner color='primary' />
                        </S.LoadingOverlay>
                    )
                    : (
                        <S.HoverOverlay
                            forceOpacity={editor.dragDropMonitor.draggedItem?.asset?.id === item.asset.id}
                        >
                            {/* Pin widget */}
                            <PinCreator>
                                <DraggablePinCreators
                                    editor={editor}
                                    pinCreators={[
                                        new DraggablePinCreator('ASSET', { asset: item.asset })
                                    ]}
                                    renderContent={() => (
                                        <S.MenuTrigger>
                                            <Icon
                                                key={`pin__asset--${item.asset.id}`}
                                                icon='pin-16'
                                                size='sm'
                                                style={{ padding: 2 }}
                                            />
                                        </S.MenuTrigger>
                                    )}
                                />
                            </PinCreator>

                            {
                                editor.slide.version === 2 &&
                                assetValidators.IMAGE.isFileTypeAllowed(item.asset.filename) && (
                                    <DraggableImage editor={editor} item={item} />
                                )
                            }

                            {/* Context actions */}
                            <S.ContextMenuActions
                                title={gettext('Menu')}
                                {...createTriggerProps(actionsMenuState)}
                            >
                                <S.MenuTrigger>
                                    <Icon icon='more-16' size='sm' />
                                </S.MenuTrigger>
                            </S.ContextMenuActions>
                        </S.HoverOverlay>
                    )
            }

            <Thumbnail thumbnail={item.asset.fileVersion.thumbnail} aspectRatio={1 / 1}>
                { !item.asset.fileVersion.thumbnail && (
                    <DefaultIcon>
                        <div dangerouslySetInnerHTML={{ __html: item.asset.fileType.defaultFileTypeIcon(item.asset.fileType) }} />
                    </DefaultIcon>
                )}
            </Thumbnail>

            <InfoSection>
                <div style={{ display: 'flex' }}>
                    <div>{filePath.baseName()}</div>
                    <div style={{ flexShrink: 0 }}>{filePath.extension()}</div>
                </div>
            </InfoSection>

            {/* Leave the menu here, not in the disappearing overlay */}
            <Menu {...createMenuProps(actionsMenuState)}>
                {
                    editor.slide.version === 2 &&
                    assetValidators.PDF.isFileTypeAllowed(item.asset.filename) && (
                        <MenuItem onClick={pdfToImages}>
                            <MenuItemIcon>
                                <Icon icon='pdf' />
                            </MenuItemIcon>
                            {gettext('PDF to images')}
                        </MenuItem>
                    )
                }
                <MenuItem onClick={removeItem}>
                    <MenuItemIcon>
                        <Icon icon='trash' />
                    </MenuItemIcon>
                    {gettext('Delete')}
                </MenuItem>
            </Menu>
        </S.Wrapper>
    );
}

AssetItem.propTypes = {
    editor: PropTypes.object,
    fileViewStore: PropTypes.object,
    item: PropTypes.shape({
        remove: PropTypes.func.isRequired,
        asset: PropTypes.instanceOf(Asset).isRequired
    })
};

export default inject('fileViewStore')(observer(AssetItem));
