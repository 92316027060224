import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { PinEditPanel } from './PinEditPanel';
import { AssetPin as BoardAssetPin } from '../../../models/board';
import { AssetPin as TourAssetPin } from '../../../models/tour';

export const AssetPinEditorPopup = observer(({ pin, onClose }) => {
    return (
        <PinEditPanel pin={pin} onClose={onClose} />
    );
});

// Like DraggablePin2D's props
AssetPinEditorPopup.propTypes = {
    onClose: PropTypes.func,
    pin: PropTypes.oneOfType([BoardAssetPin, TourAssetPin])
};
