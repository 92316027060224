import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import Portal from './Portal';

const CollapseToTarget = ({
    content,
    targetNode,
    delay = 1000,
    animationDuration = 400
}) => {
    const [coordinates, setCoordinates] = useState(null);
    const [cssClass, setCssClass] = useState(null);
    const [visible, setVisible] = useState(true);

    const callbackRef = useCallback(node => {
        if (node !== null) {
            const { top, left } = node.getBoundingClientRect();
            setCoordinates({ top, left });
            setCssClass('collapse-to-target');
        }
    }, []);

    const firstRun = useRef(true);
    useEffect(() => {
        if (firstRun.current) {
            firstRun.current = false;
            return;
        };

        setTimeout(() => {
            const { top, left, width, height } = targetNode.getBoundingClientRect();

            setCoordinates({
                top: top + height / 2,
                left: left + width / 2
            });
            setCssClass([cssClass, 'collapse'].join(' '));

            setTimeout(() => {
                setVisible(false);
            }, animationDuration);
        }, delay);
    }, [coordinates]);

    return visible
        ? <Portal container='body'>
            {content({
                cssClass,
                callbackRef,
                style: coordinates
            })}
        </Portal>
        : null;
};

CollapseToTarget.propTypes = {
    content: PropTypes.func,
    targetNode: PropTypes.object,
    delay: PropTypes.number,
    animationDuration: PropTypes.number
};

export default CollapseToTarget;
