import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';

import { toast } from '../toast';
import utils from './utils';
import Comment from './Comment';
import CommentEdit from './CommentEdit';
import CommentPreview from './CommentPreview';

const Reply = ({ comment, commentsStore, hideActions }) => {
    const [edit, setEdit] = useState(false);

    const deleteReply = () => {
        commentsStore.confirmDelete()
            .then(() => {
                commentsStore.api.deleteReply(
                    commentsStore.resource,
                    comment.id
                )
                    .then(() => {
                        const parent = comment.parent;
                        parent.replies.pop();
                        const updatedComment = parent.toRaw();
                        commentsStore.replaceComment(updatedComment);
                    })
                    .catch(() => toast('ERROR_OCCURED'));
            });
    };

    const onEdit = () => {
        setEdit(true);
    };

    return (
        <Comment
            comment={comment}
            menuItems={[
                ...(
                    comment.owner.login === Settings.user.login &&
                    utils.checkUpdatePermission(comment.data.pub_date) &&
                    !hideActions
                )
                    ? [
                        <IconButton
                            key='edit'
                            onClick={onEdit}
                            title={gettext('Edit')}
                            ga-action={commentsStore.GASettings.action}
                            ga-label='Edit_Reply'
                            data-what='reply-action'
                            data-which='edit'
                        >
                            <Icon icon='edit-16' size='16px' />
                        </IconButton>,
                        <IconButton
                            key='delete'
                            onClick={deleteReply}
                            title={gettext('Delete')}
                            ga-action={commentsStore.GASettings.action}
                            ga-label='Delete_Reply'
                            data-what='reply-action'
                            data-which='reply'
                        >
                            <Icon icon='trash-16' size='16px' />
                        </IconButton>

                    ]
                    : []
            ]}
        >
            {
                edit
                    ? (
                        <CommentEdit
                            comment={comment}
                            commentsStore={commentsStore}
                            onCancel={() => setEdit(false)}
                            onUpdate={(updated, mentions) => {
                                return commentsStore.api.updateReply(
                                    commentsStore.resource,
                                    updated,
                                    mentions
                                )
                                    .then(res => {
                                        const newComment = comment.parent.toRaw();
                                        newComment.replies.pop();
                                        newComment.replies.push(res);
                                        commentsStore.replaceComment(newComment);
                                    })
                                    .catch(err => {
                                        this.editField && this.editField.setProcessing(false);
                                        toast('ERROR_OCCURED');
                                    })
                                    .finally(() => setEdit(false));
                            }}
                        />
                    )
                    : (
                        <CommentPreview comment={comment} />
                    )
            }
        </Comment>
    );
};

export default Reply;

Reply.propTypes = {
    comment: PropTypes.object,
    hideActions: PropTypes.bool,
    commentsStore: PropTypes.object.isRequired
};
