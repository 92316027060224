import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

const PDFZoom = ({ viewer }) =>
    <span className='ctrl-tool zoom'>
        <span
            className='icon icon-zoom-out'
            onClick={viewer.zoomOut}
            title={gettext('Zoom out')}
            ga-action='File_View_Controller'
            ga-label='Click_Zoom_Out'
            data-what='controller-tool'
            data-which='zoom-out'
        />
        <span
            className='ctrl-tool'
            onClick={viewer.setActualPixelsSize}
            title={
                viewer.pdfViewer && viewer.pdfViewer.currentScaleValue === 'page-actual'
                    ? gettext('Zoom to page width')
                    : gettext('View actual size')
            }
        >{`${viewer.percentage ? viewer.percentage : 0}%`}
        </span>
        <span
            className='icon icon-zoom-in'
            onClick={viewer.zoomIn}
            title={gettext('Zoom in')}
            ga-action='File_View_Controller'
            ga-label='Click_Zoom_In'
            data-what='controller-tool'
            data-which='zoom-in'
        />
    </span>;

PDFZoom.propTypes = {
    viewer: PropTypes.object
};

export default observer(PDFZoom);
