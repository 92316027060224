import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Editor from '@draft-js-plugins/editor';

import { RGBColor } from '@vectorworks/vcs-ui/dist/lib/ColorPicker/utils';

import { inject } from '../../../Store';
import { blockStyleFn, buildDecorators, buildState } from './editor-funcs';

import { FontSizesCSS } from './FontSizesCSS';
import { S } from './style';

class TextEditor extends React.Component {
    constructor (props) {
        super(props);
        this.state = buildState(this.text);
        this.editor = React.createRef();
        this.editorWrapper = React.createRef();
        /*
            Handles AutoColor:
            Updates backgroundColor with itself, because in the Store.js file,
            depending on backgroundImage or backgroundColor, we set the proper textColor
            for text with AutoColor, based on luminance of the background.
        */
        if (RGBColor.guess(this.props.color).compare(RGBColor.AUTO_COLOR)) {
            this.props.editor.updateBackgroundColor(this.props.editor.slide.backgroundColor);
        }
    }

    get text () {
        return this.props.editorBox.cloudBox.text || '';
    }

    get editorState () {
        return this.state.editorState;
    }

    get contentState () {
        return this.state.editorState.getCurrentContent();
    }

    componentDidMount () {
        this.setState({ fontSizesTrigger: true });
    }

    render () {
        return (
            <div
                style={{ color: this.props.color }}
                data-what={ this.props['data-what'] }
                data-which={ this.props['data-which'] }
            >
                {this.props.children}

                <S.Editor
                    ref={this.editorWrapper}
                >
                    <Editor
                        readOnly={true}
                        ref={this.editor}
                        editorState={this.state.editorState}
                        customStyleMap={this.state.customStyleMap}
                        onChange={editorState => this.setState({ editorState })}
                        decorators={buildDecorators(this)}
                        blockStyleFn={blockStyleFn}
                    />
                </S.Editor>

                <FontSizesCSS
                    key={this.state.fontSizesTrigger}
                    parentNode={this.editorWrapper.current}
                    editorBox={this.props.editorBox} // Destructure later: https://mobx.js.org/react-optimizations.html#dereference-values-late
                />
            </div>
        );
    }
}

TextEditor.propTypes = {
    editorBox: PropTypes.object,
    color: PropTypes.string.isRequired,
    editor: PropTypes.shape({
        slide: PropTypes.object,
        updateText: PropTypes.func,
        updateBackgroundColor: PropTypes.func,
        dragDropMonitor: PropTypes.object,
        textEditEnabled: PropTypes.bool
    }).isRequired,
    slide: PropTypes.object,
    'data-what': PropTypes.string,
    'data-which': PropTypes.string
};

TextEditor.defaultProps = {
    styles: {}
};

export default inject('dragMovePinManager')(observer(TextEditor));
