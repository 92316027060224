import { defaultSlide } from '../models/slide';

import { mixColors } from '../utils/color-utils';
import { queryString } from '../../lib';
import { makeObservable, observable } from 'mobx';

const noSlidesProjector = root => ({
    root,
    slide: defaultSlide(root),
    hasNext: () => false,
    hasPrev: () => false,
    nextSlideId: () => null,
    preveSlideId: () => null
});

const TransitionManager = {
    isTransitioning: false,
    startTransition: (currentSlideColor, nextSlideColor) => {
        const overlay = document.getElementById('ib-transition-overlay');

        const transitionColor = mixColors(
            TransitionManager.ensureColorNotNone(currentSlideColor),
            TransitionManager.ensureColorNotNone(nextSlideColor)
        );
        overlay.style.background = transitionColor.toCSS();

        TransitionManager.isTransitioning = true;
        TransitionManager.fadeIn(overlay);
    },
    fadeIn: element => {
        let start = null;

        function increase (timestamp) {
            if (!start) {
                start = timestamp;
            }
            const progress = timestamp - start;
            const opacity = Math.min(progress / 160, 1);
            element.style.opacity = opacity;
            if (Math.abs(opacity - 1) < Number.EPSILON) {
                TransitionManager.pause(element);
            } else {
                window.requestAnimationFrame(increase);
            }
        }
        window.requestAnimationFrame(increase);
    },
    pause: element => {
        let start = null;

        function nop (timestamp) {
            if (!start) {
                start = timestamp;
            }
            if (timestamp - start > 140) {
                TransitionManager.fadeOut(element);
            } else {
                window.requestAnimationFrame(nop);
            }
        }
        window.requestAnimationFrame(nop);
    },
    fadeOut: element => {
        let start = null;
        function decrease (timestamp) {
            if (!start) {
                start = timestamp;
            }
            const progress = timestamp - start;
            const opacity = Math.max(1 - progress / 320, 0);
            element.style.opacity = opacity;
            if (opacity < Number.EPSILON) {
                TransitionManager.isTransitioning = false;
            } else {
                window.requestAnimationFrame(decrease);
            }
        }
        window.requestAnimationFrame(decrease);
    },
    ensureColorNotNone: color =>
        !color || color === 'none' ? '#ffffff' : color
};

class Projector {
    static create (root, slideId) {
        return slideId && slideId !== 'undefined'
            ? new Projector(root, slideId)
            : root.slide.slides.length
                ? new Projector(root, root.slide.slides[0].id)
                : noSlidesProjector(root);
    }

    slideId;
    constructor (root, slideId) {
        makeObservable(this, {
            slideId: observable
        });
        this.root = root;
        this.slideId = slideId;
        this.slide = this.root.slide.index.get(slideId);
        this.slidePointer = this.root.slide.slides.findIndex(s => s.id === this.slide.id);
        (Settings.offlinePresentation && !Settings.inAppViewer) && this.updateHash(slideId);
    }

    hasNext = () => (this.slidePointer + 1) < this.root.slide.slides.length;
    hasPrev = () => (this.slidePointer - 1) >= 0;

    nextSlide = () => {
        if (this.hasNext()) {
            return this.root.slide.slides[this.slidePointer + 1];
        }
    };

    prevSlide = () => {
        if (this.hasPrev()) {
            return this.root.slide.slides[this.slidePointer - 1];
        }
    };

    updateHash = slideId => {
        window.location.hash = slideId
            ? `/presentations/${Settings.offlinePresentation.slides.uuid}/${slideId}/`
            : `/presentations/${Settings.offlinePresentation.slides.uuid}/`;
    };

    buildSlideLocation = (slide, optional = {}) => {
        const slideId = slide.id;
        const { location } = this.root.history;
        const nextLocation = location.pathname
            .replace(`/${this.slide.id}/`, `/${slideId}/`) +
                queryString.stringify(optional);
        return nextLocation;
    };

    navigate = (slide, optional = {}) => {
        const { location } = this.root.history;
        const slideId = slide.id;
        const slideIndex = this.root.slide.slides.findIndex(s => s.id === slideId);
        const step = slideIndex - this.slidePointer;
        const locationInfo = (Settings.offlinePresentation && !Settings.inAppViewer)
            ? location.hash
            : location.pathname;
        const nextLocation = locationInfo
            .replace(`/${this.slide.id}/`, `/${slideId}/`) +
                queryString.stringify(optional);
        if (this.root.transitions) {
            TransitionManager.startTransition(
                this.slide.backgroundColor,
                this.root.slide.slides[this.slidePointer + step].backgroundColor
            );

            setTimeout(() => {
                // TODO: find a better way to fix this. VTG-25099.
                const currentUrl = window.location.href;
                const inPreview = currentUrl.includes('preview') && nextLocation.includes('preview');
                const inEditor = currentUrl.includes('edit') && nextLocation.includes('edit');
                const shouldUpdate = inPreview || inEditor ||
                    (currentUrl.includes('presentations') && currentUrl.includes(`/${this.slide.id}/`));
                shouldUpdate && (Settings.offlinePresentation && !Settings.inAppViewer)
                    ? this.updateHash(slideId)
                    : this.root.history.push(nextLocation);
            }, 200);
        } else {
            (Settings.offlinePresentation && !Settings.inAppViewer)
                ? this.updateHash(slideId)
                : this.root.history.push(nextLocation);
        }
    };

    next = () => {
        if (this.hasNext()) {
            this.root.ui.toggle('carousel', true);
            this.navigate(this.nextSlide());
        }
    };

    prev = () => {
        if (this.hasPrev()) {
            this.root.ui.toggle('carousel', true);
            this.navigate(this.prevSlide());
        }
    };
}

export default Projector;
export { TransitionManager };
