import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import { Pin, withDraggable } from './Pin';
import PreviewPin from './PreviewPin';
import { TargetType } from '../models/board';
import { getPinIdByEntityKey } from '../Editor/Board/TextEditor/editor-funcs';
import { PendingTypePin } from './PendingTypePin';

const pinPoint = {
    viewPoint (x, y, container) {
        return {};
    },
    positionWithin (x, y, container) {
        return {};
    },
    normalized (x, y, container) {
        return {};
    },
    normalizedRelative (x, y, container) {
        return {};
    }
};

const DraggableTextPin = withDraggable(observer((props) => {
    return (
        <div className='text-pin'>
            <Pin
                {...props}
                isTextPin
                data-offset-key={props.offsetKey}
            />
        </div>
    );
}));

DraggableTextPin.propTypes = {
    container: PropTypes.object,
    pin: PropTypes.object,
    pinPoint: PropTypes.object,
    screen: PropTypes.object,
    popoverActions: PropTypes.object,
    className: PropTypes.string,
    entityKey: PropTypes.string,
    offsetKey: PropTypes.string
};

const TextPin = observer((props) => {
    const pinId = getPinIdByEntityKey(props.contentState, props.entityKey);
    const pin = props.screen.slide.getPinById(pinId);

    return !!pin && (
        pin.isPendingTypePin
            ? (
                <PendingTypePin pin={pin} />
            )
            : (
                <DraggableTextPin
                    {...props}
                    pin={pin}
                    pinPoint={pinPoint}
                    data={{
                        source: TargetType.TEXT,
                        entityKey: props.entityKey,
                        offsetKey: props.offsetKey
                    }}
                    bounds='.DraftEditor-root'
                />
            )
    );
});

TextPin.propTypes = {
    container: PropTypes.object,
    pin: PropTypes.object,
    screen: PropTypes.object,
    entityKey: PropTypes.string,
    offsetKey: PropTypes.string,
    contentState: PropTypes.object
};

const ThumbnailTextPin = () => <span>{ ' ' }</span>;

const SimplePreviewTextPin = props => <Pin {...props} isTextPin />;
const PreviewTextPin = props =>
    <div className='text-pin'>
        <PreviewPin
            {...props}
            container={{}}
            pinPoint={pinPoint}
            pinType={SimplePreviewTextPin}
        />
    </div>;

export {
    TextPin,
    PreviewTextPin,
    ThumbnailTextPin
};
