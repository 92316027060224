import { buildAction } from '../../../base/action';
import LibraryDeleteFileDialog from '../../Dialog/LibraryDeleteFile';

export default {
    delete: buildAction({
        action (items) {
            return this.store.dialog.open({
                component: LibraryDeleteFileDialog,
                context: {
                    items
                }
            }).result.then(assets => this.assetStore.remove(assets));
        },
        properties: {
            multiple: true,
            isAllowed: () => true,
            title: gettext('Delete'),
            icon: 'icon-trash',
            GALabel: 'Click_Delete'
        }
    }, 'delete', 'presentations-library-file')
};
