import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { EditorState } from 'draft-js';

import { TextPin as BoardTextPin } from '../../models/board';
import { TextPin as TourTextPin } from '../../models/tour';

import { buildEditorState } from '../../Editor/Board/TextEditor/editor-funcs';
import { getColorsFromEditorState } from '../../Editor/utils';
import { TextPinEditor, decorator } from '../../FileViewers/Tour/PinEditors/TextPinEditor/TextPinEditor';
import { Observer } from 'mobx-react';

const S = {
    TextPinEditor: styled(TextPinEditor)`
       ${({ isEmpty }) => isEmpty && 'display: none;'}

        > div {
            height: auto;
            color: var(--text-primary-color);
        }
    `
};

const Text = ({ pin, ...rest }) => {
    const editorState = EditorState.set(
        buildEditorState(pin.textProps),
        { decorator }
    );

    return (
        <Observer>{() => (
            <S.TextPinEditor
                editorState={editorState}
                customStyleMap={getColorsFromEditorState(editorState)}
                readOnly
                ariaLabel='text-pin__preview'
                /* If I render null on empty editor, I get https://jira.vectorworks.net/browse/VCS-29218 */
                isEmpty={!editorState.getCurrentContent().hasText()}
                {...rest}
            />
        )}
        </Observer>
    );
};

Text.propTypes = {
    pin: PropTypes.oneOfType([BoardTextPin, TourTextPin])
};

export default Text;
