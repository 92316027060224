import React from 'react';
import reactStringReplace from 'react-string-replace';
import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';

function selectByCount (singular, plural) {
    return params => {
        const msg = params.count === 1
            ? singular
            : (params.count > 1)
                ? (plural || singular)
                : null;
        return msg && interpolate(msg, params, true);
    };
}

function routerGo (path) {
    import('../vendor/ko-component-router').then((Router) => {
        Router.default.update(`${Settings.PORTAL_PREFIX}${path}`);
    });
}

window.shortcuts = window.shortcuts || {};
window.shortcuts.routerGo = routerGo;

/* eslint-disable */
const NOTIFICATIONS = {
    'UPLOAD_SUBMIT_PROGRESS': {
        msg: selectByCount(gettext('Uploading %(count)s file.'), gettext('Uploading %(count)s files.')),
        immediate: false,
        type: 'success',
        className: 'progress'
    },
    'UPLOAD_SUBMIT': {
        msg: selectByCount(gettext('Uploading %(count)s file.'), gettext('Uploading %(count)s files.')),
        immediate: false,
        type: 'success'
    },
    'JOB_SUBMIT': {
        msg: selectByCount(gettext('%(count)s job submitted.'), gettext('%(count)s jobs submitted.')),
        immediate: false,
        type: 'success',
        className: 'progress'
    },
    'COPY_JOB_SUBMIT': {
        msg: ({ count }) => interpolate( gettext('Copying %(count)s items...'), { count }, true ),
        immediate: false,
        type: 'success',
        className: 'progress'
    },
    'DELETE_FOLDER_SUBMIT': {
        msg: selectByCount(gettext('%(count)s folder submitted for delete.'), gettext('%(count)s folders submitted for delete.')),
        immediate: false,
        type: 'success'
    },
    'RESTORE_FOLDER_SUBMIT': {
        msg: selectByCount(gettext('%(count)s folder submitted for restore.'), gettext('%(count)s folders submitted for restore.')),
        immediate: false,
        type: 'success'
    },
    'UPLOAD_COMPLETE': {
        msg: selectByCount(gettext('%(count)s file finished uploading.'), gettext('%(count)s files finished uploading.')),
        immediate: false,
        type: 'success'
    },
    'JOB_COMPLETE': {
        msg: selectByCount(gettext('%(count)s job finished.'), gettext('%(count)s jobs finished.')),
        immediate: false,
        type: 'success'
    },
    'DELETE_COMPLETE': {
        msg: selectByCount(gettext('%(count)s file deleted.'), gettext('%(count)s files deleted.')),
        immediate: false,
        type: 'success'
    },
    'DELETE_PRESENTATION_COMPLETE': {
        msg: selectByCount(gettext('%(count)s presentation deleted.'), gettext('%(count)s presentations deleted.')),
        immediate: false,
        type: 'success'
    },
    'EMPTY_TRASH': {
        msg: () => gettext('Emptying trash...'),
        immediate: false,
        type: 'success'
    },
    'RESTORE_COMPLETE': {
        msg: selectByCount(gettext('%(count)s file restored.'), gettext('%(count)s files restored.')),
        immediate: false,
        type: 'success'
    },
    'RESTORE_PRESENTATION_COMPLETE': {
        msg: selectByCount(gettext('%(count)s presentation restored.'), gettext('%(count)s presentations restored.')),
        immediate: false,
        type: 'success'
    },
    'SHARE_COMPLETE': {
        msg: selectByCount(gettext('Shared with %(count)s person.'), gettext('Shared with %(count)s people.')),
        immediate: false,
        type: 'success',
        priority: 0.1
    },
    'UNSHARE_COMPLETE': {
        msg: selectByCount(gettext('%(count)s file/folder was unshared.'), gettext('%(count)s files/folders were unshared.')),
        immediate: false,
        type: 'success'
    },
    'FOLDER_CREATION_COMPLETE': {
        msg: selectByCount(gettext('%(count)s folder was created.'), gettext('%(count)s folders were created.')),
        immediate: false,
        type: 'success'
    },
    'FOLDER_DELETE_COMPLETE': {
        msg: selectByCount(gettext('%(count)s folder was deleted.'), gettext('%(count)s folders were deleted.')),
        immediate: false,
        type: 'success'
    },
    'FOLDER_RESTORE_COMPLETE': {
        msg: selectByCount(gettext('%(count)s folder was restored.'), gettext('%(count)s folders were restored.')),
        immediate: false,
        type: 'success'
    },
    'UPLOAD_FAIL': {
        msg: selectByCount(gettext('%(count)s upload failed'), gettext('%(count)s uploads failed')),
        immediate: false,
        type: 'error'
    },
    'JOB_FAIL': {
        msg: selectByCount(gettext('%(count)s job failed.'), gettext('%(count)s jobs failed.')),
        immediate: false,
        type: 'error'
    },
    'JOB_SUBMIT_FAIL': {
        msg: selectByCount(gettext('%(count)s job failed to submit.'), gettext('%(count)s jobs failed to submit.')),
        immediate: false,
        type: 'error'
    },
    'JOB_EXISTS': {
        msg: selectByCount(gettext('%(count)s job already exists.'), gettext('%(count)s jobs already exist.')),
        immediate: false,
        type: 'error'
    },
    'DELETE_FAIL': {
        msg: selectByCount(gettext('%(count)s file/folder could not be deleted.'), gettext('%(count)s files/folders could not be deleted.')),
        immediate: false,
        type: 'error'
    },
    'DELETE_PRESENTATION_FAIL': {
        msg: selectByCount(gettext('%(count)s presentation could not be deleted.'), gettext('%(count)s presentations could not be deleted.')),
        immediate: false,
        type: 'error'
    },
    'RESTORE_FAIL': {
        msg: selectByCount(gettext('%(count)s file/folder could not be restored.'), gettext('%(count)s files/folders could not be restored.')),
        immediate: false,
        type: 'error'
    },
    'RESTORE_PRESENTATION_FAIL': {
        msg: selectByCount(gettext('%(count)s presentation could not be restored.'), gettext('%(count)s presentations could not be restored.')),
        immediate: false,
        type: 'error'
    },
    'SHARE_FAIL': {
        msg: selectByCount(gettext('Share unsuccessful.')),
        immediate: false,
        type: 'error'
    },
    'UNSHARE_FAIL': {
        msg: selectByCount(gettext('%(count)s file/folder could not be unshared.'), gettext('%(count)s files/folders could not be unshared.')),
        immediate: false,
        type: 'error'
    },
    'NEW_SHARED_ITEM': {
        msg: ({ user, itemName, itemUrl }) => {
            const name = itemName.length > 25 ? `${itemName.substring(0, 25)}...` : itemName;
            return <div>
                {reactStringReplace(
                    interpolate(
                        gettext('%(user)s shared %(item)s with you'),
                        { user, item: '%(item)s' },
                        true
                    ),
                    '%(item)s',
                    () => <a href="#" onClick={() => window.location.href=itemUrl}>{name}</a>
                )
            }</div>;
        },
        immediate: true,
        type: 'success'
    },
    'NEW_SHARED_ITEMS': {
        msg: ({ user, sharedWithMeUrl, count }) => {
            return <div>
                {reactStringReplace(
                    interpolate(
                        gettext('%(user)s shared %(count)s items with you'),
                        { user, count: '%(count)s' },
                        true
                    ),
                    '%(count)s',
                    () => <a href="#" onClick={() => window.location.href=`${window.location.origin}${sharedWithMeUrl}`}>{count}</a>
                )
            }</div>;
        },
        immediate: true,
        type: 'success'
    },
    'FOLDER_CREATION_FAIL': {
        msg: selectByCount(gettext('%(count)s folder could not be created.'), gettext('%(count)s folders could not be created.')),
        immediate: false,
        type: 'error'
    },
    'NO_MORE_THAN': {
        msg: selectByCount(gettext('Select no more than %(count)s items.'), gettext('Select no more than %(count)s items.')),
        immediate: false,
        type: 'error'
    },
    'COPIED': {
        msg: selectByCount(gettext('Copied to clipboard')),
        immediate: true,
        type: 'success'
    },
    'COMMENT_NOT_ALLOWED': {
        msg: () => gettext('Allowed time to edit has passed.'),
        immediate: true,
        type: 'error'
    },
    'RENAME_FAILED': {
        msg: () => gettext('Rename failed.'),
        immediate: true,
        type: 'error'
    },
    // TODO: should these messages be interpolated?
    'NEW_COMMENT': {
        msg: ({ params }) => {
            const { name, fileName, fileUrl, count } = params;
            const itemName = fileName.length > 25 ? `${fileName.substring(0, 25)}...` : fileName;
            return count === 1
                ? <div>{name} {gettext('commented on')} <a href="#" onClick={() => window.location.href=`${window.location.origin}${fileUrl}`}>{itemName}</a></div>
                : <div>{count} {gettext('new comments on')} <a href="#" onClick={() => window.location.href=`${window.location.origin}${fileUrl}`}>{itemName}</a></div>
        },
        immediate: false,
        type: 'success'
    },
    'NEW_REPLY': {
        msg: ({ params }) => {
            const { name, fileName, fileUrl, count } = params;
            const itemName = fileName.length > 25 ? `${fileName.substring(0, 25)}...` : fileName;
            return count === 1
                ? <div>{name} {gettext('replied to your comment on')} <a href="#" onClick={() => window.location.href=`${window.location.origin}${fileUrl}`}>{itemName}</a></div>
                : <div>{count} {gettext('new replies to your comment on')} <a href="#" onClick={() => window.location.href=`${window.location.origin}${fileUrl}`}>{itemName}</a></div>
        },
        immediate: false,
        type: 'success'
    },
    'NEW_MENTION': {
        msg: ({ params }) => {
            const  { name, fileName, fileUrl, count } = params;
            const itemName = fileName.length > 25 ? `${fileName.substring(0, 25)}...` : fileName;
            return count === 1
                ? <div>{name} {gettext('mentioned you in a comment on')} <a href="#" onClick={() => window.location.href=`${window.location.origin}${fileUrl}`}>{itemName}</a></div>
                : <div>{count} {gettext('new mentions in a comment on')} <a href="#" onClick={() => window.location.href=`${window.location.origin}${fileUrl}`}>{itemName}</a></div>
        },
        immediate: false,
        type: 'success'
    },
    'RESOURCE_DOES_NOT_EXIST': {
        msg: () => gettext('Resource cannot be found.'),
        immediate: true,
        type: 'error'
    },
    'ERROR_OCCURED': {
        msg: () => gettext('Error occurred. Please try again later.'),
        immediate: true,
        type: 'error'
    },
    'FULLSCREEN_NOT_SUPPORTED': {
        msg: () => gettext('Fullscreen is not supported on this device.'),
        immediate: true,
        type: 'error'
    },
    'UPLOAD_FOLDERS_ERROR': {
        msg: () => gettext('Folder upload is not allowed.'),
        immediate: true,
        type: 'error'
    },
    'COPY_IMAGE_FAILED': {
        msg: () => gettext('Copy image failed.'),
        immediate: true,
        type: 'error'
    },
    'COPY_UNSUPPORTED_BY_BROWSER': {
        msg: () => gettext('Copying of image is not supported by this browser.'),
        immediate: true,
        type: 'error'
    },
    'TASK_CREATE_SUCCESS': {
        msg: ({ name }) => interpolate(
            gettext('Task created "%(name)s".'),
            { name: name.length > 30 ? `${name.substring(0, 30)}...` : name },
            true
        ),
        immediate: true,
        type: 'success'
    },
    'TASK_CREATE_ERROR': {
        msg: ({ name }) => interpolate(
            gettext('Failed to create a task "%(name)s".'),
            { name: name.length > 30 ? `${name.substring(0, 30)}...` : name },
            true
        ),
        immediate: true,
        type: 'error'
    },
    'TASK_DELETE_COMPLETE': {
        msg: selectByCount(gettext('%(count)s task deleted.'), gettext('%(count)s tasks deleted.')),
        immediate: false,
        type: 'success'
    },
    'TASK_DELETE_FAIL': {
        msg: selectByCount(gettext('%(count)s task could not be deleted.'), gettext('%(count)s tasks could not be deleted.')),
        immediate: false,
        type: 'error'
    },
    'TASK_UPDATE_COMPLETE': {
        msg: ({ name }) => interpolate(
            gettext('Task %(name)s updated.'),
            { name: name.length > 30 ? `${name.substring(0, 30)}...` : name },
            true
        ),
        immediate: true,
        type: 'success'
    },
    'TASK_UPDATE_FAIL': {
        msg: ({ name }) => interpolate(
            gettext('Task %(name)s could not be updated.'),
            { name: name.length > 30 ? `${name.substring(0, 30)}...` : name },
            true
        ),
        immediate: true,
        type: 'error'
    },
    'TASKS_UPDATE_COMPLETE': {
        msg: selectByCount(gettext('%(count)s task updated.'), gettext('%(count)s tasks updated.')),
        immediate: true,
        type: 'success'
    },
    'TASKS_UPDATE_FAIL': {
        msg: selectByCount(gettext('%(count)s task could not be updated.'), gettext('%(count)s tasks could not be updated.')),
        immediate: true,
        type: 'error'
    },

    // Presentations
    'PRESENTATION_CREATION_COMPLETE_BTN': {
        msg: ({ params }) => {
            const { path } = params;
            return <div>
                {gettext('Presentation created')}
                <Button onClick={() => shortcuts.routerGo(path)} style={{ marginLeft: 10 }}>{gettext('Open')}</Button>
            </div>
        },
        immediate: false,
        type: 'success',
        style: {
            alignItems: 'center'
        }
    },
    'PRESENTATION_CREATION_COMPLETE': {
        msg: () => gettext('Presentation created'),
        immediate: false,
        type: 'success'
    },
    'PRESENTATION_CREATION_FAIL': {
        msg: () => gettext('Presentation could not be created.'),
        immediate: false,
        type: 'error'
    },
    'PRESENTATION_UPDATE_COMPLETE_BTN': {
        msg: ({ params }) => {
            const { path } = params;
            return <div>
                {gettext('Presentation updated')}
                <Button onClick={() => shortcuts.routerGo(path)} style={{ marginLeft: 10 }}>{gettext('Open')}</Button>
            </div>
        },
        immediate: false,
        type: 'success'
    },
    'PRESENTATION_UPDATE_FAIL': {
        msg: () => gettext('Presentation could not be updated.'),
        immediate: false,
        type: 'error'
    },
    'PRESENTATION_DELETE_COMPLETE': {
        msg: selectByCount(gettext('%(count)s presentations deleted.'), gettext('%(count)s presentations deleted.')),
        immediate: false,
        type: 'success'
    },
    'PRESENTATION_DELETE_FAIL': {
        msg: selectByCount(gettext('%(count)s presentations could not be deleted.'), gettext('%(count)s presentations could not be deleted.')),
        immediate: false,
        type: 'error'
    },
    'PRESENTATION_RENAME_COMPLETE': {
        msg: () => gettext('Presentation renamed successfully.'),
        immediate: false,
        type: 'success'
    },
    'PRESENTATION_RENAME_FAIL': {
        msg: () => gettext('Presentation rename failed.'),
        immediate: false,
        type: 'error'
    },
    'PRESENTATION_EXPORT_START': {
        msg: () => gettext('Exporting presentation ...'),
        immediate: false,
        type: 'success',
        className: 'progress'
    },
    'PRESENTATION_EXPORT_DONE': {
        msg: () => gettext('Export finished'),
        immediate: false,
        type: 'success'
    },
    'PRESENTATION_EXPORT_FAIL': {
        msg: () => gettext('Export failed'),
        immediate: false,
        type: 'error'
    },
    'PRESENTATION_MAKE_SLIDES_START': {
        msg: () => gettext('Creating slides ...'),
        immediate: false,
        type: 'success',
        className: 'progress'
    },
    'PRESENTATION_MAKE_SLIDES_DONE': {
        msg: () => gettext('Slides created'),
        immediate: false,
        type: 'success'
    },
    'PRESENTATION_MAKE_SLIDES_FAIL': {
        msg: () => gettext('Error creating slides'),
        immediate: false,
        type: 'error'
    },
    // TODO: should those next 3 strings be interpolated for translation?
    'NEW_COMMENT_SLIDE': {
        msg: ({ name, slideName, slideUrl, count }) => {
            slideName = slideName.length > 25 ? `${slideName.substring(0, 25)}...` : slideName;
            return count === 1
                ? <div>{name} {gettext('commented on')} <a href="#" onClick={() => window.location.href=`${window.location.origin}${slideUrl}`}>{slideName}</a></div>
                : <div>{count} {gettext('new comments on')} <a href="#" onClick={() => window.location.href=`${window.location.origin}${slideUrl}`}>{slideName}</a></div>
        },
        immediate: false,
        type: 'success'
    },
    'NEW_REPLY_SLIDE': {
        msg: ({ name, slideName, slideUrl, count }) => {
            slideName = slideName.length > 25 ? `${slideName.substring(0, 25)}...` : slideName;
            return count === 1
                ? <div>{name} {gettext('replied to your comment on')} <a href="#" onClick={() => window.location.href=`${window.location.origin}${slideUrl}`}>{slideName}</a></div>
                : <div>{count} {gettext('new replies to your comment on')} <a href="#" onClick={() => window.location.href=`${window.location.origin}${slideUrl}`}>{slideName}</a></div>
        },
        immediate: false,
        type: 'success'
    },
    'NEW_MENTION_SLIDE': {
        msg: ({ name, slideName, slideUrl, count }) => {
            slideName = slideName.length > 25 ? `${slideName.substring(0, 25)}...` : slideName;
            return count === 1
                ? <div>{name} {gettext('mentioned you in a comment on')} <a href="#" onClick={() => window.location.href=`${window.location.origin}${slideUrl}`}>{slideName}</a></div>
                : <div>{count} {gettext('new mentions in a comment on')} <a href="#" onClick={() => window.location.href=`${window.location.origin}${slideUrl}`}>{slideName}</a></div>
        },
        immediate: false,
        type: 'success'
    },

    // Bluebeam
    'BLUEBEAM_CREATE_SESSION': {
        msg: () => gettext('Studio Session created.'),
        immediate: true,
        type: 'success'
    },
    'BLUEBEAM_FILES_ADDED': {
        msg: () => gettext('Files added to Studio Session.'),
        immediate: false,
        type: 'success'
    },
    'BLUEBEAM_REQ_SNAPSHOT_SUCCESS': {
        msg: () => gettext('Pull updates request sent.'),
        immediate: true,
        type: 'success'
    },
    'BLUEBEAM_REQ_SNAPSHOT_FAIL': {
        msg: () => gettext('Failed to request pull.'),
        immediate: true,
        type: 'error'
    },
    'BLUEBEAM_FINALIZE_SESSION': {
        msg: () => gettext('Finalizing Studio Session...'),
        immediate: true,
        type: 'success'
    },
    'BLUEBEAM_DELETE_SESSION': {
        msg: () => gettext('Deleting Studio Session...'),
        immediate: true,
        type: 'success'
    },
    'BLUEBEAM_EXIST_IN_SESSION': {
        msg: () => gettext('Files are already in the Session.'),
        immediate: true,
        type: 'error'
    },

    // Branding
    'BRANDING_UPDATE_SUCCESS': {
        msg: () => gettext('Branding updated.'),
        immediate: true,
        type: 'success'
    },
    'BRANDING_UPDATE_FAIL': {
        msg: () => gettext('Branding update has failed.'),
        immediate: true,
        type: 'error'
    },

    // Write permissions
    'MOUNT_SUCCESS': {
        msg: () => gettext('Folder added to Home.'),
        immediate: true,
        type: 'success'
    },
    'MOUNT_FAIL': {
        msg: () => gettext('Failed to add folder to Home.'),
        immediate: true,
        type: 'success'
    },
    'UNMOUNT_SUCCESS': {
        msg: () => gettext('Folder removed from Home.'),
        immediate: true,
        type: 'success'
    },
    'UNMOUNT_FAIL': {
        msg: () => gettext('Failed to remove folder from Home.'),
        immediate: true,
        type: 'success'
    },
    'PRESENTATION_DUPLICATION_STARTED': {
        msg: () => gettext('Duplication in progress...'),
        immediate: true,
        type: 'success'
    },
    'PRESENTATION_DUPLICATION_DONE': {
        msg: () => gettext('Presentation duplicated.'),
        immediate: true,
        type: 'success'
    },
    'CCAD_NOT_FOUND': {
        msg: () => gettext('The item was not found.'),
        immediate: true,
        type: 'error'
    },
    'PRESENTATION_MIGRATION_STARTED': {
        msg: () => gettext('Migration in progress...'),
        immediate: true,
        type: 'success'
    },
    'PRESENTATION_MIGRATION_DONE': {
        msg: () => gettext('Presentation migrated.'),
        immediate: true,
        type: 'success'
    },
    'PRESENTATION_MIGRATION_FAIL': {
        msg: () => gettext('Presentation failed to migrate.'),
        immediate: true,
        type: 'success'
    },
    'PIN.DRAG_TO_CREATE': {
        msg: () => gettext('Drag to create a pin.'),
        type: 'success',
        icon: 'add-pin'
    }
};
/* eslint-enable */

Object.keys(NOTIFICATIONS).forEach((key) => {
    NOTIFICATIONS[key].id = key;
});

export {
    NOTIFICATIONS
};
