import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Make sure when instatianting the <BrandingLogo /> to
// place it right under a view wrapper component with a
// relative position because this component uses the parent
// to set its location.

class BrandingLogoInner extends Component {
    constructor (props) {
        super(props);

        this.logoRef = React.createRef();
        this.state = {
            styles: {
                position: 'absolute',
                opacity: 0,
                width: 0,
                height: 0,
                zIndex: '2'
            }
        };
    }

    componentDidMount () {
        window.addEventListener('resize', this.setStyles);
        this.setStyles();
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this.setStyles);
    }

    getTop = (parent) => {
        const { size, position } = this.props.branding;
        const e = parent.offsetHeight / 9;

        // Wrapper
        const wrapperLogoWidth = size / 100 * e * 16;
        const wrapperLogoHeight = wrapperLogoWidth / position.logoAR;

        // Container
        const containerLogoWidth = size / 100 * parent.offsetWidth;
        const containerLogoHeight = containerLogoWidth / position.logoAR;

        const interval = wrapperLogoHeight - containerLogoHeight;

        const topToPixels = position.top / 100 * parent.offsetHeight;
        const topPixelsMax = parent.offsetHeight - wrapperLogoHeight;
        const coef = topToPixels / topPixelsMax;
        return topToPixels + (coef * interval);
    };

    setStyles = () => {
        const { size, opacity, position } = this.props.branding;
        const parent = this.logoRef.current.parentElement;
        setTimeout(() => this.setState(({ styles }) => {
            return {
                styles:
                {
                    ...styles,
                    ...(parent
                        ? {
                            opacity,
                            width: size + '%',
                            height: 'auto',
                            left: position.left + '%',
                            top: this.getTop(parent)
                        }
                        : {}
                    )
                }
            };
        }), 500);
    };

    onDragStart = ev => ev.preventDefault();

    render () {
        return (
            <img
                ref={ this.logoRef }
                className='branding-logo'
                style={this.state.styles}
                src={this.props.branding.image}
                onDragStart={this.onDragStart}
            />
        );
    }
}

BrandingLogoInner.propTypes = {
    branding: PropTypes.object
};

class BrandingLogo extends Component {
    render () {
        return this.props.branding && this.props.branding.image
            ? <BrandingLogoInner branding={this.props.branding} />
            : null;
    }
}

BrandingLogo.propTypes = {
    branding: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

export default BrandingLogo;
