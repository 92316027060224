import { Text } from '@vectorworks/vcs-boards';
import { saveMixin } from '../../save';
import { boxMixin } from './mixins';

export class TextBox {
    constructor ({ root, board, data }) {
        this.editorBox = new Text({
            store: board.vcsBEStore,
            ...data.props
        });

        this.databaseId = data.id;
        this.root = root;
        this.board = board;
        this.type = data.type;
        this.text = data.props.text;
        this.editorBox.cloudBox = this;
        this.postCreationQueue = [];
        this.updateStack = [this.apiProps];
    }

    get id () {
        return this.editorBox.id;
    }

    get isOptimistic () {
        return !this.databaseId;
    }

    get apiProps () {
        return {
            ...this.editorBox.apiProps,
            text: this.text
        };
    }

    getComparableProps (props) {
        // eslint-disable-next-line no-unused-vars
        const { height, ...comparable } = props || this.apiProps;
        return comparable;
    }

    get createData () {
        return {
            type: 'text',
            props: this.apiProps
        };
    }
}

Object.assign(TextBox.prototype, saveMixin);
Object.assign(TextBox.prototype, boxMixin);
