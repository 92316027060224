import React from 'react';
import PropTypes from 'prop-types';

class Spinner extends React.Component {
    render () {
        return (
            <div
                className='lds lds--container lds--large'
                ref={ref => { this.ref = ref; }}
                data-what='loading-indicator'
            />
        );
    }
}

function ListItemSpinner (props) {
    return (
        <div className='extra-row' style={props.styles}>
            <div className='lds lds--small lds--grey' style={{ zIndex: 0 }} />
            <span style={{ marginLeft: 4 }}>{ props.message }</span>
        </div>
    );
}

ListItemSpinner.propTypes = {
    message: PropTypes.string,
    styles: PropTypes.object
};

export default Spinner;
export { ListItemSpinner };
