import React from 'react';

import PropTypes from 'prop-types';

import DialogFrame from '../../Components/Dialog/DialogFrame';

function createMarkup (markup) {
    return { __html: markup };
}
const ConfirmDialog = props => {
    const { submitAnalytics, cancelAnalytics } = props;

    return (
        <DialogFrame
            dialog={props.dialog}
            title={props.title}
            submitTitle={props.yesTitle}
            cancelTitle={props.noTitle}
            useSimpleCallback={true}
            submitAnalytics={submitAnalytics}
            cancelAnalytics={cancelAnalytics}
        >
            <p
                className='dialog-paragraph'
                dangerouslySetInnerHTML={createMarkup(props.text)}
            />

            { props.children }
        </DialogFrame>
    );
};

ConfirmDialog.propTypes = {
    dialog: PropTypes.any,
    title: PropTypes.string,
    yesTitle: PropTypes.string,
    noTitle: PropTypes.string,
    text: PropTypes.string,
    cancelAnalytics: PropTypes.object,
    submitAnalytics: PropTypes.object
};

ConfirmDialog.defaultProps = {
    submitAnalytics: {},
    cancelAnalytics: {}
};

export default ConfirmDialog;
