import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

const VideoPlayButton = ({ viewer }) =>
    <span
        className={`icon ${viewer.paused ? 'icon-play' : 'icon-pause'} ${viewer.canPlay ? '' : 'disabled'}`}
        onClick={viewer.togglePause}
        ga-action='File_View_Controller'
        ga-label='Click_Toggle_Pause'
        data-what='controller-tool'
        data-which='toggle-pause'
    />;

VideoPlayButton.propTypes = {
    viewer: PropTypes.object.isRequired
};

export default observer(VideoPlayButton);
