import { observable, action, computed, makeObservable } from 'mobx';
import { ImageBox } from '../../models/v2/box';
import { rotatedCoordinates } from './util';

const BaseDroppableType = {
    create (...args) {
        return new Droppables[this.name](...args);
    },
    register (dragDropMonitor, ...args) {
        const droppable = this.create(dragDropMonitor, ...args);
        dragDropMonitor.registerDroppable(droppable);
        return droppable;
    },
    isDraggingOver (dragDropMonitor) {
        return dragDropMonitor.draggingOver.zIndex === this.zIndex;
    }
};

const DroppableTypes = {
    NOTHING: { ...BaseDroppableType, name: 'nothing', zIndex: 0 },
    BOARD_PINS: { ...BaseDroppableType, name: 'board_pins', zIndex: 1 },
    MAP_PINS: { ...BaseDroppableType, name: 'map_pins', zIndex: 1 },
    TOUR_PLACE: { ...BaseDroppableType, name: 'tour_place', zIndex: 1 },
    PINS: { ...BaseDroppableType, name: 'pins', zIndex: 1 },
    TEXT_EDITOR_PINS: { ...BaseDroppableType, name: 'text_editor_pins', zIndex: 2 },
    ITEMS: { ...BaseDroppableType, name: 'items', zIndex: 3 }
};
// Pin droppables are the areas where you can drop Pins onto.
// Droppables are the regular reordering sections from react-dnd
const PinDroppables = {
    zIndexes: [1, 2],
    isDraggingOver (dragDropMonitor) {
        return this.zIndexes.includes(
            dragDropMonitor.draggingOver.zIndex
        );
    }
};

class Droppable {
    constructor (dragDropMonitor) {
        makeObservable(this, {
            isDraggingOver: computed
        });

        this.dragDropMonitor = dragDropMonitor;
    }

    get isDraggingOver () {
        // Compares the name of the container in <Droppable /> props. 'items'
        return this.dragDropMonitor.draggingOver.name === this.name;
    }

    onDragOver (isOver = true) {
        this.dragDropMonitor.onDragUpdate(this, isOver);
    }
}

class PinDroppable extends Droppable {
    constructor (dragDropMonitor, eventHandlers) {
        super(dragDropMonitor);

        makeObservable(this, {
            baseOnDropPin: action,
            onDropPin: action
        });

        Object.assign(this, eventHandlers);
    }

    /**
     * @param {HTMLElement} container Where it is dropped
     * @param {object} positionFuncs
     * @param {Function} action Callback
     */
    baseOnDropPin (action) {
        const draggedItem = this.dragDropMonitor.draggedItem;
        this.dragDropMonitor.onDragEnd();
        return action(draggedItem);
    }

    /**
     * @param {HTMLElement} container Where it is dropped
     * @param {object} positionFuncs
     * @param {Function} action Callback
     */
    onDropPin (container, positionFuncs, action) {
        const draggedItem = this.dragDropMonitor.draggedItem;
        const { left, top } = this.dragDropMonitor.draggedPinPosition;
        this.dragDropMonitor.onDragEnd();
        const normalizedPosition = positionFuncs.normalized(left, top, container, this.dragDropMonitor.editor.slide.scaleFactor);

        return action(
            draggedItem,
            { position: normalizedPosition }
        );
    }

    onDropPin2 (slideDOM, action) {
        const box = (this.dragDropMonitor.draggedPinElement && this.dragDropMonitor.draggingOverBox)
            ? this.dragDropMonitor.draggingOverBox
            : this.dragDropMonitor.editor.slide._BACKGROUND_BOX;

        const scaleFactor = box.editorBox.store.dimensionStore.scaleFactor;

        const draggedItem = this.dragDropMonitor.draggedItem; // This contains type (for draggable widget) and asset for BoardItems. Used in board.createPin

        const slideBox = slideDOM.getBoundingClientRect();
        const imageBox = box.pinContainerRef.current.getBoundingClientRect();
        const DOMNode = this.dragDropMonitor.draggedPinElement
            ? this.dragDropMonitor.draggedPinPosition
            : this.dragDropMonitor.draggedImagePosition; // pin.getClientBoundingRect()

        /*
        Since the Slide (#canvas) is scaled with CSS (scaleFactor),
        but the space around it (#available-space, the gray portion) is not,
        we have to consider scaling only within the slide and
        ignore the non-scaled gray area around the Slide
        */
        const imageRelativeToSlide = {
            left: imageBox.left - slideBox.left,
            top: imageBox.top - slideBox.top
        };

        this.dragDropMonitor.onDragEnd();

        const position = rotatedCoordinates(
            { ...box.editorBox.contentBox, rotation: box.editorBox.rotation },
            {
                left: (DOMNode.left - imageRelativeToSlide.left - slideBox.left) / scaleFactor,
                top: (DOMNode.top - imageRelativeToSlide.top - slideBox.top) / scaleFactor
            },
            {
                width: imageBox.width / scaleFactor,
                height: imageBox.height / scaleFactor
            },
            DOMNode.width
        );

        return action(
            draggedItem,
            {
                position: {
                    x: position.x,
                    y: position.y
                }
            }
        );
    }
}

const Droppables = {
    [DroppableTypes.ITEMS.name]: class extends Droppable {},
    [DroppableTypes.BOARD_PINS.name]: class extends PinDroppable {},
    [DroppableTypes.TEXT_EDITOR_PINS.name]: class extends PinDroppable {},
    [DroppableTypes.MAP_PINS.name]: class extends PinDroppable {},
    [DroppableTypes.TOUR_PLACE.name]: class extends PinDroppable {},
    [DroppableTypes.NOTHING.name]: class extends Droppable {}
};

Object.keys(Droppables).forEach(k => {
    const type = DroppableTypes[k.toUpperCase()];
    Object.assign(Droppables[k].prototype, {
        name: type.name,
        zIndex: type.zIndex
    });
});

class DragDropMonitor {
    draggedItem = null;
    draggedPinElement = null;
    draggedImageElement = null;
    layers = [];
    draggingOverBox = null;

    constructor (editor) {
        makeObservable(this, {
            draggedItem: observable,
            draggedPinElement: observable,
            draggedImageElement: observable,
            layers: observable,
            registerDroppable: action,
            draggedPinPosition: computed,
            draggingOver: computed,
            onDragStart: action,
            onDragUpdate: action,
            onDragCancel: action,
            onDragEnd: action,
            onCursorUp: action,
            onCursorMove: action,
            isDragging: computed,
            draggingOverBox: observable,
            setDraggingOverBox: action,
            reset: action,
            setDraggedPin: action,
            setDraggedImage: action
        });

        this.editor = editor;
        this.layers[DroppableTypes.NOTHING.zIndex] = {
            isDraggingOver: true,
            droppable: DroppableTypes.NOTHING.create(this)
        };
        PinDroppables.zIndexes.forEach(i => {
            this.layers[i] = {
                isDraggingOver: false,
                droppable: null
            };
        });
        this.layers[DroppableTypes.ITEMS.zIndex] = {
            isDraggingOver: false,
            droppable: null
        };
        this.reset();
    }

    setDraggingOverBox = (box) => {
        this.draggingOverBox = box instanceof ImageBox ? box : null;
    };

    registerDroppable (droppable) {
        this.layers[droppable.zIndex] = {
            isDraggingOver: false,
            droppable
        };
    }

    get draggedPinPosition () {
        const pin = this.draggedPinElement;
        return pin ? pin.getBoundingClientRect() : { top: 0, left: 0 };
    }

    get draggedImagePosition () {
        const node = this.draggedImageElement;
        return node ? node.getBoundingClientRect() : { top: 0, left: 0 };
    }

    get draggingOver () {
        // Returns the layer that has isDraggingOver, and gets it's droppable. - PinDroppable / Droppable
        return this.layers.slice().reverse().find(l => l.isDraggingOver).droppable;
    }

    onDragStart (item) {
        this.draggedItem = item;
        this.layers[DroppableTypes.ITEMS.zIndex].isDraggingOver = true;
    }

    onDragUpdate (droppable, isOver) {
        if (!this.isDragging) return;
        if (this.layers[droppable.zIndex].isDraggingOver !== isOver) {
            this.layers[droppable.zIndex].isDraggingOver = isOver;
        }
    }

    onDragCancel () {
        if (!this.isDragging) return;
        this.reset();
    }

    onDragEnd () {
        if (!this.isDragging) return;
        if (this.draggingOver.name === DroppableTypes.ITEMS.name) {
            this.reset();
        }
    }

    onCursorUp (ev) {
        if (!this.draggingOver.onCursorUp) return;
        this.draggingOver.onCursorUp(ev);
    }

    onCursorMove (ev) {
        PinDroppables.zIndexes
            .map(zIndex => this.layers[zIndex] && this.layers[zIndex].droppable)
            .filter(droppable => droppable && droppable.onCursorMove)
            .forEach(droppable => {
                this.onDragUpdate(droppable, droppable.onCursorMove(ev));
            });
    }

    get isDragging () {
        return !!this.draggedItem;
    }

    reset () {
        PinDroppables.zIndexes.forEach(i => {
            this.layers[i].isDraggingOver = false;
        });
        this.layers[DroppableTypes.ITEMS.zIndex].isDraggingOver = false;
        this.draggedItem = null;
    }

    setDraggedPin (pin) {
        this.draggedPinElement = pin;
    }

    setDraggedImage (imageDOMNode) {
        this.draggedImageElement = imageDOMNode;
    }
}

class DragDropEventListener {
    constructor (component) {
        this.component = component;
        this.lastTouch = null;
    }

    listen () {
        window.addEventListener('mousemove', this.onCursorMove);
        window.addEventListener('touchmove', this.onCursorMove);
        window.addEventListener('mouseup', this.onCursorUp);
        window.addEventListener('touchend', this.onCursorUp);
    }

    unlisten () {
        window.removeEventListener('mousemove', this.onCursorMove);
        window.removeEventListener('touchmove', this.onCursorMove);
        window.removeEventListener('mouseup', this.onCursorUp);
        window.removeEventListener('touchend', this.onCursorUp);
    }

    onCursorUp = ev => {
        if (!ev.defaultPrevented) { // not drop event
            return;
        }
        if (ev.touches) {
            this.lastTouch && this.component.editor.dragDropMonitor.onCursorUp(this.lastTouch);
            this.lastTouch = null;
        } else {
            this.component.editor.dragDropMonitor.onCursorUp(ev);
        }
    };

    onCursorMove = ev => {
        if (ev.touches) {
            if (ev.touches.length) {
                // Drag-drop uses only the first touch point
                this.lastTouch = ev.touches.item(0);
                this.component.editor.dragDropMonitor.onCursorMove(this.lastTouch);
            }
        } else {
            this.component.editor.dragDropMonitor.onCursorMove(ev);
        }
    };
}

export {
    DragDropMonitor,
    Droppables,
    DroppableTypes,
    DragDropEventListener,
    PinDroppables
};
