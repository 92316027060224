import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

function CommentPreview ({ comment }) {
    const [expand, setExpand] = useState(false);
    const ref = useRef();

    const onClick = () => {
        if (
            window.getSelection().getRangeAt(0).startContainer.parentNode === ref.current &&
            window.getSelection().toString().length > 0
        ) return;
        setExpand(!expand);
    };

    return (
        <div
            ref={ref}
            className='content comment-text'
            onClick={onClick}
            dangerouslySetInnerHTML={{
                __html: expand
                    ? comment.content
                    : (
                        comment.preview + (
                            comment.preview !== comment.content
                                ? ` <a>${gettext('Show more ...')}</a>`
                                : ''
                        )
                    )
            }}
            data-what='comment-body'
        />
    );
}

CommentPreview.propTypes = {
    comment: PropTypes.object
};

export default CommentPreview;
