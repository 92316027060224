// sync this with the pin css class
const isPhoneScreen = Settings.device.getScreenInfo().size === 'xs';
const PIN_WIDTH = isPhoneScreen ? 15 : 20;
const PIN_HEIGHT = isPhoneScreen ? 15 : 20;

const pinPoint = {
    viewPoint (direction, scene) {
        const cameraEl = scene.querySelector('.camera');
        if (!(cameraEl)) {
            return;
        }

        // Get direction vector from normalized coordinates
        const camera = cameraEl.components.camera.camera;
        const origin = new THREE.Vector3();
        origin.setFromMatrixPosition(camera.matrixWorld);

        // Calculate position
        const position = new THREE.Vector3();
        const distance = 5;
        const { x, y, z } = direction;
        position.addVectors(origin, new THREE.Vector3(x, y, z).multiplyScalar(distance));

        return position;
    },
    positionWithin (position, scene) {
        return this.viewPoint(position, scene);
    },
    normalized (x, y, scene, offset = { x: PIN_WIDTH, y: PIN_HEIGHT }, relative = false) {
        const canvas = scene.canvas;
        const cameraEl = scene.querySelector('.camera');
        if (!(cameraEl && canvas)) {
            return;
        }

        // Get mouse click normalized coordinates
        const canvasRect = relative
            ? { left: 0, top: 0 }
            : canvas.getBoundingClientRect();
        const [normX, normY] = [
            ((x + offset.x - canvasRect.left) / canvas.clientWidth) * 2 - 1,
            -((y + offset.y - canvasRect.top) / canvas.clientHeight) * 2 + 1
        ];

        // Get direction vector from normalized coordinates
        const camera = cameraEl.components.camera.camera;
        const origin = new THREE.Vector3();
        origin.setFromMatrixPosition(camera.matrixWorld);
        const direction = new THREE.Vector3();
        direction.set(normX, normY, 0.5).unproject(camera).sub(origin).normalize();
        return {
            x: direction.x,
            y: direction.y,
            z: direction.z
        };
    },
    normalizedRelative (x, y, container, offset = { x: PIN_WIDTH, y: PIN_HEIGHT }) {
        return this.normalized(x, y, container, offset, true);
    },
    sceneToCanvas (object, scene, offset = { x: PIN_WIDTH, y: PIN_HEIGHT }) {
        const canvas = scene.canvas;
        const cameraEl = scene.querySelector('.camera');
        if (!(cameraEl && canvas)) {
            return;
        }
        const camera = cameraEl.components.camera.camera;

        const canvasRect = canvas.getBoundingClientRect();
        const { width, height } = canvasRect;
        const [widthHalf, heightHalf] = [width / 2, height / 2];

        let pos = new THREE.Vector3();
        pos = pos.setFromMatrixPosition(object.matrixWorld);
        pos.project(camera);
        return {
            x: (pos.x * widthHalf) + widthHalf - offset.x,
            y: -(pos.y * heightHalf) + heightHalf - offset.y
        };
    }

};

const pinPoint2D = {
    viewPoint (x, y, container) {
        return {
            position: 'absolute',
            left: x,
            top: y,
            units: 'px'
        };
    },
    positionWithin (x, y, container) {
        return { x, y };
    },
    normalized (x, y, container) {
        return { x, y };
    },
    normalizedRelative (x, y, container) {
        return { x, y };
    }
};

export {
    pinPoint,
    pinPoint2D
};
