import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
    computed,
    observable,
    action,
    autorun,
    runInAction,
    observe,
    makeObservable
} from 'mobx';

// Utils
import { makeRef } from '../../utils/react-utils';
import { withLoadDependency } from '../../common/loaderHOCs';
import { isCursorOverElement, isPointInRect } from '../../../lib/dom-utils';
import { delay } from '../../../lib/utils';
import { buildEditorState } from '../../Editor/Board/TextEditor/editor-funcs';
import { pinPoint } from './point';

// Drag
import { DroppableTypes } from '../../Editor/DragDropMonitor';
import { DraggablePinCreator } from '../../Editor/DraggablePinCreators';

// Scene
import loadAframe from '~static/file-viewer/panorama/aframe-loader';
import SelectCameraPosition from './SelectCameraPosition';
import PanoramaScene from '../../../Components/Panorama/PanoramaScene';
import { scene as sceneTemplate, pins as pinsTemplate } from './templates';

// Stores
import SceneStore from './SceneStore';
import UIStore from '../../Stores/UI';
import { inject } from '../../Store';

// Pins
import { getPinSize } from '../../Pins/Pin';
import { Portal, AssetPin, TextPin, LinkPin, TourPin } from '../../models/tour';
import { PortalEditorPopup } from './PinEditors/PortalEditorPopup';
import { AssetPinEditorPopup } from './PinEditors/AssetPinEditorPopup';
import { TextPinEditorPopup } from './PinEditors/TextPinEditorPopup';
import { LinkPinEditorPopup } from './PinEditors/LinkPinEditorPopup';

// UI
import { Drawer } from '@vectorworks/vcs-ui/dist/lib/Drawer/Drawer';

import LoadingSpinner from '../../common/LoadingSpinner';
import PortalUI from '../../../Components/Portal';
import FileOverlay from '../../Layouts/Editor/FileOverlay';
import AssetLibraryDialog from '../../Dialog/AssetLibraryDialog';
import { FileTypeCanvas } from './FileTypeCanvas';
import { assetValidators } from '../../validations';
import { PinInfo } from './Popover';

const PinEditor = observer(({ pin, onClose, ...rest }) => {
    const Component = [
        [Portal, PortalEditorPopup],
        [AssetPin, AssetPinEditorPopup],
        [TextPin, TextPinEditorPopup],
        [LinkPin, LinkPinEditorPopup]
    ].find(([pinType, _]) => pin instanceof pinType)[1];

    return <Component pin={pin} onClose={onClose} {...rest} />;
});
PinEditor.propTypes = {
    onClose: PropTypes.func,
    pin: PropTypes.oneOfType([Portal, AssetPin, TextPin, LinkPin].map(PropTypes.instanceOf))
};

let isMouseHold = false;
let droppedAPin = false;

class EditorPanoramaViewer extends React.Component {
    name = 'EditorPanoramaViewer';
    modeName = 'normal';
    editedPin = null;

    constructor (props) {
        super(props);

        makeObservable(this, {
            modeName: observable,
            editedPin: observable,
            mode: computed,
            setMode: action,
            cancelPin: action
        });

        this.registerDroppable();
        this.sceneRef = makeRef();
        this.containerRef = React.createRef();
        this.cameraRotation = null;
        this.sceneStore = new SceneStore(this.props.editor, { inEditor: true });
        this.props.editor.registerSceneRef(this.sceneRef);
    };

    sceneEventHandlers = {
        'pin-click': ev => this.modeHandleEvent('pin-click', ev),
        'canvas-mousedown': ev => this.modeHandleEvent('canvas-mousedown', ev),
        'canvas-mousemove': ev => this.modeHandleEvent('canvas-mousemove', ev),
        'canvas-mouseup': ev => this.modeHandleEvent('canvas-mouseup', ev),
        'canvas-touchstart': ev => this.modeHandleEvent('canvas-mousedown', ev),
        'pin-mouseenter': ev => this.modeHandleEvent('pin-mouseenter', ev),
        'pin-mouseleave': ev => this.modeHandleEvent('pin-mouseleave', ev),
        'pin-mousedown': ev => this.modeHandleEvent('pin-mousedown', ev),
        'pin-mouseup': ev => this.modeHandleEvent('pin-mouseup', ev),
        'camera-rotate': ev => {
            this.cameraRotation = ev.detail;
        }
    };

    registerDroppable () {
        const viewer = this;
        this.droppable = DroppableTypes.TOUR_PLACE.register(
            this.props.editor.dragDropMonitor,
            {
                onCursorUp (ev) {
                    return viewer.mode.onCursorUp(ev);
                },
                onCursorMove (ev) {
                    return viewer.mode.onCursorMove(ev);
                }
            }
        );
        this.trackDragOverDisposer && this.trackDragOverDisposer();
        this.trackDragOverDisposer = autorun(() => {
            if (this.droppable.isDraggingOver) {
                this.setMode('placePin');
            } else if (
                /*
                    You cancelled to drop a pin over the canvas.
                    placePin mode should be escaped, otherwise the scene gets locked.
                 */
                this.modeName === 'placePin' &&
                !this.droppable.isDraggingOver &&
                !droppedAPin
            ) {
                this.setMode('normal');
            }
        });
    }

    forceCameraRotation () {
        this.cameraRotation && delay().then(() => {
            this.sceneRef.current.emit('camera-rotation-request', this.cameraRotation);
        });
    }

    get mode () {
        return this.modes[this.modeName];
    }

    setMode (mode, state) {
        this.props.editor.unhoverPin();

        /*
        Execute the change on next iteration using delay()
        Otherwise event handlers between modes could collide
        */
        return delay().then(() => {
            this.mode.onLeave({ nextMode: mode });
            runInAction(() => {
                this.modeName = mode;
                this.mode.state = state;
            });
            /*
            You can still override state in onEnter.
            It just default to what you entered the mode with.
             */
            this.mode.onEnter(state);
        });
    }

    componentDidMount () {
        this.trackPlace();
        this.trackMode();
        this.sceneStore.precalcPinPositions();
        this.props.dragMovePinManager.subscribe(
            this.props.dragMovePinManager.LAYERS.BOARD, this
        );
    }

    componentWillUnmount () {
        this.placeTracker && this.placeTracker();
        this.props.dragMovePinManager.unsubscribe(this.props.dragMovePinManager.LAYERS.BOARD);
        this.props.ui.toggle('pinEditor', false);
    }

    componentDidUpdate (prevProps) {
        if (this.props.editor !== prevProps.editor) {
            this.sceneStore.reset(this.props.editor);
            this.sceneStore.precalcPinPositions();
            this.setMode('normal');
            this.trackPlace();
            this.trackMode();
            this.registerDroppable();
        } else if (this.props.editor.dragDropMonitor !== prevProps.editor.dragDropMonitor) {
            this.registerDroppable();
        }
    }

    trackPlace () {
        this.placeTracker && this.placeTracker();
        this.placeTracker = this.props.editor &&
            observe(this.props.editor, 'place', change => this.setMode('normal'));
    }

    trackMode () {
        this.modeTracker && this.modeTracker();
        this.modeTracker = this.props.editor &&
            observe(this.props.editor, 'mode', change => this.setMode(change.newValue));
    }

    modeHandleEvent (name, ev) {
        const handler = this.mode.sceneEventHandlers[name];
        handler && handler(ev);
    }

    onDragPinEnd = (...args) => {
        return this.mode.onDragPinEnd && this.mode.onDragPinEnd(...args);
    };

    onDropPin = (...args) => {
        return this.mode.onDropPin && this.mode.onDropPin(...args);
    };

    cancelPin = () => {
        droppedAPin = false;
        runInAction(() => { this.setMode('normal'); });
        return Promise.reject();
    };

    createPanoramaPin = async (fromPlace, normalizedPosition, toPlace) => {
        await this.setMode('chooseCameraRotation', this.mode.state);

        return this.props.dialog.open({
            component: SelectCameraPosition,
            context: {
                store: this.props.editor.root,
                place: toPlace
            }
        }).result
            .then(cameraRotation => {
                return runInAction(() => {
                    return fromPlace.addPortal({
                        title: toPlace.asset.title,
                        props: this.props.ui.defaultPresets.default,
                        position: normalizedPosition,
                        to_place: toPlace.id,
                        to_place_props: { cameraRotation }
                    });
                });
            })
            .catch(() => this.cancelPin())
            .finally(async () => {
                /*
                After the Set Camera Rotation dialog (Save OR Cancel), your panorama rotation
                should be reset back to the last scene rotation
                */
                return this.setMode('normal').then(() => {
                    runInAction(() => {
                        this.forceCameraRotation();
                    });
                });
            });
    };

    createAssetPin = (fromPlace, normalizedPosition, asset) => {
        if (asset.fileVersion.file_type === 'PANORAMA') {
            return this.props.editor.slide.getOrCreatePlaceByAsset(asset)
                .then(toPlace => this.createPanoramaPin(fromPlace, normalizedPosition, toPlace));
        } else {
            return runInAction(() => {
                return fromPlace.addAssetPin({
                    title: asset.filename,
                    props: this.props.ui.defaultPresets.default,
                    position: normalizedPosition,
                    asset: asset.uuid
                });
            });
        }
    };

    createTextPin = (fromPlace, normalizedPosition) => {
        return runInAction(() => {
            return fromPlace.addTextPin({
                title: gettext('Title'),
                props: this.props.ui.defaultPresets.default,
                position: normalizedPosition,
                text_props: buildEditorState()
            });
        });
    };

    createLinkPin = (fromPlace, normalizedPosition) => {
        return runInAction(() => {
            return fromPlace.addLinkPin({
                title: gettext('Title'),
                props: this.props.ui.defaultPresets.default,
                position: normalizedPosition,
                link: 'https://example.com',
                in_new_tab: true
            });
        });
    };

    createPendingTypePin = (fromPlace, normalizedPosition) => {
        return runInAction(() => {
            return fromPlace.addPendingTypePin({
                props: this.props.ui.defaultPresets.default,
                position: normalizedPosition
            }).then(pin => {
                pin.result
                    .then(async (droppedType) => {
                        return this.setMode('normal')
                            .then(() => {
                                return this.createPin(fromPlace, normalizedPosition, droppedType);
                            });
                    });
                return pin;
            });
        });
    };

    createPin = (...args) => {
        return this._createPin(...args)
            .then(pin => {
                this.setMode('editPin', { pin });
                return pin;
            }).finally(() => {
                droppedAPin = false;
            });
    };

    _createPin = (fromPlace, normalizedPosition, toPlace) => {
        const store = this.props.editor.root;

        if (toPlace.type === DraggablePinCreator.TEXT.type) {
            return this.createTextPin(fromPlace, normalizedPosition);
        } else if (toPlace.type === DraggablePinCreator.PENDING_TYPE.type) {
            return this.createPendingTypePin(fromPlace, normalizedPosition);
        } else if (toPlace.type === DraggablePinCreator.LINK.type) {
            return this.createLinkPin(fromPlace, normalizedPosition);
        } else {
            const assetPromise = toPlace.asset
                ? Promise.resolve(toPlace.asset)
                : store.dialog.open({
                    component: AssetLibraryDialog,
                    context: {
                        store,
                        validateSelection: items => items.length === 1 && items[0].data.isReady,
                        selectDialogProps: {
                            dialogTitle: gettext('Add files to presentation resources'),
                            validateSelection: items => items.length > 0 && items.every(a => a.isFile)
                        },
                        filterFunction: item => assetValidators.BOARD.isFileTypeAllowed(item.data.filename),
                        uploadValidation: assetValidators.BOARD.forUpload(
                            store.asset,
                            store.dialog,
                            gettext('The following files are not supported in a Tour and will not be uploaded.')
                        )
                    }
                }).result
                    .then(assets => assets[0])
                    .catch(() => this.cancelPin());

            return assetPromise.then(asset => {
                return this.createAssetPin(fromPlace, normalizedPosition, asset);
            });
        }
    };

    modes = {
        createPendingTypePin: {
            state: {},
            onEnter: () => {
                this.sceneStore.controlScene('lookControls', false);
            },
            onLeave: () => {
                this.sceneStore.controlScene('lookControls', true);
                this.mode.state.pin.place.removePendingTypePin(this.mode.state.pin);
                this.setMode('normal');
            },
            onCursorMove: () => {},
            onCursorUp: ev => {},
            getStyles: () => ({}),
            sceneEventHandlers: {
                'canvas-mouseup': ev => {
                    this.mode.onLeave();
                }
            },
            render: () => {
                const aFramePin = document.getElementById('bind-for-pins--1');
                if (!aFramePin) return null;

                const position = pinPoint.sceneToCanvas(
                    aFramePin.object3D,
                    this.sceneRef.current
                );

                return (
                    <PinInfo
                        container={this.containerRef.current}
                        hoveredPin={{
                            pin: this.mode.state.pin,
                            position
                        }}
                        screen={this.props.editor}
                    />
                );
            }
        },
        normal: {
            state: {},
            onEnter: () => {},
            onLeave: () => {},
            onCursorMove: ev => {
                const canvas = this.sceneRef.current && this.sceneRef.current.canvas;
                return isCursorOverElement(canvas, ev);
            },
            onCursorUp: ev => {},
            getStyles: () => ({}),
            sceneEventHandlers: {
                'pin-click': ev => {
                    const pin = this.props.editor.place.pins.find(p => p.id === ev.detail.id);
                    this.setMode('editPin', { pin });
                },
                'pin-mouseenter': ev => {
                    if (!ev.detail.event.detail.intersection) return;
                    const pin = this.props.editor.place.pins.find(p => p.id === ev.detail.id);
                    const scene = this.sceneRef.current;
                    const position = pinPoint.sceneToCanvas(
                        ev.detail.event.detail.intersection.object,
                        scene
                    );
                    this.props.editor.hoverPin(pin, position);
                },
                'pin-mouseleave': ev => {
                    this.props.editor.unhoverPin();
                }
            },
            render: () => null
        },
        placePin: {
            state: {},
            onEnter: () => {
                this.sceneStore.controlScene('lookControls', false);
            },
            onLeave: () => {
                this.sceneStore.controlScene('lookControls', true);
            },
            onCursorMove: ev => {
                const canvas = this.sceneRef.current && this.sceneRef.current.canvas;
                return isCursorOverElement(canvas, ev);
            },
            onCursorUp: ev => {
                if (!this.sceneRef.current || !this.sceneRef.current.canvas) {
                    return;
                }

                droppedAPin = true;
                const dragDropMonitor = this.droppable.dragDropMonitor;
                const container = this.sceneRef.current;
                const toPlace = dragDropMonitor.draggedItem;
                const fromPlace = this.props.editor.place;
                const { left, top } = dragDropMonitor.draggedPinPosition;
                dragDropMonitor.onDragEnd();
                const normalizedPosition = pinPoint.normalized(left, top, container);

                this.createPin(fromPlace, normalizedPosition, toPlace);
            },
            getStyles: () => ({}),
            sceneEventHandlers: {},
            render: () => null
        },
        editPin: {
            state: {},
            onEnter: ({ pin }) => {
                if (!pin) { return this.cancelPin(); }
                if (pin.pinType === TourPin.TYPES.pendingTypePin) {
                    return this.setMode('createPendingTypePin', { pin });
                }

                this.sceneStore.controlScene('lookControls', false);
                this.props.ui.toggle('pinEditor', true);
                runInAction(() => {
                    this.editedPin = pin;
                    this.sceneStore.selectedPin = pin.id;
                });
            },
            onLeave: ({ nextMode }) => {
                this.sceneStore.controlScene('lookControls', true);
                this.props.ui.toggle('pinEditor', nextMode === 'editPin');
                runInAction(() => {
                    this.sceneStore.selectedPin = undefined;
                });
            },
            onCursorMove: ev => {
                const canvas = this.sceneRef.current && this.sceneRef.current.canvas;
                return isCursorOverElement(canvas, ev);
            },
            onCursorUp: ev => {},
            getStyles: () => ({}),
            sceneEventHandlers: {
                'canvas-mousemove': ev => {
                    if (isMouseHold) {
                        const canvasBCR = this.sceneRef.current.canvas.getBoundingClientRect();

                        if (!isMouseHold.offsetX) {
                            isMouseHold.offsetX = ev.pageX - isMouseHold.x - canvasBCR.left;
                            isMouseHold.offsetY = ev.pageY - isMouseHold.y - canvasBCR.top;
                        }

                        if (isPointInRect({ left: ev.pageX, top: ev.pageY }, canvasBCR)) {
                            /*
                                Using this.editedPin, would cause delay and wrong pin movement if you drag two different pins too
                                quickly. This is because MobX re-evaluates the this.editedPin slower.
                                So, I just inject the moved pin in the isMouseHold variable.
                            */
                            isMouseHold.pin.move({
                                scene: this.sceneRef.current,
                                position2D: {
                                    x: ev.pageX - isMouseHold.offsetX,
                                    y: ev.pageY - isMouseHold.offsetY
                                }
                            });
                        }
                    }
                },
                'canvas-mouseup': ev => {
                    !isMouseHold && this.setMode('normal');
                    isMouseHold = false;
                },
                'pin-mousedown': ev => {
                    const pin = this.props.editor.place.pins.find(p => p.id === ev.detail.id);
                    this.setMode('editPin', { pin });
                    isMouseHold = pinPoint.sceneToCanvas(
                        ev.detail.event.detail.intersection.object,
                        this.sceneRef.current,
                        {
                            x: getPinSize(pin.props.size) / 2,
                            y: getPinSize(pin.props.size) / 2
                        }
                    );
                    isMouseHold.pin = pin;
                }
            },
            onDragPinEnd: ({ rect, pin }) => {
                const canvas = this.sceneRef.current && this.sceneRef.current.canvas;
                if (canvas && isPointInRect(rect, canvas.getBoundingClientRect())) {
                    pin.move({
                        position2D: { x: rect.left, y: rect.top },
                        scene: this.sceneRef.current
                    });
                    return true;
                }
            },
            onDropPin: () => {},
            render: () => null
        },
        chooseCameraRotation: {
            state: {},
            onEnter: () => {},
            onLeave: () => {
                this.props.editor.registerSceneRef(this.sceneRef);
            },
            onCursorMove: ev => {},
            onCursorUp: ev => {},
            getStyles: () => ({}),
            sceneEventHandlers: {},
            render: () => <div
                style={{
                    width: '100%',
                    backgroundColor: 'grey'
                }}
            />
        }
    };

    renderScene () {
        return (
            <React.Fragment>
                <LoadingSpinner resource={this.sceneStore.image}/>
                <PanoramaScene
                    sceneRef={this.sceneRef}
                    eventHandlers={this.sceneEventHandlers}
                    sceneStore={this.sceneStore}
                    sceneProps={{
                        sceneChildren: pinsTemplate
                    }}
                    sceneTemplate={sceneTemplate}
                />
                {/* Reuse filetypes instead of rendering new canvas for each type */}
                <FileTypeCanvas pins={this.props.editor.place.pins} />
            </React.Fragment>
        );
    }

    render () {
        const isDraggingOver = this.droppable.isDraggingOver;
        const draggedItem = this.droppable.dragDropMonitor.draggedItem;

        return (
            <div
                className='fileview-component-loader panorama-viewer'
                data-what='file-viewer'
                ref={this.containerRef}
                style={{
                    position: 'relative',
                    ...this.mode.getStyles()
                }}
            >
                {
                    isDraggingOver &&
                    <FileOverlay
                        icon='icon-hand'
                        color='blue'
                        text={(() => {
                            if (draggedItem.isDraggablePinCreator) {
                                if (draggedItem.type === DraggablePinCreator.ASSET.type) {
                                    return gettext('Drop to pin a file to the tour or to link panoramas.');
                                } else if (draggedItem.type === DraggablePinCreator.LINK.type) {
                                    return gettext('Drop to create a hyperlink pin.');
                                } else if (draggedItem.type === DraggablePinCreator.TEXT.type) {
                                    return gettext('Drop to create a text pin.');
                                } else if (draggedItem.type === DraggablePinCreator.PENDING_TYPE.type) {
                                    return gettext('Drop to create a pin.');
                                }
                            } else if (draggedItem.asset) {
                                if (draggedItem.asset.isPanorama) {
                                    return gettext('Drop to create a pin and link panoramas.');
                                } else {
                                    return gettext('Drop to pin the file to the tour.');
                                }
                            } else {
                                return gettext('Drop to create a pin.');
                            }
                        })()}
                    />
                }
                { this.props.children }
                { this.mode.render() }
                <PortalUI container='.toolbar'>
                    <Drawer
                        open={this.props.ui.pinEditor}
                        anchor='right'
                        variant='persistent'
                        className='ib-carousel'
                    >
                        {
                            this.editedPin
                                ? <PinEditor
                                    onClose={() => {
                                        this.props.ui.toggle('pinEditor', false);
                                        this.setMode('normal');
                                    }}
                                    pin={this.editedPin}
                                    editor={this}
                                />
                                : null
                        }
                    </Drawer>
                </PortalUI>
                { this.modeName !== 'chooseCameraRotation' && this.renderScene() }
            </div>
        );
    };
}

EditorPanoramaViewer.propTypes = {
    editor: PropTypes.object,
    ui: PropTypes.instanceOf(UIStore),
    dialog: PropTypes.object,
    dragMovePinManager: PropTypes.object
};

export default inject('dialog')(inject('ui')(
    inject('dragMovePinManager')(withLoadDependency(loadAframe)(observer(EditorPanoramaViewer)))
));
