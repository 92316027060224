import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { usePopupState, bindMenu } from 'material-ui-popup-state/hooks';
import { Menu } from '@vectorworks/vcs-ui/dist/lib/Menu/Menu';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import ViewerContext from '../../ViewerContext';

import { pubsub } from '../../../../../../base';

const S = {
    FilterTogglerContainer: styled.div`
        ${({ isMobile }) => isMobile
        ? css`
            height: 24px;
            width: 24px;
        `
        : css`
            height: 16px;
            width: 16px;
        `}

        ${({ isHidden }) => isHidden && css`
            visibility: hidden;
        `}

        display: flex;
        justify-content: center;
        align-items: center;
    `
};

const ColumnFilterToggler = ({ columnId, isHidden }) => {
    const { store, isMobile } = React.useContext(ViewerContext);
    const [icon, setIcon] = React.useState('filter-16');
    const [isFilterOpen, setIsFilterOpen] = React.useState(false);
    const popperState = usePopupState({ variant: 'popper', popupId: 'filterTooltip' });
    let clickTimeout = null;

    const updateIcon = () => {
        const filter = store.filters[columnId];
        const newIcon = (filter && store.isFilterSet(filter) && filter.isActive)
            ? 'filter-on-16'
            : 'filter-16';
        setIcon(newIcon);
    };

    React.useEffect(() => {
        pubsub.subscribe(ColumnFilterToggler, 'ccad.filters.icon.update', (id) => {
            id === columnId && updateIcon();
        });
        pubsub.subscribe(ColumnFilterToggler, 'ccad.filtermenu.open', () => {
            setIsFilterOpen(true);
            hideTooltip();
        });
        pubsub.subscribe(ColumnFilterToggler, 'ccad.filtermenu.close', () => {
            setIsFilterOpen(false);
        });
        return () => pubsub.unsubscribeAll(ColumnFilterToggler);
    }, []);

    React.useEffect(() => {
        updateIcon();
    }, [store.table]);

    const handleClick = e => {
        if (!isMobile) {
            const clickCount = e.detail;
            if (clickCount === 1) {
                clickTimeout = setTimeout(() => {
                    if (store.isFilterSet(store.filters[columnId])) {
                        store.toggleFilter(columnId);
                        pubsub.publish('ccad.filters.icon.update', columnId);
                    }
                }, 300);
            } else if (clickCount === 2) {
                clickTimeout && clearTimeout(clickTimeout);
                pubsub.publish('ccad.filtermenu.open', {
                    id: columnId,
                    x: e.clientX,
                    y: e.clientY
                });
            }
        }
    };

    const handlePress = e => {
        if (isMobile) {
            clickTimeout = setTimeout(() => {
                pubsub.publish('ccad.filtermenu.open', {
                    id: columnId,
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY
                });
            }, 1000);
        }
    };

    const handleRelease = e => {
        if (isMobile) {
            clickTimeout && clearTimeout(clickTimeout);
            if (store.isFilterSet(store.filters[columnId])) {
                store.toggleFilter(columnId);
                pubsub.publish('ccad.filters.icon.update', columnId);
            } else {
                !isFilterOpen && showTooltip(e);
            }
        }
    };

    const showTooltip = e => {
        popperState.open(e.target);
    };

    const hideTooltip = () => {
        popperState.close();
    };

    const preventContextMenu = e => {
        e.preventDefault();
    };

    return (
        <ClickAwayListener
            mouseEvent='onMouseDown'
            touchEvent='onTouchStart'
            onClickAway={hideTooltip}
        >
            <div style={{ display: 'flex' }}>
                <S.FilterTogglerContainer
                    isMobile={isMobile}
                    onClick={handleClick}
                    onTouchStart={handlePress}
                    onTouchEnd={handleRelease}
                    onContextMenu={preventContextMenu}
                    isHidden={isHidden}
                    onMouseEnter={showTooltip}
                    onMouseLeave={hideTooltip}
                    data-what='ccad-column-filter-button'
                    data-which={columnId}
                >
                    <Icon
                        icon={icon}
                        size='16px'
                        data-what='ccad-column-filter-icon'
                        data-which={columnId}
                    />
                </S.FilterTogglerContainer>
                <Menu
                    {...bindMenu(popperState)}
                    style={{ pointerEvents: 'none' }}
                >
                    <div style={{ padding: '0 10px' }}>
                        {
                            isMobile
                                ? gettext('Long tap to add filters. Tap to toggle.')
                                : gettext('Double click to add filters. Single click to toggle.')
                        }
                    </div>
                </Menu>
            </div>
        </ClickAwayListener>
    );
};

ColumnFilterToggler.propTypes = {
    columnId: PropTypes.string,
    isHidden: PropTypes.bool
};

export default ColumnFilterToggler;
