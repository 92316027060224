import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Bowser from 'bowser';
import '../../bundles/core-vendors';
import convert from 'htmr';

import '../../base/device-info';
import { App } from '../../iboards/main/preview';
import keyboard from '../../base/keyboard';
import DialogFrame from '../../Components/Dialog/DialogFrame';
import DialogContainer from '../../Components/Dialog/Dialog';
import DialogStore from '../../Components/Dialog/Store';
import '../../NomadWebview/host-notification';

import 'expose-loader?exposes=io!socket.io-client';  // eslint-disable-line
import 'screenfull/dist/screenfull';

window.location.hash = `#/presentations/${Settings.offlinePresentation.slides.uuid}/`;

keyboard.pushKeyEvents({});
keyboard.init();

const MACOSGuide = props => {
    const onSubmit = () => props.dialog.dismiss();

    return (
        <DialogFrame
            title={gettext('Instructions')}
            submitTitle={gettext('OK')}
            dialog={props.dialog}
            onSubmit={onSubmit}
            onCancel={onSubmit}
            extend={true}
            hideCancel
        >
            <div className='di-block'>
                <p>{ convert(gettext('We recommend you use the Mozilla Firefox browser to view downloaded presentations.')) }</p>
                <div>{ convert(gettext('Alternatively, launch the presentation in Safari as follows:')) }</div>
                <div>{ convert(gettext('1. In Safari, select <b>Edit > Preferences.</b>')) }</div>
                <div>{ convert(gettext('2. From the <b>Advanced</b> tab, select <b>Show Develop menu in the menu bar.</b>')) }</div>
                <div>{ convert(gettext('3. From the <b>Develop</b> menu, select <b>Disable Local File Restrictions</b>')) }</div>
                <div>{ convert(gettext('4. Open index.html with Safari.')) }</div>
            </div>
        </DialogFrame>
    );
};

MACOSGuide.propTypes = {
    dialog: PropTypes.object
};

const WINGuide = props => {
    const onSubmit = () => props.dialog.dismiss();

    return (
        <DialogFrame
            title={gettext('Instructions')}
            submitTitle={gettext('OK')}
            dialog={props.dialog}
            onCancel={onSubmit}
            onSubmit={onSubmit}
            extend={true}
            hideCancel
        >
            <div className='di-block'>
                <p>{ convert(gettext('We recommend you use the Launcher.exe file in the presentation folder to view downloaded presentations.')) }</p>
                <div>{ convert(gettext('Alternatively, open the index.html file with Mozilla Firefox or Microsoft Edge.')) }</div>
            </div>
        </DialogFrame>
    );
};

WINGuide.propTypes = {
    dialog: PropTypes.object
};

class Wrapper extends React.Component {
    constructor (props) {
        super(props);
        this.dialogStore = new DialogStore(null);
        this.bowser = Bowser.getParser(window.navigator.userAgent).parse().parsedResult;
    }

    componentDidMount () {
        const os = this.bowser.os.name;
        const browser = this.bowser.browser.name;
        const isLauncher = window.location.href.includes('launcher=true');

        (['Firefox', 'Microsoft Edge'].includes(browser)) ||
        isLauncher ||
        Settings.offlinePresentation.skipGuide ||
        Settings.IN_WEB_VIEW ||
        this.dialogStore.open({
            component: os === 'Windows'
                ? WINGuide
                : MACOSGuide
        });
    }

    render () {
        return (
            <React.Fragment>
                <App />
                <DialogContainer dialogContainer={this.dialogStore} />
            </React.Fragment>
        );
    }
}

ReactDOM.render(
    <Wrapper />,
    document.getElementById('iboards-root')
);

window.Webview = {
    v1: {
        setupWebview (nomadSupportedFileTypes = []) {
            window.Settings.IN_WEB_VIEW = true;
            window.Settings.NOMAD_SUPPORTED_FILE_TYPES = nomadSupportedFileTypes;
        }
    }
};

export {
    WINGuide
};
