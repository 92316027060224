import React from 'react';

import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';

import Filter from './Filter';
import S from '../styles';
import ViewerContext from '../../ViewerContext';

const SearchBox = () => {
    const { store, isMobile } = React.useContext(ViewerContext);
    const [showClearBtn, setShowClearBtn] = React.useState(false);
    const ref = React.createRef(null);
    const popperState = usePopupState({ variant: 'popper', popupId: 'search' });

    React.useEffect(() => {
        clearSearch();
    }, [store.table]);

    const onChange = e => {
        const trimmed = e.target.value.trim() || '';
        store.setSearchQuery(trimmed);
        setShowClearBtn(trimmed.length > 0);
    };

    const clearSearch = () => {
        store.setSearchQuery('');
        setShowClearBtn(false);
        if (ref.current) {
            ref.current.value = '';
        }
    };

    const submitSearch = () => {
        store.setSearchQuery(ref.current?.value.trim());
        popperState.close();
        setShowClearBtn(true);
    };

    const handleKeyPress = ({ key }) => {
        if (key === 'Enter') {
            submitSearch();
        }
    };

    return isMobile
        ? <Filter
            isActive={showClearBtn}
            clear={clearSearch}
            label='search'
        >
            <S.SearchIcon
                icon='search'
                {...bindTrigger(popperState)}
                active={store.searchQuery}
                open={popperState.isOpen}
                isMobile={isMobile}
                data-what='ccad-filter'
                data-which='search-icon'
            />
            <S.SearchMenu {...bindMenu(popperState)}>
                <S.SearchBox
                    ref={ref}
                    defaultValue={store.searchQuery || ''}
                    isMobile={isMobile}
                    onKeyDown={handleKeyPress}
                    data-what='ccad-filter'
                    data-which='search-input'
                />
                <S.SubmitSearchIcon
                    icon='enter-16'
                    onClick={submitSearch}
                    isMobile={isMobile}
                    data-what='ccad-filter'
                    data-which='search-submit'
                />
            </S.SearchMenu>
        </Filter>
        : <React.Fragment>
            <S.SearchIcon
                icon='search'
            />
            <S.SearchBox
                ref={ref}
                onChange={onChange}
                defaultValue={store.searchQuery || ''}
                isMobile={isMobile}
                data-what='ccad-filter'
                data-which='search-input'
            />
            {
                showClearBtn &&
                    <S.ClearSearchIcon
                        icon='close'
                        onClick={clearSearch}
                        isMobile={isMobile}
                        data-what='ccad-filter'
                        data-which='search-clear'
                    />
            }
        </React.Fragment>;
};

export default SearchBox;
