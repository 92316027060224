import React from 'react';
import PropTypes from 'prop-types';

const FileOverlay = props =>
    <div className={`drag-file-overlay ${props.color}`}>
        <div className='drag-notification'>
            <span className={`icon ${props.icon}`}/>
            <span className='notification-text'>{props.text}</span>
        </div>
    </div>;

FileOverlay.propTypes = {
    icon: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string
};

export default FileOverlay;
