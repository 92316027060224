import React from 'react';

import PropTypes from 'prop-types';

import { DialogThumbsLoader } from '../common/ThumbsLoader';
import { FileTypes } from '../../lib/file';
import DialogFrame from '../../Components/Dialog/DialogFrame';

const LibraryDeleteFileDialog = props => {
    const onSubmit = () => props.dialog.close(props.items);

    const onCancel = () => props.dialog.dismiss();

    const getIcon = filename => {
        return FileTypes.get(filename, false, null).defaultFileTypeIcon();
    };

    return (
        <DialogFrame
            title={gettext('Permanent deletion')}
            dialog={props.dialog}
            submitTitle={gettext('Delete')}
            cancelTitle={gettext('Cancel')}
            onSubmit={onSubmit}
            onCancel={onCancel}
            submitAnalytics={{
                'ga-action': 'Library_Delete_Asset_Dialog',
                'ga-label': 'Click_Submit'
            }}
            cancelAnalytics={{
                'ga-action': 'Library_Delete_Asset_Dialog',
                'ga-label': 'Click_Cancel'
            }}
        >
            <p className='dialog-paragraph'>{ gettext("Are you sure you want to delete these files from the presentation resources? You won't be able to restore later.") }</p>
            <br />
            <div className='items'>
                {
                    props.items.map(item => (
                        <div key={item.uuid} className='di-list-item'>
                            <DialogThumbsLoader thumbnail={item.fileVersion && item.fileVersion.thumbnail} iconHTML={getIcon(item.filename)} />
                            <span className='item-name'>{ item.filename }</span>
                        </div>
                    ))
                }
            </div>
        </DialogFrame>
    );
};

LibraryDeleteFileDialog.propTypes = {
    items: PropTypes.array,
    dialog: PropTypes.any
};

export default LibraryDeleteFileDialog;
