import React from 'react';
import PropTypes from 'prop-types';

import keyboard from '../base/keyboard';

class KeyHandler extends React.Component {
    componentDidMount () {
        this.keyEvents = keyboard.peekKeyEvents();
        this.props.extend
            ? this.keyEvents.extend(this.props.keyEvents)
            : keyboard.pushKeyEvents(this.props.keyEvents);
    }

    componentWillUnmount () {
        this.props.extend
            ? this.keyEvents.reduce()
            : keyboard.popKeyEvents();
    }

    render () {
        return null;
    }
}

KeyHandler.propTypes = {
    keyEvents: PropTypes.object,
    extend: PropTypes.bool
};

KeyHandler.defaulProps = {
    extend: false
};

export default KeyHandler;
