import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';

import {
    isCursorOverElement,
    isPointInRect
} from '../../lib/dom-utils';
import { TargetType } from '../models/board';
import { makeRef } from '../utils/react-utils';
import { inject } from '../Store';
import LazyImage from '../common/LazyImage';
import IterableContainer from '../common/IterableContainer';
import { DroppableTypes } from '../Editor/DragDropMonitor';
import {
    EditorPin as BaseEditorPin,
    withPinPoint,
    MapViewerPin
} from '../Pins/Pin';

import DefaultPin from '../Pins/PreviewPin';

import AfterParent from '../../Components/AfterParent';
import FileOverlay from '../Layouts/Editor/FileOverlay';
import SelectCameraPosition from './Tour/SelectCameraPosition';

const pinPoint = {
    viewPoint (x, y, img) {
        return {
            position: 'absolute',
            left: x * 100,
            top: y * 100,
            units: '%'
        };
    },
    positionWithin (x, y, img) {
        return {
            x: Math.ceil(x * img.clientWidth),
            y: Math.ceil(y * img.clientHeight)
        };
    },
    normalized (x, y, img, scaleFactor = 1) {
        const imgRect = img.getBoundingClientRect();
        return {
            x: ((x - imgRect.left) / img.clientWidth) / scaleFactor,
            y: ((y - imgRect.top) / img.clientHeight) / scaleFactor
        };
    },
    normalizedRelative (x, y, img) {
        return {
            x: x / img.clientWidth,
            y: y / img.clientHeight
        };
    }
};

/* eslint-disable react/prop-types */

const SimpleImageViewer = observer(class SimpleImageViewer extends React.Component {
    img;

    contextWrapper = React.createRef();

    constructor (props) {
        super(props);

        makeObservable(this, {
            img: observable,
            setImg: action
        });
    }

    setImg (imgElement) {
        this.img = imgElement;
    }

    onLoad = () => {
        this.setImg(this.imgElement);
    };

    render () {
        return (
            <div
                className='image-dropcontext-wrapper dropcontext-wrapper'
                ref={this.contextWrapper}
            >
                <span className='helper' />

                <AfterParent>
                    <LazyImage
                        eventHandlers={{ onLoad: this.onLoad }}
                        containerSelector={this.props.containerSelector}
                        placeholder={this.props.file}
                        src={this.props.file.fileVersion.download_url}
                        reference={el => {
                            this.imgElement = el;
                        }}
                    />
                </AfterParent>

                { this.props.children }
            </div>
        );
    }
});

SimpleImageViewer.propTypes = {
    file: PropTypes.object,
    containerSelector: PropTypes.string
};

export const ImageViewer = observer(class ImageViewer extends React.Component {
    img;

    contextWrapper = React.createRef();

    constructor (props) {
        super(props);
        this.version = this.props.screen.slide.version;
        this.imageRef = React.createRef();

        makeObservable(this, {
            img: observable,
            setImg: action
        });
    }

    get pinContainerRef () {
        return this.version === 2
            ? (this.props.box?.pinContainerRef || makeRef()) // A drity fix for Preview mode, when box isn't loaded
            : this.props.pinContainerRef;
    }

    componentWillUnmount () {
        this.pinContainerRef(null);
        if (this.imageRef.current) {
            this.imageRef.current.src = ''; // Cancel pending/unfinished download
        }
    }

    setImg (imgElement) {
        this.img = imgElement;
        this.pinContainerRef(this.img);
    }

    onLoad = () => {
        this.setImg(this.imgElement);
    };

    render () {
        const FileOverlay = this.props.fileOverlay;

        return (
            <div
                // Without this class, the legacy editor doesn't move pins right - they jump sligthly onDrop
                className='image-dropcontext-wrapper dropcontext-wrapper'
                ref={this.contextWrapper}
                style={{
                    width: '100%',
                    height: '100%',
                    ...(this.version === 2
                        ? { backgroundColor: 'rgba(0,0,0,0.1)' }
                        : {})
                }}
            >
                { FileOverlay && FileOverlay }

                {
                    this.version === 2
                        ? (
                            <img
                                className='img-real'
                                src={this.props.file.fileVersion.download_url}
                                style={{
                                    background: `url("${this.props.file.fileVersion.thumbnail}")`,
                                    backgroundSize: 'cover'
                                }}
                                onLoad={(e) => { this.setImg(e.target); }}
                                ref={this.imageRef}
                                onDragStart={ev => ev.preventDefault()}
                            />
                        )
                        : (
                            <AfterParent>
                                <LazyImage
                                    eventHandlers={{ onLoad: this.onLoad }}
                                    containerSelector={this.props.containerSelector}
                                    placeholder={this.props.file}
                                    src={this.props.file.fileVersion.download_url}
                                    hideFakeLazyImage={this.version === 2}
                                    reference={el => {
                                        this.imgElement = el;
                                    }}
                                />
                            </AfterParent>
                        )
                }

                {this.props.children}

                { !!this.img &&
                    <IterableContainer
                        entryType={this.props.pinType}
                        entryName='pin'
                        entries={this.props.pins}
                        keyBuilder={p => `${p.pinType}-${p.id}`}
                        container={this.img}
                        screen={this.props.screen}
                        previewContainer={this.props.previewContainer}
                        extraPinProps={this.props.extraPinProps}
                    />}
            </div>
        );
    }
});

ImageViewer.propTypes = {
    box: PropTypes.object, // Required for v2 only
    file: PropTypes.object,
    pins: PropTypes.array,
    pinType: PropTypes.object,
    screen: PropTypes.object.isRequired,
    previewContainer: PropTypes.object,
    pinContainerRef: PropTypes.func,
    containerSelector: PropTypes.string
};

ImageViewer.defaultProps = {
    containerSelector: '#presentation-screen'
};

ImageViewer.defaultProps = {
    pinType: withPinPoint(DefaultPin, pinPoint),
    pinContainerRef: () => {}
};

export const BaseEditorPinType = withPinPoint(BaseEditorPin, pinPoint);
export const BasePreviewPinType = withPinPoint(DefaultPin, pinPoint);

const EditorImageViewer = inject('dragMovePinManager')(observer(class EditorImageViewer extends React.Component {
    constructor (props) {
        super(props);
        this.registerDroppable();
        this.pinContainerRef = makeRef();
    }

    componentDidMount () {
        this.props.dragMovePinManager.subscribe(
            this.props.dragMovePinManager.LAYERS.BOARD, this
        );
    }

    componentWillUnmount () {
        this.props.dragMovePinManager.unsubscribe(this.props.dragMovePinManager.LAYERS.BOARD);
    }

    componentDidUpdate (prevProps) {
        if (this.props.dragDropMonitor !== prevProps.dragDropMonitor) {
            this.registerDroppable();
        }
    }

    // TODO: Not being able to drag a pin to the end without being super precise can be fixed here
    onDragPinEnd = ({ rect, pin, data }) => {
        const sourceTargetType = data && data.source;
        if (sourceTargetType !== TargetType.MEDIA) return;
        const pinContainer = this.pinContainerRef.current;
        if (isPointInRect(rect, pinContainer.getBoundingClientRect())) {
        // eslint-disable-next-line no-constant-condition
        // if (true) {
            pin.move({
                targetType: TargetType.MEDIA,
                targetId: this.props.file.uuid,
                position: pinPoint.normalized(rect.left, rect.top, pinContainer, this.props.editor.slide.scaleFactor)
            });
            return true;
        }
    };

    registerDroppable () {
        const viewer = this;
        this.droppable = DroppableTypes.BOARD_PINS.register(
            this.props.dragDropMonitor,
            {
                // This gets called instead of onDragPinEnd, because it's an attached event to a DOM element
                onCursorUp (ev) {
                    this.onDropPin(
                        viewer.pinContainerRef.current,
                        pinPoint,
                        (creator, data) => viewer.props.editor.mode.onCursorUp(creator, data, TargetType.MEDIA)
                    );
                },
                onCursorMove (ev) {
                    // Gets cursor coordinates
                    // isCursorOver (document.getElementById('#canvas'))
                    // Can I use onMouseEnter
                    return isCursorOverElement(viewer.pinContainerRef.current, ev, false);
                }
            }
        );
    }

    render () {
        return (
            <ImageViewer
                {...this.props}
                pinType={BaseEditorPinType}
                pinContainerRef={this.pinContainerRef}
            />
        );
    }
}));

EditorImageViewer.propTypes = {
    ...ImageViewer.propTypes,
    dragDropMonitor: PropTypes.object,
    dragMovePinManager: PropTypes.object
};

const MapViewer = observer(class MapViewer extends React.Component {
    img;

    constructor (props) {
        super(props);

        makeObservable(this, {
            img: observable,
            setImg: action
        });
    }

    setImg (imgElement) {
        this.img = imgElement;
    }

    onLoad = () => {
        this.setImg(this.imgElement);
    };

    onDrop = ev => {
        this.props.onDrop && this.props.onDrop(ev, this.img);
    };

    render () {
        const Pin = this.props.pinType;

        return (
            <div
                className='image-dropcontext-wrapper'
                style={{ position: 'relative' }}
            >
                <span className='helper'/>
                <img
                    className='map-img'
                    src={this.props.file.fileVersion.download_url}
                    onLoad={this.onLoad}
                    ref={el => {
                        this.imgElement = el;
                    }}
                />

                {this.props.children}
                {
                    this.img && this.props.pins.map(pin =>
                        <Pin
                            key={pin.id}
                            pin={pin}
                            container={this.img}
                            {...pin}
                            {...this.props}
                        />
                    )
                }
            </div>
        );
    }
});

MapViewer.propTypes = {
    file: PropTypes.object,
    pins: PropTypes.array,
    onDrop: PropTypes.func,
    pinType: PropTypes.object,
    screen: PropTypes.object
};

MapViewer.defaultProps = {
    pinType: withPinPoint(MapViewerPin, pinPoint)
};

const MapEditorViewer = inject('dragMovePinManager')(observer(class MapEditorViewer extends React.Component {
    constructor (props) {
        super(props);
        this.registerDroppable();
        this.pinContainerRef = makeRef();
    }

    componentDidMount () {
        this.props.dragMovePinManager.subscribe(
            this.props.dragMovePinManager.LAYERS.BOARD, this
        );
    }

    componentWillUnmount () {
        this.props.dragMovePinManager.unsubscribe(this.props.dragMovePinManager.LAYERS.BOARD);
    }

    componentDidUpdate (prevProps) {
        if (this.props.dragDropMonitor !== prevProps.dragDropMonitor) {
            this.registerDroppable();
        }
    }

    onDragPinEnd = ({ rect, pin }) => {
        const pinContainer = this.pinContainerRef.current;
        if (isPointInRect(rect, pinContainer.getBoundingClientRect())) {
            pin.move({
                position: pinPoint.normalized(rect.left, rect.top, pinContainer)
            });
            return true;
        }
    };

    handleCreateMapPin = (place, data) => {
        const store = this.props.editor.root;

        store.dialog.open({
            component: SelectCameraPosition,
            context: {
                store,
                place,
                initialCameraRotation: place.props.cameraRotation
            }
        }).result
            .then(cameraRotation => {
                return place.tour.map.addPin({
                    title: place.asset.title,
                    position: data.position,
                    props: {},
                    to_place: place.id
                }).then(pin => {
                    pin.updateCameraRotation(cameraRotation);
                    this.props.onPinCreate(pin);
                });
            });
    };

    registerDroppable () {
        const viewer = this;
        this.droppable = DroppableTypes.MAP_PINS.register(
            this.props.dragDropMonitor,
            {
                onCursorUp (ev) {
                    this.onDropPin(
                        viewer.pinContainerRef.current,
                        pinPoint,
                        viewer.handleCreateMapPin
                    );
                },
                onCursorMove (ev) {
                    return isCursorOverElement(viewer.pinContainerRef.current, ev, false);
                }
            }
        );
    }

    render () {
        const isDragging = this.props.dragDropMonitor.isDragging;
        const MapEditorPinType = withPinPoint(this.props.pinType, pinPoint);

        return (
            <ImageViewer
                {...this.props}
                screen={this.props.editor}
                pinType={MapEditorPinType}
                containerSelector='.image-ctx-wrapper'
                pinContainerRef={this.pinContainerRef}
            >
                {
                    isDragging &&
                    <FileOverlay
                        icon='icon-hand'
                        color='blue'
                        text={gettext('Drop over the highlighted area to create a pin.')}
                    />
                }
            </ImageViewer>
        );
    }
}));

MapEditorViewer.propTypes = {
    ...ImageViewer.propTypes,
    dragDropMonitor: PropTypes.object,
    map: PropTypes.object,
    pinType: PropTypes.func,
    editor: PropTypes.object,
    dragMovePinManager: PropTypes.object,
    onPinCreate: PropTypes.func
};

export {
    SimpleImageViewer,
    ImageViewer as PreviewImageViewer,
    EditorImageViewer,
    MapEditorViewer,
    MapViewer
};

/* eslint-enable */
