import ShapeWithIconModel from '../FileViewers/Tour/PinEditors/Appearance/ShapeWithIcon/model';

export const getPinSize = userDefinedSize => {
    const screen = Settings.device.getScreenInfo().size;

    const sizeFactor = { xs: 3.5, sm: 7, md: 6.5, lg: 8 };
    const minSize = { xs: 10, sm: 15, md: 20, lg: 20 };

    return minSize[screen] + (sizeFactor[screen] * userDefinedSize);
};

export const getImageSize = getPinSize;

export const getBorderWidth = (userDefinedWidth = 0) => {
    const screen = Settings.device.getScreenInfo().size;
    const sizeFactor = { xs: 0.6, sm: 0.7, md: 0.8, lg: 1 };

    return sizeFactor[screen] * userDefinedWidth;
};

export const getTextBorderFactor = () => {
    const screen = Settings.device.getScreenInfo().size;

    const sizeFactor = { xs: 0.2, sm: 0.4, md: 0.8, lg: 1 };

    return sizeFactor[screen];
};

export function getBoundPosition (bounds, node, x, y) {
    if (!bounds) return [x, y];

    const { ownerDocument } = node;
    const ownerWindow = ownerDocument.defaultView;
    const boundNode = (bounds === 'parent')
        ? node.parentNode
        : ownerDocument.querySelector(bounds);
    if (!(boundNode instanceof ownerWindow.HTMLElement)) {
        throw new Error('Bounds selector "' + bounds + '" could not find an element.');
    }
    const nodeRect = node.getBoundingClientRect();
    const boundNodeRect = boundNode.getBoundingClientRect();
    // Compute bounds. Skip margin and padding since they should be 0px
    bounds = {
        left: boundNodeRect.left - nodeRect.left,
        top: boundNodeRect.top - nodeRect.top,
        right: boundNodeRect.left + boundNodeRect.width - nodeRect.width - nodeRect.left,
        bottom: boundNodeRect.top + boundNodeRect.height - nodeRect.height - nodeRect.top
    };

    x = (x > 0)
        ? Math.min(x, bounds.right)
        : Math.max(x, bounds.left);
    y = (y > 0)
        ? Math.min(y, bounds.bottom)
        : Math.max(y, bounds.top);

    return [x, y];
}

/*
    Size calculations happen differently for 2D and 3D pin, the arguments size/border/imageSize
    are the calculated ones, NOT the same as pin.props.size/borderWidth/imageSize
*/
export const calculateHightlightSize = ({ size, borderWidth, imageSize, isShapeNone, isImageNone }) => {
    const shapeSize = isShapeNone ? -1 : (size + borderWidth * 2);
    const _imageSize = isImageNone ? -1 : imageSize;
    const max = Math.max(shapeSize, _imageSize);
    return max === -1
        ? size
        : max;
};

export const calculatePinSize2D = (appearanceModel) => {
    return calculateHightlightSize({
        size: getPinSize(appearanceModel.size),
        borderWidth: getBorderWidth(appearanceModel.borderWidth),
        imageSize: getImageSize(appearanceModel.imageSize),
        isShapeNone: appearanceModel.shape === ShapeWithIconModel.SHAPES.NONE.type,
        isImageNone: appearanceModel.image === ShapeWithIconModel.IMAGES.NONE.type
    });
};
