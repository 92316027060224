import { reaction, computed, runInAction, makeObservable } from 'mobx';

import BaseCommentsStore from '../../comments/store';
import ConfirmDialog from './../Dialog/ConfirmDialog';

import api from '../Comments/api';
import { pubsub } from '../../base';

class CommentsStore extends BaseCommentsStore {
    constructor (root) {
        super(root);

        makeObservable(this, {
            resource: computed,
            resourceComments: computed
        });

        this.api = api;
        this.withAccess = []; // required for Mentions.
        this.GASettings = { action: 'Comments_Presentations' };

        reaction(
            () => this.root.id,
            uuid => {
                this.root.allowComments &&
                this.load();
            }, {
                delay: 1000
            }
        );

        pubsub.subscribe(
            this,
            'presentations.comments.comment.new',
            comment => this.getNewComment(comment)
        );
        pubsub.subscribe(
            this,
            'presentations.comments.reply.new',
            comment => this.getNewReply(comment)
        );
    }

    get resource () {
        return this.root.staticProjector.projector.slide
            ? {
                id: this.root.staticProjector.projector.slide.id,
                presentation: this.root.id,
                exists: true
            }
            : {};
    }

    get resourceComments () {
        return this.comments
            .filter(c => c.resource.object.id === parseInt(this.resource.id));
    }

    load = () => {
        !Settings.offlinePresentation &&
        this.api.loadCommentsForPresentation(this.root.id)
            .then(comments => {
                runInAction(() => {
                    this.comments = comments.map(c => this.create(c));
                });
            });
    };

    /* channel notifications comment/reply methods */
    getNewComment = newComment => {
        const comment = this.getComment(newComment.id);
        (!comment || comment.modDate !== newComment.mod_date) &&
        this.api
            .getComment(this.resource, newComment.id)
            .then(res => this.add(res));
    };

    getNewReply = newComment => {
        this.api
            .getReply(this.resource, newComment.id)
            .then(res => {
                const comment = { ...this.getComment(res.parent) };
                comment.replies = [...comment.replies.filter(c => c.id !== res.id), res];
                this.replaceComment(comment);
            });
    };

    /* methods are required for mentions functionality */
    checkMentionPermissionsAndAlert = () => Promise.resolve(true);
    checkAccess = () => Promise.resolve(true);

    confirmDelete = () => {
        return this.root.dialog.open({
            component: ConfirmDialog,
            context: {
                title: gettext('Delete Confirmation'),
                yesTitle: gettext('Delete'),
                noTitle: gettext('Cancel'),
                text: gettext('Are you sure you want to delete this comment?'),
                submitAnalytics: {
                    'ga-action': 'Comments_Presentations',
                    'ga-label': 'Delete_Comment_Dialog_Continue'
                },
                cancelAnalytics: {
                    'ga-action': 'Comments_Presentations',
                    'ga-label': 'Delete_Comment_Dialog_Cancel'
                }
            }
        }).result;
    };
}

export default CommentsStore;
