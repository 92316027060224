import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Resizable } from 're-resizable';

import { Drawer } from '@vectorworks/vcs-ui/dist/lib/Drawer/Drawer';
import { Dialog } from '@vectorworks/vcs-ui/dist/lib/Dialog/Dialog';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';

import styled from 'styled-components';

import { MapViewer } from '../FileViewers/Image.jsx';
import EmptyMessage from './EmptyMessage';
import { useLocation, useNavigate } from 'react-router-dom';

function calcAspectRatioFit (target, container) {
    const ratio = Math.min(
        container.width / target.width,
        container.height / target.height
    );

    return {
        width: target.width * ratio,
        height: target.height * ratio
    };
}

const S = {
    Drawer: styled(Drawer)`
        .MuiPaper-root {
            position: absolute;
            top: 0;
            border: 1px solid var(--border-color);
            height: auto;
            overflow: hidden;
        }

        .icon-panel-resize {
            bottom: 0;
            right: 0;
            color: var(--text-primary-color);
            position: absolute;
            pointer-events: none;
        }

    `,
    Toolbar: styled(Dialog.Header)`
        #map__action--edit {
            margin-left: auto;
            margin-right: 32px;
        }
    `
};

const MapWidget = ({ store, map, currentPlace, screen, ...props }) => {
    let image = null;

    const ratioFit = map && map.image
        ? calcAspectRatioFit(
            map.image.params.size,
            { width: 300, height: 300 }
        )
        : { width: 300, height: 200 };

    const [minWidth, setMinWidth] = useState(ratioFit.width);
    const [minHeight, setMinHeight] = useState(ratioFit.height);
    const [maxWidth, setMaxWidth] = useState(0);
    const [maxHeight, setMaxHeight] = useState(0);

    const location = useLocation();
    const navigate = useNavigate();

    const updateImageSize = () => {
        const inner = document.querySelector('.map-widget-inner');
        image = document.querySelector('.map-img');

        inner.style.width = `${minWidth}px`;
        inner.style.height = `${minHeight}px`;

        if (image) {
            image.style.maxWidth = `${minWidth}px`;
            image.style.maxHeight = `${minHeight}px`;
        }
    };

    const onOrientationChange = () => {
        if (map && map.image) {
            const ratioFit = calcAspectRatioFit(
                map.image.params.size,
                { width: 300, height: 300 }
            );
            setMinWidth(ratioFit.width);
            setMinHeight(ratioFit.height);
        }
    };

    useEffect(() => updateImageSize(), [minWidth, maxWidth]);

    useEffect(() => {
        const windowWidth = $(window).width();
        setMaxWidth(Settings.device.isMobile ? windowWidth - 50 : windowWidth / 2);
        setMaxHeight($(window).height() * 0.90);

        image = document.querySelector('.map-img');

        const openReactionDisposer = reaction(
            () => store.ui.map,
            map => !map && updateImageSize()
        );

        const tourChangeReactionDisposer = reaction(
            () => map,
            map => {
                if (map && map.image) {
                    const ratioFit = calcAspectRatioFit(
                        map.image.params.size,
                        { width: 300, height: 300 }
                    );
                    setMinWidth(ratioFit.width);
                    setMinHeight(ratioFit.height);
                }
            }
        );

        window.addEventListener('orientationchange', onOrientationChange);

        return () => {
            openReactionDisposer && openReactionDisposer();
            tourChangeReactionDisposer && tourChangeReactionDisposer();
            window.removeEventListener('orientationchange', onOrientationChange);
        };
    }, []);

    const onResize = (event, direction, refToElement) => {
        if (!image) {
            return;
        }

        image.style.maxWidth = refToElement.clientWidth - 2 + 'px';
        image.style.maxHeight = refToElement.clientHeight - 2 + 'px';
    };

    const toggle = () => store.ui.toggle('map');

    const edit = () => {
        store.ui.toggle('carousel', true);
        store.ui.inMapEditor = true;
        navigate(`${location.pathname}map/`);
    };

    const isOpen = store.ui.map;

    return (
        <S.Drawer
            open={isOpen}
            anchor='left'
            variant='persistent'
        >
            <S.Toolbar title={gettext('Tour map')} onClose={toggle}>
                {
                    props.isEdit && (
                        <IconButton
                            onClick={edit}
                            id='map__action--edit'
                            title={gettext('Edit')}
                        >
                            <Icon icon='edit' />
                        </IconButton>
                    )
                }
            </S.Toolbar>
            <Resizable
                className='map-widget-inner'
                defaultSize={{
                    width: minWidth,
                    height: minHeight
                }}
                minWidth={minWidth}
                minHeight={minHeight}
                maxWidth={maxWidth}
                maxHeight={maxHeight}
                onResize={onResize}
                lockAspectRatio={true}
            >
                {
                    map
                        ? (
                            <MapViewer
                                file={map.image}
                                currentPlace={currentPlace}
                                screen={screen}
                                pins={map.pins}
                            />
                        )
                        : (
                            <EmptyMessage
                                secondaryText={ gettext('Add an image that resembles a map of the space that your panoramic images represent and then create pins to locate them on the map.') }
                                onClick={edit}
                            />
                        )
                }
                <span className='icon icon-panel-resize'/>
            </Resizable>
        </S.Drawer>
    );
};

MapWidget.propTypes = {
    map: PropTypes.object,
    isEdit: PropTypes.bool,
    currentPlace: PropTypes.object,
    store: PropTypes.object,
    screen: PropTypes.shape({
        slide: PropTypes.object
    })
};

export default observer(MapWidget);
