import React from 'react';
import PropTypes from 'prop-types';

const SelectionCounter = ({ collectionStore, cssClass = '' }) => {
    return (
        <div className={ 'tb__selection-counter ' + cssClass }>
            <span
                className='tool'
                style={{ fontSize: 24 }}
            >{ '(' + collectionStore.selection.items.length + ')'}
            </span>
            <a
                href='#'
                className='tool icon-close'
                onClick={ collectionStore.exitMobileSelectionMode }
            />
        </div>
    );
};

SelectionCounter.propTypes = {
    collectionStore: PropTypes.any,
    cssClass: PropTypes.string
};

export default SelectionCounter;
