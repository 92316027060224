import screenfull from 'screenfull/dist/screenfull';

const eventNameMap = {
    change: 'fullscreen-change',
    error: 'fullscreen-error'
};

const fullScreen = {
    fake: true,
    enabled: true,
    isFullscreen: false,
    element: null,
    request (element) {
        element = element || document.documentElement;
        this.element = element;
        $(element).addClass('full-screen');
        this.isFullscreen = true;
        $(document).trigger(eventNameMap.change);
    },
    exit () {
        $(this.element).removeClass('full-screen');
        this.element = null;
        this.isFullscreen = false;
        $(document).trigger(eventNameMap.change);
    },
    toggle (element) {
        if (this.isFullscreen) {
            this.exit();
        } else {
            this.request(element);
        }
    },
    onchange (callback) {
        this.on('change', callback);
    },
    onerror (callback) {
        this.on('error', callback);
    },
    on (event, callback) {
        const eventName = eventNameMap[event];
        eventName && $(document).on(eventNameMap[event], callback, false);
    },
    off (event, callback) {
        const eventName = eventNameMap[event];
        eventName && $(document).off(eventNameMap[event], callback, false);
    }
};

export default (screenfull && screenfull.enabled)
    ? screenfull
    : fullScreen;
