import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import moment from 'moment';

import toolbar from '../../view/action-toolbar.vm';
import fullScreen from '../../base/fullscreen';
import PanoramaQualitySettingDialog from '../Dialog/PanoramaQualitySettingDialog';
import Slide from './Slide';
import storage from '../utils/storage';
import QRCodeDialog from '../../share/QRCode';
import ShowInNomad, { requestDeepLink } from '../../nomad/ShowInNomad';

window.moment = moment;
const SHOW_IN_NOMAD_FOR_PRESENTATIONS = false;

const POWERED_BY_URL = Settings.offlinePresentation
    ? 'https://cloud.vectorworks.net/'
    : window.location.origin;

const Presentation = observer(({ store, preview, ...props }) => {
    const oldToolbar = toolbar.view();
    const [deepLinkUrl, setDeepLinkUrl] = useState(null);
    const [showInNomadTargetNode, setShowInNomadTargetNode] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const startPresentation = () => {
        const firstSlide = store.slide.slides[0];
        const url = location.pathname;

        navigate(`/${store.id}/${firstSlide.id}/` + `?next=${url}`);
        const preview = document.getElementById('presentation-fs-preview');
        store.setFullscreen(true);
        fullScreen.toggle(preview);
    };

    const handleFullscreenChange = () => store.setFullscreen(fullScreen.isFullscreen);

    const openSettings = () => {
        store.dialog.open({
            component: PanoramaQualitySettingDialog,
            context: {}
        });
    };

    const openQRDialog = () => {
        store.dialog.open({
            component: QRCodeDialog,
            context: {}
        });
    };

    const openDeepLink = () => {
        window.location.href = deepLinkUrl;
    };

    const handleShowInNomadTarget = node => setShowInNomadTargetNode(node);

    useEffect(() => {
        const label = storage.get('preferences.presentation.tour.quality') || store.versionLabel;
        store.updateVersionLabel(label);

        fullScreen.on('change', handleFullscreenChange);

        const view = {
            items: {
                isListView: () => false,
                selection: () => false
            },
            toolbarActions: {
                openSettings: {
                    action: openSettings,
                    properties: {
                        isAllowed: true,
                        title: gettext('Settings'),
                        icon: 'icon-settings',
                        isPublic: true
                    }
                }
            }
        };
        toolbar.view(view);

        SHOW_IN_NOMAD_FOR_PRESENTATIONS && !Settings.offlinePresentation &&
            requestDeepLink().then(({ shortLink }) => {
                setDeepLinkUrl(shortLink);
            });

        return () => {
            toolbar.view(oldToolbar);
            store.setFullscreen(false);
            fullScreen.off('change', handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        if (store.slide.slides.length > 0) {
            store.slide.slides[0].preloadImages?.();
        }
    }, []);

    const root = preview.root;
    const owner = `${root.owner.first_name} ${root.owner.last_name}`;
    const hasSlides = store.slide.slides.length > 0;

    return (
        <div className='presentation-content'>
            <div className='presentation-page-main'>
                {
                    !Settings.offlinePresentation &&
                    <React.Fragment>
                        {
                            SHOW_IN_NOMAD_FOR_PRESENTATIONS &&
                        Settings.device.isMobile &&

                        <React.Fragment>
                            {
                                deepLinkUrl &&
                                showInNomadTargetNode &&
                                <ShowInNomad
                                    node={showInNomadTargetNode}
                                    link={deepLinkUrl}
                                />
                            }
                            <a
                                ref={handleShowInNomadTarget}
                                className='tool icon-home presentation-tool-nomad'
                                onClick={openDeepLink}
                                title={gettext('Show in Nomad')}
                                which='show-in-nomad'
                                ga-action='Presentation_Toolbar'
                                ga-label='Click_ShowInNomad'
                            />
                        </React.Fragment>
                        }
                        <a
                            className='tool icon-settings presentation-settings'
                            title={gettext('Settings')}
                            onClick={openSettings}
                            data-what='glob-tool'
                            data-which='settings'
                            ga-action='Presentation_Toolbar'
                            ga-label='Click_Settings'
                        />
                        <a
                            className='tool icon-qr-code presentation-tool-qr'
                            onClick={openQRDialog}
                        />
                    </React.Fragment>
                }

                <div className='title-author'>
                    <div className='title'>{root.title}</div>
                    <div className='author'>{gettext('Created by')} {owner}</div>
                    <div className='powered-by'>
                        <a href={POWERED_BY_URL} target='_blank' rel='noreferrer'>
                            {gettext('Powered by Vectorworks Cloud')}
                        </a>
                    </div>
                </div>

                {
                    hasSlides &&
                    <React.Fragment>
                        <span
                            className='icon icon-run-presentation'
                            title={gettext('Slideshow')}
                            onClick={startPresentation}
                            data-what='glob-tool'
                            data-which='presentation-preview'
                            ga-action='Presentation_Toolbar'
                            ga-label='Click_Presentation_Preview'
                        />
                        <span
                            className='start-text'
                            onClick={startPresentation}
                            data-what='glob-tool'
                            data-which='presentation-preview'
                            ga-action='Presentation_Toolbar'
                            ga-label='Click_Presentation_Preview_Text'
                        >{ gettext('Start') }
                        </span>
                    </React.Fragment>
                }
            </div>
            <div id='assets-wrapper'>
                <div className='container-fluid' style={{ paddingTop: '15px' }}>
                    {
                        store.slide.slides.map(slide =>
                            <Slide
                                {...slide}
                                key={slide.id}
                                slide={slide}
                                store={store}
                            />)
                    }
                </div>
            </div>
        </div>
    );
});

Presentation.propTypes = {
    store: PropTypes.object,
    projector: PropTypes.object,
    preview: PropTypes.object
};

const Preview = props => (
    <Presentation
        preview={props.preview}
        projector={props.projector}
        store={props.store}
    />
);

Preview.propTypes = {
    store: PropTypes.object.isRequired,
    preview: PropTypes.object,
    projector: PropTypes.shape({
        slide: PropTypes.object
    }).isRequired
};

export default observer(Preview);
