import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

const ThumbnailViewer = props =>
    <div className='image-dropcontext-wrapper'>
        <span className='helper' />
        {
            props.file.fileVersion
                ? <img src={props.file.fileVersion.thumbnail}/>
                : <span>{gettext('Error occurred') }</span>
        }
    </div>;

ThumbnailViewer.propTypes = {
    file: PropTypes.object
};

export default observer(ThumbnailViewer);
