import moment from 'moment';

function delay (timeout) {
    const deffered = $.Deferred();
    setTimeout(function () {
        deffered.resolve();
    }, timeout);
    return deffered.promise();
}

function forEachWithDelay (seq, timeout, callback) {
    const [item, ...rest] = seq;
    if (item === undefined) {
        return;
    }
    callback(item);
    delay(timeout).then(() => forEachWithDelay(rest, timeout, callback));
}

function arrayToObject (data) {
    const result = {};
    $.each(data, function () {
        result[this.name] = this.value;
    });
    return result;
}

function arraysEqual (a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    a.sort();
    b.sort();

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

function formatDate (dateString) {
    return dateString
        ? moment.utc(dateString).local().format(Settings.DATE_FORMAT)
        : '';
}

function formatDateTime (dateString) {
    return dateString
        ? moment.utc(dateString).local().format(Settings.DATE_TIME_FORMAT)
        : '';
}

function formatDuration (start, end) {
    if (!(start && end) || start === end || moment(end).year() === 1970 || moment(start).year() === 1970) {
        return `0 ${pgettext('duration', 'seconds')}`;
    }

    const td = moment.duration(moment(end).diff(start));
    const s = td.seconds();
    const m = td.minutes();
    const h = td.hours();
    const d = td.days();

    const days = d < 1
        ? ''
        : d > 1
            ? `${d} ${pgettext('duration', 'days')}`
            : `${d} ${pgettext('duration', 'day')}`;

    const hours = h < 1
        ? ''
        : h > 1
            ? `${h} ${pgettext('duration', 'hours')}`
            : `${h} ${pgettext('duration', 'hour')}`;

    const minutes = m < 1
        ? ''
        : m > 1
            ? `${m} ${pgettext('duration', 'minutes')}`
            : `${m} ${pgettext('duration', 'minute')}`;

    const seconds = s < 1
        ? ''
        : s > 1
            ? `${s} ${gettext('seconds')}`
            : `${s} ${gettext('second')}`;

    return `${days} ${hours} ${minutes} ${seconds}`.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '');
}

function isObject (item) {
    return (item && typeof item === 'object' && !Array.isArray(item) && item !== null);
}

function mergeDeepTwo (target, source) {
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, { [key]: {} });
                }
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        });
    }
    return target;
}

function mergeDeep (target, ...sources) {
    let src;
    for (src of sources) {
        mergeDeepTwo(target, src);
    }
    return target;
}

function setIntersection (xs, ys) {
    const intersection = new Set();
    for (const elem of ys) {
        if (xs.has(elem)) {
            intersection.add(elem);
        }
    }
    return intersection;
}

function setDifference (setA, setB) {
    const _difference = new Set(setA);
    for (const elem of setB) {
        _difference.delete(elem);
    }
    return _difference;
}

function copyObject (obj) {
    const copy = Object.create(obj.constructor.prototype);
    Object.assign(copy, obj);
    return copy;
}

function capitalize (s) {
    return s && s[0].toUpperCase() + s.slice(1);
}

function stringToBool (s) {
    if (typeof s === 'boolean') {
        return s;
    } else if (typeof s === 'string') {
        switch (s.toLowerCase().trim()) {
        case 'true': case 'yes': case '1': return true;
        case 'false': case 'no': case '0': case null: return false;
        default: return Boolean(s);
        }
    }
    return s;
}

const comparators = {
    text (x, y, desc = false) {
        const result = x.toLowerCase() < y.toLowerCase() ? -1 : x.toLowerCase() > y.toLowerCase() ? 1 : x < y ? -1 : x > y ? 1 : 0;
        return desc ? -result : result;
    },
    number (x, y, desc = false) {
        const result = x === y ? 0 : (x < y ? -1 : 1);
        return desc ? -result : result;
    },
    date (x, y, desc = false) {
        const result = x === y ? 0 : (x < y ? -1 : 1);
        return desc ? -result : result;
    }
};

const isEmptyObject = object => {
    return Object.keys(object).every(x => {
        return object[x] === '' || object[x] === null;
    });
};

const hasEmptyValue = object => {
    return Object.keys(object).some(x => {
        return object[x] === '' || object[x] === null;
    });
};

function generateGuid () {
    const S4 = () =>
        (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4());
}

function template (strings, ...keys) {
    return function (...values) {
        const dict = values[values.length - 1] || {};
        const result = [strings[0]];
        keys.forEach(function (key, i) {
            const value = Number.isInteger(key) ? values[key] : dict[key];
            result.push(value, strings[i + 1]);
        });
        return result.join('');
    };
}

const hashCode = str => {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
        hash = ((hash << 5) - hash) + str.charCodeAt(i);
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

function createTimer (timeout, handler) {
    return {
        start () {
            this.timer = setTimeout(handler, timeout);
        },
        stop () {
            this.timer && clearTimeout(this.timer);
            this.timer = null;
        },
        reset () {
            this.stop();
            this.start();
        },
        destroy () {
            this.stop();
        }
    };
}

function waitForNode (getNode) {
    return new Promise(function (resolve, reject) {
        const retry = 0;
        const renderer = setInterval(() => {
            const node = getNode();
            if (node) {
                clearInterval(renderer);
                resolve(node);
            } else {
                if (retry > 50) {
                    clearInterval(renderer);
                    reject();
                }
            }
        }, 100);
    });
}

function navigateToURL (url) {
    window.location.href = url;
}

export {
    delay,
    forEachWithDelay,
    arrayToObject,
    arraysEqual,
    formatDate,
    formatDateTime,
    formatDuration,
    mergeDeep,
    setIntersection,
    setDifference,
    copyObject,
    capitalize,
    stringToBool,
    comparators,
    isEmptyObject,
    hasEmptyValue,
    generateGuid,
    template,
    hashCode,
    createTimer,
    waitForNode,
    navigateToURL
};
