import compileCode from './compiler';

function parseProps (props) {
    return props.split(';').map(prop => {
        let [componentProp, expr] = prop.trim().split(/:(.*)/s).map(s => s.trim());
        if (!expr) {
            [componentProp, expr] = [null, componentProp];
        }
        return {
            prop: componentProp,
            expr: compileCode(expr),
            eval (sandbox) {
                return this.prop
                    ? { [this.prop]: this.expr(sandbox) }
                    : this.expr(sandbox);
            }
        };
    });
}

function parseForProps (props) {
    const as = props.lastIndexOf('as');
    const prop = props.substr(0, as).trim();
    const name = props.substr(as + 2).trim();
    return { name, prop };
}

function elementListRenderer (root, elementFactory) {
    const idMap = new Map();
    return {
        add (items) {
            for (const item of items) {
                const el = elementFactory(item);
                idMap.set(item.id, el.id);
                root.appendChild(el);
            }
        },
        remove (items) {
            for (const item of items) {
                const el = root.querySelector(`#${idMap.get(item.id)}`);
                root.removeChild(el);
            }
        },
        removeAll () {
            while (root.firstChild) {
                root.removeChild(root.firstChild);
            }
        }
    };
}

function createTemplate (template) {
    return {
        render (item, env) {
            const text = Object.keys(env).reduce((acc, key) => {
                return acc.replace(new RegExp(`\\$${key}`, 'g'), env[key]);
            }, template);
            return htmlToElement(text);
        }
    };
}

function htmlToElement (html) {
    const el = document.createElement('div');
    el.innerHTML = html.trim();
    return el.firstChild;
}

export {
    parseProps,
    parseForProps,
    elementListRenderer,
    createTemplate,
    htmlToElement
};
