import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { pubsub } from '../../../../base';
import withResponsiveListeners from '../../../../utils/Responsive';

import S from './styles';

const Entity = observer(
    ({
        item,
        screen,
        header,
        collapse,
        children
    }) => {
        const isMobile = React.useMemo(() => !['md', 'lg'].includes(screen.size), [screen]);
        const [open, setOpen] = React.useState(false);
        const toggleOpen = () => setOpen(!open);
        const [selected, setSelected] = React.useState(false);
        const toggleSelected = () => setSelected(!selected);
        const ref = React.useRef(null);
        const isHighlighted = () => (isMobile && open) || (!isMobile && selected);

        React.useEffect(() => {
            pubsub.subscribe(Entity, 'fv.ccad.item.nav', ({ sourceItem, nav }) => {
                if (item.isMe(sourceItem, nav.cells, nav.extraContidion)) {
                    setOpen(true);
                    if (nav.socket) {
                        // the heading socket is an <Entity/>, not a <Socket/>, and doesn't listen for fv.ccad.socket.nav
                        if (item.isMe(sourceItem, nav.socket)) {
                            ref.current && ref.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
                            setSelected(true);
                        } else {
                            setTimeout(pubsub.publish('fv.ccad.socket.nav', ({ sourceItem, nav })));
                        }
                    } else {
                        ref.current && ref.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
                        setSelected(true);
                    }
                }
            });
            return () => pubsub.unsubscribeAll(Entity);
        }, []);

        return (
            <React.Fragment>
                <S.trToggler
                    ref={ref}
                    className={`${screen.size} ${isHighlighted() ? 'item-selected-light' : null}`}
                    onClick={toggleSelected}
                    expand={open}
                    identation={0}
                >
                    { header(open, toggleOpen) }
                    <S.Collapse in={open}>
                        { open && collapse }
                    </S.Collapse>
                </S.trToggler>
                { open && children }
            </React.Fragment>
        );
    }
);

Entity.propTypes = {
    item: PropTypes.object,
    identation: PropTypes.number,
    skipColumns: PropTypes.array,
    store: PropTypes.object,
    onClick: PropTypes.func,
    screen: PropTypes.object
};

export default withResponsiveListeners(Entity);
