import loadScript from '../lib/load-script';
import { FileGroupIterator, fileResourceId } from './file';
import '../dialog/react-dialog';
import '../toast/react-toasts';

class FilePreviewViewModel {
    resolve = result => result;

    open (fileResource, group = null, options = {}) {
        return this.loadFilePreviewModule()
            .then(() => {
                return window.FilePreview.open(this, fileResource, group, options);
            })
            .then(() => new Promise((resolve, reject) => {
                this.resolve = resolve;
            }));
    }

    loadFilePreviewModule () {
        return window.FilePreview
            ? Promise.resolve(window.FilePreview)
            : loadScript(`${Settings.ASSETS.js.react}`, 'react-script')
                .then(() => loadScript(`${Settings.ASSETS.js['file-preview']}`, 'file-preview-script'))
                .then(() => window.FilePreview);
    }

    openFromAsset (asset, group, options = {}) {
        const fileResource = fileResourceId.fromAsset(asset);
        return this.open(fileResource, FileGroupIterator.fromAssets(group, asset), options);
    }

    openFromParams (params, options = {}) {
        const fileResource = fileResourceId.fromParams(params);
        return this.open(fileResource, null, options);
    }

    openFromUrl (options = {}) {
        const fileResource = fileResourceId.fromUrl();
        if (fileResource) {
            this.opendFromUrl = true;
            return {
                willOpen: true,
                promise: this.open(fileResource, null, options)
            };
        } else {
            return {
                willOpen: false,
                promise: Promise.resolve(null)
            };
        }
    }

    close () {
        this.resolve(true);
    }
}

const filePreview = new FilePreviewViewModel();

!ko.components.isRegistered('file-preview') &&
ko.components.register('file-preview', {
    viewModel: { instance: filePreview },
    template: { element: 'file-preview' }
});

export {
    filePreview,
    fileResourceId
};
