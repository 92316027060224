const PRESENTATION_ALLOWED_FILE_TYPES = [
    'image', 'panorama', 'pdf', 'video', 'vgx'
];

const ASSET_JOB_ACTIONS = {
    NONE: 'none',
    EXPLODE_PDF: 'explode_pdf',
    ADD_RESULTS_TO_BOARD: 'add_results_to_board',
    REPLACE_BOARD_MEDIA: 'replace_board_media',
    REPLACE_MAP_IMAGE: 'replace_map_image',
    CREATE_MAP: 'create_map',
    ADD_TO_BOARD: 'add_to_board',
    ADD_TO_TOUR: 'add_to_tour'
};

const GROUP_ASSET_JOB_ACTIONS = {
    NONE: 'none',
    MAKE_SLIDE: 'make_slide'
};

export {
    PRESENTATION_ALLOWED_FILE_TYPES,
    ASSET_JOB_ACTIONS,
    GROUP_ASSET_JOB_ACTIONS
};
