import React from 'react';

import Icon from '../../Components/Icon';

const CouldNotOpenVersion = () => (
    <div className='no-preview'>
        <div className='message-box flex-col'>
            <Icon icon='icon-view'/>
            <div className='message'>
                <span className='msg-row-prim'>{ gettext('File not found') }</span>
                <span>{ gettext('Sorry, no preview is available for this file. Use Dropbox to view prior versions of files stored in Dropbox.') }</span>
            </div>
        </div>
    </div>);

export default CouldNotOpenVersion;
