function deg2Rad (angle) {
    return (angle * Math.PI) / 180;
}

function rad2Deg (angle) {
    return (angle * 180) / Math.PI;
}

function normalizeAngle (angle) {
    const normalized = angle % (2 * Math.PI);
    return normalized > Math.PI
        ? normalized - 2 * Math.PI
        : normalized < -Math.PI
            ? normalized + 2 * Math.PI
            : normalized;
}

function positiveAngle (angle) {
    angle = normalizeAngle(angle);
    return angle < 0
        ? 2 * Math.PI + angle
        : angle;
}

function shortestAngle (first, second) {
    first = positiveAngle(first);
    second = positiveAngle(second);
    const rawDiff = first > second ? first - second : second - first;
    const modDiff = rawDiff % (2 * Math.PI);
    const distance = modDiff > Math.PI ? 2 * Math.PI - modDiff : modDiff;
    const direction = (second > first ? 1 : -1) * (modDiff > Math.PI ? -1 : 1);
    return { distance, direction };
}

class Rotation {
    static fromDeg (rotation) {
        return new Rotation({
            x: deg2Rad(rotation.x || 0),
            y: deg2Rad(rotation.y || 0),
            z: deg2Rad(rotation.z || 0)
        });
    }

    constructor ({ x = 0, y = 0, z = 0 }) {
        this.x = normalizeAngle(x);
        this.y = normalizeAngle(y);
        this.z = normalizeAngle(z);
    }

    add (other) {
        other = new Rotation(other);
        return new Rotation({
            x: this.x + other.x,
            y: this.y + other.y,
            z: this.z + other.z
        });
    }

    mul (scalar) {
        return new Rotation({
            x: this.x * scalar,
            y: this.y * scalar,
            z: this.z * scalar
        });
    }

    stringify (units = 'deg') {
        const convert = units === 'deg' ? rad2Deg : v => v;
        return `${convert(this.x)} ${convert(this.y)} ${convert(this.z)}`;
    }

    toObject () {
        return {
            x: this.x,
            y: this.y,
            z: this.z
        };
    }
}

function pos2RotDelta (cameraPos, cameraDir, panoramaPos, hotspotPos) {
    const chv = cameraDir.clone();
    const cvv = chv.clone();

    const vptc = panoramaPos.clone().sub(cameraPos.clone());

    const hv = hotspotPos.clone();
    // Scale effect
    hv.x *= -1;
    hv.add(vptc).normalize();
    const vv = hv.clone();

    chv.y = 0;
    hv.y = 0;

    let ha = Math.atan2(hv.z, hv.x) - Math.atan2(chv.z, chv.x);
    ha = ha > Math.PI ? ha - 2 * Math.PI : ha;
    ha = ha < -Math.PI ? ha + 2 * Math.PI : ha;
    let va = Math.abs(cvv.angleTo(chv) + (cvv.y * vv.y <= 0 ? vv.angleTo(hv) : -vv.angleTo(hv)));
    va *= vv.y > cvv.y ? 1 : -1;
    return [ha, va];
}

function pos2Rot (pos) {
    const [y, p] = pos2RotDelta(
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(0, 0, -1),
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(pos.x, pos.y, pos.z)
    );
    return { x: p, y, z: 0 };
}

export {
    deg2Rad,
    rad2Deg,
    normalizeAngle,
    positiveAngle,
    shortestAngle,
    Rotation,
    pos2Rot
};
