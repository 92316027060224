import React from 'react';
import PropTypes from 'prop-types';

import S from './styles';
import Cell from './Cell';
import Circuit from './Circuit';
import DeviceSocket from './DeviceSocket';
import Equipment from './Equipment';
import Location from './Location';
import ViewerContext from './ViewerContext';
import { pubsub } from '../../../../base';

const viewMap = {
    circuits: Circuit,
    equipment: Equipment,
    devices: DeviceSocket,
    locations: Location
};

const LgCollapsableRow = ({ row, identation, onClick, expand }) => {
    const { store } = React.useContext(ViewerContext);

    return (
        <S.LgCollapsableRow count={store.columns.active.length}>
            {
                store.columns.collapsable.map(
                    (col, i) => i === identation
                        ? <S.Toggler
                            className='cllps-toggle'
                            key={`toggler-${row.column}-${row.value}`}
                            expand={expand}
                            onClick={onClick}
                            setHeight={true} // fix a bug in Safari (VCS-31084)
                            data-what='collapsable-toggler'
                            data-which={`${col.id}-${row.value}`}
                        >
                            { row.value }
                        </S.Toggler>
                        : <Cell
                            key={`empty-cell-${row.column}-${i}`}
                            item={row}
                            cell={null}
                        />
                )
            }
        </S.LgCollapsableRow>
    );
};

LgCollapsableRow.propTypes = {
    row: PropTypes.object,
    identation: PropTypes.number,
    onClick: PropTypes.func,
    expand: PropTypes.bool
};

const MobileCollapsableRow = ({ row, identation, onClick, expand }) => {
    const { store } = React.useContext(ViewerContext);
    return (
        <S.MobileCollapsableRow identation={identation}>
            <S.Toggler
                className='cllps-toggle'
                expand={expand}
                onClick={onClick}
                data-what='collapsable-toggler'
                data-which={`${store.columns.collapsable[identation].id}-${row.value}`}
            >
                { row.value }
            </S.Toggler>
        </S.MobileCollapsableRow>
    );
};

MobileCollapsableRow.propTypes = {
    row: PropTypes.object,
    identation: PropTypes.number,
    onClick: PropTypes.func,
    expand: PropTypes.bool
};

const CollapsableRow = ({ row, identation }) => {
    const { store, isMobile } = React.useContext(ViewerContext);
    const [open, setOpen] = React.useState(true);
    const [willScrollTo, setWillScrollTo] = React.useState(false);
    const Component = isMobile ? MobileCollapsableRow : LgCollapsableRow;
    const NonCollapsable = viewMap[store.table];
    const ref = React.useRef(null);

    React.useEffect(() => {
        pubsub.subscribe(CollapsableRow, 'fv.ccad.collapsable.select.row', ({ column, value }) => {
            if (row?.column === column && row?.value === value) {
                setOpen(true);
                setWillScrollTo(true);
            } else {
                setOpen(false);
            }
        });

        return () => pubsub.unsubscribeAll(CollapsableRow);
    }, []);

    React.useEffect(() => {
        if (willScrollTo && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
            setWillScrollTo(false);
        }
    }, [ref?.current, willScrollTo]);

    const toggleOpen = () => {
        setOpen(!open);
    };

    return (
        <React.Fragment>
            {/* div used for scrolling only */}
            <div ref={ref} />
            <Component
                onClick={toggleOpen}
                expand={open}
                row={row}
                identation={identation}
            >
                <S.Toggler
                    className='cllps-toggle'
                    expand={open}
                    onClick={toggleOpen}
                    key={`collapsable-toggler-${row.column}-${row.value}`}
                />
                { row.value }
            </Component>
            {
                open && row.contents.map(c =>
                    c.column && store.columns.collapsable.map(cC => cC.id).includes(c.column)
                        ? <CollapsableRow
                            key={`collapsable-${c.column}-${c.value}`}
                            row={c}
                            identation={identation + 1}
                        />
                        : <NonCollapsable
                            key={c.deviceID ? `collapsed-${c.id}-${c.deviceID}` : `collapsed-${c.id}`}
                            item={c}
                            skipColumns={store.columns.collapsable}
                            identation={identation + 1}
                            selected={false}
                        />
                )
            }
        </React.Fragment>
    );
};

CollapsableRow.propTypes = {
    row: PropTypes.object,
    identation: PropTypes.number
};

export { MobileCollapsableRow };
export default CollapsableRow;
