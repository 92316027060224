import React from 'react';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';

import S from './styles';

const Trigger = ({ children, ...rest }) =>
    <S.Trigger {...rest}>
        { children }
        <Icon icon='arrow-down' style={{ fontSize: 7, marginLeft: 5 }}/>
    </S.Trigger>;

export default Trigger;
