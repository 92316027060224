function partition (promises) {
    const resolved = [];
    const rejected = [];

    if (!promises.length) {
        return Promise.resolve([[], []]);
    }

    return new Promise((resolve, reject) => {
        promises.forEach(obj => {
            const { data, context } = ContextWrapper.unwrap(obj);
            const promise = data;
            promise.then((result) => {
                resolved.push(ContextWrapper.wrapIfContext(result, context));
                resolveIfFinished();
            }, (reason) => {
                rejected.push(ContextWrapper.wrapIfContext(reason, context));
                resolveIfFinished();
            });
        });

        function resolveIfFinished () {
            if (resolved.length + rejected.length === promises.length) {
                resolve([resolved, rejected]);
            }
        }
    });
}

class ContextWrapper {
    static unwrap (obj) {
        return (obj instanceof ContextWrapper)
            ? obj.unwrap()
            : { data: obj, context: null };
    }

    static wrapIfContext (obj, context) {
        return context ? new ContextWrapper(obj, context) : obj;
    }

    constructor (data, context) {
        this.data = data;
        this.context = context;
    }

    unwrap () {
        return {
            data: this.data,
            context: this.context
        };
    }
}

function mapWithContext (items, action, ...options) {
    return items.map(item => new ContextWrapper(action(item, ...options), item));
}

function promisifyEvent (emitter, event) {
    return new Promise(function (resolve, reject) {
        const listener = (...args) => {
            emitter.removeEventListener(event, listener);
            resolve(...args);
        };
        emitter.addEventListener(event, listener);
    });
}

export {
    partition,
    mapWithContext,
    promisifyEvent
};
