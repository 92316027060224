const WINDOWS_RESERVED_NAMES = [
    'CON', 'PRN', 'AUX', 'NUL',
    'COM1', 'COM2', 'COM3', 'COM4', 'COM5', 'COM6', 'COM7', 'COM8', 'COM9',
    'LPT1', 'LPT2', 'LPT3', 'LPT4', 'LPT5', 'LPT6', 'LPT7', 'LPT8', 'LPT9'
];

const validators = {
    /* eslint-disable */
    email: text => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(text),
    fileName: text => /^[^ \\?<>:*|%#&"~\/][^\\?<>:*|%#&"~\/]*$/.test(text),
    /* eslint-enable */
    trailingSpace: text => !/ +$/.test(text),
    trailingDot: text => !/\.+$/.test(text),
    folderName: text => validators.fileName(text) &&
        validators.trailingSpace(text) &&
        validators.trailingDot(text),
    invalidName: text => !WINDOWS_RESERVED_NAMES.includes(text)
};

export default validators;
