import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AssetPin as BoardAssetPin } from '../../models/board';
import { AssetPin as TourAssetPin } from '../../models/tour';
import { Thumbnail, InfoSection } from '../../Carousel/Items/common';

const S = {
    Wrapper: styled.div`
        width: 140px;
        height: 140px;

        position: relative;
    `
};

const Asset = ({ pin }) => {
    const asset = pin.asset;

    return (
        <S.Wrapper>
            {
                asset.fileVersion && asset.fileVersion.thumbnail &&
                <Thumbnail thumbnail={asset.fileVersion.thumbnail} aspectRatio={1 / 1}/>
            }
            <InfoSection>
                <div>{ pin.title }</div>
            </InfoSection>
        </S.Wrapper>
    );
};

Asset.propTypes = {
    pin: PropTypes.oneOfType([BoardAssetPin, TourAssetPin])
};

export default Asset;
