import React from 'react';
import { observer } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';

/* eslint-disable react/prop-types */

function withLoadDependency (loadDependency) {
    return function (Component) {
        const Wrapper = observer(class Wrapper extends React.Component {
            loadingState = 'pending';

            constructor (props) {
                super(props);

                makeObservable(this, {
                    loadingState: observable,
                    setLoadingState: action
                });
            }

            setLoadingState (state) {
                this.loadingState = state;
            }

            componentDidMount () {
                loadDependency()
                    .then(() => {
                        this.setLoadingState('ready');
                    })
                    .catch(() => {
                        this.setLoadingState('error');
                    });
            }

            render () {
                return this.loadingState === 'pending'
                    ? <div
                        className='line-loading' style={{
                            top: '-1px'
                        }}
                    />
                    : this.loadingState === 'error'
                        ? <div>Ooops...</div>
                        : this.loadingState === 'ready'
                            ? <Component {...this.props}/>
                            : null;
            }
        });

        Wrapper.displayName = `withLoadDependency(${Component.displayName || Component.name})`;
        return Wrapper;
    };
}

export {
    withLoadDependency
};

/* eslint-enable */
