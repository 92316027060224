import React from 'react';
import axios from 'axios';
import { observer } from 'mobx-react';
import initSqlJs from 'sql.js';
import sqlWasm from '!!file-loader?name=sql-wasm-[contenthash].wasm!sql.js/dist/sql-wasm.wasm';  // eslint-disable-line

import { commonPropTypes } from '../../utils';
import { queryString } from '../../../../lib';
import Store from './Store';
import { pubsub } from '../../../../base';

import ConnectCADFilters from './Filters';
import Table from './Table';
import SettingsDialog from './SettingsDialog';
import ViewerContext from './ViewerContext';

class ConnectCADViewer extends React.Component {
    name = 'ConnectCADViewer';

    store = new Store();
    state = {
        screen: Settings.device.getScreenInfo()
    };

    loadDb = async () => {
        const resolvedUrl = await Promise.resolve(
            $.getJSON(
                queryString.buildUrl(
                    this.props.store.file.file.downloadUrl,
                    {
                        web: 'on',
                        download: 'on',
                        response_type: 'data',
                        viewable: 'off'
                    }
                )
            )
        );

        return axios.get(resolvedUrl.url, { responseType: 'arraybuffer' });
    };

    openSettingsDialog = () => {
        const { unitsStore } = this.store;
        window.ReactDialogs.open({
            component: SettingsDialog,
            store: this.store
        })
            .result
            .then(columns => unitsStore.setUnits(columns))
            .catch(() => null);
    };

    async componentDidMount () {
        const loadedDb = await this.loadDb();
        this.sqlPromise = initSqlJs({
            locateFile: () => sqlWasm
        }).then(sql => {
            const uintData = new Uint8Array(loadedDb);
            this.store.db = new sql.Database(uintData);
            this.store.query();
        }).then(() => {
            this.props.store.endLoading();
        });

        pubsub.subscribe(this, 'fv.ccad.settings.dialog.open', this.openSettingsDialog);
        pubsub.subscribe(this, 'fv.ccad.table.changed', ({ table }) => {
            this.store.setTable(table);
        });
        window.addEventListener('resize', this.resizeHandler);
    }

    componentWillUnmount () {
        pubsub.unsubscribeAll(this);
        window.removeEventListener('resize', this.resizeHandler);
    }

    resizeHandler = () => {
        this.setState({
            screen: Settings.device.getScreenInfo()
        });
    };

    render () {
        const { store: fvStore, children } = this.props;

        return (
            <div
                className='fileview-component-loader ccad-component-loader'
                data-what='file-viewer'
                style={{ display: 'block' }}
            >
                { children }
                {
                    (!fvStore.loading && this.store.columns.active) &&
                        <ViewerContext.Provider value={{
                            store: this.store,
                            isMobile: !['md', 'lg'].includes(this.state.screen.size)
                        }}
                        >
                            <ConnectCADFilters table={this.store.table} />
                            <Table />
                        </ViewerContext.Provider>
                }
            </div>
        );
    }
};

ConnectCADViewer.propTypes = {
    ...commonPropTypes
};

export default observer(ConnectCADViewer);
