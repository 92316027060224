import React from 'react';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';

import { MenuItem } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';
import { Checkbox, CheckboxState } from '@vectorworks/vcs-ui/dist/lib/Checkbox/Checkbox';

import Filter from './Filter';
import Trigger from '../Trigger';
import S from '../styles';
import ViewerContext from '../../ViewerContext';

const ColumnsDropdown = () => {
    const { store, isMobile } = React.useContext(ViewerContext);
    const { columns } = store;
    const popperState = usePopupState({ variant: 'popper', popupId: 'columnsMenu' });
    const isActive = columns.columns.filter(c => !c.active).length > 0;

    const clear = () => {
        columns.columns.forEach(c => !c.active && c.toggleActive());
    };

    return (
        <Filter
            isActive={isActive}
            clear={clear}
            label='columns'
        >
            {
                isMobile
                    ? <S.FilterIcon
                        icon='columns-16'
                        {...bindTrigger(popperState)}
                        data-what='ccad-filter'
                        data-which='columns-button'
                    />
                    : <Trigger
                        {...bindTrigger(popperState)}
                        data-what='ccad-filter'
                        data-which='columns-button'
                    >
                        {gettext('Columns')}
                    </Trigger>
            }
            <S.ScrollableMenu {...bindMenu(popperState)}>
                {
                    columns
                        .filterable
                        .map(c => (c.id !== 'device') &&
                            <MenuItem
                                key={`ci-${c.id}`}
                                onClick={ c.toggleActive }
                                data-what='ccad-filters-column-item'
                                data-which={`${c.id}`}
                            >
                                <Checkbox
                                    state={ c.active ? CheckboxState.ON : CheckboxState.OFF }
                                    style={{ margin: '0 5px' }}
                                    data-what='ccad-filters-column-checkbox'
                                    data-which={`${c.id}`}
                                />
                                { c.title }
                            </MenuItem>
                        )
                }
            </S.ScrollableMenu>
        </Filter>
    );
};

export default ColumnsDropdown;
