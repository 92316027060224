import { RGBColor } from '@vectorworks/vcs-ui/dist/lib/ColorPicker/utils';
import { flatten } from 'lodash';

export function alphaComp (BackLight, ForeLight, alpha) {
    const Xa = 255 * (1 - (alpha / 255.0));
    const Da = alpha + Xa;
    return (ForeLight * alpha + BackLight * Xa) / Da;
}

export function luminance (color) {
    return Math.round(((color.r * 299) + (color.g * 587) + (color.b * 114)) / 1000);
}

export function getColorsFromEditorState (editorState) {
    const initialColors = {};

    flatten(
        flatten(
            editorState.getCurrentContent().getBlocksAsArray()
                .map(block => block.getCharacterList().toJS())
        )
            .map(char => char.style)
    )
        .filter(item => item.startsWith('color-'))
        .forEach(color => {
            initialColors[color] = { color: RGBColor.fromStyleClass(color).toCSS() };
        });

    return initialColors;
}
