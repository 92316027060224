import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { pubsub } from '../../../../base';
import { COLUMNS, TABLES, highlightSearch } from './utils';
import ViewerContext from './ViewerContext';

const NavButton = styled.div`
    padding: 0 4px;
    margin: 1px 0;

    border-radius: 4px;
    border: 1px solid var(--vcs-color--cyan);

    display: flex;
    align-self: center;
    
    cursor: pointer;
    word-break: break-word;
`;

const NAVIGATORS = {
    srcDeviceName: {
        table: TABLES.equipment.name,
        cells: {
            src: 'srcDeviceName',
            dst: 'deviceName'
        }
    },
    srcSocketName: {
        table: TABLES.devices.name,
        cells: {
            src: 'srcSocketName',
            dst: 'socketName'
        },
        extraCondition: (source, target) => {
            return source.srcDeviceName === target.device;
        }
    },
    dstDeviceName: {
        table: TABLES.equipment.name,
        cells: {
            src: 'dstDeviceName',
            dst: 'deviceName'
        }
    },
    dstSocketName: {
        table: TABLES.devices.name,
        cells: {
            src: 'dstSocketName',
            dst: 'socketName'
        },
        extraCondition: (source, target) => {
            return source.dstDeviceName === target.device;
        }
    },
    device: {
        table: TABLES.equipment.name,
        cells: {
            src: 'device',
            dst: 'deviceName'
        }
    },
    connectedTo: {
        table: TABLES.circuits.name,
        cells: {
            src: 'connectedTo',
            dst: 'number'
        },
        extraCondition: (source, target) => {
            const isSrc = source.device === target.srcDeviceName &&
                source.socketName === target.srcSocketName;
            const isDst = source.device === target.dstDeviceName &&
                source.socketName === target.dstSocketName;
            return isSrc || isDst;
        }
    },
    deviceName: {
        table: TABLES.devices.name,
        cells: {
            src: 'deviceName',
            dst: 'device'
        }
    }
};

const Navigator = ({ item, cell, nav }) => {
    const { store } = React.useContext(ViewerContext);
    const value = item[cell.id];
    const sourceItem = item;

    const onClick = e => {
        e.stopPropagation();
        pubsub.publish('fv.ccad.table.changed', {
            table: nav.table,
            previousTable: store.table,
            sourceItem,
            addToHistory: true
        });

        setTimeout(() => {
            if (nav.table === TABLES.devices.name && nav.cells.dst === COLUMNS.device.id) {
                pubsub.publish('fv.ccad.collapsable.select.row', {
                    column: COLUMNS.device.id,
                    value: sourceItem[nav.cells.src]
                });
            } else {
                pubsub.publish('fv.ccad.item.nav', { sourceItem, nav });
            }
        }); // setTimeout is used to run the action only after the table has changed
    };

    return value && value !== '---'
        ? <NavButton
            onClick={onClick}
            data-what='ccad-navigation-button'
            data-which={value}
        >
            { highlightSearch(value, store.searchQuery, item, cell) }
        </NavButton>
        : highlightSearch(value, store.searchQuery, item, cell);
};

Navigator.propTypes = {
    item: PropTypes.object,
    cell: PropTypes.object,
    nav: PropTypes.object
};

export default Navigator;
export { NAVIGATORS };
