import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';

const C = {
    Wrapper: styled.div`
        display: flex;
        flex-direction: column;
    `,
    Header: styled.div`
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        flex-shrink: 0;
    `,
    Actions: styled.div`
        display: flex;
        align-items: center;
        margin-left: auto;

        > button {
            margin-left: 16px;
        }
    `,
    AllComments: styled.div`
        display: flex;
        align-items: center;
        cursor: pointer;

        > span {
            margin-left: 4px;
        }
    `
};

export const VCDOCHeader = observer(function ({ commentsStore }) {
    const {
        showAnnotations, setShowAnnotations,
        filters, setFilters
    } = commentsStore;

    const onChangeResolved = () => {
        setFilters({ resolved: !filters.resolved });
    };

    const onChangeShowAnnotations = () => {
        setShowAnnotations(!showAnnotations);
    };

    return (commentsStore.currentVersionComments.length > 0 &&
        <C.Header>
            <C.Actions>
                {!commentsStore.isWebview && <IconButton
                    title={
                        commentsStore.showAnnotations
                            ? gettext('Hide annotations')
                            : gettext('Show annotations')
                    }
                    toggled={commentsStore.showAnnotations}
                    onClick={onChangeShowAnnotations}
                    ga-action={commentsStore.GASettings.action}
                    ga-label='Toggle_ShowAnnotations'
                    data-what='comments-btn'
                    data-which='toggle-show-annotations'
                >
                    <Icon icon='eye-16' size='16px' />
                </IconButton>}

                <IconButton
                    title={
                        commentsStore.filters.resolved
                            ? gettext('Show resolved')
                            : gettext('Hide resolved')
                    }
                    toggled={!commentsStore.filters.resolved}
                    onClick={onChangeResolved}
                    ga-action={commentsStore.GASettings.action}
                    ga-label='Toggle_Resolved'
                    data-what='comments-btn'
                    data-which='toggle-resolved'
                >
                    <Icon icon='status-ok-16' size='16px' />
                </IconButton>
            </C.Actions>

        </C.Header>
    );
});

VCDOCHeader.propTypes = {
    commentsStore: PropTypes.object.isRequired
};
