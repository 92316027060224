import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Routes, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import Router from './router';
import ErrorBoundary from '../common/ErrorBoundary';
import Projector from '../View/Projector';
import { Store, StoreProvider } from '../Store';
import Preview from '../Preview';
import PreviewSlide from '../Preview/PreviewSlide';
import Portal from '../../Components/Portal';
import DialogContainer from '../../Components/Dialog/Dialog';

import { ConfirmDialogViewModel } from '../../base/dialog';
import { sentAnalyticsEvent } from '../../base/analytics';

import toolbar from '../../view/action-toolbar.vm';

ko.components.register('action-toolbar', {
    viewModel: {
        createViewModel (ctx) {
            toolbar.setParams(ctx);
            return toolbar;
        }
    },
    template: { element: 'action-toolbar' }
});

ko.components.register('global-action-toolbar', {
    viewModel: { instance: toolbar },
    template: { element: 'global-action-toolbar' }
});

ko.components.register('global-create-actions', {
    viewModel: { instance: toolbar },
    template: { element: 'global-create-actions' }
});

ko.components.register('dialog-delete-comment', {
    viewModel: ConfirmDialogViewModel,
    template: { element: 'dialog-delete-comment' }
});

const PresentationLoader = ({ store, ...props }) => {
    const params = useParams();
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        store.getPresentation(params.uuid)
            .then(() => {
                setLoading(false);
            });
    }, []);

    return loading
        ? <div className='toolbar relative' />
        : props.children({ store });
};

PresentationLoader.propTypes = {
    store: PropTypes.object.isRequired,
    match: PropTypes.object
};

const RenderPresentationLoader = (props) => {
    const params = useParams();

    return (
        <PresentationLoader {...props}>
            {({ store }) =>
                <Projector store={store}>
                    {args =>
                        <React.Fragment>
                            {
                                params.slideId &&
                                <Portal container='#presentation-fs-preview'>
                                    <DialogContainer dialogContainer={store.dialog} />
                                    <PreviewSlide
                                        {...args}
                                        {...props}
                                        store={store}
                                    />
                                </Portal>
                            }
                            <Preview {...args} {...props}/>
                        </React.Fragment>}
                </Projector>}
        </PresentationLoader>
    );
};

RenderPresentationLoader.propTypes = {
    match: PropTypes.object
};

const OfflinePreview = ({ store }) => {
    return (
        <StoreProvider store={store}>
            <HashRouter basename='/presentations'>
                <Routes>
                    <Route path=':uuid'>
                        <Route index element={<RenderPresentationLoader store={store}/>} />
                        <Route path=':slideId' element={<RenderPresentationLoader store={store}/>} />
                    </Route>
                </Routes>
            </HashRouter>
        </StoreProvider>
    );
};

OfflinePreview.propTypes = {
    store: PropTypes.object.isRequired
};

const OnlinePreview = ({ store }) => {
    return (
        <StoreProvider store={store}>
            <Router basename='/presentations' history={store.history}>
                <Routes>
                    <Route path=':uuid'>
                        <Route index element={<RenderPresentationLoader store={store}/>} />
                        <Route path=':slideId' element={<RenderPresentationLoader store={store}/>} />
                    </Route>
                </Routes>
            </Router>
            <DialogContainer dialogContainer={store.dialog} />
        </StoreProvider>
    );
};

OnlinePreview.propTypes = {
    store: PropTypes.object.isRequired
};

class App extends React.Component {
    constructor (props) {
        super(props);
        this.store = new Store({
            historyBasename: '/presentations'
        });
        this.store.init();
    }

    componentDidMount () {
        $(document).on('click', this.sendGA);
    }

    componentWillUnmount () {
        this.store.cleanUp();
        $(document).off('click', this.sendGA);
    }

    sendGA = event => {
        const action = event.target.getAttribute('ga-action');
        const label = event.target.getAttribute('ga-label');
        action && label && sentAnalyticsEvent({ action, label }, { event });
    };

    render () {
        return Settings.offlinePresentation
            ? <OfflinePreview store={this.store} />
            : <OnlinePreview store={this.store} />;
    }
}

ReactDOM.render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>,
    document.getElementById('iboards-root')
);

export { App };
