import { Image } from '@vectorworks/vcs-boards';
import { makeRef } from '../../../utils/react-utils';

export class BackgroundBox {
    constructor ({ board }) {
        this.editorBox = new Image({
            store: board.vcsBEStore,
            id: '_BACKGROUND',
            x: 0,
            y: 0,
            width: 1920,
            height: 1080
        });

        this.editorBox.moveableProps = new Proxy({}, { get () { return false; } });
        this.board = board;
        this.root = board.root;

        this.pinContainerRef = makeRef();
        this.editorBox.cloudBox = this;
        this.isOptimistic = false;
    }

    get pins () {
        return this.board.backgroundPins;
    }
}
