import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';
import { computed, makeObservable } from 'mobx';

import splitContextActions from '../../../base/toolbar-context-actions';
import SelectionCounter from '../../common/ToolbarComponents/SelectionCounter';

const ContextProvider = React.createContext();

class ContextTools extends Component {
    constructor (props) {
        super(props);

        makeObservable(this, {
            toolbarContextActions: computed
        });
    }

    static Visible () {
        return (
            <ContextProvider.Consumer>
                { ctx =>
                    <div className='tb__ctx-tools--primary'>
                        { ctx.actions.primary.map(ctx.renderVisible) }
                    </div>}
            </ContextProvider.Consumer>
        );
    }

    static Collapsed () {
        return (
            <ContextProvider.Consumer>
                { ctx =>
                    !!ctx.actions.secondary.length &&
                    <div className='dropdown' data-what='toolbar-dropdown'>
                        <a
                            className='dropdown-toggle'
                            data-toggle='dropdown'
                        ><span className='tool icon-more'/>
                        </a>

                        <ul className='dropdown-menu override-bootstrap-dropdown'>
                            { ctx.actions.secondary.map(ctx.renderCollapsed) }
                        </ul>
                    </div>}
            </ContextProvider.Consumer>
        );
    }

    static ContextActions ({ alignRight = false }) {
        return (
            <div className={'tb__ctx-tools__actions' + (alignRight ? ' tb__ctx-tools__actions--right' : '')}>
                <ContextTools.Collapsed />
                <ContextTools.Visible />
            </div>
        );
    }

    static SelectionCounter ({ visible = true }) {
        return (
            <ContextProvider.Consumer>
                { ctx =>
                    visible &&
                    <SelectionCounter
                        collectionStore={ctx.collectionStore}
                    />}
            </ContextProvider.Consumer>
        );
    }

    state = {
        visibleActionsCount: window.innerWidth < 360 ? 3 : 4
    };

    get toolbarContextActions () {
        return splitContextActions(
            this.props.target.actions,
            this.state.visibleActionsCount,
            this.props.hiddenActions);
    }

    doAction = (action) =>
        () => {
            return this.props.target.doAction(action);
        };

    renderVisible = action =>
        (<a
            className={`tool ${action.data.properties.icon}`}
            onClick={this.doAction(action.name)}
            title={action.data.properties.title}
            key={action.name}
            data-what={this.props.what}
            data-which={action.name}
            ga-action={this.props.GAAction}
            ga-label={action.data.properties.GALabel}
        />);

    renderCollapsed = action =>
        (<li
            onClick={this.doAction(action.name)}
            title={action.data.properties.title}
            key={action.name}
            data-what={this.props.what}
            data-which={action.name}
            ga-action={this.props.GAAction}
            ga-label={action.data.properties.GALabel}
        >
            <div className='dropdown-item'>
                <span className={`icon ${action.data.properties.icon}`}/>
                <span>{ action.data.properties.title }</span>
            </div>
        </li>);

    render () {
        return (
            <ContextProvider.Provider
                value={{
                    actions: this.toolbarContextActions,
                    renderVisible: this.renderVisible,
                    renderCollapsed: this.renderCollapsed,
                    collectionStore: this.props.collectionStore
                }}
            >{ this.props.children }
            </ContextProvider.Provider>
        );
    }
};

ContextTools.propTypes = {
    what: PropTypes.string,
    GAAction: PropTypes.string,
    target: PropTypes.object.isRequired,
    collectionStore: PropTypes.any,
    hiddenActions: PropTypes.array
};

export default observer(ContextTools);
