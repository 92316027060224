import React from 'react';

import { observer } from 'mobx-react';

import { commonPropTypes } from './utils';
import Icon from '../../Components/Icon';

const NoPreview = props => {
    const download = () => props.store.file.file.actions.download.action(props.store.file.file);

    return (
        <div className='no-preview'>
            <div className='message-box flex-col'>
                <Icon icon='icon-view'/>
                <div className='message'>
                    <span className='msg-row-prim'>{ gettext('No preview') }</span>
                    <span>{ gettext('Sorry, no preview is available for this file.') }</span>

                    {
                        props.store.file.file.actions.download &&
                    !props.store.file.isOwner &&
                        <div className='download-link'>
                            <Icon icon='icon-download'/>
                            <a
                                className='dw-link'
                                onClick={download}
                            >
                                { gettext('Download') }
                            </a>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default observer(NoPreview);

NoPreview.propTypes = {
    ...commonPropTypes
};
