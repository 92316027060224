import { reaction } from 'mobx';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

const commonPropTypes = {
    store: PropTypes.object,
    controller: PropTypes.func
};

const reactionWithOldValue = (expression, effect) => {
    let oldValue;
    return reaction(expression, v => {
        if (!isEqual(v, oldValue)) {
            effect(v, oldValue);
            oldValue = v;
        }
    });
};

export {
    commonPropTypes,
    reactionWithOldValue
};
