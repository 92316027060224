import hideSplashScreen from './splash-screen';
import keyboard from './keyboard';
import pubsub from './pubsub';
import { handleSafariLoadBug } from './shame';

const bootstrapper = {
    tasks: [],
    run () {
        for (const task of this.tasks) {
            task();
        }
    },
    schedule (task) {
        this.tasks.push(task);
    }
};

bootstrapper.schedule(() => keyboard.init());
bootstrapper.schedule(() => {
    ko.applyBindings({}, document.body);
    pubsub.publish('knockout.js-bindings-applied');
});
bootstrapper.schedule(hideSplashScreen);

$(function () {
    !handleSafariLoadBug() && bootstrapper.run();
});

export default bootstrapper;
