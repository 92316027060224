import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Navigator, { NAVIGATORS } from './Navigator';
import { convertUnit } from './units';
import S from './styles';

const Cell = ({ item, onClick, cell, store, isMobile, children }) =>
    <S.Cell
        align={!isMobile && cell && item.getAlign(cell)}
        onClick={onClick}
    >
        { children }
        {
            cell && (
                NAVIGATORS[cell.id]
                    ? <Navigator item={item} cell={cell} nav={NAVIGATORS[cell.id]} />
                    : convertUnit(cell, item[cell.id], store.unitsStore)
            )
        }
    </S.Cell>;

Cell.propTypes = {
    store: PropTypes.object,
    item: PropTypes.object,
    cell: PropTypes.object,
    onClick: PropTypes.func,
    isMobile: PropTypes.bool
};

export default observer(Cell);
