import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { Menu } from '@vectorworks/vcs-ui/dist/lib/Menu/Menu';
import { MenuItem, MenuItemIcon } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';

import { DraggablePinCreator } from '../Editor/DraggablePinCreators';
import { PendingTypePin as PendingTypePinModel, TargetType } from '../models/board';
import { PinUI } from './PinUI';

export const GenericPin = React.forwardRef(
    (props, ref) => {
        const { pin, ...rest } = props;

        return (
            <div
                ref={ref}
                className='pin'
                style={{
                    position: 'absolute',
                    top: `${pin.position.y * 100}%`,
                    left: `${pin.position.x * 100}%`
                }}
                {...rest}
            >
                <PinUI
                    appearanceModel={pin.props}
                    highlightOpacity={1}
                />
            </div>
        );
    }
);

GenericPin.propTypes = {
    pin: PropTypes.shape({
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number
        }),
        props: PropTypes.object
    })
};

const GenericTextPin = React.forwardRef(
    (props, ref) => {
        const { pin, ...rest } = props;

        return (
            <div className='text-pin'>
                <div
                    ref={ref}
                    className='pin'
                    {...rest}
                >
                    <PinUI
                        isTextPin={true}
                        appearanceModel={pin.props}
                        highlightOpacity={1}
                    />
                </div>
            </div>
        );
    }
);

GenericTextPin.propTypes = {
    pin: PropTypes.shape({
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number
        }),
        props: PropTypes.object
    })
};

export const PendingPinOptions = ({ onSelect }) => {
    return (
        <React.Fragment>
            <MenuItem
                key={DraggablePinCreator.ASSET.type}
                onClick={ (e) => onSelect(e, DraggablePinCreator.ASSET) }
                data-what='pending-pin-menu'
                data-which={DraggablePinCreator.ASSET.type}
            >
                <MenuItemIcon>
                    <Icon icon={DraggablePinCreator.ASSET.getTypeIcon()} />
                </MenuItemIcon>
                { gettext('File') }
            </MenuItem>
            <MenuItem
                key={DraggablePinCreator.TEXT.type}
                onClick={ (e) => onSelect(e, DraggablePinCreator.TEXT) }
                data-what='pending-pin-menu'
                data-which={DraggablePinCreator.TEXT.type}
            >
                <MenuItemIcon>
                    <Icon icon={DraggablePinCreator.TEXT.getTypeIcon()} />
                </MenuItemIcon>
                { gettext('Text') }
            </MenuItem>
            <MenuItem
                key={DraggablePinCreator.LINK.type}
                onClick={ (e) => onSelect(e, DraggablePinCreator.LINK) }
                data-what='pending-pin-menu'
                data-which={DraggablePinCreator.LINK.type}
            >
                <MenuItemIcon>
                    <Icon icon={DraggablePinCreator.LINK.getTypeIcon()} />
                </MenuItemIcon>
                { gettext('Link') }
            </MenuItem>
        </React.Fragment>
    );
};

PendingPinOptions.propTypes = {
    onSelect: PropTypes.func
};

export const PendingTypePin = ({ pin, ...rest }) => {
    const anchorRef = React.createRef();
    const [anchorEl, setAnchorEl] = React.useState(null);

    React.useEffect(() => {
        setAnchorEl(anchorRef.current);
    }, [anchorRef]);

    const handleClose = (e) => {
        e.stopPropagation();
    };

    const onSelect = (e, pinType) => {
        handleClose(e);
        pin.onChoose(pinType);
    };

    const onCancel = (e) => {
        handleClose(e);
        pin.onCancel();
    };

    const PinComponent = pin.targetType === TargetType.TEXT ? GenericTextPin : GenericPin;

    return (
        <React.Fragment>
            <PinComponent ref={anchorRef} pin={pin} {...rest} />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onCancel}
                style={{ paddingRight: 15 }}
            >
                <PendingPinOptions onSelect={onSelect} />
            </Menu>
        </React.Fragment>
    );
};

PendingTypePin.propTypes = {
    pin: PropTypes.instanceOf(PendingTypePinModel)
};
