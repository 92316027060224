/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { MenuItem } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';
import { Select } from '@vectorworks/vcs-ui/dist/lib/Select/Select';

import { TextPin as BTextPin, LinkPin as BLinkPin } from '../../../../../models/board';
import { TextPin as TTextPin, LinkPin as TLinkPin } from '../../../../../models/tour';
import { FileTypes } from '../../../../../../lib';
import Model, { Image } from './model';

const CHOICES = [
    [
        Model.IMAGES.ARROW_UP,
        Model.IMAGES.ARROW_DOWN,
        Model.IMAGES.ARROW_LEFT,
        Model.IMAGES.ARROW_RIGHT,
        Model.IMAGES.ARROW_UP_FRAME,
        Model.IMAGES.ARROW_DOWN_FRAME,
        Model.IMAGES.ARROW_LEFT_FRAME,
        Model.IMAGES.ARROW_RIGHT_FRAME
    ],
    [
        Model.IMAGES.PANORAMA,
        Model.IMAGES.V,
        Model.IMAGES.IMAGE,
        Model.IMAGES.PDF,
        Model.IMAGES.VGX,
        Model.IMAGES.VIDEO,
        Model.IMAGES.PANORAMA_FRAME,
        Model.IMAGES.V_FRAME,
        Model.IMAGES.IMAGE_FRAME,
        Model.IMAGES.PDF_FRAME,
        Model.IMAGES.VGX_FRAME,
        Model.IMAGES.VIDEO_FRAME
    ],
    [
        Model.IMAGES.LINK_1,
        Model.IMAGES.LINK_2,
        Model.IMAGES.LINK_3,
        Model.IMAGES.INFO,
        Model.IMAGES.EXCLAM,
        Model.IMAGES.QUESTION,
        Model.IMAGES.INFO_FRAME,
        Model.IMAGES.EXCLAM_FRAME,
        Model.IMAGES.QUESTION_FRAME
    ],
    [
        Model.IMAGES.FB_N,
        Model.IMAGES.INSTA_N,
        Model.IMAGES.LINKEDIN_N,
        Model.IMAGES.RSS_N,
        Model.IMAGES.TWITTER_N,
        Model.IMAGES.YT_N
    ]
];

const S = {
    Select: styled(Select)`
        height: 32px;

        .MuiSelect-select {
            > button {
                color: var(--text-primary-color);
                padding: 0;

                /* The selection */
                > div {
                    display: none;
                }

                > i {
                    font-size: 32px;
                }
            }
        }
    `,
    IconMenuItem: styled(MenuItem)`
        display: inline-flex;
        padding: 7px;

        &:hover {
            background-color: transparent;
        }

        &.Mui-selected {
            &:hover {
                background-color: transparent;
                border: none;
                box-shadow: none;
            }
            background-color: transparent;
            border: none;
            box-shadow: none;
        }
    `,
    Separator: styled.div`
        height: 0;
        border-bottom: 1px solid var(--border-color);
        margin: 7px 0;
    `,
    IconsSeparator: styled.div`
        height: 0;
        margin: 7px 0;
    `
};

export default function IconPicker ({ image, onChange, ...rest }) {
    const [open, setOpen] = React.useState(false);

    const handleChange = (event) => {
        onChange(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <S.Select
            value={image}
            open={open}
            onClose={(e) => { e.stopPropagation(); }}
            onOpen={handleOpen}
            onChange={handleChange}
            MenuProps={{
                onClose: handleClose
            }}
            {...rest}
        >
            <MenuItem value={Model.IMAGES.NONE.type}>
                {Model.IMAGES.NONE.title}
            </MenuItem>
            <MenuItem value={Model.IMAGES.AUTO.type}>
                {Model.IMAGES.AUTO.title}
            </MenuItem>
            <S.Separator />

            {
                CHOICES.map((group) => {
                    // This is because of the separators. MenuItem should be a direct child of Select.
                    group[group.length - 1] !== null && group.push(null);

                    return (
                        group.map(imageOption => {
                            return imageOption !== null
                                ? (
                                    <S.IconMenuItem value={ imageOption.type }>
                                        <IconButton
                                            toggled={ imageOption.type === image }
                                            toggleIndicatorProps={{ style: { width: 26, height: 26 } }}
                                        >
                                            <Icon icon={imageOption.type} size='sm' />
                                        </IconButton>
                                    </S.IconMenuItem>
                                )
                                : <S.IconsSeparator />;
                        })
                    );
                })
            }
        </S.Select>
    );
}

IconPicker.getAutoImage = (pin) => {
    let image;
    if (pin.asset) {
        image = {
            [FileTypes.panorama]: Model.IMAGES.PANORAMA,
            [FileTypes.image]: Model.IMAGES.IMAGE,
            [FileTypes.pdf]: Model.IMAGES.PDF,
            [FileTypes.txt]: Model.IMAGES.INFO,
            [FileTypes.vwx]: Model.IMAGES.VGX,
            [FileTypes.vgx]: Model.IMAGES.VGX,
            [FileTypes.vwx3dw]: Model.IMAGES.VGX,
            [FileTypes.video]: Model.IMAGES.VIDEO
        }[pin.asset.fileType.type] || Model.IMAGES.NONE;
    } else {
        image = [
            [TTextPin, Model.IMAGES.INFO],
            [BTextPin, Model.IMAGES.INFO],
            [TLinkPin, Model.IMAGES.LINK_1],
            [BLinkPin, Model.IMAGES.LINK_1]
        ].find(([pinType, _]) => pin instanceof pinType)[1] || Model.IMAGES.NONE;
    }
    return image.type;
};

IconPicker.propTypes = {
    image: PropTypes.oneOfType([PropTypes.instanceOf(Image), PropTypes.number, PropTypes.string])
};
