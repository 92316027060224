import React from 'react';
import PropTypes from 'prop-types';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// Mofidied implementation of
// https://github.com/remix-run/react-router/blob/0f86bb31856ec19dd45c5b9d1de14df97dc2353b/packages/react-router-dom/index.tsx#L288
function BrowserRouter ({ basename, children, window, history: browserHistory }) {
    const historyRef = React.useRef(); // to keep BrowserHistory
    if (historyRef.current == null) {
        historyRef.current = browserHistory || createBrowserHistory({ window, v5Compat: true });
    }

    const history = historyRef.current;
    const [state, setState] = React.useState({
        action: history.action,
        location: history.location
    });

    React.useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router
            basename={basename}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        >
            {children}
        </Router>
    );
}

BrowserRouter.propTypes = {
    basename: PropTypes.string,
    history: PropTypes.object,
    window: PropTypes.instanceOf(window.constructor)
};

export default BrowserRouter;
