import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';

import { MenuItem, MenuItemIcon } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';

import { S, PinEditPanel } from './PinEditPanel';
import { Portal } from '../../../models/tour';
import SelectCameraPosition from '../SelectCameraPosition';

export const PortalEditorPopup = observer(({ pin, editor, onClose }) => {
    const store = editor.props.editor.root;

    const updateCameraPosition = () => {
        return editor
            .setMode('chooseCameraRotation')
            .then(() => {
                return store.dialog.open({
                    component: SelectCameraPosition,
                    context: {
                        store,
                        place: pin.toPlace,
                        initialCameraRotation: pin.toPlaceProps.cameraRotation
                    }
                }).result.then(cameraRotation => {
                    return pin.updateCameraRotation(cameraRotation);
                }).finally(() => {
                    runInAction(() => {
                        editor.setMode('editPin', { pin });
                        editor.forceCameraRotation();
                    });
                });
            });
    };

    return (
        <PinEditPanel pin={pin} onClose={onClose}>
            <S.SectionTitle>{gettext('Panorama')}</S.SectionTitle>
            <MenuItem
                onClick={updateCameraPosition}
                title={gettext('Set camera view...')}
            >
                <MenuItemIcon>
                    <Icon icon='camera-position' />
                </MenuItemIcon>
                {gettext('Set camera view...')}
            </MenuItem>
            <S.Separator style={{ marginTop: 8 }} />
        </PinEditPanel>
    );
});

// Like DraggablePin2D's props
PortalEditorPopup.propTypes = {
    onClose: PropTypes.func,
    pin: PropTypes.instanceOf(Portal)
};
