function subscribe (context, topic, handler) {
    const subscription = ko.postbox.subscribe(topic, (...args) => {
        handler.apply(context, args);
    });
    context.subscriptions = context.subscriptions || {};
    context.subscriptions[topic] = subscription;
    return subscription;
};

function unsubscribe (context, topic) {
    const subscription = context.subscriptions && context.subscriptions[topic];
    if (subscription) {
        subscription.dispose();
        delete context.subscriptions[topic];
    }
}

function unsubscribeAll (context) {
    Object.keys(context.subscriptions || {}).forEach(topic => {
        context.subscriptions[topic].dispose();
    });
    context.subscriptions = {};
}

const publish = ko.postbox.publish;

export default {
    subscribe,
    unsubscribe,
    unsubscribeAll,
    publish
};
