import keyboard from './keyboard';

const KEY_EVENTS = {
    escape: {
        predicate: event => event.which === 27,
        handler: () => {
            const currentDialog = Dialog.current();
            const activeDialog = currentDialog && currentDialog.active();
            if (activeDialog) {
                currentDialog.dismiss(null);
            }
        }
    }
};

class Dialog {
    static open (params) {
        keyboard.pushKeyEvents(KEY_EVENTS);

        if (Dialog.current()) {
            Dialog.next.previous = Dialog.current();
        }
        Dialog.current(Dialog.next);
        return Dialog.current().activate(params);
    }

    static dismiss () {
        while (Dialog.current()) {
            Dialog.current().dismiss(null);
        }
    }

    static register (component, options) {
        if (!Dialog.components.hasOwnProperty(component)) {
            ko.components.register(component, options);
            Dialog.components[component] = true;
        }
    }

    constructor () {
        this.init();
        Dialog.next = this;
    }

    init () {
        this.params = {};
        this.component = this.component || ko.observable(null);
        this.component(null);
        this.ctx = this.ctx || ko.observable({});
        this.ctx({});
        this.active = this.active || ko.observable(false);
        this.active(false);
    }

    activate (params) {
        params.ctx = params.ctx || {};
        params.ctx.dialog = this;
        this.params = params;
        this.component(params.component);
        this.ctx(params.ctx);
        this.active(false);
        this.show();
        return this;
    }

    show () {
        this.result = new Promise((resolve, reject) => {
            this.deferred = { resolve, reject };
        });
        this.toggle();
        return this;
    }

    close (result) {
        keyboard.popKeyEvents();
        this.toggle();
        this.deferred.resolve(result);
    }

    dismiss (reason) {
        keyboard.popKeyEvents();
        this.toggle();
        this.deferred.reject(reason);
    }

    toggle () {
        this.active(!this.active());
        if (!this.active()) {
            Dialog.next = Dialog.current();
            Dialog.current(Dialog.current().previous);
            Dialog.next.init();
        } else {
            $(':focus').blur();
        }
    }
}

Dialog.current = ko.observable(null);
Dialog.components = window.Dialog ? window.Dialog.components : {};

Dialog.register('dialog-overlay', {
    synchronous: true,
    viewModel: {
        createViewModel () {
            return {
                active: ko.computed(function () {
                    const current = Dialog.current();
                    const isActive = current && current.active();
                    if (isActive) {
                        $('body').css({ overflow: 'hidden' });
                    } else {
                        $('body').css({ overflow: 'visible' });
                    }
                    return isActive;
                })
            };
        }
    },
    template: '<div id="dialog-overlay" class="dialog" data-bind="visible: active()" style="display: none;"></div>'
});

Dialog.register('dialog-container', {
    synchronous: true,
    viewModel: Dialog,
    template: `<!-- ko if: component() -->
            <div class="dialog__root">
                <div class="dialog__overlay" data-bind="__dialog__,visible: active()" style="display: none;"></div>
            </div>

        <dialog-container></dialog-container>
        <!-- /ko -->`
});

ko.bindingHandlers.__dialog__ = {
    init (el, valueAccessor, allBindings, viewModel, bindingCtx) {
        const dialog = bindingCtx.$rawData;

        ko.applyBindingsToNode(el, {
            component: {
                name: dialog.component,
                params: dialog.ctx
            }
        });

        return { controlsDescendantBindings: true };
    }
};

// Define some useful dialogs
Dialog.register('alert', {
    viewModel: function AlertViewModel (params) {
        const self = this;

        self.ctx = params.ctx;
        self.dialog = params.dialog;

        self.dismissText = params.template.dismissText || gettext('Close');
        self.headerTitle = params.template.headerTitle || gettext('Warning');

        self.dismiss = function () {
            self.dialog.dismiss();
        };

        self.template = ko.observable(params.template);
    },
    template: `
        <!-- ko if: template().name -->
        <div class="alert-by-name-loader" data-bind="template: template().name"></div>
        <!-- /ko -->
        <!-- ko if: template().text -->
        <div id="dialog-alert" class="dialog-sm">
            <div class="dialog-header">
                <span class="dialog-title" data-bind="text: headerTitle"></span>
                <span class="icon icon-close" data-bind="click: dismiss"></span>
            </div>
            <div class="dialog-inner">
                <p class="dialog-paragraph" data-bind="text: template().text"></p>
            </div>
            <div class="dialog-buttons">
                <span class="di-btn di-btn-prim" data-bind="click: dismiss, text: dismissText"></span>
            </div>
        </div>
        <!-- /ko -->`
});

Dialog.alert = (template, ctx = {}) => {
    return Dialog.open({
        component: 'alert',
        ctx: {
            ctx,
            template
        }
    });
};

function ConfirmDialogViewModel (params) {
    this.ctx = params.ctx || {};
    this.dialog = params.dialog;

    const currentEvents = keyboard.peekKeyEvents();
    currentEvents.extend({
        enter: {
            predicate: event => event.which === 13,
            handler: () => this.confirm()
        }
    });

    this.confirm = () => {
        this.dialog.close();
    };

    this.dismiss = () => {
        keyboard.popKeyEvents();
        this.dialog.dismiss();
    };
}

function EnableFeatureViewModel (params) {
    this.ctx = params.ctx || {};
    this.dialog = params.dialog;

    this.confirm = () => {
        window.location.href = 'http://www.vectorworks.net/service-select';
    };

    this.dismiss = () => {
        if (this.ctx.dismissAction !== 'stay-here') {
            window.history.back();
        } else {
            this.dialog.dismiss();
        }
    };
}

function ExpiredRevokedViewModel (params) {
    this.ctx = params.ctx || {};
    this.dialog = params.dialog;

    this.dismiss = () => {
        window.location.replace(Settings.HOME_URL);
        this.dialog.dismiss();
    };
}

Dialog.register('dialog-enable-feature', {
    viewModel: EnableFeatureViewModel,
    template: { url: 'browser/dialogs/dialog-enable-feature.html' }
});

Dialog.register('dialog-integration-expired-revoked', {
    viewModel: ExpiredRevokedViewModel,
    template: { url: 'browser/dialogs/dialog-integration-expired-revoked.html' }
});

window.Dialog = Dialog;
export {
    Dialog,
    ConfirmDialogViewModel
};
