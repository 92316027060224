import { observable, action, makeObservable, runInAction } from 'mobx';

const executeCommands = commands => {
    const [command, ...rest] = commands;
    return command
        ? command.execute().then(() => executeCommands(rest))
        : Promise.resolve();
};

const MIN_SAVE_INTERVAL = 5 * 60 * 1000; // 5 minutes = 5 * 60sec. 1 sec = 1000ms
const SCHEDULE_INTERVAL = 200; // ms

class SaveQueue {
    commands = [];
    isSaved = true;

    constructor (root) {
        makeObservable(this, {
            commands: observable,
            isSaved: observable,
            push: action,
            pushOrUpdate: action,
            flush: action
        });

        this.root = root;
        this.lastPush = null;
        this.timeout = null;
    }

    cleanUp () {
        this.flush();
    }

    push (command) {
        this.pushOrUpdate(command);
        this.isSaved = false;
        this.lastPush = new Date().getTime();
        (command.command.params || {}).flush ? this.flush() : this.scheduleSave();
        return command;
    }

    pushOrUpdate (command) {
        if ((command.command.params || {}).noReduce) {
            this.commands.push(command);
        } else {
            const sameResourceIndex = this.commands.findIndex(c =>
                c.context === command.context && c.command === command.command
            );

            if (sameResourceIndex > -1) {
                if (
                    typeof this.commands[sameResourceIndex].args === 'object' &&
                    command.command.params?.mergeObjectArgs
                ) {
                    this.commands[sameResourceIndex].args = [{
                        ...(this.commands[sameResourceIndex].args[0] || {}),
                        ...command.args[0]
                    }];
                } else {
                    this.commands[sameResourceIndex].args = command.args;
                }
            } else {
                this.commands.push(command);
            }
        }
    }

    flush = () => {
        if (this.commands.length) {
            const promise = executeCommands(this.commands)
                .then(() => {
                    runInAction(() => {
                        this.isSaved = true;
                    });
                });
            this.commands = [];
            clearTimeout(this.timeout);
            this.lastPush = null;
            return promise;
        }
    };

    scheduleSave () {
        this.timeout = setTimeout(() => {
            const now = new Date().getTime();
            if (!this.lastPush) {
                return;  // eslint-disable-line
            } else if (now - this.lastPush > MIN_SAVE_INTERVAL) {
                this.flush();
            } else {
                this.scheduleSave();
            }
        }, SCHEDULE_INTERVAL);
    }
}

export default SaveQueue;
