/*
TODO: the currently used version of the draft js static toolbar includes too many styles.
Is there a way to not load them instead of overriding them all?
*/

import styled from 'styled-components';

export const S = {
    Editor: styled.div`
        ul, ol {
            margin: 0 !important;
        }

        ol > li {
            margin: 0 !important;
            padding-left: var(--ol-li-paddingLeft) !important;

            &::before {
                left: 0 !important;
                width: var(--ol-li-before-width) !important;
            }
        }

        ul > li {
            margin-left: var(--ul-li-marginLeft) !important;
        }
    `,
    Toolbar: styled.div`
        position: fixed;
        top: 0;
        padding: 0 15px;
        z-index: 9999;
        background-color: var(--bg-color);

        /* TODO: make this fit in the toolbar */
        width: 60%;

        >div {
            display: flex;
            align-items: center;
            height: 60px;

            box-shadow: none;
            border: none;
            background-color: var(--bg-color);
        }

        // button wrapper
        .bi09khh { // stylelint-disable-line
            padding: 0 7px;
        }

        // Use > operator so we don't propagate the hover style to children
        .bi09khh > #color-tool { // stylelint-disable-line
            &:hover {
                color: var(--text-primary-color);
            }
        }
        
        // button element
        .bc4rxid {
            padding: 0;
            width: 32px;
            height: 32px;
            font-size: 32px;
            
            color: var(--text-primary-color);
            background-color: var(--bg-color);

            transition: color 0.4s;

            &:hover {
                background-color: inherit;
                color: var(--vcs-color--primary);
            }
        }

        // active button(tool)
        .akzb7t5 {
            color: var(--vcs-color--primary)
        }
        
        // tool separator
        .s6m29i4 {
            display: inline-block;
            height: 20px;
            margin: 0 5px;
        }

        .text-button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            font-weight: bold;
        }
    `
};
