import React from 'react';

import { observable, action, computed, makeObservable, runInAction } from 'mobx';
import { RouterStore, syncHistoryWithStore } from '../lib/mobx-react-router';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';

import CommentsStore from './Stores/Comments';
import DialogStore from '../Components/Dialog/Store';
import DragMovePinManager from './Stores/DragMovePinManager';
import FileViewStore from './Stores/Fileview';
import PushNotificationListener from './Stores/PushNotificationListener';
import SaveQueue from './Stores/SaveQueue';
import SessionStore from './Stores/Session';
import SlideStore from './Stores/Slide';
import StaticProjector from './Stores/StaticProjector';
import UIStore from './Stores/UI';

import { AssetStore } from './Stores/Asset';
import { loadImage } from './FileViewers/loader';
import { saveMixinNoRoot } from './models/save';
import api from './api';
import createImagePreloader from './FileViewers/preload';
import { SlideType } from './models/slide';

const StoreContext = React.createContext('store');

class StoreProvider extends React.Component {
    render () {
        return (
            <StoreContext.Provider value={this.props.store}>
                {this.props.children}
            </StoreContext.Provider>
        );
    }
}

StoreProvider.propTypes = {
    store: PropTypes.object
};

const extractProps = (obj, props) => props.reduce((acc, p) => {
    acc[p] = p === 'root' ? obj : obj[p];
    return acc;
}, {});

const inject = (...stores) => Component => {
    const Wrapper = (props, ref) =>
        <StoreContext.Consumer>
            {store =>
                <Component
                    {...props}
                    {...extractProps(store, stores)}
                    ref={ref}
                />}
        </StoreContext.Consumer>;
    Wrapper.displayName = `inject(${Component.displayName || Component.name})`;
    return React.forwardRef(Wrapper);
};

class Store {
    id = '';
    title = '';
    actions = {};
    transitions;
    makeSlidesFromAssets;
    versionLabel;
    branding = false;
    allowComments = true;
    owner = {};
    isFullscreen = false;

    editor = null;
    preview = null;

    stores = new Map();

    constructor (options) {
        makeObservable(this, {
            id: observable,
            title: observable,
            actions: observable,
            transitions: observable,
            makeSlidesFromAssets: observable,
            versionLabel: observable,
            branding: observable,
            allowComments: observable,
            owner: observable,
            isFullscreen: observable,
            editor: observable,
            preview: observable,
            getPresentation: action,
            toggleConvertToSlides: action,
            toggleTransitions: action,
            toggleBranding: action,
            toggleAllowComments: action,
            brandingImage: computed,
            updateVersionLabel: action,
            updateContent: action,
            setFullscreen: action
        });

        this.registerStore('asset', new AssetStore(this));
        this.registerStore('slide', new SlideStore(this));
        this.registerStore('dialog', new DialogStore(this));
        this.registerStore('ui', new UIStore(this));
        this.registerStore('session', new SessionStore(this));
        this.registerStore('saveQueue', new SaveQueue(this));
        this.registerStore('router', new RouterStore());

        // This is the BrowserHistory instance used in all (online) Presentations Routers.
        // This way we can re-use the same history listeners to trigger navigation
        // outside of React components (ex. the Knockout toolbar);
        this.registerStore('history', syncHistoryWithStore(createBrowserHistory(), this.router));
        this.registerStore('fileViewStore', new FileViewStore(this));
        this.registerStore(
            'imagePreloader',
            createImagePreloader({ loadResource: loadImage })
        );
        this.registerStore('dragMovePinManager', new DragMovePinManager(this));
        this.registerStore('pushNotificationListener', new PushNotificationListener(this));
        this.registerStore('staticProjector', new StaticProjector(this, this.history));
        this.registerStore('comments', new CommentsStore(this));
    }

    async getPresentation (uuid) {
        this.id = uuid;
        return Promise.all([
            api.presentation.getPresentation(uuid),
            this.asset.load()
        ])
            .then(([presentation, assets]) => {
                runInAction(() => {
                    this.title = presentation.title;
                    this.id = presentation.uuid;
                    this.version = presentation.version;
                    this.localLink = '/portal/presentation/' + this.id;
                    this.shareableLink = '/presentations/' + this.id;
                    this.props = presentation.props;
                    this.owner = presentation.owner;
                    this.transitions = this.props.transitions || false;
                    this.allowComments = this.props.allowComments !== undefined
                        ? this.props.allowComments
                        : true;
                    this.versionLabel = this.props.versionLabel ||
                        (Settings.device.isMobile ? 'recommended' : 'original');
                    this.branding = presentation.props.branding !== undefined
                        ? !!presentation.props.branding
                        : !!presentation.owner.branding;
                    this.makeSlidesFromAssets = this.props.makeSlidesFromAssets !== undefined
                        ? this.props.makeSlidesFromAssets
                        : true;
                    this.thumbnail = presentation.thumbnail;
                    this.slide.setSlides(presentation.slides);
                    this.staticProjector.createProjector();
                });
                return this;
            })
            .catch(err => {
                throw err;
            });
    };

    registerStore (name, store) {
        this.stores.set(name, store);
        this[name] = store;
    }

    initStore = (StoreClass, name, ...args) => {
        this[name] = new StoreClass(this, ...args);
    };

    destroyStore = name => {
        this[name] = null;
    };

    init () {
        this.stores.forEach(store => {
            store.init && store.init();
        });
    }

    cleanUp () {
        this.stores.forEach(store => {
            store.cleanUp && store.cleanUp();
        });
    }

    toggleConvertToSlides = () => {
        this.makeSlidesFromAssets = !this.makeSlidesFromAssets;
    };

    toggleTransitions = () => {
        this.transitions = !this.transitions;
    };

    toggleBranding = () => {
        this.branding = !this.branding;
    };

    toggleAllowComments = () => {
        this.allowComments = !this.allowComments;
    };

    get brandingImage () {
        return this.branding && this.owner.branding;
    }

    updateVersionLabel = versionLabel => {
        this.versionLabel = versionLabel;
    };

    updateContent () {
        this.scheduleSave(this.saveCommands.updateContent, {
            transitions: this.transitions,
            makeSlidesFromAssets: this.makeSlidesFromAssets,
            branding: this.branding,
            versionLabel: this.versionLabel,
            allowComments: this.allowComments
        });
    }

    setFullscreen = val => {
        this.isFullscreen = val;
    };

    setPreviewMode = (inPreview) => {
        this.slide.slides
            .filter(slide => slide.type === SlideType.BOARD && slide.version === 2)
            .forEach(board => board.vcsBEStore.setPreview(inPreview));
    };

    saveCommands = {
        updateContent: {
            command (props) {
                return api.presentation.updatePresentation(this.id, { props });
            }
        }
    };
};

Object.assign(
    Store.prototype,
    saveMixinNoRoot
);

export { Store, StoreProvider, inject };
