const UploadStatus = {
    pending: {
        completed: false,
        name: 'pending',
        text: gettext('Pending'),
        icon: 'icon icon-status-pending-16'
    },
    uploading: {
        completed: false,
        name: 'uploading',
        text: gettext('Uploading'),
        icon: false
    },
    uploaded: {
        completed: true,
        name: 'uploaded',
        text: gettext('Uploaded'),
        icon: 'icon icon-status-ok-16'
    },
    canceled: {
        completed: true,
        name: 'canceled',
        text: gettext('Canceled'),
        icon: 'icon icon-status-error-16'
    },
    failed: {
        completed: true,
        name: 'failed',
        text: gettext('Failed'),
        icon: 'icon icon-status-warning-16'
    }
};

export { UploadStatus };
