import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import withResponsiveListeners from '../../../../utils/Responsive';

import Entity from './Entity';
import Cell from './Cell';
import S from './styles';
import MobileContents from './MobileContents';
import LgContents from './LgContents';

const Equipment = observer(
    ({ item, onClick, store, screen }) => {
        const isMobile = React.useMemo(() => !['md', 'lg'].includes(screen.size), [screen]);

        return (
            <Entity
                item={item}
                store={store}
                onClick={onClick}
                header={(open, toggle) => isMobile
                    ? <React.Fragment>
                        <S.Toggler className='cllps-toggle' expand={open} onClick={toggle} />
                        {
                            !open && <S.TogglerDetails columns={2}>
                                <S.CellPair>
                                    <Cell
                                        key={`${item.key}#deviceName`}
                                        cell={store.columns.map.get('deviceName')}
                                        item={item}
                                        store={store}
                                    />
                                    <S.SecondaryText>
                                        <Cell
                                            key={`${item.key}#makemodel`}
                                            cell={store.columns.map.get('makeModel')}
                                            item={item}
                                            store={store}
                                        />
                                    </S.SecondaryText>
                                </S.CellPair>
                                <S.CellPair>
                                    <Cell
                                        key={`${item.key}#room`}
                                        cell={store.columns.map.get('room')}
                                        item={item}
                                        store={store}
                                    />
                                    <S.SecondaryText>
                                        <Cell
                                            key={`${item.key}#racklocation`}
                                            cell={store.columns.map.get('rackLocation')}
                                            item={item}
                                            store={store}
                                        />
                                    </S.SecondaryText>
                                </S.CellPair>
                            </S.TogglerDetails>
                        }
                    </React.Fragment>
                    : <LgContents item={item} store={store} onClick={onClick} />}
                collapse={isMobile && <MobileContents item={item} store={store}/>}
            />
        );
    }
);

Equipment.propTypes = {
    item: PropTypes.object,
    store: PropTypes.object,
    onClick: PropTypes.func
};

export default withResponsiveListeners(Equipment);
