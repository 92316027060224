import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import Entity from './Entity';
import Cell from './Cell';
import withResponsiveListeners from '../../../../utils/Responsive';
import ViewerContext from './ViewerContext';
import S from './styles';

const Location = observer(
    ({ item, skipColumns, identation }) => {
        const { store } = React.useContext(ViewerContext);
        const { columns } = store;

        return (
            <Entity
                item={item}
                identation={identation}
                skipColumns={skipColumns}
                selectable={false}
                isNested={true}
                mobilePreview={
                    <S.TogglerDetails columns={2}>
                        <S.CellPair>
                            <Cell
                                key={`${item.key}#deviceName`}
                                cell={columns.map.get('deviceName')}
                                item={item}
                                isPreview={true}
                            />
                            <S.SecondaryText>
                                <Cell
                                    key={`${item.key}#makemodel`}
                                    cell={columns.map.get('makeModel')}
                                    item={item}
                                    isPreview={true}
                                />
                            </S.SecondaryText>
                        </S.CellPair>
                        <S.CellPair />
                    </S.TogglerDetails>
                }
            />
        );
    }
);

Location.propTypes = {
    item: PropTypes.object,
    onClick: PropTypes.func
};

export default withResponsiveListeners(Location);
