import _UndoManager from 'undo-manager';

class UndoStore {
    constructor (root) {
        this.root = root;
        this._undoManager = new _UndoManager();
        this.disabled = false;
    }

    withDisabled (handler) {
        if (this.disabled) return;
        if (this.root.version !== 2) return;

        /*
            While executing an undo/redo action,
            the handler could uselessly register undo/redo actions within itself.
            So, temporarily disable adding undo/redo steps during the handler execution.
            This makes recursive codes safe, ex:

            const move = () => { undoStore.add({ undo: move() }) }
        */
        const initiallyDisabled = this.disabled;
        this.disabled = true;
        const result = handler();
        this.disabled = initiallyDisabled;
        return result;
    }

    add (...args) {
        return this.withDisabled(() => this._undoManager.add(...args));
    }

    undo (...args) {
        return this.withDisabled(() => this._undoManager.undo(...args));
    }

    redo (...args) {
        return this.withDisabled(() => this._undoManager.redo(...args));
    }
}

export default UndoStore;
