import { observable, makeObservable, action } from 'mobx'; // eslint-disable-line

class Comment {
    replies = [];

    constructor (data, parent) {
        makeObservable(this, {
            replies: observable
        });

        const {
            id,
            content,
            pub_date,
            owner,
            resource = {},
            resolved,
            replies = [],
            metadata,
            resource_type
        } = data;

        this.data = data;
        this.id = id;
        this.resolved = resolved;
        this.content = content;
        // First 180 symbols, doesn't cut off mensions
        // TODO: If the comment has no spaces, this will not trim it at 180 chars.
        this.preview = content.length > 180
            ? this.content.replace(/^(.{180}[^\s]*).*/, '$1')
            : this.content;
        this.published = moment(pub_date).fromNow();
        this.author = `${owner.first_name} ${owner.last_name}`;
        this.owner = owner;
        this.resource = resource; // File or slide
        this.parent = parent;
        this.replies = replies.map(reply => new Comment(reply, this));
        this.metadata = metadata;
        this.resourceType = resource_type;
    }

    // TODO: Is this just this.data, but without replies?
    toRaw = () => {
        return {
            content: this.data.content,
            id: this.id,
            owner: this.data.owner,
            pub_date: this.data.pub_date,
            replies: this.replies.map(r => r.data),
            resolved: this.data.resolved,
            resource: this.data.resource || {},
            metadata: this.data.metadata
        };
    };
}

export {
    Comment
};
