import React from 'react';
import { observer } from 'mobx-react';

import Spinner from '../../Components/Spinner';

export default observer(({ resource }) =>
    resource.state === 'loading'
        ? <Spinner/>
        : resource.state === 'error'
            ? <div>Ooops...</div>
            : null
);
