import React from 'react';

const ResourceDoesNotExist = () => {
    return (
        <div className='message-box flex-col does-not-exist'>
            <span className='icon icon-delete-fromcloud' />

            <div className='message'>
                <span className='msg-row-prim'>{gettext('Not permitted.')}</span>
                <span className='msg-row-sec'>{gettext('This resource has been deleted. Restore it to be able to comment.')}</span>
            </div>
        </div>
    );
};

export default ResourceDoesNotExist;
