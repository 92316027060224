import unorm from 'unorm';
import { observable, computed, reaction, action, makeObservable } from 'mobx';

import { queryString, utils } from '../../lib';
import keyboard from '../../base/keyboard';
import { FileTypes, FilePath } from '../../lib/file';
import { createEmptyFile } from '../../preview/file';

class UIStore {
    visible = false;
    fullScreen = false;

    constructor (root) {
        makeObservable(this, {
            visible: observable,
            fullScreen: observable,
            show: action,
            hide: action
        });

        this.root = root;

        this.controller = (() => {
            let $controller = null;
            const timer = utils.createTimer(5000, () => {
                $controller.fadeOut(500);
            });
            let allowFade = false;
            return {
                init (controller) {
                    $controller = $(controller);
                },
                setFadeSetting (value) {
                    allowFade = value;
                    $controller.fadeIn(100);
                },
                show (timeout) {
                    if (!allowFade || Settings.device.isMobile || !$controller) return;
                    timer.reset();
                    $controller.fadeIn(100);
                },
                hide () {
                    if (!allowFade || Settings.device.isMobile || !$controller) return;
                    timer.stop();
                    $controller.fadeOut(500);
                },
                toggle () {
                    $controller.toggle();
                }
            };
        })();
    };

    show = () => {
        this.visible = true;
        this.root.startLoading();
    };

    hide = () => {
        this.visible = false;
        this.root.endLoading();
    };
}

class FileViewStore {
    loading = false;

    startLoading = () => {
        this.loading = true;
    };

    endLoading = () => {
        this.loading = false;
    };

    constructor (root) {
        makeObservable(this, {
            loading: observable,
            startLoading: action,
            endLoading: action
        });

        this.root = root;
        this.ui = new UIStore(this);
        this.file = new FileStore(this);
    };
}

class ItemGroupIterator {
    cursor;
    static empty () {
        return new ItemGroupIterator([], -1);
    }

    static fromFile (initial, items) {
        if (!items) {
            return ItemGroupIterator.empty();
        }
        const cursor = items.findIndex(a => initial.id === a.id);
        return new ItemGroupIterator(items, cursor);
    }

    constructor (items, cursor) {
        makeObservable(this, {
            cursor: observable
        });

        this.items = items.map(item => item.asset);
        this.cursor = cursor;
    }

    current () {
        return this.items[this.cursor];
    }

    next () {
        if (this.items.length > 0) {
            this.cursor = (this.cursor + 1) % this.items.length;
            return this.current();
        }
    }

    prev () {
        if (this.items.length > 0) {
            this.cursor = (this.cursor - 1 + this.items.length) % this.items.length;
            return this.current();
        }
    }

    hasNext () {
        return this.items.length > 1;
    }

    hasPrev () {
        return this.items.length > 1;
    }
}

class FileStore {
    fileResource;
    innerFile = createEmptyFile();
    group = ItemGroupIterator.empty();

    get numberOfFiles () {
        return this.group.items.length;
    }

    get file () {
        const fileVersion = this.innerFile.fileVersion;
        const prefix = fileVersion.prefix;
        return {
            name: this.innerFile.filename,
            prefix: unorm.nfc(prefix.replace(/\/$/, '')),
            downloadUrl: fileVersion.download_url,
            fileType: FileTypes.fromResourceFileType(fileVersion.file_type) ||
                FileTypes.get(prefix, false),
            extension: FilePath(prefix).extension(false),
            isAvailable: () => true,
            ownerInfo: this.root.root.owner
        };
    }

    get sourceUrl () {
        return queryString.buildUrl(this.file.downloadUrl, {
            viewable: 'on',
            embedded: 'on',
            view_content: 'on',
            ...this.queryParams
        });
    }

    get offlineSourceUrlResp () {
        return {
            url: this.sourceUrl.replace(
                this.file.name,
                encodeURI(this.file.name)
            )
        };
    }

    constructor (root) {
        makeObservable(this, {
            fileResource: observable,
            innerFile: observable,
            group: observable,
            numberOfFiles: computed,
            file: computed,
            sourceUrl: computed,
            offlineSourceUrlResp: computed,
            open: action,
            prev: action,
            next: action,
            empty: action,
            cleanGroupAndHide: action,
            clearFile: action
        });

        this.root = root;
        this.keyEvents = {
            left: {
                predicate: event => event.which === 37,
                handler: () => this.prev()
            },
            right: {
                predicate: event => event.which === 39,
                handler: () => this.next()
            }
        };
        this.reactionFileChangeDisposer = reaction(
            () => this.innerFile,
            file => {
                (file.isEmpty && !file.name && !this.root.loading) && this.cleanGroupAndHide();
                this.root.startLoading();
            }
        );

        this.reactionKeyEventsDisposer = reaction(
            () => this.root.ui.visible,
            visible => {
                if (visible) {
                    keyboard.pushKeyEvents(this.keyEvents);
                } else {
                    keyboard.popKeyEvents();
                }
            }
        );
    }

    open = (initial, files) => {
        if (
            Settings.offlinePresentation &&
            Settings.IN_WEB_VIEW &&
            Settings.NOMAD_SUPPORTED_FILE_TYPES.includes(initial.asset.fileVersion.file_type)
        ) {
            window.HostNotification.event('presentations_open_local_asset', {
                asset: JSON.stringify(initial.asset.fileVersion)
            }).send();
        } else {
            this.group = ItemGroupIterator.fromFile(initial, files);
            this.root.ui.show();
            this.innerFile = this.group.current();

            this.root.startLoading();
        }
    };

    prev = () => {
        if (this.group.hasPrev()) {
            this.root.startLoading();
            this.innerFile = this.group.prev();
        }
    };

    next = () => {
        if (this.group.hasNext()) {
            this.root.startLoading();
            this.innerFile = this.group.next();
        }
    };

    empty = resource => {
        this.innerFile = createEmptyFile(resource);
    };

    cleanGroupAndHide = () => {
        this.root.ui.hide();
        this.group = ItemGroupIterator.empty();
    };

    clearFile = () => {
        this.innerFile = createEmptyFile();
    };

    cleanUp = () => {
        this.reactionFileChangeDisposer();
    };
}

export default FileViewStore;
