import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

const HandTool = ({ viewer }) =>
    <span
        className={`ctrl-tool icon icon-pan ${viewer.panMode ? 'active' : ''}`}
        onClick={viewer.toggleHandTool}
        title={gettext('Pan')}
        ga-action='File_View_Controller'
        ga-label='Click_Hand_Tool'
        data-what='controller-tool'
        data-which='toggle-hand-tool'
    />;

HandTool.propTypes = {
    viewer: PropTypes.object
};

export default observer(HandTool);
