/*
This is the hidden Image behind a BoardItem that appears when you drag over a board
It gets created with the exact position and dimensions like the image that's created on drop.
*/
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import DraggableItems from '../Editor/DraggableItems';

const S = {
    /* This should fit over the whole BoardItem, so dragging could start from anywhere */
    Wrapper: styled.div`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: -1;
        cursor: default;
    `,
    Content: styled.div`
        position: absolute;
        opacity: 0.7;
        background-color: gray;
        background-size: cover;
    `
};

const HiddenImage = observer(({ dragDropMonitor, isDragging, asset, editor }) => {
    const contentRef = React.createRef();

    React.useEffect(() => {
        dragDropMonitor.setDraggedImage(isDragging ? contentRef.current : null);
    }, [isDragging]);

    return (
        <S.Wrapper
            style={{
                opacity: isDragging ? 1 : 0
            }}
        >
            <S.Content
                ref={contentRef}
                style={{
                    width: asset.params.size.width * editor.slide.vcsBEStore.dimensionStore.scaleFactor,
                    height: asset.params.size.height * editor.slide.vcsBEStore.dimensionStore.scaleFactor,
                    backgroundImage: `url("${asset.fileVersion.thumbnail}")`
                }}
            />
        </S.Wrapper>
    );
});

HiddenImage.propTypes = {
    dragDropMonitor: PropTypes.object,
    isDragging: PropTypes.bool,
    asset: PropTypes.object,
    editor: PropTypes.object
};

HiddenImage.defaultProps = {
    isDragging: false
};

const DraggableImage = observer(
    ({ editor, item }) => {
        return (
            <DraggableItems
                editor={editor}
                dragDropMonitor={editor.dragDropMonitor}
                items={[item]}
                carouselItemType={({ isDragging, ...rest }) => (
                    <HiddenImage
                        asset={item.asset}
                        isDragging={isDragging}
                        editor={editor}
                        dragDropMonitor={editor.dragDropMonitor}
                        {...rest}
                    />
                )}
                isDropDisabled
            />
        );
    }
);

DraggableImage.propTypes = {
    editor: PropTypes.shape({
        slide: PropTypes.object,
        dragDropMonitor: PropTypes.object,
        place: PropTypes.object,
        placeProps: PropTypes.object,
        image: PropTypes.object
    }).isRequired,
    item: PropTypes.shape({
        asset: PropTypes.object
    })
};

export default DraggableImage;
