import styled, { css } from 'styled-components';
import { Collapse, Toggler } from '@vectorworks/vcs-ui/dist/lib/Collapse/Collapse';

const CELL_WIDTH_PX = 150;
const CELL_PADDING_PX = 5;

const S = {
    Head: styled.div`
        height: 45px;
        padding: 10px 15px;

        display: grid;
        grid-gap: 15px;
        align-items: center;

        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
    
        ${({ count }) => css`
            width: max(100%, ${(count + 1) * (CELL_WIDTH_PX + CELL_PADDING_PX)}px);
            grid-template-columns: repeat(${count}, ${CELL_WIDTH_PX}px);
        `}
    `,
    Body: styled.div`
        ${({ isMobile, count }) => isMobile
        ? css`
            width: 100%;
        `
        : css`
            width: max(100%, ${(count + 1) * (CELL_WIDTH_PX + CELL_PADDING_PX)}px)  ;
        `}

        overflow-x: hidden;
        overflow-y: auto;
    `,
    th: styled.div`
        padding: 0 ${CELL_PADDING_PX}px;
        align-items: center;
        white-space: nowrap;
        color: var(--text-secondary-color);
        cursor: pointer;

        .icon {
            margin-left: 5px;
        }

        ${({ cellType }) => cellType && css`
            display: flex;
            justify-content: ${cellType === 'number' ? 'right' : 'left'};
        `}
    `,
    grid: styled.div`
        min-height: 50px;
        padding: 0 15px;

        display: grid;
        align-items: center;
        border-bottom: 1px solid var(--border-color);

        ${({ count }) => css`
            grid-template-columns: repeat(${count}, 1fr);
        `}
    `,
    tr: styled.div`
        min-height: 50px;
        display: grid;
        border-bottom: 1px solid var(--border-color);
    
        ${({ count }) => css`
            grid-template-columns: repeat(${count}, 1fr);
        `}
    `,
    Arrow: styled.div`
        justify-self: center;
        align-self: center;
    `,
    Cell: styled.div`
        padding: 0 ${CELL_PADDING_PX}px;
        align-contents: center;
        display: flex;

        ${({ align }) => align && css`
            justify-content: ${align};
        `}
    `,
    CellPair: styled.div`
        display: grid;
    `,
    TogglerDetails: styled.div`
        margin-left: 10px;
        display: grid;
        flex: 1;
        align-items: center;
                
        ${({ columns }) => css`
            grid-template-columns: repeat(${columns}, 1fr);
        `}}
    `,
    Toggler: styled(Toggler)`
        &.md,
        &.lg {
            display: none;
        }
        `,
    trToggler: styled.div`
        ${({ expand }) => !expand && css`
            align-items: center;
        `}

        display: flex;
        min-height: 50px;

        &.xs, &.sm {
            padding: 5px 15px;
            font-weight: bold;

            border-bottom: 1px solid var(--border-color);

            ${({ identation }) => identation && css`
                padding: 5px 0 5px ${identation * 50}px;
            `}
        }

    `,
    LgContent: styled.div`
        min-height: 50px;
        padding: 0 15px;
        align-items: center;

        display: grid;
        grid-gap: 15px;

        border-bottom: 1px solid var(--border-color);

        ${({ count }) => css`
            width: max(100%, ${(count + 1) * (CELL_WIDTH_PX + CELL_PADDING_PX)}px);
            grid-template-columns: repeat(${count}, ${CELL_WIDTH_PX}px);
        `}
    `,
    SmContent: styled.div`
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        padding: 2px 10px;
        align-items: center;
    `,
    Collapse: styled(Collapse)`
        ${({ in: open }) => open && css`flex: 1 0 auto;`}}
    `,
    SecondaryText: styled.div`
        color: var(--text-secondary-color) !important;
    `
};

export default S;
