import React from 'react';
import PropTypes from 'prop-types';

import { ToastComponent } from '@vectorworks/vcs-ui/dist/lib/Toast/Toast';

import styled from 'styled-components';

const StyledOverlay = styled.div`
    visibility: hidden;

    position: absolute;
    inset: 0;

    background-color: rgba(var(--vcs-color--primary-rgb), 0);
    border: solid 3px transparent;
    opacity: 0;
    transition: all 0.3s ease;

    /* TODO: z-index: index($main-z, 'upload-overlay'); */
    z-index: 5000;

    ${({ mounted }) => mounted
        ? `
        visibility: visible;

        background-color: rgba(var(--vcs-color--primary-rgb), 0.3);
        border: solid 3px var(--vcs-color--primary);
        opacity: 1;
    `
        : ''}
`;

class UploadOverlay extends React.Component {
    state = {
        mounted: false
    };

    componentDidMount () {
        setTimeout(() => this.setState({ mounted: true }), 1);
    }

    componentWillUnmount () {
        this.setState({ mounted: false });
    }

    render () {
        const { title } = this.props;
        const { mounted } = this.state;

        return (
            <StyledOverlay mounted={mounted}>
                <ToastComponent
                    notification={{
                        msg: () => title,
                        icon: 'hand',
                        style: {
                            backgroundColor: 'var(--vcs-color--primary)',
                            margin: '10px'
                        }
                    }}
                    hideClose
                />
            </StyledOverlay>
        );
    }
}

UploadOverlay.propTypes = {
    title: PropTypes.string.isRequired
};

export default UploadOverlay;
