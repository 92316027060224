import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { observable, computed, action, makeObservable } from 'mobx';

import { observer } from 'mobx-react';

import { isPointInRect } from '../../lib/dom-utils';
import { queryString } from '~static/js/lib';
import { makeRef } from '../utils/react-utils';
import { TargetType } from '../models/board';
import { inject } from '../Store';
import { DroppableTypes } from '../Editor/DragDropMonitor';
import { EditorPin, withPinPoint } from '../Pins/Pin';
import DefaultPin from '../Pins/PreviewPin';
import Spinner from '../../Components/Spinner';
import { loadPdfJs } from '../../lib/load-pdf';

function isCursorOverElement (pdfPage, cursorEvent) {
    if (!pdfPage) return false;
    const { pageY, pageX } = cursorEvent;
    const { left, right, bottom, top } = pdfPage.getBoundingClientRect();
    return pageX > left && pageX < right && pageY > top && pageY < bottom;
}

const pinPoint = {
    viewPoint (x, y, page) {
        const [viewX, viewY] = page.page.viewport.convertToViewportPoint(x, y);
        return {
            position: 'absolute',
            left: Math.ceil(viewX),
            top: Math.ceil(viewY),
            units: 'px'
        };
    },
    positionWithin (x, y, page) {
        const viewPoint = this.viewPoint(x, y, page);
        return {
            x: viewPoint.left,
            y: viewPoint.top
        };
    },
    normalized (x, y, page) {
        const elementRect = page.getBoundingClientRect();
        const [pdfX, pdfY] = page.page.viewport.convertToPdfPoint(
            x - elementRect.left,
            y - elementRect.top
        );
        return {
            page: page.pageNumber,
            x: pdfX,
            y: pdfY
        };
    },
    normalizedRelative (x, y, page) {
        const [pdfX, pdfY] = page.page.viewport.convertToPdfPoint(x, y);
        return {
            page: page.pageNumber,
            x: pdfX,
            y: pdfY
        };
    }
};

const PinContainer = observer(props => {
    return ReactDOM.createPortal(
        props.children,
        props.pageElement
    );
});

PinContainer.propTypes = {
    pageElement: PropTypes.object
};

/* eslint-disable react/prop-types */

const PDFViewer = observer(class PDFViewer extends React.Component {
    sourceUrl = '';
    pages = observable.map([], { deep: false });
    currentPageNumber = -1;
    document = {
        state: 'init'
    };

    viewAreaVersion = 0;

    constructor (props) {
        super(props);

        makeObservable(this, {
            currentPageNumber: observable,
            document: observable,
            setDocumentState: action,
            addPage: action,
            resetPages: action,
            setPage: action,
            currentPage: computed.struct,
            prevPage: computed.struct,
            nextPage: computed.struct,
            pinsByPage: computed
        });
    }

    getBoundingClientRect () {
        let boundingRect = this._boundingRect;
        if (boundingRect && boundingRect.version === this.viewer.viewAreaVersion) {
            return boundingRect.boundingRect;
        } else {
            boundingRect = {
                version: this.viewer.viewAreaVersion,
                boundingRect: this.page.div.getBoundingClientRect()
            };
            this._boundingRect = boundingRect;
            return boundingRect.boundingRect;
        }
    }

    setDocumentState (state) {
        this.document.state = state;
    }

    addPage (pageNumber, page) {
        this.pages.set(pageNumber, page);
        if (this.currentPageNumber - 1 <= pageNumber && pageNumber <= this.currentPageNumber + 1) {
            this.props.pinContainerRef &&
                this.props.pinContainerRef([this.prevPage, this.currentPage, this.nextPage]);
        }
    }

    resetPages () {
        this.pages.clear();
        this.currentPageNumber = -1;
    }

    setPage (pageNumber) {
        this.currentPageNumber = pageNumber;
        this.props.pinContainerRef &&
            this.props.pinContainerRef([this.prevPage, this.currentPage, this.nextPage]);
    }

    get currentPage () {
        return this.pages.has(this.currentPageNumber) &&
            {
                viewer: this,
                page: this.pages.get(this.currentPageNumber),
                pageNumber: this.currentPageNumber,
                getBoundingClientRect: this.getBoundingClientRect
            };
    }

    get prevPage () {
        return this.pages.has(this.currentPageNumber - 1) &&
            {
                viewer: this,
                page: this.pages.get(this.currentPageNumber - 1),
                pageNumber: this.currentPageNumber - 1,
                getBoundingClientRect: this.getBoundingClientRect
            };
    }

    get nextPage () {
        return this.pages.has(this.currentPageNumber + 1) &&
            {
                viewer: this,
                page: this.pages.get(this.currentPageNumber + 1),
                pageNumber: this.currentPageNumber + 1,
                getBoundingClientRect: this.getBoundingClientRect
            };
    }

    get pinsByPage () {
        return this.props.pins.reduce((acc, pin) => {
            if (!acc[pin.position.page]) {
                acc[pin.position.page] = [];
            }
            acc[pin.position.page].push(pin);
            return acc;
        }, {});
    }

    componentDidMount () {
        loadPdfJs().then(PDFJS => {
            this.PDFJS = PDFJS;
            this.eventBus = new PDFJS.EventBus();
            this.pdfLinkService = new PDFJS.PDFLinkService({
                eventBus: this.eventBus
            });
            this.pdfViewer = new PDFJS.PDFViewer({
                container: this.container,
                eventBus: this.eventBus,
                linkService: this.pdfLinkService
            });
            this.handTool = new window.GrabToPan({
                element: this.container
            });

            this.pdfLinkService.setViewer(this.pdfViewer);
            this.pdfViewer.eventBus.on('pagesinit', this.onPagesInit);
            this.pdfViewer.eventBus.on('pagerendered',
                ev => {
                    // Needed because of VTG-25281
                    ev.source.div.parentNode.parentNode === this.container &&
                        this.addPage(ev.pageNumber, ev.source);
                }
            );
            this.pdfViewer.eventBus.on('pagechanging',
                () => this.setPage(this.pdfViewer.currentPageNumber)
            );
            this.pdfViewer.eventBus.on('updateviewarea',
                () => {
                    this.viewAreaVersion += 1;
                }
            );

            this.loadDocument();
        });

        this.board = document.querySelector('.ib-preview');
        this.resizeViewerContainer();
    }

    componentWillUnmount () {
        this.pdfViewer && this.pdfViewer.cleanup();
        this.container = undefined;
        this.props.pinContainerRef && this.props.pinContainerRef([]);
    }

    componentDidUpdate (prevProps) {
        if (this.props.file !== prevProps.file) {
            this.pdfViewer.setDocument(null);
            this.pdfViewer && this.loadDocument();
        }
    }

    loadDocument () {
        this.resetPages();
        this.sourceUrl = this.props.file.fileVersion.download_url;
        this.setDocumentState('loading');
        if (this.spinner) {
            this.spinner.ref.style.display = 'block';
        }
        const documentUrl = Settings.offlinePresentation
            ? Promise.resolve(this.props.file.fileVersion.download_url)
            : $.getJSON(queryString.buildUrl(
                this.props.file.fileVersion.download_url,
                { response_type: 'data', viewable: 'off' }
            ));
        documentUrl
            .then(async data => await this.PDFJS.getDocument(data).promise)
            .then(pdfDocument => {
                this.pdfViewer.setDocument(pdfDocument);
                this.pdfLinkService.setDocument(pdfDocument, null);
                this.setDocumentState('ready');
                this.resizeViewerContainer();
                if (this.spinner) {
                    this.spinner.ref.style.display = 'none';
                }
            })
            .catch(() => {
                this.setDocumentState('error');
            });
    }

    resizeViewerContainer = () => {
        if (!this.container) return;

        this.container.style.maxHeight = `${this.board.clientHeight}px`;
    };

    onPagesInit = () => {
        const isIntro = this.props.screen.slide.layout === 'intro';
        this.pdfViewer.currentScaleValue = 'page-fit';
        this.setPage(this.pdfViewer.currentPageNumber);

        if (this.pdfViewer.pagesCount === 1 && !isIntro) {
            this.container.style.overflow = 'hidden';
        } else {
            this.container.style.overflow = 'auto';
        }
    };

    render () {
        const Pin = this.props.pinType;
        const FileOverlay = this.props.fileOverlay;

        return (
            <React.Fragment>
                <Spinner ref={spinner => { this.spinner = spinner; } }/>
                <div
                    id='viewerContainer'
                    ref={container => { this.container = container; } }
                    style={{ position: 'absolute' }}
                >
                    { FileOverlay && FileOverlay }
                    <div id='viewer' className='pdfViewer'/>
                </div>
                {
                    this.prevPage &&
                        <PinContainer pageElement={this.prevPage.page.div}>
                            {this.props.children}
                            {(this.pinsByPage[this.prevPage.pageNumber] || []).map(pin =>
                                <Pin
                                    key={pin.id}
                                    pin={pin}
                                    container={this.prevPage}
                                    screen={this.props.screen}
                                    previewContainer={this.props.previewContainer}
                                />
                            )}
                        </PinContainer>
                }
                {
                    this.currentPage &&
                        <PinContainer pageElement={this.currentPage.page.div}>
                            {this.props.children}
                            {(this.pinsByPage[this.currentPage.pageNumber] || []).map(pin =>
                                <Pin
                                    key={pin.id}
                                    pin={pin}
                                    container={this.currentPage}
                                    screen={this.props.screen}
                                    previewContainer={this.props.previewContainer}
                                />
                            )}
                        </PinContainer>
                }
                {
                    this.nextPage &&
                        <PinContainer pageElement={this.nextPage.page.div}>
                            {this.props.children}
                            {(this.pinsByPage[this.nextPage.pageNumber] || []).map(pin =>
                                <Pin
                                    key={pin.id}
                                    pin={pin}
                                    container={this.nextPage}
                                    screen={this.props.screen}
                                    previewContainer={this.props.previewContainer}
                                />
                            )}
                        </PinContainer>
                }
            </React.Fragment>
        );
    }
});

PDFViewer.propTypes = {
    file: PropTypes.object,
    pins: PropTypes.array,
    pinType: PropTypes.object,
    screen: PropTypes.object,
    previewContainer: PropTypes.object,
    pinContainerRef: PropTypes.func
};

PDFViewer.defaultProps = {
    pinType: withPinPoint(DefaultPin, pinPoint)
};

const SimplePDFViewer = observer(class SimplePDFViewer extends React.Component {
    sourceUrl = '';
    pages = observable.map([], { deep: false });
    currentPageNumber = -1;
    document = {
        state: 'init'
    };

    viewAreaVersion = 0;

    constructor (props) {
        super(props);

        makeObservable(this, {
            currentPageNumber: observable,
            document: observable,
            setDocumentState: action,
            resetPages: action,
            setPage: action,
            currentPage: computed.struct
        });
    }

    setDocumentState (state) {
        this.document.state = state;
    }

    resetPages () {
        this.pages.clear();
        this.currentPageNumber = -1;
    }

    setPage (pageNumber) {
        this.currentPageNumber = pageNumber;
    }

    get currentPage () {
        return this.pages.has(this.currentPageNumber) &&
            {
                viewer: this,
                page: this.pages.get(this.currentPageNumber),
                pageNumber: this.currentPageNumber
            };
    }

    componentDidMount () {
        loadPdfJs().then(PDFJS => {
            this.PDFJS = PDFJS;
            this.eventBus = new PDFJS.EventBus();
            this.pdfLinkService = new PDFJS.PDFLinkService({
                eventBus: this.eventBus
            });
            this.pdfViewer = new PDFJS.PDFViewer({
                container: this.container,
                eventBus: this.eventBus,
                linkService: this.pdfLinkService
            });
            this.handTool = new window.GrabToPan({
                element: this.container
            });

            this.pdfLinkService.setViewer(this.pdfViewer);
            this.pdfViewer.eventBus.on('pagesinit', this.onPagesInit);
            this.pdfViewer.eventBus.on('pagerendered',
                ev => {
                    // Needed because of VTG-25281
                    ev.source.div.parentNode.parentNode === this.container &&
                        this.addPage(ev.pageNumber, ev.source);
                }
            );
            this.pdfViewer.eventBus.on('pagechanging',
                () => this.setPage(this.pdfViewer.currentPageNumber)
            );
            this.pdfViewer.eventBus.on('updateviewarea',
                () => {
                    this.viewAreaVersion += 1;
                }
            );

            this.loadDocument();
        });

        this.board = document.querySelector('.ib-preview');
        this.resizeViewerContainer();
    }

    componentWillUnmount () {
        this.pdfViewer && this.pdfViewer.cleanup();
    }

    componentDidUpdate (prevProps) {
        if (this.props.file !== prevProps.file) {
            this.pdfViewer.setDocument(null);
            this.pdfViewer && this.loadDocument();
        }
    }

    loadDocument () {
        this.resetPages();
        this.sourceUrl = this.props.file.fileVersion.download_url;
        this.setDocumentState('loading');
        if (this.spinner) {
            this.spinner.ref.style.display = 'block';
        }
        const documentUrl = Settings.offlinePresentation
            ? Promise.resolve(this.props.file.fileVersion.download_url)
            : $.getJSON(queryString.buildUrl(
                this.props.file.fileVersion.download_url,
                { response_type: 'data', viewable: 'off' }
            ));
        documentUrl
            .then(async data => await this.PDFJS.getDocument(data).promise)
            .then(pdfDocument => {
                this.pdfViewer.setDocument(pdfDocument);
                this.pdfLinkService.setDocument(pdfDocument, null);
                this.setDocumentState('ready');
                this.resizeViewerContainer();
                if (this.spinner) {
                    this.spinner.ref.style.display = 'none';
                }
            })
            .catch(() => {
                this.setDocumentState('error');
            });
    }

    resizeViewerContainer = () => {
        if (!this.container) return;
        this.container.style.maxHeight = `${this.board.clientHeight}px`;
    };

    onPagesInit = () => {
        const isIntro = this.props.screen.slide.layout === 'intro';
        this.pdfViewer.currentScaleValue = 'page-width';
        this.setPage(this.pdfViewer.currentPageNumber);

        if (this.pdfViewer.pagesCount === 1 && !isIntro) {
            this.container.style.overflow = 'hidden';
        } else {
            this.container.style.overflow = 'auto';
        }
    };

    render () {
        return (
            <React.Fragment>
                <Spinner ref={spinner => { this.spinner = spinner; } }/>
                <div
                    id='viewerContainer'
                    style={{ position: 'absolute' }}
                    ref={container => { this.container = container; } }
                >
                    <div id='viewer' className='pdfViewer'/>
                </div>
            </React.Fragment>
        );
    }
});

SimplePDFViewer.propTypes = {
    file: PropTypes.object,
    screen: PropTypes.object
};

SimplePDFViewer.defaultProps = {
    pinType: withPinPoint(DefaultPin, pinPoint)
};

const EditorPDFPinType = withPinPoint(EditorPin, pinPoint);

const EditorPDFViewer = inject('dragMovePinManager')(observer(class EditorPDFViewer extends React.Component {
    constructor (props) {
        super(props);
        this.registerDroppable();
        this.pinContainerRef = makeRef();
        this.pinContainerRef([]);
    }

    componentDidMount () {
        this.props.dragMovePinManager.subscribe(
            this.props.dragMovePinManager.LAYERS.BOARD, this
        );
    }

    componentWillUnmount () {
        this.props.dragMovePinManager.unsubscribe(this.props.dragMovePinManager.LAYERS.BOARD);
    }

    componentDidUpdate (prevProps) {
        if (this.props.dragDropMonitor !== prevProps.dragDropMonitor) {
            this.registerDroppable();
        }
    }

    onDragPinEnd = ({ rect, pin, data }) => {
        const sourceTargetType = data && data.source;
        if (sourceTargetType !== TargetType.MEDIA) return;
        const pinContainer = this.pinContainerRef.current.find(pinContainer =>
            pinContainer && isPointInRect(rect, pinContainer.getBoundingClientRect())
        );
        if (pinContainer) {
            pin.move({
                targetType: TargetType.MEDIA,
                targetId: this.props.file.uuid,
                position: pinPoint.normalized(rect.left, rect.top, pinContainer)
            });
            return true;
        }
    };

    registerDroppable () {
        const viewer = this;
        this.droppable = DroppableTypes.BOARD_PINS.register(
            this.props.dragDropMonitor,
            {
                onCursorUp (ev) {
                    const pinContainer = viewer.pinContainerRef.current.find(pinContainer =>
                        pinContainer && isCursorOverElement(pinContainer, ev)
                    );
                    this.onDropPin(
                        pinContainer,
                        pinPoint,
                        (creator, data) => {
                            return viewer.props.editor.mode.onCursorUp(creator, data, TargetType.MEDIA);
                        }
                    );
                },
                onCursorMove (ev) {
                    return viewer.pinContainerRef.current.some(pinContainer =>
                        pinContainer && isCursorOverElement(pinContainer, ev)
                    );
                }
            }
        );
    }

    render () {
        return (
            <PDFViewer
                {...this.props}
                pinType={EditorPDFPinType}
                pinContainerRef={this.pinContainerRef}
            />
        );
    }
}));

EditorPDFViewer.propTypes = {
    ...PDFViewer.propTypes,
    dragDropMonitor: PropTypes.object,
    dragMovePinManager: PropTypes.object
};

export {
    PDFViewer as PreviewPDFViewer,
    SimplePDFViewer,
    EditorPDFViewer
};

/* eslint-enable */
