import { queryString } from '../lib';
import bootstrapper from '../base/bootstrapper';

class HostNotification {
    static event (event, data = {}) {
        return new HostNotification(`event/${event}`, data);
    }

    static task (taskId, data = {}) {
        return new HostNotification(`task/${taskId}`, data);
    }

    static error (type, data = {}) {
        return new HostNotification('error', {
            error: type,
            ...data
        });
    }

    constructor (path, qs) {
        this.path = path;
        this.qs = qs;
    }

    send () {
        window.location.href = queryString.buildUrl(`inapp://webview.v1/${this.path}`, this.qs);
    }
}

bootstrapper.schedule(() => {
    HostNotification.event('ready').send();
});

export default HostNotification;

window.HostNotification = HostNotification;
