import { observable, makeObservable } from 'mobx';

import { flatten, unflatten } from 'flat';
import { queryString, utils, FilePath } from '../lib';
import assetApi from '../asset/api';

const EMPTY_FILE = {
    isEmpty: true,
    integrationRevoked: false,
    name: '',
    prefix: '',
    previous_versions: [],
    related: [],
    actions: {},
    downloadUrl: '',
    fileType: {
        type: 'unknown'
    },
    ownerInfo: {},
    allVersions: () => [],
    previousVersions: () => [],
    isAvailable: () => true
};

function createEmptyFile (name = '') {
    return Object.assign({}, EMPTY_FILE, { name });
}

function createNotFoundFile (name = '') {
    return Object.assign({}, EMPTY_FILE, { name, isEmpty: false, exists: false });
}

function createIntegrationRevokedFile (name = '') {
    const ownerInfo = { hasPermission: () => false };
    return Object.assign({}, EMPTY_FILE, { name, ownerInfo, isEmpty: false, integrationRevoked: true });
}

class FileGroupIterator {
    cursor;
    static empty () {
        return new FileGroupIterator([], -1);
    }

    static fromAssets (assets, initial) {
        if (!assets) {
            return FileGroupIterator.empty();
        }
        const cursor = assets.findIndex(a => initial.equals(a));
        return new FileGroupIterator(assets, cursor);
    }

    constructor (assets, cursor, filePreview) {
        makeObservable(this, {
            cursor: observable
        });

        this.items = assets.map(asset => {
            return { asset, file: null };
        });
        this.cursor = cursor;
    }

    current () {
        return this.items[this.cursor];
    }

    next () {
        if (this.items.length > 0) {
            this.cursor = (this.cursor + 1) % this.items.length;
            return this.current();
        }
    }

    prev () {
        if (this.items.length > 0) {
            this.cursor = (this.cursor - 1 + this.items.length) % this.items.length;
            return this.current();
        }
    }

    hasNext () {
        return this.items.length > 1;
    }

    hasPrev () {
        return this.items.length > 1;
    }

    previewNext () {
        if (this.items.length > 0) {
            const cursor = (this.cursor + 1) % this.items.length;
            return this.items[cursor];
        }
    }

    previewPrev () {
        if (this.items.length > 0) {
            const cursor = (this.cursor - 1 + this.items.length) % this.items.length;
            return this.items[cursor];
        }
    }

    cacheRequest (asset, promise) {
        if (asset && this.items.length) {
            const cursor = this.items.findIndex(a => {
                return asset.storageType === a.asset.storageType && asset.prefix === a.asset.prefix;
            });
            if (cursor !== -1) {
                this.items[cursor].request = promise;
            }
        }
        return promise;
    };

    cache (file) {
        if (file && this.items.length) {
            const cursor = this.items.findIndex(asset => {
                return file.storage_type === asset.asset.storageType && file.prefix === asset.asset.prefix;
            });
            if (cursor !== -1) {
                this.items[cursor].file = file;
            }
        }
        return file;
    }
}

const fileResourceIdBase = {
    removeFromUrl (location) {
        location = location || window.location;
        const search = (location.search) ? location.search.substring(1) : '';
        const qs = queryString.parse(search);
        const qsWithoutFile = Object.keys(qs).reduce((acc, k) => {
            if (!k.startsWith('file.')) {
                acc[k] = qs[k];
            }
            return acc;
        }, {});
        return location.pathname + queryString.stringify(qsWithoutFile);
    },
    asUrl (location, version = '') {
        location = location || window.location;
        const search = (location.search) ? location.search.substring(1) : '';
        const qs = Object.assign(
            {},
            queryString.parse(search),
            { ...this.buildUrlParams() }
        );
        return location.pathname + queryString.stringify(qs);
    },
    withVersion (location, versionId) {
        location = location || window.location;
        const parsedSearch = queryString.parse(location.search);
        if (versionId) {
            parsedSearch['file.versionId'] = versionId;
        } else {
            delete parsedSearch['file.versionId'];
        }
        const qs = Object.assign(
            {},
            parsedSearch,
            { ...this.buildUrlParams() }
        );
        return location.pathname + queryString.stringify(qs);
    }
};

const fileLinkResourceId = (() => ({
    create ({ uuid, path, options = {}, srcId = '' }) {
        return {
            ...fileResourceIdBase,
            uuid,
            path: path.replace(/\/*$/, ''),
            name: FilePath(path).name(),
            isLink: true,
            options,
            srcId,
            load () {
                return assetApi.getFromLink(this.uuid, this.path, this.srcId);
            },
            buildUrlParams () {
                const params = flatten({
                    file: {
                        type: 'link',
                        uuid: this.uuid,
                        path: this.path,
                        options,
                        ...(srcId ? { srcId: this.srcId } : {})
                    }
                });
                return this.path
                    ? Object.keys(params).reduce((acc, k) => {
                        acc[window.encodeURIComponent(k)] = params[k];
                        return acc;
                    }, {})
                    : {};
            }
        };
    }
}))();

const fileResourceId = (() => {
    function create ({ storageType, owner, path, isShared, options = {}, srcId = '' }) {
        return {
            ...fileResourceIdBase,
            storageType,
            owner,
            path: path.replace(/\/*$/, ''),
            name: FilePath(path).name(),
            isShared: utils.stringToBool(isShared),
            options,
            srcId,
            load () {
                return assetApi.get(this.storageType, this.owner, this.path, this.isShared, this.srcId);
            },
            buildUrlParams () {
                return flatten({
                    file: {
                        type: 'file',
                        storageType: this.storageType,
                        owner: this.owner,
                        path: this.path,
                        isShared: this.isShared,
                        options,
                        ...(srcId ? { srcId: this.srcId } : {})
                    }
                });
            }
        };
    }

    return {
        fromUrl (location) {
            location = location || window.location;
            const search = (location.search) ? location.search.substring(1) : '';
            const qs = unflatten(queryString.parse(search) || {});
            if (qs.file) {
                const params = qs.file;
                const factory = (params.type === 'link')
                    ? fileLinkResourceId.create
                    : (params.type === 'file')
                        ? create
                        : null;
                return factory && factory(params);
            }
        },
        fromAsset (asset, options = {}) {
            const { storageType, owner, prefix, ownerInfo, srcId } = asset;
            const link = ownerInfo.link;
            return link
                ? fileLinkResourceId.create({ uuid: link.uuid, path: prefix, srcId })
                : create({ storageType, owner, path: prefix, isShared: ownerInfo.isShared, srcId });
        },
        fromParams (params, options = {}) {
            return create({ ...params, options });
        },
        fromLink (uuid, path, options = {}, srcId = '') {
            return fileLinkResourceId.create({ uuid, path, options, srcId });
        }
    };
})();

const defaultPreview = {
    next () {},
    prev () {},
    reload () {},
    options: {}
};

export {
    createEmptyFile,
    createNotFoundFile,
    createIntegrationRevokedFile,
    FileGroupIterator,
    fileResourceId,
    defaultPreview
};
