import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DefaultPalette } from '@vectorworks/vcs-ui/dist/lib/ColorPicker/DefaultPalette';
import { CustomColorsPalette } from '@vectorworks/vcs-ui/dist/lib/ColorPicker/CustomColorsPalette';
import { CustomColorPicker } from '@vectorworks/vcs-ui/dist/lib/ColorPicker/CustomColorPicker';
import { DEFAULT_COLORS, RGBColor } from '@vectorworks/vcs-ui/dist/lib/ColorPicker/utils';

import { InputLabelField } from '@vectorworks/vcs-ui/dist/lib/Input/Text/Input';
import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';

import api from './api';

const S = {
    Wrapper: styled.div`
        width: 230px;
        padding: 7px;
    `,
    CustomColors: styled.div`
        border-top: 1px solid var(--border-color);
        margin-top: 7px;
        padding: 7px 0;

        > label {
            display: block;
        }
    `,
    CustomColorPicker: styled(CustomColorPicker)`
        border-top: 1px solid var(--border-color);
        padding: 7px 0;
        /* margin-top: 7px; */
    `,
    Header: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
`
};

class ApiColor {
    static fromApi (data) {
        return new ApiColor(data);
    }

    constructor (data) {
        Object.assign(this, data);
        this.color = RGBColor.fromObject(data.color);
    }

    compare (...args) {
        return this.color.compare(...args);
    }
}

const CustomColors = ({ selectedColor, onChange }) => {
    const [customColors, setCustomColors] = useState([]);

    useEffect(() => {
        api.list()
            .then(({ results }) => setCustomColors(results.map(ApiColor.fromApi)));
    }, []);

    const saveCustomColor = () => {
        if (![...DEFAULT_COLORS, ...customColors].find(c => c.compare(selectedColor))) {
            api.create(selectedColor.toObject())
                .then((newColor) => setCustomColors(p => [...p, ApiColor.fromApi(newColor)]));
        }
    };

    const deleteCustomColor = () => {
        api.delete(customColors.find(c => c.compare(selectedColor)).id)
            .then(() => setCustomColors(c => c.filter(cc => !cc.compare(selectedColor))));
    };

    return (
        <S.CustomColors>
            <S.Header>
                <InputLabelField labelText={gettext('Saved colors')}/>

                {
                    customColors.find(c => c.compare(selectedColor)) && (
                        <IconButton onClick={deleteCustomColor}>
                            <Icon icon='trash' size='sm' />
                        </IconButton>
                    )
                }
            </S.Header>

            <CustomColorsPalette
                selectedColor={selectedColor}
                onChange={onChange}
                customColors={customColors.map(c => c.color)}
            />

            <Button
                variant='secondary'
                onClick={saveCustomColor}
                style={{
                    width: '100%',
                    margin: '7px 0',
                    lineHeight: '20px'
                }}
            >
                {gettext('Save color')}
            </Button>
        </S.CustomColors>
    );
};

CustomColors.propTypes = {
    selectedColor: PropTypes.instanceOf(RGBColor),
    onChange: PropTypes.func
};

export default function ColorPicker ({
    selectedColor,
    onChange
}) {
    return (
        <S.Wrapper>
            <DefaultPalette
                selectedColor={selectedColor}
                onChange={onChange}
            />
            <CustomColors
                selectedColor={selectedColor}
                onChange={onChange}
            />
            <S.CustomColorPicker
                selectedColor={selectedColor}
                onChange={onChange}
            />
        </S.Wrapper>
    );
}

ColorPicker.propTypes = {
    selectedColor: PropTypes.instanceOf(RGBColor),
    onChange: PropTypes.func
};
