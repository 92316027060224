import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { TextOverflow } from '@vectorworks/vcs-ui/dist/lib/styles/common';
import { Spinner } from '@vectorworks/vcs-ui/dist/lib/Loading';

import iboardsApi from '../../api';
import { LinkPin as BoardLinkPin } from '../../models/board';
import { LinkPin as TourLinkPin } from '../../models/tour';
import { InfoSection, Thumbnail } from '../../Carousel/Items/common';

const S = {
    Wrapper: styled.div`
        width: 360px;
        padding: 8px;

        display: flex;
        flex-direction: column;

        /* Because of the <InfoSection />, being snapped to the bottom */
        position: relative;
    `,
    Title: styled.div`
        display: flex;
        align-items: center;

        font-size: 16px;
        font-weight: bold;

        margin-bottom: 8px;
    `,
    Favicon: styled.img`
        max-width: 16px;
        max-height: 16px;
        margin-right: 7px;
        flex-shrink: 0;
    `,
    Content: styled.div`
        display: flex;
        align-items: center;
    `,
    TextContent: styled.div`
        display: flex;
        flex-direction: column;
        min-width: 0;
    `,
    Description: styled.div`
        flex: 1;
        margin-bottom: 30px;
        overflow: hidden;
        color: var(--text-secondary-color);
    `,
    ThumbnailContainer: styled.div`
        width: 90px;
        height: 90px;
        flex-shrink: 0;
    `,
    Skeleton: styled.div`
        width: 100%;
        height: 90px;
        margin-bottom: 30px;

        display: flex;
        align-items: center;
        justify-content: center;
    `
};

const cache = {};

const Link = ({ pin }) => {
    const [previewData, setPreviewData] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const {
        url = pin.linkState.link,
        title = pin.title,
        description = '',
        images = [],
        favicons = []
    } = previewData;

    const MAX_DESCRIPTION_LENGTH = 160;
    const desc = description.slice(0, MAX_DESCRIPTION_LENGTH) + (description.length > MAX_DESCRIPTION_LENGTH ? ' ...' : '');

    React.useEffect(() => {
        let { request, result } = cache[pin.linkState.link] || {};
        if (result) {
            setPreviewData(result);
            return;
        } else if (!request) {
            request = iboardsApi.request({
                url: `${Settings.IBOARDS_API_URL}/link-preview/?url=${encodeURIComponent(pin.linkState.link)}`
            });
            cache[pin.linkState.link] = { request };
        }
        setLoading(true);
        request
            .then(result => {
                cache[pin.linkState.link] = { result };
                setPreviewData(result);
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <S.Wrapper>
            <S.Title>
                { favicons.length > 0 && <S.Favicon src={favicons[0]} />}
                <TextOverflow><a href={url} target={pin.linkState.inNewTab ? '_blank' : '_self'} rel='noreferrer'>{ url }</a></TextOverflow>
            </S.Title>
            { !loading
                ? (
                    <S.Content>
                        <S.TextContent>
                            <S.Title>
                                <TextOverflow>{title}</TextOverflow>
                            </S.Title>
                            <S.Description>{desc}</S.Description>
                        </S.TextContent>

                        { images.length > 0
                            ? (
                                <S.ThumbnailContainer>
                                    <Thumbnail
                                        thumbnail={images[0]}
                                        aspectRatio={1 / 1}
                                        style={{ flex: 1 }}
                                    />
                                </S.ThumbnailContainer>
                            )
                            : null}
                    </S.Content>
                )
                : (
                    <S.Skeleton>
                        <Spinner />
                    </S.Skeleton>
                )}

            <InfoSection>
                <div>{ pin.title }</div>
            </InfoSection>
        </S.Wrapper>
    );
};

Link.propTypes = {
    pin: PropTypes.oneOfType([BoardLinkPin, TourLinkPin])
};

export default Link;
