import { loadGooglePages } from '../Sidebar/Integrations/api';

export const GOOGLE_PAGES_NAMES = {
    MY_DRIVE: {
        id: 'myDrive',
        name: gettext('My Drive')
    },
    SHARED_WITH_ME: {
        id: 'sharedWithMe',
        name: gettext('Shared with me')
    }
};

function trimSlashes (input) {
    return input.replace(/(^\/+|\/+$)/mg, '');
}

export function parseSharedDriveLookup (folder = '') {
    let driveId = '';
    let path = folder.replace(/^\/+|\/+$/g, '');

    if (folder.startsWith('driveId_')) {
        const driveIdLookup = folder.split('/', 1)[0];
        driveId = driveIdLookup.match(/driveId_([\w-$!]+)/)[1];
        path = trimSlashes(folder.slice(driveIdLookup.length));
    }

    return [driveId, path];
}

export function isSharedWithMeRoot (input) {
    const [driveId, path] = parseSharedDriveLookup(input);
    return driveId.startsWith('sharedWithMe') && !path;
}

export function previewPath (initialPath = '') {
    const [driveId, path] = parseSharedDriveLookup(initialPath);

    const previewName = driveId && driveId !== GOOGLE_PAGES_NAMES.MY_DRIVE.id
        ? driveId.startsWith(GOOGLE_PAGES_NAMES.SHARED_WITH_ME.id)
            ? GOOGLE_PAGES_NAMES.SHARED_WITH_ME.name
            : (
                loadGooglePages(true).find(sd => sd.id === driveId) ||
                { name: withDriveId('', driveId) }
            ).name
        : GOOGLE_PAGES_NAMES.MY_DRIVE.name;

    return [previewName, path]
        .map(trimSlashes)
        .filter(part => !!part)
        .join('/');
}

export function withDriveId (_path, driveId) {
    const path = trimSlashes(_path);
    return trimSlashes(
        !driveId || driveId === 'myDrive'
            ? path
            : driveId === 'sharedWithMe'
                ? `driveId_sharedWithMe${Settings.user.nvwuid.replace(/-/g, '')}/${path}`
                : `driveId_${driveId}/${path}`
    );
}
