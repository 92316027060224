import axios from 'axios';

const events = {
    CONNECT: 'connect',
    DISCONNECT: 'disconnect',
    RECONNECT: 'reconnect',
    MESSAGE: 'message',
    ERROR: 'error'
};

class MessengerClient {
    constructor (pingIntervalMs = 300000, pongWaitMs = 5000, reconnectWaitMs = 5000, maxReconnects = 10) {
        this.socket = null;
        // WebSocket doesn't allow setting on* callbacks before opening the connection
        // so we store them for later use
        this.callbacks = {};
        this.topics = new Set();
        this.pingIntervalMs = pingIntervalMs;
        this.pongWaitMs = pongWaitMs;
        this.reconnectWaitMs = reconnectWaitMs;
        this.reconnects = 0;
        this.maxReconnects = maxReconnects;
        this.fatalDisconnect = false;
        this.initCallbacks();
    };

    generateCommand = (request_id, command) => {
        return {
            packet_type: 'command',
            request_id,
            command
        };
    };

    generateId = () => [...Array(32)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

    connect = async (isReconnect = false) => {
        this.topics = new Set();
        try {
            const url = await this.generateToken();
            this.socket = new window.WebSocket(url);
            this.setup(isReconnect);
        } catch (error) {
            this.callbacks[events.ERROR](error);
        }
    };

    reconnect = () => {
        console.warn('[messenger] reconnect');
        const isReconnect = true;
        this.connect(isReconnect);
    };

    abort = error => {
        console.error('[messenger] abort', error);
        this.fatalDisconnect = true;
        this.callbacks[events.ERROR](error);
        this.socket.close();
    };

    subscribe = newTopics => {
        let updated = false;
        for (const topic of newTopics) {
            if (!this.topics.has(topic)) {
                updated = true;
                this.topics.add(topic);
            }
        }
        if (!updated) {
            return;
        }

        console.log('[messenger] subscribe', Array.from(this.topics));
        const id = this.generateId();
        const command = this.generateCommand(id, {
            command_type: 'subscribe',
            topic_ids: Array.from(this.topics)
        });
        this.socket.send(JSON.stringify(command));
    };

    on = (event, callback) => {
        this.callbacks[event] = callback;
    };

    initCallbacks = () => {
        Object.values(events).forEach(event => {
            this.callbacks[event] = () => {
                console.warn(`[messenger] ${event} event callback not defined`);
            };
        });
    };

    setup = isReconnect => {
        this.socket.addEventListener('open', () => {
            console.log(`[messenger] open; reconnect=${isReconnect}`);
            isReconnect
                ? this.callbacks[events.RECONNECT]()
                : this.callbacks[events.CONNECT]();
        });

        this.socket.addEventListener('close', () => {
            console.warn('[messenger] closed');
            this.callbacks[events.DISCONNECT]();
        });

        this.socket.addEventListener('message', msg => {
            const msgData = JSON.parse(msg.data);
            if (msgData.packet_type === 'message') {
                this.callbacks[events.MESSAGE](msgData.topic_id, msgData.data);
            } else if (msgData.packet_type === 'error') {
                this.abort(msgData.error);
            } else if (msgData.packet_type === 'ack' && msgData.request_id === this.lastPingId) {
                this.waitForPong = false;
            }
        });

        this.socket.addEventListener('error', err => {
            console.error('[messenger] error', err);
            this.callbacks[events.ERROR](err);
        });

        setTimeout(this.healthCheck, this.pingIntervalMs);
    };

    ping = () => {
        const id = this.generateId();
        const command = this.generateCommand(id, {
            command_type: 'ping'
        });
        if (this.socket.readyState === window.WebSocket.OPEN) {
            this.lastPingId = id;
            this.socket.send(JSON.stringify(command));
        }
    };

    waitMs = ms => {
        return new Promise((resolve, reject) => setTimeout(resolve, ms));
    };

    healthCheck = async () => {
        while (true) {
            if (this.fatalDisconnect) {
                break;
            }
            this.ping();
            this.waitForPong = true;
            await this.waitMs(this.pongWaitMs);
            if (this.waitForPong) {
                this.waitForPong = false;
                this.reconnects += 1;
                if (this.reconnects >= this.maxReconnects) {
                    this.abort('Max websocket reconnects reached');
                } else {
                    await this.waitMs(this.reconnectWaitMs);
                    this.reconnect();
                }
                break;
            }
            await this.waitMs(this.pingIntervalMs);
        }
    };

    generateToken = async () => {
        return axios.get('/restapi/public/v1/messenger/presigned-uri/?app_name=portal')
            .then(result => {
                return result.uri;
            });
    };
}

export default MessengerClient;
