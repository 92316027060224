import ReactDOM from 'react-dom';
import React from 'react';

import { DialogContainer } from '../Components/Dialog/Dialog';
import DialogStore from '../Components/Dialog/Store';

window.ReactDialogs = (() => {
    let loaded = false;
    const store = new DialogStore();

    const load = () => {
        if (!loaded) {
            ReactDOM.render(
                <DialogContainer dialogContainer={store} />,
                document.getElementById('dialogs-react')
            );
            loaded = true;
        }
    };

    return {
        open (params) {
            load();
            return store.open(params);
        },
        pop () {
            return store.pop();
        },
        dismissAll (reason) {
            return store.dismissAll(reason);
        }
    };
})();
