import React, { Component } from 'react';

import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import * as zip from '@zip.js/zip.js';

import { commonPropTypes } from './utils';
import { Loader } from '~static/file-viewer/panorama/loader';
import { DefaultController } from './Controllers/Default';
import LegacyVGXViewer from './VGXViewer';
import UnityVGXViewer, { OpenInNomad } from './UnityVGXViewer';
import { queryString } from '~static/js/lib';

const urlCreator = window.URL || window.webkitURL;

function detectNewVGX (blob) {
    const reader = new zip.ZipReader(new zip.BlobReader(blob));
    return reader.getEntries()
        .then(entries => entries.some(entry => /\.(gltf|glb)$/.test(entry.filename)))
        .catch(err => {
            console.error(err);
            return false;
        })
        .finally(() => reader.close());
};

class VGXLoader extends Component {
    name = 'VGXLoader';
    sourceUrl = '';
    blobUrl = null;

    constructor (props) {
        super(props);
        this.state = {
            blobUrl: null,
            isLegacy: 'unknown',
            turnOffMobileUnity: window.Settings.device.isMobile &&
                window.Settings.NEW_FEATURES.isActive('turnOffMobileUnity')
        };
    };

    componentDidMount () {
        this.autorunDisposer = autorun(() => this.props.store.file.sourceUrl && this.loadFile());
        this.loadFile();
    };

    componentWillUnmount () {
        this.cleanupBlobUrl();
        this.autorunDisposer && this.autorunDisposer();
    }

    cleanupBlobUrl () {
        if (this.blobUrl) {
            urlCreator.revokeObjectURL(this.blobUrl);
            this.blobUrl = null;
        }
    }

    loadFile = () => {
        if (this.sourceUrl === this.props.store.file.sourceUrl) {
            return;
        }
        this.sourceUrl = this.props.store.file.sourceUrl;
        this.cleanupBlobUrl();
        this.setState({ blobUrl: null, isLegacy: 'unknown' });
        this.props.store.startLoading();
        (
            Settings.offlinePresentation
                ? Promise.resolve(this.props.store.file.offlineSourceUrlResp)
                : $.getJSON(queryString.buildUrl(
                    this.props.store.file.sourceUrl,
                    {
                        response_type: 'data',
                        viewable: 'off'
                    }
                ))
        ).catch(err => {
            if (err?.status === 404) {
                this.props.store.file.setNotFoundFile();
            }
        }).then(data => {
            this.loadVGX(data.url);
        });
    };

    loadVGX = (vgxUrl) => {
        const loader = new Loader();
        loader.setResponseType('blob');
        loader.crossOrigin = '';

        loader.load(
            vgxUrl,
            vgxBlob => {
                detectNewVGX(vgxBlob)
                    .then(isNew => this.showVGX(!isNew, vgxBlob));
            },
            ({ loaded, total }) => {}
        );
    };

    showVGX = (isLegacy, vgxBlob) => {
        this.blobUrl = urlCreator.createObjectURL(vgxBlob);
        this.setState({ blobUrl: this.blobUrl, isLegacy });
    };

    renderViewer = () => {
        const { children } = this.props;

        return this.state.isLegacy
            ? <React.Fragment>
                <LegacyVGXViewer
                    {...this.props}
                    sourceUrl={this.state.blobUrl}
                    ref={instance => {
                        this.viewer = instance;
                    }}
                >
                    {children}
                </LegacyVGXViewer>
            </React.Fragment>
            : <React.Fragment>
                {
                    this.state.turnOffMobileUnity
                        ? <OpenInNomad
                            {...this.props}
                            ref={instance => {
                                this.viewer = instance;
                            }}
                        >
                            {children}
                        </OpenInNomad>
                        : <UnityVGXViewer
                            {...this.props}
                            sourceUrl={this.state.blobUrl}
                            ref={instance => {
                                this.viewer = instance;
                            }}
                        >
                            {children}
                        </UnityVGXViewer>
                }
            </React.Fragment>;
    };

    render () {
        return this.state.isLegacy === 'unknown'
            ? <React.Fragment>
                <div className='fileview-component-loader' data-what='file-viewer'>
                    <div className='vgx-loader-loading lds--container'>
                        <div className='lds lds--large'/>
                        <span id='loading-title'>{gettext('Downloading file')}</span>
                    </div>
                </div>
                <DefaultController {...this.props} />
            </React.Fragment>
            : this.renderViewer();
    }
};

export default observer(VGXLoader);

VGXLoader.propTypes = {
    ...commonPropTypes
};
