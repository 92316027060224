import { request } from '../base/axios';
import { Account } from '@vectorworks/vcs-ui/dist/lib/utils/account/account';

const loadCollaborators = callback =>
    request({
        url: '/restapi/public/v2/trusted-accounts/',
        method: 'GET'
    });

let collaborators = null;
const createCollaboratorsLoader = () =>
    () => collaborators
        ? Promise.resolve(collaborators)
        : loadCollaborators()
            .then(data => {
                collaborators = data.map(u => new Account(u));
                return collaborators;
            });

export default {
    createCollaboratorsLoader,
    loadCollaborators
};
