import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Layouts from '../../Layouts/Preview';

import ErrorBoundary from '../../common/ErrorBoundary';
import AspectRatio from '../../common/AspectRatio';
import BrandingLogo from '../../../branding/BrandingLogo';

class BoardViewer extends React.Component {
    get branding () {
        return this.props.store.brandingImage;
    }

    render () {
        const Layout = Layouts[this.props.preview.slide.layout] || null;

        return (
            <React.Fragment>
                <ErrorBoundary title={gettext('Something went wrong with your board!')}>
                    <div className='slide-type-indicator'>
                        <span className='icon icon-board'/>
                    </div>

                    <AspectRatio selector='#presentation-screen'>
                        <div id='presentation-screen'>
                            <BrandingLogo branding={ this.branding } />
                            <Layout
                                store={this.props.store}
                                slide={this.props.preview.slide}
                                preview={this.props.preview}
                                previewContainer={this.props.previewContainer}
                            />
                        </div>
                    </AspectRatio>
                </ErrorBoundary>
            </React.Fragment>
        );
    }
};

BoardViewer.propTypes = {
    store: PropTypes.object.isRequired,
    preview: PropTypes.object.isRequired,
    previewContainer: PropTypes.object
};

export default observer(BoardViewer);
