import { autorun } from 'mobx';

export default {
    cache: new Map(),
    withUserPrefix: key => `${Settings.user.login}.${key}`,
    get (key, defaultVal = undefined) {
        if (!this.cache.has(key)) {
            this.cache.set(key, window.store.get(
                this.withUserPrefix(key), defaultVal
            ));
        }
        return this.cache.get(key);
    },
    set (key, value) {
        this.cache.set(key, value);
        window.store.set(this.withUserPrefix(key), value);
    },
    syncWith (obj, name, props) {
        props.forEach(p => {
            const val = this.get(`${name}.${p}`);
            if (val !== undefined) {
                obj[p] = val;
            }
        });

        return autorun(() => {
            props.forEach(p => {
                const key = `${name}.${p}`;
                if (obj[p] !== this.get(key)) {
                    this.set(key, obj[p]);
                }
            });
        }, {
            name: 'syncToLocalStorage',
            delay: 500
        });
    }
};
