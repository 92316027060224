import iboardsApi from '../../api';

const request = iboardsApi.request;

export default {
    list () {
        return request({
            url: `${Settings.IBOARDS_API_URL}/custom-colors/`
        });
    },
    create (color) {
        return request({
            url: `${Settings.IBOARDS_API_URL}/custom-colors/`,
            method: 'POST',
            data: { color },
            dataType: 'json'
        });
    },
    delete (id) {
        return request({
            url: `${Settings.IBOARDS_API_URL}/custom-colors/${id}/`,
            method: 'DELETE'
        });
    }
};
