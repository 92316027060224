import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import CollectionStore from '../Stores/Collection';
import EmptyMessage from './EmptyMessage';

const Item = observer((props) => {
    let drag = null;
    let hold = false;
    let timer = null;

    const stopPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    };

    const onClickItem = (e, callback) => {
        stopPropagation(e);
        props.collectionStore.onClickItem(e, props.item, callback);
    };

    const onRightClick = e => {
        props.collectionStore.onRightClick(e, props.item);
    };

    const handlePress = (e, item) => {
        stopPropagation(e);
        timer = setTimeout(() => {
            hold = true;
            !drag && props.collectionStore.enterMobileSelectionMode(item);
        }, 1000);
    };

    const handleRelease = (e, callback) => {
        stopPropagation(e);
        !drag && !hold && onClickItem(e, callback);
        cleanUp();
    };

    const handleDrag = () => {
        drag = true;
    };

    const cleanUp = () => {
        clearInterval(timer);
        hold = false;
        drag = false;
    };

    const clickEvents = (item, callback) => {
        return props.collectionStore.mobileMode
            ? {
                onTouchStart: e => handlePress(e, item),
                onTouchEnd: e => handleRelease(e, callback),
                onContextMenu: e => stopPropagation(e),
                onTouchMove: handleDrag
            }
            : {
                onClick: onClickItem,
                onContextMenu: onRightClick,
                onDoubleClick: callback
            };
    };

    return props.renderItem({
        isValid: props.item.isValid,
        data: props.item.data,
        isSelected: props.item.isSelected,
        collectionStore: props.collectionStore,
        doAction: props.item.doAction.bind(props.item),
        clickEvents: clickEvents.bind(this)
    });
});

Item.propTypes = {
    collectionStore: PropTypes.instanceOf(CollectionStore).isRequired,
    item: PropTypes.object,
    renderItem: PropTypes.func.isRequired
};

class Collection extends React.Component {
    render () {
        return (
            <React.Fragment>
                {
                    this.props.emptyMessage && !this.props.collectionStore.items.length &&
                    <EmptyMessage {...this.props.emptyMessage} />
                }
                {this.props.collectionStore.items.map(item =>
                    <Item
                        collectionStore={this.props.collectionStore}
                        item={item}
                        key={item.data.id}
                        renderItem={this.props.renderItem}
                    />
                )}
            </React.Fragment>
        );
    }
}

Collection.propTypes = {
    collectionStore: PropTypes.instanceOf(CollectionStore).isRequired,
    renderItem: PropTypes.func.isRequired,
    emptyMessage: PropTypes.object,
    itemsFilterQuery: PropTypes.string
};

export default observer(Collection);
export { Item };
