import { utils } from '../lib';

function ssoApiAjax (type, apiUrl, data, successCallback, failCallback) {
    const jsonData = data.length > 0 ? JSON.stringify(utils.arrayToObject(data)) : '';
    ssoApiAjaxWithJson(type, apiUrl, jsonData, successCallback, failCallback);
}

function ssoApiAjaxWithJson (type, apiUrl, jsonData, successCallback, failCallback) {
    $.ajax({
        type,
        url: Settings.SSO_SERVER + apiUrl,
        data: jsonData,
        contentType: 'application/json',
        xhrFields: {
            // doesn't work on IE 8
            withCredentials: true
        },
        beforeSend: function (request) {
            request.setRequestHeader('X-CSRFToken', getCookie(Settings.SSO_CSRF_COOKIE_NAME));
        }
    }).done(successCallback).fail(failCallback);
}

function getCookie (name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = jQuery.trim(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function login (next) {
    if (next && !(/^https?:\/\//i).test(window.decodeURIComponent(next))) {
        next = window.encodeURIComponent(
            window.location.origin + window.decodeURIComponent(next)
        );
    }
    const qs = next ? `?${Settings.SSO_NEXT_ARG}${next}` : '';
    window.location.href = `${Settings.SSO_SERVER}/accounts/login/${qs}`;
}

function logout () {
    ssoApiAjax('POST', Settings.SSO_LOGOUT_API_URL, [], requestDone, requestDone);

    function requestDone (data) {
        window.location.href = Settings.PORTAL_PREFIX;
    }
}

export default {
    ssoApiAjax,
    ssoApiAjaxWithJson,
    login,
    logout,
    getCookie
};
