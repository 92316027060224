const ua = window.navigator.userAgent;

const is = {
    IE11: () => {
        // IE 11
        // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
        return ua.indexOf('Trident/') > 0;
    },
    Edge: () => {
        // Edge 12 (Spartan)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

        // Edge 13
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';
        return ua.indexOf('Edge/') > 0;
    },
    Safari: () => {
        const is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
        let is_safari = navigator.userAgent.indexOf('Safari') > -1;
        if ((is_chrome) && (is_safari)) { is_safari = false; }
        return is_safari;
    },
    Opera: () => {
        // Opera 47
        // ua = 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.90 Safari/537.36 OPR/47.0.2631.80'
        /* eslint-disable */
        return (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        /* eslint-enable */
    }
};

export { is };
