function parsePath (path) {
    const match = /(?<uuid>([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}|[a-f0-9]{32})|([a-zA-z0-9]{16}))(\/(?<assetType>folder)\/(?<lookup>.*))?\/$/.exec(path);
    return match && match.groups;
}

function folderFromLookup (lookup) {
    return decodeURIComponent(lookup).replace(/^p:/, '').replace(/\/*$/, '');
}

function getBase (path, linkUuid) {
    return path.substr(0, path.indexOf(linkUuid));
}

function isLinkView () {
    return window.location.pathname.startsWith('/links') &&
        parsePath(window.location.pathname);
}

export {
    parsePath,
    folderFromLookup,
    getBase,
    isLinkView
};
