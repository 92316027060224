import { utils, FileTypes } from '../lib';
import { request } from '../base/axios';

const OWN_FILE_OWNER_INFO = {
    owner: Settings.user.login,
    owner_email: '',
    owner_name: '',
    upload_prefix: Settings.user.uploadPrefix,
    region: Settings.user.region,
    has_joined: false,
    permission: [],
    date_created: new Date(),
    shared_parent_folder: '',
    mount_point: null
};

function getShortCode (file) {
    return request({
        url: '/restapi/public/v2/code/:create/',
        data: {
            node_link: file.uuid,
            asset: {
                src_id: file.asset.src_id || file.asset.srcId,
                path: file.asset.prefix,
                link_uuid: file.uuid,
                is_folder: false
            }
        },
        method: 'POST'
    }).then(data => data);
}

class OwnerInfo {
    static own () {
        return new OwnerInfo(OWN_FILE_OWNER_INFO, false);
    }

    static fromLink (data) {
        const ownerInfo = new OwnerInfo(
            Object.assign({}, data, {
                permission: ['download'],
                branding: data.branding
            }),
            true
        );
        ownerInfo.link = {
            expires: data.expires,
            uuid: data.uuid,
            link: data.link
        };
        return ownerInfo;
    }

    static create (data, isShared = false) {
        return new OwnerInfo(data, isShared);
    }

    static fromLogin (login) {
        return login === Settings.user.login
            ? OwnerInfo.own()
            : new OwnerInfo({
                owner: login,
                owner_email: '',
                owner_name: '',
                upload_prefix: login,
                has_joined: false,
                permission: [],
                date_created: new Date(),
                shared_parent_folder: '',
                mount_point: null
            });
    }

    constructor (data, isShared = false) {
        this.isShared = isShared || data.owner !== Settings.user.login;
        this.owner = data.owner;
        this.ownerEmail = data.owner_email;
        this.ownerName = data.owner_name || data.owner;
        this.uploadPrefix = data.upload_prefix || data.uploadPrefix;
        this.region = data.region || data.owner_region || 'default';
        this.hasJoined = data.has_joined;
        this.permission = data.permission;
        this.shareDate = data.date_created;
        this.formatedShareDate = utils.formatDate(data.date_created);
        this.sharedParentFolder = data.shared_parent_folder;
        this.mountPoint = data.mount_point;
        this.branding = this.owner === Settings.user.login ? Settings.user.branding : data.branding;
    }

    mount (mountPoint) {
        const result = utils.copyObject(this);
        result.mountPoint = mountPoint;
        return result;
    }

    unmount () {
        const result = utils.copyObject(this);
        result.mountPoint = null;
        return result;
    }

    hasPermission (permission) {
        return this.permission.includes(permission);
    }
}

const NOT_SHARED = {
    allow_comments: true,
    is_shared: false,
    link: null,
    link_uuid: null,
    link_expires: null,
    shared_with: [],
    resource_uri: '',
    last_modified: null
};

const DATE_MAX = '9999-12-31T23:59:59Z';
const DATE_MIN = '0000-00-00T00:00:00Z';

function _parseExpireFromApi (link_expires) {
    return (link_expires !== DATE_MAX) ? link_expires : null;
}

class SharingError {
    constructor (action, data, items, error) {
        this.type = 'sharing_error';
        this.action = action;
        this.data = data;
        this.items = items;
        this.error = error;
    }
}

class SharedWith {
    constructor (data) {
        this.email = data.email;
        this.login = data.login;
        this.username = data.username;
        this.permissions = data.permissions;
        this.hasJoined = data.has_joined;
    }

    asJson () {
        return {
            email: this.email,
            login: this.login,
            username: this.username,
            permissions: this.permissions,
            has_joined: this.hasJoined
        };
    }
}

class SharingInfo {
    static create (data, link = null) {
        const result = new SharingInfo(data || NOT_SHARED);
        if (link) {
            result.link = link.link;
            result.linkUuid = link.linkUuid;
            result.linkExpires = link.linkExpires;
            result.linkVisitsCount = link.linkVisitsCount;
        }
        return result;
    }

    static fromSharedInfo (data) {
        return new SharingInfo({
            is_shared: !!data.shared_with_info.length,
            link: data.shareable_link,
            link_uuid: data.shareable_link_uuid,
            link_expires: data.shareable_link_expires,
            link_visits_count: data.shareable_link_visits_count,
            shared_with: data.shared_with_info,
            resource_uri: data.resource.resource_uri,
            last_share_date: data.last_share_date || DATE_MIN
        });
    }

    constructor (data) {
        this.allowComments = data.allow_comments;
        this.isShared = data.is_shared;
        this.link = data.link;
        this.linkUuid = data.link_uuid;
        this.linkExpires = _parseExpireFromApi(data.link_expires);
        this.linkVisitsCount = data.link_visits_count;
        this.sharedWith = (data.shared_with || []).map(sw => new SharedWith(sw));
        this.resourceUri = data.resource_uri;
        this.lastShareDate = data.last_share_date;
        this.formatedLastShareDate = utils.formatDate(data.last_share_date);
    }

    share () {
        const result = utils.copyObject(this);
        result.isShared = true;
        return result;
    }

    unshare (removeLink = false) {
        const result = SharingInfo.create();
        if (!removeLink) {
            result.link = this.link;
            result.linkUuid = this.linkUuid;
            result.linkExpires = this.linkExpires;
        }
        return result;
    }

    createLink (url) {
        const result = utils.copyObject(this);
        result.link = url;
        result.linkUuid = url.split('/').slice(-2, -1)[0];
        result.linkExpires = null;
        result.linkVisitsCount = 0;
        result.allowComments = true;
        return result;
    }

    updateLink (data) {
        const result = utils.copyObject(this);
        const expire = _parseExpireFromApi(data.expires);
        return Object.assign(result, {
            linkExpires: expire,
            allowComments: data.allow_comments
        });
    }

    removeLink () {
        const result = utils.copyObject(this);
        result.link = null;
        result.linkUuid = null;
        result.linkExpires = null;
        result.linkVisitsCount = null;
        return result;
    }

    shareWith (data) {
        const result = utils.copyObject(this).unshareWith(data);
        result.sharedWith.push(new SharedWith(data));
        result.isShared = true;
        return result;
    }

    unshareWith (data) {
        const result = utils.copyObject(this);

        if (data.login) {
            result.sharedWith = result.sharedWith
                .filter(sw => sw.login !== data.login);
        } else {
            result.sharedWith = result.sharedWith
                .filter(sw => sw.email !== data.email);
        }

        result.isShared = !!result.sharedWith.length;
        return result;
    }

    getLink () {
        return {
            link: this.link,
            linkUuid: this.linkUuid,
            linkExpires: this.linkExpires,
            linkVisitsCount: this.linkVisitsCount
        };
    }

    asJson () {
        return {
            is_shared: this.isShared,
            link: this.link,
            link_uuid: this.linkUuid,
            link_expires: this.linkExpires,
            link_visits_count: this.linkVisitsCount,
            shared_with: this.sharedWith.map(sw => sw.asJson()),
            allow_comments: this.allowComments,
            resource_uri: this.resourceUri,
            last_share_date: this.lastShareDate
        };
    }
}

class SharePermission {
    static forAsset (asset) {
        return asset.isFolder
            ? asset.storageType === Settings.DEFAULT_STORAGE
                ? [
                    SharePermission.VIEW_AND_DOWNLOAD_PERMISSION,
                    SharePermission.COLLABORATION_PERMISSION
                ]
                : [
                    SharePermission.VIEW_AND_DOWNLOAD_PERMISSION
                ]
            : asset.fileType.type === FileTypes.vwx
                ? [
                    SharePermission.VIEW_AND_DOWNLOAD_PERMISSION
                ]
                : [
                    SharePermission.VIEW_AND_DOWNLOAD_PERMISSION
                ];
    }

    static forPermissionsList (permissions) {
        return (permissions.indexOf('modify') !== -1)
            ? SharePermission.COLLABORATION_PERMISSION
            : SharePermission.VIEW_AND_DOWNLOAD_PERMISSION;
    }

    static possiblePermissions (assets) {
        return Array.from(assets
            .map(asset => SharePermission.forAsset(asset))
            .reduce(
                (acc, permissions) => utils.setIntersection(acc, new Set(permissions)),
                new Set(SharePermission.ALL)
            ));
    }

    constructor (name, apiParams, constName, icon, desc) {
        this.name = name;
        this.apiParams = apiParams;
        this.constName = constName;
        this.icon = icon;
        this.desc = desc;
    }
}

SharePermission.VIEW_AND_DOWNLOAD_PERMISSION = new SharePermission(
    gettext('View and Download'), ['download'], 'VIEW_AND_DOWNLOAD_PERMISSION',
    'icon-download',
    gettext('Allows viewing, commenting and download')
);
SharePermission.COLLABORATION_PERMISSION = new SharePermission(
    gettext('Sync and Edit'), ['download', 'modify'], 'COLLABORATION_PERMISSION',
    'icon-edit',
    gettext('Allows syncing to desktop and editing')
);
SharePermission.ALL = [
    SharePermission.VIEW_AND_DOWNLOAD_PERMISSION,
    SharePermission.COLLABORATION_PERMISSION
];

export {
    getShortCode,
    OwnerInfo,
    SharingInfo,
    SharedWith,
    SharingError,
    SharePermission
};
