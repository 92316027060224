import $ from 'jquery';

import { Loader } from './loader';

const SEEK_SET = 0;
const SEEK_CUR = 1;
const SEEK_END = 2;

const SPHERICAL_UUID_ID = new DataView(new Uint8Array([
    0xff, 0xcc, 0x82, 0x63, 0xf8, 0x55, 0x4a, 0x93, 0x88, 0x14, 0x58, 0x7a, 0x02, 0x52, 0x1f, 0xdd
]).buffer, 0, 16); // ffcc8263-f855-4a93-8814-587a02521fdd

const util = {
    compareUint8Buffer (x, y) {
        if (x.byteLength !== y.byteLength) {
            return false;
        }
        for (let i = 0; i < x.byteLength; ++i) {
            if (x.getUint8(i) !== y.getUint8(i)) {
                return false;
            }
        }
        return true;
    },
    getStringFromDataView (dataView, fromByte = 0, toByte = null) {
        let outstr = '';
        for (let n = fromByte; n < (toByte || dataView.byteLength); n++) {
            outstr += String.fromCharCode(dataView.getUint8(n));
        }
        return outstr;
    }
};

class EOFError extends Error {
    constructor (...args) {
        super(...args);
        Error.captureStackTrace(this, EOFError);
    }
}

class Buffer {
    constructor (start, data) {
        this.start = start;
        this.data = data;
        this.length = data.byteLength;
        this.end = this.start + this.length;
    }

    contains (start, end) {
        return this.start <= start && start < end && end <= this.end;
    }

    get (start, end) {
        if (!this.contains(start, end)) {
            throw new Error('Out of range');
        }
        return this.data.slice(start - this.start, end - this.start);
    }
}

class BaseCursor {
    seek (length, fromPosition = SEEK_CUR) {
        if (fromPosition === SEEK_CUR) {
            this.position = this.position + length;
        } else if (fromPosition === SEEK_SET) {
            this.position = length;
        } else if (fromPosition === SEEK_END) {
            throw new Error('Seek relative to end is not supported');
        }
    }

    async getNext (currentBox = null) {
        if (currentBox) {
            this.seek(currentBox.size);
        }

        try {
            let headerSize = 8;
            let size = (await this.read(4)).getUint32(0);
            if (size === 1) {
                size = (await this.read(8)).getBigUint64(0);
                headerSize = 16;
            }

            if (size < 8) {
                throw new Error('Invalid size');
            }

            const name = util.getStringFromDataView(await this.read(4));
            this.seek(-headerSize);
            return {
                name,
                size,
                headerSize,
                readContent: async () => {
                    this.seek(headerSize);
                    return this.readRaw(size - headerSize);
                }
            };
        } catch (error) {
            if (error instanceof EOFError) {
                return null;
            } else {
                throw error;
            }
        }
    }

    async read (length) {
        const data = await this.readRaw(length);
        return new DataView(data, 0, data.byteLength);
    }
}

class HTTPCursor extends BaseCursor {
    constructor (url) {
        super();
        this.url = url;
        this.position = 0;
        this.buffer = new Buffer(0, new ArrayBuffer());
        this.loader = new Loader();
    }

    async readRaw (length) {
        if (!this.buffer.contains(this.position, this.position + length)) {
            await this.bufferData(length);
        }
        const data = this.buffer.get(this.position, this.position + length);
        this.seek(length);
        return data;
    }

    bufferData (length) {
        const requestDataLength = Math.max(1024, length);
        return new Promise((resolve, reject) => {
            this.loader
                .setResponseType('arraybuffer')
                .setHeader({
                    Range: `bytes=${this.position}-${this.position + requestDataLength - 1}`
                })
                .load(
                    this.url,
                    response => resolve(response),
                    event => {},
                    error => reject(error)
                );
        })
            .then(response => {
                if (length > response.byteLength) {
                    throw new EOFError();
                }
                this.buffer = new Buffer(this.position, response);
            });
    }
}

class BufferCursor extends BaseCursor {
    constructor (buffer) {
        super();
        this.position = 0;
        this.buffer = new Buffer(0, buffer);
    }

    async readRaw (length) {
        if (this.position + length > this.buffer.length) {
            throw new EOFError();
        }
        const data = this.buffer.get(this.position, this.position + length);
        this.seek(length);
        return data;
    }
}

async function getMoovBox (cursor) {
    let box = await cursor.getNext();
    while (box) {
        if (box.name === 'moov') {
            return await box.readContent();
        }
        box = await cursor.getNext(box);
    }
}

async function getTrackBoxes (moovBox, handleTrack) {
    const cursor = new BufferCursor(moovBox);
    let box = await cursor.getNext();
    while (box) {
        if (box.name === 'trak') {
            const trackBox = await box.readContent();
            if (await handleTrack(trackBox)) {
                return;
            }
        }
        box = await cursor.getNext(box);
    }
}

async function getSphericalMetadata (trackBox) {
    const cursor = new BufferCursor(trackBox);
    let box = await cursor.getNext();
    while (box) {
        if (box.name === 'uuid') {
            const sphericalMetadata = processUuidBox(await box.readContent());
            if (sphericalMetadata) {
                return parseSphericalMetadata(sphericalMetadata);
            }
        }
        box = await cursor.getNext(box);
    }
    return null;
}

function processUuidBox (data) {
    const uuid = new DataView(data, 0, 16);
    if (util.compareUint8Buffer(uuid, SPHERICAL_UUID_ID)) {
        return util.getStringFromDataView(new DataView(data, 16, data.byteLength - 16));
    }
    return null;
}

function parseSphericalMetadata (data) {
    /*
    <?xml version="1.0"?>
    <rdf:SphericalVideo xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:GSpherical="http://ns.google.com/videos/1.0/spherical/">
        <GSpherical:Spherical>true</GSpherical:Spherical>
        <GSpherical:Stitched>true</GSpherical:Stitched>
        <GSpherical:StitchingSoftware>Spherical Metadata Tool</GSpherical:StitchingSoftware>
        <GSpherical:ProjectionType>equirectangular</GSpherical:ProjectionType
    ></rdf:SphericalVideo>
    */

    const xml = $.parseXML(data);
    const root = $(xml);

    let spherical = root.find('GSpherical\\:Spherical');
    if (!spherical.length) {
        spherical = root.find('Spherical');
    }

    let projectionType = root.find('GSpherical\\:ProjectionType');
    if (!projectionType.length) {
        projectionType = root.find('ProjectionType');
    }

    return {
        spherical: spherical && spherical.text() === 'true',
        equirectangular: projectionType && projectionType.text() === 'equirectangular'
    };
}

async function findSphericalMetadata (url) {
    const moovBox = await getMoovBox(new HTTPCursor(url), 0);
    let sphericalMetadata = null;
    await getTrackBoxes(moovBox, async trackBox => {
        sphericalMetadata = await getSphericalMetadata(trackBox);
        return sphericalMetadata;
    });

    return sphericalMetadata;
}

async function showSphericalMetadata (url) {
    const sphericalMetadata = await findSphericalMetadata(url);
    console.log('sphericalMetadata', sphericalMetadata);
}

async function isVideoPanoramic (url) {
    const sphericalMetadata = await findSphericalMetadata(url);
    return sphericalMetadata &&
        sphericalMetadata.spherical &&
        sphericalMetadata.equirectangular;
}

export {
    showSphericalMetadata,
    isVideoPanoramic
};
