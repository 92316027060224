import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Controller } from './Default';
import FullScreen from './Widgets/FullScreen';
import Navigation from './Widgets/Navigation';

const Toggler = observer(({ controller, title, icon, name }) =>
    <span
        className={`ctrl-tool icon icon-${icon} ${controller.state.get('currentPanel') === name ? 'active' : ''}`}
        onClick={() => controller.togglePanel(name)}
        title={title}
    />
);

Toggler.propTypes = {
    controller: PropTypes.object,
    title: PropTypes.string,
    prop: PropTypes.string
};

const NavigationMode = observer(({ controller }) => {
    const isWalkthrough = controller.state.get('navigationMode') === 'walkthrough';
    return (
        <span
            className={`ctrl-tool icon icon-3d-walkthrough ${isWalkthrough ? 'active' : ''}`}
            onClick={() => controller.toggleNavigationMode()}
            title={gettext('Walkthrough')}
        />
    );
});

NavigationMode.propTypes = {
    controller: PropTypes.object,
    title: PropTypes.string,
    prop: PropTypes.string
};

const UnityVGXViewerController = ({ store, viewer }) =>
    <Controller store={store}>
        <div className='control-section left'>
            { viewer
                ? <React.Fragment>
                    <span
                        className='ctrl-tool icon icon-3d-home'
                        onClick={viewer.controller.goHome}
                        title={gettext('Home')}
                    />
                    <Toggler controller={viewer.controller} title={gettext('Palettes')} icon='3d-show-panel' name='palettes' />
                    <Toggler controller={viewer.controller} title={gettext('Edit clip cube')} icon='3d-edit-clipcube' name='clipCube' />
                    <NavigationMode controller={viewer.controller} />
                </React.Fragment>
                : null}
        </div>

        <div className='control-section center'>
            <Navigation store={store}/>
        </div>

        <div className='control-section right'>
            { viewer
                ? <React.Fragment>
                    <Toggler controller={viewer.controller} title={gettext('Settings')} icon='3d-settings' name='settings' />
                    <Toggler controller={viewer.controller} title={gettext('Help')} icon='help' name='help' />
                </React.Fragment>
                : null}
            <FullScreen store={store}/>
        </div>
    </Controller>;

UnityVGXViewerController.propTypes = {
    store: PropTypes.object,
    viewer: PropTypes.object
};

export default observer(UnityVGXViewerController);
