import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { PinEditPanel, S } from './PinEditPanel';
import { LinkCreator } from './LinkCreator/LinkCreator';
import { LinkPin as BoardLinkPin } from '../../../models/board';
import { LinkPin as TourLinkPin } from '../../../models/tour';

export const LinkPinEditorPopup = observer(({ pin, onClose }) => {
    const handleLinkChange = ({ linkState }) => {
        pin.update(linkState);
    };

    return (
        <PinEditPanel pin={pin} onClose={onClose}>
            <S.SectionTitle>{gettext('Link')}</S.SectionTitle>
            <S.Section>
                <LinkCreator linkState={pin.linkState} onChange={handleLinkChange} />
            </S.Section>
            <S.Separator />
        </PinEditPanel>
    );
});

// Like DraggablePin2D's props
LinkPinEditorPopup.propTypes = {
    onClose: PropTypes.func,
    pin: PropTypes.oneOfType([BoardLinkPin, TourLinkPin])
};
