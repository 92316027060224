import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { FileTypes } from '../../lib/file';
import { DialogThumbsLoader } from '../common/ThumbsLoader';
import DialogFrame from '../../Components/Dialog/DialogFrame';

class DialogInvalidUpload extends Component {
    onSubmit = () => {
        this.props.dialog.close(this.props.items);
    };

    onCancel = () => {
        this.props.dialog.dismiss();
    };

    getIcon (filename) {
        return FileTypes.get(filename, false, null).defaultFileTypeIcon();
    }

    render () {
        return (
            <DialogFrame
                title={gettext('Unsupported file type')}
                dialog={this.props.dialog}
                submitTitle={gettext('OK')}
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                submitAnalytics={{
                    'ga-action': 'Invalid_Upload_Dialog',
                    'ga-label': 'Click_Submit'
                }}
                cancelAnalytics={{
                    'ga-action': 'Invalid_Upload_Dialog',
                    'ga-label': 'Click_Cancel'
                }}
                hideCancel
            >
                <p className='dialog-paragraph'>{ this.props.message }</p>
                <br />
                <div className='items'>
                    {
                        this.props.items.map((item, index) => (
                            <div key={index} className='di-list-item'>
                                <DialogThumbsLoader iconHTML={this.getIcon(item.name)} />
                                <span className='item-name'>{ item.name }</span>
                            </div>
                        ))
                    }
                </div>
            </DialogFrame>
        );
    }
}

DialogInvalidUpload.propTypes = {
    dialog: PropTypes.object,
    items: PropTypes.array,
    message: PropTypes.string
};

export default DialogInvalidUpload;
