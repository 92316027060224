import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { pubsub } from '../../../../base';

import { TABLES } from './utils';

const NavButton = styled.div`
    padding: 0 4px;
    margin: 1px 0;

    border-radius: 4px;
    border: 1px solid var(--vcs-color--cyan);

    display: flex;
    align-self: center;
    
    cursor: pointer;
    word-break: break-word;
`;

const NAVIGATORS = {
    srcDeviceName: {
        table: TABLES.equipment.name,
        cells: {
            src: 'srcDeviceName',
            dst: 'deviceName'
        }
    },
    srcSocketName: {
        table: TABLES.devices.name,
        cells: {
            src: 'srcDeviceName',
            dst: 'device'
        },
        socket: {
            src: 'srcSocketName',
            dst: 'socketName'
        }
    },
    dstDeviceName: {
        table: TABLES.equipment.name,
        cells: {
            src: 'dstDeviceName',
            dst: 'deviceName'
        }
    },
    dstSocketName: {
        table: TABLES.devices.name,
        cells: {
            src: 'dstDeviceName',
            dst: 'device'
        },
        socket: {
            src: 'dstSocketName',
            dst: 'socketName'
        }
    },
    device: {
        table: TABLES.equipment.name,
        cells: {
            src: 'device',
            dst: 'deviceName'
        }
    },
    connectedTo: {
        table: TABLES.circuits.name,
        cells: {
            src: 'connectedTo',
            dst: 'number'
        },
        extraContidion: (source, target) => {
            const isSrc = source.device === target.srcDeviceName &&
                source.socketName === target.srcSocketName;
            const isDst = source.device === target.dstDeviceName &&
                source.socketName === target.dstSocketName;
            return isSrc || isDst;
        }
    },
    deviceName: {
        table: TABLES.devices.name,
        cells: {
            src: 'deviceName',
            dst: 'device'
        }
    }
};

const Navigator = ({ item, cell, nav }) => {
    const value = item[cell.id];
    const sourceItem = item;

    const onClick = e => {
        e.stopPropagation();
        pubsub.publish('fv.ccad.table.changed', nav.table);

        setTimeout(() => {
            pubsub.publish('fv.ccad.item.nav', { sourceItem, nav });
        }, 500); // setTimeout is used to run the action only after the table has changed; the 500ms value fixes a bug with Safari: VCS-30288
    };

    return value && value !== '---'
        ? <NavButton onClick={onClick}>{value}</NavButton>
        : value;
};

Navigator.propTypes = {
    item: PropTypes.object,
    cell: PropTypes.object,
    nav: PropTypes.object
};

export default Navigator;
export { NAVIGATORS };
