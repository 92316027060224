export class Appearance {
    static guessType (type) {
        return Appearance.MODELS[type];
    }

    static fromApi (data) {
        const Model = Appearance.guessType(data.type);
        return new Model(data);
    }

    constructor (type = Appearance.TYPES.SHAPE_WITH_ICON) {
        if (!Appearance.guessType(type)) {
            throw Error('Invalid appearance type.');
        }

        this.type = type;
    }
}

Appearance.TYPES = {
    SHAPE_WITH_ICON: 'shape_with_icon'
};

Appearance.MODELS = {};
