import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { pubsub } from '../../../../base';
import withResponsiveListeners from '../../../../utils/Responsive';

import Entity from './Entity';
import Cell from './Cell';
import LgContents from './LgContents';
import MobileContents from './MobileContents';
import S from './styles';

const skipColumns = ['device'];

const TogglerCell = observer(({ item, cell, store, open, toggleOpen }) =>
    <Cell
        key={`${item.key}#socketName`}
        cell={cell}
        item={item}
        store={store}
    >
        <S.Toggler className='cllps-toggle' expand={open} onClick={toggleOpen} />
    </Cell>
);

TogglerCell.propTypes = {
    item: PropTypes.object,
    cell: PropTypes.object,
    store: PropTypes.object,
    open: PropTypes.bool,
    toggleOpen: PropTypes.func
};

const Socket = observer(withResponsiveListeners(({ item, store, screen }) => {
    const isMobile = React.useMemo(() => !['md', 'lg'].includes(screen.size), [screen]);
    const [open, setOpen] = React.useState(false);
    const toggleOpen = () => setOpen(!open);
    const [selected, setSelected] = React.useState(false);
    const toggleSelected = () => setSelected(!selected);
    const ref = React.useRef(null);

    React.useEffect(() => {
        pubsub.subscribe(Socket, 'fv.ccad.socket.nav', ({ sourceItem, nav }) => {
            if (item.isMe(sourceItem, nav.socket)) {
                setOpen(true);
                setSelected(true);
                ref.current && ref.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
            }
        });
        return () => pubsub.unsubscribeAll(Socket);
    }, []);

    return isMobile
        ? <S.trToggler
            ref={ref}
            className={`${screen.size} ${open ? 'item-selected-light' : null}`}
            expand={open}
            identation={1}
        >
            <S.Toggler className='cllps-toggle' expand={open} onClick={toggleOpen} />
            {
                !open &&
                    <S.TogglerDetails columns={2}>
                        <Cell
                            key={`${item.key}#socketName`}
                            cell={store.columns.map.get('socketName')}
                            item={item}
                            store={store}
                        />
                        <Cell
                            key={`${item.key}#connectedTo`}
                            cell={store.columns.map.get('connectedTo')}
                            item={item}
                            store={store}
                        />
                    </S.TogglerDetails>
            }
            <S.Collapse in={open}>
                <MobileContents item={item} store={store} skipColumns={skipColumns} />
            </S.Collapse>
        </S.trToggler>
        : <LgContents
            ref={ref}
            item={item}
            store={store}
            selected={selected}
            onClick={toggleSelected}
            skipColumns={skipColumns}
        />;
}));

Socket.propTypes = {
    item: PropTypes.object,
    store: PropTypes.object,
    onClick: PropTypes.func
};

const LgHeader = ({ item, store, sockets, open, toggleOpen, selected, toggleSelected }) => {
    const deviceCell = store.columns.map.get('device');
    return (
        <S.LgContent
            className={`${selected ? 'item-selected-light' : null}`}
            count={store.columns.activeCount}
            onClick={toggleSelected}
        >
            {
                sockets.length === 1
                    ? <Cell
                        key={`${item.key}#${deviceCell.id}`}
                        item={item}
                        cell={deviceCell}
                        store={store}
                        isMobile={false}
                    />
                    : <TogglerCell
                        item={item}
                        cell={deviceCell}
                        store={store}
                        open={open}
                        toggleOpen={toggleOpen}
                    />
            }
            {
                (sockets.length === 1 || open) &&
                store.columns.active
                    .filter(cell => !skipColumns.includes(cell.id))
                    .map(cell =>
                        <Cell
                            key={`${item.key}#${cell.id}`}
                            item={item}
                            cell={cell}
                            store={store}
                            isMobile={false}
                        />
                    )
            }
        </S.LgContent>
    );
};

LgHeader.propTypes = {
    item: PropTypes.object,
    store: PropTypes.object,
    sockets: PropTypes.array,
    open: PropTypes.bool,
    toggleOpen: PropTypes.func,
    selected: PropTypes.bool,
    toggleSelected: PropTypes.func
};

const Device = ({ item, sockets, store, screen }) => {
    const isMobile = React.useMemo(() => !['md', 'lg'].includes(screen.size), [screen]);
    const collapseSockets = isMobile ? sockets : sockets.slice(1);

    return (
        <Entity
            item={item}
            store={store}
            header={(open, toggleOpen) => isMobile
                ? <TogglerCell
                    item={item}
                    cell={store.columns.map.get('device')}
                    store={store}
                    open={open}
                    toggleOpen={toggleOpen}
                />
                : <LgHeader
                    item={item}
                    store={store}
                    sockets={sockets}
                    open={open}
                    toggleOpen={toggleOpen}
                />}
            collapse={null}
        >
            {
                collapseSockets.map(socket => <Socket
                    key={`socket-${socket.key}`}
                    item={socket}
                    store={store}
                />)
            }
        </Entity>
    );
};

Device.propTypes = {
    item: PropTypes.object,
    store: PropTypes.object,
    sockets: PropTypes.array,
    screen: PropTypes.object
};

export default withResponsiveListeners(Device);
