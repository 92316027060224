import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import Navigator, { NAVIGATORS } from './Navigator';
import { highlightSearch } from './utils';
import S from './styles';
import ViewerContext from './ViewerContext';

const Cell = ({ item, cell, isPreview, children }) => {
    const { store, isMobile } = React.useContext(ViewerContext);
    const Component = isMobile ? S.MobileCell : S.LgCell;
    return (
        <Component
            align={cell && item.getAlign(cell)}
            isPreview={isPreview}
        >
            { children }
            {
                cell && (
                    NAVIGATORS[cell.id]
                        ? <Navigator
                            store={store}
                            item={item}
                            cell={cell}
                            nav={NAVIGATORS[cell.id]}
                        />
                        : highlightSearch(
                            item[cell.id], store.searchQuery, item, cell
                        )
                )
            }
        </Component>
    );
};

Cell.propTypes = {
    item: PropTypes.object,
    cell: PropTypes.object,
    isPreview: PropTypes.bool
};

export default observer(Cell);
