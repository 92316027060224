import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { FilePath, FileTypes } from '../../lib/file';
import CollectionStore from '../Stores/Collection';
import AssetLibrary from '../View/AssetLibrary';
import ThumbsLoader from '../common/ThumbsLoader';
import CollectionKeyHandler from '../common/CollectionKeyHandler';
import DialogFrame from '../../Components/Dialog/DialogFrame';
import AfterParent from '../../Components/AfterParent';

import actions from '../View/AssetLibrary/actions';

const Asset = observer(({ data, isSelected, clickEvents }) => {
    const selectedStyles = 'item-selected-light list-selected-light';

    const getIcon = () => {
        return FileTypes.get(data.filename, false, null).defaultFileTypeIcon();
    };

    const { id, filename, fileVersion } = data;

    return (
        <div
            className={'asset library-ls-item library-asset-ls-item ' + (isSelected ? selectedStyles : '')}
            data-what='library-asset'
            data-which={`${id}:${filename}`}
            {...clickEvents(data)}
        >

            <div className='attr attr-primary name'>
                <div className='name-wrapper'>
                    <div className='base-name'>{ FilePath(filename).baseName() }</div>
                    <div className='extension'>{ FilePath(filename).extension() }</div>
                </div>
            </div>

            <ThumbsLoader thumbnail={fileVersion && fileVersion.thumbnail} iconHTML={getIcon()} />
        </div>
    );
});

Asset.propTypes = {
    isSelected: PropTypes.bool,
    data: PropTypes.object,
    clickEvents: PropTypes.func
};

class AssetLibraryDialog extends React.Component {
    constructor (props) {
        super(props);
        this.collectionStore = new CollectionStore(
            props.store.asset.assets,
            actions,
            {
                assetStore: props.store.asset,
                store: props.store
            }
        );
    }

    componentWillUnmount () {
        this.collectionStore.cleanUp();
    }

    onSubmit = () => {
        this.props.dialog.close(this.collectionStore.selection.items.map(item => item.data));
    };

    onCancel = () => {
        this.props.dialog.dismiss();
    };

    render () {
        return (
            <DialogFrame
                id='dialog--file-library'
                dialog={this.props.dialog}
                title={gettext('Add files to slide')}
                submitTitle={gettext('Select')}
                submitAnalytics={{
                    'ga-action': 'Presentation_Library_Dialog',
                    'ga-label': 'Click_Submit'
                }}
                cancelTitle={gettext('cancel')}
                cancelAnalytics={{
                    'ga-action': 'Presentation_Library_Dialog',
                    'ga-label': 'Click_Cancel'
                }}
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                validationError={
                    !this.props.validateSelection(this.collectionStore.selection.items) ||
                this.collectionStore.selection.items.some(item => !item.isValid)
                }
            >
                <AssetLibrary
                    id='editor-dialog'
                    store={this.props.store}
                    collectionStore={this.collectionStore}
                    uploadValidation={this.props.uploadValidation}
                    validationFunction={this.props.validationFunction}
                    filterFunction={this.props.filterFunction}
                    selectDialogProps={this.props.selectDialogProps}
                />
                <AfterParent>
                    <CollectionKeyHandler collectionStore={ this.collectionStore } extend={true}/>
                </AfterParent>
            </DialogFrame>
        );
    }
}

AssetLibraryDialog.propTypes = {
    dialog: PropTypes.any,
    uploadValidation: PropTypes.func,
    validationFunction: PropTypes.func,
    validateSelection: PropTypes.func,
    filterFunction: PropTypes.func,
    store: PropTypes.object,
    /* Those are used in the Upload files dialog, not the main one */
    // TODO: Rename to uploadDialogProps
    selectDialogProps: PropTypes.object
};

AssetLibraryDialog.defaultProps = {
    validateSelection: items => items.length > 0
};

export default observer(AssetLibraryDialog);
