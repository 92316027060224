import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observe, makeObservable, action } from 'mobx';

import {
    usePopupState,
    bindTrigger,
    bindMenu
} from 'material-ui-popup-state/hooks';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { Menu } from '@vectorworks/vcs-ui/dist/lib/Menu/Menu';
import { MenuItem, MenuItemIcon } from '@vectorworks/vcs-ui/dist/lib/MenuItem/MenuItem';

import api from '../../api';
import { inject } from '../../Store';
import { AssetStore } from '../../Stores/Asset';
import CollectionStore from '../../Stores/Collection';
import { validateExistingFiles } from '../../validations';
import ContextTools from '../../common/ToolbarComponents/ContextTools';
import { Dialog } from '../../../base/dialog';
import SearchWidget from '../../../Components/SearchWidget';
import { browse } from './upload';

const GlobalTools = inject('root', 'session')(observer((props) => {
    const popupState = usePopupState({
        variant: 'popper',
        popupId: 'presentations-library__add-files'
    });

    const upload = () => {
        popupState.close();
        browse({
            rootStore: props.root,
            uploadValidation: props.uploadValidation,
            collectionStore: props.collectionStore
        });
    };

    const getSelectDialogContext = () => {
        const ctx = props.selectDialogProps
            ? props.selectDialogProps
            : {
                dialogTitle: gettext('Add files to presentation resources'),
                filesOnly: true,
                validateSelection: assets => assets.length > 0 && assets.every(a => a.isFile)
            };
        const { storageType, folder, ownerInfo } = props.session.lastSelectFilesDialogUse;
        // TODO: check if integration is valid
        Object.assign(ctx, {
            currentFolder: folder,
            currentStorageType: storageType,
            ownerInfo
        });
        return ctx;
    };

    /** TODO: @srolev
 * Edit slide
 * + from carousel
 * Library --> Add from home
 * Should open with zIndex: 7
 */
    const copyFromCloud = () => {
        popupState.close();
        return Dialog.open({
            component: 'dialog-select-file',
            ctx: {
                ctx: getSelectDialogContext()
            }
        }).result
            .then(files => {
                props.session.trackSelectFilesDialogUse(files);
                return files;
            })
            .then(files => validateExistingFiles(props.assetStore.assets, files))
            .then(files => api.asset.copyFromCloudBulk(props.presentationId, files))
            .then(jobs =>
                jobs.map(job => job.asset).forEach(asset => {
                    props.assetStore.createOrUpdateAssetFromData(asset);
                    props.collectionStore.index.map.get(asset.uuid).select();
                })
            );
    };

    return (
        <React.Fragment>
            <div className={props.searchProps.expanded ? 'asset-lib__search-wrap' : ''}>
                { props.searchProps.expanded &&
                    <SearchWidget
                        {...props.searchProps}
                        placeholder={ gettext('Search library') }
                        cssClass='asset-lib__search-widget'
                    />}
                <a
                    className={`tool icon icon16 icon-search-16 pull-right ${props.searchProps.expanded ? 'tool-active' : ''}`}
                    title={ gettext('Search library') }
                    onClick={ props.searchProps.toggle }
                    data-what='presentations-library-search'
                    ga-action='Presentation_Library'
                    ga-label='Click_Search'
                />
            </div>
            <div className='gl-tools'>
                {!props.searchProps.expanded &&
                <React.Fragment>
                    <div
                        id={props.id}
                        className='tool icon icon-upload-16 icon16'
                        title={ gettext('Upload files') }
                        data-what='presentations-library-add-files'
                        ga-action='Presentation_Library'
                        ga-label='Click_Add_Files'
                        {...bindTrigger(popupState)}
                    />
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={upload}>
                            <MenuItemIcon>
                                <Icon
                                    icon='upload'
                                    className='action-icon'
                                    title={ gettext('Upload files to library') }
                                    data-what='presentations-upload-files'
                                    ga-action='Presentation_Library'
                                    ga-label='Click_Add_From_Computer'
                                />
                            </MenuItemIcon>
                            { gettext('Upload files') }
                        </MenuItem>
                        <MenuItem onClick={copyFromCloud}>
                            <MenuItemIcon>
                                <Icon
                                    icon='plus-content'
                                    className='action-icon'
                                    title={ gettext('Copy files from cloud') }
                                    data-what='presentations-library-add-cloud-files'
                                    ga-action='Presentation_Library'
                                    ga-label='Click_Add_From_Cloud'
                                />
                            </MenuItemIcon>
                            { gettext('Add files from cloud storage') }
                        </MenuItem>
                    </Menu>
                </React.Fragment>}
            </div>
        </React.Fragment>
    );
}));

GlobalTools.propTypes = {
    id: PropTypes.any,
    presentationId: PropTypes.string,
    assetStore: PropTypes.instanceOf(AssetStore),
    uploadValidation: PropTypes.func,
    selectDialogProps: PropTypes.object,
    dialog: PropTypes.object,
    searchProps: PropTypes.object,
    root: PropTypes.object,
    session: PropTypes.object,
    collectionStore: PropTypes.object
};

class Toolbar extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            search: false,
            searchQuery: ''
        };

        makeObservable(this, {
            clearSearch: action,
            onSearchInput: action
        });
    }

    componentDidMount () {
        this.selectionObserver = observe(this.props.collectionStore, 'selection', (change) => {
            change.oldValue.items.length < change.newValue.items.length &&
                this.closeSearch(); // Close search on selection
        });
    }

    closeSearch = () => {
        this.setState({ search: false });
    };

    clearSearch = () => {
        this.closeSearch();
        this.setState({ searchQuery: '' });
        this.props.collectionStore.filters.delete('filename');
    };

    toggleSearch = () => {
        this.setState(
            ({ search }) => ({ search: !search })
        );
    };

    onSearchInput = (query) => {
        if (!query) {
            this.props.collectionStore.filters.delete('filename');
        } else {
            this.props.collectionStore.filters.set('filename', (item) => (
                item.data.filename.toLowerCase()
                    .includes(query.toLowerCase())
            ));
        }
        this.setState({ searchQuery: query });
    };

    render () {
        return (
            <React.Fragment>
                <div className='toolbar'>
                    <GlobalTools
                        id={ this.props.id }
                        presentationId={ this.props.store.id }
                        assetStore={ this.props.store.asset }
                        collectionStore={ this.props.collectionStore }
                        uploadValidation={ this.props.uploadValidation }
                        selectDialogProps={this.props.selectDialogProps}
                        searchProps={{
                            expanded: this.state.search,
                            initialQuery: this.state.searchQuery,
                            onInput: this.onSearchInput,
                            toggle: this.toggleSearch,
                            onClose: this.clearSearch
                        }}
                    />

                    { !this.state.search &&
                    <React.Fragment>
                        <span className='tool-separator'/>

                        {
                            Object.keys(this.props.target.actions).length
                                ? (<ContextTools
                                    target={this.props.target}
                                    what='library-glob-tool'
                                    GAAction='Presentation_Library_Toolbar'
                                >
                                    <ContextTools.ContextActions />
                                </ContextTools>
                                )
                                : (
                                    <div style={{ overflow: 'hidden' }}>
                                        <div className='toolbar-prim-text pull-left' what='presentation-name' style={{ width: '100%' }}>
                                            {
                                                this.props.closePanel &&
                                                <span
                                                    className='icon icon16 icon-hide-16 vam hover-icon hidden-lg'
                                                    onClick={ () => { this.props.store.ui.assetLibraryVisible = false; } }
                                                />
                                            }
                                            { gettext('Presentation resources') }&nbsp;
                                        </div>
                                    </div>
                                )
                        }
                    </React.Fragment>}
                </div>
            </React.Fragment>
        );
    }
}

export default observer(Toolbar);

Toolbar.propTypes = {
    id: PropTypes.any,
    store: PropTypes.object,
    collectionStore: PropTypes.instanceOf(CollectionStore),
    target: PropTypes.object,
    uploadValidation: PropTypes.func,
    selectDialogProps: PropTypes.object,
    closePanel: PropTypes.bool

};
