import { runInAction } from 'mobx';

import api from '../../../api';

export class CommandTypes {
    static CREATE = 'CREATE';
    static UPDATE = 'UPDATE';
    static DELETE = 'DELETE';
    static TRASH_PINS = 'TRASH_PINS';
}

export const boxMixin = {
    postCreate (options = {}) {
        this.postCreationQueue.forEach(callback => callback());
        this.postCreationQueue = [];

        const { flush = true } = options;
        return flush
            ? this.root.saveQueue.flush()
            : Promise.resolve();
    },
    update () {
        if (!this.databaseId) {
            this.postCreationQueue.push(() => this.update());
            return Promise.resolve();
        } else {
            return this.scheduleSave(this.saveCommands.update, this.editorBox);
        }
    },
    applyProps (props) {
        this.editorBox.applyProps(props);
        const applyPromise = props.text
            ? this.editorBox._dangerouslySetText(props.text)
            : Promise.resolve();

        applyPromise.then(() => {
            this.update();
            this.board.vcsBEStore.updateRect();
        });
    },
    restore () {
        this.board.vcsBEStore.addBox(this.editorBox);
        this.scheduleCreate();
    },
    scheduleCreate (...args) { return this.scheduleSave(this.saveCommands.add, ...args); },
    scheduleUpdate (...args) { return this.scheduleSave(this.saveCommands.update, ...args); },
    scheduleDelete (...args) { return this.scheduleSave(this.saveCommands.remove, ...args); },
    saveCommands: {
        add: {
            type: CommandTypes.CREATE,
            command () {
                return api.board.box.create(this.board, this.createData)
                    .then(({ id }) => runInAction(() => {
                        /*
                            Check if you deleted the box from the UI during the request.
                            If so, trigger a delete box with that databaseId?
                            Remove the Delete request from the Q after
                        */
                        this.databaseId = id;
                        this.pins && this.pins.forEach(pin => {
                            pin.boxId = id;
                            // pin.update(); ? Save to DB? Does the pin already have and id? Add this as a reaction to the pin itself?
                        });

                        if (this.editorBox.store.boxes.includes(this.editorBox)) {
                            this.postCreate();
                            return this;
                        } else {
                            this.scheduleDelete();
                        }
                    }));
            },
            params: {
                noReduce: true
            }
        },
        update: {
            type: CommandTypes.UPDATE,
            command () {
                return api.board.box.update(this, {
                    props: this.apiProps
                });
            },
            params: {
                mergeObjectArgs: true
            }
        },
        remove: {
            type: CommandTypes.DELETE,
            command () {
                return api.board.box.remove(this);
            },
            params: {
                noReduce: true
            }
        }
    }
};
