import React, { Component } from 'react';

import { observer } from 'mobx-react';

import { commonPropTypes } from './utils';
import CouldNotOpenVersion from './CouldNotOpenVersion';
import NoPermission from './NoPermission';
import NoPreview from './NoPreview';
import NotFound from './NotFound';
import SharedFileNotFound from './SharedFileNotFound';
import OwnerStoppedIntegration from './OwnerStoppedIntegration';

class ErrorViewer extends Component {
    name = 'ErrorViewer';

    componentDidMount () {
        setTimeout(this.props.store.endLoading, 1000);
    };

    componentDidUpdate () {
        if (this.props.store.loading) {
            setTimeout(this.props.store.endLoading, 1000);
        }
    }

    render () {
        if (this.props.store.loading) {
            return null;
        }

        if (!this.props.store.file.file.isAvailable()) {
            return <CouldNotOpenVersion store={this.props.store}/>;
        } else if (this.props.store.file.preview.error === 'integration') {
            return <OwnerStoppedIntegration store={this.props.store}/>;
        } else if (this.props.store.file.preview.error === 'preview') {
            return <NoPreview store={this.props.store}/>;
        } else if (this.props.store.file.preview.error === 'found' && !this.props.store.loading && this.props.store.file.fileResource.isShared) {
            return <SharedFileNotFound store={this.props.store}/>;
        } else if (this.props.store.file.preview.error === 'found' && !this.props.store.loading) {
            return <NotFound store={this.props.store}/>;
        } else if (this.props.store.file.preview.error === 'permission' && !this.props.store.loading) {
            return <NoPermission/>;
        } else {
            return null;
        }
    }
}

export default observer(ErrorViewer);

ErrorViewer.propTypes = {
    ...commonPropTypes
};
