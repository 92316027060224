import PropTypes from 'prop-types';

import {
    createInlineStyleButton,
    createBlockStyleButton,
    createBlockAlignmentButton
} from '@draft-js-plugins/buttons';
import React from 'react';
import styled from 'styled-components';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';
import { getAlignment } from '../../../../Editor/Board/TextEditor/editor-funcs';

const S = {
    StyleButton: styled(IconButton).attrs({
        toggleIndicatorProps: {
            style: {
                top: 0,
                left: 0,
                width: '32px',
                height: '32px'
            }
        }
    })`
        display: block;
    `
};

export const Bold = ({ toggled, ...props }) => {
    const Inner = createInlineStyleButton({
        style: 'BOLD',
        children: (
            <S.StyleButton toggled={toggled}>
                <Icon
                    icon='style-bold'
                    data-what='text-style-tool'
                    data-which='bold'
                    ga-action='Presentation_Text_Tool'
                    ga-label='Click_Bold'
                />
            </S.StyleButton>
        )
    });

    return <Inner {...props} />;
};
Bold.propTypes = {
    toggled: PropTypes.bool
};

export const Italic = ({ toggled, ...props }) => {
    const Inner = createInlineStyleButton({
        style: 'ITALIC',
        children: (
            <S.StyleButton toggled={toggled}>
                <Icon
                    icon='style-italic'
                    data-what='text-style-tool'
                    data-which='italic'
                    ga-action='Presentation_Text_Tool'
                    ga-label='Click_Italic'
                />
            </S.StyleButton>
        )
    });
    return <Inner {...props} />;
};
Italic.propTypes = {
    toggled: PropTypes.bool
};

export const Underline = ({ toggled, ...props }) => {
    const Inner = createInlineStyleButton({
        style: 'UNDERLINE',
        children: (
            <S.StyleButton toggled={toggled}>
                <Icon
                    icon='style-underline'
                    data-what='text-style-tool'
                    data-which='underline'
                    ga-action='Presentation_Text_Tool'
                    ga-label='Click_Underline'
                />
            </S.StyleButton>
        )
    });
    return <Inner {...props} />;
};
Underline.propTypes = {
    toggled: PropTypes.bool
};

export const UnorderedList = ({ toggled, ...props }) => {
    const Inner = createBlockStyleButton({
        blockType: 'unordered-list-item',
        children: (
            <S.StyleButton toggled={toggled}>
                <Icon
                    icon='style-list'
                    data-what='text-style-tool'
                    data-which='unordered-list'
                    ga-action='Presentation_Text_Tool'
                    ga-label='Click_Unordered_List'
                />
            </S.StyleButton>
        )
    });
    return <Inner {...props} />;
};

UnorderedList.propTypes = {
    toggled: PropTypes.bool
};

export const OrderedList = ({ toggled, ...props }) => {
    const Inner = createBlockStyleButton({
        blockType: 'ordered-list-item',
        children: (
            <S.StyleButton toggled={toggled}>
                <Icon
                    icon='style-list-numbered'
                    data-what='text-style-tool'
                    data-which='numbered-list'
                    ga-action='Presentation_Text_Tool'
                    ga-label='Click_Numbered_List'
                />
            </S.StyleButton>
        )
    });
    return <Inner {...props} />;
};
OrderedList.propTypes = {
    toggled: PropTypes.bool
};

function passAlignment (Component) {
    const Wrapper = React.forwardRef((props, ref) => {
        const { getEditorState } = props;
        const alignment = getAlignment(getEditorState());
        return <Component {...props} alignment={alignment} ref={ref} />;
    });
    Wrapper.propTypes = {
        getEditorState: PropTypes.func
    };
    Wrapper.displayName = `passAlignment(${Component.displayName || Component.name})`;
    return Wrapper;
}

export const AlignLeft = ({ toggled, ...props }) => {
    const Inner = passAlignment(
        createBlockAlignmentButton({
            alignment: 'left',
            children: (
                <S.StyleButton
                    toggled={toggled}
                    data-what='text-style-tool'
                    data-which='align-left'
                    ga-action='Presentation_Text_Tool'
                    ga-label='Click_Align_Left'
                >
                    <Icon icon='text-align-left' />
                </S.StyleButton>
            )
        }
        )
    );

    return <Inner {...props} />;
};
AlignLeft.propTypes = {
    toggled: PropTypes.bool
};

export const AlignCenter = ({ toggled, ...props }) => {
    const Inner = passAlignment(
        createBlockAlignmentButton({
            alignment: 'center',
            children: (
                <S.StyleButton
                    toggled={toggled}
                    data-what='text-style-tool'
                    data-which='align-center'
                    ga-action='Presentation_Text_Tool'
                    ga-label='Click_Align_Center'
                >
                    <Icon icon='text-align-center' />
                </S.StyleButton>
            )
        }
        )
    );
    return <Inner {...props} />;
};
AlignCenter.propTypes = {
    toggled: PropTypes.bool
};

export const AlignRight = ({ toggled, ...props }) => {
    const Inner = passAlignment(
        createBlockAlignmentButton({
            alignment: 'right',
            children: (
                <S.StyleButton
                    toggled={toggled}
                    data-what='text-style-tool'
                    data-which='align-right'
                    ga-action='Presentation_Text_Tool'
                    ga-label='Click_Align_Right'
                >
                    <Icon icon='text-align-right' />
                </S.StyleButton>
            )
        }
        )
    );

    return <Inner {...props} />;
};
AlignRight.propTypes = {
    toggled: PropTypes.bool
};

export const HeadlineOne = ({ toggled, ...props }) => {
    const Inner = createBlockStyleButton({
        blockType: 'header-one',
        children: (
            <S.StyleButton toggled={toggled}>
                <Icon
                    icon='fontsize-lg'
                    data-what='text-style-tool'
                    data-which='large'
                    ga-action='Presentation_Text_Tool'
                    ga-label='Click_Large'
                />
            </S.StyleButton>
        )
    });
    return <Inner {...props} />;
};
HeadlineOne.propTypes = {
    toggled: PropTypes.bool
};

export const HeadlineTwo = ({ toggled, ...props }) => {
    const Inner = createBlockStyleButton({
        blockType: 'header-two',
        children: (
            <S.StyleButton toggled={toggled}>

                <Icon
                    icon='fontsize-md'
                    data-what='text-style-tool'
                    data-which='medium'
                    ga-action='Presentation_Text_Tool'
                    ga-label='Click_Medium'
                />
            </S.StyleButton>
        )
    });
    return <Inner {...props} />;
};
HeadlineTwo.propTypes = {
    toggled: PropTypes.bool
};

export const HeadlineThree = ({ toggled, ...props }) => {
    const Inner = createBlockStyleButton({
        blockType: 'header-three',
        children: (
            <S.StyleButton toggled={toggled}>
                <Icon
                    icon='fontsize-sm'
                    data-what='text-style-tool'
                    data-which='small'
                    ga-action='Presentation_Text_Tool'
                    ga-label='Click_Small'
                />
            </S.StyleButton>
        )
    });
    return <Inner {...props} />;
};
HeadlineThree.propTypes = {
    toggled: PropTypes.bool
};

export const StyledToolbar = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    button {
        border: 0;
        background-color: transparent;
        font-size: 32px;
        display: block;

        i {
            display: block;
        }
    }
`;
