import axios from 'axios';

import { Dialog } from './dialog';
import { queryString, utils } from '../lib';
import cache from './requst-cache';
import { IntegrationExpiredRevokedError } from './error';

const NAMESPACE = 'vcs';

class DefaultErrorHandler {
    constructor (name, handler) {
        this.name = name;
        this.handler = handler;
    }
}

DefaultErrorHandler.ERROR_304 = new DefaultErrorHandler(
    'ERROR_304', [
        304,
        (error, response) => {
            const version = response.headers['x-version'];
            if (response.status === 304 && version) {
                const url = queryString.buildUrl(
                    response.config.url,
                    queryString.parse(response.config.params)
                );
                return Promise.resolve(cache.get(url).data);
            }
            return response;
        }
    ]);

DefaultErrorHandler.ERROR_400 = new DefaultErrorHandler(
    'ERROR_400', [
        (_, response) => response.status === 400 && response.data.error_code === 'resource_not_supported',
        (error, response) => {
            return import('../asset/asset').then(({ Asset }) =>
                Dialog.open({
                    component: 'alert',
                    ctx: {
                        ctx: {
                            asset: Asset.fromSimpleData(response.data.asset)
                        },
                        template: { name: 'url:browser/dialogs/dialog-node-not-supported.html' }
                    }
                }).result
            );
        }
    ]);

DefaultErrorHandler.ERROR_401_403_428 = new DefaultErrorHandler(
    'ERROR_401_403_428', [
        (error, response) => {
            const statusCode = response.status;
            return statusCode === 401 || statusCode === 403 || statusCode === 428;
        },
        (error, response) => {
            if (response.data === 'Credentials expired or revoked.') { // Dropbox/Google Drive integration is expired
                IntegrationExpiredRevokedError.showErrorDialog();
                return Promise.reject(new IntegrationExpiredRevokedError(error));
            } else { // Show general error dialog
                const { pathname, search, hash } = window.location;
                const next = window.encodeURI(pathname + search + hash);

                const loginUrl = `/accounts/login/?next=${next}`;

                const loginRedirectMessage = interpolate(
                    gettext("You must %(loginUrl)s to access this page or your current account has no permissions for this action."), // eslint-disable-line
                    { loginUrl: `<a href="${loginUrl}">${gettext('log in')}</a>` },
                    true
                );

                const permissionMessage = response.data.detail;
                if (permissionMessage === 'Comment Reply Permission') {
                    return Dialog.open({
                        component: 'alert',
                        ctx: {
                            ctx: {
                                reload: function () { window.location.reload(); return false; }
                            },
                            template: { name: 'url:browser/dialogs/dialog-not-permitted-comment.html' }
                        }
                    }).result;
                };
                return Dialog.open({
                    component: 'alert',
                    ctx: {
                        ctx: {
                            loginRedirectMessage,
                            reload: function () { window.location.reload(); return false; }
                        },
                        template: { name: 'url:browser/dialogs/dialog-unauthorized-error.html' }
                    }
                }).result;
            }
        }
    ]);

DefaultErrorHandler.ERROR_429 = new DefaultErrorHandler(
    'ERROR_429', [
        429,
        (error, response) => {
            const config = error.config;
            const currentState = config[NAMESPACE] || {};
            const data = response.data;
            const retryAfter = currentState.retryCount < 10 && data && data.retry_after;
            return retryAfter
                ? utils.delay(retryAfter)
                    .then(() => {
                        fixConfig(axios, config);
                        return axios(config);
                    })
                : Promise.reject(error);
        }
    ]);

DefaultErrorHandler.ALL = [
    DefaultErrorHandler.ERROR_304,
    DefaultErrorHandler.ERROR_400,
    DefaultErrorHandler.ERROR_401_403_428,
    DefaultErrorHandler.ERROR_429
];

export { DefaultErrorHandler };

export function createErrorInterceptor (errorHandlers = DefaultErrorHandler.ALL) {
    return function (error) {
        const response = error.response;
        if (response) {
            for (const errorHandler of errorHandlers) {
                const [predicate, handler] = errorHandler.handler;
                const shouldHandle = $.isFunction(predicate)
                    ? predicate(error, response)
                    : predicate === response.status;
                if (shouldHandle) return handler(error, response);
            }
        } else if (error.request) {
            console.log('The request was made but no response was received', error.request);
        } else {
            console.log('Request error', error.message);
        }
        return Promise.reject(error);
    };
}

function defaultRequestInterceptor (request) {
    request = setDefaultState(request);
    request = setVersionHeader(request);
    return request;
}

function defaultResponseInterceptor (response) {
    response = handleVersionHeader(response);
    return response.data;
}

const defaultErrorInterceptor = createErrorInterceptor();

// On response, set or delete the cache
function handleVersionHeader (response) {
    const version = response.headers['x-version'];
    if (version) {
        const url = queryString.buildUrl(
            response.config.url,
            queryString.parse(response.config.params)
        );
        cache.set(url, {
            version,
            data: response.data
        });
    }
    return response;
}

function setDefaultState (config) {
    const currentState = config[NAMESPACE] || {};
    currentState.retryCount = currentState.retryCount || 0;
    config[NAMESPACE] = currentState;
    return config;
}

function setVersionHeader (request) {
    // Only cache GET requests
    if (request.method === 'get') {
        const url = queryString.buildUrl(
            request.url,
            queryString.parse(request.params)
        );
        const cached = cache.get(url);
        if (cached) {
            request.headers['x-if-no-version-match'] = cached.version;
        }
    }

    return request;
}

function fixConfig (axios, config) {
    if (axios.defaults.agent === config.agent) {
        delete config.agent;
    }
    if (axios.defaults.httpAgent === config.httpAgent) {
        delete config.httpAgent;
    }
    if (axios.defaults.httpsAgent === config.httpsAgent) {
        delete config.httpsAgent;
    }
    if (config.timeout === 0) {
        delete config.timeout;
    }
}

axios.interceptors.request.use(defaultRequestInterceptor);
axios.interceptors.response.use(defaultResponseInterceptor, defaultErrorInterceptor);

function createRequest (options = {}) {
    const request = axios.create();
    request.interceptors.request.use(options.requestInterceptor || defaultRequestInterceptor);
    request.interceptors.response.use(
        options.responseInterceptor || defaultResponseInterceptor,
        options.errorInterceptor || defaultErrorInterceptor
    );
    return request;
}

const request = createRequest();

export {
    createRequest,
    defaultRequestInterceptor,
    defaultResponseInterceptor,
    defaultErrorInterceptor,
    request
};
