import { runInAction } from 'mobx';

import api from '../api';

const assetMixin = {
    addAsset (assetId) {
        if (!this.assetIds.includes(assetId)) {
            return api.slide
                .addAsset(this, assetId)
                .then(() => runInAction(() => {
                    this.assetIds.push(assetId);
                }));
        }
        return Promise.resolve(null);
    },
    removeAsset (assetId) {
        if (this.assetIds.includes(assetId)) {
            return api.slide
                .removeAsset(this, assetId)
                .then(() => runInAction(() => {
                    this.assetIds.remove(assetId);
                }));
        }
        return Promise.resolve(null);
    }
};

export default assetMixin;
